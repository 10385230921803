/* eslint-disable complexity */
import { BOOLEAN_OPTIONS_FORM, INCOME_LIST, INCOME_LIST_WITHOUT_YEARLY, GENDER_OPTIONS, INDUSTRY_TYPE_OPTIONS, RELATIONSHIP_STATUS, RELATIONSHIP_STATUS_OPTIONS, NUMBER_OPTIONS, HOUSEHOLD_EXPENSE_OPTIONS, BALLOON_REASONS_OPTIONS, BALLOON_REPAYMENT_METHODS_OPTIONS } from "../../constants/checkout-constants";
import * as Yup from "yup";
import { getFinanceLendersPrivacyDiscliamers } from "../../utils/helpers/finance-loan-application-util";
import { NUMBER } from "../../constants/app-constants";
import { getOptions } from "../../utils/helpers/checkout-util";

const v1AutopayResetQuestions = ["otherSourceOfIncome"];
const V1_AUTOPAY_CONFIG = ({ components = {}, formData }) => {
    const { previousAddress, currentAddressDuration, previousAddressDuration, relationshipStatus = "", lenderName, balloon } =  formData || {};
    let { currentEmployment = {}, otherSourceOfIncome = {}, homeExpensesShare = {}, personalLoans = {}, groceriesAndPetCareExpense = {}, mortgage = {} } = formData || {};
    otherSourceOfIncome = otherSourceOfIncome || {};
    currentEmployment = currentEmployment || {};
    homeExpensesShare = homeExpensesShare || {};
    personalLoans = personalLoans || {};
    mortgage = mortgage || {};
    groceriesAndPetCareExpense = groceriesAndPetCareExpense || {};
    const {questionLabel: privacyPolicyLabel, apiKey: privacyPolicyKey } = getFinanceLendersPrivacyDiscliamers(lenderName) || {};
    const currentAddressLessThanTwo = ((currentAddressDuration || {}).year < NUMBER.TWO);
    // eslint-disable-next-line no-magic-numbers
    return {
        formId: "v1_autopay",
        totalStages: 5,
        formTitle: `${lenderName} loan application`,
        readOnlyTitle: "Review your details",
        stages: [
            {
                name: "Additional personal details",
                isReadDisabled: false,
                intermediateScreenText: "Let’s add your personal details now",
                stageNo: 1,
                totalPages: 2,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { gender }  = formData || {};
                            return !!gender;
                        },
                        questions: [
                            {
                                questionLabel: "Your gender",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "gender",
                                    shortTitle: "Gender",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: GENDER_OPTIONS,
                                    placeholder: "Your gender",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: GENDER_OPTIONS,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Your relationship status",
                                questionHelperLabel: null,
                                helperText: "Filled based on your pre-approval form details",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "relationshipStatus",
                                    shortTitle: "Relationship status",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: RELATIONSHIP_STATUS_OPTIONS,
                                    placeholder: "Your relationship status",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: RELATIONSHIP_STATUS_OPTIONS,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            gender: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                            relationshipStatus: Yup.string().required("Enter valid input").nullable("Enter valid input")
                        }),
                        initialValues: {
                            gender: formData.gender,
                            relationshipStatus: formData.relationshipStatus
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            if (currentAddressLessThanTwo) return currentAddressDuration && previousAddressDuration;
                            return currentAddressDuration;
                        },
                        questions: [
                            {
                                questionLabel: "Duration of stay at current address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "currentAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address year${(currentAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("year", 0, NUMBER.THIRTY)
                                    }
                                }, {
                                    apiKey: "currentAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address month${(currentAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("month", 0, NUMBER.ELEVEN)
                                    }
                                }]
                            },
                            {
                                questionLabel: "Address of previous stay",
                                helperText: null,
                                isRequired: true,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "previousAddress",
                                    errorKey: "previousAddress.full",
                                    fieldType: components.locationPicker,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: true,
                                    props: {
                                        value: previousAddress
                                    }
                                }]
                            },
                            {
                                questionLabel: "Duration of stay at previous address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "previousAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address year${(previousAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "previousAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address month${(previousAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            currentAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                            }),
                            ...currentAddressLessThanTwo && ({
                                previousAddress: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    full: Yup.string().required("Enter valid input").nullable("Enter valid input")
                                }),
                                previousAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                    month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                                })
                            })
                        }),
                        initialValues: {
                            currentAddressDuration: formData.currentAddressDuration,
                            previousAddressDuration: formData.previousAddressDuration,
                            previousAddress: formData.previousAddress
                        }
                    }
                ],
                sidebarNavigation: {
                    key: "personalDetails",
                    title: "Personal details",
                    description: "Add personal details"
                }
            },
            {
                name: "Employment & Income",
                intermediateScreenText: "Let’s add your employment details now",
                isReadDisabled: false,
                stageNo: 2,
                totalPages: 3,
                isCompleted: () => {
                    const { annualGrossIncome  }  = formData || {};
                    const { income, frequency }  = annualGrossIncome || {};
                    return income && frequency;
                },
                sidebarNavigation: {
                    key: "employmentIncome",
                    title: "Employment details",
                    description: "Add income details"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { annualGrossIncome  }  = formData || {};
                            const { income, frequency }  = annualGrossIncome || {};
                            return income && frequency;
                        },
                        questions: [
                            {
                                questionLabel: "Your gross income",
                                questionHelperLabel: null,
                                helperText: "Your annual gross income shown based on your pre-approval form details, please edit if you wish to. ",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "annualGrossIncome.income",
                                    shortTitle: "Your gross income",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "annualGrossIncome.frequency",
                                    shortTitle: "Your gross income frequency",
                                    fieldType: components.radioButtonGroup,
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "Your partner's gross income",
                                questionHelperLabel: "Don't worry, your partner will not be added as a co-applicant! We only need this to allocate your expenses accurately.",
                                helperText: "Your partner's gross income shown based on your pre-approval form details, please edit if you wish to. ",
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partnerAnnualGrossIncome.income",
                                    shortTitle: "Partner's gross income",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "partnerAnnualGrossIncome.frequency",
                                    shortTitle: "Partner's gross income frequency",
                                    fieldType: components.radioButtonGroup,
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            annualGrossIncome: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                income: Yup.number().required(`Your income should be > 0`).min(1, "Your income should be > 0").nullable(),
                                frequency: Yup.string().required(`Enter valid input`).nullable()
                            })
                        }),
                        initialValues: {
                            annualGrossIncome: formData.annualGrossIncome,
                            partnerAnnualGrossIncome: formData.partnerAnnualGrossIncome
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            const { employer }  = currentEmployment || {};
                            return employer;
                        },
                        questions: [
                            {
                                questionLabel: "Current Employer",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "currentEmployment.employer",
                                    shortTitle: "Current employer",
                                    title: "Employer",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.inputText,
                                    options: [],
                                    placeholder: "Enter employer",
                                    props: {}
                                }, {
                                    apiKey: "currentEmployment.industry",
                                    shortTitle: "Current employer industry",
                                    title: "Employer industry",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.select,
                                    options: INDUSTRY_TYPE_OPTIONS,
                                    placeholder: "Select Industry",
                                    props: {
                                        data: INDUSTRY_TYPE_OPTIONS,
                                        isSearchable: true
                                    }
                                }, {

                                    apiKey: "currentEmployment.payDate",
                                    fieldType: components.datePicker,
                                    shortTitle: "Current empl. pay date",
                                    title: "Next pay date",
                                    readOnlyComponent: "dateType",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter your next pay date",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        startDate: new Date(),
                                        minDate: new Date(),
                                        dateFormat: "dd-MM-yyyy"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            currentEmployment: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                employer: Yup.string().required(`Enter valid input`).nullable(),
                                industry: Yup.string().required(`Enter valid input`).nullable(),
                                payDate: Yup.string().required(`Enter valid input`).nullable()
                            }).nullable("Enter valid input")
                        }),
                        initialValues: {
                            currentEmployment: formData.currentEmployment
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            return otherSourceOfIncome.isOpted === true || otherSourceOfIncome.isOpted === false;
                        },
                        questions: [
                            {
                                questionLabel: "Other sources of income, if any",
                                questionHelperLabel: "Please enter the monthly amount, if applicable",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherSourceOfIncome.isOpted",
                                    shortTitle: "Other income (Y/N)",
                                    title: "Do you have other sources of income?",
                                    isVisible: true,
                                    fieldType: components.listTabGroup,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "otherSourceOfIncome.governmentBenefits",
                                    shortTitle: "Gov. benefits income",
                                    title: "Government benefits",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            otherSourceOfIncome: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                isOpted: Yup.boolean().required("Enter valid input").nullable()
                            })
                        }),
                        initialValues: {
                            otherSourceOfIncome: formData.otherSourceOfIncome
                        }
                    }
                ]
            },
            {
                name: "Expenses",
                intermediateScreenText: "Let’s add your expenses now",
                isReadDisabled: false,
                stageNo: 3,
                totalPages: 3,
                isCompleted: () => {
                    return !!(homeExpensesShare.homeExpensesShare);
                },
                sidebarNavigation: {
                    key: "expenses",
                    title: "Expenses",
                    description: "Add your expenses"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            return !!(homeExpensesShare.homeExpensesShare);
                        },
                        questions: [ {
                            questionLabel: "What % of total household expenses do you contribute?",
                            questionHelperLabel: null,
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "homeExpensesShare.homeExpensesShare",
                                fieldType: components.select,
                                shortTitle: "% share in household income",
                                title: null,
                                isVisible: true,
                                options: HOUSEHOLD_EXPENSE_OPTIONS,
                                placeholder: "Enter your share of expenses",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: HOUSEHOLD_EXPENSE_OPTIONS,
                                    isSearchable: false
                                }
                            }]
                        },
                        {
                            questionLabel: "Enter share, if other",
                            questionHelperLabel: null,
                            helperText: null,
                            inFormNudge: null,
                            isRequired: false,
                            isVisible: homeExpensesShare.homeExpensesShare === "Other",
                            fieldStyle: null,
                            fields: [{
                                apiKey: "homeExpensesShare.otherShare",
                                shortTitle: "Other share in household income",
                                fieldType: components.inputNumber,
                                title: null,
                                isVisible: homeExpensesShare.homeExpensesShare === "Other",
                                options: [],
                                placeholder: "Enter number between 0 - 100",
                                isRequired: false,
                                priceType: false,
                                props: {
                                    type: "number"
                                }
                            }]
                        }, {
                            questionLabel: "Rent / boarding expense",
                            questionHelperLabel: "Total household expense, not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "rentAndBoardingExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Rent / boarding ",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "rentAndBoardingExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Rent / boarding exp. frequency ",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Communications",
                            questionHelperLabel: "Total household expense (incl. landline, mobile, internet), not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "communicationExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Communication",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "communicationExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Comm. exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Transportation",
                            questionHelperLabel: "Total household expense (incl. petrol, parking and transport) , not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "transportExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Transportation",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "transportExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Transport exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Utilities",
                            questionHelperLabel: "Total household expense (incl. power, water, gas), not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "utilitiesAndOtherExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Utilities",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "utilitiesAndOtherExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Utilities exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }
                        ],
                        formValidator: Yup.object().shape({
                            homeExpensesShare: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                homeExpensesShare: Yup.string().required("Enter valid input").nullable()
                                // otherShare: Yup.number().transform((value, originalValue) => !originalValue ? 0 : value).min(0, `Enter number between 0 - 100`).max(NUMBER.HUNDRED, `Enter number between 0 - 100`)
                            }),
                            rentAndBoardingExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter valid input").nullable(),
                                frequency: Yup.string().required("select frequency").nullable(),
                                shared: Yup.string("Enter valid input").nullable()
                            }),
                            communicationExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter valid input").nullable(),
                                frequency: Yup.string().required("select frequency").nullable()
                            }),
                            transportExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter valid input").nullable(),
                                frequency: Yup.string().required("select frequency").nullable()
                            }),
                            utilitiesAndOtherExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter valid input").nullable(),
                                frequency: Yup.string().required("select frequency").nullable()
                            })
                        }),
                        initialValues: {
                            homeExpensesShare: formData.homeExpensesShare,
                            rentAndBoardingExpense: formData.rentAndBoardingExpense,
                            communicationExpense: formData.communicationExpense,
                            transportExpense: formData.transportExpense,
                            utilitiesAndOtherExpense: formData.utilitiesAndOtherExpense
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            return !!(groceriesAndPetCareExpense.expense);
                        },
                        questions: [
                            {
                                questionLabel: "Food & groceries",
                                questionHelperLabel: "Total household expense, (incl. alcohol and cigarretes), not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "groceriesAndPetCareExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Food and groceries",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "groceriesAndPetCareExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Food exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Debt repayments",
                                questionHelperLabel: "Total household expense, not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "debtRepayments.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Debt repayment",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "debtRepayments.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Debt repayment frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Other household expenses",
                                questionHelperLabel: "Total household expense, not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherHouseHoldExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Other house exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherHouseHoldExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Other house exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            groceriesAndPetCareExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter valid input").nullable(),
                                frequency: Yup.string().required("select frequency").nullable()
                            })
                        }),
                        initialValues: {
                            groceriesAndPetCareExpense: formData.groceriesAndPetCareExpense,
                            debtRepayments: formData.debtRepayments,
                            otherHouseHoldExpense: formData.otherHouseHoldExpense
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            return true;
                        },
                        questions: [
                            {
                                questionLabel: "Health insurance & medical expenses",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "healthInsurance.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Health insurance / medical",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "healthInsurance.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Health insurance / medical exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Private education fees",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "privateEducation.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Private education",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "privateEducation.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Private education exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Other expenses",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Other exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Other exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({}),
                        initialValues: {
                            healthInsurance: formData.healthInsurance,
                            privateEducation: formData.privateEducation,
                            otherExpense: formData.otherExpense
                        }
                    }
                ]
            }, {
                name: "Liabilities",
                intermediateScreenText: "Let’s add your liabilities now",
                hideIntermediateScreen: true,
                isReadDisabled: false,
                stageNo: 4,
                totalPages: 1,
                sidebarNavigation: {
                    key: "liabilities",
                    title: "Liabilities",
                    description: "Add your liabilities"
                },
                jumpToNextStageNo: () => {
                    if (balloon) {
                        return NUMBER.FIVE;
                    }
                    return NUMBER.SIX;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { numberOfLoans } = personalLoans || {};
                            return typeof numberOfLoans === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many personal loans do you have?",
                                questionHelperLabel: "Include secured and unsecured loans",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.numberOfLoans",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Personal loans",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of personal loans",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# loans should be >=0",
                                        maxError: "# loans should not exceed 3"
                                    }
                                }]
                            }, {
                                questionLabel: "How many mortgages do you have?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.numberOfMortgage",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Mortgages",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of mortgages",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# mortgages should be >=0",
                                        maxError: "# mortgages should not exceed 3"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            personalLoans: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfLoans: Yup.number().min(0, "# loans should be >=0").max(NUMBER.THREE, "# loans should not exceed 3").required("Enter valid input").nullable(),
                                numberOfMortgage: Yup.number().min(0, "# mortgages should be >=0").max(NUMBER.THREE, "# mortgages should not exceed 3").nullable()
                            })
                        }),
                        initialValues: {
                            personalLoans: {
                                ...formData.personalLoans,
                                numberOfLoans: ((personalLoans && personalLoans.numberOfLoans) || 0)
                            },
                            mortgage: {
                                ...formData.mortgage,
                                numberOfMortgage: ((mortgage && mortgage.numberOfMortgage) || 0)
                            }
                        }

                    }
                ]
            }, {
                name: "Balloon Details",
                intermediateScreenText: "Let’s add your Balloon details now",
                stageNo: 5,
                isHidden: !balloon,
                isReadDisabled: !balloon,
                totalPages: 1,
                hideIntermediateScreen: true,
                sidebarNavigation: {
                    key: "balloonDetails",
                    title: "Balloon Details",
                    description: "Add balloon details",
                    isHidden: !balloon
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isHidden: !balloon,
                        isCompleted: () => {
                            const { balloonReason, balloonRepaymentMethod }  = formData || {};
                            return !!balloonReason && !!balloonRepaymentMethod;
                        },
                        questions: [
                            {
                                questionLabel: "Why did you opt for balloon payment in your loan?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonReason",
                                    shortTitle: "Balloon reason",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REASONS_OPTIONS,
                                    placeholder: "Select reason",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REASONS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "What will likely be your method of repayment when balloon is due?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonRepaymentMethod",
                                    shortTitle: "Balloon repayment method",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                    placeholder: "Select method",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            balloonReason: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                            balloonRepaymentMethod: Yup.string().required("Enter valid input").nullable("Enter valid input")
                        }),
                        initialValues: {
                            balloonReason: formData.balloonReason,
                            balloonRepaymentMethod: formData.balloonRepaymentMethod
                        }
                    }
                ]
            }, {
                name: "Declaration & Signature",
                hideIntermediateScreen: true,
                isReadDisabled: true,
                stageNo: 6,
                totalPages: 1,
                isCompleted: () => {
                    const { declarationForInformation } = formData || {};
                    return declarationForInformation;
                },
                sidebarNavigation: {
                    key: "declaration",
                    title: "Declaration",
                    description: "Add your signature"
                },
                jumpToPrevStageNo: () => {
                    if (balloon) {
                        return NUMBER.FIVE;
                    }
                    return NUMBER.FOUR;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { declarationForInformation } = formData || {};
                            return declarationForInformation;
                        },
                        questions: [
                            {
                                questionLabel: "I declare that the information I have provided is correct to the best of my knowledge",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInformation",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            },
                            {
                                questionLabel: privacyPolicyLabel,
                                setHtml: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: privacyPolicyKey,
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I agree that Cars24 may collect, use and disclose my personal information as set out by the privacy policy, including requesting access to my information from credit reporting bodies",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForPersonalDataUses",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I consent to receive a call from CARS24 to discuss insurance and get a no-obligation quote (optional)",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInsuranceCall",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "Name",
                                questionHelperLabel: null,
                                questionStyle: "styles.smallQuestionDark",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "name",
                                    fieldType: components.inputText,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: null,
                                questionHelperLabel: null,
                                questionStyle: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "signatureDateTime",
                                    fieldType: components.staticDate,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        startDate: new Date(),
                                        minDate: new Date(),
                                        dateFormat: "dd-MM-yyyy",
                                        value: new Date()
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            declarationForInformation: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            [privacyPolicyKey]: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            declarationForPersonalDataUses: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            name: Yup.string().required("Enter valid input").nullable()
                        }),
                        initialValues: {
                            declarationForInformation: formData.declarationForInformation,
                            [privacyPolicyKey]: formData[privacyPolicyKey],
                            declarationForPersonalDataUses: formData.declarationForPersonalDataUses,
                            declarationForInsuranceCall: formData.declarationForInsuranceCall,
                            name: formData.name,
                            signatureDateTime: formData.signatureDateTime
                        }
                    }
                ]
            }
        ]
    };
};

export {
    V1_AUTOPAY_CONFIG,
    v1AutopayResetQuestions
};
