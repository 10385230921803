import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import ReviewYourPurchaseCard from "../review-your-purchase-card/component";

import styles from "./styles.css";

import { getVASItems, removeVASItem, setAddonScreenType, setCounterAnimation } from "../actions";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import { SCREEN_TYPE } from "../reducers";
import {  trackMobileCustomEventsAU } from "../../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
import { NUMBER, SERVICING_VARIANT_MAPPING } from "../../../../constants/app-constants";
import { filterExcludedItems } from "../../../../utils/helpers/checkout-util";

const ReviewYourPurchase = ({
    appointmentId,
    egc,
    getVASItemsConnect = () => { },
    orderId,
    removeKey,
    removeLoading,
    vasCart = {},
    removeVASItemConnect = () => { },
    setAddonScreenTypeConnect = () => { },
    setCounterAnimationConnect = () => { },
    year,
    servicingPriceConfig
}) => {
    const { data } = vasCart || {};
    const { items = [] } = data || {};
    const isNewServicingVariant = servicingPriceConfig?.data?.experiment === SERVICING_VARIANT_MAPPING.VARIANT;
    const filteredItems = isNewServicingVariant ? filterExcludedItems(items) : items;
    const onViewVasStore = async () => {
        await getVASItemsConnect("VAS_POPULAR_BUNDLES", year, egc);
        setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.VAS_STORE });
    };

    useEffect(() => {
        window.setTimeout(() => {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                eventAction: "Cart page_landed",
                eventLabel: "Yes"
            });
        }, 0);
    }, []);

    return (
        <div styleName={"styles.reviewOuter"}>
            <p styleName={"styles.reviewHeading"}>Review your add-ons purchase</p>
            {filteredItems.map((item => {
                const { key, type, name, discountedPrice, iconUrl, category } = item || {};

                const onRemoveClick = async () => {
                    trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                        eventAction: "Cart page_remove_item",
                        eventLabel: name
                    });
                    await removeVASItemConnect({ appointmentId, key, type, orderId });
                    if (filteredItems.length > NUMBER.ONE) {
                        setCounterAnimationConnect(true);
                    }
                };

                const loading = removeLoading && key === removeKey;

                return (
                    <ReviewYourPurchaseCard category={category} type={type} loading={loading} key={key} onRemove={onRemoveClick} carImage={iconUrl} reviewCardHeading={name} reviewAmount={makePriceLabelRound(discountedPrice)} />
                );
            }))}

            <div styleName={"styles.addSomething"}>
                <span>Want to add more add-ons?</span>
                <button onClick={onViewVasStore}>Add</button>
            </div>
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content: {
            appointmentId,
            egc,
            year
        }
    },
    checkout: {
        order: {
            orderId
        }
    },
    addOns: {
        vasCart,
        removeVASItem: {
            loading: removeLoading,
            key: removeKey
        },
        servicingPriceConfig
    }
}) => ({
    appointmentId,
    egc,
    orderId,
    removeKey,
    removeLoading,
    vasCart,
    year,
    servicingPriceConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getVASItemsConnect: getVASItems,
    removeVASItemConnect: removeVASItem,
    setAddonScreenTypeConnect: setAddonScreenType,
    setCounterAnimationConnect: setCounterAnimation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewYourPurchase);

ReviewYourPurchase.propTypes = {
    activeStore: PropTypes.string,
    appointmentId: PropTypes.string,
    egc: PropTypes.number,
    getVASItemsConnect: PropTypes.func,
    orderId: PropTypes.string,
    removeKey: PropTypes.string,
    removeLoading: PropTypes.bool,
    removeVASItemConnect: PropTypes.func,
    setAddonScreenTypeConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    vasCart: PropTypes.object,
    year: PropTypes.number,
    servicingPriceConfig: PropTypes.object
};
