import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./component";
import { logout } from "../../au.configuration/actions";
import { resetWishlist } from "../my-wishlist/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import { clearAllFilters } from "../filters/actions";
import { setTradeInToggleSelected } from "../trade-in-your-car-wrapper/actions";

const mapStateToProps = ({
    user: { profileName, firstName, seoMenu, email},
    location: { selectedCity: { code: selectedCityCode, name: selectedCityName } = {} },
    preApprovalLoan: {preApproved},
    tradeIn: {userTradeInData}
}) => ({
    profileName,
    firstName,
    email,
    selectedCityCode,
    selectedCityName,
    seoMenu,
    preApproved,
    userTradeInData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logoutConnect: logout,
    resetWishlistConnect: resetWishlist,
    reloadCarListConnect: reloadCarList,
    resetCarListConnect: resetCarList,
    clearAllFiltersConnect: clearAllFilters,
    setTradeInToggleSelectedConnect: setTradeInToggleSelected
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
