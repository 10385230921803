import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCheckoutPageURL, getTestDriveV2CheckoutURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import {
    setCheckoutEditCompletedStages,
    setCheckoutEditFormData,
    setCheckoutEditFormExitModal,
    setCheckoutEditMode,
    setCheckoutEditQuestionsUpdateModal,
    setLoanOfferScreen,
    setZeroDpSelectOfferCoachMark
} from "../checkout-info/actions";
import { isEqual } from "lodash";
import { LOAN_OFFER_SCREENS } from "../../../constants/loan-offers";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import { testDriveV2Routes } from "../../../constants/au.desktop/test-drive-v2-routes";

export default function withEditCheckout(Component) {
    const CheckoutEditMode = (props) => {
        const {
            checkoutEditFormData,
            content,
            history,
            readOnlyFinanceData,
            financeErrorResponse,
            setCheckoutEditFormDataConnect,
            setCheckoutEditCompletedStagesConnect,
            setCheckoutEditQuestionsUpdateModalConnect,
            setCheckoutEditFormExitModalConnect,
            setCheckoutEditModeConnect,
            setLoanOfferScreenConnect,
            setZeroDpSelectOfferCoachMarkConnect,
            preApporvedOrigin
        } = props || {};

        const resetCheckoutEditData = () => {
            setCheckoutEditFormDataConnect(readOnlyFinanceData);
            setCheckoutEditModeConnect(false);
            setCheckoutEditQuestionsUpdateModalConnect(false, false);
            setCheckoutEditFormExitModalConnect(false);
            setCheckoutEditCompletedStagesConnect([]);
            if (financeErrorResponse && financeErrorResponse.errorCode) {
                setLoanOfferScreenConnect(LOAN_OFFER_SCREENS.ERROR_SCREEN);
                setZeroDpSelectOfferCoachMarkConnect(false);
            } else setLoanOfferScreenConnect(LOAN_OFFER_SCREENS.LOAN_OFFERS);
        };

        const resetEditMode = () => {
            resetCheckoutEditData();
            const isTDflow = preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE;
            let selectOfferUrl = getCheckoutPageURL(content, checkoutRoutes.selectOffer.route);
            if (isTDflow) {
                selectOfferUrl = getTestDriveV2CheckoutURL(content, testDriveV2Routes.selectOffer.route);
            }
            history.push(selectOfferUrl?.relativeURL);
        };

        const onFormExit = () => {
            if (isEqual(readOnlyFinanceData, checkoutEditFormData)) {
                resetEditMode();
            } else {
                setCheckoutEditFormExitModalConnect(true);
            }
        };

        const onEditStageComplete = (stages = []) => {
            setCheckoutEditCompletedStagesConnect(stages);
        };

        return (
            <Component
                {...props}
                resetCheckoutEditData={resetCheckoutEditData}
                resetEditMode={resetEditMode}
                onFormExit={onFormExit}
                onEditStageComplete={onEditStageComplete}
            />
        );
    };

    const mapStateToProps = ({
        carDetails: { content },
        checkout: { checkoutEditCompletedStages, readOnlyFinanceData, checkoutEditFormData, financeErrorResponse },
        preApprovalLoan: {preApporvedOrigin}
    }) => ({
        content,
        checkoutEditCompletedStages,
        checkoutEditFormData,
        readOnlyFinanceData,
        financeErrorResponse,
        preApporvedOrigin
    });

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                setCheckoutEditFormDataConnect: setCheckoutEditFormData,
                setCheckoutEditFormExitModalConnect: setCheckoutEditFormExitModal,
                setCheckoutEditQuestionsUpdateModalConnect: setCheckoutEditQuestionsUpdateModal,
                setCheckoutEditModeConnect: setCheckoutEditMode,
                setCheckoutEditCompletedStagesConnect: setCheckoutEditCompletedStages,
                setLoanOfferScreenConnect: setLoanOfferScreen,
                setZeroDpSelectOfferCoachMarkConnect: setZeroDpSelectOfferCoachMark
            },
            dispatch
        );

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutEditMode));
}
