import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const FinancePills = ({ image, text, color, isImage, onClick = () => {} }) => {
    const toggleColor = () => {
        switch (color) {
        case "blue":
            return "styles.blueOuter";
        case "green":
            return "styles.greenOuter";
        default:
            return "styles.outer";
        }
    };
    return (
        <div styleName={toggleColor()} onClick={onClick}>
            <div styleName={"styles.circle"}>
                {isImage ? <img src={image} /> : image}
            </div>
            <p styleName={"styles.text"}>{text}</p>
        </div>
    );
};

FinancePills.propTypes = {
    image: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    isImage: PropTypes.bool
};

export default FinancePills;
