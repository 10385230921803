import { Helmet } from "react-helmet";
import React from "react";
import { appUrl, appUrlTh, getLogoURL } from "../../../constants/url-constants";
import faqSchema from "./faqSchema.json";

const HomeMeta = () => {
    const ldDetails = JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Cars24 Australia",
        "url": `${appUrl()}/`,
        "logo": getLogoURL(),
        "sameAs": [
            "https://www.facebook.com/CARS24Australia/",
            "https://au.linkedin.com/company/cars24-australia",
            "https://instagram.com/cars24au/",
            "https://www.youtube.com/channel/UCtJQy3cbuWRYPZAKyqHWVNA" ],
        "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+61488825496",
            "contactType": "Sales/customer support",
            "areaServed": "AU"
        }]
    });
    const siteLinkSearchSchema = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "CARS24 AU",
        "url": `${appUrl()}/`,
        "potentialAction": [{
            "@type": "SearchAction",
            "target": `${appUrl()}/buy-used-cars-australia?search={search_term_string}`,
            "query-input": `required name=search_term_string`
        }]
    });
    const title = "CARS24 Australia- Buy & Sell Used Cars Online | Second Hand Cars for Sale in Australia";
    const description = `Buy, Trade or Sell Top Quality Used Cars, 100% Online with CARS24. Delivered to your door in 7 days or less. 7 day returns with a 100% money back guarantee.`;
    return (
        <Helmet  script={[
            {
                type: "application/ld+json",
                innerHTML: ldDetails
            },
            {  type: "application/ld+json",
                innerHTML: siteLinkSearchSchema
            },
            {  type: "application/ld+json",
                innerHTML: JSON.stringify(faqSchema)

            }]}>
            <title>{title}</title>
            <meta name="description"
                content={description} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="Cars24 - Australia" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={getLogoURL()}/>
            <meta name="twitter:image:alt" content="Logo image"/>
            <meta name="og:title" content={title}/>
            <meta name="og:description" content={description}/>
            <meta name="og:image" content={getLogoURL()}/>
            <meta name="og:url" content={`${appUrl()}/`} />
            <meta name="og:site_name" content="Cars24 Australia"/>
            <meta name="og:locale" content="en_AU"/>
            <meta name="og:type" content="website"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <link rel="alternate" href={`${appUrl()}`} hrefLang="x-default" />
            <link rel="alternate" href={`${appUrl()}/`} hrefLang="en-IN" />
            <link rel="alternate" href={`${appUrl()}/`} hrefLang="en-AU" />
            <link rel="alternate" href={`${appUrl()}/ae/`} hrefLang="en-AE" />
            <link rel="alternate" href={`${appUrlTh()}`} hrefLang="th-TH" />
            <link rel="alternate" href={`${appUrlTh()}/en/`} hrefLang="en-TH"/>
            <link rel="canonical" href={`${appUrl()}/`} />
        </Helmet>
    );
};

export default HomeMeta;
