import { createTypes } from "reduxsauce";

export default createTypes(
    `
    POPULATE_CHECKOUT_PERSONAL_DATA
    
    SET_USER_PERSONAL_DETAILS
    SET_DELIVERY_MODE
    SET_FINANCE_PERSONAL_DETAILS
    
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    SET_DELIVERY_DATA
    SET_FINANCE_SCREEN
    SET_FINANCE_DATA
    SET_LOAN_DETAILS
    SET_LOAN_TYPE

    UPDATE_DELIVERY_MODE_SUCCESS
    UPDATE_DELIVERY_MODE_FAILURE

    CREATE_ORDER_REQUEST
    CREATE_ORDER_SUCCESS
    CREATE_ORDER_FAILURE

    GET_COLD_LEAD_REQUEST
    GET_COLD_LEAD_SUCCESS
    GET_COLD_LEAD_FAILURE

    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE
    GET_ORDER_DETAIL_INIT

    SET_MAX_STEP

    INITIATE_ORDER_PAYMENT_REQUEST

    SOFT_RESET_CHECKOUT
    GET_PAYMENT_STATUS

    GET_LOAN_OFFER_REQUEST
    GET_LOAN_OFFER_SUCCESS
    GET_LOAN_OFFER_FAILURE

    CREATE_LOAN_OFFER_REQUEST
    CREATE_LOAN_OFFER_SUCCESS
    CREATE_LOAN_OFFER_FAILURE

    UPDATE_LOAN_OFFER_REQUEST
    UPDATE_LOAN_OFFER_SUCCESS
    UPDATE_LOAN_OFFER_FAILURE

    UPDATE_FINANCE_DETAILS
    UPDATE_FINANCE_DETAILS_SUCCESS
    UPDATE_FINANCE_DETAILS_FAILURE

    SET_RECALCULATE_TRIGGERED

    FETCH_EGC_PRICE
    FETCH_EGC_PRICE_SUCCESS
    FETCH_EGC_PRICE_FAILURE
    GET_TRADE_IN_REQUEST
    GET_TRADE_IN_SUCCESS
    GET_TRADE_IN_FAILURE

    CREATE_TRADE_IN_REQUEST
    CREATE_TRADE_IN_SUCCESS
    CREATE_TRADE_IN_FAILURE

    UPDATE_TRADE_IN_REQUEST
    UPDATE_TRADE_IN_SUCCESS
    UPDATE_TRADE_IN_FAILURE

    SET_TRADE_OPTION
    
    GET_TRADE_IN_STATE_REQUEST
    GET_TRADE_IN_STATE_SUCCESS
    GET_TRADE_IN_STATE_FAILURE

    SAVE_CHECKOUT_SOURCE_PATHNAME

    GET_BYO_LOAN_INIT
    GET_BYO_LOAN_SUCCESS
    GET_BYO_LOAN_FAILURE
    UPDATE_BYO_LOAN_INIT
    UPDATE_BYO_LOAN_SUCCESS
    UPDATE_BYO_LOAN_FAILURE
    SET_SPLIT_PAYMENT

    UPDATE_SPLIT_PAYMENT
    UPDATE_SPLIT_PAYMENT_SUCCESS
    UPDATE_SPLIT_PAYMENT_FAILURE

    SET_SPLIT_PAYMENT_USER_STATE

    GET_PAYMENT_HISTORY_SUCCESS
    GET_PAYMENT_HISTORY_FAILURE
    GET_PAYMENT_HISTORY_INIT
    SAVE_TRADE_IN_LOCALSTATE

    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE
    GET_DELIVERY_INFO_INIT

    SET_USER_UPDATING
    HANDLE_REJECT_MODAL
    CLOSE_REJECT_MODAL

    GET_TRADE_IN_VEHICLE_REQUEST
    GET_TRADE_IN_VEHICLE_SUCCESS
    GET_TRADE_IN_VEHICLE_FAILURE


    SAVE_CAR_REGNO_VIN

    GET_TRADE_IN_CAR_DETAILS
    GET_TRADE_IN_CAR_DETAILS_SUCCESS
    GET_TRADE_IN_CAR_DETAILS_FAILURE

    GET_TRADE_IN_CAR_VARIANT
    GET_TRADE_IN_CAR_VARIANT_SUCCESS
    GET_TRADE_IN_CAR_VARIANT_FAILURE

    UPDATE_CAR_VARIANT
    UPDATE_CAR_VARIANT_SUCCESS
    UPDATE_CAR_VARIANT_FAILURE

    SAVE_CAR_REGNO_VIN
    SAVE_TRADE_IN_ID
    RESET_CAR_TRADE_IN_DETAILS

    SAVE_SELECTED_CITY

    FETCH_SEO_CONTENT
    FETCH_SEO_CONTENT_SUCCESS
    FETCH_SEO_CONTENT_FAILURE

    GET_ORDER_REQUEST
    GET_ORDER_SUCCESS
    GET_ORDER_FAILURE

    SET__MAKE_MODEL_MAX_STEP
    SET__MAKE_MODEL_CURRENT_STEP
    SET__MAKE_MODEL_HARD_REJECTION
    SET__MAKE_MODEL_RECHEDULE_FLAG
    SET_MAKE_MODEL_YEAR
    SET_MMVY_USER_INPUTS
    UPDATE_MAKE_MODEL_REQUEST

    OPEN_GUEST_LOGIN
    CLOSE_GUEST_LOGIN

    SET_REDIRECTION_DATA
`,
    {
        prefix: "c2b-trade/"
    }
);
