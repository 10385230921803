import { NUMBER } from "../constants/app-constants";
import { PENSION, UNEMPLOYED } from "../constants/checkout-constants";

const DRIVA_MIN_AMOUNT = 500;
const ZERO_DP_MINIMUM_DEPOSIT = 0;
const ZERO_DP_MINIMUM_SLIDER_STEP_COUNT = 500;
const DRIVA_MIN_LOAN_AMOUNT_OFFERED = NUMBER.FIVE_THOUSAND + DRIVA_MIN_AMOUNT;
const ZERO_DP_MIN_LOAN_AMOUNT_OFFERED = NUMBER.FIVE_THOUSAND + ZERO_DP_MINIMUM_DEPOSIT;
const DISABLE_EMPLOYMENT_DURATION_QUESTIONS_TYPE = [UNEMPLOYED, PENSION];

export {
    DRIVA_MIN_AMOUNT,
    DRIVA_MIN_LOAN_AMOUNT_OFFERED,
    DISABLE_EMPLOYMENT_DURATION_QUESTIONS_TYPE,
    ZERO_DP_MIN_LOAN_AMOUNT_OFFERED,
    ZERO_DP_MINIMUM_DEPOSIT,
    ZERO_DP_MINIMUM_SLIDER_STEP_COUNT
};
