import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cars24PlatinumCover from "./component";

const mapStateToProps = ({
    addOns: {
        vasCart: {
            data: vasCartData
        },
        servicingPriceConfig,
        carCoverBundles: {
            data: { carCoverDetails, carCoverTitle } = {}
        }
    }
}) => ({
    vasCartData,
    servicingPriceConfig,
    carCoverDetails,
    carCoverTitle
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cars24PlatinumCover);
