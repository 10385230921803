import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PriceBreakupPaymentTerms from "./component";

const mapStateToProps = ({
    checkout: {
        financeData: {
            loan: {
                amount,
                deposit
            },
            selectedQuote
        },
        order: {
            financeOpted,
            financeProvider,
            chargeDetails
        }
    }
}) => ({
    amount,
    deposit,
    financeOpted,
    financeProvider,
    chargeDetails,
    selectedQuote
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceBreakupPaymentTerms);
