/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import Arrow from "./images/right-arrow.svg";
import GreyArrow from "./images/arrow-grey.svg";
import DarkGreyArrow from "./images/dark-grey.svg";
import BlueArrow from "./images/blue-arrow.svg";
import DarkBlueArrow from "./images/arrow-dark-blue.svg";
import WhiteArrow from "./images/white-arrow.svg";
import BlackArrow from "./images/arrow-black.svg";
import GreenArrow from "./images/arrow-green.svg";
import YellowArrow from "./images/arrow-yellow.svg";
import LightBlueArrow from "./images/light-blue.svg";

// eslint-disable-next-line complexity
const ArrowIcon = ({ yellow, grey, DarkGrey, blue, white, black, green, lightBlue, rotateBy, width, height, arrowColor, darkBlue, ...props }) => {
    const transform = typeof rotateBy === "string" ? rotateBy : `rotate(${rotateBy}deg)`;
    const style = {
        width,
        height,
        transform,
        transition: "all 0.2s ease-in-out 0s"
    };

    let icon = Arrow;
    if (grey) {
        icon = GreyArrow;
    }
    if (DarkGrey) {
        icon = DarkGreyArrow;
    }
    if (blue) {
        icon = BlueArrow;
    }
    if (yellow) {
        icon = YellowArrow;
    }
    if (white) {
        icon = WhiteArrow;
    }
    if (black) {
        icon = BlackArrow;
    }
    if (green) {
        icon = GreenArrow;
    }
    if (lightBlue) {
        icon = LightBlueArrow;
    }
    if (darkBlue) {
        icon = DarkBlueArrow;
    }
    // TODO: change arrow color to accept string
    if (arrowColor === "white") {
        icon = WhiteArrow;
    }
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <img onClick={handleClick} src={icon} style={style} alt="arrow icon" {...props} />
    );
};

ArrowIcon.propTypes = {
    rotateBy: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    grey: PropTypes.string,
    onClick: PropTypes.func,
    blue: PropTypes.string,
    white: PropTypes.string,
    black: PropTypes.string,
    green: PropTypes.string,
    yellow: PropTypes.string,
    arrowColor: PropTypes.string,
    lightBlue: PropTypes.string,
    darkBlue: PropTypes.string,
    DarkGrey: PropTypes.string
};

export default ArrowIcon;
