import { createTypes } from "reduxsauce";

export default createTypes(
    `
    POPULATE_CHECKOUT_PERSONAL_DATA
    POPULATE_EMAIL_FROM_TOKEN
    
    SET_USER_PERSONAL_DETAILS
    SET_DELIVERY_MODE
    SET_FINANCE_PERSONAL_DETAILS
    SET_FINANCE_SELECTED_CYCLE
    SET_POST_FINANCE_SCREEN
    
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    SET_DELIVERY_DATA
    SET_FINANCE_SCREEN
    SET_FINANCE_DATA
    SET_LOAN_DETAILS
    SET_LOAN_TYPE

    UPDATE_DELIVERY_MODE_SUCCESS
    UPDATE_DELIVERY_MODE_FAILURE

    CREATE_ORDER_REQUEST
    CREATE_ORDER_SUCCESS
    CREATE_ORDER_FAILURE

    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE
    GET_ORDER_DETAIL_INIT

    SET_MAX_STEP

    INITIATE_ORDER_PAYMENT_REQUEST

    SOFT_RESET_CHECKOUT
    GET_PAYMENT_STATUS

    GET_LOAN_OFFER_REQUEST
    GET_LOAN_OFFER_SUCCESS
    GET_EMPTY_LOAN_OFFER_SUCCESS
    GET_LOAN_OFFER_FAILURE

    CREATE_LOAN_OFFER_REQUEST
    CREATE_LOAN_OFFER_SUCCESS
    CREATE_LOAN_OFFER_FAILURE

    UPDATE_LOAN_OFFER_REQUEST
    UPDATE_LOAN_OFFER_SUCCESS
    UPDATE_LOAN_OFFER_FAILURE

    UPDATE_FINANCE_DETAILS
    UPDATE_FINANCE_DETAILS_SUCCESS
    UPDATE_FINANCE_DETAILS_FAILURE

    SET_RECALCULATE_TRIGGERED

    FETCH_EGC_PRICE
    FETCH_EGC_PRICE_SUCCESS
    FETCH_EGC_PRICE_FAILURE
    GET_TRADE_IN_REQUEST
    GET_TRADE_IN_SUCCESS
    GET_TRADE_IN_FAILURE


    CREATE_TRADE_IN_REQUEST
    CREATE_TRADE_IN_SUCCESS
    CREATE_TRADE_IN_FAILURE

    UPDATE_TRADE_IN_REQUEST
    UPDATE_TRADE_IN_SUCCESS
    UPDATE_TRADE_IN_FAILURE

    SET_TRADE_OPTION

    SET_LOAN_OFFER_SCREEN_LOADER
    
    GET_TRADE_IN_STATE_REQUEST
    GET_TRADE_IN_STATE_SUCCESS
    GET_TRADE_IN_STATE_FAILURE

    GET_TRADE_IN_CAR_DETAILS
    GET_TRADE_IN_CAR_DETAILS_SUCCESS
    GET_TRADE_IN_CAR_DETAILS_FAILURE

    GET_TRADE_IN_CAR_VARIANT
    GET_TRADE_IN_CAR_VARIANT_SUCCESS
    GET_TRADE_IN_CAR_VARIANT_FAILURE

    SAVE_CAR_REGNO_VIN
    SAVE_TRADE_IN_ID
    RESET_CAR_TRADE_IN_DETAILS

    SAVE_CHECKOUT_SOURCE_PATHNAME

    GET_BYO_LOAN_INIT
    GET_BYO_LOAN_SUCCESS
    GET_BYO_LOAN_FAILURE
    UPDATE_BYO_LOAN_INIT
    UPDATE_BYO_LOAN_SUCCESS
    UPDATE_BYO_LOAN_FAILURE
    SET_SPLIT_PAYMENT

    UPDATE_SPLIT_PAYMENT
    UPDATE_SPLIT_PAYMENT_SUCCESS
    UPDATE_SPLIT_PAYMENT_FAILURE

    SET_SPLIT_PAYMENT_USER_STATE

    GET_PAYMENT_HISTORY_SUCCESS
    GET_PAYMENT_HISTORY_FAILURE
    GET_PAYMENT_HISTORY_INIT

    GET_SIGN_CONTRACT_INIT
    GET_SIGN_CONTRACT_SUCCESS
    GET_SIGN_CONTRACT_FAILURE

    POST_SIGN_CONTRACT_INIT
    POST_SIGN_CONTRACT_SUCCESS
    POST_SIGN_CONTRACT_FAILURE
    SIGN_CONTRACT_POLL_STATUS

    PAYMENT_INIT
    SESSION_INIT
    
    SET_CURRENT_DELIVERY_STEP

    SET_CHECKOUT_EDIT_COMPLETED_STAGES
    SET_CHECKOUT_EDIT_FORM_EXIT_MODAL
    SET_CHECKOUT_EDIT_QUESTIONS_UPDATE_MODAL
    SET_CHECKOUT_EDIT_FORM_DATA

    GET_TRADE_IN_VEHICLE_REQUEST
    GET_TRADE_IN_VEHICLE_SUCCESS
    GET_TRADE_IN_VEHICLE_FAILURE

    UPDATE_BASIC_HUBSPOT_INIT
    UPDATE_BASIC_HUBSPOT_SUCCESS
    UPDATE_BASIC_HUBSPOT_FAILURE
    
    GET_CHECKOUT_STEPS_INIT
    GET_CHECKOUT_STEPS_SUCCESS
    GET_CHECKOUT_STEPS_FAILURE

    CREATE_CHECKOUT_STEPS_REQUEST
    CREATE_CHECKOUT_STEPS_SUCCESS
    CREATE_CHECKOUT_STEPS_FAILURE

    UPDATE_CHECKOUT_STEPS_REQUEST
    UPDATE_CHECKOUT_STEPS_SUCCESS
    UPDATE_CHECKOUT_STEPS_FAILURE

    GET_PROMO_CODE_REQUEST
    GET_PROMO_CODE_SUCCESS
    GET_PROMO_CODE_FAILURE


    POST_PROMO_CODE_REQUEST
    POST_PROMO_CODE_SUCCESS
    POST_PROMO_CODE_FAILURE
    
    RESET_POST_PROMO_CODE

    REMOVE_PROMO_CODE_REQUEST
    REMOVE_PROMO_CODE_SUCCESS
    REMOVE_PROMO_CODE_FAILURE

    RESET_DRIVA_FINANCE_DATA
    GET_WARRANTY_PLANS_REQUEST
    GET_WARRANTY_PLANS_SUCCESS
    GET_WARRANTY_PLANS_FAILURE
    UPDATE_WARRANTY_PLANS_REQUEST
    UPDATE_WARRANTY_PLANS_SUCCESS
    UPDATE_WARRANTY_PLANS_FAILURE

    GET_TEST_DRIVE_INIT
    GET_TEST_DRIVE_SUCCESS
    GET_TEST_DRIVE_FAILURE

    GET_TEST_DRIVE_STEPS_INIT
    GET_TEST_DRIVE_STEPS_SUCCESS
    GET_TEST_DRIVE_STEPS_FAILURE
    CREATE_TEST_DRIVE_STEPS_REQUEST
    CREATE_TEST_DRIVE_STEPS_SUCCESS
    CREATE_TEST_DRIVE_STEPS_FAILURE
    UPDATE_TEST_DRIVE_STEPS_REQUEST
    UPDATE_TEST_DRIVE_STEPS_SUCCESS
    UPDATE_TEST_DRIVE_STEPS_FAILURE
   
    UPDATE_TEST_DRIVE_DETAILS

    GET_BOOKINGS_SUMMARY_INIT
    GET_BOOKINGS_SUMMARY_SUCCESS
    GET_BOOKINGS_SUMMARY_FAILURE

    UPDATE_ORDER_REQUEST
    UPDATE_ORDER_SUCCESS
    UPDATE_ORDER_FAILURE

    GET_TEST_DRIVE_DATA_INIT
    GET_TEST_DRIVE_DATA_SUCCESS
    GET_TEST_DRIVE_DATA_FAILURE
    CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_REQUEST
    CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_SUCCESS
    CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_FAILURE

    GET_VALID_QUOTES_HOUSE_FINANCE_REQUEST
    GET_VALID_QUOTES_HOUSE_FINANCE_SUCCESS
    GET_VALID_QUOTES_HOUSE_FINANCE_FAILURE

    GET_VERIFICATION_STATUS_INIT
    GET_VERIFICATION_STATUS_SUCCESS
    GET_VERIFICATION_STATUS_FAILURE

    GET_VERIFICATION_ID_STATUS

    REGISTER_VERIFICATION_STATUS_INIT
    REGISTER_VERIFICATION_STATUS_SUCCESS
    REGISTER_VERIFICATION_STATUS_FAILURE

    CREATE_TEST_DRIVE_AVAILABILITY_REQUEST
    CREATE_TEST_DRIVE_AVAILABILITY_SUCCESS
    CREATE_TEST_DRIVE_AVAILABILITY_FAILURE

    SET_SELECTED_QUOTE

    SET_LOAN_OFFER_SCREENS

    REQUESTED_FOR_WARRANRY_CALLBACK

    POST_EVENT_IMPRESSION_SOURCE_INIT
    POST_EVENT_IMPRESSION_SOURCE_SUCCESS
    POST_EVENT_IMPRESSION_SOURCE_FAILURE

    FETCH_TRADE_IN_CANCELLATION_REASON
    FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS
    FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE

    FETCH_CHECKOUT_CALLBACK_REQUEST_INIT
    FETCH_CHECKOUT_CALLBACK_REQUEST_SUCCESS
    FETCH_CHECKOUT_CALLBACK_REQUEST_FAILURE

    CHECKOUT_CALLBACK_REQUEST_INIT
    CHECKOUT_CALLBACK_REQUEST_SUCCESS
    BYOF_CALLBACK_REQUEST_SUCCESS
    CHECKOUT_CALLBACK_REQUEST_FAILURE

    SET_ZERO_DP_SELECT_OFFER_COACHMARK

    POST_ZERO_DP_PAYMENT_API_INIT
    POST_ZERO_DP_PAYMENT_API_SUCCESS
    POST_ZERO_DP_PAYMENT_API_FAILURE

    UPLOAD_BANK_TRANSFER_FILE_REQUEST
    UPLOAD_BANK_TRANSFER_FILE_SUCCESS
    UPLOAD_BANK_TRANSFER_FILE_FAILURE

    GET_LOAN_OFFER_FAQ_REQUEST
    GET_LOAN_OFFER_FAQ_SUCCESS
    GET_LOAN_OFFER_FAQ_FAILURE

    GET_ZERO_DP_TEXT_DATA_CONFIG_INIT
    GET_ZERO_DP_TEXT_DATA_CONFIG_SUCCESS
    GET_ZERO_DP_TEXT_DATA_CONFIG_FAILURE
    UPLOAD_BANK_TRANSFER_FILE_FAILURE,
    RESET_CHECKOUT_FINANCE_DATA
    SET_TRADEIN_VARIANT_LIST


    SET_CHECKOUT_EDIT_MODE
    SET__MAKE_MODEL_MAX_STEP
    SET__MAKE_MODEL_CURRENT_STEP
    SET__MAKE_MODEL_HARD_REJECTION
    SET__MAKE_MODEL_RECHEDULE_FLAG
    SET_MAKE_MODEL_YEAR
    SET_MMVY_USER_INPUTS
    UPDATE_MAKE_MODEL_REQUEST
    GET_BANK_TRANSFER_REQUEST
    GET_BANK_TRANSFER_SUCCESS
    GET_BANK_TRANSFER_FAILURE
    UPDATE_CHECKOUT_PRE_APPROVAL_STEPS

    UPDATE_CHARGE_DETAILS

    GET_ADYEN_FAILURE_INIT
    GET_ADYEN_FAILURE_SUCCESS
    GET_ADYEN_FAILURE_FAILURE
    ADYEN_FAILURE_POLL_STATUS

    MSITE_CHECKOUT_SUMMARY_FAQ_REQUEST
    MSITE_CHECKOUT_SUMMARY_FAQ_SUCCESS
    MSITE_CHECKOUT_SUMMARY_FAQ_FAILURE

    SET_ST_ELIGIBLE_START
    SET_ST_ELIGIBLE_SUCCESS
    SET_ST_ELIGIBLE_FAILURE

    SET_FINANCE_FIRST_CHECKOUT_ENABLED
    
    SET_CHECKOUT_LOGIN_TYPE

`,
    {
        prefix: "checkout/"
    }
);

