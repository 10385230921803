import { CURRENCY } from "../../constants/app-constants";
import priceFormatter from "./price-formatter";

const makePriceLabel = (price, currency = CURRENCY.AU) => `${currency}${priceFormatter(price, true, currency)}`;

export const makePriceLabelRound = (price = 0) => {
    return makePriceLabel(Math.round(price), CURRENCY.AU);
};

export default makePriceLabel;
