import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import LazyImage from "../lazy-image";
import { useInView } from "react-intersection-observer";
import TrackImpression from "../track-impression";
import { trackHomePageEvent } from "../../../handlers/mobile/event-tracker";

const SmarterWayCard = ({image, heading, detail1, link, detail2}) => {
    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true});

    const handleImpressionView = () => {
        trackHomePageEvent("smart_way_viewd", heading);
    };

    return (
        <TrackImpression handleImpressionView={handleImpressionView}>
            <div styleName={"styles.outer"}>
                {inView ?  <LazyImage src={image} /> : <div ref={ref} />}
                <div styleName={"styles.contentOuter"}>
                    <p styleName={"styles.heading"}>{heading}</p>
                    <p styleName={"styles.detail"}>
                        {detail1}
                        {link && <a href={link.href}>{link.text}</a>}
                        <span styleName={"styles.detail"}>{detail2}</span>
                    </p>

                </div>
            </div>
        </TrackImpression>
    );
};

SmarterWayCard.propTypes = {
    image: PropTypes.string,
    heading: PropTypes.string,
    detail1: PropTypes.string,
    link: PropTypes.string,
    detail2: PropTypes.string
};

export default SmarterWayCard;
