import React from "react";
import styles from "./styles.css";
import CARFIRST from "./images/carfirst.svg";
import CARSEC from "./images/carsec.svg";
import RIGHTICON from "./images/right.svg";

const AfterTestDrive = () => {
    return (
        <div styleName={"styles.nextStepWrap"}>
            <p styleName={"styles.nextStep"}>After the test drive</p>
            <div className="media" styleName={"styles.stepWrapper"}>
                <p styleName={"styles.stepCount"}>1</p>
                <div className="media-body">
                    <p styleName={"styles.stepHead"}>Buy the car </p>
                    <img src={CARFIRST} alt="" />
                    <p styleName={"styles.stepDetail"}>Pay the full amount if you like the car, using either cash or financing. Your car will come with;</p>
                    <div>
                        <div styleName={"styles.stepimg"}>
                            <img src={RIGHTICON} alt="" />
                            <p styleName={"styles.steptext"}>3 month complimentary warranty</p>
                        </div>
                        <div styleName={"styles.stepimg"}>
                            <img src={RIGHTICON} alt="" />
                            <p styleName={"styles.steptext"}>Registration and CTP</p>
                        </div>
                        <div styleName={"styles.stepimg"}>
                            <img src={RIGHTICON} alt="" />
                            <p styleName={"styles.steptext"}>Roadside assistance</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="media" styleName={"styles.stepWrapper"}>
                <p styleName={"styles.stepCount"}>2</p>
                <div className="media-body">
                    <p styleName={"styles.stepHead"}>Test own for 7 days</p>
                    <img src={CARSEC} alt="" />
                    <p styleName={"styles.stepDetail"}>All cars come with a 7 day return policy. If within 7 days of owning your car you don’t like it, we’ll refund you 100%.</p>
                </div>
            </div>
        </div>

    );
};

export default AfterTestDrive;
