import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import icon from "./images/product.svg";

// import { trackAmplitude, trackMobileCustomEventsAU } from "../../../../tracking";
// import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
// import { getVASItems, setAddonScreenType } from "../actions";
// import { SCREEN_TYPE } from "../reducers";
// import { AMPLITUDE_EVENTS_CATEGORY } from "../../../../tracking/amplitude-events";
import { getVASItems, setAddonScreenType } from "../../add-ons/actions";
import { SCREEN_TYPE } from "../../add-ons/reducers";

const PickProducts = ({
    egc,
    getVASItemsConnect,
    setAddonScreenTypeConnect,
    year
}) => {

    const onExploreNowClick = async () => {
        // trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
        //     eventLabel: "Yes",
        //     eventAction: "Explore now"
        // });
        // trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.LANDING_PAGE, {
        //     "Explore now": "Explore"
        // });
        await getVASItemsConnect("VAS_POPULAR_BUNDLES", year, egc);
        setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.VAS_STORE, activeStore: "VAS_POPULAR_BUNDLES" });
    };
    return (
        <div styleName={"styles.outer"}>
            <div className="media" styleName={"styles.mediaWrapper"}>
                <img src={icon} alt="Pick products that you want" />
                <div className="media-body">
                    <p styleName={"styles.heading"}>Pick products that you want</p>
                    <p styleName={"styles.subHeading"}>Starting at $2/week</p>
                </div>
            </div>
            <p styleName={"styles.visitStore"} onClick={onExploreNowClick}>Visit store</p>
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content: {
            egc,
            year
        }
    },
    addOns: {
        vasBundles: {
            data: {
                leastProductRepaymentPerWeek,
                products
            }
        }
    }
}) => ({
    egc,
    leastProductRepaymentPerWeek,
    products,
    year
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getVASItemsConnect: getVASItems,
    setAddonScreenTypeConnect: setAddonScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PickProducts);

PickProducts.propTypes = {
    activeStore: PropTypes.string,
    egc: PropTypes.number,
    getVASItemsConnect: PropTypes.func,
    leastProductRepaymentPerWeek: PropTypes.number,
    products: PropTypes.array,
    setAddonScreenTypeConnect: PropTypes.func,
    year: PropTypes.number
};
