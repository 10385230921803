/* eslint-disable complexity */
/* eslint-disable max-params */
import { DRIVA_MIN_AMOUNT, ZERO_DP_MINIMUM_DEPOSIT } from "../../config/driva-config";
import { NUMBER } from "../../constants/app-constants";
import { makePriceLabelRound } from "./make-price-label";
import { DELIVERY_TYPES } from "../../constants/checkout-constants";

// @object egcTotal = {egc, motorVehicleDuty, transferFee}
// @number egcPrice, dapPrice
// @string mode
// dap = drive away price

const getCarPrice = ({egcPrice = 0, dapPrice = 0, egcData = [], offeredPrice, shippingPrice = 0, discountPrice = 0, chargeDetails = [], discount = [], deliveryMode = "", pickupCharges = 0}, formatter = makePriceLabelRound) => {

    const {egc = 0, motorVehicleDuty = 0, transferFee = 0, ctp = 0, registrationCost = 0, registrationTenure, amountPaid = 0} = egcData[0] || {};

    const addPickupChargesIntoDap = (deliveryMode === DELIVERY_TYPES.PickUp && pickupCharges) || 0;
    const addDeliveryChargeIntoDap = (deliveryMode === DELIVERY_TYPES.HomeDelivery && shippingPrice) || 0;

    //adding charges
    const chargeAmount = (chargeDetails || []).filter((cd) => !["BUNDLE", "PRODUCT"].includes(cd.type)).reduce((n, { amount }) => n + amount, 0);
    const vasAmount = (chargeDetails || []).filter((cd) => ["BUNDLE", "PRODUCT"].includes(cd.type)).reduce((n, { discountedPrice }) => n + discountedPrice, 0);
    const discountAmount = (discount || []).reduce((n, {discountValue}) => n + discountValue, 0);

    const totalDap = egc + motorVehicleDuty + transferFee + addDeliveryChargeIntoDap + ctp + registrationCost - discountPrice + (chargeAmount - discountAmount) + vasAmount + addPickupChargesIntoDap;
    const tradeInPrice = totalDap - (offeredPrice || 0);

    return {
        egc: {value: egcPrice || egc, label: formatter(egcPrice || egc) },
        totalDap: {value: totalDap - amountPaid, label: formatter(totalDap - amountPaid)},
        dap: {value: dapPrice, label: formatter(dapPrice)},
        tradeInPrice: {value: tradeInPrice, label: formatter(tradeInPrice)},
        motorVehicleDuty: {value: motorVehicleDuty, label: formatter(motorVehicleDuty)},
        transferFee: {value: transferFee, label: formatter(transferFee)},
        ctp: {value: ctp, label: formatter(ctp)},
        registrationCost: {value: registrationCost, label: formatter(registrationCost)},
        registrationTenure: {value: registrationTenure, label: `${registrationTenure} months registration`},
        amountPaid: {value: amountPaid, label: formatter(amountPaid)},
        headerTotalDap: {value: tradeInPrice, label: formatter(tradeInPrice)},
        isTradeinHigh: offeredPrice && totalDap <= offeredPrice
    };
};

const getFinanceConfig = ({totalAmount, egcData, isUserZeroDpVariant}) => {
    const {amountPaid = 0} = egcData[0] || {};
    const drivaConfig = {
        min: Math.abs((amountPaid || 0) - (isUserZeroDpVariant ? ZERO_DP_MINIMUM_DEPOSIT : DRIVA_MIN_AMOUNT)),
        max: Math.round(totalAmount) - NUMBER.FIVE_THOUSAND,
        isDrivaEnabled() {return totalAmount >= NUMBER.FIVE_THOUSAND + this.min;}
    };

    const byofConfig = {
        min: Math.abs((amountPaid || 0) - NUMBER.FIVE_HUNDRED),
        max: Math.round(totalAmount - NUMBER.THOUSAND),
        isByofEnabled() {return totalAmount >= NUMBER.THOUSAND + this.min;},
        getMinError() {return `Deposit should be >= $${this.min}`;},
        getMaxError() {return `Maximum loan amount is $${this.max}`;}

    };

    return {drivaConfig, byofConfig};

};

// eslint-disable-next-line max-statements
const getTradePriceLabel = ({egcData = [], tradeInData = {}, tradeinOpted = false, deliveryInfo = {}, dapPrice, discountPrice = 0, chargeDetails = [], discount = [], isUserZeroDpVariant, deliveryMode = ""}) => {
    const { deliveryInfoError, deliveryDate, shippingPrice, pickupCharges } = deliveryInfo;

    const {offeredPrice = 0} = tradeinOpted ? tradeInData : {};
    const  carPrice = getCarPrice({egcData, dapPrice, offeredPrice, shippingPrice, discountPrice, chargeDetails, discount, deliveryMode, pickupCharges });
    const {tradeInPrice, totalDap, egc, motorVehicleDuty, transferFee} = carPrice;
    const isCarAvailable = motorVehicleDuty.value + transferFee.value !== 0;
    const isTradeHigh = offeredPrice > totalDap.value;
    const isDeliveryAvailable = (deliveryDate || deliveryInfoError);
    const tradeLabel = isTradeHigh ? "Balance amount (Payable to you)" : "Final Price";
    const driveAwayLabel = (isDeliveryAvailable || isCarAvailable) ?  "Drive Away price" : "Excluding government charges(EGC)";
    const priceSubLabel = isTradeHigh  ? "Total payable to you" : "Total payable by you";
    const price = (isDeliveryAvailable || isCarAvailable) ?  totalDap.value  :  egc.value - discountPrice;
    const warrantyLabel =  (chargeDetails || []).find(item => item.key === "extendedWarranty") || {};
    const windowTinting =  (chargeDetails || []).find(item => item.key === "tintingPlan") || {};
    const vasItems = (chargeDetails || []).filter(item => ["BUNDLE", "PRODUCT"].includes(item.type)) || [];
    //let vasItems = omsVasItems;
    //if ((vasCartItems || []).length) vasItems = vasCartItems;
    let priceData = {
        priceValue: tradeinOpted && isDeliveryAvailable ? Math.abs(tradeInPrice.value) : price,
        priceLabel: tradeinOpted &&  isDeliveryAvailable ? tradeLabel : driveAwayLabel,
        priceSubLabel: isDeliveryAvailable ? priceSubLabel : "",
        dapOrTradePrice: tradeinOpted ? tradeInPrice.value : totalDap.value,
        isDeliveryAvailable,
        tradeinOpted,
        offeredPrice,
        isCarAvailable,
        warrantyLabel,
        windowTinting,
        shippingPrice,
        vasItems,
        ...carPrice
    };

    const financeAmountConfig = getFinanceConfig({totalAmount: priceData.dapOrTradePrice, discountPrice, egcData, isUserZeroDpVariant});
    priceData = {...priceData, ...financeAmountConfig};
    return priceData;
    // const state =  store.getState();
    // const {checkout: {tradeInData, egcData, tradeinOpted}, deliveryInfo} = state;

};

export {getCarPrice, getTradePriceLabel};
