import React from "react";
import styles from "./styles.css";
import Banner from "./images/banner.png";

const BannerRevamp = () => {
    return (
        <div styleName={"styles.outer"}>
            <img src={Banner} />
            <p styleName={"styles.heading"}>Fully equip your car<br />before delivery</p>
            <p styleName={"styles.subHeading"}>Add car care packages to your<br />purchase.</p>
        </div>

    );
};

export default BannerRevamp;
