import React, { useState } from "react";
import styles from "./styles.css";
import ContractIcon from "./images/contract.svg";
import PropTypes from "prop-types";
import CarDeliveryDateCard from "../car-delivery-date-card";
import BookingsFooter from "../bookings-footer";
import CheckoutLayout from "../checkout-layout";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../constants/au.desktop/tasks-routes";
import { useHistory } from "react-router-dom";
import DocusignLoaderInfo from "../../shared/docusign-loader-info/component";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { poll } from "../../../utils/polling";

const ContractSaleNote = ({
    content,
    postSignContractConnect,
    signContractData = [],
    showToastConnect,
    showVariant = "VARIANT_A",
    customSignClick,
    orderId
}) => {
    const history = useHistory();
    const { documentType = "SALE"} = signContractData[0] || {};
    const { appointmentId } = content || {};
    const [ docuSignLoading, setDocuSignLoading ] = useState(false);

    const handleBackClick = () => {
        if (customSignClick) {
            customSignClick();
        } else {
            const lastState = history?.location?.state?.from;
            const isLastStateTrackApplication = lastState === tasksRoutes.trackApplication.route;
            const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, isLastStateTrackApplication ? tasksRoutes.trackApplication.route : tasksRoutes.viewTask.route);
            history.push(relativeURL);
        }
    };

    const validateSignContractData = (response) => {
        if (response.data.viewUrl) {
            window.location.href = response.data.viewUrl;
        } else {
            setDocuSignLoading(false);
            showToastConnect({ variant: "black", text: "Something went wrong. Please try again or contact support.", position: "middle"});
        }
        return false;
    };
    const handleDocuSignClick = async () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CONTRACT_OF_SALE);
        setDocuSignLoading(true);
        const params = {
            orderId,
            documentType
        };
        const pollingParams = {
            fn: postSignContractConnect,
            validate: validateSignContractData,
            interval: 5000,
            maxAttempts: 6,
            fnParams: params,
            retryOnFail: true
        };
        try {
            await poll(pollingParams);
        } catch (error) {
            setDocuSignLoading(false);
            showToastConnect({ variant: "black", text: "Something went wrong. Please try again or contact support.", position: "middle"});
        }
    };

    return (
        <React.Fragment>
            {docuSignLoading && <DocusignLoaderInfo />}
            <div className="row">
                <div className={showVariant === EXPERIMENT_TYPE.VARIANT_A ? "col-lg-7" : ""}>
                    <CheckoutLayout
                        customBack={handleBackClick}
                        customNextText={"READ AND SIGN"}
                        customNext={handleDocuSignClick}
                        nextButtonDisabled={!orderId}
                    >
                        <div styleName={"styles.taskWrapper"}>
                            <h4 styleName={"styles.title"}>
                                <img src={ContractIcon} alt="Contract of sale" />
                Contract of sale
                            </h4>
                            <div styleName={"styles.noteWrapper"}>
                                <p>Please Note:</p>
                                <ul>
                                    <li>
                                        <span>Keep your Driver’s Licence with you. It will come in handy while signing the contract</span>
                                    </li>
                                    <li>
                                        <span>We’ll mail you the documents after successful signing</span>
                                    </li>
                                    <li>
                                        <span>You will be redirected to <strong>Docusign</strong> where you can read and sign the contract</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </CheckoutLayout>
                </div>

                {showVariant === EXPERIMENT_TYPE.VARIANT_A &&
              <div className="col-lg-5">
                  <CarDeliveryDateCard/>
                  <div
                      styleName={"styles.getInTouchWrapper"}>
                      <BookingsFooter />
                  </div>
              </div>
                }
            </div>

        </React.Fragment>
    );
};

ContractSaleNote.propTypes = {
    content: PropTypes.object,
    signContractData: PropTypes.array,
    postSignContractConnect: PropTypes.func,
    showToastConnect: PropTypes.func,
    updateSignContractStatusConnect: PropTypes.func,
    showVariant: PropTypes.string,
    customSignClick: PropTypes.func,
    orderId: PropTypes.string
};

export default ContractSaleNote;
