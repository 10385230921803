import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProfile } from "../../../au.configuration/actions";
import { sendMobileOTP, setUserPersonalDetails, updateBasicHubspot, updateDeliveryMode, createCheckoutSteps, updateCheckoutSteps, updateOrder } from "../../checkout-info/actions";
import BasicDetails from "./components";

const mapStateToProps = ({
    user: { secureToken, mobile: savedMobileNumber, email, name },
    carDetails: { content, ipDetectedState },
    checkout: { personalDetails, checkoutSourcePathname, order, checkoutStepsData}
}) => ({
    secureToken,
    savedMobileNumber,
    content,
    personalDetails,
    checkoutSourcePathname,
    name,
    order,
    checkoutStepsData,
    email,
    ipDetectedState
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserPersonalDetailsConnect: setUserPersonalDetails,
            sendMobileOTPConnect: sendMobileOTP,
            updateProfileConnect: updateProfile,
            updateDeliveryModeConnect: updateDeliveryMode,
            updateBasicHubspotConnect: updateBasicHubspot,
            createCheckoutStepsConnect: createCheckoutSteps,
            updateCheckoutStepsConnect: updateCheckoutSteps,
            updateOrderConnect: updateOrder
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
