/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import useToggle from "../../../hooks/use-toggle";
import PropTypes from "prop-types";
import IconSuccess from "../icon-success";
import { numberRegex } from "../../../utils/helpers/regex";
const MAX_LENGTH = 100;

const InputText = ({
    value = "",
    id = "",
    text = "",
    onChange = () => {},
    isAutoFocused = false,
    onBlurCallback = () => { },
    maxLength = MAX_LENGTH,
    validator = () => { },
    errorMessage = "",
    inputRef,
    disabled = false,
    placeholder = null,
    onFocusCallback = () => { },
    showErrorOnFocus = false,
    type = "text",
    showValidCheckmark = false,
    onKeyPress = () => { },
    readOnly = false,
    serverErrorMessage = "",
    isError,
    showError = false,
    disableOnChangeError = false,
    showErrorOnBlur = false,
    showErrorBorder = false,
    showPlaceHolderAsLabel = true,
    onlyNumber = false,
    name,
    suffixText = null,
    ...restProps
}) => {
    /* Added these for overriding onChange & value for formik fields */
    const { field = {}, isFormikForm = false, props = {} } = restProps || {};
    onChange = isFormikForm ? field.onChange : onChange;
    value = isFormikForm ? (field.value || "") : value;

    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useToggle(isAutoFocused);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(serverErrorMessage || errorMessage);

    useEffect(() => {
        if (isError) {
            setError(serverErrorMessage);
        } else {
            setError("");
        }
    }, [serverErrorMessage, isError]);

    const onChangeHandler = (e) => {
        const inputValue = e.target.value;
        if (onlyNumber && inputValue && !numberRegex.test(inputValue)) return;
        setIsTouched(true);
        if (!disableOnChangeError) {
            if (validator(inputValue)) {
                setHasError(false);
                setError("");
            } else {
                setHasError(true);
                setError(errorMessage);
            }
        }
        const { onChange: propsOnChange } = props || {};
        if (typeof propsOnChange === "function") {
            props.onChange(e, id);
        }
        onChange(e, id);
    };

    const onFocus = (e) => {
        const inputValue = e.target.value;
        setIsFocused();
        setIsTouched(true);
        if (!validator(inputValue) && showErrorOnFocus) {
            setHasError(true);
            setError(errorMessage);
        } else {
            setHasError(false);
            setError("");
        }
        onFocusCallback(e);
    };

    const onBlur = (e) => {
        setIsFocused();
        if (showErrorOnBlur) {
            if (validator(e.target.value)) {
                setHasError(false);
                setError("");
            } else {
                setHasError(true);
                setError(errorMessage);
            }
        }
        onBlurCallback(e.target.value, id);
    };

    // useEffect(() => {
    //     if (showValidCheckmark) {
    //         setHasError(!validator(value));
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <div className="inputText" styleName={`styles.inputShift ${showError ? "styles.inputErrorBox" : ""}`}>
            <input
                ref={inputRef}
                id={id}
                type={type}
                className="form-control"
                styleName={showErrorBorder ?
                    "styles.errorBorder" : ""}
                value={value}
                maxLength={maxLength}
                onChange={onChangeHandler}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
                onKeyPress={onKeyPress}
                readOnly={readOnly}
                name={name}
            // required
            />
            {suffixText && <div styleName={"styles.suffixText"}>{suffixText}</div>}
            {text && showPlaceHolderAsLabel && <label styleName={`${(isFocused || !!value) ? "active" : ""}`}>
                {text}
            </label>}
            {
                ((isTouched &&
                    !isFocused &&
                    hasError) || (showErrorOnFocus && hasError) || (isTouched && hasError) || isError) &&
                <small styleName={"styles.error"}>{error}</small>
            }
            {(showValidCheckmark && !hasError) && <div styleName={"styles.success"}>
                <IconSuccess />
            </div>
            }
        </div>
    );
};

InputText.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    onBlurCallback: PropTypes.func,
    maxLength: PropTypes.number,
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    onFocusCallback: PropTypes.func,
    placeholder: PropTypes.string,
    isAutoFocused: PropTypes.bool,
    disabled: PropTypes.bool,
    showErrorOnFocus: PropTypes.bool,
    type: PropTypes.string,
    showValidCheckmark: PropTypes.bool,
    onKeyPress: PropTypes.func,
    readOnly: PropTypes.bool,
    serverErrorMessage: PropTypes.string,
    restProps: PropTypes.object,
    isError: PropTypes.bool,
    disableOnChangeError: PropTypes.bool,
    showErrorOnBlur: PropTypes.bool,
    showError: PropTypes.bool,
    showErrorBorder: PropTypes.bool,
    name: PropTypes.string,
    showPlaceHolderAsLabel: PropTypes.bool,
    onlyNumber: PropTypes.bool,
    suffixText: PropTypes.string
};
export default InputText;
