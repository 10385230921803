import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import snarkdown from "snarkdown";
import {  trackMobileCustomEventsAU } from "../../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
import IconSpinner from "../../../shared/icon-spinner";
import { getCheckoutPageURL } from "../../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../../constants/checkout-routes";

const CarPackagesCard = ({ ctaText = "ADD", loading = false, showKnowMore = false, bundle, onBundleClick = () => {}, onCtaClick = () => {}, content = {} }) => {
    const history = useHistory();
    const { category = [], discount, discountedPrice, name, repaymentsPerWeek } = bundle || {};

    const onKnowMoreClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Pack_know_more",
            eventLabel: name
        });
        onBundleClick();
    };

    const onProductClick = (e) => {
        e.stopPropagation();
        const {key, type} = bundle || {};
        const { year, egc } = content || {};
        const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.addOns.route);
        history.push(`${relativeURL}?key=${key}&type=${type}&carYear=${year}&carPrice=${egc}`);
    };

    return (
        <div styleName={"styles.cardOuter"}>
            <div styleName={"styles.cardTopSection"}>
                {discount > 0 &&
                    <div styleName={"styles.stickerWrapper"}>
                        <p styleName={"styles.saveAmount"}>SAVE {makePriceLabelRound(discount)}</p>
                    </div>
                }
                <p styleName={"styles.cardHeading"} onClick={onProductClick}>{name}</p>
                {category.map((highlight) => {
                    // eslint-disable-next-line no-shadow
                    const { iconUrl, key, name } = highlight || {};

                    return (
                        <div key={key} styleName={"styles.checklistWrap"}>
                            <img src={iconUrl} />
                            <p styleName={"styles.list"} dangerouslySetInnerHTML={{
                                __html: snarkdown(name || "")
                            }} />
                        </div>
                    );
                })}
                <p onClick={onKnowMoreClick} styleName={"styles.knowMore"}>{showKnowMore ? "Know more" : ""}</p>
            </div>
            <div styleName={"styles.cardBottomSection"}>
                <div styleName={"styles.bottomLeftSection"}>
                    <p styleName={"styles.perWeek"}>{makePriceLabelRound(repaymentsPerWeek)}/wk</p>
                    <p styleName={"styles.actualPricePreApproved"}>{makePriceLabelRound(discountedPrice)}</p>
                </div>
                <button styleName={"styles.outerBtn"} disabled={loading} onClick={onCtaClick}>
                    {loading ? <IconSpinner /> : ctaText}
                </button>
            </div>
        </div>
    );
};

export default CarPackagesCard;

CarPackagesCard.propTypes = {
    bundle: PropTypes.object,
    ctaText: PropTypes.string,
    loading: PropTypes.bool,
    preApproved: PropTypes.bool,
    onCtaClick: PropTypes.func,
    onBundleClick: PropTypes.func,
    showKnowMore: PropTypes.bool,
    content: PropTypes.object
};

