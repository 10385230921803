import React from "react";
import styles from "./styles.css";
import { LOAN_PROVIDER } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
// import { getSum } from "../../../utils/helpers/split-payment";
// import PriceIcon from "./images/price-sign.svg";

const PriceBreakupPaymentTerms = ({
    financeOpted,
    financeProvider,
    deposit,
    selectedQuote,
    amountWithSurcharge
    // chargeDetails
}) => {
    const { totalLoanAmount } = selectedQuote  || {};
    const isDriva = financeOpted && financeProvider === LOAN_PROVIDER.DRIVA;
    const isInHouse = financeOpted && financeProvider === LOAN_PROVIDER.CARS24;
    // const chargeAmount = getSum(chargeDetails);

    return (
        <React.Fragment>
            {(isDriva || isInHouse) && <div>
                <h3 styleName={"styles.heading"}>Payment terms</h3>
                <div styleName={"styles.totalPayableWrap"}>
                    <div styleName={"styles.payableContainer"}>
                        <div styleName={"styles.flexWrapper"}>
                            <p styleName={"styles.deposit"}>Deposit Price</p>
                            <p styleName={"styles.depositAmount"}>{makePriceLabelRound(deposit)}</p>
                        </div>
                        <p styleName={"styles.payableNow"}>Payable now</p>
                    </div>
                    <div styleName={"styles.payableContainer"}>
                        <div styleName={"styles.flexWrapper"}>
                            <p styleName={"styles.deposit"}>Loan Amount</p>
                            <p styleName={"styles.depositAmount"}>{makePriceLabelRound(totalLoanAmount)}</p>
                        </div>
                        {!!isDriva && <p styleName={"styles.payableNow styles.bottomBorder"}>Payable via Driva</p>}
                    </div>
                    <div styleName={"styles.flexWrapper"}>
                        <p styleName={"styles.totalAmount"}>Total Payable</p>
                        <p styleName={"styles.totalAmount"}>{makePriceLabelRound(amountWithSurcharge)}</p>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    );
};

PriceBreakupPaymentTerms.propTypes = {
    deposit: PropTypes.number,
    selectedQuote: PropTypes.object,
    amountWithSurcharge: PropTypes.number,
    financeOpted: PropTypes.bool,
    financeProvider: PropTypes.string,
    totalDap: PropTypes.object,
    dapOrTradePrice: PropTypes.number,
    chargeDetails: PropTypes.array
};

export default PriceBreakupPaymentTerms;
