/* eslint-disable max-statements */
import mobileEventsAU from "./au.mobile-events";
import inhouseMobileEventsAU from "./au.mobile-events-in-house-finance";
import desktopEventsAU, {DESKTOP_EVENT_CATEGORY} from "./au.desktop-events";
import commonEventsAU from "./ga-common-events.js";
import mobileCleverTapEventsAU from "./au.desktop-clevertap";
import desktopCleverTapEventsAU from "./au.desktop-clevertap";
import { getDeviceType } from "./c2c-tracking-params";
import pageSectionViewd from "./page-section-viewd";
import reMarketingEvents from "./marketing-page-events";
import fbEvents from "./fb-events";

const sendEvent = (data) => {
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({ ...data });
    }
};

export const trackCustomEvents = ({ platformEvents, category }, extras) => {
    const data = { ...extras, ...platformEvents[category] };
    sendEvent(data);
};

export const trackMobileCustomEventsAU = (category, extras = {eventLabel: "NA"}) => {
    trackCustomEvents(
        {
            platformEvents: {...mobileEventsAU, ...inhouseMobileEventsAU, ...pageSectionViewd, ...fbEvents },
            category
        },
        extras
    );
};

export const trackDesktopCustomEventsAU = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: {...desktopEventsAU, ...inhouseMobileEventsAU, ...pageSectionViewd, ...DESKTOP_EVENT_CATEGORY, ...fbEvents},
        category
    }, extras);
};

export const trackCommonCustomEventsAU = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: {...commonEventsAU },
        category
    }, extras);
};

export const desktopCleverTapEvent = (eventName, data = {}, { userAgent = false } = {}) => {
    data = userAgent ? {...data, Device_type: getDeviceType() } : {...data};
    if (typeof window !== "undefined" && window.clevertap && window.clevertap.event) {
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        window.clevertap.event.push(desktopCleverTapEventsAU[eventName], data);
    }
};

export const mobileCleverTapEvent = (eventName, data = {}, { userAgent = false } = {}) => {
    data = userAgent ? {...data, Device_type: getDeviceType() } : {...data};
    if (typeof window !== "undefined" && window.clevertap && window.clevertap.event) {
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        window.clevertap.event.push(mobileCleverTapEventsAU[eventName], data);
    }
};

export const cleverTapLogin = ({fullName, email, mobile, whatsappOptIn = true}) => {
    if (window.clevertap &&  window.clevertap.onUserLogin) {
        const profileData =  {"Site": {
            "Name": fullName,
            "Email": email,
            "Identity": email,
            "Phone": `+61${mobile}`,
            "MSG-email": true,
            "MSG-push": true,
            "MSG-sms": true,
            "MSG-whatsapp": whatsappOptIn ? true : false
        }};
        window.clevertap.onUserLogin.push(profileData);
        window.clevertap.profile.push(profileData);
    }
};

export const updateCleverTapProfile = ({fullName, email, mobile, whatsappOptIn = false}) => {
    if (window.clevertap && window.clevertap.profile) {
        const profileData =  {"Site": {
            "Name": fullName,
            "Email": email,
            "Identity": email,
            "Phone": `+61${mobile}`,
            "MSG-whatsapp": whatsappOptIn ? true : false
        }};
        window.clevertap.profile.push(profileData);
    }
};

export const clevertapLogout = () => {
    window.clevertap.logout();
};

export const showSiteControlWidget = (id) => {
    if (window && window.gsc) {
        window.gsc("show", id);
    }
};

export const trackCustomReMarketingEvents = (category, tagParams) => {
    const events = {...reMarketingEvents};
    const data = { ...events[category], google_tag_params: {...events[category].google_tag_params, ...tagParams} };
    sendEvent(data);
};

export const trackDesktopAmplitude = () => {
    // track(desktopEventsAU[event] || event, properties);
};

export const trackAmplitude = () => {
    // if (event) {
    //     track(event, objectToString(properties ? JSON.parse(JSON.stringify((properties))) : properties));
    // }
};

export const sendCustomGaEvents = (data = {}) => {
    sendEvent(data);
};

export const clevertapUserProperties = (clevertapUserPropertiesData) => {
    if (window !== "undefined" && window?.clevertap?.profile) {
        window.clevertap.profile.push({"Site": {...clevertapUserPropertiesData}});
    }
};
