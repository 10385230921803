import { appUrl, carSalesListingUrl } from "../../constants/url-constants";
import qs from "querystring";
import dashedLowercase from "./dashed-lowercase";
import { EXPERIMENT_TYPE } from "../../constants/optimize-constants";
import { MEDIA_SOURCE } from "../../constants/app-constants";

// eslint-disable-next-line complexity
export default ({ carName, year, carId, city, pincode, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
    url.push(carText.split(" ").join("-"));
    if (year) { url.push(year); }
    url.push("car");
    if (city) { url.push((city || "").split(" ").join("-")); }
    url.push(carId);
    const normalizedURL = url.join("-");
    return {
        absoluteURL: `${appUrl()}/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`,
        relativeURL: `/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`
    };
};

export const carDetailUrlAu = ({ carName, carId, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "").toLowerCase();
    url.push(carText.split(" ").join("-"));
    url.push("car");
    url.push(carId);
    const normalizedURL = url.filter(Boolean).join("-");
    return {
        absoluteURL: `${appUrl()}/${normalizedURL}/${isCheckout ? `checkout/` : ""}`,
        relativeURL: `/${normalizedURL}/${isCheckout ? `checkout/` : ""}`
    };
};

export const getCarDetailsPageURL = (content = {}) => {
    return `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}`;
};

export const getCarDetailsPageRelURL = (content = {}) => {
    return {
        relativeURL: `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}`
    };
};

export const getCarDetailsPageURLWithoutParam = (content = {}) => {
    return `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}`;
};

export const getCarDetailsGalleryPageURL = ({ content = {}, activeTab = "", subActiveTab = "", imageName, selectedImageIndex = "", mediaSource, backRedirect }) => {
    if (mediaSource === MEDIA_SOURCE.helloAR) {
        return `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}/gallery?activeTab=${activeTab}&subActiveTab=${subActiveTab}${imageName ? `&imageName=${imageName}` : ""}&selectedImageIndex=${selectedImageIndex}`;
    }
    return `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}/gallery?activeTab=${activeTab}&selectedImageIndex=${selectedImageIndex}${imageName ? `&imageName=${imageName}` : ""}${backRedirect ? `&backRedirect=${backRedirect}` : "" }`;

};

export const getCheckoutPageURL = (content, route = "", query = null) => {
    const windowQuery = window?.location?.search || "";
    const queryStr = query ? `?${qs.stringify(query)}` : windowQuery;
    const path = `${getCarDetailsPageURLWithoutParam(content)}/checkout/${route}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getTradeInYourCarPageURL = (route = "") => {
    const path = `/trade-in-your-car/${route}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getCarsalesCallbackURL = () => {
    return carSalesListingUrl();
};

// eslint-disable-next-line max-params
export const getMyBookingCarDetailsURL = (appointmentId, route = "", query = null, type) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    let path;
    if (type === EXPERIMENT_TYPE.VARIANT_B) {
        path = `/my-bookings/${appointmentId}/verify/${route}${queryStr}`;
    } else {
        path = `/my-bookings-${appointmentId}/${route}${queryStr}`;
    }
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getTestDriveCheckoutURL = (content, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    let path = `${getCarDetailsPageURLWithoutParam(content)}/test-drive/checkout/${route}${queryStr}`;
    if (!route) {
        path = getCarDetailsPageURL(content);
    }
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getWishlistURL = () => {
    return {
        absoluteURL: "/wishlist",
        relativeURL: "/wishlist"
    };
};

export const getGuestLoginPostPayURL = (appointmentId, route, query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/my-bookings/${appointmentId}/guest/${route || "verify-user"}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getSimilarCarDetailsPageURL = (content = {}, type) => {
    return {
        relativeURL: `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}/similar-cars/?type=${type}`
    };
};

export const getTestDriveV2CheckoutURL = (content, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    let path = `${getCarDetailsPageURLWithoutParam(content)}/book-test-drive/${route}${queryStr}`;
    if (!route) {
        path = getCarDetailsPageURL(content);
    }
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

// eslint-disable-next-line max-params
export const getTestDriveURL = (carMakeModel, appointmentId, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/buy-used-${carMakeModel}-car-${appointmentId}/book-test-drive/${route}${queryStr}`;

    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};
