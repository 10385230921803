import baseApi from "./baseApi";// New Java APIs
import paymentApi from "./payment-service";
import suggestionApi from "./auto-suggestion-service";

import checkoutApi from "./checkout-service";
import checkoutBaseApi from "./checkout-base-api";

import testDriveApi from "./test-drive-service";
import testDriveBaseApi from "./test-drive-base-api";

import slotPlannerApi from "./slot-planner-service";
import slotPlannerBaseApi from "./slot-planner-base-api";

import b2cDrivaBaseApi from "./b2c-driva-base-api";
import b2cGreenIdBaseApi from "./b2c-green-id-base-api";
import tradeInBaseApi from "./trade-in-base-api";
import b2cInHouseFinanceBaseApi from "./b2c-in-house-finance-base-api";

import listingBaseApi from "./listing-api";
import listingBaseApiAU from "./listing-api-au";
import listingApi from "./listing-service";
import financeApi from "./finance-service";
import secureListingApi from "./secure-listing-service";

import userAuth from "./userAuthApi";

import userAuthApi from "./user-auth-service";

import userAuthAU from "./userAuthApiAU";
import userAuthApiAU from "./user-auth-service-au";

import b2cInventoryBaseApi from "./b2cInventoryApi";
import b2cInventoryBaseApiAU from "./b2cInventoryApiAU";

import b2cInventoryApi from "./b2c-inventory-service";

import thirdPartyBaseApi from "./thirdparty-api";
import thirdParyApi from "./thirdparty-service";

import b2cMasterDataBaseApi from "./b2cMasterDataApi";
import b2cMasterDataApi from "./b2c-master-data-service";
import subscriptionApi from "./subscription-api";
import b2cConsumerFinanceBaseAPi from "./consumer-finance-base-api";

import nudgeBaseApi from "./nudge-base-api";
import nudgeService from "./nudge-service";
import signContractBaseApi from "./sign-contract-base-api";

import c2bTradeService from "./c2b-trade-base-api";

import configBaseApi from "./config-base-api";
import configService  from "./config-service";
import paymentApiAu from "./payment-service-au";
import paymentBaseApi from "./payment-base-api";
import b2cHubspotApi from "./b2c-hubspot-api";
import b2cHubspotBaseApi from "./b2c-hubspot-base-api";
import b2cFinanceBaseApi from "./b2c-finance-base-api";

import bffBaseApi from "./bff-base-api";
import inHouseBaseApi from "./in-house-base-api";
import bffService from "./bff-service";

import bffPayBaseApi from "./coupon-base-api";
import listingApiBaseAu from "./listing-api-base";
import listingBaseApiAUV2 from "./listing-api-au-v2";
import vaseBaseAPI from "./vas-base-api";
import vasApi from "./vas-api";

import geoLocationApi from "./geo-location-service";
import geoLocationBaseApi from "./geo-location-base-api";

const userAuthenticate = userAuthApi(userAuth);
const userAuthenticateAU = userAuthApiAU(userAuthAU);
const b2cHubspot = b2cHubspotApi(b2cHubspotBaseApi);
const payment = paymentApi(baseApi);
const suggestions = suggestionApi(baseApi);
const checkout = checkoutApi(checkoutBaseApi);
const testDrive = testDriveApi(testDriveBaseApi);
const slotPlanner = slotPlannerApi(slotPlannerBaseApi);
const b2cDriva = checkoutApi(b2cDrivaBaseApi);
const b2cGreenId = checkoutApi(b2cGreenIdBaseApi);
const b2cInHouseFinance = checkoutApi(b2cInHouseFinanceBaseApi);
const tradeIn = checkoutApi(tradeInBaseApi);
const listing = listingApi(listingBaseApi);
const listingAU = listingApi(listingBaseApiAU);
const b2cInventory = b2cInventoryApi(b2cInventoryBaseApi);
const b2cInventoryAU = b2cInventoryApi(b2cInventoryBaseApiAU);

const secureListing = secureListingApi(listingBaseApi);
const secureListingAU = secureListingApi(listingBaseApiAU);
const thirdparty = thirdParyApi(thirdPartyBaseApi);
const b2cMasterData = b2cMasterDataApi(b2cMasterDataBaseApi);
const subscription = subscriptionApi(tradeInBaseApi);
const b2cConsumerFinance = checkoutApi(b2cConsumerFinanceBaseAPi);
const nudgeAlert =  nudgeService(nudgeBaseApi);
const signContract =  checkoutApi(signContractBaseApi);
const c2bTrade = checkoutApi(c2bTradeService);

const configData = configService(configBaseApi);
const paymentAu = paymentApiAu(paymentBaseApi);
const bffData = bffService(bffBaseApi);
const inHouseData = bffService(inHouseBaseApi);

const bffPaymentSystem = checkoutApi(bffPayBaseApi);
const bffContactUs = b2cHubspotApi(bffBaseApi);

const b2cBffListing = listingApi(bffBaseApi);
const b2cFinance = financeApi(b2cFinanceBaseApi);
const listingBaseService = listingApi(listingApiBaseAu);
const listingAUV2 = listingApi(listingBaseApiAUV2);

const VasService = vasApi(vaseBaseAPI);

const geoLocation = geoLocationApi(geoLocationBaseApi);
export {
    baseApi,
    payment as PaymentService,
    suggestions as SuggestionService,
    listing as ListingService,
    listingAU as ListingServiceAU,
    userAuthenticate as UserAuthService,
    userAuthenticateAU as UserAuthServiceAU,
    b2cInventory as B2cInventoryService,
    b2cInventoryAU as B2cInventoryServiceAU,
    secureListing as SecureListingService,
    secureListingAU as SecureListingServiceAU,
    thirdparty as ThirdPartyService,
    b2cMasterData as B2CMasterData,
    checkout as CheckoutService,
    testDrive as testDriveService,
    slotPlanner as slotPlannerService,
    tradeIn as CheckoutTradeInService,
    b2cDriva as CheckoutDrivaService,
    b2cGreenId as GreenIdService,
    b2cInHouseFinance as CheckoutInHouseFinanceService,
    subscription as SubsrciptionService,
    b2cConsumerFinance as B2cConsumerFinanceService,
    nudgeAlert as NudgeAlertService,
    signContract as SignContractService,
    c2bTrade as C2bTradeService,
    configData as ConfigService,
    paymentAu as PaymentServiceAu,
    b2cHubspot as B2cHubspotService,
    bffData as BffDataAU,
    inHouseData as InHouseDataAU,
    bffPaymentSystem as BffPaymentService,
    bffContactUs,
    b2cBffListing as b2cBffListingAU,
    b2cFinance as B2cFinanceService,
    listingBaseService as ListingBaseService,
    listingAUV2 as ListingServiceAUV2,
    VasService,
    geoLocation as geoLocationService
};
