import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_EXPERIMENT
    SET_ALL_EXPERIMENT
    ALL_CLEAR_EXPERIMENT
    SET_STATSIG_INITIALIZATION
    CLEAR_EXPERIMENT
`,
    {
        prefix: "abexperiment/"
    }
);
