import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_NUDGE_DETAILS
    HIDE_NUDGE_DETAILS
    SHOW_HELP_SUPPORT_MODAL

    SET_MODAL_CONTENT_SEEN
    UPDATE_SUPPORT_WIDGET_CONFIG
    UPDATE_NUDGE_COUNTS
    UPDATE_NOTIFICATION_COUNTS

    SET_NUDGE_TIMER_ID
    SET_ACCORDION_STATE

    UPDATE_NUDGE_COUNT_SYNC_STATUS
`,
    {
        prefix: "supportWidget/"
    }
);
