import React from "react";
import { useSelector } from "react-redux";
import { getAbExpirementVariant } from "../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../constants/optimize-constants";

const usePreBiAssistance = () => {
    const {
        supportWidget = {},
        abExperiment: {
            webMobilePreBiAssistance = {}
        } = {},
        user: {
            isFirstUserSession = false
        } = {}
    } = useSelector((state) => state || {});
    const {isModalContentSeen = false, launchNudge = false, showNudge = false} = supportWidget || {};
    const renderSupportNudge = !!(getAbExpirementVariant(webMobilePreBiAssistance, EXPERIMENT_TYPE.VARIANT_B) && !isModalContentSeen && launchNudge && showNudge);
    return {renderSupportNudge, isFirstUserSession};
};

export default usePreBiAssistance;
