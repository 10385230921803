import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_GUEST_LOGIN_VISIBLE
    SET_GUEST_LOGIN_SUCCESS_RESPONSE
`,
    {
        prefix: "guestlogin/"
    }
);
