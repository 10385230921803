
/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios from "axios";
import { captureException } from "@sentry/browser";
import { configApiUrl } from "../constants/url-constants";
import authRefresh from "../utils/helpers/auth-refresh";
import { COUNTRY_CODE } from "../constants/app-constants";

const DEBUG = process.env.NODE_ENV === "development";

const BASE_API_URL = `${configApiUrl()}/api`;
/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X_COUNTRY": COUNTRY_CODE.AU,
        "X_VEHICLE_TYPE": "CAR"
    }
    // withCredentials: true,
});

// Request Interceptor which handles token refresh pre and post token expiry
api.interceptors.request.use(authRefresh, (error) => {
    if (DEBUG) {
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) { console.debug("📩 ", response); }
    return response;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) { console.error("Error: ", error.response.data); }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) { console.error("📩 ", error); }
    return Promise.reject(error);
});

export default api;
