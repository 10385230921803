import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PreApprovalPillNavigation from "./component";

const mapStateToProps = ({ carDetails: { content }, preApprovalLoan: { maxStep } }) => ({ content, maxStep });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreApprovalPillNavigation);
