/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import Types from "./types";
import { CheckoutService, C2bTradeService, ListingServiceAU, CheckoutTradeInService, BffDataAU} from "../../../../service";
import {  NUMBER, CITY_WISE_CONTENT } from "../../../constants/app-constants.js";

const X_VEHICLE_TYPE = {
    CAR: "CAR",
    car: "car"
};

const populateCheckoutPersonalData = (userData) => ({
    type: Types.POPULATE_CHECKOUT_PERSONAL_DATA,
    userData
});

const setUserPersonalDetails = (personalDetails) => ({
    type: Types.SET_USER_PERSONAL_DETAILS,
    personalDetails
});

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanDetails = (data) => {
    return {
        type: Types.SET_LOAN_DETAILS,
        newData: data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const initiateOrderPaymentRequest = () => ({
    type: Types.INITIATE_ORDER_PAYMENT_REQUEST
});

const initiateOrderPayment = (orderId, payload) => (dispatch, getState) => {
    dispatch(initiateOrderPaymentRequest());
    const {
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateOrderPayment(orderId, payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatusRequest = () => ({
    type: Types.GET_PAYMENT_STATUS
});

const getPaymentStatus = (orderId) => (dispatch, getState) => {
    dispatch(getPaymentStatusRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const setMaxStep = (maxStep) => ({type: Types.SET_MAX_STEP, maxStep});

const requestCallback = (params = {}) => (dispatch, getState) => {
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        C2bTradeService.c2bRequestCallBack(secureToken, params)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

//Trade-in
const getTradeInDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_REQUEST
});

const getTradeInDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_SUCCESS,
    data
});

const getTradeInDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_FAILURE,
    error
});

const getTradeInDetails = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const {user: { secureToken }} = getState();
        try {
            const {isBooked, token} = params;
            let response;
            if (isBooked) {
                response = await C2bTradeService.getBookedC2bTrade(token || secureToken);
            } else {
                response = await C2bTradeService.getC2bTrade(token || secureToken);
            }
            const {data} = response.data || {};
            dispatch(getTradeInDetailsSuccess(data || {}));
            return Promise.resolve(data);
        } catch (error) {
            dispatch(getTradeInDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const getOrderDetailsRequest = () => ({
    type: Types.GET_ORDER_REQUEST
});

const getOrderDetailsSuccess = (data, evaluatedData) => ({
    type: Types.GET_ORDER_SUCCESS,
    data,
    evaluatedData
});

const getOrderDetailsFailure = (error) => ({
    type: Types.GET_ORDER_FAILURE,
    error
});

const getOrderDetails = (orderId, token) =>
    (dispatch, getState) => {
        dispatch(getOrderDetailsRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.getOrderDetails(token || secureToken, orderId)
                .then((response) => {
                    const {data, status, cars24Email, priceComparison, valuationTags} = response.data || {};
                    if (status === NUMBER.ONE) {
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(getOrderDetailsSuccess(data, evaluatedData));
                    }
                    resolve(data);
                })
                .catch((error) => {
                    dispatch(getOrderDetailsFailure(error));
                    reject(error);
                });
        });
    };

const getSellOrderDetails = (params = {}) => {
    return async (dispatch, getState) => {
        const {user: { secureToken }} = getState();
        try {
            const  response = await C2bTradeService.getSellOrderDetails(secureToken, params);
            const {data} = response.data || {};
            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

const getColdLeadRequest = () => ({
    type: Types.GET_COLD_LEAD_REQUEST
});

const getColdLeadSuccess = (data, evaluatedData) => ({
    type: Types.GET_COLD_LEAD_SUCCESS,
    data,
    evaluatedData
});

const getColdLeadFailure = (error) => ({
    type: Types.GET_COLD_LEAD_FAILURE,
    error
});

const getColdLeadDetails = (orderId) =>
    (dispatch) => {
        dispatch(getColdLeadRequest());
        return new Promise((resolve, reject) => {
            C2bTradeService.getColdLeadDetails(orderId)
                .then((response) => {
                    const {data, status, cars24Email, priceComparison, valuationTags} = response.data || {};
                    if (status === NUMBER.ONE) {
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(getColdLeadSuccess(data, evaluatedData));
                    }
                    resolve(data);
                })
                .catch((error) => {
                    dispatch(getColdLeadFailure(error));
                    reject(error);
                });
        });
    };

const createTradeInRequest = () => ({
    type: Types.CREATE_TRADE_IN_REQUEST
});

const createTradeInSuccess = (data) => ({
    type: Types.CREATE_TRADE_IN_SUCCESS,
    data
});

const createTradeInFailure = (error) => ({
    type: Types.CREATE_TRADE_IN_FAILURE,
    error
});

const createTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequest());
        const {
            user: {
                secureToken,
                email
            }
        } = getState();
        const payload = {
            userEmail: email,
            ...params
        };
        return new Promise((resolve, reject) => {
            C2bTradeService.createC2bTrade(secureToken, payload)
                .then((response) => {
                    const { data, status } = response.data || {};
                    if (status === NUMBER.TWO_HUNDRED) {
                        dispatch(createTradeInSuccess({...data, status}));
                    }
                    resolve({...data, status});
                })
                .catch((error) => {
                    dispatch(createTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateTradeInRequest = () => ({
    type: Types.UPDATE_TRADE_IN_REQUEST
});

const updateTradeInSuccess = (data, evaluatedData) => {
    return {
        type: Types.UPDATE_TRADE_IN_SUCCESS,
        data,
        evaluatedData
    };
};

const updateTradeInFailure = (error) => ({
    type: Types.UPDATE_TRADE_IN_FAILURE,
    error
});

const updateTradeIn = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.updateC2bTrade(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    if (response.data && (response.data.status === NUMBER.TWO_HUNDRED || response.data.status === NUMBER.ONE)) {
                        const { data, cars24Email, priceComparison, valuationTags } = response.data || {};
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(updateTradeInSuccess(data, evaluatedData));
                    } else {
                        const error = response.data && response.data.errorMessage;
                        dispatch(updateTradeInFailure(error));
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const submitTradeIn = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            BffDataAU.submitC2bTrade(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    if (response.data && (response.data.status === NUMBER.TWO_HUNDRED || response.data.status === NUMBER.ONE)) {
                        const { data, cars24Email, priceComparison, valuationTags } = response.data || {};
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(updateTradeInSuccess(data, evaluatedData));
                    } else {
                        const error = response.data && response.data.errorMessage;
                        dispatch(updateTradeInFailure(error));
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const acceptTradeIn = (orderID, payload) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.acceptC2bTrade(secureToken, orderID, payload)
                .then((response) => {
                    const { data } = response.data || {};
                    dispatch(updateTradeInSuccess(data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateBasicDetails = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.updateBasicDetails(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    const { data } = response.data || {};
                    dispatch(updateTradeInSuccess(data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const customerOptedTrade = (tradeinOpted) => ({
    type: Types.SET_TRADE_OPTION,
    tradeinOpted
});

const fetchTradeInStateRequest = () => ({
    type: Types.GET_TRADE_IN_STATE_REQUEST
});

const fetchTradeInStateSuccess = (tradeInStates) => ({
    type: Types.GET_TRADE_IN_STATE_SUCCESS,
    tradeInStates
});

const fetchTradeInStateFailure = (error) => ({
    type: Types.GET_TRADE_IN_STATE_FAILURE,
    error
});

const getTradeInState = () => async (dispatch, getState) => {
    dispatch(fetchTradeInStateRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getTradeInStates(secureToken);
        const stateValue = response.data.reduce((acc, data) => {
            acc.push({ ...data, label: data.stateName, value: data.stateName});
            return acc;
        }, []);
        dispatch(fetchTradeInStateSuccess(stateValue));
        return response;
    } catch (error) {
        dispatch(fetchTradeInStateFailure(error));
        return error;
    }
};

const saveCheckoutSourcePathname = (data) => ({
    type: Types.SAVE_CHECKOUT_SOURCE_PATHNAME,
    data
});

const saveTradeInLocal  = (data) => ({
    type: Types.SAVE_TRADE_IN_LOCALSTATE,
    data
});

const getDeliveryInfoSuccess = (data, pincode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode
});

const getDeliveryInfoFailure = (error, pincode) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error,
    pincode
});

const setDeliveryInfoFetch = (data) => ({
    type: Types.GET_DELIVERY_INFO_INIT,
    deliveryInfoFetched: data
});

const getDeliveryInfo = (appointmentId, pincode, origincity) => (dispatch, getState) => {
    const {carDetails = {}, user: { secureToken }} = getState();
    const {cityCode} = carDetails.content || {};
    dispatch(setDeliveryInfoFetch(false));
    return new Promise((resolve, reject) => {
        BffDataAU.getDeliveryAvailability(secureToken, appointmentId, pincode, origincity || cityCode, false).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, pincode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, pincode));
            reject(error);
        });
    });
};

const getC2bDelivery = (pincode, origincity) => (dispatch, getState) => {
    const {carDetails = {}} = getState();
    const {cityCode} = carDetails.content || {};
    dispatch(setDeliveryInfoFetch(false));
    return new Promise((resolve, reject) => {
        ListingServiceAU.getC2bAvailability(pincode, origincity || cityCode, false).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, pincode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, pincode));
            reject(error);
        });
    });
};

const setUserUpdating = (data) => dispatch => {
    dispatch({ type: Types.SET_USER_UPDATING, data });
};

const handleRejectModal = (modalType) => dispatch => {
    dispatch({type: Types.HANDLE_REJECT_MODAL, modalType});
};

const closeHandleRejectModal = () => dispatch => dispatch({type: Types.CLOSE_REJECT_MODAL});

const getTradeVehicleDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_VEHICLE_REQUEST
});

const getTradeVehicleDetailsSuccess = ({data}) => ({
    type: Types.GET_TRADE_IN_VEHICLE_SUCCESS,
    data
});

const getTradeVehicleDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_VEHICLE_FAILURE,
    error
});

const getTradeVehicleDetails = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getTradeVehicleDetailsRequest());
        const { user: { secureToken }} = getState();
        try {
            const response =  await C2bTradeService.getC2BTradeInVehicleDetails(secureToken, params);
            dispatch(getTradeVehicleDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeVehicleDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const getSeoContentRequest = () => ({
    type: Types. FETCH_SEO_CONTENT
});

const getSeoContentSuccess = (data) => ({
    type: Types.FETCH_SEO_CONTENT_SUCCESS,
    data
});

const getSeoContentFailure = (error) => ({
    type: Types.FETCH_SEO_CONTENT_FAILURE,
    error
});

const getSeoContent = (params = {}) =>
    async (dispatch) => {
        dispatch(getSeoContentRequest());
        try {
            const response =  await C2bTradeService.getSeoContent(params);
            const { city }  =  response.data.seoMeta;
            const seoSectionsData =  CITY_WISE_CONTENT[city] || {};
            const responseObj = {
                ...response.data,
                seoSectionsData
            };
            dispatch(getSeoContentSuccess(responseObj));
            return Promise.resolve(response.data.seoMeta);
        } catch (error) {
            dispatch(getSeoContentFailure(error));
            return Promise.reject(error);
        }
    };

const saveSelectedCity = (data) => ({
    type: Types.SAVE_SELECTED_CITY,
    data
});

const saveTradeInRegoandVin = (data) => ({
    type: Types.SAVE_CAR_REGNO_VIN,
    data
});

const fetchTradeInCarVariants = () => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT
});

const fetchTradeInCarVariantsSuccess = (tradeInCarVariants) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS,
    tradeInCarVariants
});

const fetchTradeInCarVariantsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_FAILURE,
    error
});

const getTradeInCarVariants = (params = {}) => async (dispatch, getState) => {
    dispatch(fetchTradeInCarVariants());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getVariants(secureToken, params);
        if (response.data && response.data.data && response.data.data.length) {
            dispatch(fetchTradeInCarVariantsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        }
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        return error;
    }
};

const saveTradeInCarMakeModelId = (data) => ({
    type: Types.SAVE_TRADE_IN_ID,
    data
});

const fetchTradeInCarDetails = () => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS
});

const fetchTradeInCarDetailsSuccess = (tradeInCarDetails) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS,
    tradeInCarDetails
});

const fetchTradeInCarDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_FAILURE,
    error
});

// make model flow actions

const setMakeFlowMaxStep = (maxStep) => ({type: Types.SET__MAKE_MODEL_MAX_STEP, maxStep});
const setMakeFlowCurrentStep = (currentStep) => ({type: Types.SET__MAKE_MODEL_CURRENT_STEP, currentStep});
const setMakeFlowHardRejection = (rejectionReasonInfo) => ({type: Types.SET__MAKE_MODEL_HARD_REJECTION, rejectionReasonInfo});
const setMakeFlowRecheduleflag = (rescheduleFlag) => ({type: Types.SET__MAKE_MODEL_RECHEDULE_FLAG, rescheduleFlag});
const setMakeModelYear = (makeModelYear) => ({type: Types.SET_MAKE_MODEL_YEAR, makeModelYear});
const setMmvyUserInputs = (mmvyUserInputs) => ({type: Types.SET_MMVY_USER_INPUTS, mmvyUserInputs});

const getTradeInCarMakeModel = () => async (dispatch, getState) => {
    dispatch(fetchTradeInCarDetails());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getCarMakeModel(secureToken);
        if (response.data && response.data.data) {
            dispatch(fetchTradeInCarDetailsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        }
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        return error;
    }
};

const resetTradeInDetails = () => dispatch => dispatch({ type: Types.RESET_CAR_TRADE_IN_DETAILS });

const updateMakeModelFlowRequest = () => ({
    type: Types.UPDATE_MAKE_MODEL_REQUEST
});

const updateMakeModelFlow = (orderData, orderId) =>
    (dispatch, getState) => {
        dispatch(updateMakeModelFlowRequest());
        const {
            user: {
                secureToken
            }
        } = getState();

        return new Promise((resolve, reject) => {
            C2bTradeService.updateMakeModelFlow(secureToken, orderData, orderId)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

const openGuestLogin = (guestPopupType, isMobileMandatory) => ({type: Types.OPEN_GUEST_LOGIN, guestPopupType, isMobileMandatory});
const closeGuestLogin = () => ({type: Types.CLOSE_GUEST_LOGIN });

const setRedirectionData = (data) => ({
    type: Types.SET_REDIRECTION_DATA,
    data
});

export {
    populateCheckoutPersonalData,
    setUserPersonalDetails,
    setPersonalDetailSuccessMessage,
    setFinanceData,
    setLoanDetails,
    setLoanTenureValue,
    setDeliveryMode,
    setFinancingTab,
    setFinancePersonalDetails,
    updateDeliveryMode,
    updateOrderBooking,
    initiateOrderPayment,
    setFinancingScreen,
    setFinanceChargeList,
    getPaymentStatus,
    setMaxStep,
    requestCallback,
    getTradeInDetails,
    createTradeIn,
    updateTradeIn,
    customerOptedTrade,
    getTradeInState,
    saveCheckoutSourcePathname,
    saveTradeInLocal,
    getDeliveryInfo,
    setUserUpdating,
    handleRejectModal,
    getC2bDelivery,
    getTradeVehicleDetails,
    saveSelectedCity,
    getSeoContent,
    getSellOrderDetails,
    acceptTradeIn,
    updateBasicDetails,
    saveTradeInRegoandVin,
    getTradeInCarVariants,
    saveTradeInCarMakeModelId,
    getTradeInCarMakeModel,
    resetTradeInDetails,
    getOrderDetails,
    getSeoContentSuccess,
    submitTradeIn,

    // make model flow actions
    setMakeFlowMaxStep,
    setMakeFlowCurrentStep,
    setMakeFlowHardRejection,
    setMakeFlowRecheduleflag,
    setMakeModelYear,
    updateMakeModelFlow,
    setMmvyUserInputs,
    openGuestLogin,
    closeGuestLogin,
    getColdLeadDetails,
    closeHandleRejectModal,
    setRedirectionData
};
