import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";

export const defaultCopy = [
    {
        title: "Search for a quality car",
        description: "All cars have passed a 300 point inspection",
        imageSrc: image1
    },
    {
        title: "Pay 100% online",
        description: "Pay outright, or apply for a loan, all online with no paperwork",
        imageSrc: image2
    },
    {
        title: "Schedule home delivery",
        description: "We’ll deliver your car to your home at a time that suits you",
        imageSrc: image3
    },
    {
        title: "Test own for 7 days",
        description: "Test drive the car for 7 days and if you don’t like it, we’ll give you a 100% refund.",
        imageSrc: image4
    }
];

export const testDriveCopy =  [
    {
        title: "Search for a quality car",
        description: "All cars have passed a 300 point inspection",
        imageSrc: image1
    },
    {
        title: "Buy online or book test drive",
        description: "Buy outright or book test drive at our hub (Test drive available for selective cars only)",
        imageSrc: image2
    },
    {
        title: "Schedule delivery",
        description: "Once purchased, we’ll schedule a pick-up from our hubs or delivery to your home",
        imageSrc: image3
    },
    {
        title: "Test own for 7 days",
        description: "Test drive the car for 7 days and if you don’t like it, we’ll give you a 100% refund.",
        imageSrc: image4
    }
];
