import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_MAX_STEP
    SET_CURRENT_STEP

    FETCH_SLOTS_SUCCESS
    FETCH_SLOTS_REQUEST
    FETCH_SLOTS_FAILURE

    BOOK_SLOT_REQUEST
    BOOK_SLOT_SUCCESS
    BOOK_SLOT_FAILURE

    GET_ORDER_DETAIL_INIT
    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE

    SET_SELECTED_SLOT
    SET_RECHEDULE_FLAG

    CANCEL_SLOT_INIT
    CANCEL_SLOT_SUCCESS
    CANCEL_SLOT_FAILURE
    RESET_CANCEL_RESCHEDULE_POPUP_STATE
    SET_TEST_DRIVE_VARIANT_INIT
    SET_TEST_DRIVE_VARIANT_SUCCESS
    SET_TEST_DRIVE_VARIANT_FAILURE
    UPDATE_SLOT_RESCHEDULE

    GET_TEST_DRIVE_VARIANT_INIT
    GET_TEST_DRIVE_VARIANT_SUCCESS
    GET_TEST_DRIVE_VARIANT_FAILURE
`,
    {
        prefix: "book-test-drive/"
    }
);
