import Types from "./types";
import { B2cInventoryServiceAU as B2cInventoryService, BffDataAU, CheckoutDrivaService, ListingServiceAU as ListingService, ListingServiceAUV2 } from "../../../service";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import { API_SOURCE } from "../../../constants/app-constants";

const fetchDetails = () => ({
    type: Types.FETCH_CAR_DETAILS
});

const fetchCarDetailsSuccess = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailure = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE, error });

const fetchCarDetails = (carId, params = {}) => (dispatch, getState) => {
    dispatch(fetchDetails());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin },
        tradeIn: {
            tradeInToggleSelected
        }
    } = getState();

    params = {
        ...(params || {}),
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP,
        ...(tradeInToggleSelected && ({tradeIn: tradeInToggleSelected })),
        cdpRequest: true // used for recently viewed cars /search-page API ordering and differentiate with v1/vehicle in BE
    };
    if (!(params || {}).userFinanceType) {
        params = {...(params || {}), userFinanceType: FINANCE_TYPE.CARS24};
    }
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchCarDetailV3(carId, isGuestLogin ? null : secureToken, params, API_SOURCE.WEB_APP)
            .then((response) => {
                dispatch(fetchCarDetailsSuccess(response.data));
                return resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarsInit = () => ({
    type: Types.FETCH_SIMILAR_CARS
});

const fetchSimilarCarsSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_SUCCESS,
    data
});

const fetchSimilarCarsFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FAILURE,
    error
});

const fetchSimilarCars = (carId, params) => (dispatch, getState) => {
    dispatch(fetchSimilarCarsInit());
    const {
        user: {
            secureToken,
            isUserZeroDpVariant
        },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        }
    } = getState();

    // Trade-in toggle selected or not
    const { tradeIn = false } =  userTradeInData || {};
    params = {
        ...(params || {}),
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP,
        ...(tradeIn && ({tradeIn: tradeInToggleSelected }))
    };

    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params, secureToken)
            .then((response) => {
                dispatch(fetchSimilarCarsSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchSimilarCarsFailure(error));
                reject(error);
            });
    });
};

const fetchSeoSimilarCarsInit = () => ({
    type: Types.FETCH_SEO_SIMILAR_CARS
});

const fetchSeoSimilarCarsSuccess = (data) => ({
    type: Types.FETCH_SEO_SIMILAR_CARS_SUCCESS,
    data
});

const fetchSeoSimilarCarsFailure = (error) => ({
    type: Types.FETCH_SEO_SIMILAR_CARS_FAILURE,
    error
});

const fetchSeoSimilarCars = (carId, params) => (dispatch, getState) => {
    dispatch(fetchSeoSimilarCarsInit());
    const {
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        ListingService.fetchSeoSimilarCars(carId, params, secureToken)
            .then((response) => {
                dispatch(fetchSeoSimilarCarsSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchSeoSimilarCarsFailure(error));
                reject(error);
            });
    });
};

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const setGsGalleryData = (data) => ({
    type: Types.SET_GS_GALLERY_DATA,
    data
});

const getAvailabilityInfoRequest = () => ({
    type: Types.GET_DELIVERY_INFO_REQUEST
});

const getAvailabilityInfoSuccess = (data, pincode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode
});

const getAvailabilityInfoFailure = (error) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error
});

const getAvailabilityInfo = (appointmentId, cityCode) => (dispatch) => {
    dispatch(getAvailabilityInfoRequest());
    return new Promise((resolve, reject) => {
        B2cInventoryService.getPickupLocation(appointmentId, cityCode)
            .then((response) => {
                dispatch(getAvailabilityInfoSuccess(response.data, cityCode));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(getAvailabilityInfoFailure(error));
                reject(error);
            });
    });
};

const getDrivaRoiDetailsRequest = () => ({
    type: Types.GET_DRIVA_ROI_REQUEST
});

const getDrivaRoiDetailsSuccess = (data) => ({
    type: Types.GET_DRIVA_ROI_SUCCESS,
    data
});

const getDrivaRoiDetailsFailure = (error) => ({
    type: Types.GET_DRIVA_ROI_FAILURE,
    error
});

const getDrivaRoiDetails = (params) => (dispatch, getState) => {
    dispatch(getDrivaRoiDetailsRequest());
    const { user: { secureToken}} = getState();
    return new Promise((resolve, reject) => {
        CheckoutDrivaService.getDrivaRoiDetails(secureToken, params)
            .then((response) => {
                dispatch(getDrivaRoiDetailsSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(getDrivaRoiDetailsFailure(error));
                reject(error);
            });
    });
};

const updateLoginFetchCarDetailCompleted = (data) => ({
    type: Types.UPDATE_LOGIN_CAR_DETAIL_FETCH_COMPLETED,
    data
});

const setCurrentDeliveryTimeline = (data) => ({
    type: Types.SET_CURRENT_DELIVERY_TIMELINE,
    data
});

const fetchInspectionReportDataSuccess = (data) => ({
    type: Types.FETCH_INSPECTION_REPORT_DATA_SUCCESS,
    data
});

const fetchInspectionReportDataFailure = () => ({
    type: Types.FETCH_INSPECTION_REPORT_DATA_FAILURE
});

const fetchInspectionReportData = (params) => (dispatch, getState) => {
    const { user: { secureToken}} = getState();
    return new Promise((resolve, reject) => {
        BffDataAU.fetchInspectionReportDataApi(secureToken, params)
            .then((response) => {
                dispatch(fetchInspectionReportDataSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchInspectionReportDataFailure());
                reject(error);
            });
    });
};

const setRedirectRoute = (data) => ({
    type: Types.SET_REDIRECT_ROUTE,
    data
});
export {
    fetchCarDetails,
    updateCarDetailsSSRStatus,
    fetchSimilarCars,
    setGsGalleryData,
    getAvailabilityInfo,
    getDrivaRoiDetails,
    fetchSeoSimilarCars,
    updateLoginFetchCarDetailCompleted,
    setCurrentDeliveryTimeline,
    fetchInspectionReportData,
    setRedirectRoute
};
