import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_PRE_APPROVAL_PERSONAL_DETAILS
    SET_PRE_APPROVAL_FINANCE_SCREEN
    SET_PRE_APPROVAL_FINANCE_DATA
    SET_PRE_APPROVAL_DETAILS
    SET_PRE_APPROVAl_LOAN_TYPE
    SET_POST_FINANCE_SCREEN
    SET_SHOW_ALL_OFFERS

    SET_PRE_APPROVAL_MAX_STEP

    GET_PRE_APPROVAL_LOAN_REQUEST
    GET_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS
    GET_PRE_APPROVAL_LOAN_SUCCESS
    GET_PRE_APPROVAL_LOAN_FAILURE

    GET_PRE_APPROVAL_QUESTIONS_REQUEST
    GET_PRE_APPROVAL_QUESTIONS_SUCCESS
    GET_PRE_APPROVAL_QUESTIONS_FAILURE

    CREATE_PRE_APPROVAL_LOAN_REQUEST
    CREATE_PRE_APPROVAL_LOAN_SUCCESS
    CREATE_PRE_APPROVAL_LOAN_FAILURE

    UPDATE_PRE_APPROVAL_LOAN_REQUEST
    UPDATE_PRE_APPROVAL_LOAN_SUCCESS
    UPDATE_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS
    UPDATE_PRE_APPROVAL_LOAN_FAILURE

    UPDATE_PRE_APPROVAL_FINANCE_DETAILS
    UPDATE_PRE_APPROVAL_FINANCE_DETAILS_SUCCESS
    UPDATE_PRE_APPROVAL_FINANCE_DETAILS_FAILURE


    GET_DEPOSIT_RANGE_REQUEST
    GET_DEPOSIT_RANGE_SUCCESS
    GET_DEPOSIT_RANGE_FAILURE

    PRE_APPROVED_ORIGIN

    SET_PRE_APPROVAL_COACHMARK_DISPLAY
    SET_PRE_APPROVAL_EDIT_REDIRECTION_ROUTE

    UPDATE_BATCH_PRICE_REQUEST
    UPDATE_BATCH_PRICE_SUCCESS
    UPDATE_BATCH_PRICE_FAILURE
    
    RESET_FINANCE_DATA
    RESET_PRE_APPROVAL_DATA

    SET_CURRENT_ACTIVE_TAB
    SET_CAR_INFORMATION
    SET_PRE_APPROVAL_EDIT_MODE

    SET_ELIGIBITLITY_FOR_INHOUSE

    SET_LEAVE_PRE_APPROVAL_POP_UP_STATUS
    SET_INTRO_COACH_MARK_VISIBLE

    PRE_APPROVAL_REQUEST_CALLBACK_INTI
    PRE_APPROVAL_REQUEST_CALLBACK_SUCCESS
    PRE_APPROVAL_REQUEST_CALLBACK_FAILURE

    GET_PRE_APPROVAL_FORM_PROGRESS

    GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_REQUEST
    GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_SUCCESS
    GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_FAILURE

    GET_MIN_MAX_INTEREST_RATES_REQUEST
    GET_MIN_MAX_INTEREST_RATES_SUCCESS
    GET_MIN_MAX_INTEREST_RATES_FAILURE

`,
    {
        prefix: "checkout/"
    }
);
