import { createTypes } from "reduxsauce";

export default createTypes(
    `
    GET_FINANCE_POST_PAYMENT_APPLICATION_INIT
    GET_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS
    GET_FINANCE_POST_PAYMENT_APPLICATION_FAILURE

    PATCH_FINANCE_POST_PAYMENT_APPLICATION_INIT
    PATCH_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS
    PATCH_FINANCE_POST_PAYMENT_APPLICATION_FAILURE

    POST_FINANCE_POST_PAYMENT_APPLICATION_INIT
    POST_FINANCE_POST_PAYMENT_APPLICATION_SUCCESS
    POST_FINANCE_POST_PAYMENT_APPLICATION_FAILURE

    GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_INIT
    GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_SUCCESS
    GET_PRESIGNED_FINANCE_UPLOADED_IMAGE_FAILURE

    UPDATE_ZERO_EXPIRY_TIME_INIT
    UPDATE_ZERO_EXPIRY_TIME_SUCCESS
    UPDATE_ZERO_EXPIRY_TIME_FAILURE

    AUTO_UPDATE_ZERO_EXPIRY_TIME_INIT
    AUTO_UPDATE_ZERO_EXPIRY_TIME_SUCCESS
    AUTO_UPDATE_ZERO_EXPIRY_TIME_FAILURE

    GET_STAGE_TWO_METADATA_REQUEST
    GET_STAGE_TWO_METADATA_SUCCESS
    GET_STAGE_TWO_METADATA_FAILURE

    GET_ST_APPLICATION_STATUS_START
    GET_ST_APPLICATION_STATUS_SUCCESS
    GET_ST_APPLICATION_STATUS_FAILURE
`,
    {
        prefix: "checkout/"
    }
);
