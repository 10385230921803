/* eslint-disable complexity */
import { LICENCE_TYPE_OPTIONS, LICENCE_STATE_OPTIONS, TEMPORARY_VISA } from "../../constants/checkout-constants";
import * as Yup from "yup";
import { NUMBER } from "../../constants/app-constants";

const V3_UPLOAD_CONFIG = ({ components = {}, formData, financeData }) => {
    const { user } = financeData || {};
    const { residency } = user || {};
    let { drivingLicenseDetails = {}, partnershipAgreement = {}} = formData || {};
    drivingLicenseDetails = drivingLicenseDetails || {};
    partnershipAgreement = partnershipAgreement || {};
    formData = {
        ...formData,
        drivingLicenseDetails: {
            media: !drivingLicenseDetails.media ? [null, null] : drivingLicenseDetails.media,
            ...drivingLicenseDetails
        }
    };
    return {
        formId: "v3_upload",
        totalStages: 3,
        formTitle: "Upload Documents",
        introTexts: ["Your driving license", residency === TEMPORARY_VISA ?  "Your visa & passport" : "Your medicare card", "Your trust deed / partnership agreement, if applicable"],
        stages: [
            {
                name: "Driving license",
                stageNo: 1,
                totalPages: 1,
                sidebarNavigation: {
                    key: "DriverLicence",
                    title: "Driver's licence",
                    description: "Upload your DL"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            return !!drivingLicenseDetails.licenceType;
                        },
                        questions: [
                            {
                                questionLabel: "Driving license back",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[0]",
                                    fieldType: components.uploadFile,
                                    title: null,
                                    shortTitle: "Driving license back",
                                    readOnlyComponent: "downloadDocument",
                                    objectDisplayName: "fileName",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "back",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Driving license  front",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[1]",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Driving license front",
                                    readOnlyComponent: "downloadDocument",
                                    objectDisplayName: "fileName",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "front",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Licence type",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceType",
                                    fieldType: components.select,
                                    shortTitle: "Licence type",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_TYPE_OPTIONS,
                                    placeholder: "Type of license you hold",
                                    isRequired: true,
                                    props: {
                                        data: LICENCE_TYPE_OPTIONS
                                    }
                                }]
                            },
                            {
                                questionLabel: "Drivers licence number",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceNumber",
                                    fieldType: components.inputText,
                                    shortTitle: "Drivers licence number",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "DL number as mentioned on your license",
                                    isRequired: true,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Licence state",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceState",
                                    fieldType: components.select,
                                    shortTitle: "Licence state",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_STATE_OPTIONS,
                                    placeholder: "Select the state you hold your license in",
                                    isRequired: true,
                                    props: {
                                        data: LICENCE_STATE_OPTIONS
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            drivingLicenseDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                media: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            fileName: Yup.string().required("Please upload a valid document.")
                                        }).nullable().required("Please upload a valid document.")
                                    )
                                    .min(NUMBER.TWO, "Please upload a valid document.")
                                    .required("Please upload a valid document.").nullable(),
                                licenceType: Yup.string().required("Please select a valid option ").nullable(),
                                licenceNumber: Yup.string().required("Please enter a valid text").nullable(),
                                licenceState: Yup.string().required("Please select a valid option ").nullable()
                            })
                        }),
                        initialValues: {
                            drivingLicenseDetails: formData.drivingLicenseDetails
                        }
                    }

                ]
            }, {
                name: "Medicare card",
                stageNo: 2,
                totalPages: 1,
                sidebarNavigation: {
                    key: "medicareCard",
                    title: residency === TEMPORARY_VISA ? "Visa and Passport" : "Medicare card",
                    description: residency === TEMPORARY_VISA ? "Add Visa & Passport" : "Add your medicare"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { medicareCard }  = formData || {};
                            const { media }  = medicareCard || {};
                            return Array.isArray(media) && media.filter((value) => value.fileName).length > 0;
                        },
                        questions: [
                            ...residency !== TEMPORARY_VISA && ({
                                questionLabel: "Medicare card",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "medicareCard.media",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Medicare card",
                                    readOnlyComponent: "downloadDocument",
                                    objectDisplayName: "fileName",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }),
                            ...residency === TEMPORARY_VISA && ({
                                questionLabel: "Visa certificate",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "visaCertificateDetails.media",
                                    fieldType: components.uploadFile,
                                    errorKey: "visaCertificateDetails",
                                    shortTitle: "Visa certificate",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }),
                            ...residency === TEMPORARY_VISA && ({
                                questionLabel: "Passport",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "passportDetails.media",
                                    errorKey: "passportDetails",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Passport",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            })
                        ],
                        formValidator: Yup.object().shape({
                            medicareCard: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                media: Yup.array().min(NUMBER.ONE, "Please upload a valid document.").required("Please upload a valid document.").nullable()
                            })
                        }),
                        initialValues: {
                            medicareCard: formData.medicareCard
                        }
                    }
                ]
            }, {
                name: "Trust deed / partnership agreement",
                stageNo: 3,
                totalPages: 1,
                isReadDisabled: (partnershipAgreement.media || []).filter((value) => value.fileName).length === NUMBER.ZERO,
                sidebarNavigation: {
                    key: "trustDeed",
                    title: "Partnership / Trust docs",
                    description: "Add applicable documents"
                },
                forms: [
                    {
                        nextButtonCTA: "SUBMIT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { media }  = partnershipAgreement || {};
                            return Array.isArray(media) && media.filter((value) => value.fileName).length > 0;
                        },
                        questions: [
                            {
                                questionLabel: "Trust deed / partnership agreement",
                                questionHelperLabel: "Please upload a certified trust deed or partnership agreement, if you operate as a trust or a partnership",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partnershipAgreement.media",
                                    fieldType: components.uploadFile,
                                    title: null,
                                    isVisible: true,
                                    shortTitle: "Trust deed / partnership agreement",
                                    readOnlyComponent: "downloadDocument",
                                    objectDisplayName: "fileName",
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }
                        ],
                        initialValues: {
                            partnershipAgreement: formData.partnershipAgreement
                        }
                    }
                ]
            }
        ]
    };
};

export {
    V3_UPLOAD_CONFIG
};
