const { NUMBER } = require("../../constants/app-constants");

const getConvertedFileSize = (bytes) => {
    let value = bytes / NUMBER.ONE_THOUSAND_TWENTY_FOUR;
    let unit = "KB";
    if (value > NUMBER.ONE_THOUSAND_TWENTY_FOUR) {
        value /= NUMBER.ONE_THOUSAND_TWENTY_FOUR;
        unit = "MB";
    }
    return `${(value).toFixed(NUMBER.TWO)} ${unit}`;
};

export {
    getConvertedFileSize
};
