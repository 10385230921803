import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginSignupModal from "./component";
import { sendOTP } from "../../au.configuration/actions";

const mapStateToProps = ({ user: { mobile, name, isLoggedIn, email } }) => ({
    mobile,
    name,
    isLoggedIn,
    email
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            sendOTPConnect: sendOTP
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignupModal);
