import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import snarkdown from "snarkdown";

const MaximumSavingCard = ({savingCardImage, savingCardHeading, savingCardDetail}) => {
    return (
        <div className="media" styleName={"styles.cardOuter"}>
            <img src={savingCardImage} />
            <div className="media-body">
                <p styleName={"styles.contentWrap"}>{savingCardHeading}</p>
                {savingCardDetail && <p styleName={"styles.cardSubHeading"} dangerouslySetInnerHTML={{ __html: snarkdown(savingCardDetail || "") }} />}
            </div>
        </div>
    );
};

MaximumSavingCard.propTypes = {
    savingCardImage: PropTypes.string,
    savingCardHeading: PropTypes.string,
    savingCardDetail: PropTypes.string
};

export default MaximumSavingCard;
