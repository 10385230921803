import { FILTER_PRIORITY, FILTER_ENTITIES } from "./constants";
import { computeBudgetURL } from "./budget";
import { computeBodyTypeURL } from "./bodytype";
import { computeMakeURL } from "./make";
import { computeFuelTypeURL } from "./fueltype";
import { computeOwnerURL } from "./owners";

class Init {

    constructor() {
        this._entityModifiers = {
            [FILTER_ENTITIES.BUDGET]: computeBudgetURL,
            [FILTER_ENTITIES.BODY_TYPE]: computeBodyTypeURL,
            [FILTER_ENTITIES.CAR_NAME]: computeMakeURL,
            [FILTER_ENTITIES.FUEL_TYPE]: computeFuelTypeURL,
            [FILTER_ENTITIES.OWNERS]: computeOwnerURL
        };
    }

    getPriorityOrder() {
        return FILTER_PRIORITY;
    }

    getEntityModifier(key) {
        return this._entityModifiers[key];
    }
}

export default Init;
