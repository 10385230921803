import React from "react";
import styles from "./styles.css";
import SmarterWayCard from "../../shared/smarter-way-card";
import Card1 from "./images/card-1.png";
import Card2 from "./images/card-2.png";
import Card3 from "./images/card-3.png";
import Card4 from "./images/card-4.png";
import Card5 from "./images/card-5.png";
import Card6 from "./images/card-6.png";

import { InView } from "react-intersection-observer";
import { cmsURl } from "../../../constants/url-constants";

const BuyUsedCarLanding = () => {

    const onChangeInView = () => {
    };

    return (
        <React.Fragment>
            <InView rootMargin={"-150px 0px 0px 0px"} as="div" onChange={onChangeInView} />
            <section styleName={"styles.sectionWrap"}>
                <div className={"container"}>
                    <h2 styleName={"styles.sectionTitle"}>The <strong>smarter way</strong> to buy a used car</h2>
                    <div className="row" styleName={"styles.cardsWrapper"}>
                        <div className="col-4">
                            <SmarterWayCard image={Card1}
                                heading="300-point inspection"
                                detail1="Every car listed for sale has to pass our 300-point checklist and an on-road test. It’s then fully refurbished by our team of experts. We even take care of state roadworthy certificates." />
                        </div>
                        <div className="col-4">
                            <SmarterWayCard image={Card2}
                                heading="Three-month warranty"
                                detail1="Your car is covered by a comprehensive three-month warranty. You can make unlimited claims up to the value of your car inside this time." />
                        </div>
                        <div className="col-4">
                            <SmarterWayCard image={Card3}
                                heading="Seven-day return"
                                detail1="If you decide that your new car isn't a perfect match, we'll pick it up. There are some "
                                link={{text: "terms and conditions", href: `${cmsURl()}/blog/faq/#question2`}}
                                detail2=" including limits on how far you can travel over the seven days."
                            />
                        </div>
                    </div>
                    <div className="row" styleName={"styles.cardsWrapper"}>
                        <div className="col-4">
                            <SmarterWayCard image={Card4}
                                heading="100% online"
                                detail1="CARS24 is changing the way Australians buy used cars. Our cars are all quality assured, so you can buy online with peace of mind. We can arrange finance with an easy five-minute pre-approval process." />
                        </div>
                        <div className="col-4">
                            <SmarterWayCard image={Card5}
                                heading="We own every car we sell"
                                detail1="We only sell top-quality cars. Every car that we list for sale has been thoroughly inspected, test-driven and refurbished. We want you to be thrilled when you drive your new car." />
                        </div>
                        <div className="col-4">
                            <SmarterWayCard image={Card6}
                                heading="Fast home delivery"
                                detail1="Just like anything else you buy online, your car is home-delivered. A CARS24 Carcierge will guide you through a complete handover, and be there to offer assistance over the first seven days." />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default BuyUsedCarLanding;
