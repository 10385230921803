import { createTypes } from "reduxsauce";

export default createTypes(
    `
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE
    DELIVERY_INFO_FETCHING

    GET_PICKUP_CITIES_INIT
    GET_PICKUP_CITIES_SUCCESS
    GET_PICKUP_CITIES_FAILURE
`,
    {
        prefix: "deliveryinfo/"
    }
);
