export const PLATINUM_COVER_GA_EVENTS = {
    PLATINUM_COVER: "Platinum cover",
    SKIP_BUTTONS_SEEN: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons seen",
        eventAction: "Platinum cover page_buttons seen",
        eventLabel: "{{[Skip]}}"
    },
    ADD_PROCEED_BUTTON_SEEN: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons seen",
        eventAction: "Platinum cover page_buttons seen",
        eventLabel: "{{[Add & proceed]}}"
    },
    BUNDLE_SECTION_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_opened",
        eventLabel: "{{[Platinum_cover]}}"
    },
    WARRANTY_SECTION_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_opened",
        eventLabel: "{{[Warranty]}}"
    },
    SERVICING_SECTION_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_opened",
        eventLabel: "{{[Servicing]}}"
    },
    BUNDLE_SECTION_CLOSED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_closed",
        eventLabel: "{{[Platinum_cover]}}"
    },
    WARRANTY_SECTION_CLOSED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_closed",
        eventLabel: "{{[Warranty]}}"
    },
    SERVICING_SECTION_CLOSED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections",
        eventAction: "Platinum cover page_buttons_sections_closed",
        eventLabel: "{{[Servicing]}}"
    },
    WHAT_IS_COVERED_MODAL_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections_platinum_cover_links",
        eventAction: "Platinum cover page_buttons_sections_platinum_cover_links_clicked",
        eventLabel: "{{[What_is_covered]}}"
    },
    HOW_WE_ARE_BETTER_MODAL_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections_platinum_cover_links",
        eventAction: "Platinum cover page_buttons_sections_platinum_cover_links_clicked",
        eventLabel: "{{[How_we_are_better]}}"
    },
    MARKET_COMPARISON_MODAL_OPENED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_sections_platinum_cover_links",
        eventAction: "Platinum cover page_buttons_sections_platinum_cover_links_clicked",
        eventLabel: "{{[Market_comparison]}}"
    },
    CART_PAGE_PROCEEDED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_Cart_page",
        eventAction: "Platinum cover page_Cart_page_proceeded",
        eventLabel: "{{Platinum cover page_Cart_page_proceeded}}"
    },
    CART_PAGE_PRODUCT_REMOVED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_Cart_page",
        eventAction: "Platinum cover page_Cart_page_product_removed",
        eventLabel: "{{[$product]_[$duration]}}" //E.g., Platinum_cover_6 months, Warranty_6 months, Platinum_cover_extend_to_12 months, Servicing_6 months}}'
    },
    PRODUCT_PAGE_ADDED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_product_added",
        eventAction: "Platinum cover page_buttons_product_added & duration",
        eventLabel: "{{[$product]_[$duration]}}"//Platinum_cover_6 months, Warranty_6 months, Platinum_cover_extend_to_12 months, Servicing_6 months
    },
    PRODUCT_PAGE_REMOVE: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_product_removed",
        eventAction: "Platinum cover page_buttons_product_cleared_& duration",
        eventLabel: "{{[$product]_[$duration]}}"//Platinum_cover_6 months, Warranty_6 months, Platinum_cover_extend_to_12 months, Servicing_6 months
    },
    PRODUCT_PAGE_DUPLICATE_POP_UP: {
        event: "custom_event",
        eventCategory: "Platinum cover page_buttons_multi_product",
        eventAction: "Platinum cover page_buttons_product_duplicate_pop_up",
        eventLabel: "{{Duplicate_pop_up_seen}}"
    },
    VIEW_CART_CLICKED: {
        event: "custom_event",
        eventCategory: "Platinum cover page_cart",
        eventAction: "Platinum cover page_View_cart",
        eventLabel: "{{Platinum cover page_View_cart_clicked}}"
    },
    PLATINUM_COVER_VARIANT_SEEN: {
        event: "custom_event",
        eventCategory: "Platinum cover page_variant_seen",
        eventAction: "Platinum cover page_variant_seen_name",
        eventLabel: "{{[$variant]}}"
    }
};
