import React from "react";
import styles from "./styles.css";

const Cars24PlatinumCover = () => {

    return (
        <div styleName={"styles.outer"}>
            <h2>CARS24 Platinum Cover™</h2>
            <p styleName={"styles.text-one"}>CARS24 Platinum Cover warranty is designed to complement your car's existing manufacturer warranty.</p>
        </div>
    );
};

Cars24PlatinumCover.propTypes = {};

export default Cars24PlatinumCover;
