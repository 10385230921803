import React from "react";
import PropTypes from "prop-types";
import { NUMBER } from "../../../../constants/app-constants";
import FinanceDisclaimerTooltipBody from "../../../shared/finance-disclaimer-tooltip-body/component";
import InfoIcon from "./images/info-icon.svg";
import Tooltip from "../../../shared/tooltip";
import styles from "./styles.css";

const Disclaimer = ({ additionalDisclaimers = [], warrantyPlanInfo }) => {
    const { extendedWarranty = {} } = warrantyPlanInfo || {};
    const { comparisonRate = 0, interestRate = 0 } = extendedWarranty || {};

    return (
        <React.Fragment>
            <p styleName={"styles.information"}>
                {(additionalDisclaimers || []).length > 0 && additionalDisclaimers.map((d) => <React.Fragment key={d}>{d}<br/></React.Fragment>)}
            Weekly repayments shown are based on the below rates.
                <br />
            Actual repayments subject to change, based on your quotes.
                <br/>
                {interestRate.toFixed(NUMBER.TWO)}% interest rate (p.a)
                <br/>
                {comparisonRate.toFixed(NUMBER.TWO)}% comparison rate
                <Tooltip heading={``} displayComponent={FinanceDisclaimerTooltipBody}  showComponent={true} displayComponentProp={{ showInHouse: true}}>
                    <span><img src={InfoIcon} /></span>
                </Tooltip>
            </p>
        </React.Fragment>
    );
};

export default Disclaimer;

Disclaimer.propTypes = {
    additionalDisclaimers: PropTypes.array,
    content: PropTypes.object,
    warrantyPlanInfo: PropTypes.object
};
