/* eslint-disable no-undef */

const parse = JSON.parse;
const stringify = JSON.stringify;

export const getItemFromSession = (key) => {
    return parse(sessionStorage.getItem(key)) || null;
};

export const setItemInSession = (key, value) => {
    if (!value) {
        return null;
    }
    return sessionStorage.setItem(key, stringify(value));
};

export const clearItemFromSession = (key) => {
    return sessionStorage.removeItem(key);
};

export const clearAllSessionItem = () => {
    return sessionStorage.clear();
};
