import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Call from "./images/phone-icon.svg";
import Checked from "./images/checked.svg";
import animationData from "./checkout-loader.json";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});

const defaultLottieOptions = {
    loop: true,
    animationData
};

const CallBackSuccess = ({mobile}) => {
    return (
        <div className="media" styleName={"styles.requestCallWrapGreen"}>
            <img src={Checked} />
            <div className="media-body">
                <p styleName={"styles.requestCallGreen"}>Callback request received</p>
                <p styleName={"styles.financeExpertGreen"}>We will call you on {mobile} shortly</p>
            </div>
        </div>
    );
};

CallBackSuccess.propTypes = {
    mobile: PropTypes.string.isRequired
};

export const CallBackRequest = ({ loading = false, onClick, success = false, mobile }) => {
    if (loading) {
        return (<div className="media" styleName={"styles.requestCallWrap"}>
            <Lottie options={defaultLottieOptions} height={42} width={42} speed={2} />
        </div>);
    }

    if (success) {
        return <CallBackSuccess mobile={mobile} />;
    }

    return (
        <div className="media" styleName={"styles.requestCallWrap"} onClick={onClick}>
            <img src={Call} /><div className="media-body">
                <p styleName={"styles.requestCall"}>Request a callback now</p>
                <p styleName={"styles.financeExpert"}>Our finance expert will call you shortly</p>
            </div>
        </div>
    );
};

CallBackRequest.propTypes = {
    loading: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    success: PropTypes.bool.isRequired,
    mobile: PropTypes.string.isRequired
};
