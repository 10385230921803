import FinancePlaceHolder from "../../../components/shared/images/Headshots/finance.webp";
import BuyerPlaceHolder from "../../../components/shared/images/Headshots/buyer.webp";
import { EMAIL_ADDRESS, SESSION_KEYS } from "../../../constants/app-constants";
import { getItemFromSession, setItemInSession } from "../../../utils/helpers/session-storage";

export const PRE_BI_ASSISTANCE_CATEGORIES  = {
    BUYER: "buyer",
    GENERIC: "generic",
    FINANCE: "finance"
};
export const PRE_BI_ASSISTANCE_PROMPT_TEXT_CONFIG = {
    [PRE_BI_ASSISTANCE_CATEGORIES.BUYER]: {
        prompt: "Hey, I need help in buying a car.",
        cdpPrompt: "Hey, I have some questions regarding dynamic_makeModel"
    },
    [PRE_BI_ASSISTANCE_CATEGORIES.FINANCE]: {
        prompt: "Hey, I need help with financing a car."
    }
};

export const BUYER_ASSISTANT_NAMES = {
    BUYER_ASSISTANT: "Thomas",
    FINANCE_ASSISTANT: "Swaps"
};

export const CONFIG_TYPES = {
    FINANACE: "FINANCE_NUDGE_CONFIG",
    BUYER: "B2C_NUDGE_CONFIG"
};

export const GENERIC_EMOJI_CODE = {
    HELLO: "👋",
    WELCOME_BACK: "🤝"
};

export const PRE_BI_ASSISTANCE_CONFIG =  {
    newUser: {
        CLP: {
            CLP_LAND: {
                heading: "Welcome to CARS24!",
                bodyText: "We are here to help you find your dream car.",
                imagePath: null,
                emojiCode: GENERIC_EMOJI_CODE.HELLO,
                category: PRE_BI_ASSISTANCE_CATEGORIES.GENERIC
            },
            CLP_REACH_END: {
                heading: "Couldn’t find what you are looking for?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            },
            FFD_CLP_LAND: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance car advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            },
            CLP_LAND_FROM_PRE_APPROVAL_FLOW: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance car advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            }
        },
        CDP: {
            CDP_VIEW_TWICE: {
                heading: "Still have questions about the dynamic_makeModel?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            },
            CDP_CLOSE_LOAN_CALCULATOR: {
                heading: "Having trouble getting finance on dynamic_makeModel?",
                bodyText: `dynamic_name, your finance advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            },
            SCROLL_PAST_INSPECTION_REPORT: {
                heading: "Want to chat more on car quality?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            }
        },
        HOMEPAGE: {
            HOME_PAST_SECOND_FOLD: {
                heading: "Not sure what you are looking for?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            },
            HOME_LAND_FROM_PRE_APPROVAL_FLOW: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance car advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            }
        }
    },
    repeatUser: {
        CLP: {
            CLP_LAND_SECOND_SESSION: {
                heading: "Welcome back!",
                bodyText: "We’ve got fresh cars in. Let us help you find your dream ride.",
                imagePath: null,
                emojiCode: GENERIC_EMOJI_CODE.WELCOME_BACK,
                category: PRE_BI_ASSISTANCE_CATEGORIES.GENERIC
            },
            FFD_LAND_SECOND_SESSION: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            },
            PRE_APPROVAL_FLOW_DROPPED_USER: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            }
        },
        CDP: {
            CDP_VIEW_CAR_AGAIN: {
                heading: "Still have questions about the dynamic_makeModel?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            }
        },
        HOMEPAGE: {
            HOME_SECOND_SESSION_NO_RV_CARS: {
                heading: "Welcome back!",
                bodyText: "We’ve got fresh cars in. Let us help you find your dream ride.",
                imagePath: null,
                emojiCode: GENERIC_EMOJI_CODE.WELCOME_BACK,
                category: PRE_BI_ASSISTANCE_CATEGORIES.GENERIC
            },
            HOME_SECOND_SESSION_WITH_RV_CARS: {
                heading: "Still have questions about the dynamic_makeModel?",
                bodyText: `dynamic_name, your car advisor is here to help you.`,
                imagePath: BuyerPlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.BUYER
            },
            HOME_FFD_INTERACTION: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            },
            HOME_PRE_APPROVED_USED_PREVIOUSLY: {
                heading: "Having trouble sorting out finance?",
                bodyText: `dynamic_name, your finance advisor is here to help you.`,
                imagePath: FinancePlaceHolder,
                category: PRE_BI_ASSISTANCE_CATEGORIES.FINANCE
            }
        }
    }
};

export const updateSessionStorageCount = (category) => {
    const sessionStorageCount = getItemFromSession(SESSION_KEYS.CATEGORY_NUDGE_COUNT);
    setItemInSession(SESSION_KEYS.CATEGORY_NUDGE_COUNT, {
        ...sessionStorageCount,
        [category]: (sessionStorageCount[category] || 0) + 1
    });
};

export const PRE_BI_ASSISTANCE_NUDGE_TIMEOUT = 5000;
export const PRE_BI_ASSISTANCE_NUDGE_FIRE_DELAY = 5000;
export const PRE_BI_ASSISTANCE_FLOW_DROPPED_DELAY = 3000;

export const EMAIL_CONFIG = {
    C2B: {
        address: EMAIL_ADDRESS.C2B,
        subject: "Assistance required"
    },
    B2C: {
        address: EMAIL_ADDRESS.B2C,
        subject: "Assistance required"
    }
};
