import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const InputRadioButton = ({
    id = "",
    text = "",
    ...rest
}) => {

    return (
        <div styleName={"styles.inputRadioButton"}>
            <input id={id} type="radio" name="radio-group" {...rest} />
            <label htmlFor={id}>{text}</label>
        </div>
    );
};

InputRadioButton.propTypes = {
    text: PropTypes.string,
    id: PropTypes.string
};
export default InputRadioButton;
