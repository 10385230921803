import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";

import styles from "./styles.css";

/* eslint-disable react/react-in-jsx-scope */
const VasCartSummary = ({ vasCart, bundleValue }) => {
    const { data } = vasCart || {};
    const { value = {} } = data || {};
    const { totalAmount, totalDiscount, netPayable } = typeof bundleValue === "undefined" ? value : bundleValue || {};

    return (
        <div styleName={"styles.wrapper"}>
            {totalAmount > 0 &&
                <div styleName={"styles.totalItemWrap"}>
                    <p styleName={"styles.title"}>Add-ons total</p>
                    <p styleName={"styles.title"}>{makePriceLabelRound(totalAmount)}</p>
                </div>
            }
            {totalDiscount > 0 &&
                <div styleName={"styles.totalItemWrap"}>
                    <p styleName={"styles.title"}>Bundle discount</p>
                    <p styleName={"styles.amount"}>-{makePriceLabelRound(totalDiscount)}</p>
                </div>
            }
            {netPayable > 0 &&
                <div styleName={"styles.totalItemWrap styles.borderTop"}>
                    <p styleName={"styles.title styles.strong"}>Net payable</p>
                    <p styleName={"styles.title styles.strong"}>{makePriceLabelRound(netPayable)}</p>
                </div>
            }
        </div>
    );
};

VasCartSummary.propTypes = {
    bundleValue: PropTypes.object,
    vasCart: PropTypes.object
};

const mapStateToProps = ({
    carDetails: {
        content: {
            appointmentId
        }
    },
    checkout: {
        order: {
            orderId
        }
    },
    addOns: {
        vasCart
    }
}) => ({
    appointmentId,
    orderId,
    vasCart
});

export default connect(mapStateToProps)(VasCartSummary);
