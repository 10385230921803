/* eslint-disable complexity */
/* eslint-disable max-params */

export const checkIfFilterExists = ({filterKey, optionKey, subOptionKey, isRangeFilter}, selectedFilters) => {
    let existingFilter = [];

    if (isRangeFilter) {
        return false;
    } else {
        existingFilter = (selectedFilters || []).filter(selectedFilter => selectedFilter.filterKey === filterKey &&
            (optionKey ? selectedFilter.optionKey === optionKey : true) && ((subOptionKey ? selectedFilter.subOptionKey === subOptionKey : true))
        )[0];
    }
    return !!existingFilter;
};

const addFilter = ({filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues, spath, subFilterKey}, selectedFilters) => {
    let updatedAppliedFilters = [
        ...selectedFilters
    ];
    if (!checkIfFilterExists({filterKey, optionKey, subOptionKey, isRangeFilter}, selectedFilters)) {
        updatedAppliedFilters = updatedAppliedFilters.concat({
            filterKey,
            ...(optionKey && {optionKey}),
            ...(subOptionKey && {subOptionKey}),
            displayText,
            ...(isRangeFilter && rangeValues && {isRangeFilter, min: rangeValues.min, max: rangeValues.max}),
            ...(spath && {spath}),
            ...(subFilterKey && {subFilterKey})
        });
    }
    return updatedAppliedFilters;
};

const removeFilter = ({filterKey, optionKey, subOptionKey}, selectedFilters) => {
    let updatedAppliedFilters = [];
    updatedAppliedFilters = selectedFilters.filter((filter) => {
        const isFilterKeyMatched = filterKey === filter.filterKey;
        const isOptionKeyMatched = optionKey ? optionKey === filter.optionKey : true;
        const isSubOptionKeyMatched = subOptionKey ? subOptionKey === filter.subOptionKey : true;

        return !(isFilterKeyMatched && isOptionKeyMatched && isSubOptionKeyMatched);
    });

    return updatedAppliedFilters;
};

const getUpdatedAppliedFilters = ({filterKey, optionKey, subOptionKey, isSelected, displayText, isRangeFilter, rangeValues, isSingleEntity, isAllModelVariant = false, spath, subFilterKey}, selectedFilters) => {
    if (isRangeFilter || isSingleEntity) {
        let updatedFilters = [];
        if (isSelected) {
            updatedFilters = removeFilter({filterKey}, selectedFilters);
            updatedFilters = addFilter({filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues, spath, subFilterKey}, updatedFilters);
        } else {
            return removeFilter({filterKey, optionKey, subOptionKey}, selectedFilters);
        }
        return updatedFilters;
    } else if (isSelected) {
        let updatedFilter =  selectedFilters;
        const isMatchFound =  selectedFilters.find(item => item.optionKey === optionKey && !item.subOptionKey);
        if (isAllModelVariant && isMatchFound) {
            updatedFilter = removeFilter({filterKey, optionKey: isMatchFound.optionKey}, updatedFilter);
        }
        return addFilter({filterKey, optionKey, subOptionKey, displayText, isRangeFilter, rangeValues, spath, subFilterKey}, updatedFilter);
    } else {
        return removeFilter({filterKey, optionKey, subOptionKey}, selectedFilters);
    }

};

export const getUpdatedSubFilterOptions = ({filterKey, optionKey, isSelected, selectedFilters, displayText, allFilters, isAllModelVariant = false}) => {
    const selectedFilter = allFilters[filterKey];
    const selectedOption = selectedFilter.options.filter(filterOption => filterOption.key === optionKey)[0] || {};
    const selectedSubFilterOptions = selectedOption.subFilter.options;

    let updatedFilters = [
        ...selectedFilters
    ];

    if (isAllModelVariant && filterKey === "make") {
        if (isSelected) {
            updatedFilters.forEach((subOption) => {
                if (optionKey === subOption.optionKey) {
                    updatedFilters = removeFilter({filterKey, optionKey, subOptionKey: subOption.key}, updatedFilters);
                }
            });
            updatedFilters = addFilter({filterKey, optionKey, subOptionKey: "", displayText}, updatedFilters);
        } else {
            updatedFilters = removeFilter({filterKey, optionKey}, updatedFilters);

        }

    } else {

        selectedSubFilterOptions.forEach((subOption) => {
            if (isSelected) {
                updatedFilters = addFilter({filterKey, optionKey, subOptionKey: subOption.key, displayText: subOption.displayText}, updatedFilters);
            } else {
                updatedFilters = removeFilter({filterKey, optionKey, subOptionKey: subOption.key}, updatedFilters);
            }
        });
    }

    return updatedFilters;
};

export default getUpdatedAppliedFilters;
