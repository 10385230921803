/* eslint-disable max-len */
import { appUrl, currentEnv, ppApiUrl } from "./url-constants";

export const NUMBER = {
    ZERO: 0,
    DECIMAL_FIVE: 0.5,
    DECIMAL_ZERO_FIVE: 0.05,
    DECIMAL_EIGHT: 0.8,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    TWELVE: 12,
    THIRTEEN: 13,
    FOURTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_TWO: 22,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    THIRTY_FOUR: 34,
    FOURTY: 40,
    FIFTY: 50,
    SIXTY: 60,
    HUNDRED: 100,
    ONE_HUNDRED_FIFTY: 150,
    TWO_HUNDRED: 200,
    TWO_HUNDRED_EIGHTY: 280,
    ONE_HUNDRED_EIGHTY: 180,
    THREE_HUNDRED: 300,
    FIVE_HUNDRED: 500,
    SEVEN_HUNDRED: 700,
    THOUSAND: 1000,
    ONE_THOUSAND_TWENTY: 1020,
    ONE_THOUSAND_TWENTY_ONE: 1021,
    ONE_THOUSAND_TWENTY_TWO: 1022,
    ONE_THOUSAND_THIRTY_TWO: 1032,
    ONE_THOUSAND_FIFTY_FOUR: 1054,
    ONE_THOUSAND_FIFTY_FIVE: 1055,
    TWO_THOUSAND: 2000,
    THREE_THOUSAND: 3000,
    FOUR_THOUSAND: 4000,
    FIVE_THOUSAND: 5000,
    TEN_THOUSAND: 10000,
    TWENTY_FIVE_THOUSAND: 25000,
    FIFTY_THOUSAND: 50000,
    ONE_LAKH: 100000,
    TEN_LAKH: 1000000,
    THIRTY_LAKH: 3000000,
    NINETY_NINE_LAKH: 9900000,
    TWENTY_ONE: 21,
    TWENTY_SIX: 26,
    THIRTY_ONE: 31,
    THIRTY_SIX: 36,
    FOURTY_ONE: 41,
    FOURTY_SIX: 46,
    NINETEEN_SEVENTY: 1970,
    MINUS_TWENTY: -20,
    TWENTY_THOUSAND: 20000,
    FIFTY_FIVE_HUNDRED: 5500,
    FIFTEEN_HUNDRED: 1500,
    ONE_THOUSAND_TWELVE: 1012,
    ONE_THOUSAND_FOURTEEN: 1014,
    ONE_THOUSAND_THIRTEEN: 1013,
    ONE_THOUSAND_TWENTY_FIVE: 1025,
    ONE_THOUSAND_TWENTY_SEVEN: 1027,
    MINUS_THREE: -3,
    THOUSAND_SEVEN: 1007,
    SIXTY_FIVE_THOUSAND: 65000,
    ONE_LAKH_FIFTY_THOUSAND: 150000,
    THREE_LAKH_FIFTY_THOUSAND: 350000,
    FOUR_HUNDRED_FOUR: 404,
    FOUR_HUNDRED_ONE: 401,
    THOUSAND_THREE: 1003,
    NINTY_NINE: 99,
    FOUR_HUNDRED: 400,
    TWO_THOUSAND_FOURTEEN: 2014,
    FOURTEEN_LAKH: 140000,
    ONE_LAKH_FORTY_THOUSAND: 140000
};

export const seoTemplateType = {
    MAKE: "make",
    CITY: "city",
    MAKE_CITY: "makeCity",
    MAKE_CITY_LOCATION: "makeCityLocation",
    MAKE_MODEL: "makeModel",
    MAKE_MODEL_CITY: "makeModelCity",
    MAKE_MODEL_CITY_LOCATION: "makeModelCityLocation",
    CITY_LOCATION: "cityLocation",
    GENERIC: "generic"
};

export const SAVE_EMAIL_CODE = {
    SUCCESS: 1,
    FAILED: 3,
    NEUTRAL: 2
};

export const urlExpandParam = {
    RECENTLY_INSPECTED: "recently_inspected",
    BLOG_LIST: "blog_list",
    SEO_CONTENT: "seo_content",
    TESTIMONIAL_LIST: "testimonial_list",
    EXPERTS_LIST: "experts_list",
    CITY_LIST: "city_list",
    MAKE_LIST: "make_list"
};

export const resetReducerKeys = {
    RESET_CAR_FINDER: "RESET_CAR_FINDER",
    RESET_BRANDS: "RESET_BRANDS",
    RESET_MODELS: "RESET_MODELS",
    RESET_YEARS: "RESET_YEARS",
    RESET_VARIANTS: "RESET_VARIANTS",
    RESET_REGISTRATION_STATES: "RESET_REGISTRATION_STATES",
    RESET_KILOMETERS_DRIVEN: "RESET_KILOMETERS_DRIVEN",
    RESET_BLOGS: "RESET_BLOGS",
    RESET_TESTIMONIALS: "RESET_TESTIMONIALS",
    RESET_EXPERTS: "RESET_EXPERTS",
    RESET_FAQS: "RESET_FAQS",
    RESET_RECENTLY_INSPECTED: "RESET_RECENTLY_INSPECTED",
    RESET_FOOTER: "RESET_FOOTER",
    META_TAGS: "RESET_META_TAGS",
    RESET_NEWS: "RESET_NEWS",
    RESET_LEAD_DATA: "RESET_LEAD_DATA",
    RESET_SEO_MODELS: "RESET_SEO_MODELS",
    RESET_BRANCH_LOCATION: "RESET_BRANCH_LOCATION",
    RESET_CITIES: "RESET_CITIES",
    RESET_APPOINTMENT_LOCATION: "RESET_APPOINTMENT_LOCATION",
    RESET_APPOINTMENT_DETAILS: "RESET_APPOINTMENT_DETAILS",
    RESET_USER: "RESET_USER",
    RESET_LOCATION: "RESET_LOCATION",
    RESET_RESCHEDULE: "RESET_RESCHEDULE",
    RESET_CONFIRMATION_DETAILS: "RESET_CONFIRMATION_DETAILS",
    RESET_SETTINGS: "RESET_SETTINGS",
    RESET_WIDGET: "RESET_WIDGET",
    RESET_REVIEW: "RESET_REVIEW",
    RESET_BRANCH_LOCATOR: "RESET_BRANCH_LOCATOR",
    RESET_ONE_STEP_APPOINTMENT: "RESET_ONE_STEP_APPOINTMENT",
    RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",
    RESET_REVIEW_SUBMISSION: "RESET_REVIEW_SUBMISSION",
    RESET_RC_TRANSFER: "RESET_RC_TRANSFER",
    RESET_TOP_SELLING_USED_CARS: "RESET_TOP_SELLING_USED_CARS",
    RESET_NEED_HELP: "RESET_NEED_HELP",
    RESET_SEO_TESTIMONIALS: "RESET_SEO_TESTIMONIALS",
    RESET_RATED_TESTIMONIALS: "RESET_RATED_TESTIMONIALS",
    RESET_BRANCH_FILTER: "RESET_BRANCH_FILTER",
    RESET_TRUECALLER: "RESET_TRUECALLER",
    RESET_RTO_INFO: "RESET_RTO_INFO",
    RESET_LEAD_BRANCHES: "RESET_LEAD_BRANCHES",
    RESET_LEAD_BRANCH_DATE: "RESET_LEAD_BRANCH_DATE",
    RESET_LEAD_PROGRESS_BAR: "RESET_LEAD_PROGRESS_BAR",
    RESET_SEO_DETAILS: "RESET_SEO_DETAILS",
    RESET_AB_EXPERIMENTS: "RESET_AB_EXPERIMENTS",
    RESET_CAR_LIST: "RESET_CAR_LIST",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_CONFIG: "RESET_CONFIG",
    RESET_FUEL_PRICE: "RESET_FUEL_PRICE",
    RESET_LEAD_RTO: "RESET_LEAD_RTO",
    RESET_CAR_DETAILS: "RESET_CAR_DETAILS",
    RESET_EXPLORE_USED_CARS: "RESET_EXPLORE_USED_CARS",
    RESET_SIMILAR_CARS: "RESET_SIMILAR_CARS",
    RESET_RECENTLY_VIEWED_CARS: "RESET_RECENTLY_VIEWED_CARS",
    RESET_SELECT_OFFER_PRICE: "RESET_SELECT_OFFER_PRICE",
    RESET_PARKING_YARD: "RESET_PARKING_YARD",
    FEATURED_CARS: "FEATURED_CARS",
    RESET_CONTENT_HOOK: "RESET_CONTENT_HOOK",
    RESET_LEGACY_LEAD_FORM: "RESET_LEGACY_LEAD_FORM",
    RESET_SELL_BIKE: "RESET_SELL_BIKE",
    RESET_SCRIPT_LOADER: "RESET_SCRIPT_LOADER",
    RESET_REFER: "RESET_REFER",
    RESET_FRANCHISE_FORM: "RESET_FRANCHISE_FORM",
    RESET_PARTNER_FORM: "RESET_PARTNER_FORM",
    RESET_LOAN_FORM: "RESET_LOAN_FORM",
    RESET_BIKE_FORM: "RESET_BIKE_FORM",
    RESET_BIKE_BRANDS: "RESET_BIKE_BRANDS",
    RESET_BIKE_BRANCHES: "RESET_BIKE_BRANCHES",
    RESET_BIKE_BRANCH_DATE: "RESET_BIKE_BRANCH_DATE",
    RESET_CLASSIFIED: "RESET_CLASSIFIED",
    MAKE_AN_OFFER: "MAKE_AN_OFFER",
    USED_CAR_LEAD_INFO: "USED_CAR_LEAD_INFO",
    RESET_MARKETING_LEAD_FORM: "RESET_MARKETING_LEAD_FORM",
    RESET_LEAD_BRANCH_SELECTIONS: "RESET_LEAD_BRANCH_SELECTIONS",
    RESET_TOP_SELLING_CARS: "RESET_TOP_SELLING_CARS",
    RESET_FEATURED_CARS: "RESET_FEATURED_CARS",
    RESET_EXCHANGE_CAR_DETAILS: "RESET_EXCHANGE_CAR_DETAILS",
    RESET_TOP_DEALS_REDUCER: "RESET_TOP_DEALS_REDUCER",
    RESET_LISTING_INFO: "RESET_LISTING_INFO",
    RESET_LOAN_CALCULATION_DATA: "RESET_LOAN_CALCULATION_DATA",
    RESET_BUYER_SELLER_DETAIL: "RESET_BUYER_SELLER_DETAIL",
    RESET_BIKE_RESCHEDULE: "RESET_BIKE_RESCHEDULE",
    RESET_BIKE_CONFIRMATION_DETAILS: "RESET_BIKE_CONFIRMATION_DETAILS",
    RESET_WISHLISTED_CARS: "RESET_WISHLISTED_CARS",
    RESET_WISHLISTED_LOGIN: "RESET_WISHLISTED_LOGIN",
    RESET_TOAST_MESSAGE: "RESET_TOAST_MESSAGE",
    RESET_CAR_FINDER_LISTING: "RESET_CAR_FINDER_LISTING",
    RESET_CAR_FINDER_CAR_LIST: "RESET_CAR_FINDER_CAR_LIST",
    RESET_CAR_SERVICES_DATA: "RESET_CAR_SERVICES_DATA",
    RESET_CAR_SERVICE_LEAD_FORM: "RESET_CAR_SERVICE_LEAD_FORM",
    RESET_CAR_SERVICE_INFO: "RESET_CAR_SERVICE_INFO",
    RESET_CAR_SERVICE_BOOK: "RESET_CAR_SERVICE_BOOK",
    RESET_LEAD_FUEL_TYPE: "RESET_LEAD_FUEL_TYPE",
    RESET_DEAL_PAYMENT_INFO: "RESET_DEAL_PAYMENT_INFO",
    RESET_CARS24_SELECT: "RESET_CARS24_SELECT",
    RESET_GS_BUY_CAR_DETAILS: "RESET_GS_BUY_CAR_DETAILS",
    RESET_CONSUMER_FINANCE: "RESET_CONSUMER_FINANCE",
    RESET_MY_BOOKINGS: "RESET_MY_BOOKINGS",
    RESET_LOCATION_PICKER_MODAL: "RESET_LOCATION_PICKER_MODAL",
    RESET_CHECKOUT: "RESET_CHECKOUT",
    RESET_CHAT_ICON: "RESET_CHAT_ICON",
    RESET_CALLBACK_DETAILS: "RESET_CALLBACK_DETAILS",
    RESET_C24Q_PRICE: "RESET_C24Q_PRICE",
    RESET_CUSTOMER_CAR_INSPECTION_PARAMETER: "RESET_CUSTOMER_CAR_INSPECTION_PARAMETER",
    SAVED_FILTERS: "SAVED_FILTERS",
    RESET_RECOMMENDED_CARS: "RESET_RECOMMENDED_CARS",
    RESET_DELIVERY_INFO: "RESET_DELIVERY_INFO",
    RESET_HOME_LIST: "RESET_HOME_LIST",
    RESET_SITEMAP_LIST: "RESET_SITEMAP_LIST",
    RESET_NUDGE_LIST: "RESET_NUDGE_LIST"
};

export const stringConstants = {
    YES: "yes",
    NO: "no"
};

//added undefiend for local env - has to tbe removed
const baseUrl = {
    privateProduct: {
        QA: "https://consumer-private-web-qa.ninja24.in/account",
        STAGING: "https://stage-c2b-website.qac24svc.dev/account",
        LNOD: "https://stage-c2b-website.qac24svc.dev/account",
        PRODUCTION: "https://www.cars24.com/account"
    },
    c2c: {
        QA: "http://c2cweb-qa.ninja24.in/buy-used-cars",
        STAGING: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        // STAGING: "http://localhost:3000//buy-used-cars",
        LNOD: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        PRODUCTION: "https://www.cars24.com/buy-used-cars"
    },
    c2bPHP: {
        STAGING: "https://staging.cars24.com",
        PRODUCTION: "https://www.cars24.com"
    }
};

export const redirect = {
    AUTH_LOGIN: `${baseUrl.privateProduct[currentEnv]}/login`,
    AUTH_APPOINTMENTS: `${baseUrl.privateProduct[currentEnv]}/appointments`,
    AUTH_BIDS: `${baseUrl.privateProduct[currentEnv]}/bids`,
    AUTH_PROFILE: `${baseUrl.privateProduct[currentEnv]}/profile`,
    c2C: (path = "") => `${baseUrl.c2c[currentEnv]}${path}`,
    CARS24_HOME: appUrl,
    BYE_BYE_DRIVE: `${appUrl()}/byebyedrive/`,
    BLOG: `${appUrl()}/blog`,
    RC_TRANSFER: `${appUrl()}/rc-transfer`,
    HOW_IT_WORKS: `${appUrl()}/how-it-works`,
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: `${appUrl()}/faq`,
    FAQ_AE: `${appUrl()}/ae/faq`,
    HELP: `${appUrl()}/need-help`,
    BECOME_OUR_PARTNER: `${appUrl()}/become-our-partner`,
    BRANCHES: `${appUrl()}/centres`,
    CAR_LOAN: `${appUrl()}/car-loan`,
    TESTIMONIALS: `${appUrl()}/testimonials/`,
    GOOGLE_PLAY_STORE_APP: "https://play.google.com/store/apps/details?id=com.cars24.seller&hl=en_IN",
    APPLE_STORE_APP: "https://itunes.apple.com/in/app/cars24-sell-car-at-best-price/id1364492085?mt=8",
    FINANCIAL_SERVICES_TERMS_CONDITIONS: "https://img.24c.in/cars24/docs/finance/terms_&_conditions.pdf",
    CAR_SERVICE: `${appUrl()}/car-service?itm_source=car_service&itm_medium=nav_bar`,
    MY_BOOKINGS: `${appUrl()}/my-bookings`
};

export const appRedirect = {
    HOME: "/",
    BLOG: "/blog",
    RC_TRANSFER: "/rc-transfer",
    HOW_IT_WORKS: "/how-it-works",
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: "/faq",
    HELP: "/need-help",
    BECOME_OUR_PARTNER: "/become-our-partner",
    BRANCHES: "/centres",
    SALE_TERMS: "/terms-of-sale/"
};

export const YOUTUBE_THUMBNAIL = "https://img.youtube.com/vi/4vArfT4vSCg/maxresdefault.jpg";

export const CITY_KEY = "selectedCity";

export const MISSED_CALL_CHECK_INTERVAL = 30000;

export const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Transmission: "transmission",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    KilometerDriven: "kilometersDriven",
    Branch: "branch",
    Date: "date",
    Time: "time",
    Override: "override",
    User: "user",
    Location: "location",
    Price: "price",
    Body: "body",
    Priority: "priority",
    CarService: "carService",
    Personal: "personal",
    Residential: "residential",
    Income: "income"
};

export const OTP_MAX_LENGTH = 4;
export const OTP_MAX_LENGTH_EMAIL = 6;

export const HTTP_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    SUCCESS: 200,
    FOUND: 302,
    MOVED_PERMANENTLY: 301
};

export const KEY_DOWN_CODES = {
    DELETE: 8
};

export const LEAD_STATUSES = {
    CREATED: "Created",
    CAPTURED: "AppointmentCaptured"
};

export const DEFAULT_SLIDER_SETTINGS = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrow: true
};

export const BRANCH_STORE_TYPES = ["franchise", "store"];

export const BRANCHES_TOP_OFFSET = 170;

export const BRANCH_LIST_RIGHT_OFFSET = 275;

export const LOCAL_STORAGE_KEYS = {
    userProfile: "userProfile"
};

export const FEATURE_FLAG_KEYS = {
    KBB_FEATURE_FLAG: "kbbFeatureFlag"
};

export const DEFAULT_MOBILE_SLIDER_SETTINGS = {
    slidesToShow: 1.14,
    slidesToScroll: 1,
    infinite: false,
    arrow: false
};

export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg"];

export const USED_CAR_ALLOWED_CITIES = [];

export const COOKIE_SEARCH_KEYWORDS = "c2b-searched-keywords";

export const CITY_NOT_LISTED = {
    background_image: null,
    background_image_mobile: null,
    city_name: "My city is not listed here"
};

export const IGNORE_PRIVATE_PRODUCT_APIS = [`${ppApiUrl()}/pp/ums/users`];

export const ALTERNATE_LEAD_CITIES = ["769", "648", "650"];
export const RELEVANT_ORDER_EXPERIMENT_CITIES = ["2130", "2423", "4709", "5", "2", "6", "132", "134", "8597"];
export const PRICE_REVISION_CITIES = ["2378", "777", "5732"];
// export const DEAL_RATING_IGNORED_CITIES = [...RELEVANT_ORDER_EXPERIMENT_CITIES];
export const DEAL_RATING_IGNORED_CITIES = [];

export const LEAD_FORM = {
    DEFAULT: "default",
    ONE_STEP: "oneStep",
    BIKE: "bike",
    MARKETING: "marketing",
    CARFINDER: "carfinder",
    CAR_SERVICE: "carService",
    FINANCE_FORM: "financeForm"
};

export const COLUMN_MAPPING = {
    1: "col-12",
    2: "col-6",
    3: "col-4",
    4: "col-3",
    6: "col-2",
    12: "col-1"
};

export const EXPERIMENTS = {};

export const LOCATION_REQUEST_TYPE = {
    COORDINATES: "coordinates",
    PLACE: "place"
};

export const MAP_TYPE = {
    GOOGLE: "googlemap",
    MMI: "mapmyindia"
};

export const DISPOSITION_STATUS = {
    SOLD: "SOLD",
    PUBLISHED: "PUBLISHED",
    BOOKED: "BOOKED",
    LOST: "LOST",
    RE_PUBLISH: "RE-PUBLISH",
    RE_PUBLISH_1: "RE_PUBLISH",
    AUCTIONED: "AUCTIONED",
    REJECTED: "REJECTED",
    DRAFT: "DRAFT"
};

export const CAR_LIST_DISPLAY_TYPES = {
    CARD: "card",
    LIST: "list"
};

export const PRICE_RANGE_THRESHOLD = 1500000;
export const PRICE_RANGE_STEP = 10000;
export const PRICE_RANGE_BAR_GRAPH_STEP = 40000;
export const MAX_RANGE_THRESHOLD = 9999999;

export const DEFAULT_C2C_CITY = {
    city_id: "8597",
    city_name: "Delhi-NCR",
    city_slug: "delhi-ncr",
    pincode: "110001"
};

export const DEFAULT_BIKE_CITY = {
    city_id: "2",
    city_name: "New Delhi",
    city_slug: "new-delhi"
};

export const VIRUTAL_CITY = {
    city_id: DEFAULT_C2C_CITY.city_id,
    city_name: DEFAULT_C2C_CITY.city_name,
    city_slug: DEFAULT_C2C_CITY.city_slug,
    isReplicaOfCity: "2"
};

export const DEFAULT_INTEREST_RATE = 18;
// export const INTEREST_INCREMENTAL_RATE = 0.1;

export const AD_TYPE = {
    INSTANTLY_AVAILABLE: "instantlyAvailable",
    MONEYBACK_GUARANTEE: "moneybackGuarantee",
    GET_CAR_PRICE: "getCarPrice",
    FINANCE_AVAILABLE: "financeAvailable",
    WARRANTY_AVAILABLE: "warrantyAvailable",
    PRICE_DROP_AVAILABLE: "priceDropAvailable",
    VIEW_360_AVAILABLE: "view360Available",
    CAR_FINDER: "carFinder",
    DEAL_RATING: "dealRating"
};

export const MIN_LOAN_AMOUNT = 50000;

export const CAR_LIST_EVENT_NAMES = {
    LISTING: "Buy_used_cars_C2C_Car_Listing_Page",
    B2C_LISTING: "Buy_used_cars_B2C_Car_Listing_Page",
    MONEYBACK: "Buy_used_cars_C2C_Car_Listing_Page_Moneyback",
    INSTANTLY_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Instantly_Available",
    FINANCE_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Finance_Available",
    PRICE_DROP: "Buy_used_cars_C2C_Car_Listing_Page_Price_Drop",
    HOME_PAGE: "Buy_used_cars_C2C_Car_Home_Page",
    B2C_HOME_PAGE: "Buy_used_cars_B2C_Homepage",
    FILTER_PAGE: "Buy_used_cars_C2C_filter_page",
    DETAILS: "Buy_used_cars_C2C_Car_Details_Page",
    VIEW_360_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_View_360_Available",
    WHISLIST: "Buy_used_cars_C2C_Whislist_page",
    DETAIL_SIMILAR_CARS: "Buy_used_cars_C2C_Car_detail_page_similiar_cars"
};

export const CREDR_VALID_CITIES = ["4709"];

export const BANNER_LOCATIONS = {
    experts_say: "experts_say",
    featured_tweets: "featured_tweets",
    faq: "faq"
};

export const SCRIPT_TYPES = {
    mmi: "mmi"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};

export const HIDE_POPUP_FOR_URLS = [
    /([a-z]*)(confirmation)([a-z]*)/gi,
    /([a-z]*)(customer-review)([a-z]*)/gi,
    /(\/mobile-apps)/gi,
    /(offer|car-prices|specifications|images|variants|reviews|colors|mileage|service-cost|faqs|rto-vehicle-registration-details|-prices-in-|car-finder)/gi,
    /(mycar)([a-z]*)/gi,
    /(buyer-details)([a-z]*)/gi,
    /(seller-details)([a-z]*)/gi,
    /tokenpayment([a-z]*)/gi,
    /cars24-select/gi
];

export const PATHS = [
    /(\/centres)/gi,
    /(\/mobile-apps)/gi,
    /(\/customer-review)/gi,
    /(\/faq)/gi,
    ...HIDE_POPUP_FOR_URLS
];

export const CTA_LOCATIONS = {
    LIST_SELL_CAR: "classified",
    INSTANT_SELL_CAR: "instant_sell_car",
    REFINANCE: "REFINANCE"
};

export const PRICES = {
    GREAT: "great",
    GOOD: "good",
    FAIR: "fair",
    OVER_PRICED: "overpriced"
};

export const BIKE_BOOKING_STATUS_TYPE = {
    BOOKED: "BOOKED",
    CONFIRMED: "CONFIRMED",
    INSPECTED: "INSPECTED",
    PURCHASED: "PURCHASE"
};

export const TEST_DRIVE_BOOKING_STATUS_MESSAGE = {
    BOOKED: "Test drive booked",
    COMPLETED: "Test drive conducted",
    CANCELLED: "Test drive has been cancelled",
    RESCHEDULED: "",
    CANCELLING: ""
};

export const TEST_DRIVE_BOOKING_STATUS_TYPE = {
    BOOKED: "BOOKED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    RESCHEDULED: "RESCHEDULED",
    CANCELLING: "CANCELLING"
};

export const APPOINTMENT_TYPE = {
    BRANCH: "branch",
    HOME: "inspection",
    CRM: "CRM"
};

export const APPOINTMENT_SCREEN = {
    DEFAULT: "default",
    ADDRESS: "address",
    DOCUMENT: "document"
};

export const FORM_TYPE = {
    CAR: "car",
    BIKE: "bike",
    CLASSIFIED_RESCHEDULE: "classified_reschedule",
    ONE_STEP: "one_step",
    BIKE_RESCHEDULE: "bike_reschedule",
    PAYWALL_BOOK_NOW: "paywall_book_now",
    PAYWALL_MAKE_OFFER: "paywall_make_offer",
    GS_BOOK_NOW: "gs_book_now",
    TOKEN_PAYMENT: "token_payment",
    VONE_STEP: "vone_step",
    CHECKOUT: "checkout"
};
// eslint-disable-next-line no-magic-numbers
export const MAX_ALLOWED_UPLOAD_SIZE = 5 * 1024 * 1000;

export const APPOINTMENT_LOCATION_TYPE = {
    LOCALITY: "locality",
    BIKE_LOCALITY: "bike_locality"
};

export const MAKE_AN_OFFER_SOURCE = {
    EMI: "emi",
    DETAILS: "details",
    GALLERY: "gallery"
};
export const LOC_ID = {
    HOME: 3,
    BRANCH: 1,
    CLASSIFIED: 4
};

export const VERIFIED_SOURCE = {
    PAYMENT: "payment"
};

export const USED_CAR_LEAD_FLOWS = {
    BOOK_CAR: "BOOK_CAR",
    MAKE_AN_OFFER: "MAKE_AN_OFFER"
};

export const CUSTOM_ACTION_TYPES = {
    SET_LOCATION_NAME: "SET_LOCATION_NAME",
    SET_WHATSAPP_CONSENT: "SET_WHATSAPP_CONSENT",
    SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
    LEAD_ACTION: "LEAD_ACTION",
    SET_DATA: "SET_DATA",
    SET_VISITED_STATE: "SET_VISITED_STATE",
    OVERRIDE_CURRENT_POSITION_KEYS: "OVERRIDE_CURRENT_POSITION_KEYS",
    SHOW_PROCEED_BUTTON: "SHOW_PROCEED_BUTTON",
    SET_STYLES: "SET_STYLES"
};

export const IMAGE_UPLOADER_ENUMS = {
    UPLOAD: "upload",
    DELETE: "delete",
    VALIDATION_FAILURE: "validationFailure"
};

export const SELLER_PORTAL_SCREEN = {
    HOME: "HOME",
    DELIST: "DELIST",
    REVISE: "REVISE"
};

export const FALLBACK_C2B_CITY_ID = "2";

export const LEAD_TYPES = {
    REFINANCE: "refinance",
    EXCHANGE: "EXCHANGE"
};

export const CAR_SOURCE = {
    GS: "GS_CAR"
};

export const INACTIVE_CAR_STATES = [
    DISPOSITION_STATUS.LOST,
    DISPOSITION_STATUS.SOLD,
    DISPOSITION_STATUS.DRAFT,
    DISPOSITION_STATUS.AUCTIONED,
    DISPOSITION_STATUS.REJECTED
];

export const CAR_FINDER_SCREEN_TYPES = {
    LANDING: "LANDING",
    LEAD_FORM: "LEADFORM",
    LISTING: "LISTING",
    PERSONAL_DETAIL: "PERSONAL_DETAIL"
};

export const CAR_SERVICE_DISCOUNT = 1.2;

export const COOKIE_KEYS = {
    CAR_SERVICE_FIRST_CLICK_URL: "car_service_first_click_url"
};

export const INSPECTION_VIEW_MORE_AFTER = 6;

const CITY_ID = {
    MUMBAI: "2378",
    BENGALURU: "4709"
};

export const BOOK_NOW_CITIES = [];
export const MAKE_OFFER_CITIES = [CITY_ID.BENGALURU];

export const PAYWALL_FLOW_TYPE = {
    PAYWALL_OFFER: "paywall_offer",
    PAYWALL_BOOKNOW: "paywall_booknow"
};

export const WHATSAPP_STAGE = {
    STAGE_1: "stage_1",
    STAGE_2: "stage_2"
};

export const PAYMENT_OPTIONS = {
    FINANCE: "FINANCE",
    CASH: "CASH"
};

export const ANGLE = {
    ZERO: 0,
    NINETY: 90,
    HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    THREE_HUNDRED_SIXTY: 360
};

export const DEFAULT_CITY = {
    AE: {
        city_id: "73662522",
        city_name: "Dubai",
        city_slug: "dubai",
        code: "CC_AE_1"
    },
    IN: {
        city_id: "8597",
        city_name: "Delhi-NCR",
        city_slug: "delhi-ncr"
    },
    AU: {
        city_id: "",
        city_name: "Brisbane",
        code: "BNE100",
        pincode: 4000,
        city_slug: "brisbane"
    }
};

export const COUNTRY = {
    name: "Australia",
    slug: "australia",
    id: "",
    code: "au"
};

export const COUNTRY_CODES = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

export const CURRENCY = {
    IN: "₹",
    AE: "AED",
    AU: "$"
};

export const COUNTRY_CODE = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

// export const AB_TEST_CONST = AB_TEST_VARIATIONS.VARIANT_A;
export const ACTIVE_EXPERIMENT_TEST = AB_TEST_VARIATIONS.VARIANT;
export const ACTIVE_VARIANT_SCREEN = {
    FUELTYPE: "fueltype",
    TRANSMISSIONTYPE: "transmissionType",
    VARIANT: "variant"
};

export const AU_HOMEPAGE_PAGINATION_SIZE = 6;
export const MY_BOOKINGS_PAGINATION_SIZE = 20;
export const AU_CITY = "au_city";
//todo: remove duplicated constant
export const DEFAULT_AU_CITY_CODE = DEFAULT_CITY.AU.code;
export const DEFAULT_AU_PINCODE = DEFAULT_CITY.AU.pincode;
export const DEFAULT_AU_CITY_NAME = DEFAULT_CITY.AU.city_name;

export const HELPLINE_NUMBER_AU = { label: "+61 482 091 942", value: "+61482091942 " };
export const SMS_NUMBER_AU = { label: "0488 825 496", value: "0488825496" };
export const SUPPORT_EMAIL = {
    label: "sellmycar.au@cars24.com",
    redirect: "mailto:sellmycar.au@cars24.com"
};

export const ADDRESS_LEVEL_TYPE = {
    SUBPREMISE: "subpremise",
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country",
    POSTAL_CODE: "postal_code"
};

export const PHONE_COUNTRY_CODE = 61;
export const DEFAULT_PHONE_LENGTH = 10;

export const EXTERNAL_LINKS = {
    DRIVA_POLICY: "https://www.driva.com.au/legal",
    CAREERS_LINK: "https://www.linkedin.com/jobs/cars24-australia-jobs-worldwide?f_C=73063943&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0",
    WORKABLE_CAREER_LINK: "https://apply.workable.com/cars24/?lng=en"
};

export const COMPARISON_RATE_AMOUNT = 30000;

export const API_SOURCE = {
    M_SITE: "mSite",
    WEB_APP: "WebApp"
};

export const PAYMENT_SURCHARGE = { value: 1.38, label: "1.38%" };

export const LOAN_PROVIDER = {
    DRIVA: "DRIVA",
    BYOF: "BYOF",
    NONE: "NONE"
};
export const REFURBISHMENTS_URL = "https://drive.google.com/file/d/1pd-0cihvyuNcRbu5gGzqruHFfymObOMA/view";

export const PAYMENT_HISTORY_STATUS = {
    INITIATED: "INITIATED"
};

export const WARRANTY_TERMS_CONDITION = "https://drive.google.com/file/d/1mA5j2pdxXfEcnD5ysYXmL34GOcq43Tlb/view";

export const CUSTOMER_REVIEW = "https://blog-au.c24.tech/pages/reviews/";

export const DEFAULT_CITY_PINCODE_MAP = {
    NSW: 2000,
    VIC: 3000,
    QLD: 4000,
    SA: 5000
};

export const SEARCH_ALL_CARS_ROUTE = `/buy-used-cars-${COUNTRY.slug}`;

export const ERROR_TYPES = {
    DEFAULT: "default",
    ODOMETER: "odometer",
    YEAR: "year",
    FINANCE: "finance",
    HIGH_VALUATION: "highValuation",
    CONDITION: "condition",
    DO_NOT_PROCURE: "doNotProcure",
    MAKE_MODEL_ERROR: "makeModelError",
    WRITTEN_OFF: "writtenOff",
    REGISTRATION: "registration"
};

export const ERROR_STATUS_MAP = {
    1013: ERROR_TYPES.DEFAULT,
    1020: ERROR_TYPES.ODOMETER,
    1021: ERROR_TYPES.YEAR,
    1022: ERROR_TYPES.FINANCE,
    1025: ERROR_TYPES.HIGH_VALUATION,
    1027: ERROR_TYPES.CONDITION,
    1028: ERROR_TYPES.DO_NOT_PROCURE,
    1030: ERROR_TYPES.MAKE_MODEL_ERROR
};

export const ERROR_GA_MAP = {
    [ERROR_TYPES.DEFAULT]: "default_rejection",
    [ERROR_TYPES.ODOMETER]: "odo_rejection",
    [ERROR_TYPES.YEAR]: "year_rejection",
    [ERROR_TYPES.FINANCE]: "finance_rejection",
    [ERROR_TYPES.HIGH_VALUATION]: "price_rejection",
    [ERROR_TYPES.CONDITION]: "condition_rejection",
    [ERROR_TYPES.DO_NOT_PROCURE]: "do_not_procure_rejection",
    [ERROR_TYPES.MAKE_MODEL_ERROR]: "make_model_rejection",
    [ERROR_TYPES.WRITTEN_OFF]: "written_off",
    [ERROR_TYPES.REGISTRATION]: "registration"
};

export const STEP_ROUTE_MAP = {
    SELECT_CAR_INFO: "car-details",
    VALUATION: "valuation",
    CAR_CONDITION: "exterior-condition",
    PRECONDITIONS: "pre-conditions",
    EXTERIOR_CONDITION: "exterior-condition",
    MECHANICAL_CONDITION: "mechanical-condition",
    INTERIOR_CONDITION: "interior-condition",
    CAR_CONDITION_AND_DETAILS: "exterior-condition",
    ESTIMATED_VALUATION: "estimated-valuation",
    ADDITIONAL_DETAILS: "extra-details"
};

export const INSPECTION_ROUTE_MAP = {
    SELECT_DAY: "select-day",
    PERSONAL_DETAILS: "personal-details"
};

export const CITY_WISE_LINKS = [
    {
        label: "Brisbane",
        spath: "sell-your-car-brisbane"
    },
    {
        label: "Melbourne",
        spath: "sell-your-car-melbourne"
    },
    {
        label: "Sydney",
        spath: "sell-your-car-sydney"
    }
];

// export const STATES_AU = [
//     {label: "Queensland", value: "Queensland"},
//     {label: "Victoria", value: "Victoria"},
//     {label: "New South Wales", value: "New South Wales"},
//     {label: "South Australia", value: "South Australia"},
//     {label: "Nothern Territory", value: "Nothern Territory"},
//     {label: "Western Australia", value: "Western Australia"},
//     {label: "Tasmania", value: "Tasmania"}
// ];

export const STATES_AU = {
    "QUEENSLAND": "Queensland",
    "VICTORIA": "Victoria",
    "NEW SOUTH WALES": "New South Wales",
    "SOUTH AUSTRALIA": "South Australia",
    "NOTHERN TERRITORY": "Nothern Territory",
    "WESTERN AUSTRALIA": "Western Australia",
    "TASMANIA": "Tasmania"
};
export const TASK_SOURCES = {
    IN_HOUSE_FINANCE: "IN_HOUSE_FINANCE",
    DOC_SIGN: "DOC_SIGN",
    DMS: "DMS"
};

export const TASK_SOURCES_ALERT = {
    FINISH_LOAN_APPLICATION: "FINISH_LOAN_APPLICATION",
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SALE: "SALE",
    SPLIT_PAYMENT: "SPLIT_PAYMENT"
};

export const ENABLE_NEW_POST_BOOK_FLOW = {
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SPLIT_PAYMENT: "SPLIT_PAYMENT",
    SALE: "SALE"
};

export const CITY_WISE_CONTENT = {
    Brisbane: {
        top: {
            heading: "Sell used cars in Brisbane. Quick process, no hassle.",
            description: "Need quick cash in Brisbane? Sell your car to CARS24, all online. Get instant car valuation, top prices, virtual inspections, and immediate online contracting. Remove your car sale listings from marketplaces, and sell your car in Brisbane without the hassle of dealing with window shoppers. Simply sell your car in Brisbane today without the middleman with CARS24."
        },
        bottom: {
            heading: "Best place to sell your car online in Brisbane",
            description: "CARS24, the online leader in selling top-quality used cars, has been a trusted presence in Brisbane. We go beyond selling by specialising in buying and accepting trade-ins of quality vehicles across Australia. Selling your car with CARS24 is the fastest, simplest way to sell your car in Brisbane without leaving your home. With the best prices in town, we operate 100% online and will gladly pick up your car from your door, anywhere in metro Brisbane. Our online portal and dedicated customer service team streamline the process, managing everything from transferring your registration to organising roadworthy and safety certificates. To start the process, simply embrace the quoted offer, schedule a video call inspection with us, and finalise the contract.",
            additionalText: "Our seamless 100% online process takes mere minutes and can be done anywhere, as long as you have a phone and an internet connection in Brisbane."
        },
        reviews: [{
            name: "Francesca K.",
            title: "Easy and quick sale…",
            description: "Easy and quick sale… would definitely recommend selling this way. I sold my car through Cars24 (I am based in Brisbane). The whole process was so easy and I am very happy with their offer for my car. Sagar (the CARS24 rep) kept me in the loop throughout, and everything was processed so quickly. A deal was agreed on, on the day of the inspection. A deposit was paid immediately, and the balance was deposited into my account the day before they collected the car. I am really impressed and would definitely recommend this method of selling a car to my friends. Do yourself a favour and don’t waste time haggling with people on selling sites. Thanks, Sagar for helping make the process so easy"
        },
        {
            name: "Michael Ward",
            title: "Sale of my 2016 Toyota Hilux Ute",
            description: "Sale of my 2016 Toyota Hilux Ute. Thank you Cars24 team for doing a fantastic job buying my car. I got the price I wanted and the process was seamless which I appreciate greatly. Kindest regards"
        },
        {
            name: "Ashlee",
            title: "Super easy hassle free!",
            description: "Super easy hassle free! Sold my car for a reasonable price, Cars24 handled all the logistics with zero hassle. Divya was very helpful and friendly. Would definitely use it again."
        }
        ],
        location: [
            {
                text: "40 Steel Pl, Morningside QLD 4170"
            }
        ]
    },
    Melbourne: {
        top: {
            heading: "Sell used cars in Melbourne, 100% online.",
            description: "Selling your car can be exhausting and stressful. No more wasting your time listing your car in every online marketplace and classifieds. No more waiting for calls and emails from 'potential buyers' who never respond and often never show up to inspect. No more questions from buyers who aren't serious about purchasing. Sell your car to CARS24 in Melbourne today, all online."
        },
        bottom: {
            heading: "Best place to sell your car online in Melbourne",
            description: "You might know CARS24 for being the online leader in selling top quality used cars, but we also buy and accept trade-in of quality vehicles across Australia. Selling your car with CARS24 is the fastest, simplest way to sell your car in Melbourne without leaving the house. We offer the best prices in town, are 100% online and we will even pick the car up from your door anywhere in metro Melbourne. Our online portal and dedicated customer service team streamline the process including transferring your registration and organising road-worthy and safety certificates. All you need to do is accept the quoted offer, book a video call inspection with us, and finalise the contract.",
            additionalText: "Our seamless 100% online process takes mere minutes to complete and can be done anywhere, as long as you have a smartphone and an internet connection anywhere in Melbourne."
        },
        reviews: [{
            name: "Alison G",
            title: "Selling my car to Cars24 was so easy!!",
            description: "Selling my car to Cars24 was so easy!! The whole experience of selling my car to Cars24 was very easy. I inquired about selling my car and that day Vish called me and organised a virtual inspection. This was done over video call. That afternoon he had already sent me the contract of sale and the next day the money was transferred into my bank account. The car was picked up the following day. So well organised and easy!"
        },
        {
            name: "Olivia",
            title: "So easy, I didn’t have to do a thing!",
            description: "So easy, I didn’t have to do a thing! Selling my car via Cars24 was so easy. I supplied a few details, answered some simple questions and they made me a competitive offer in no time. No haggling, no time wasters and no stress for me. The whole process was made easier as I didn’t need to do a thing - I didn’t even have to wash the car! Hassle free for me , I’d definitely recommend selling via Cars24."
        },
        {
            name: "Suzana Anna Djordj",
            title: "Thank You Cars24!!",
            description: " Selling my Toyota Corolla was simply amazing, Thank You Cars24!! Thank you Noreen!! First time user of Cars24 and all I can say is, it’s absolutely the best way to sell a car. From start to finish the process was so easy, no stress, no headaches, Ms Noreen was simply wonderful, very patient with me, very professional and explained the process thoroughly.  I can honestly say I recommend this service 110% to everyone who has a car to sell. Thank you again Ms Noreen, I’ve not had such outstanding customer service in a while!!"
        }
        ],
        location: [
            {
                text: "118 Cherry Ln, Laverton North VIC 3026"
            }
        ]

    },
    Sydney: {
        top: {
            heading: "Sell used cars in Sydney. Instant offers, fast cash.",
            description: "Selling your car in Sydney? Benefit from unbeatable offers and fast cash payments when you choose CARS24. Skip the exhausting and stressful process of listing your car on multiple websites, dealing with non-responsive buyers, and scheduling test drives that lead to haggling. Sell your car in Sydney today, 100% online, with CARS24."
        },
        bottom: {
            heading: "Best place to sell your car online in Sydney",
            description: "You might recognize CARS24 as the online leader in selling top-quality used cars in Sydney. Beyond selling, we specialise in buying and accepting trade-ins of quality vehicles across Australia. Selling your car with CARS24 is the fastest, simplest way to sell your car in Sydney without leaving your home. Offering the best prices in town, we are 100% online and will conveniently pick up your car from your door, anywhere in metro Sydney. Our online portal and dedicated customer service team streamline the process, handling everything from transferring your registration to organising roadworthy and safety certificates. To get started, simply embrace the quoted offer, schedule a video call inspection with us, and finalise the contract.",
            additionalText: "Our seamless 100% online process takes mere minutes and can be done anywhere, as long as you have a phone and an internet connection in Sydney."
        },
        reviews: [{
            name: "Timothy",
            title: "Easy, hassle free process!",
            description: "Easy, hassle free process! The process of selling my car happened in a matter of days. Noreen, who accompanied the process, exceeded my expectations. Overall satisfied with the service!"
        },
        {
            name: "Fernando Jose Belchior dos Santos",
            title: "Sale of my 2016 Toyota Hilux Ute",
            description: " I really liked all the work done by Cars24. From the valuation to vehicle pick-up everything was smooth and hassle free."
        },
        {
            name: "Bennette",
            title: "Reliable, fast and simple",
            description: "I feel it very simple and fast, reliable, especially with the communication with Miss Noreen, she shows her profession, patience to answer my lots of questions as its my first time selling car through online. if my friends would like to buy and sell the car, I could recommend cars24 to them."
        }
        ],
        location: [
            {
                text: "155 Parramatta Rd, Granville NSW 2142"
            }
        ]
    },
    default: {
        reviews: [{
            name: "Glenn",
            title: "Incredibly easy to sell",
            description: "Incredibly easy to sell. Gave me the best price out of any quotes I had received, and the sales process could not have been quicker or simpler. Massive kudos to this company and for Connor who assisted. Dream transaction!"
        },
        {
            name: "Rachelle",
            title: "I sold my car in a matter of days!",
            description: " I sold my car in a matter of days! I can’t believe how easy the whole process was. Virtual inspection was organised for the next day following the initial contact. I was offered a good price then and there. Deposit transferred immediately and pick up organised, full balance received the day before pick up. I only had to sign and hand over the keys! The best part of all of this was I didn’t have to worry about strangers coming to look at the car, haggling prices and taking time out of my day to sell my car. The easiest and most stress free process, extremely happy with Cars24!"
        },
        {
            name: "Hannah T",
            title: "Great, simple process",
            description: "Really easy process. Great, simple process. My car was sold very quickly- the virtual inspection was easy and the lady talked me through exactly what I needed to do. An offer was made immediately and payment came through promptly. Seamless process. One which I’d do again for sure!"
        }
        ],
        location: [
            {
                text: "<span>Melbourne:</span> 118 Cherry Ln, Laverton North VIC 3026"
            },
            {
                text: "<span>Brisbane:</span> 40 Steel Pl, Morningside QLD 4170"
            },
            {
                text: "<span>Sydney:</span> 155 Parramatta Rd, Granville NSW 2142"
            }
        ]
    }
};

export const FAQ_DATA = {
    Brisbane: [
        {
            key: "faqOne",
            heading: "Will CARS24 honour the purchase price?",
            data: ["The online evaluation conducted by CARS24 is valid for up to 7 days. The assessed value won’t change if the information you provided during the virtual inspection is correct. We will purchase the vehicle given the below conditions:"],
            listPoints: [
                "You are the vehicle's current registered owner and have all the valid registration documents in your name",
                "Your vehicle was first registered in Australia",
                "Your vehicle is in working condition",
                "Your vehicle hasn’t been written off or failed a roadworthy",
                "There are no mechanical faults in your vehicle including issues with the steering system, engine, transmission, suspension and brakes",
                "All the electricals systems in your vehicle are in working condition",
                "Your vehicle hasn’t been modified mechanically or aesthetically",
                "The mileage record of the vehicle is correct",
                "No warning lights are on",
                "The exterior of the vehicle hasn’t suffered wear and tear due to hail or corrosion",
                "All the tyres are road-legal and in usable condition"
            ]
        },
        {
            key: "faqTwo",
            heading: "When will my car be inspected and picked up?",
            data: ["In the first phase, your vehicle will be inspected virtually from a location of your choice via a Google Meet video call. Next, you’ll be provided with a detailed report of the inspection through email confirming vehicle details and confirming the evaluation price. Finally, we will organise to pick up the vehicle from a location of your choice within 100km radius of Brisbane."]
        },
        {
            key: "faqThree",
            heading: "Will you price match or negotiate on my vehicle value?",
            data: ["Evaluation quotes are calculated based on the vehicle make, model, year and history and the overall condition and quality of the vehicle. As negotiating is stressful and time-consuming process, we believe in a quick no hassle process to make car selling for you a streamlined and stress-free process. Please note that we always provide our best offer up front. "]
        },
        {
            key: "faqFour",
            heading: "Can I sell my unregistered car? ",
            data: ["While we do buy unregistered cars, we’ll need you to produce valid Australian registration documents of the vehicle in your name."]
        },
        {
            key: "faqFive",
            heading: "Can I sell my car with a personalised number plate?",
            data: ["We are more than willing to purchase a car that has got personalized number plates. However, the owners of the personalized plates need to be present at the time of the inspection of the vehicle to sign the transfer documents. In case you wish to retain the personalized number plates, you will have to visit the local registration office to replace the personalized number for a generic number before you hand over your car to CARS24"]
        },
        {
            key: "faqSix",
            heading: "Can I sell a car under finance?",
            data: ["Yes, we buy cars that have outstanding finance. As per the conditions in the finance payout letter, we pay the outstanding amount to the financier of the vehicle. Meanwhile, the difference in our purchase price and the amount paid to the financier will be handed over to you. In case the outstanding amount to the financier of the vehicle is higher than the purchase value of the vehicle, you will be required to pay the difference to the financier. Moreover, you’ll be required to share the payment confirmation with us before we send the purchase contract to you."]
        }
    ],
    Sydney: [
        {
            key: "faqOne",
            heading: "Will CARS24 honour the purchase price?",
            data: ["CARS24’s valuation of your car remains valid for 7 days. The value is gauged through a systematic process and doesn’t change as long as the information you provide during the virtual inspection is 100% accurate. We will buy your vehicle on the following conditions"],
            listPoints: [
                "You are the registered vehicle owner and possess all registration documents in your name",
                "Your vehicle is originally registered in Australia",
                " Your vehicle is in a road-worthy condition",
                "Your vehicle has not been written off or in a serious accident",
                "Your vehicle doesn’t have any mechanical issues with the suspension, engine, transmission, steering and brakes",
                "Your vehicle’s electrical system is in working condition",
                "Your doesn’t have any customizations to its aesthetics or mechanicals",
                "The recorded mileage of the vehicle is accurate to the best of your information",
                "There are no warning lights being shown in the instrument binnacle",
                "There has been no wear and tear caused by hail or corrosion",
                "The tyres of your vehicle are road-worthy and in usable condition"
            ]
        },
        {
            key: "faqTwo",
            heading: "When will my car be inspected and picked up?",
            data: ["Your vehicle is inspected by CARS24 virtually at a place of your choice. This inspection takes place through a Google Meet video call. In the next step, you’re provided with a detailed inspection report through email. In the final step, we pick up your vehicle from anywhere within a 100km radius of Sydney."]
        },
        {
            key: "faqThree",
            heading: "Will you price match or negotiate on my vehicle value?",
            data: ["We, at CARS24, value the time of our customers. Negotiating is a stressful and time-consuming process for both the buyer as well as the seller. Hence, we provide you with our best offer up front. Through this, we make selling the vehicle a hassle-free process."]
        },
        {
            key: "faqFour",
            heading: "Can I sell my unregistered car? ",
            data: ["Yes, we buy unregistered cars provided you have valid Australian registration documents of the vehicle in your name."]
        },
        {
            key: "faqFive",
            heading: "Can I sell my car with a personalised number plate?",
            data: ["Yes, we buy cars that have a personalized number plate. That said, all the owners of the personalized number plate need to sign the transfer documents at the time of the inspection. Also, if you wish to retain the plates, you will need to visit the local registration office to replace the personalized number with a regular number before you sell the vehicle to CARS24."]
        },
        {
            key: "faqSix",
            heading: "Can I sell a car under finance?",
            data: ["Yes, we can buy a car that has outstanding finance on it. However, as per the finance payout letter, we pay the outstanding amount to the financier and the difference of the purchase price and the financed amount to you. Also, if the outstanding amount to the financier is higher than the value of the vehicle, the difference will have to be paid by you to the financier. Once paid, you’ll have to share the payment confirmation with us before we share the purchase contract with you."]
        }
    ],
    Melbourne: [
        {
            key: "faqOne",
            heading: "Will CARS24 honour the purchase price?",
            data: ["The valuation done by CARS24 has a validity of up to 7 days. The value offered by us is after a systematic check and won’t change unless the information given by you during the virtual inspection is inaccurate. We will honour the quoted price offered by us on following conditions: "],
            listPoints: [
                "You have valid registration documents of your vehicle in your name",
                "Your vehicle is originally Australia-registered",
                "Your vehicle is in complete working condition",
                "Your vehicle wasn’t written off in any type of accident",
                "Your vehicle is devoid of any mechanical problem with its engine, transmission, suspension, steering system and brakes",
                "The electrical system of your vehicle is in complete working condition",
                "You have not made any modifications to the exterior, interior or the mechanicals of the vehicle",
                "The recorded mileage of your vehicle is 100% accurate",
                "The dashboard doesn’t have any warning lights on",
                "Your vehicle hasn’t suffered from any damage in hail or due to corrosion",
                "Your vehicle has tyres that are road-worthy"
            ]
        },
        {
            key: "faqTwo",
            heading: "When will my car be inspected and picked up?",
            data: ["CARS24 will inspect your vehicle online at a location of your choice through a Google Meet video call. Next, you’ll be provided with an inspection report on your email. Finally, we will collect the vehicle from the location of your choice within 100km radius from Melbourne."]
        },
        {
            key: "faqThree",
            heading: "Will you price match or negotiate on my vehicle value?",
            data: ["CARS24 values your time. Hence, we make the best possible offer up front. We understand that negotiating is a time-consuming and stressful process for you. Therefore, we provide you with the best price in the first instance."]
        },
        {
            key: "faqFour",
            heading: "Can I sell my unregistered car? ",
            data: ["CARS24 buys unregistered cars as long as you provide Australian registration documents that prove the vehicle is in your name."]
        },
        {
            key: "faqFive",
            heading: "Can I sell my car with a personalised number plate?",
            data: ["CARS24 buys vehicles that carry personalized number plates. However, all the owners of the personalized number plates need to sign the transfer documents when we inspect the vehicle. Moreover, in case you wish to retain the plates, you need to visit VicRoads to replace them with a regular number before selling your vehicle to us. "]
        },
        {
            key: "faqSix",
            heading: "Can I sell a car under finance?",
            data: ["CARS24 buys a car with outstanding finance on it. That said, as per the finance payout letter, we pay the outstanding amount to your vehicle’s financier before paying the difference of the purchase amount and financed amount to you. Moreover, in case the outstanding amount turns out to be higher than the purchase value of the vehicle, the difference will have to be paid by you to the financier. We will share the purchase contract with you once you share the payment confirmation with us."]
        }
    ],
    Default: [
        {
            key: "faqOne",
            heading: "Will CARS24 honour the purchase price?",
            data: ["Our valuation is valid for 7 days. The appraisal will not change as long as the information you provided was accurate at the time of our virtual inspection of your car. We'll accept the vehicle as long as:"],
            listPoints: [
                "You're the registered owner of the car and have a valid copy of the registration papers",
                "Your vehicle was first registered in Australia",
                "Your vehicle is overall in good condition",
                "Your vehicle has never been written off or has had any major accidents",
                "It doesn't have mechanical faults (like noticeable problems with the steering, transmission, clutch, gearbox, suspension or brakes)",
                "All electrical functions are in working order",
                "Its appearance or performance hasn't been modified",
                "Its mileage is accurate to the best of your knowledge",
                "No warning lights show on the dashboard",
                "No Hail or Corrosion on the exterior of the vehicle",
                "The tyres are safe and legal",
                "We do not accept cars that have no service history. A final decision on cars with partial service history will be made at the time of the virtual inspection."
            ]
        },
        {
            key: "faqTwo",
            heading: "When will my car be inspected and picked up?",
            data: ["Your vehicle will undergo a virtual inspection from the convenience of your home using a google meet video call. We’ll send you the details of the inspection by email. When it comes time to pick up, your car will be picked up from the location you provide. Please note, we use a third party logistics provider for vehicle pick ups and offer a free vehicle pick up only in metro areas of NSW, QLD & VIC."]
        },
        {
            key: "faqThree",
            heading: "Will you price match or negotiate on my vehicle value?",
            data: ["Selling a car is not easy and we can understand that.  We work on a no haggle, no hassle approach and offer a fair price keeping in line with overall market trends. Please note any major imperfections on the vehicle will be reviewed and assessed at the time of the virtual inspection, as we are unable to assess the condition of a vehicle based only on images. If the car does not meet our overall good condition requirements, we will let you know upfront."]
        },
        {
            key: "faqFour",
            heading: "Can I sell my unregistered car? ",
            data: ["We do but we'll need to see prior Australian registration documents in your name."]
        },
        {
            key: "faqFive",
            heading: "Can I sell my car with a personalised number plate?",
            data: ["Yes, we do inspect cars with personalised registration plates. If we are proceeding with the purchase of your car, you will need to visit your local Transport Authority Office (Service NSW, VicRoads, QLD TMR to swap your personalised registration plates to regular registration plates before the balance payment is made and the car is picked up. "]
        },
        {
            key: "faqSix",
            heading: "Can I sell a car under finance?",
            data: ["Yes, we do buy cars that have finance outstanding. As per the finance payout letter, we will pay the amount owing to the financier. The difference between the amount paid to the financier and our purchase price will be paid to you. If the amount outstanding to the financier is more than the purchase price of the vehicle, we will request you to pay the difference and share a payment confirmation with us prior to the contract being sent to you."]
        },
        {
            key: "faqSeven",
            heading: "Do I need to organise a Roadworthy Certificate/ Safety Certificate if I sell my car to CARS24?",
            data: ["No, we don't need a Roadworthy certificate or Safety certificate as we are a dealership."]
        },
        {
            key: "faqEight",
            heading: "Where are you located in Australia?",
            data: ["We are a 100% online dealership and have our operations in NSW, VIC and QLD."]
        },
        {
            key: "faqNine",
            heading: "If I proceed with the sale of my car to CARS24, who will manage the transfer of vehicle registration?",
            data: ["We have a dedicated registrations team at CARS24 and they will manage the transfer of vehicle registration with the Transport Authority in your state. The registration is normally transferred within 4 - 5 business days of the vehicle being picked up but this can vary depending on the processing timeframe at Service NSW, VicRoads & QLD TMR."]
        },
        {
            key: "faqTen",
            heading: "How will you pay me for my car?",
            data: ["At CARS24, we only make payments via a bank transfer for both initial deposit and balance payment. We don't make cash payments."]
        }
    ],
    usedCarCalculator: [
        {
            key: "faqOne",
            heading: "Can I get a market value of my car online?",
            data: ["Yes! You can use an online car valuation calculator to get a close approximation of the market value of your car. You will be required to enter the required details related to your car model, make, mileage, and details of physical condition, number of keys, damage and imperfections, etc. You can calculate the market price of your used car through CARS24 Used Car Online Calculator."]
        },
        {
            key: "faqTwo",
            heading: "Do I need to get an appointment for online valuation of my car?",
            data: ["No! The Online Calculator for valuation of used cars developed by CARS24 is hassle-free and does not require any prior appointment. All you need is to spare a few minutes and you will get an estimate of the market value of your used car."]
        },
        {
            key: "faqThree",
            heading: "What is the best way to sell my car?",
            data: ["Selling used cars at best prices demands patience, honesty, and commitment. Along with the knowledge of your car and its worth, insights into market trends are also an important factor in getting the best prices. If you are short on time, you can check the value of your car through the CARS24 online car valuation calculator. You can study about the market trends regarding price and demand on our blog. Once you have a good idea of the worth of your car and market insights and you are happy with the instant quote CARS24 has offered you, book an inspection online at your selected date and time slot."]
        },
        {
            key: "faqFour",
            heading: "How can you determine the market value of second hand cars for sale?",
            data: ["Theoretically, there are different methods you can evaluate the price of your second hand car. For example, you can go through classified ads, read consumer reports, survey reports of car dealers and reports published by automobile companies. However, primarily, there are two ways to determine the market value of a second hand car. One is the traditional method of visiting car dealers door-to-door and getting different price estimates based on manual evaluation of the car. Second method is using an online car valuation calculator to determine the overall market value of the car and then visiting selected car dealers to get the best rates. CARS24 offers a highly sophisticated online calculator for valuation of used cars."]
        }
    ]
};

export const TRADE_IN_FAQ = [
    {
        key: "faqOne",
        heading: "Will CARS24 honour the purchase price?",
        data: ["Our valuation is valid for 7 days. We will honour the offered price if you have provided accurate information about the vehicle and its current condition.We will accept the vehicle if:"],
        listPoints: [
            "Your vehicle was first registered in Australia",
            "You are the current registered owner of the vehicle and have a valid copy of the registration papers with the authority to sell the vehicle",
            "Your vehicle is in a ‘roadworthy’ condition",
            "Your vehicle has never been in a major accident",
            "Your vehicle has never been written off",
            "Your vehicle has never been stolen",
            "The airbags have never been deployed",
            "Your vehicle’s engine hasn’t been replaced and it has no mechanical faults (such as noticeable problems with the steering,transmission, clutch, gearbox, suspension or brakes)",
            "All the accessories added or modifications made to the vehicle are legal",
            "The odometer reading you supplied is accurate",
            "The odometer is in working condition and has not been replaced or altered",
            "Any known interior damage has been declared",
            "Any known  exterior damage has been declared",
            "Any warning lights showing on the dashboard have been declared",
            "The tyres are safe and legal"
        ]
    },
    {
        key: "faqTwo",
        heading: "When will my car be inspected and picked up?",
        data: ["Your vehicle will undergo a virtual inspection from the convenience of your home using a Google Meet video call. We’ll send you the details of the inspection by email. When it comes time to pick up, your car will be picked up from the location you provide. Please note, we use a third-party logistics provider for vehicle pick up. The vehicle pick up is free only in metro areas of NSW, QLD & VIC."]
    },
    {
        key: "faqThree",
        heading: "Will you price match or negotiate on my vehicle value?",
        data: ["Selling a car is not easy and we can understand that. We work on a no haggle, no hassle approach and offer a fair price keeping in line with overall market trends. Please note any major imperfections on the vehicle will be reviewed and assessed at the time of the virtual inspection, as we are unable to completely assess the condition of a vehicle based only on images. If the car does not meet our overall good condition requirements, we will let you know during the inspection."]
    },
    {
        key: "faqFour",
        heading: "Can I sell my unregistered car? ",
        data: ["We do but we'll need to see prior Australian registration documents in your name."]
    },
    {
        key: "faqFive",
        heading: "Can I sell my car with a personalised number plate?",
        data: ["Yes, we do accept cars with personalised registration plates. If you want to keep your personalised registration plate, you will need to visit your local Transport Authority Office (Service NSW, VicRoads, QLD TMR to swap your personalised registration plates to regular registration plates before the car is picked up. The car’s registration certificate must match the new number plate when we pick-up your car."]
    },
    {
        key: "faqSix",
        heading: "Do you accept trade-in for a car under finance?",
        data: ["Yes, we do buy cars that have finance outstanding. As per the finance payout letter, we will pay the amount owing to the financier. A member from our team will reach out to you for assistance."]
    },
    {
        key: "faqSeven",
        heading: "Do I need to organise a Roadworthy Certificate/ Safety Certificate if I sell my car to CARS24?",
        data: ["No, we don't need a Roadworthy certificate or Safety certificate as we are a dealership."]
    },
    {
        key: "faqEight",
        heading: "What documents are required to trade-in my car?",
        data: ["We’ll need to verify that you are the owner of the car being traded, so we will need copies of:"],
        listPoints: [
            "The front and back of your drivers licence",
            "Registration documentation for the car in your name",
            "Service log book/receipts and Roadworthy Condition (RWC) if available"
        ],
        additionalText: "This list does not include additional documents that you will need to provide as part of purchase of your new car"
    },
    {
        key: "faqNine",
        heading: "Where are you located in Australia?",
        data: ["We are a 100% online dealership and have our operations in NSW, VIC and QLD."]
    },
    {
        key: "faqTen",
        heading: "If I proceed with the sale of my car to CARS24, who will manage the transfer of vehicle registration?",
        data: ["We have a dedicated registrations team at CARS24 and they will manage the transfer of vehicle registration with the Transport Authority in your state. The registration is normally transferred within 4 - 5 business days of the vehicle being picked up but this can vary depending on the processing timeframe at Service NSW, VicRoads & QLD TMR."]
    },
    {
        key: "faqEleven",
        heading: "How will you pay me for my car?",
        data: ["The value of your trade-in car will be adjusted against the new car you purchase from us. In case the value of the trade-in car is higher than the value of the car you purchase from CARS24, we will make the balance payment via a bank transfer. We don't make cash payments."]
    },
    {
        key: "faqTweleve",
        heading: "What happens to my trade-in car if I return the car I’ve bought from CARS24?",
        data: ["If you decide to return the car you purchased from us within the 7-day return period, we will either return the Trade-in-Car to you or pay you the trade-in amount. However, if your trade-in car has already been sent to our partners or we have made investments in refurbishment of the car we can only pay you for the Trade-in. Payment will be done directly to your bank account and will be processed within 7 days of pickup of the returned car."]
    }
];

export const NEXT_STEP_DETAILS_TRADE_IN = [
    {
        index: 1,
        heading: "Choose your car",
        details: "To trade-in your old car you need to find a new car to buy. Search from our range of quality cars ",
        buttonDetails: {
            showButton: true,
            buttonLabel: "CHOOSE CAR NOW"
        }
    },
    {
        index: 2,
        heading: "Checkout",
        details: "If you’re happy, proceed with your purchase. Your trade-in car details will be pre-fetched in the checkout flow",
        buttonDetails: {}
    },
    {
        index: 3,
        heading: "Same day delivery and pick-up",
        details: "We’ll pick-up your trade-in when we deliver your new car",
        buttonDetails: {}
    }
];

export const NEXT_STEP_DETAILS_SELL_OUTRIGHT = [
    {
        index: 1,
        heading: "Book a virtual inspection",
        details: "Book a virtual inspection via video call with an inspector.",
        buttonDetails: {}
    },
    {
        index: 2,
        heading: "Provide payment details",
        details: "Add your bank account details where we’ll transfer the valuation amount.",
        buttonDetails: {}
    },
    {
        index: 3,
        heading: "Payment transfer and car pick-up",
        details: "We’ll deposit the money in your bank account and pick up your car.",
        buttonDetails: {}
    }
];

export const SHAZAM_FAQ = {
    key: "shazamFaq",
    heading: "How can I enter the SHAZAM! FURY OF THE GODS offer? ",
    data: ["Entry is only open to NSW, QLD and VIC residents who are 18 years and over and holds a valid drivers licence issued in an Australian State or Territory AND be the registered owner of the vehicle submitted for valuation."],
    listPoints: [
        "All completed valuations 1-16th March will automatically enter the prize draw to WIN 1 of 10 double cinema passes to see ‘Shazam! Fury of the Gods’.",
        "All completed valuations 1-31st March will automatically enter the prize draw to WIN a used Mini Cooper S 2014 with automatic transmission, including a minimum of 3-months registration, a minimum 3-months compulsory third party insurance, stamp duty and dealer delivery charges (which may vary from State by State)."
    ]
};

export const GUEST_POPUP_TYPES = {
    EMAIL: "email",
    VERIFY: "verify"
};

export const OPTIONS_LIST_TYPE = {
    LIST: "list",
    POPULAR: "popular"
};

export const UTM_PARAMS_KEY = "utm__params";

export const TRADE_STATE = [
    {
        stateCode: "AU_NSW ",
        stateName: "New South Wales",
        label: "NSW",
        value: "New South Wales"
    },
    {
        stateCode: "AU_QLD",
        stateName: "Queensland",
        label: "QLD",
        value: "Queensland"
    },
    {
        stateCode: "AU_NTR",
        stateName: "Northern Territory",
        label: "NT",
        value: "Northern Territory"
    },
    {
        stateCode: "AU_CAPTR",
        stateName: "Australian Capital Territory",
        label: "ACT",
        value: "Australian Capital Territory"
    },
    {
        stateCode: "AU_SAU",
        stateName: "South Australia",
        label: "SA",
        value: "South Australia"
    },
    {
        stateCode: "AU_TAS",
        stateName: "Tasmania",
        label: "TAS",
        value: "Tasmania"
    },
    {
        stateCode: "AU_VIC",
        stateName: "Victoria",
        label: "VIC",
        value: "Victoria"
    },
    {
        stateCode: "AU_WEST",
        stateName: "Western Australia",
        label: "WA",
        value: "Western Australia"
    }
];

export const BREADCRUMB_DATA = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Sell my car",
        path: "/sell-your-car/"
    }
];

export const LOGIN_SOURCE = {
    WISHLIST: "wishlist",
    FINANCE: "finance",
    TRADE_IN: "tradein",
    CHECKOUT: "checkout",
    HAMBURGER: "hamburger"

};

export const CDP_REDIRECTION_KEY = "c2b_cdp_redirection";

export const toHideSec = 15000;
export const showOnHomeSec = 10000;
export const showOnClpSec = 14000;
export const showOnCdpSec = 32000;

export const surveyQuestion = [
    "Yes, selling now",
    "Selling in a few months",
    "Don't want to sell",
    "Don't own a car",
    "Sold already"
];

export const SHOW_QUICK_SELL_SURVEY = "show_quick_sell_survey";
