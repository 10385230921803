import { createReducer } from "reduxsauce";
import { NUMBER } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    isGuestLoginVisible: null,
    loginStep: NUMBER.ONE,
    backHandler: null
};

export const setGuestLoginVisible = (state = INITIAL_STATE, {data}) => {
    return { ...state, isGuestLoginVisible: data.isVisible, loginStep: data.loginStep, backHandler: data.backHandler };
};

export const setGuestLoginSuccessResponse = (state = INITIAL_STATE, {data}) => {
    return { ...state, ...data};
};

export const HANDLERS = {
    [Types.SET_GUEST_LOGIN_VISIBLE]: setGuestLoginVisible,
    [Types.SET_GUEST_LOGIN_SUCCESS_RESPONSE]: setGuestLoginSuccessResponse
};

export default createReducer(INITIAL_STATE, HANDLERS);
