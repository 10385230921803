import React from "react";
import PropTypes from "prop-types";
import logoImage from "./images/cars24-logo.svg";

const Logo = ({ width, height }) => <img style={{width, height}} src={logoImage} alt="CARS24" />;

Logo.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
};

export default Logo;
