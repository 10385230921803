import React from "react";
import styles from "./styles.css";

const CreditScoreDiv = () => {
    return (
        <div
            styleName={"styles.creditScoreDiv"}
        >
            <p>No impact on credit score</p>
        </div>
    );
};

export default CreditScoreDiv;
