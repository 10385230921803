import React, { useEffect } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

// TODO - Refactor this component
const Checkbox = ({
    checkedStatus,
    onClickCallback = () => { },
    id = "checkbox",
    name = "checkbox",
    disabled = false,
    onChange = () => {}
}) => {
    const [checked, setChecked] = React.useState(checkedStatus);

    useEffect(() => {
        setChecked(checkedStatus);
    }, [checkedStatus]);

    const onClickHandler = (e) => {
        e.stopPropagation();
        onClickCallback(e);
    };

    const handleChange = () => {
        const value = !checked;
        setChecked(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div styleName={"styles.checkboxHolder"}>
            <input
                onClick={onClickHandler}
                type="checkbox"
                id={id}
                name={name}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
            />
            <label htmlFor={id} />
        </div>
    );
};

Checkbox.propTypes = {
    onClickCallback: PropTypes.func,
    checkedStatus: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default Checkbox;
