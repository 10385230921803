/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { OTP_MAX_LENGTH_EMAIL } from "../../../constants/app-constants";

const InputOTP = ({
    onChange,
    inputRef,
    value = "",
    onBlurCallback = () => { },
    onFocusCallback = () => { },
    ...rest }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasError, setHasError] = useState(false);

    const onFocus = (e) => {
        const otp = (e.target.value || "");
        if (otp.length === OTP_MAX_LENGTH_EMAIL || otp.length === 0) {
            setIsFocused(true);
        }
        onFocusCallback(e);
    };
    const onBlur = (e) => {
        setIsFocused(false);
        onBlurCallback(e.target.value);
    };

    const onChangeHandler = (e) => {
        const regNumbers = /^\d{0,10}$/;
        let number;
        if (regNumbers.test(e.target.value)) {
            number = e.target.value;
            if (!mobileNumberValidator(number)) {
                setHasError(true);
            } else {
                setHasError(false);
            }
            onChange(e);
        }
    };

    return (

        <div>
            <div styleName={"styles.inputShift"}>
                <input
                    ref={inputRef}
                    type="tel"
                    maxLength={OTP_MAX_LENGTH_EMAIL}
                    className="form-control"
                    onFocus={(e) => onFocus(e)}
                    onBlur={(e) => onBlur(e)}
                    value={value}
                    onChange={onChangeHandler}
                    autoComplete={"off"}
                    data-sl="mask"
                    {...rest}
                />
                <label
                    styleName={`${(value || isFocused || hasError) ? "active" : ""}`}>
                    Enter One Time Password
                </label>
            </div>
        </div>
    );
};

InputOTP.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputRef: PropTypes.any,
    onBlurCallback: PropTypes.func,
    onFocusCallback: PropTypes.func,
    showError: PropTypes.bool
};

export default InputOTP;
