import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_PAYMENT_CONFIGURATION
`,
    {
        prefix: "paymentConfiguration/"
    }
);
