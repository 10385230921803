/* eslint-disable max-statements */

import { NUMBER, ONSALE } from "../../constants/app-constants";

/* eslint-disable complexity */
const getSaleConfigType = (saleConfig = {}) => {
    let type = "default";
    let key = "";
    let stripMeta = {};
    let bannerMeta = {};
    let listingMeta = {};
    const config = saleConfig;
    const {saleLive} = config || {};
    if (config.configType) {
        const currentTime = Date.now() / NUMBER.THOUSAND;
        const {time: preSaleTime} = config.preSale || {};
        const {time: onSaleTime} = config.onSale || {};
        if (currentTime >= preSaleTime.start && currentTime <= preSaleTime.end) {
            type = "preSale";
        } else if (currentTime >= onSaleTime.start && currentTime <= onSaleTime.end || saleLive) {
            type = "onSale";
        } else if (config.offSale && type === "default") {
            type = "offSale";
        }
        if (config.key) {
            key = config.key;
        }
        // type = "onSale";
        if (type) {
            const {uiComponents = {}} = config[type] || config[type] || {};
            const strip = (uiComponents || {}).strip || {};
            const banner = (uiComponents || {}).banner || {};
            const listing = (uiComponents || {}).listing || {};
            bannerMeta = banner.meta || {};
            stripMeta = strip.meta;
            listingMeta = listing.meta;
        }
    }
    return {currentType: type, stripMeta, bannerMeta, listingMeta, key, saleLive};
};

const isSaleActive = ({promotion, saleConfig = {}, type}) => {
    const { currentType, key, saleLive} = saleConfig?.data || saleConfig || {};
    return (promotion === ONSALE && currentType === "onSale") || (key === type) && saleLive;
};

export  {getSaleConfigType, isSaleActive};
