import { createReducer } from "reduxsauce";
import Types from "./types";
import sitemapInitialState from "../../../config/sitemap-structure.json";

export const SITEMAP_INITIAL_STATE = sitemapInitialState;

export const INITIAL_STATE = {
    sitemapData: SITEMAP_INITIAL_STATE,
    sitemapDataError: false,
    isSitemapListFetched: false
};

export const fetchSiteMapDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        sitemapData: [
            ...state.sitemapData,
            ...(Array.isArray(data) && data)
        ],
        isSitemapListFetched: true
    };
};

export const fetchSiteMapDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        sitemapDataError: error
    };
};

export const HANDLERS = {
    [Types.FETCH_SITEMAP_DATA_SUCCESS]: fetchSiteMapDataSuccess,
    [Types.FETCH_SITEMAP_DATA_FAILURE]: fetchSiteMapDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
