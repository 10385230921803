import { createReducer } from "reduxsauce";
import { COUNTRY_CODE } from "../../constants/app-constants";
import { LOGIN_TYPE } from "../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../constants/optimize-constants";
import Types from "./types";

const USER_CITY_DATA = {
    city: "",
    state: "",
    stateCode: "",
    country: "",
    countryCode: ""
};

export const INITIAL_STATE = {
    mobile: "",
    lastVerifiedMobile: "",
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    isLoggedIn: false,
    unAuthorized: true,
    isFetched: false,
    isAffiliate: false,
    affiliateSource: "",
    checkAuth: false,
    isFirstLoad: true,
    isSSR: false,
    isWebview: false,
    gaId: null,
    userId: "",
    secureToken: "",
    countryCode: COUNTRY_CODE.AU,
    userProfiles: null,
    error: null,
    profileName: "",
    isLoginPopupVisible: false,
    showAllowTracking: true,
    showSplitPaymentModal: true,
    seoMenu: {},
    loginType: LOGIN_TYPE.DEFAULT,
    logoURL: "/",
    isUserZeroDpVariant: null,
    isUserZeroDpVariantLoading: false,
    isUserZeroDpVariantError: false,
    isTradeinOfferValid: null,
    offeredPrice: null,
    amplitudeDeviceId: null,
    isFetchingGeoData: false,
    userGeoData: {...USER_CITY_DATA},
    userGeoDataFetchError: false,
    cfConnectingIp: null,
    isCtSub: false,
    closedClpSaleNudge: false,
    clpSaleAnimationSeen: false,
    currentBrowserType: null,
    recentlyViewedCars: [],
    isFirstUserSession: false
};

export const setSecureToken = (state = INITIAL_STATE, { secureToken, isGuestLogin = false }) => {
    return { ...state, secureToken, isGuestLogin };
};

export const setMobile = (state = INITIAL_STATE, { mobile }) => {
    return { ...state, mobile };
};

export const setLastVerifiedMobile = (state = INITIAL_STATE, { lastVerifiedMobile }) => {
    return { ...state, lastVerifiedMobile };
};

export const setProfileMobile = (state = INITIAL_STATE, { profileMobile }) => {
    return { ...state, profileMobile };
};

export const setProfileName = (state = INITIAL_STATE, { profileName }) => {
    return { ...state, profileName };
};

export const setEmail = (state = INITIAL_STATE, { email }) => {
    return { ...state, email };
};

export const getProfileDetailsSuccess = (state = INITIAL_STATE,
    { data }
) => {
    const { userName: email, firstName = "", middleName = "", lastName = "", countryCode, secureToken, userProfiles = [], profileMobile, isGuestLogin, isNewUser } = data;
    return {
        ...state,
        secureToken,
        fullName: `${firstName || ""} ${middleName || ""} ${lastName || ""}`,
        firstName,
        middleName,
        lastName,
        mobile: (profileMobile || "").toString(),
        email,
        countryCode,
        isLoggedIn: true,
        userProfiles,
        unAuthorized: false,
        isFetched: true,
        isGuestLogin,
        isNewUser
    };
};

export const getProfileDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isLoggedIn: false, isFetched: true, secureToken: "" };
};

export const markUnauthorized = (state = INITIAL_STATE) => {
    return { ...state, isLoggedIn: false, isFetched: true, secureToken: "" };
};

export const setCheckAuth = (state = INITIAL_STATE, { checkAuth }) => {
    return { ...state, isFetched: true, checkAuth };
};

export const setFirstLoad = (state = INITIAL_STATE) => {
    return { ...state, isFirstLoad: false };
};

export const updateWebviewStatus = (state = INITIAL_STATE, { isWebview }) => {
    return { ...state, isWebview };
};

export const setGAId = (state = INITIAL_STATE, { gaId }) => {
    return { ...state, gaId };
};

export const setIsSSR = (state = INITIAL_STATE, {isSSR}) => {
    return { ...state, isSSR };
};

const logoutSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        ...INITIAL_STATE,
        seoMenu: state.seoMenu,
        isFetched: true,
        showAllowTracking: state.showAllowTracking,
        isLoginPopupVisible: state.isLoginPopupVisible,
        isUserZeroDpVariant: state.isUserZeroDpVariant,
        isUserZeroDpVariantLoading: state.isUserZeroDpVariantLoading,
        isUserZeroDpVariantError: state.isUserZeroDpVariantError,
        amplitudeDeviceId: state.amplitudeDeviceId,
        userGeoData: state.userGeoData,
        closedClpSaleNudge: state.closedClpSaleNudge,
        clpSaleAnimationSeen: state.clpSaleAnimationSeen,
        currentBrowserType: state.currentBrowserType,
        isSSR: state.isSSR,
        recentlyViewedCars: state.recentlyViewedCars,
        isFirstUserSession: state.isFirstUserSession
    };
};

const logoutFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error };
};

const updateProfileSuccess = (state = INITIAL_STATE, { userData }) => {
    return { ...state, ...userData };
};

const updateProfileFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error };
};

const openLoginPopup = (state = INITIAL_STATE) => {
    return {...state, isLoginPopupVisible: true};
};

const closeLoginPopup = (state = INITIAL_STATE) => {
    return {...state, isLoginPopupVisible: false, loginType: LOGIN_TYPE.DEFAULT};
};

export const setShowAllowTracking = (state = INITIAL_STATE, { showAllowTracking }) => {
    return {...state, showAllowTracking};
};

export const setSplitPaymentModal = (state = INITIAL_STATE, { showSplitPaymentModal }) => {
    return {...state, showSplitPaymentModal};
};

export const seoHeaderMenuSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seoMenu: { isLoading: false, ...data, error: false }
    };
};

export const seoHeaderMenuFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        seoMenu: { ...state.seoMenu, isLoading: false, error: true }
    };
};

export const setAffiliateSource = (state = INITIAL_STATE, {affiliateSource}) => {
    return {
        ...state,
        isAffiliate: true,
        affiliateSource
    };
};

export const setLoginType = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        loginType: data
    };
};

export const setLogoUrl = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        logoURL: data
    };
};

const getZeroDpConfigInit = (state = INITIAL_STATE, {data}) =>  {
    return {
        ...state,
        isUserZeroDpVariantLoading: data,
        isUserZeroDpVariantError: null
    };
};

const getZeroDpConfigSuccess = (state = INITIAL_STATE, {data}) =>  {
    return {
        ...state,
        isUserZeroDpVariant: (data || {}).zeroDpVariant === ZERO_DP_VARIANTS.ZERO_DP,
        isUserZeroDpVariantLoading: false,
        isUserZeroDpVariantError: false
    };
};

const getZeroDpConfigFailure = (state = INITIAL_STATE, {error}) =>  {
    return {
        ...state,
        isUserZeroDpVariantLoading: false,
        isUserZeroDpVariantError: error
    };
};

const setZeroDpVariant = (state = INITIAL_STATE, {data}) =>  {
    return {
        ...state,
        isUserZeroDpVariant: state.isUserZeroDpVariant === null ? data : state.isUserZeroDpVariant
    };
};
export const setProfileDetails = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

export const setHideSiteHeader = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        hideSiteHeader: data
    };
};

export const setLocationInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetchingGeoData: true
    };
};

export const setLocationSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isFetchingGeoData: false,
        userGeoData: data
    };
};

export const setCfConnectingIpSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        cfConnectingIp: data
    };
};

export const setLocationFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        isFetchingGeoData: false,
        userGeoDataFetchError: error
    };
};

const setIsCtSub = (state = INITIAL_STATE, {value}) => {
    return {
        ...state,
        isCtSub: value
    };
};
export const closeClpNudge = (state = INITIAL_STATE) => {
    return {
        ...state,
        closedClpSaleNudge: true
    };
};
export const clpSaleAnimationSeen = (state = INITIAL_STATE) => {
    return {
        ...state,
        clpSaleAnimationSeen: true
    };
};

export const setCurrentBrowser = (state = INITIAL_STATE, {data}) => {
    return { ...state, currentBrowserType: data };
};

export const updateRecentlyViewedCars = (state = INITIAL_STATE, {data = ""}) => {
    const newRecentlyViewedCars = [...state.recentlyViewedCars];
    if (newRecentlyViewedCars.includes(data)) {
        newRecentlyViewedCars.splice(newRecentlyViewedCars.indexOf(data), 1);
    }
    return {
        ...state,
        recentlyViewedCars: data ? [...state.recentlyViewedCars, data] : state.recentlyViewedCars
    };
};

const updateIsFirstUserSession = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isFirstUserSession: data
    };
};

export const HANDLERS = {
    [Types.SET_PROFILE_NAME]: setProfileName,
    [Types.SET_MOBILE]: setMobile,
    [Types.SET_LAST_VERIFIED_MOBILE]: setLastVerifiedMobile,
    [Types.SET_PROFILE_MOBILE]: setProfileMobile,
    [Types.SET_EMAIL]: setEmail,
    [Types.GET_PROFILE_DETAILS_SUCCESS]: getProfileDetailsSuccess,
    [Types.GET_PROFILE_DETAILS_FAILURE]: getProfileDetailsFailure,
    [Types.MARK_UNAUTHORIZED]: markUnauthorized,
    [Types.CHECK_AUTH]: setCheckAuth,
    [Types.SET_FIRST_LOAD]: setFirstLoad,
    [Types.UPDATE_WEBVIEW_STATUS]: updateWebviewStatus,
    [Types.SET_GA_ID]: setGAId,
    [Types.LOGOUT_SUCCESS]: logoutSuccess,
    [Types.LOGOUT_FAILURE]: logoutFailure,
    [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
    [Types.UPDATE_PROFILE_FAILURE]: updateProfileFailure,
    [Types.SET_SECURE_TOKEN]: setSecureToken,
    [Types.OPEN_LOGIN_POPUP]: openLoginPopup,
    [Types.CLOSE_LOGIN_POPUP]: closeLoginPopup,
    [Types.SET_SHOW_ALLOW_TRACKING]: setShowAllowTracking,
    [Types.SET_SHOW_SPLIT_PAYMENT_MODAL]: setSplitPaymentModal,
    [Types.SEO_HEADER_MENU_SUCCESS]: seoHeaderMenuSuccess,
    [Types.SEO_HEADER_MENU_FAILURE]: seoHeaderMenuFailure,
    [Types.SET_AFFILIATE_SOURCE]: setAffiliateSource,
    [Types.SET_LOGIN_TYPE]: setLoginType,
    [Types.SET_CURRENT_LOGO_URL]: setLogoUrl,
    [Types.GET_ZERO_DP_VARIANT_INIT]: getZeroDpConfigInit,
    [Types.GET_ZERO_DP_VARIANT_SUCCESS]: getZeroDpConfigSuccess,
    [Types.GET_ZERO_DP_VARIANT_FAILURE]: getZeroDpConfigFailure,
    [Types.SET_INITIAL_ZERO_DP_OPTIMIIZE_VARIANT]: setZeroDpVariant,
    [Types.SET_PROFILE_DETAILS]: setProfileDetails,
    [Types.HIDE_SITE_HEADER]: setHideSiteHeader,
    [Types.GET_LOCATION_INIT]: setLocationInit,
    [Types.GET_LOCATION_SUCCESS]: setLocationSuccess,
    [Types.GET_LOCATION_FAILURE]: setLocationFailure,
    [Types.SET_IS_CT_SUB]: setIsCtSub,
    [Types.CLOSE_CLP_SALE_NUDGE]: closeClpNudge,
    [Types.CLP_SALE_ANIMATION_SEEN]: clpSaleAnimationSeen,
    [Types.SET_CURRENT_BROWSER]: setCurrentBrowser,
    [Types.SET_IS_SSR]: setIsSSR,
    [Types.UPDATE_RECENTLY_VIEWED_CARS]: updateRecentlyViewedCars,
    [Types.UPDATE_IS_FIRST_USER_SESSION]: updateIsFirstUserSession
};

export default createReducer(INITIAL_STATE, HANDLERS);
