/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import styles from "./styles.css";
import { REDIRECT_TO_BOOKING } from "../../../constants/checkout-constants";
import { LOAN_PROVIDER } from "../../../constants/app-constants";
import { loadMultipleAsyncScript } from "../../../utils/helpers/load-muliple-async-script";
import Loader from "../loader";
import GreenIdScripts from "./green-id-scripts";

const ERROR_TIMEOUT = "5000";

const GREEN_ID_SCRIPTS = {
    PRODUCTION: [
        "https://simpleui-au.vixverify.com/df/javascripts/greenidConfig.js",
        "https://simpleui-au.vixverify.com/df/javascripts/greenidui.min.js",
        "https://simpleui-au.vixverify.com/df/javascripts/libs/jquery-3.5.1.min.js",
        "https://au.vixverify.com/df/javascripts/libs/bootstrap.js"
    ],
    STAGING: [
        "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
        "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
        "https://simpleui-test-au.vixverify.com/df/javascripts/libs/jquery-3.5.1.min.js",
        "https://test-au.vixverify.com/df/javascripts/libs/bootstrap.js"
    ]

};

const GreenId = ({
    registerVerificationStatusConnect,
    getVerificationStatusConnect,
    order
}) => {
    const ACCOUNTNAME = process.env.GREEN_ID_ACCOUNTNAME;
    const APICODE = process.env.GREEN_ID_APICODE;
    const RULE_ID = process.env.GREEN_ID_RULE_ID;
    const [check, setCheck] = useState(false);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    const query = (useLocation().search || "").toString();
    const queryParams = qs.parse(query);
    const { verificationToken, status, success = "", error = "", orderId } = queryParams;
    const { financeProvider = "" } = order || {};

    useEffect(() => {
        const loadGreenId = async () => {
            try {
                await loadMultipleAsyncScript(GREEN_ID_SCRIPTS[process.env.HOST_ENV]);
                setIsScriptLoaded(true);
            } catch (scriptError) {
                setIsScriptLoaded(false);
            }
        };
        loadGreenId();
    }, []);

    const handleRedirects = () => {
        if ((financeProvider === LOAN_PROVIDER.BYOF) || (financeProvider === LOAN_PROVIDER.NONE)) {
            window.location.href = `${REDIRECT_TO_BOOKING}/${order.appointmentId}/verify/user-id`;
        } else {
            window.location.href = `${REDIRECT_TO_BOOKING}-${order.appointmentId}/tasks`;
        }
    };

    useEffect(() => {
        if (window.greenidUI && isScriptLoaded) {
            window.greenidJQuery("document").ready(() => {
                window.greenidUI.setup({
                    environment: process.env.HOST_ENV === "PRODUCTION" ? "prod" : "test",
                    frameId: "greenid-div",
                    onRegister: (verificationId) => { console.log("registerCallback==>", verificationId); },
                    registerCallback: (verificationId) => { console.log("registerCallback==>", verificationId); },
                    onRegisterCallback: (verificationId) => { console.log("registerCallback==>", verificationId); },
                    errorCallback: (res, overallState) => {
                        console.log("errorCallback==>", res, overallState);
                        window.setTimeout(() => {
                            handleRedirects();
                        }, ERROR_TIMEOUT);
                    },
                    sessionCompleteCallback: (res, overallState) => {
                        console.log("sessionCompleteCallback==>", res, overallState);
                        registerVerificationStatusConnect(orderId, res, verificationToken ? verificationToken : "").then(() => {
                            window.setTimeout(() => {
                                handleRedirects();
                            }, ERROR_TIMEOUT);
                        });
                    },
                    sourceAttemptCallback: (res, overallState) => { console.log("sourceAttemptCallback==>", res, overallState); },
                    sessionCancelledCallback: (res, overallState) => {
                        console.log("sessionCancelledCallback==>", res, overallState);
                        window.setTimeout(() => {
                            handleRedirects();
                        }, ERROR_TIMEOUT);

                        // window.location.href = `${error}${makeParams(overallState)}`;
                    },
                    submitCallback: (res, overallState) => {
                        console.log("submitCallback==>", res, overallState);
                        if (verificationToken) {
                            registerVerificationStatusConnect(orderId, res, verificationToken).then(() => {
                                window.setTimeout(() => {
                                    handleRedirects();
                                }, ERROR_TIMEOUT);
                            });
                        } else {
                            window.setTimeout(() => {
                                handleRedirects();
                            }, ERROR_TIMEOUT);
                        }

                    }
                });
                setCheck(true);
            });
        }
    }, [error, getVerificationStatusConnect, orderId, registerVerificationStatusConnect, success, verificationToken, isScriptLoaded]);

    const startBiometrics = useCallback(() => {
        // if you want to override anything from the greenidConfig.js file, you would use this function
        // greenidConfig.setOverrides(overrides);
        if (verificationToken && status === "CREATED") {
            window.greenidUI.show(ACCOUNTNAME, APICODE, verificationToken);
        } else {
            window.greenidUI.showBiometricCapture(ACCOUNTNAME, APICODE, RULE_ID);
        }
    });

    useEffect(() => {
        if (window.greenidUI && check) {
            startBiometrics();
        }
    }, [check, startBiometrics]);

    if (isScriptLoaded) {
        return (
            <div>
                <GreenIdScripts />
                <div id="greenid-div" styleName={"styles.greenid-div"} />
            </div>
        );
    }
    return <Loader />;
};

GreenId.propTypes = {
    registerVerificationStatusConnect: PropTypes.func,
    getVerificationStatusConnect: PropTypes.func,
    order: PropTypes.object
};

export default GreenId;
