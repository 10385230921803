import React from "react";
import styles from "./styles.css";
import StepOne from "./images/step-1.png";
import StepTwo from "./images/step-2.png";
import StepThree from "./images/step-3.png";
import StepFour from "./images/step-4.png";

const DriveWithPeaceOfMind = () => {
    return (
        <div styleName={"styles.outer"}>
            <p styleName={"styles.heading"}>Drive with peace of mind</p>
            <div styleName={"styles.stepsWrapper"}>
                <div styleName={"styles.leftWrapper"}>
                    <p styleName={"styles.count"}>1</p>
                    <p styleName={"styles.stepsDetail"}>Choose one of our bundles <br />or select products individually</p>
                </div>
                <img src={StepOne} />
            </div>
            <div styleName={"styles.stepsWrapper"}>
                <img src={StepTwo} />
                <div styleName={"styles.rightWrapper"}>
                    <p styleName={"styles.count"}>2</p>
                    <p styleName={"styles.stepsDetail"}>Pay as you want. Pay full<br />or add it to your loan</p>
                </div>
            </div>
            <div styleName={"styles.stepsWrapper"}>
                <div styleName={"styles.leftWrapper"}>
                    <p styleName={"styles.count"}>3</p>
                    <p styleName={"styles.stepsDetail"}>We’ll get your car ready<br />with add-ons for delivery </p>
                </div>
                <img src={StepThree} />
            </div>
            <div styleName={"styles.stepsWrapper"}>
                <img src={StepFour} />
                <div styleName={"styles.rightWrapper"}>
                    <p styleName={"styles.count"}>4</p>
                    <p styleName={"styles.stepsDetail"}>Drive with peace. It’s time<br />to show off your car</p>
                </div>
            </div>
        </div>
    );
};

export default DriveWithPeaceOfMind;
