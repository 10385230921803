import { GreenIdService } from "../../../service";
import Types from "./types";

const registerVerificationStatusInit  = (data) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_INIT,
    data
});

const registerVerificationStatusSuccess = (data) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_SUCCESS,
    data
});

const registerVerificationStatusFailure = (error) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_FAILURE,
    error
});

const registerVerificationStatus = (orderId, verificationId, verificationToken) => async (dispatch, getState) => {
    dispatch(registerVerificationStatusInit());
    const { user: { secureToken }  } = getState();
    try {
        const response = await GreenIdService.registerVerificationStatus(secureToken, orderId, verificationId, verificationToken);
        const data = response.data && response.data.data;
        dispatch(registerVerificationStatusSuccess(data));
        return Promise.resolve(data);

    } catch (error) {
        dispatch(registerVerificationStatusFailure(error));
        return Promise.reject(error);
    }
};

export {
    registerVerificationStatus
};
