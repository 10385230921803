const loadScript = (url) => {
    return new Promise(((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = false;
        script.onload = function() {
            resolve(url);
        };
        script.onerror = function() {
            reject(url);
        };
        document.body.appendChild(script);
    }));
};

const loadMultipleAsyncScript = async (scripts = []) => {
    // save all Promises as array
    const promises = [];
    scripts.forEach((url) => {
        promises.push(loadScript(url));
    });
    try {
        const dataLoaded = await Promise.all(promises);
        window.console.log("loading scripts....", dataLoaded);
        return Promise.resolve(dataLoaded);
    } catch (error) {
        window.console.log(error, "all scripts loaded");
        return Promise.reject(error);
    }
};

export {
    loadMultipleAsyncScript
};
