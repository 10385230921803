import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CITY_LIST_SUCCESS
    GET_DRIVA_TIMEOUT_CONFIG_SUCCESS
    GET_DRIVA_TIMEOUT_CONFIG_FAILURE
    GET_DRIVA_TIMEOUT_CONFIG_REQUEST

    GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_SUCCESS
    GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_FAILURE
    GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_REQUEST

    IN_HOUSE_FINANCE_API_POLL_SUCCESS
    IN_HOUSE_FINANCE_API_POLL_FAILURE

    DRIVA_API_POLL_SUCCESS
    DRIVA_API_POLL_FAILURE

    DRIVA_API_CALLBACK_SUCCESS
    DRIVA_API_CALLBACK_FAILURE

    CHECKOUT_SCREEN_CONFIG_SUCCESS
    CHECKOUT_SCREEN_CONFIG_FAILURE
    CHECKOUT_SCREEN_CONFIG_REQUEST
`,
    {
        prefix: "configssr/"
    }
);
