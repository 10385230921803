import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { withRouter } from "react-router-dom";
import CheckoutPillNavigation from "../checkout-pill-navigation";
import PreApprovalPillNavigation from "../pre-approval-pill-navigation";

const CheckoutHeader = ({
    showExtraInfo = false,
    showPreApprovalPill = false,
    onCustomPillClick = () => {}
}) => {

    return (
        <React.Fragment>
            {showExtraInfo ? (
                <React.Fragment>
                    {showPreApprovalPill ? <PreApprovalPillNavigation /> : <CheckoutPillNavigation onCustomPillClick={onCustomPillClick} />}
                </React.Fragment>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

CheckoutHeader.propTypes = {
    onBackClick: PropTypes.func.isRequired,
    onCallClick: PropTypes.func.isRequired,
    onCustomPillClick: PropTypes.func,
    showExtraInfo: PropTypes.bool,
    showPreApprovalPill: PropTypes.bool,
    content: PropTypes.object
};

export default withRouter(CheckoutHeader);
