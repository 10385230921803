import React from "react";
import styles from "./styles.css";
import Warranty from "./images/warranty.svg";
import Installation from "./images/installation.svg";

const cardsData = [
    {
        image: Warranty,
        heading: "Warranty Included",
        text: "Upto <span>lifetime</span> warranty"
    },
    {
        image: Installation,
        heading: "Installation included",
        text: "<span>Ready</span> at delivery"
    }
];

const WarrantyInstallationIncludedCard = () => {
    return (
        cardsData.map(el => {
            return (<div key={el.heading} className="media" styleName={"styles.mediaContainer"}>
                <img src={el.image} />
                <div className="media-body">
                    <p styleName={"styles.heading"}>{el.heading}</p>
                    <p styleName={"styles.subHeading"} dangerouslySetInnerHTML={{ __html: el.text }} />
                </div>
            </div>);
        })
    );
};

export default WarrantyInstallationIncludedCard;
