import { PAGE_SOURCE } from "../../constants/app-constants";
import getCurrentPage from "./get-current-page";

export const pageSource = (isFinanceCentricListing) => {
    const {isDetailsPage, isListingPage, isHomePage} = getCurrentPage();
    if (isHomePage) {
        return PAGE_SOURCE.HOME_PAGE;
    } else if (isDetailsPage) {
        return PAGE_SOURCE.CDP;
    } else if (isFinanceCentricListing) {
        return PAGE_SOURCE.FINANCE_CLP;
    } else if (isListingPage) {
        return PAGE_SOURCE.CLP;
    }
    return null;
};
