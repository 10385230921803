import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    "successUrl": "",
    "cancelUrl": "",
    "failureUrl": ""
};

export const setPaymentConfiguration = (state = INITIAL_STATE, {data}) => {
    return { ...state, ...data};
};

export const HANDLERS = {
    [Types.SET_PAYMENT_CONFIGURATION]: setPaymentConfiguration
};

export default createReducer(INITIAL_STATE, HANDLERS);
