export const AMPLITUDE_EVENT_NAMES = {
    PURCHASE_STARTED: "purchase started",
    PURCHASE_STARTED_USP: "purchase started usp"
};

export const GA_EVENTS = {
    BOOKING_INITATED_USP: "booking_initiated_usp",
    BOOKING_INTIATED_DETAIL: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "booking_initiated_usp"
    }
};
