// import loadable from "@loadable/component";

// const TaskOverView = loadable(() => import("../../components/au.desktop/order-summary-tasks"));
import TestDriveBasicDetails from "../../components/au.desktop/test-drive/basic-details";
// import TestDriveDeliveryDetails from "../../components/au.desktop/test-drive/test-drive-date";
import TestDrivePayment from "../../components/au.desktop/test-drive/pay-deposit";
import TestDriveFinishPage from "../../components/au.desktop/test-drive-confirmation-page";
import AdyenPay from "../../components/au.desktop/adyen-payment";

export const testDriveRoutes = {
    basicDetails: {
        key: "basicDetails",
        name: "basic details",
        route: "basic-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "basic_details",
        defaultBack: "",
        defaultNext: "payment-summary",
        component: TestDriveBasicDetails,
        hideCurrentPill: false,
        useRoute: null,
        type: "testDrive",
        postBookingRoute: false,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Basic details",
            subHeading: "Tell us about yourself",
            navKey: "basicDetails"
        }
    },
    paymentSummary: {
        key: "paymentSummary",
        name: "Payment Summary",
        route: "payment-summary",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "basic-details",
        component: TestDrivePayment,
        hideCurrentPill: false,
        useRoute: null,
        approx: 1,
        type: "testDrive",
        postBookingRoute: false,
        showEgcPrice: false,
        sidebarNavigation: {
            heading: "Pay deposit",
            subHeading: "100% refundable",
            navKey: "paymentSummary"
        }
    },
    payment: {
        key: "payment",
        name: "task",
        route: "payment",
        gtmPageName: "Paymenbt",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "basic-details",
        component: AdyenPay,
        type: "testDrive",
        approx: 0,
        useRoute: null,
        sidebarNavigation: {
            heading: "Pay deposit",
            subHeading: "100% refundable",
            navKey: "paymentSummary"
        }
    },
    orderConfirmed: {
        key: "orderConfirmed",
        name: "Order Confirmed",
        route: "order-confirmed",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: TestDriveFinishPage,
        postBookingRoute: true,
        hideCurrentPill: false,
        useRoute: null,
        approx: 1,
        type: "testDrive",
        sidebarNavigation: {
            heading: "Confirmation",
            subHeading: "Test drive summary",
            navKey: "orderConfirmed"
        }
    }
};

export const testDriveRoutesArray = Object.values(testDriveRoutes);

export const routesToRedirecttasks = testDriveRoutesArray
    .filter((routeData) => routeData.type === "tasks")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = testDriveRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = testDriveRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);
