import mobileEventsAU from "./c2b-mobile-events";
// import desktopEventsAU from "./au.desktop-events";

const sendEvent = (data) => {
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({ ...data });
    }
};

export const trackCustomEvents = ({ platformEvents, category }, extras) => {
    const data = { ...extras, ...platformEvents[category] };
    sendEvent(data);
};

export const trackC2bMobileCustomEvents = (category, extras = {eventLabel: "NA"}) => {
    trackCustomEvents(
        {
            platformEvents: mobileEventsAU,
            category
        },
        extras
    );
};

export const sendCustomEvent = (data) => {
    sendEvent(data);
};
