import React from "react";
import withAULayout from "../../../components/au.desktop/layout";
import ErrorHandler from "../../../components/shared/with-error-handler";
import HomePage from "../../../components/au.desktop/home";
import HomeMeta from "../../../components/shared/meta-constants";

const Home = () => {
    return (
        <React.Fragment>
            <HomeMeta/>
            <ErrorHandler>
                <HomePage />
            </ErrorHandler>
        </React.Fragment>
    );
};

export default withAULayout(Home, true, true, {headerProps: {fixed: true}});
