export const MARKETING_EVENTS_CONSTANTS = {
    HOME: "HOME",
    VIEW_ALL_CARS: "VIEW_ALL_CARS",
    SEARCH_RESULTS: "SEARCH_RESULTS",
    CAR_DETAIL: "CAR_DETAIL",
    BOOKING_INITIATED: "BOOKING_INITIATED",
    START_PURCHASE: "START_PURCHASE",
    BOOKING_CONFIRMED: "BOOKING_CONFIRMED",
    FINANCE_STEP: "FINANCE_STEP",
    FINANCE_SELECTED: "FINANCE_SELECTED",
    CATEGORY: "CATEGORY"

};
const MARKETING_EVENTS = {
    [MARKETING_EVENTS_CONSTANTS.HOME]: {
        "event": "dynRmktParamsReady",
        "google_tag_params": {
            "ecomm_pagetype": "home"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.VIEW_ALL_CARS]: {
        "event": "dynRmktParamsReady",
        "google_tag_params": {
            "ecomm_pagetype": "view-all-cars"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.SEARCH_RESULTS]: {
        "event": "dynRmktParamsReady",
        "google_tag_params": {
            "ecomm_pagetype": "searchresults"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.CAR_DETAIL]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "cardetail"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.BOOKING_INITIATED]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "booking-initiated"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.START_PURCHASE]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "start-purchase"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.BOOKING_CONFIRMED]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "booking-confirmed"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.FINANCE_STEP]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "finance-step"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.FINANCE_SELECTED]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "finance-selected"
        }
    },
    [MARKETING_EVENTS_CONSTANTS.CATEGORY]: {
        event: "dynRmktParamsReady",
        google_tag_params: {
            "ecomm_pagetype": "category"
        }
    }
};

export default MARKETING_EVENTS;
