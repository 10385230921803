import { X_VEHICLE_TYPE } from "../constants/api-constants";

// eslint-disable-next-line max-statements
export default (api) => {
    const fetchNudgeList = (token) => {
        return api.get(`/v1/alerts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const closeNudgeList = (token, payload) => {
        return api.put(`/v1/alerts/suppress`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    return {
        fetchNudgeList,
        closeNudgeList
    };
};
