import Types from "./types";
import { C2bTradeService, CheckoutTradeInService } from "../../../service";
import { X_VEHICLE_TYPE } from "../../../constants/api-constants";

const getTradeInDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_REQUEST
});

const getTradeInDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_SUCCESS,
    data
});

const getTradeInDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_FAILURE,
    error
});

const getTradeInDetails = () =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const { user: { secureToken } } = getState();
        try {
            const response = await CheckoutTradeInService.getTradeInDetails(secureToken);
            dispatch(getTradeInDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeInDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const createTradeInRequest = () => ({
    type: Types.CREATE_TRADE_IN_REQUEST
});

const createTradeInSuccess = (data) => ({
    type: Types.CREATE_TRADE_IN_SUCCESS,
    data
});

const createTradeInFailure = (error) => ({
    type: Types.CREATE_TRADE_IN_FAILURE,
    error
});

const createTradeIn = (params = { submitted: false }, token) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    currency
                }
            },
            carDetails: {
                content: {
                    appointmentId
                }
            }
        } = getState();
        const payload = {
            userEmail: email,
            appointmentId,
            currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            CheckoutTradeInService.createTradeInTopFunnel(payload, (secureToken || token))
                .then((response) => {
                    dispatch(createTradeInSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(createTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateTradeInRequest = () => ({
    type: Types.UPDATE_TRADE_IN_REQUEST
});

const updateTradeInSuccess = (data) => {
    return {
        type: Types.UPDATE_TRADE_IN_SUCCESS,
        data
    };
};

const updateTradeInFailure = (error) => ({
    type: Types.UPDATE_TRADE_IN_FAILURE,
    error
});

const updateTradeIn = (params = { submitted: false }, token) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    currency
                }
            }
        } = getState();
        const payload = {
            userEmail: email,
            currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            CheckoutTradeInService.updateTradeInTopFunnel(payload, (secureToken || token))
                .then((response) => {
                    dispatch(updateTradeInSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const getTradeInStateRequest = () => ({
    type: Types.GET_TRADE_IN_STATE_REQUEST
});

const getTradeInStateSuccess = (data) => ({
    type: Types.GET_TRADE_IN_STATE_SUCCESS,
    data
});

const getTradeInStateFailure = (error) => ({
    type: Types.GET_TRADE_IN_STATE_FAILURE,
    error
});

const getTradeInState = () => async (dispatch, getState) => {
    dispatch(getTradeInStateRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getTradeInStates(secureToken);
        const stateValue = response.data.reduce((acc, data) => {
            acc.push({ ...data, label: data.stateName, value: data.stateName});
            return acc;
        }, []);
        dispatch(getTradeInStateSuccess(stateValue));
        return response;
    } catch (error) {
        dispatch(getTradeInStateFailure(error));
        return error;
    }
};

const getTradeVehicleDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_VEHICLE_REQUEST
});

const getTradeVehicleDetailsSuccess = ({data}) => ({
    type: Types.GET_TRADE_IN_VEHICLE_SUCCESS,
    data
});

const getTradeVehicleDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_VEHICLE_FAILURE,
    error
});

const getTradeVehicleDetails = (params = {}, token) =>
    async (dispatch, getState) => {
        dispatch(getTradeVehicleDetailsRequest());
        const { user: { secureToken }} = getState();
        try {
            const response = await CheckoutTradeInService.getTradeInVehicleDetails((secureToken || token), params);
            dispatch(getTradeVehicleDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeVehicleDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const setTradeInAnotherCarTriggered = (data) => ({
    type: Types.SET_TRADE_IN_ANOTHER_CAR_TRIGGERED,
    data
});

const setEditCarDetailsTriggered = (data) => ({
    type: Types.SET_EDIT_CAR_DETAILS_TRIGGERED,
    data
});

const setTradeInToggleSelected = (data) => ({
    type: Types.SET_TRADE_IN_TOGGLE_SELECTED,
    data
});

const saveTradeInCarMakeModelId = (data) => ({
    type: Types.SAVE_TRADE_IN_ID,
    data
});

const fetchTradeInCarVariantRequest = () => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_REQUEST
});

const fetchTradeInCarVariantsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS,
    data
});

const fetchTradeInCarVariantsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_FAILURE,
    error
});

const setViewCarsButtonTriggered = (data) => ({
    type: Types.SET_VIEW_CARS_BUTTON_TRIGGERED,
    data
});

const getTradeInCarVariants = (params = {}) => async (dispatch, getState) => {
    dispatch(fetchTradeInCarVariantRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getVariants(secureToken, params);
        dispatch(fetchTradeInCarVariantsSuccess(response.data.data));
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarVariantsFailure(error));
        return error;
    }
};

const fetchTradeInCarDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_REQUEST
});

const fetchTradeInCarDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS,
    data
});

const fetchTradeInCarDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_FAILURE,
    error
});

const getTradeInCarMakeModel = (token) => async (dispatch, getState) => {
    dispatch(fetchTradeInCarDetailsRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getCarMakeModel((secureToken || token));
        dispatch(fetchTradeInCarDetailsSuccess(response.data.data));
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarDetailsFailure(error));
        return error;
    }
};

const fetchCancellationReasonRequest = () => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON
});

const fetchCancellationReasonSuccess = (cancelationReasons) => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS,
    cancelationReasons
});

const fetchCancellationReasonFailure = (error) => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE,
    error
});

const fetchCancellationReason = () => (dispatch, getState) => {
    dispatch(fetchCancellationReasonRequest());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        CheckoutTradeInService.fetchCancellationReason(secureToken)
            .then(response => {
                const reasons = response.data && response.data.feedback;
                dispatch(fetchCancellationReasonSuccess(reasons));
                resolve(reasons);
            }).catch(error => {
                dispatch(fetchCancellationReasonFailure(error));
                reject(error);
            });
    });
};

const saveTradeInRegoandVin = (data) => ({
    type: Types.SAVE_CAR_REGNO_VIN,
    data
});

const getTradeInToggleVisibleRequest = () => ({
    type: Types.GET_TRADE_IN_TOGGLE_VISIBLE_REQUEST
});

const getTradeInToggleVisibleSuccess = (data) => ({
    type: Types.GET_TRADE_IN_TOGGLE_VISIBLE_SUCCESS,
    data
});

const getTradeInToggleVisibleFailure = (error) => ({
    type: Types.GET_TRADE_IN_TOGGLE_VISIBLE_FAILURE,
    error
});

const getTradeInToggleVisible = () =>
    async (dispatch, getState) => {
        dispatch(getTradeInToggleVisibleRequest());
        const { user: { secureToken, isLoggedIn}, tradeIn: {tradeInToggleSelected} } = getState();
        try {
            const response = await C2bTradeService.getTradeInDetailsV2(secureToken);
            const {havingAnOffer = false, expired = false, offeredPrice = 0, c2bOrderId = "", c2bEligible = false} = response.data;
            let data = {};
            if (havingAnOffer && !expired) {
                data = {
                    tradeIn: true,
                    tradeInAmount: offeredPrice,
                    c2bOrderId,
                    c2bEligible
                };
            } else {
                data = {
                    tradeIn: false,
                    c2bEligible: false,
                    tradeInAmount: 0
                };
            }
            dispatch(getTradeInToggleVisibleSuccess(data));
            if (tradeInToggleSelected === null && isLoggedIn && havingAnOffer && !expired) dispatch(setTradeInToggleSelected(true));
            else if (tradeInToggleSelected && expired) dispatch(setTradeInToggleSelected(null));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeInToggleVisibleFailure(error));
            dispatch(setTradeInToggleSelected(null));
            return Promise.reject(error);
        }
    };

const setTradeInToggleVisibleState = () => (dispatch, getState) => {
    const { tradeIn: { tradeInData }, user: {isLoggedIn}} = getState();
    const { offeredPrice = 0 } = tradeInData || {};
    const data = {
        tradeIn: true,
        tradeInAmount: offeredPrice
    };
    dispatch(setViewCarsButtonTriggered(true));
    dispatch(getTradeInToggleVisibleSuccess(data));
    const toggleState = isLoggedIn ? true : null;
    dispatch(setTradeInToggleSelected(toggleState));
};

const setPillsMaxStep = (data) => ({
    type: Types.SET_PILLS_MAX_STEP,
    data
});

const setQuestionnairePageNo = (data) => ({
    type: Types.SET_QUESTIONNAIRE_PAGE_NO,
    data
});

const resetTradeInDetails = () => ({
    type: Types.RESET_CAR_TRADE_IN_DETAILS
});

export {
    getTradeInDetails,
    createTradeIn,
    updateTradeIn,
    getTradeInState,
    getTradeVehicleDetails,
    setTradeInAnotherCarTriggered,
    setEditCarDetailsTriggered,
    setTradeInToggleSelected,
    saveTradeInCarMakeModelId,
    getTradeInCarVariants,
    getTradeInCarMakeModel,
    saveTradeInRegoandVin,
    getTradeInToggleVisible,
    setTradeInToggleVisibleState,
    setPillsMaxStep,
    setQuestionnairePageNo,
    resetTradeInDetails,
    setViewCarsButtonTriggered,
    fetchCancellationReason
};
