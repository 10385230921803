import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./styles.css";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import Tick from "./images/tick.svg";

const Pill = ({
    pillData,
    current,
    active,
    content,
    activeRef,
    index,
    showGreenTick = false,
    showNumber = false,
    onCustomPillClick = () => {}
}) => {
    if (current) {
        return (<div ref={activeRef} styleName={`styles.pill styles.currentPill ${showGreenTick ? "styles.greens" : ""}`} className="pill">
            {showNumber &&
                    <div styleName={"styles.circle"}>
                        {index}
                    </div>
            }
            {pillData.name}
        </div>);
    }

    if (!active) {
        return (
            <div styleName="styles.pill styles.inactivePill" className="pill">
                {showNumber &&
                    <div styleName={"styles.inactiveCirlce"}>
                        {index}
                    </div>
                }
                {pillData.name}
            </div>
        );
    }

    const {relativeURL} = getCheckoutPageURL(content, pillData.route);

    const trackClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_CHIP_NAVIGATION, {
            eventLabel: `options ${pillData.gtmPillClickLabel}`
        });
    };

    return (
        <Link to={pillData.type === "pre-approval-financing" ? pillData.route : relativeURL} onClick={(e) => onCustomPillClick(e, pillData) || trackClick}>
            <div styleName={`styles.pill ${showGreenTick ? "styles.greens" : ""}`} className="pill">
                {showNumber &&
                    <div styleName={current ? "styles.circle" : "styles.inactiveCirlce"}>
                        {showGreenTick ? <img src={Tick} alt="" /> :  index}
                    </div>
                }
                {pillData.name}
            </div>
        </Link>
    );
};

Pill.propTypes = {
    pillData: PropTypes.object,
    current: PropTypes.bool,
    active: PropTypes.bool,
    index: PropTypes.number,
    showNumber: PropTypes.bool,
    content: PropTypes.object,
    activeRef: PropTypes.object,
    onCustomPillClick: PropTypes.func,
    showGreenTick: PropTypes.bool
};

export default Pill;
