import { createReducer } from "reduxsauce";
import Types from "./types";
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import {FILTER_TAB_TYPES} from "../../../utils/filters-v2/constants";
import getUpdatedFilters from "../../../utils/filters-v2/get-updated-filters";
// import getAppliedFilters from "../../../utils/filters-v2/get-applied-filters";
import { getSortData } from "../../../utils/filters-v2/get-sort-data";
import getUpdatedAppliedFilters, { getUpdatedSubFilterOptions } from "../../../utils/filters-v2/get-updated-applied-filters";
import { LISTING_TYPE } from "../../../constants/app-constants";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: null,
    sorts: [],
    allFilters: {},
    onScreenFilters: [],
    offScreenFilter: [],
    selectedOnScreenFilter: "",
    showAllFilters: false,
    isListingCountFetched: false,
    isListingCountLoading: false,
    listingCount: null,
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    selectedSort: {
        key: "releavance",
        displayText: "By Relevance"
    },
    appliedFilters: [],           // Filters which are applied in the listing.
    selectedFilters: [],         // Filters which are selected but not yet applied in the listing
    selectedSuggestions: [],
    appliedSuggestions: [],
    urlOptions: {},
    isSortModalOpen: false,
    categories: [],
    loadingCategory: true,
    categoryError: false,
    selectedCategories: [],
    marketCategoryEvent: false,
    listingType: LISTING_TYPE.ALL_CARS,
    fbEventType: "",
    removedMakeFilter: "",
    config: {}
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { data }) => {
    const filtersData = getFiltersData(data.filters);
    const sortsData = getSortData(data.sorts);

    return {
        ...state,
        isFetched: true,
        isLoading: false,
        sorts: {...sortsData},
        ...filtersData,
        config: data.config || {},
        listingCount: data.total

    };
};

export const fetchFiltersFailure = () => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: null,
        isLoading: false,
        lastUpdated: (new Date()).getTime()
    };
};

// export const fetchSSRFiltersSuccess = (state = INITIAL_STATE, {data}) => {
//     const filtersData = getFiltersData(filterMock.filters);
//     return {
//         ...state,
//         isFetched: true,
//         isLoading: false,
//         ...filtersData
//     };
// };

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateFilterStamp = (state = INITIAL_STATE, { lastUpdated }) => {
    return { ...state, lastUpdated };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, {selectedOnScreenFilter}) => {
    return {
        ...state,
        selectedOnScreenFilter
    };
};

export const updateFilterOptionData = (state = INITIAL_STATE, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData = {}, filterOptionData = {}, subFilterOptionData = {}}) => {
    return {
        ...state,
        allFilters: getUpdatedFilters(state.allFilters, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData, filterOptionData, subFilterOptionData})
    };
};

export const updateSelectedFiltersList = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedFilters: getUpdatedAppliedFilters(data, state.selectedFilters)
    };
};

export const updateSelectedSuggestions = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedSuggestions: getUpdatedAppliedFilters(data, state.selectedSuggestions)
    };
};

export const updateAllFiltersVisibility = (state = INITIAL_STATE, {showAllFilters}) => {
    return {
        ...state,
        showAllFilters
    };
};

export const fetchListingCountInit = (state = INITIAL_STATE) => {
    return { ...state, isListingCountLoading: true };
};

export const fetchListingCountSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false,
        listingCount: data.total
    };
};

export const fetchListingCountFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, {activeFilterTab}) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const updateSelectedSort = (state = INITIAL_STATE, {selectedSort}) => {
    return {
        ...state,
        selectedSort
    };
};

export const clearAllFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: [],
        appliedFilters: [],
        selectedSuggestions: [],
        appliedSuggestions: [],
        selectedSort: INITIAL_STATE.selectedSort,
        listingCount: INITIAL_STATE.listingCount,
        listingType: LISTING_TYPE.ALL_CARS
    };
};

export const updateAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedFilters: [
            ...state.selectedFilters
        ]
    };
};

export const updateAppliedSuggestions = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedSuggestions: [
            ...state.selectedSuggestions
        ]
    };
};

export const updateUrlOptions = (state = INITIAL_STATE, {urlOptions}) => {
    return {
        ...state,
        urlOptions
    };
};

export const clearSuggestions = (state = INITIAL_STATE, {filterKey}) => {
    const updatedSuggestions = state.selectedSuggestions.filter(suggestion => suggestion.filterKey !== filterKey);
    return {
        ...state,
        selectedSuggestions: updatedSuggestions
    };
};

export const updateAllSubFilterOptions = (state = INITIAL_STATE, {filterKey, optionKey, isSelected, isAllModelVariant = false}) => {
    return {
        ...state,
        selectedFilters: getUpdatedSubFilterOptions({filterKey, optionKey, isSelected, selectedFilters: state.selectedFilters, allFilters: state.allFilters, isAllModelVariant})
    };
};

export const updateSortModalVisibility = (state = INITIAL_STATE, {isVisible}) => {
    return {
        ...state,
        isSortModalOpen: isVisible
    };
};

const fetchCategoryFilterInit = (state = INITIAL_STATE, {loading}) => {
    return {
        ...state,
        loadingCategory: loading
    };
};

const fetchCategoryFiltersSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        categories: data,
        loadingCategory: false
    };
};

const fetchCategoryFiltersFailure = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        categoryError: data,
        loadingCategory: false
    };
};

const updateAllSelectedFilterOptions = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedFilters: [...state.selectedFilters, ...data],
        appliedFilters: [...state.appliedFilters, ...data]
    };
};

const updateSelectedCategories = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedCategories: [...state.selectedCategories, ...data]
    };
};

export const setMarketingCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        marketCategoryEvent: data
    };
};

export const updateListingType = (state = INITIAL_STATE, { listingType }) => ({
    ...state,
    listingType
});

const setListingFBEvents = (state = INITIAL_STATE, { fbEventType }) => ({
    ...state,
    fbEventType
});

const clearSelectedFilter = (state = INITIAL_STATE, { data }) => ({
    ...state,
    removedMakeFilter: data
});

export const replaceSelectedFilters = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectedFilters: [...data]
    };
};

export const HANDLERS = {
    [Types.FETCH_FILTERS]: fetchFiltersInit,
    [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_FILTER_TIMESTAMP]: updateFilterStamp,
    [Types.UPDATE_SELECTED_ON_SCREEN_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_FILTER_OPTION_DATA]: updateSelectedFiltersList,
    [Types.UPDATE_ALL_FILTERS_VISIBILITY]: updateAllFiltersVisibility,
    [Types.FETCH_LISTING_COUNT]: fetchListingCountInit,
    [Types.FETCH_LISTING_COUNT_SUCCESS]: fetchListingCountSuccess,
    [Types.FETCH_LISTING_COUNT_FAILURE]: fetchListingCountFailure,
    [Types.UPDATE_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.UPDATE_SELECTED_SORT]: updateSelectedSort,
    [Types.CLEAR_ALL_FILTERS]: clearAllFilters,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.UPDATE_URL_OPTIONS]: updateUrlOptions,
    [Types.UPDATE_SELECTED_SUGGESTIONS]: updateSelectedSuggestions,
    [Types.UPDATE_APPLIED_SUGGESTIONS]: updateAppliedSuggestions,
    [Types.CLEAR_SUGGESTIONS]: clearSuggestions,
    [Types.UPDATE_ALL_SUB_FILTER_OPTIONS]: updateAllSubFilterOptions,
    [Types.UPDATE_SORT_MODAL_VISIBILITY]: updateSortModalVisibility,
    [Types.FETCH_CATEGORY_FILTER_INIT]: fetchCategoryFilterInit,
    [Types.FETCH_CATEGORY_FILTER_SUCCESS]: fetchCategoryFiltersSuccess,
    [Types.FETCH_CATEGORY_FILTER_FAILURE]: fetchCategoryFiltersFailure,
    [Types.UPDATE_ALL_SELECTED_FILTER_OPTIONS]: updateAllSelectedFilterOptions,
    [Types.UPDATE_SELECTED_CATEGORIES]: updateSelectedCategories,
    [Types.SET_MARKETING_CATEGORY]: setMarketingCategory,
    [Types.UPDATE_LISTING_TYPE]: updateListingType,
    [Types.SET_LISTING_FB_EVENTS]: setListingFBEvents,
    [Types.CLEAR_SELECTED_FILTER]: clearSelectedFilter,
    [Types.REPLACE_SELECTED_FILTERS]: replaceSelectedFilters

};

export default createReducer(INITIAL_STATE, HANDLERS);
