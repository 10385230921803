import React, {useState} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import CloseIcon from "./images/close.svg";
import IconSpinner from "../../../shared/icon-spinner";
import Modal from "../../../shared/modal";

const ReviewYourPurchaseCard = ({ category = [], carImage, loading, onRemove, reviewCardHeading, type = "PRODUCT", reviewAmount }) => {
    const [modal, setModal] = useState(false);

    return (
        <div styleName={"styles.reviewCardOuter"}>
            <div styleName={"styles.cardMediaContainer"} className="media">
                <img src={carImage} />
                <div className="media-body">
                    {type === "BUNDLE" ? <p onClick={() => setModal(true)} styleName={"styles.cardHeading styles.headingLink"}>{reviewCardHeading}</p> : <p styleName={"styles.cardHeading"}>{reviewCardHeading}</p>}
                    <p styleName={"styles.cardAmount"}>{reviewAmount}</p>
                </div>
            </div>
            {loading ? <div styleName={"styles.spinner"}><IconSpinner /></div> : <img onClick={onRemove} src={CloseIcon} />}

            <Modal isOpen={modal} close={() => setModal(false)}>
                <div styleName={"styles.modalWrapper"}>
                    <div styleName={"styles.modalHeader"}>
                        <span styleName={"styles.modalHeading"}>{reviewCardHeading}</span>
                        <img src={CloseIcon} onClick={() => setModal(false)} />
                    </div>

                    <ul>
                        {(category || []).map(({iconUrl, name}) => (
                            <li styleName={"styles.productsList"}>
                                <img src={iconUrl} />
                                {name}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

ReviewYourPurchaseCard.propTypes = {
    category: PropTypes.array,
    carImage: PropTypes.string,
    loading: PropTypes.bool,
    onRemove: PropTypes.func,
    reviewCardHeading: PropTypes.string,
    reviewAmount: PropTypes.string,
    type: PropTypes.string
};

export default ReviewYourPurchaseCard;
