import { API_SOURCE } from "../../constants/app-constants";

export const getWebViewSource = (source) => {
    switch (source) {
    case "android": {
        return API_SOURCE.ANDROID;
    }
    case "ios": {
        return API_SOURCE.IOS;
    }
    default:
        return API_SOURCE.M_SITE;
    }
};
