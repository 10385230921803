export const FB_EVENTS_CATEGORY = {
    HOME_PAGE_MAKE: "homepage_make",
    HOME_PAGE_MAKE_MODEL: "homepage_make_model",
    LISTING_PAGE_MAKE: "listing_make",
    LISTING_PAGE_MAKE_MODEL: "listing_make_model",
    SEARCH_SUGGESTIONS: "search_suggestions",
    ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
    HOME_PAGE_ALL_CARS: "HOME_PAGE_ALL_CARS",
    CDP_ADD_TO_WISHLIST: "CDP_ADD_TO_WISHLIST"

};

const FB_EVENTS = {
    [FB_EVENTS_CATEGORY.HOME_PAGE_MAKE]: {
        event: "search_event",
        eventCategory: "homepage_make",
        eventAction: "search_homepage_make"
    },
    [FB_EVENTS_CATEGORY.HOME_PAGE_MAKE_MODEL]: {
        event: "search_event",
        eventCategory: "homepage_make_model",
        eventAction: "search_homepage_make_model"
    },
    [FB_EVENTS_CATEGORY.LISTING_PAGE_MAKE]: {
        event: "search_event",
        eventCategory: "listing_make",
        eventAction: "search_listing_make"
    },
    [FB_EVENTS_CATEGORY.LISTING_PAGE_MAKE_MODEL]: {
        event: "search_event",
        eventCategory: "listing_make_model",
        eventAction: "search_listing_make_model"
    },
    [FB_EVENTS_CATEGORY.SEARCH_SUGGESTIONS]: {
        event: "search_event",
        eventCategory: "search_suggestions",
        eventAction: "search_suggestions"
    },
    [FB_EVENTS_CATEGORY.ADD_TO_WISHLIST]: {
        event: "custom_event",
        eventCategory: "listing_page",
        eventAction: "listing_save_car"
    },
    [FB_EVENTS_CATEGORY.HOME_PAGE_ALL_CARS]: {
        event: "custom_event",
        eventCategory: "listing_page",
        eventAction: "listing_page_land"
    },
    [FB_EVENTS_CATEGORY.CDP_ADD_TO_WISHLIST]: {
        event: "custom_event",
        eventCategory: "cdp_page",
        eventAction: "cdp_save_car"
    }
};

export default FB_EVENTS;
