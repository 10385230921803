import Types from "./types";

const saveTradeInRegoandVin = (data) => ({
    type: Types.SAVE_TRADE_IN_REGO_VIN,
    data
});

const saveTradeInCarMakeModelId = (data) => ({
    type: Types.SAVE_TRADE_IN_ID,
    data
});
export {
    saveTradeInRegoandVin,
    saveTradeInCarMakeModelId
};
