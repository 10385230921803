
export default {
    "landingBannerViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "landingBanner",
        "eventAction": "page_section_viewed"
    },
    "financePreapprovalViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "finance_pre_approval",
        "eventAction": "page_section_viewed"
    },
    "howitworksViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "howitworks",
        "eventAction": "page_section_viewed"
    },
    "perfectCarViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "find_your_perfect_car",
        "eventAction": "page_section_viewed"
    },
    "smartWayViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "smart_way_to_buy",
        "eventAction": "page_section_viewed"
    },
    "latestCarsViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "latest_car",
        "eventAction": "page_section_viewed"
    },
    "lifeStyleCarsViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "lifeStyle_category_car",
        "eventAction": "page_section_viewed"
    },
    "testimonialViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "testimonials",
        "eventAction": "page_section_viewed"
    },
    "subscribeViewd": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "subscribe",
        "eventAction": "page_section_viewed"
    },

    "imageGalleryViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "imageGallery",
        "eventAction": "page_section_viewed"
    },
    "testDriveViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "testDrive",
        "eventAction": "page_section_viewed"
    },
    "aboutThisCarViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "aboutThisCar",
        "eventAction": "page_section_viewed"
    },
    "qualityAssuranceViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "qualityAssurance",
        "eventAction": "page_section_viewed"
    },
    "priceCalculatorViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "priceCalculator",
        "eventAction": "page_section_viewed"
    },
    "carComparisonViwed": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "carComparison",
        "eventAction": "page_section_viewed"
    },
    "whyBuyWithCars24Viewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "whyBuyWithCars24",
        "eventAction": "page_section_viewed"
    },
    "moreCarsForYouViwed": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "moreCarsForYou",
        "eventAction": "page_section_viewed"
    },
    "stepsToBuyingViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "stepsToBuying",
        "eventAction": "page_section_viewed"
    },
    "kbbFanViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "kbbFan",
        "eventAction": "page_section_viewed"
    },
    "faqViewd": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "faq",
        "eventAction": "page_section_viewed"
    },
    "inspectionReport": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "300inspectionReport",
        "eventAction": "page_section_viewed"
    },
    "brakeSafetyReportViwed": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "page_section_viewed",
        "eventAction": "brake_safety_report"
    },
    "tradein": {
        "event": "custom_event",
        "eventCategory": "CARS24_HOMEPAGE",
        "eventLabel": "trade-in",
        "eventAction": "page_section_viewed"
    },
    "listing_tradein": {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page",
        "eventLabel": "trade-in",
        "eventAction": "page_section_viewed"
    },
    "quality_promise": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "quality_promise",
        "eventAction": "inspection_report_viewed"
    },
    "car_inspection_report": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventLabel": "car_inspection_report",
        "eventAction": "inspection_report_viewed"
    },
    "checkpoint_cleared": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "inspection_report_status"
    },
    "tradeInBannerViewed": {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "trade_in_banner",
        "eventLabel": "viewed"
    },
    "homePageSellBannerViewed": {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "viewed",
        "eventLabel": "sell_trade_banner"
    }
};
