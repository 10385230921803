import { createTypes } from "reduxsauce";

export default createTypes(
    `
UPDATE_CITY
UPDATE_CITY_WITH_CODE
`,
    {
        prefix: "locationpicker/"
    }
);
