import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "./component";
import { reloadCarList, resetCarList } from "../car-listing/actions";

const mapStateToProps = ({
    carListing: { totalCars },
    cities: { selectedCity: { code: selectedCityCode, name: selectedCityName } = {} },
    user: {
        isLoggedIn,
        seoMenu
    }
}) => ({
    totalCars,
    selectedCityCode,
    selectedCityName,
    isLoggedIn,
    seoMenu
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    resetCarListConnect: resetCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
