import React, { useEffect } from "react";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import closeIcon from "./images/close.svg";
import Warranty from "./images/warranty.svg";
import Servicing from "./images/service.svg";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";

const CustomiseYourCoverModal = ({ isOpen, onClose, vasCartItems, onItemRemove, loading }) => {
    useEffect(() => {
        if (isOpen) {
            trackDesktopCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.VIEW_CART_CLICKED);
        }
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen}>
            <div styleName={"styles.modalOuter"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.heading"}>Your cart</p>
                    <img src={closeIcon} onClick={onClose} />
                </div>
                <div styleName={loading ? "styles.loading" : ""}>
                    {vasCartItems?.map((item) => {
                        const isServicing = item.categoryKey === "CAR_SERVICING";
                        return (
                            <div styleName={"styles.boxWrapper"}>
                                <div className="media" styleName={"styles.mediaWrapper"}>
                                    <img src={isServicing ? Servicing : Warranty} />
                                    <div className="media-body">
                                        <p styleName={"styles.boxHeading"}>{isServicing ? "Car Servicing" : "Warranty"}</p>
                                        <p styleName={"styles.boxSubHeading"}>{makePriceLabelRound(item.discountedPrice)}</p>
                                    </div>
                                </div>
                                <img src={closeIcon} onClick={() => onItemRemove(item)} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

CustomiseYourCoverModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    vasCartItems: PropTypes.array,
    onItemRemove: PropTypes.func,
    loading: PropTypes.bool
};

export default CustomiseYourCoverModal;
