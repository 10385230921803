/* eslint-disable no-nested-ternary */
import { PAYMENT_GATEWAY, PAYMENT_TYPE } from "../../../constants/checkout-constants";
import { NUMBER } from "../../../constants/app-constants";
import Card from "./images/credit-card.svg";
import Bank from "./images/bank-transfer.svg";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";

// eslint-disable-next-line complexity
const getPaymentModeConfig = ({
    amountBeingPaid,
    isInHouse,
    config
}) => {
    const { debitSurcharge, creditSurcharge, financeDebitSurcharge, financeCreditSurcharge } = config || {};
    const debitCardDescription = () => {
        if (isInHouse) {
            if (financeDebitSurcharge) {
                return `Instant, ${makePriceLabelRound(amountBeingPaid * financeDebitSurcharge / NUMBER.HUNDRED)} surcharge`;
            }
            return `Instant & no surcharge`;
        }
        return `${makePriceLabelRound(amountBeingPaid * debitSurcharge / NUMBER.HUNDRED)} surcharge`;
    };

    const creditCardDescription = () => {
        if (isInHouse) {
            if (financeCreditSurcharge) {
                return `Instant, ${makePriceLabelRound(amountBeingPaid * financeCreditSurcharge / NUMBER.HUNDRED)} surcharge`;
            }
            return `Instant & no surcharge`;
        }
        return `${makePriceLabelRound(amountBeingPaid * creditSurcharge / NUMBER.HUNDRED)} surcharge`;
    };
    const bankTrasferConfig = {
        key: "bankTransfer",
        label: "Online Bank Transfer",
        imgSrc: Bank,
        cardColor: isInHouse ? "blue" : "green",
        description: isInHouse ? "No surcharge" : "Free and Instant",
        data: {mode: PAYMENT_TYPE.BANK_TRANSFER.value, cardType: " ", gateWay: PAYMENT_GATEWAY.BANK_TRANSFER, eventLabel: "option selected direct_bank_transfer"},
        displayRightLabel: isInHouse ? "No surcharge" : "Free and Instant"
    };

    const debitCardConfig = {
        key: "debitCard",
        label: "Debit Card",
        cardColor: isInHouse ? Number(financeDebitSurcharge) > 0 ? "red" : "blue" : "red",
        surcharge: isInHouse ? financeDebitSurcharge : debitSurcharge,
        description: debitCardDescription(),
        imgSrc: Card,
        data: {mode: PAYMENT_TYPE.CARD.value, cardType: PAYMENT_TYPE.DEBITCARD.value, gateWay: PAYMENT_GATEWAY.adyen, eventLabel: "option selected Debit Card"}
    };

    const creditCardConfig = {
        key: "creditCard",
        label: "Credit Card",
        cardColor: isInHouse ? Number(financeCreditSurcharge) > 0 ? "red" : "blue" : "red",
        surcharge: isInHouse ? financeCreditSurcharge : creditSurcharge,
        description: creditCardDescription(),
        imgSrc: Card,
        data: {mode: PAYMENT_TYPE.CARD.value, cardType: PAYMENT_TYPE.CREDITCARD.value, gateWay: PAYMENT_GATEWAY.adyen, eventLabel: "option selected Credit Card"}
    };

    const modeOfPaymentBankRecommended = [{
        ...bankTrasferConfig,
        recommended: true

    },
    debitCardConfig,
    creditCardConfig
    ];

    const modeOfPayment = [
        debitCardConfig,
        creditCardConfig,
        bankTrasferConfig
    ];

    if (amountBeingPaid >= NUMBER.THREE_THOUSAND) {
        return modeOfPaymentBankRecommended;
    } else {
        return modeOfPayment;
    }
};

export {
    getPaymentModeConfig
};
