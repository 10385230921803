import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getOrderDetail, applyPromoCode, removePromoCode, resetApplyPromoCode } from "../../au.desktop/checkout-info/actions";
import PromoCode from "./component";

const mapStateToProps = ({
    carDetails: { content: {
        appointmentId
    }},
    checkout: { promoCode, order }
}) => ({
    promoCode,
    order,
    appointmentId
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            applyPromoCodeConnect: applyPromoCode,
            resetApplyPromoCodeConnect: resetApplyPromoCode,
            removePromoCodeConnect: removePromoCode,
            getOrderDetailConnect: getOrderDetail
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoCode));
