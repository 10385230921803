import React from "react";
import PropTypes from "prop-types";
import snarkdown from "snarkdown";

import styles from "./styles.css";

const VasSliderBundleDetail = ({ detailedDescription, specifications = [] }) => {
    return (
        <div styleName={"styles.outer"}>
            {detailedDescription && <p styleName={"styles.detail"}>{detailedDescription}</p>}
            {(specifications || []).length > 0 &&
                <div styleName={"styles.stepsWrapper"}>
                    {specifications.map(({ iconUrl, info }) => (
                        <div key={iconUrl} styleName={"styles.steps"}>
                            <img src={iconUrl} />
                            <p styleName={"styles.stepdetail"} dangerouslySetInnerHTML={{
                                __html: snarkdown(info)
                            }} />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

VasSliderBundleDetail.propTypes = {
    detailedDescription: PropTypes.string,
    specifications: PropTypes.array
};

export default VasSliderBundleDetail;

