/* eslint-disable no-magic-numbers */
const getFormattedDeliveryInfo = (data = {}) => {
    const {shipping = [], availability = null, availabilityDate = "", deliveryDateRange = {}} = data.homeDelivery && data.homeDelivery[0] || data.selfPickUp && data.selfPickUp[0] || {};
    const {shippingPrice = 0} = shipping[0] || {};
    const {to = "", from = ""} = deliveryDateRange;
    const rangeDateForDisplay = to ? ` - ${to}` : "";
    const dateDisplayLabel = from + rangeDateForDisplay;
    return {shippingPrice, availability, availabilityDate, deliveryDateRange, rangeDateForDisplay, dateDisplayLabel };
};

// export const getDisplayDate = ({from = "", to = ""}) => `${from }-${ to}`;

const getOrdinalNum = (number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ["th", "st", "nd", "rd", ""][selector];
};

export const getDisplayDate = ({fromDate, toDate}) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    return `${getOrdinalNum(date1.getDate())} - ${getOrdinalNum(date2.getDate())} ${date2.toLocaleString("default", { month: "long" })}, ${ date2.getFullYear()}`;
};

export default getFormattedDeliveryInfo;
