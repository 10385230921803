/* eslint-disable max-statements */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AU_RECENTLY_VIEWD_KEY, NUMBER } from "../../../constants/app-constants";
import loadable from "@loadable/component";
import getCookie from "../../../utils/helpers/get-cookie";
import styles from "./styles.css";
import BuyUsedCarLanding from "../buy-used-car-landing";
const CategoryContentSection = loadable(() => import("../category-content-section"), { ssr: true });
const Banner = loadable(() => import("../landing-banner"), { ssr: true });
const CustomerTestimonials = loadable(() => import("../customer-testimonials/component"), { ssr: true });
import StayInTouch from "../../shared/stay-in-touch";
import HowItWorks from "../how-it-works-video/components";
import { trackCustomReMarketingEvents, trackDesktopCustomEventsAU } from "../../../tracking";
import { MARKETING_EVENTS_CONSTANTS } from "../../../tracking/marketing-page-events";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import GetPreApprovedCardRevamp from "../get-pre-approved-card-revamp";
import SellTradeInBannerRevamp from "../sell-trade-in-banner-revamp/component";
import SellOrTradeInBannerRevamp from "../sell-or-trade-in-banner-revamp";
import TrackImpression from "../../shared/track-impression";
import { isExperimentNull } from "../../../utils/helpers/get-ab-expirement-variant";
import { BOOL_STATE } from "../../../constants/checkout-constants";
const FinanceCarLoanEligibiltyHomeBanner  = loadable(() => import("../finance-car-loan-eligibilty-home-banner"));
const FilterByPriceSlider = loadable(() => import("../filter-by-price-slider"));
const FilterByBodytypeSlider = loadable(() => import("../filter-by-bodytype-slider"));
const RecentlyViewedRecommendedCars = loadable(() => import("../recently-viewed-recommend-cars-section"));
const PreApprovedHomeBannerRevamp = loadable(() => import("../pre-approved-home-banner-revamp"));

const Home = ({
    isUserZeroDpVariant,
    recentlyViewedArr,
    isSSR,
    setHomeSSRConnect,
    fetchTotalAvailableCarConnect,
    secureToken,
    loanType,
    stEligible = false,
    userGeoData,
    gaId
}) => {

    const showFinanceFirstExperimentVariant = true;

    const { isPurposeBusiness = BOOL_STATE.NO } = loanType || {};
    const isPersonal = isPurposeBusiness === BOOL_STATE.NO;
    const isStEligible = stEligible;

    useEffect(() => {
        trackCustomReMarketingEvents(MARKETING_EVENTS_CONSTANTS.HOME);
        if (typeof window !== "undefined" && isSSR) {
            // setting SSR false to render the component when homepage is rendered using client side routing
            setHomeSSRConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (!isSSR) {
            fetchTotalAvailableCarConnect(secureToken);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR, secureToken, setHomeSSRConnect]);
    useEffect(() => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.ZERO_DP_HOMEPAGE, {
            eventLabel: isUserZeroDpVariant ? "Variant B" : "Variant A"
        });
    }, [isUserZeroDpVariant]);

    useEffect(() => {
        const testDriveVariant =  getCookie(AU_DESKTOP_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE);
        let customStateVariant =  getCookie(AU_DESKTOP_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT);
        customStateVariant = isExperimentNull(customStateVariant) ? null : customStateVariant;
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE, {
            eventAction: AU_DESKTOP_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE,
            eventLabel: testDriveVariant,
            eventCategory: "Cars24_home_page"
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT, {
            eventAction: AU_DESKTOP_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT,
            eventLabel: `${customStateVariant}_${JSON.stringify(userGeoData)}`,
            eventCategory: "Cars24_home_page"
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let appointmentIds = "[]";
    if (!isSSR) {
        appointmentIds = getCookie(AU_RECENTLY_VIEWD_KEY);
    }
    // if SSR true it will check for recentlyViewedArr, if SSR false, it will check for appointmentIDs from cookies
    if ((appointmentIds && JSON.parse(appointmentIds).length > NUMBER.TWO) || (recentlyViewedArr && recentlyViewedArr.length > NUMBER.TWO)) {
        return (<LayoutWithRecentlyViewed
            isPersonal={isPersonal}
            showFinanceFirstExperimentVariant={showFinanceFirstExperimentVariant}
            isStEligible={isStEligible}
            gaId={gaId}
        />);
    } else {
        return (<LayoutWithoutRecentlyViewed
            isPersonal={isPersonal}
            showFinanceFirstExperimentVariant={showFinanceFirstExperimentVariant}
            isStEligible={isStEligible}
            gaId={gaId}
        />);
    }
};

const LayoutWithoutRecentlyViewed = ({
    showFinanceFirstExperimentVariant,
    isPersonal,
    isStEligible
}) => {

    return (
        <div>
            <Banner />
            {<React.Fragment>
                <TrackImpression event={{ name: AMPLITUDE_EVENTS_CATEGORY.CARS_BY_PRICE_SEEN }}>
                    <div styleName="styles.usedCarWrapper">
                        <FilterByPriceSlider />
                    </div>
                </TrackImpression>
                <TrackImpression event={{ name: "homePageSellBannerViewed" }}>
                    <div className="container" styleName={"styles.twoBannersWrapper"}>
                        {showFinanceFirstExperimentVariant ? <SellOrTradeInBannerRevamp /> : <SellTradeInBannerRevamp />}
                        {isStEligible && <PreApprovedHomeBannerRevamp />}
                        {!isStEligible && (showFinanceFirstExperimentVariant ? <FinanceCarLoanEligibiltyHomeBanner isPersonal={isPersonal} showFinanceFirstExperimentVariant={showFinanceFirstExperimentVariant} /> : <GetPreApprovedCardRevamp />)}
                    </div>
                </TrackImpression>
                <TrackImpression event={{ name: AMPLITUDE_EVENTS_CATEGORY.CARS_BY_BODYTYPE_SEEN }}>
                    <FilterByBodytypeSlider />
                </TrackImpression>
                <HowItWorks />
            </React.Fragment>}
            <BuyUsedCarLanding />
            <CategoryContentSection />
            <CustomerTestimonials />
            <StayInTouch />
        </div>
    );

};

const LayoutWithRecentlyViewed = ({
    showFinanceFirstExperimentVariant,
    isPersonal,
    isStEligible
}) => {
    return (
        <div>
            <Banner />
            {<React.Fragment>
                <TrackImpression event={{ name: AMPLITUDE_EVENTS_CATEGORY.DESKTOP_PERSONALISATION_SECTION_SEEN }}>
                    <div styleName={"styles.recommendedWrapper"}>
                        <RecentlyViewedRecommendedCars />
                    </div>
                </TrackImpression>
                <TrackImpression event={{ name: "homePageSellBannerViewed" }}>
                    <div className="container" styleName={"styles.twoBannersWrapper"}>
                        {showFinanceFirstExperimentVariant ? <SellOrTradeInBannerRevamp /> : <SellTradeInBannerRevamp />}
                        {isStEligible && <PreApprovedHomeBannerRevamp />}
                        {!isStEligible && (showFinanceFirstExperimentVariant ? <FinanceCarLoanEligibiltyHomeBanner isPersonal={isPersonal} showFinanceFirstExperimentVariant={showFinanceFirstExperimentVariant} /> : <GetPreApprovedCardRevamp />)}
                    </div>
                </TrackImpression>
                <TrackImpression event={{ name: AMPLITUDE_EVENTS_CATEGORY.CARS_BY_PRICE_SEEN }}>
                    <div>
                        <FilterByPriceSlider />
                    </div>
                </TrackImpression>
                <HowItWorks />
                <TrackImpression event={{ name: AMPLITUDE_EVENTS_CATEGORY.CARS_BY_BODYTYPE_SEEN }}>
                    <FilterByBodytypeSlider />
                </TrackImpression>
            </React.Fragment>}
            <BuyUsedCarLanding />
            <CategoryContentSection />
            <CustomerTestimonials />
            <StayInTouch />
        </div>
    );
};

Home.propTypes = {
    isUserZeroDpVariant: PropTypes.bool,
    fetchSearchResultConnect: PropTypes.func,
    recentlyViewedCookieData: PropTypes.array,
    recentlyViewedArr: PropTypes.array,
    isSSR: PropTypes.bool,
    setHomeSSRConnect: PropTypes.func,
    fetchTotalAvailableCarConnect: PropTypes.func,
    secureToken: PropTypes.string,
    loanType: PropTypes.object,
    preApproved: PropTypes.bool,
    stEligible: PropTypes.bool,
    userGeoData: PropTypes.object,
    gaId: PropTypes.string,
    showEmailSupportModal: PropTypes.bool
};

LayoutWithRecentlyViewed.propTypes = {
    showFinanceFirstExperimentVariant: PropTypes.object,
    isPersonal: PropTypes.bool,
    isStEligible: PropTypes.bool,
    gaId: PropTypes.string
};

LayoutWithoutRecentlyViewed.propTypes = {
    showFinanceFirstExperimentVariant: PropTypes.object,
    isPersonal: PropTypes.bool,
    isStEligible: PropTypes.bool,
    gaId: PropTypes.string
};

export default Home;
