import qs from "querystring";

export default () => {
    if (typeof window !== "undefined") {
        const params = qs.parse(window.location.search.substring(1));
        const isWebView = params.utm_source === "aus_app";
        return isWebView;
    }
    return false;
};

