import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cars24PlatinumCover from "./component";

const mapStateToProps = ({
    carDetails: {content, config},
    checkout: {extendedWarranty: { warrantyPlanInfo }}
}) => ({
    content, config, warrantyPlanInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cars24PlatinumCover);
