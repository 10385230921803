import React, { useState } from "react";
import styles from "./styles.css";
import PlatinumCover from "./images/platinum-cover.svg";
import Servicing from "./images/car-service.svg";
import Warranty from "./images/warranty.svg";

import Selected from "./images/selected.svg";
import dropDown from "./images/drop-down.svg";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import PlatinumCoverExpandedSection from "../platinum-cover-expanded-section";

// eslint-disable-next-line max-params
const getSubHeading = (type, productList, collapsedSelection, selectedProductIndex, minWeeklyPrice) => {
    if (collapsedSelection) {
        const selectedProduct = productList?.[selectedProductIndex];
        return <React.Fragment>{selectedProduct?.title} selected</React.Fragment>;
    }
    if (type === "warranty") {
        return <React.Fragment>Extend up to 3 years, starting <span>{makePriceLabelRound(minWeeklyPrice)}/week</span></React.Fragment>;
    }
    return <React.Fragment>Get up to 6 services starting <span>{makePriceLabelRound(minWeeklyPrice)}/week</span></React.Fragment>;
};

const getIcon = (type) => {
    if (type === "warranty") {
        return Warranty;
    }

    if (type === "servicing") {
        return Servicing;
    }
    return PlatinumCover;
};

const getHeading = (type) => {
    if (type === "warranty") {
        return "Warranty";
    }
    return "Car services";
};

const PlatinumCoverProductSection = ({ productList, type, isExpanded, expandedSection, minWeeklyPrice }) => {
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const collapsedSelection = selectedProductIndex !== null && !isExpanded;
    const subHeading = getSubHeading(type, productList, collapsedSelection, selectedProductIndex, minWeeklyPrice);

    const handleExpandedSection = () => {
        if (isExpanded) {
            expandedSection(null);
        } else {
            expandedSection(type);
        }
    };

    return (
        <div styleName={`styles.outer ${isExpanded ? "styles.warrantyActive" : ""}`}>
            <div styleName={"styles.topSection"}>
                <div className="media" styleName={"styles.mediaContainer"}>
                    {!collapsedSelection ? <img src={getIcon(type)} alt="CARS24 Platinum cover" /> : <img src={Selected} alt="Selected Checkbox" />}
                    <div className="media-body">
                        <p styleName={"styles.heading"}>{getHeading(type)}</p>
                        <p styleName={"styles.WarrantySubHeading"}>{subHeading}</p>
                    </div>
                </div>
                <img src={dropDown} alt="Accordion image" style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }} onClick={handleExpandedSection} />
            </div>
            <PlatinumCoverExpandedSection
                expandedSection={expandedSection}
                isExpanded={isExpanded}
                productList={productList}
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={setSelectedProductIndex}
                type={type}
            />
        </div>
    );
};

PlatinumCoverProductSection.propTypes = {
    type: PropTypes.string,
    productList: PropTypes.object,
    isExpanded: PropTypes.bool,
    expandedSection: PropTypes.func,
    minWeeklyPrice: PropTypes.number
};

export default PlatinumCoverProductSection;
