/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

import PlatinumCoverBundleSection from "../platinum-cover-bundle-section/component";
import PlatinumCoverProductSection from "../platinum-cover-product-section/component";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { NUMBER } from "../../../constants/app-constants";

const getProductBundles = (carCoverDetails) => {
    const platinumCover = carCoverDetails?.platinumCover || [];
    const warrantyCover =
        carCoverDetails?.productDetails?.filter((product) => product.categoryKey === "EXTENDED_WARRANTY_PLAN") || [];
    const servicingCover =
        carCoverDetails?.productDetails?.filter((product) => product.categoryKey === "CAR_SERVICING") || [];
    return { platinumCover, warrantyCover, servicingCover };
};

let previousExpandedSection = null;
const getOpenTrackData = (expandedSection) => {
    switch (expandedSection) {
    case "bundle":
        return PLATINUM_COVER_GA_EVENTS.BUNDLE_SECTION_OPENED;
    case "warranty":
        return PLATINUM_COVER_GA_EVENTS.WARRANTY_SECTION_OPENED;
    case "servicing":
        return PLATINUM_COVER_GA_EVENTS.SERVICING_SECTION_OPENED;
    default:
        return {};
    }
};

const getCloseTrackData = (expandedSection) => {
    switch (expandedSection) {
    case "bundle":
        return PLATINUM_COVER_GA_EVENTS.BUNDLE_SECTION_CLOSED;
    case "warranty":
        return PLATINUM_COVER_GA_EVENTS.WARRANTY_SECTION_CLOSED;
    case "servicing":
        return PLATINUM_COVER_GA_EVENTS.SERVICING_SECTION_CLOSED;
    default:
        return {};
    }
};

const scrollToElement = (ref, topOffset) => {
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - topOffset;
    window.scrollTo({ top: offsetPosition });
};

const PlatinumCoverPlans = ({ handleWhatsCoveredModal, carCoverDetails, navigateToNextScreen, carCoverTitle }) => {
    const { platinumCover, warrantyCover, servicingCover } = getProductBundles(carCoverDetails);
    const [expandedSection, setExpandedSection] = useState("bundle");
    const { leastServicingRepaymentPerWeek, leastExtendedWarrantyRepaymentPerWeek } = carCoverDetails || {};
    const bundleRef = React.useRef(null);
    const warrantyAndServicingRef = React.useRef(null);

    const isInitialRender = React.useRef(true);  // Flag to track the first render

    useEffect(() => {
        const trackData = expandedSection
            ? getOpenTrackData(expandedSection)
            : getCloseTrackData(previousExpandedSection);
        trackDesktopCustomEventsAU("", trackData);
        previousExpandedSection = expandedSection;

        if (isInitialRender.current) {
            isInitialRender.current = false;  // Skip the first render
            return;
        }

        if (expandedSection === "bundle") {
            scrollToElement(bundleRef, NUMBER.ONE_HUNDRED_SEVENTY_SEVEN);
        }

        if (expandedSection === "warranty" || expandedSection === "servicing") {
            scrollToElement(warrantyAndServicingRef, NUMBER.TWENTY);
        }
    }, [expandedSection]);

    return (
        <div styleName={"styles.wrapper"}>
            <div ref={bundleRef}>
                <PlatinumCoverBundleSection
                    navigateToNextScreen={navigateToNextScreen}
                    isExpanded={expandedSection === "bundle"}
                    setExpandedSection={setExpandedSection}
                    handleWhatsCoveredModal={handleWhatsCoveredModal}
                    platinumCover={platinumCover}
                    carDescription={carCoverTitle}
                />
            </div>
            <p ref={warrantyAndServicingRef} styleName={"styles.customiseHeading"}>Customise your cover</p>
            <p styleName={"styles.customiseSubHeading"}>
                Choose the terms that work the best for you.
            </p>
            <PlatinumCoverProductSection
                productList={warrantyCover}
                type="warranty"
                isExpanded={expandedSection === "warranty"}
                expandedSection={setExpandedSection}
                minWeeklyPrice={leastExtendedWarrantyRepaymentPerWeek}
            />
            <PlatinumCoverProductSection
                productList={servicingCover}
                type="servicing"
                isExpanded={expandedSection === "servicing"}
                expandedSection={setExpandedSection}
                minWeeklyPrice={leastServicingRepaymentPerWeek}
            />
        </div>
    );
};

PlatinumCoverPlans.propTypes = {
    handleWhatsCoveredModal: PropTypes.func,
    handleWhyUsModal: PropTypes.func,
    carCoverDetails: PropTypes.object,
    navigateToNextScreen: PropTypes.func,
    carCoverTitle: PropTypes.string
};

export default PlatinumCoverPlans;
