import { CURRENCY } from "../../constants/app-constants";

export const FILTER_ENTITIES = {
    BODY_TYPE: "bodyType",
    PRICE: "quotedPrice",
    FUEL_TYPE: "fuelType",
    OWNERS: "ownerNumber",
    ODOMETER_READING: "odometerReading",
    TRANSMISSION: "transmissionType",
    REGISTRATION_CITY: "registrationCity",
    YEAR: "year",
    MAKE: "make",
    MODEL: "model",
    COLOR: "colorCategory",
    PROMOTION: "promotion",
    SEATS: "seats",
    DISCOUNT: "discount",
    DELIVERY_TIME: "deliveryTime",
    DRIVETYPE: "driveType",
    FEATURES: "features",
    SAFETYANDQUALITY: "ancapRating",
    IMPERFECTIONS: "imperfectionCount",
    MAKECITYNAME: "makeCityName",
    ALLMAKES: "allMakes",
    POPULARMAKES: "popularMakes",
    MODELCITYNAME: "modelCityName",
    CITYNAME: "cityName",
    TESTDRIVE: "testDrive",
    DEPOSIT: "deposit",
    EMI_PER_WEEK: "emiPerWeek"
};

export const INLINE_FILTER_TITLES = {
    [FILTER_ENTITIES.PRICE]: "Used cars by budget",
    [FILTER_ENTITIES.TRANSMISSION]: "Used cars by transmission",
    [FILTER_ENTITIES.BODY_TYPE]: "Used cars by body type",
    [FILTER_ENTITIES.MAKE]: "Used cars by brand",
    [FILTER_ENTITIES.MODEL]: "Used cars by Model",
    [FILTER_ENTITIES.FUEL_TYPE]: "Used cars by fuel type"
};
export const ALL_FILTERS_REVAMP = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.TESTDRIVE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.FEATURES,
    FILTER_ENTITIES.DRIVETYPE,
    FILTER_ENTITIES.DELIVERY_TIME,
    FILTER_ENTITIES.SEATS,
    FILTER_ENTITIES.SAFETYANDQUALITY,
    FILTER_ENTITIES.PROMOTION,
    FILTER_ENTITIES.FINANCE_FILTER

];

export const PRICE_FILTER_TYPE = {
    FULL_AMOUNT: "FULL_AMOUNT",
    EMI: "EMI"
};

export const DOWN_ARROW_ROTATE_DEGREE = 90;
export const UP_ARROW_ROTATE_DEGREE = -90;
export const LEFT_ARROW_ROTATE_DEGREE = 180;

export const FILTER_TAB_TYPES = {
    FILTER: "FILTER",
    SORT: "SORT"
};

export const FILTER_PRIORITY = [
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.OWNERS
];

export const MOBILE_ON_SCREEN_FILTERS = [
    FILTER_ENTITIES.MAKE,
    FILTER_ENTITIES.PRICE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.YEAR
];
export const DESKTOP_ON_SCREEN_PERSONALISED_FILTERS = [
    { filterKey: FILTER_ENTITIES.PRICE, displayText: "Price / Finance" },
    { filterKey: FILTER_ENTITIES.MAKE, displayText: "Make / Model" },
    { filterKey: FILTER_ENTITIES.BODY_TYPE, displayText: "Body type" },
    { filterKey: FILTER_ENTITIES.ODOMETER_READING, displayText: "KMs" },
    { filterKey: FILTER_ENTITIES.YEAR, displayText: "Year" },
    { filterKey: FILTER_ENTITIES.FEATURES, displayText: "Features" }
];
export const DESKTOP_ON_SCREEN_FILTERS = [
    { filterKey: FILTER_ENTITIES.MAKE, displayText: "Make / Model" },
    { filterKey: FILTER_ENTITIES.PRICE, displayText: "Price / Finance" },
    { filterKey: FILTER_ENTITIES.BODY_TYPE, displayText: "Body type" },
    { filterKey: FILTER_ENTITIES.ODOMETER_READING, displayText: "KMs" },
    { filterKey: FILTER_ENTITIES.YEAR, displayText: "Year" },
    { filterKey: FILTER_ENTITIES.FEATURES, displayText: "Features" }
];
export const MOBILE_OFF_SCREEN_FILTERS = [
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.SEATS,
    FILTER_ENTITIES.SAFETYANDQUALITY,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.PROMOTION
    // FILTER_ENTITIES.COLOR
];

export const ALERT_FILTER = [
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.BODY_TYPE
];

export const KM_OPTIONS = [
    {label: "10,000 Kms", value: 10000},
    {label: "20,000 Kms", value: 20000},
    {label: "40,000 Kms", value: 40000},
    {label: "60,000 Kms", value: 60000},
    {label: "80,000 Kms", value: 80000},
    {label: "100,000 Kms", value: 100000},
    {label: "150,000 Kms", value: 150000},
    {label: "200,000 Kms", value: 200000}

];

export const ON_SCREENT_FILTERS = ["quotedPrice", "year", "odometerReading", "make", "fuelType", "promotion"];
export const OFF_SCREEN_FILTERS = ["transmissionType", "bodyType"];

export const FILTER_TYPES = {
    RF: "rf",
    SF: "sf"
};

export const SORT_ORDER = [];

export const APPLIED_FILTER_META = {
    [FILTER_ENTITIES.PRICE]: {
        prefix: CURRENCY.AU
    },
    [FILTER_ENTITIES.ODOMETER_READING]: {
        suffix: "KMs"
    }
};

export const DESKTOP_ON_SCREEN_FILTERS_ON_SALE = [
    { filterKey: FILTER_ENTITIES.MAKE, displayText: "Make / Model" },
    { filterKey: FILTER_ENTITIES.PRICE, displayText: "Price / Finance" },
    { filterKey: FILTER_ENTITIES.BODY_TYPE, displayText: "Body type" },
    { filterKey: FILTER_ENTITIES.ODOMETER_READING, displayText: "KMs" },
    { filterKey: FILTER_ENTITIES.YEAR, displayText: "Year" },
    { filterKey: FILTER_ENTITIES.FEATURES, displayText: "Features" },
    { filterKey: FILTER_ENTITIES.PROMOTION, displayText: "Promotion" }
];

export const SORT_MAPPING = {
    PRICE_ASC: "price-asc",
    PRICE_DESC: "price-desc",
    CUSTOM_BUDGET_SORT: "custom-budget-sort"
};

export const DEFAULT_PATHNAME = "/buy-used-cars-australia";

// export const PRIMARY_FILTER_PRIORITY = [
//     FILTER_ENTITIES.BUDGET,
//     FILTER_ENTITIES.CAR_NAME,
//     FILTER_ENTITIES.ASSURED,
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.FINANCE,
//     FILTER_ENTITIES.ODOMETER_READING,
//     FILTER_ENTITIES.YEAR,
//     FILTER_ENTITIES.FUEL_TYPE,
//     FILTER_ENTITIES.TRANSMISSION,
//     FILTER_ENTITIES.BODY_TYPE,
//     FILTER_ENTITIES.OWNERS,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];

// export const RESETTABLE_FILTER_ENTITIES = [
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];
