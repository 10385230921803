import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";

const LoaderScreen = ({
    subHeading = false,
    heading = "Loading...",
    subTitle = "",
    title = "Please wait..",
    showTopToast = false,
    toastMessage = "",
    icon
}) => {
    return (
        <Modal isOpen={true} overlayClass={"preApprovalLoader"}>
            {showTopToast && toastMessage && <div styleName={"styles.toast"}>{toastMessage}</div>}
            <div styleName={"styles.loaderWrapper"}>
                {icon && <img src={icon} alt={title} />}
                <div styleName={"styles.spinner styles.primary"} role="status">
                    <span styleName={"styles.loading"}>{heading}</span>
                    <span styleName={"styles.loading"}>{subTitle}</span>
                </div>
                <p styleName={"styles.wait"}>{title}</p>
                {subHeading && <p styleName={"styles.detail"}>{subHeading}</p>}
            </div>
        </Modal>
    );
};

LoaderScreen.propTypes = {
    subHeading: PropTypes.string,
    subTitle: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    showTopToast: PropTypes.bool,
    toastMessage: PropTypes.string,
    icon: PropTypes.string
};

export default LoaderScreen;
