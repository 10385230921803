import React, { useEffect } from "react";
import styles from "./styles.css";
import AlertCard from "../../shared/alert-card";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const ToastMessage = ({
    toast,
    match,
    autoHide = true,
    // eslint-disable-next-line no-magic-numbers
    autoHideTime = 3000,
    hideToastConnect
}) => {
    const { variant, text, toastStyle, position, toastHideTime = 0 } = toast || {};

    useEffect(() => {
        if (autoHide && text) {
            window.setTimeout(() => {
                hideToastConnect();
            }, toastHideTime || autoHideTime);
        }
    });
    const stylesList = [
        "styles.wrapper",
        match.url === "/buy-used-cars/" ? "styles.home" : "",
        position === "middle" ? "styles.middle" : "",
        toastStyle ? `styles.${toastStyle}` : "",
        match.url.includes("/checkout/loan-details") ? "styles.largeToastMsg" : "",
        match.url.includes("/checkout/loan-type") ? "styles.largeToastMsg" : "",
        match.url.includes("/checkout/personal-details") ? "styles.largeToastMsg" : "",
        match.url.includes("/financing/loan-details") ? "styles.largeToastMsg" : "",
        match.url.includes("/unsubscribe-email") ? "styles.home styles.unsubscribe" : ""
    ];
    return (
        text ?
            <div className={"col-12"} styleName={stylesList.filter(_ => !!_).join(" ")}>
                <AlertCard variant={variant} margin={true}>
                    <p className={"text-center"}>{text}</p>
                </AlertCard>
            </div>
            : null
    );
};

ToastMessage.propTypes = {
    toast: PropTypes.object,
    autoHide: PropTypes.bool,
    autoHideTime: PropTypes.number,
    hideToastConnect: PropTypes.func,
    match: PropTypes.object
};

export default withRouter(ToastMessage);
