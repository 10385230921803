export default (key, value, expiryDays = 1) => {
    const expiryDate = new Date();
    const SECONDS = 60;
    const HOURS = 24;
    const MILLISECONDS = 1000;
    expiryDate.setTime(
        expiryDate.getTime() + (expiryDays * HOURS * SECONDS * SECONDS * MILLISECONDS)
    );
    document.cookie = `${key}=${value};path=/;expires=${expiryDate.toUTCString()}`;
};

const saveSessionCookie = (key, value) => {
    document.cookie = `${key}=${value};path=/;`;
};

export { saveSessionCookie };
