/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import Types from "./types";
import { FINANCE_TYPE, VEHICLE_STATE } from "../../../../constants/checkout-constants";
//import { DRIVA_MIN_AMOUNT } from "../../../../config/driva-config";
import { setItem } from "../../../../utils/helpers/storage-crud";
import { DEFAULT_FINANCE_TYPE } from "../../../../config/finance-config";
import { getFinanceData, getPreApprovalConfigDataDesktopV2 } from "../../../../utils/helpers/finance-loan-application-util";
import { PRE_APPROVAL_QUESTIONS_INITIAL_STATE } from "../../../../constants/finance-constants/pre-approval-revamp/questions";

export const FINANCE_ADDRESS_INITIAL_STATE = {
    durationMonths: null,
    durationYears: null,
    full: "",
    postCode: "",
    state: "",
    street: "",
    streetNumber: "",
    suburb: "",
    unit: 0
};

const FINANCE_EXTRA = {
    vehicleMake: "",
    vehicleModel: "",
    vehicleState: VEHICLE_STATE.USED,
    vehicleYear: 0
};
const FINANCE_PERSONAL_DETAILS_INITIAL_STATE = {
    mobile: "",     //mobile
    email: "",
    dependants: 0,
    firstName: "",  //firstName
    lastName: "",   //lastName
    middleName: "", //middleName
    dateOfBirth: "", //dateOfBirth
    residency: "", //residency
    visaNumber: "",
    livingSituation: null, //livingSituation
    currentAddressDurationBelow3Years: false,
    address: { ...FINANCE_ADDRESS_INITIAL_STATE },
    previousAddress: {}, // previousAddress
    employment: [], //employment,
    userIncomeDetails: {},
    relationshipStatus: ""
};

export const INITIAL_STATE = {
    /* Driva finance data */
    financeData: {
        submitted: false,
        selectedQuote: null,
        appointmentId: null,
        financeType: FINANCE_TYPE.DRIVA_FINANCE, // one of drivaFinance or approvedLoan
        user: {
            ...FINANCE_PERSONAL_DETAILS_INITIAL_STATE
        },
        loan: {
            amount: 0, // loan = price - deposit
            //deposit: DRIVA_MIN_AMOUNT,
            deposit: "",
            term: "", // loan years
            balloon: 0 // in percents
        },
        loanType: {
            isPurposeBusiness: "NA",
            isAbnRegistered: "NA",
            isGstRegistered: "NA",
            businessYears: null
        },
        // creditScore is used for dev purpose only, to replicate loan errors, and should not be a part prod payload
        // creditScore: {
        //     equifaxScore: 750,
        //     vedaScore: 700
        // },
        extra: {
            ...FINANCE_EXTRA
        },
        preApproveLoanDetails: {
            lenderName: "",
            make: "",
            model: "",
            user: {
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phone: ""
            }
        }
    },
    maxLoanAmount: null,
    bestInterestRate: null,
    comparisonRate: null,
    readOnlyFinanceData: {},
    financeUniqueId: "",
    financeQuotes: null, // list of loan offers
    previousStep: "", // route
    currentStep: "",  // route
    nextStep: "",     // route
    maxStep: "",      // route
    prevTabIndex: 0,

    isFinanceDataLoading: true,
    preApproved: false,
    isGetFinanceDataLoading: true,
    financeDataError: null, // also used to show generic error screen with no lenders
    isFinanceDataPosted: false,
    isGetFinanceDataError: null,
    financeErrorResponse: null, // in case of 200 from driva api
    isCreateFinanceDataError: null,
    isUpdateFinanceDataError: null,
    orderAvailable: null,
    quotesAvailable: null,
    isDepositRangeLoading: false,
    maxDeposit: null,
    depositRangeError: false,
    preApporvedOrigin: "",
    showPreApprovalCoachmark: false,
    isBatchPriceLoading: false,
    isBatchPriceError: false,
    inHouseFinanceEligibility: DEFAULT_FINANCE_TYPE.inHouseEligible,
    inHouseFinanceEligibilityFinance: DEFAULT_FINANCE_TYPE.inHouseEligible,
    showLeavePreApprovalPopUp: true,
    isIntroCoachMarkVisible: false,
    preApprovalRequestCallbackLoading: false,
    preApprovalRequestCallbackData: null,
    preApprovalRequestCallbackError: false,
    inHouseConfigData: null,
    preApprovalEditRedirectionRoute: "",
    preApprovalEditMode: false,
    firstName: null,
    lastName: null,
    preApprovalQuestions: {
        loading: false,
        error: null,
        questionsMetaData: PRE_APPROVAL_QUESTIONS_INITIAL_STATE
    },
    loanCalculatorInfo: {
        loading: false,
        error: false,
        interestRate: null
    },
    minMaxInterestRateInfo: {
        loading: false,
        error: false,
        minInterestRate: null,
        maxInterestRate: null
    },
    bestInterestRateCalculatorDetails: null
};

export const setPreApprovalDetails = (state = INITIAL_STATE, { newData }) => {
    return {
        ...state,
        financeData: { ...state.financeData, loan: { ...state.financeData.loan, ...newData } }
    };
};

export const setPreApprovalType = (state = INITIAL_STATE, { newData }) => {
    return {
        ...state,
        financeData: { ...state.financeData, loanType: { ...state.financeData.loanType, ...newData } }
    };
};

export const setFinancePersonalDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            user: {
                ...state.financeData.user,
                ...data
            }
        }
    };
};

export const setFinancingScreen = (state = INITIAL_STATE, { activeScreen }) => {
    return {
        ...state,
        financeData: { ...state.financeData, activeScreen}
    };
};

export const getPreApprovalLoanDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: true,
        isGetFinanceDataLoading: true,
        financeDataError: null,
        isGetFinanceDataError: null,
        financeErrorResponse: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const getPreApprovalLoanDetailsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        financeUniqueId: data.uniqueId,
        financeData: data.preApprovalRequest,
        maxLoanAmount: data.maxLoanAmount,
        creditScore: data.creditScore || null,
        bestInterestRate: data.bestInterestRate,
        comparisonRate: data.comparisonRate,
        readOnlyFinanceData: data.preApprovalRequest,
        preApproved: data.preApproved,
        externalApplicationStatus: data.externalApplicationStatus,
        financeQuotes: data.quotes,
        financeErrorResponse: data.errorResponse,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const getInhousePreApprovalLoanDetailsSuccess = (state = INITIAL_STATE, { data = {}, isUserZeroDpVariant }) => {
    const financeData = getFinanceData({ response: data, storeData: state.financeData, isUserZeroDpVariant });
    return {
        ...state,
        financeUniqueId: data.uuid,
        financeData,
        maxLoanAmount: data.maxLoanAmount,
        bestInterestRate: data.bestInterestRate,
        comparisonRate: data.comparisonRate,
        readOnlyFinanceData: data.loanOfferRequest,
        preApproved: data.quotesAvailable,
        creditScore: data.creditScore || null,
        externalApplicationStatus: data.externalApplicationStatus,
        maxPermissibleRepaymentPerWeek: data.maxPermissibleRepaymentPerWeek,
        financeQuotes: data.quotes,
        financeErrorResponse: data.errorResponse,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null,
        isIntroCoachMarkVisible: !(data.loanOfferRequest && data.loanOfferRequest.loan && data.loanOfferRequest.loan.term),
        bestInterestRateCalculatorDetails: data.bestInterestRateCalculatorDetails ? data.bestInterestRateCalculatorDetails  : state.bestInterestRateCalculatorDetails,
        absolutePermissibleLoanAmount: data.absolutePermissibleLoanAmount,
        errorCode: data.errorCode
        //inHouseConfigData: getPreApprovalConfigDataDesktopV2(data.loanOfferRequest)
    };
};

const getPreApprovalFormProgress = (state, data) => {
    return {
        ...state,
        inHouseConfigData: getPreApprovalConfigDataDesktopV2(data)
    };
};

export const getPreApprovalLoanDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeErrorResponse: null,
        financeDataError: error,
        isGetFinanceDataError: true,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null,
        inHouseConfigData: {}
    };
};

const getPreApprovalQuestionsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        preApprovalQuestions: {
            ...state.preApprovalQuestions,
            loading: true
        }
    };
};

const getPreApprovalQuestionsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        preApprovalQuestions: {
            ...state.preApprovalQuestions,
            loading: false,
            questionsMetaData: data
        }
    };
};

const getPreApprovalQuestionsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        preApprovalQuestions: {
            ...state.preApprovalQuestions,
            loading: false,
            questionsMetaData: PRE_APPROVAL_QUESTIONS_INITIAL_STATE,
            error: error.message || "Something went wrong"
        }
    };
};

export const createPreApprovalLoanRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: true,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null,
        financeErrorResponse: null
    };
};

export const createPreApprovalLoanSuccess = (state = INITIAL_STATE, { data }) => {
    setItem(data.uniqueId, "financeUniqueId");
    return {
        ...state,
        financeUniqueId: data.uniqueId,
        isFinanceDataPosted: true,
        isFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null

    };
};

export const createPreApprovalLoanFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        financeDataError: error,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: true,
        isUpdateFinanceDataError: null,
        financeErrorResponse: null

    };
};

export const updatePreApprovalRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isFinanceDataLoading: isLoading,
        financeErrorResponse: null,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const updatePreApprovalSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: data.preApprovalRequest,
        maxLoanAmount: data.maxLoanAmount,
        bestInterestRate: data.bestInterestRate,
        comparisonRate: data.comparisonRate,
        readOnlyFinanceData: data.preApprovalRequest,
        preApproved: data.preApproved,
        financeErrorResponse: data.errorResponse,
        isFinanceDataPosted: true,
        financeQuotes: data.quotes,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        isFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null

    };
};

export const updateInhousePreApprovalSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...data.loanOfferRequest,
            selectedQuote: data.selectedQuote,
            user: {
                ...data.loanOfferRequest.user,
                currentAddressDurationBelow3Years: !!((((data.loanOfferRequest || {}).user) || {}).previousAddress || {}).full
            }
        },
        maxLoanAmount: data.maxLoanAmount,
        bestInterestRate: data.bestInterestRate,
        comparisonRate: data.comparisonRate,
        readOnlyFinanceData: data.loanOfferRequest,
        preApproved: data.quotesAvailable,
        financeErrorResponse: data.errorResponse,
        isFinanceDataPosted: true,
        financeQuotes: data.quotes,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        isFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
        //inHouseConfigData: getPreApprovalConfigDataDesktop(data.loanOfferRequest)
    };
};

export const updatePreApprovalFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        financeErrorResponse: null,
        financeQuotes: null,
        financeDataError: error,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: true,
        inHouseConfigData: {}

    };
};

export const updateFinanceSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: data,
        isOrderDetailLoading: false,
        orderDetailError: null
    };
};

export const setMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {...state, maxStep};
};

export const getDepositRangeRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isDepositRangeLoading: isLoading,
        depositRangeError: false
    };
};

export const getDepositRangeValueSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        maxDeposit: data.deposit.max,
        depositRangeError: false
    };
};

export const getDepositRangeValueFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        depositRangeError: true
    };
};

export const setPreApprovedOrigin = (state = INITIAL_STATE, {preApporvedOrigin}) => {
    return {
        ...state,
        preApporvedOrigin
    };
};

export const setEligibilityForInhouse = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        inHouseFinanceEligibility: data.eligible,
        inHouseFinanceEligibilityFinance: data.eligible,
        preApproved: data.preApproved,
        firstName: data.firstName,
        lastName: data.lastName,
        maxPermissibleRepaymentPerWeek: (data || {}).maxPermissibleRepaymentPerWeek,
        stEligible: ((data || {}).stEligible) || false

    };
};

export const setPreApprovalCoachMarkDisplay = (state = INITIAL_STATE, {showPreApprovalCoachmark}) => {
    return {
        ...state,
        showPreApprovalCoachmark
    };
};

const setPreApprovalEditMode = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        preApprovalEditMode: data
    };
};

const setPreApprovalEditRedirectionRoute = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        preApprovalEditRedirectionRoute: data
    };
};

export const updateBatchPriceRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isBatchPriceLoading: isLoading,
        isBatchPriceError: false
    };
};

export const updateBatchPriceSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isBatchPriceLoading: false,
        isBatchPriceError: false
    };
};

export const updateBatchPriceFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isBatchPriceLoading: false,
        isBatchPriceError: true
    };
};

export const resetFinanceData = (state = INITIAL_STATE) => {
    return {
        ...state,
        financeData: state.readOnlyFinanceData
    };
};

export const resetPreAprovalData = (state) => {
    return {
        ...state,
        financeUniqueId: "",
        financeData: INITIAL_STATE.financeData,
        inHouseConfigData: {}
    };
};

const setLeavePreApprovalPopUpStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        showLeavePreApprovalPopUp: data
    };
};
const setIsIntroCoachMarkVisible = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isIntroCoachMarkVisible: data
    };
};
const postPreApprovalRequestCallbackRequest = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        preApprovalRequestCallbackLoading: data,
        preApprovalRequestCallbackData: null,
        preApprovalRequestCallbackError: false
    };
};
const postPreApprovalRequestCallbackSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        preApprovalRequestCallbackData: data,
        preApprovalRequestCallbackError: false,
        preApprovalRequestCallbackLoading: false
    };
};
const postPreApprovalRequestCallbackFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        preApprovalRequestCallbackData: null,
        preApprovalRequestCallbackError: true,
        preApprovalRequestCallbackLoading: false
    };
};

const getInterestRateForPriceCalculatorRequest = (state = INITIAL_STATE, { loading = true }) => {
    return {
        ...state,
        loanCalculatorInfo: {
            ...state.loanCalculatorInfo,
            loading
        }
    };
};

const getInterestRateForPriceCalculatorSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        loanCalculatorInfo: {
            ...state.loanCalculatorInfo,
            loading: false,
            ...data
        }
    };
};

const getInterestRateForPriceCalculatorFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        loanCalculatorInfo: {
            ...state.loanCalculatorInfo,
            loading: false,
            error
        }
    };
};

const getMinMaxInterestRatesRequest = (state = INITIAL_STATE, { loading = true }) => {
    return {
        ...state,
        minMaxInterestRateInfo: {
            ...state.minMaxInterestRateInfo,
            loading
        }
    };
};

const getMinMaxInterestRatesSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        minMaxInterestRateInfo: {
            ...state.minMaxInterestRateInfo,
            loading: false,
            ...data
        }
    };
};

const getMinMaxInterestRatesFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        minMaxInterestRateInfo: {
            ...state.minMaxInterestRateInfo,
            loading: false,
            error
        }
    };
};

export const HANDLERS = {
    [Types.SET_PRE_APPROVAL_PERSONAL_DETAILS]: setFinancePersonalDetails,
    [Types.SET_PRE_APPROVAL_FINANCE_SCREEN]: setFinancingScreen,
    [Types.SET_PRE_APPROVAL_DETAILS]: setPreApprovalDetails,
    [Types.SET_PRE_APPROVAl_LOAN_TYPE]: setPreApprovalType,
    [Types.SET_PRE_APPROVAL_MAX_STEP]: setMaxStep,

    [Types.GET_PRE_APPROVAL_LOAN_REQUEST]: getPreApprovalLoanDetailsRequest,
    [Types.GET_PRE_APPROVAL_LOAN_SUCCESS]: getPreApprovalLoanDetailsSuccess,
    [Types.GET_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS]: getInhousePreApprovalLoanDetailsSuccess,
    [Types.GET_PRE_APPROVAL_LOAN_FAILURE]: getPreApprovalLoanDetailsFailure,

    [Types.GET_PRE_APPROVAL_QUESTIONS_REQUEST]: getPreApprovalQuestionsRequest,
    [Types.GET_PRE_APPROVAL_QUESTIONS_SUCCESS]: getPreApprovalQuestionsSuccess,
    [Types.GET_PRE_APPROVAL_QUESTIONS_FAILURE]: getPreApprovalQuestionsFailure,

    [Types.CREATE_PRE_APPROVAL_LOAN_REQUEST]: createPreApprovalLoanRequest,
    [Types.CREATE_PRE_APPROVAL_LOAN_SUCCESS]: createPreApprovalLoanSuccess,
    [Types.CREATE_PRE_APPROVAL_LOAN_FAILURE]: createPreApprovalLoanFailure,

    [Types.UPDATE_PRE_APPROVAL_LOAN_REQUEST]: updatePreApprovalRequest,
    [Types.UPDATE_PRE_APPROVAL_LOAN_SUCCESS]: updatePreApprovalSuccess,
    [Types.UPDATE_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS]: updateInhousePreApprovalSuccess,
    [Types.UPDATE_PRE_APPROVAL_LOAN_FAILURE]: updatePreApprovalFailure,

    [Types.UPDATE_PRE_APPROVAL_FINANCE_DETAILS_SUCCESS]: updateFinanceSuccess,

    [Types.GET_DEPOSIT_RANGE_REQUEST]: getDepositRangeRequest,
    [Types.GET_DEPOSIT_RANGE_SUCCESS]: getDepositRangeValueSuccess,
    [Types.GET_DEPOSIT_RANGE_FAILURE]: getDepositRangeValueFailure,
    [Types.PRE_APPROVED_ORIGIN]: setPreApprovedOrigin,

    [Types.SET_PRE_APPROVAL_COACHMARK_DISPLAY]: setPreApprovalCoachMarkDisplay,
    [Types.SET_PRE_APPROVAL_EDIT_REDIRECTION_ROUTE]: setPreApprovalEditRedirectionRoute,

    [Types.UPDATE_BATCH_PRICE_REQUEST]: updateBatchPriceRequest,
    [Types.UPDATE_BATCH_PRICE_SUCCESS]: updateBatchPriceSuccess,
    [Types.UPDATE_BATCH_PRICE_FAILURE]: updateBatchPriceFailure,

    [Types.RESET_FINANCE_DATA]: resetFinanceData,
    [Types.RESET_PRE_APPROVAL_DATA]: resetPreAprovalData,

    [Types.SET_PRE_APPROVAL_EDIT_MODE]: setPreApprovalEditMode,

    [Types.SET_ELIGIBITLITY_FOR_INHOUSE]: setEligibilityForInhouse,
    [Types.SET_LEAVE_PRE_APPROVAL_POP_UP_STATUS]: setLeavePreApprovalPopUpStatus,
    [Types.SET_INTRO_COACH_MARK_VISIBLE]: setIsIntroCoachMarkVisible,

    [Types.PRE_APPROVAL_REQUEST_CALLBACK_INTI]: postPreApprovalRequestCallbackRequest,
    [Types.PRE_APPROVAL_REQUEST_CALLBACK_SUCCESS]: postPreApprovalRequestCallbackSuccess,
    [Types.PRE_APPROVAL_REQUEST_CALLBACK_FAILURE]: postPreApprovalRequestCallbackFailure,

    [Types.GET_PRE_APPROVAL_FORM_PROGRESS]: getPreApprovalFormProgress,

    [Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_REQUEST]: getInterestRateForPriceCalculatorRequest,
    [Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_SUCCESS]: getInterestRateForPriceCalculatorSuccess,
    [Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_FAILURE]: getInterestRateForPriceCalculatorFailure,

    [Types.GET_MIN_MAX_INTEREST_RATES_REQUEST]: getMinMaxInterestRatesRequest,
    [Types.GET_MIN_MAX_INTEREST_RATES_SUCCESS]: getMinMaxInterestRatesSuccess,
    [Types.GET_MIN_MAX_INTEREST_RATES_FAILURE]: getMinMaxInterestRatesFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
