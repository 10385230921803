/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { Component } from "react";
import { captureMessage, captureException, Severity } from "@sentry/react";
import PropTypes from "prop-types";
class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        captureException(error);
        console.log(info);
        captureMessage("CLIENT ERROR", Severity.Error);
        this.setState({ hasError: true });
    }

    render() {
        return this.state.hasError ? <div /> : this.props.children;
    }
}

ErrorHandler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default ErrorHandler;
