import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckoutHeader from "./component";

const mapStateToProps = ({ carDetails: { content } }) => ({ content });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader);
