import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_SITEMAP_DATA_SUCCESS
    FETCH_SITEMAP_DATA_FAILURE
`,
    {
        prefix: "sitemapinfo/"
    }
);
