import { NUMBER } from "../../constants/app-constants";

const getLoanDepositAmount = (driveAwayPrice, financeData) => {
    const { loan = {} } = financeData || {};
    let { deposit, amount }  = loan || {};
    if (deposit > (driveAwayPrice - NUMBER.FIVE_THOUSAND)) {
        deposit = driveAwayPrice - NUMBER.FIVE_THOUSAND;
    }
    amount = driveAwayPrice - deposit;
    return {
        ...loan,
        deposit,
        amount
    };
};

export {
    getLoanDepositAmount
};
