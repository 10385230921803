
export const FINANCE_FIRST_CHECKOUT_AMPLITUDE_EVENTS = {
    FINANCE_FIRST_PRE_APPROVED_SCREENS_LANDED: "FF_pre_approved_checkout_screens"
};

export const FINANCE_FIRST_CHECKOUT_GA_EVENTS = {
    FINANCE_FIRST_PRE_APPROVED_SCREENS_LANDED: "FF_pre_approved_checkout_screens",
    DETAILS: {
        event: "custom_event",
        eventCategory: "FF_pre_approved_checkout_screens",
        eventAction: "FF_pre_approved_checkout_screens"
    }
};

export const GA_EVENTS = {
    BOOKING_INITATED_USP: "booking_initiated_usp",
    BOOKING_INTIATED_DETAIL: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "booking_initiated_usp"
    }
};

export const AMPLITUDE_EVENT_NAMES = {
    PURCHASE_STARTED_USP: "purchase started usp"
};
