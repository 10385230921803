import React from "react";
import styles from "./styles.css";
import ChatIcon from "./images/chat.png";
import PropTypes from "prop-types";
import { isSellSupportAvailable } from "../../../utils/helpers/support";

const ChatWidgetRevamp = ({ onClick = () => {} }) => {
    return (
        <div styleName={"styles.chatRevampContainer"} onClick={onClick}>
            <div styleName={"styles.chatContainer"}>
                <img src={ChatIcon} />
                { <div styleName={isSellSupportAvailable ? "styles.online" : "styles.offline"} /> }
                <p styleName={"styles.needHelp"}>Contact Us!</p>
            </div>
        </div>
    );
};

export default ChatWidgetRevamp;

ChatWidgetRevamp.propTypes = {
    onClick: PropTypes.func.isRequired
};
