import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../shared/toast-message/actions";
import { postSignContract, updateSignContractStatus } from "../checkout-info/actions";
import ContractSaleNote from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        signContractData,
        isPostSignContractFetched,
        order: {
            orderId
        }
    }
}) => ({
    content,
    signContractData,
    isPostSignContractFetched,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    postSignContractConnect: postSignContract,
    updateSignContractStatusConnect: updateSignContractStatus,
    showToastConnect: showToast
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractSaleNote);
