import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CLOSE from "./images/close.svg";
import OPEN from "./images/plus.svg";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const HowWeCompare = ({warrantyPlanInfo, isVariant, screeName}) => {
    const [open, setopen] = useState(false);

    const {compareWarranty} = warrantyPlanInfo || {};

    const tableHeader = compareWarranty && Object.keys(compareWarranty);
    const headings = compareWarranty && Object.keys(compareWarranty.meta);

    const getEvents = () => {
        let eventName = "";
        switch (screeName) {
        case "PAYMENT_OPTIONS":
            eventName = "EW_Compare_pop_up";
            break;
        default:
            eventName = "CARS24_COVER_EW_COMPARISON";
        }
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS[eventName]);
    };

    const handleCompare = () => {
        setopen(!open);
        getEvents();
    };

    return (
        <div className="howWhatMargin" styleName={isVariant ? "styles.wrapper" : "styles.outer" }>
            <h2 onClick={handleCompare}>How we compare <img src={!open ?  OPEN : CLOSE}/></h2>
            {open && <div>
                <div styleName={"styles.table-content"}>
                    <div styleName={"styles.table-heading"}>
                        <p>hide</p>
                        {tableHeader.map((header, index) => {
                            if (header !== "meta") {
                                return <p key={index}>{compareWarranty.meta[header]}</p>;
                            }
                            return null;
                        })}
                    </div>
                    {headings.map((heading) => {
                        if (heading === "platinum" || heading === "average") {
                            return null;
                        } else {
                            return (
                                <div styleName={"styles.table-data"}>
                                    <p>{compareWarranty.meta[heading]}</p>
                                    <p>{compareWarranty.platinum[heading].value}</p>
                                    <p>{compareWarranty.average[heading].value}</p>
                                </div>
                            );
                        }

                    })}
                </div>
                <p styleName={"styles.text"}>Based on typical aftermarket extended warranty products available on the Australian market.</p>
            </div>}
        </div>
    );
};

HowWeCompare.propTypes = {
    warrantyPlanInfo: PropTypes.object,
    isVariant: PropTypes.bool,
    screeName: PropTypes.string
};

export default HowWeCompare;
