import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import NeedHelpWidget from "./component";
import { getInhousePreApprovalLoanDetails } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";

const mapStateToProps = ({
    preApprovalLoan: {
        financeUniqueId
    }
}) => ({
    financeUniqueId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getInhousePreApprovalLoanDetailsConnect: getInhousePreApprovalLoanDetails
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NeedHelpWidget));
