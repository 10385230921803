/* eslint-disable no-magic-numbers */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";

const FileUpload = ({
    id,
    fileSize = 5,
    capture,
    children,
    fileType = "image/*",
    customStyles = {},
    onClick = () => {},
    onUpload = () => {},
    onFileSelectCallback = () => {}
}) => {
    const handleUpload = (event) => {
        const file = event.target.files[0];
        const {  size } = file || {};
        const fileSizeinMB = size / NUMBER.TEN_LAKH;
        const hasError = fileSize && (fileSizeinMB > fileSize);
        const error = hasError ? `Size should not be greater than ${fileSize}MB` : "";
        if (!hasError) {
            const formDataValue = new window.FormData();
            formDataValue.append("file",  file);
            onUpload(formDataValue, file, id);
        }
        onFileSelectCallback({
            file,
            hasError,
            error,
            event
        });
    };

    const handleClick = () => {
        onClick();
    };

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.opengalleryModal"} style={customStyles} onClick={handleClick}>
                {capture ?
                    <input type="file" accept={fileType} onChange={handleUpload} capture={capture} /> :
                    <input type="file" accept={fileType} onChange={handleUpload} /> }

            </div>
            {children && children}
        </div>

    );
};

FileUpload.propTypes = {
    text: PropTypes.string,
    customStyles: PropTypes.object,
    onClick: PropTypes.func,
    capture: PropTypes.string,
    children: PropTypes.string,
    onUpload: PropTypes.func,
    fileSize: PropTypes.number,
    fileType: PropTypes.string,
    id: PropTypes.string,
    onFileSelectCallback: PropTypes.func
};

export default FileUpload;
