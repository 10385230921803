import { API_SOURCE } from "../../../constants/app-constants";
import { DRIVA_POLLING_STATUS } from "../../../constants/checkout-constants";
// import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { B2cFinanceService, CheckoutDrivaService, ConfigService } from "../../../service";
import { getLoanOfferDetailsSuccess } from "../checkout-info/actions";
import Types from "./types";

const getDrivaTimeoutConfigRequest = (loading) => ({
    type: Types.GET_DRIVA_TIMEOUT_CONFIG_REQUEST,
    loading
});

const getDrivaTimeoutConfigSuccess = (data) => ({
    type: Types.GET_DRIVA_TIMEOUT_CONFIG_SUCCESS,
    data
});

const getDrivaTimeoutConfigFailure = (error) => ({
    type: Types.GET_DRIVA_TIMEOUT_CONFIG_FAILURE,
    error
});

const getInHouseFinanceTimeoutConfigRequest = (loading) => ({
    type: Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_REQUEST,
    loading
});

const getInHouseFinanceTimeoutConfigSuccess = (data) => ({
    type: Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_SUCCESS,
    data
});

const getInHouseFinanceTimeoutConfigFailure = (error) => ({
    type: Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_FAILURE,
    error
});

const getDrivaTimeoutConfig = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(getDrivaTimeoutConfigRequest(true));
        ConfigService.fetchDrivaConfigDetails({type: "TIME_OUT_CONFIG", key: "DRIVA_POLLING"}).then(response => {
            dispatch(getDrivaTimeoutConfigSuccess(response.data && response.data.data && response.data.data[0]));
            resolve(response.data && response.data.data && response.data.data[0]);
        }).catch(error => {
            dispatch(getDrivaTimeoutConfigFailure(error));
            reject(error);
        });
    });
};

const getInHouseFinanceTimeoutConfig = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(getInHouseFinanceTimeoutConfigRequest(true));
        ConfigService.fetchDrivaConfigDetails({type: "TIME_OUT_CONFIG", key: "INHOUSE_POLLING"}).then(response => {
            dispatch(getInHouseFinanceTimeoutConfigSuccess(response.data && response.data.data && response.data.data[0]));
            resolve(response.data && response.data.data && response.data.data[0]);
        }).catch(error => {
            dispatch(getInHouseFinanceTimeoutConfigFailure(error));
            reject(error);
        });
    });
};

const drivaPollApiSuccess = (data) => ({
    type: Types.DRIVA_API_POLL_SUCCESS,
    data
});

const drivaPollApiFailure = (error) => ({
    type: Types.DRIVA_API_POLL_FAILURE,
    error
});

const inHouseFinancePollApiSuccess = (data) => ({
    type: Types.IN_HOUSE_FINANCE_API_POLL_SUCCESS,
    data
});

const inHouseFinancePollApiFailure = (error) => ({
    type: Types.IN_HOUSE_FINANCE_API_POLL_FAILURE,
    error
});

const drivaPollApi = (params = {}) =>  (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutDrivaService.drivaPollingApi(params, secureToken).then(response => {
            dispatch(drivaPollApiSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(drivaPollApiFailure(error));
            reject(error);
        });
    });
};

const inHouseFinancePollApi = (params = {}) => (dispatch, getState) => {
    const {
        user: { secureToken }
        // abExperiment: {
        //     showLoanOfferScreen
        // }
    } = getState();
    // const experiment = (showLoanOfferScreen || {}).data === EXPERIMENT_TYPE.VARIANT_B ? "WHITE_LABEL" : "LOAN_COMPARISON";
    // const apiParams = {
    //     experiment
    // };
    return new Promise((resolve, reject) => {
        B2cFinanceService.inHouseFinancePollingApi(params.orderId, secureToken).then(response => {
            dispatch(inHouseFinancePollApiSuccess(response.data));
            const { pollStatus } = response.data || {};
            if (pollStatus === DRIVA_POLLING_STATUS.COMPLETED) {
                dispatch(getLoanOfferDetailsSuccess(response.data, true));
            }
            resolve(response.data);
        }).catch(error => {
            dispatch(inHouseFinancePollApiFailure(error));
            reject(error);
        });
    });
};

const inHouseFinanceFirstFinancePollApi = (params = {}) => (dispatch, getState) => {
    const {
        user: { secureToken },
        preApprovalLoan: {
            financeUniqueId
        }
    } = getState();
    const token = params.token || secureToken;
    const financeUuid = params.uuid || financeUniqueId;
    return new Promise((resolve, reject) => {
        B2cFinanceService.inHouseFinanceFirstFinancePollApi(financeUuid, token).then(response => {
            dispatch(inHouseFinancePollApiSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(inHouseFinancePollApiFailure(error));
            reject(error);
        });
    });
};

const requestCallbackSuccess = (data) => ({
    type: Types.DRIVA_API_CALLBACK_SUCCESS,
    data
});

const requestCallbackFailure = (error) => ({
    type: Types.DRIVA_API_CALLBACK_FAILURE,
    error
});

const requestCallback = (params = {}) => (dispatch, getState) => {
    const { user: { secureToken, email }} = getState();
    const payload = {...params, userId: email};
    return new Promise((resolve, reject) => {
        CheckoutDrivaService.drivaCallbackApi(payload, secureToken).then(response => {
            dispatch(requestCallbackSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(requestCallbackFailure(error));
            reject(error);
        });
    });
};

const getCheckoutScreenConfigSucess = (data) => ({
    type: Types.CHECKOUT_SCREEN_CONFIG_SUCCESS,
    data
});

const getCheckoutScreenConfigFailure = (error) => ({
    type: Types.CHECKOUT_SCREEN_CONFIG_FAILURE,
    error
});

const getCheckoutScreenConfigRequest = (loading) => ({
    type: Types.CHECKOUT_SCREEN_CONFIG_REQUEST,
    loading
});

const getCheckoutScreenConfig = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(getCheckoutScreenConfigRequest(true));
        ConfigService.fetchConfigDetails({type: "CHECKOUT_SCREEN_CONFIG", source: API_SOURCE.WEB_APP}).then(response => {
            dispatch(getCheckoutScreenConfigSucess(response.data && response.data.data && response.data.data[0]));
            resolve(response.data && response.data.data && response.data.data[0]);
        }).catch(error => {
            dispatch(getCheckoutScreenConfigFailure(error));
            reject(error);
        });
    });
};

export {
    getDrivaTimeoutConfig,
    drivaPollApi,
    requestCallback,
    getInHouseFinanceTimeoutConfig,
    inHouseFinancePollApi,
    inHouseFinanceFirstFinancePollApi,
    getCheckoutScreenConfig
};
