/* eslint-disable no-magic-numbers */

import { getArrayRange } from "../utils/helpers/get-array-range";

export const ORDER_STATUS = {
    CREATED: "CREATED",
    EXPIRED: "EXPIRED",
    CANCELED: "CANCEL",
    BOOKED: "BOOKED",
    FAILED: "FAILED",
    RESERVED: "RESERVED",
    TEST_DRIVE_RESERVED: "TEST_DRIVE_RESERVED",
    TEST_DRIVE_CANCELLED: "CANCELLED",
    TEST_DRIVE_COMPLETED: "COMPLETED"
};

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    CANCEL: "CANCEL",
    RETRY: "RETRY",
    CREATED: "CREATED",
    FAILED: "FAILED",
    INITIATED: "INITIATED"
};

export const DELIVERY_TYPES = {
    PickUp: "PICK_UP",
    HomeDelivery: "HOME_DELIVERY"
};

export const PAYMENT_SPLIT = {
    SPLIT: "split",
    FULL: "full"
};

export const PAYMENT_TYPE = {
    CARD: {label: "Card", value: "card", prefix: "Paid via"},
    CREDITCARD: {label: "CREDIT CARD", value: "CREDIT", prefix: "Paid via"},
    DEBITCARD: {label: "DEBIT CARD", value: "DEBIT", prefix: "Paid via"},
    BANK_TRANSFER: {label: "Bank Transfer", value: "BANK_TRANSFER", prefix: "Paid via"},
    INTERNET_BANKING: {label: "Internet Banking", value: "internet_banking", prefix: "Paid via"},
    DRIVA_ZERO_DOWNPAYMENT: {label: "", value: "DRIVA_ZERO_DOWNPAYMENT", prefix: "Paid"},
    GLOBAL_CARS_AUS_CREDIT_ZERO_DOWNPAYMENT: {label: "", value: "GLOBAL_CARS_AUS_CREDIT_ZERO_DOWNPAYMENT", prefix: "Paid"},
    TRADEIN_VALUE_MORE_THAN_VEHICLE_PRICE: {label: "", value: "TRADEIN_VALUE_MORE_THAN_VEHICLE_PRICE", prefix: "Paid"},
    GOOGLE_PAY: {label: "Google Pay", value: "google_pay", prefix: "Paid"},
    APPLE_PAY: {label: "Apple Pay", value: "apple_pay", prefix: "Paid"},
    MONOOVA: {label: "MONOOVA", value: "MONOOVA"},
    FINANCE_PANEL: {label: "Bank Transfer", value: "BANK_TRANSFER", prefix: "Paid via"}

};

export const PAYMENT_GATEWAY = {
    adeyn: "ADYEN",
    stripe: "STRIPE",
    adyen: "ADYEN",
    poli: "POLI",
    BANK_TRANSFER: "PERSONAL_INTERNET_BANKING",
    MONOOVA: "MONOOVA",
    FINANCE_PANEL: "FINANCE_PANEL"
};

export const LOAN_YEARS_OPTIONS = [
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "7", value: 7 }
];

export const TRADE_IN_OPTIONS = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" }

];

export const LOAN_YEARS_OPTIONS_V2 = [
    { label: "3 years", value: 3 },
    { label: "4 years", value: 4 },
    { label: "5 years", value: 5 },
    { label: "7 years", value: 7 }
];

export const BUSINESS_TYPE_VALUE = {
    SOLE_TRADER: "Sole Trader",
    PARTNERSHIP: "Partnership",
    COMPANY: "Company",
    TRUST: "Trust"
};

export const BUSINESS_TYPE = [
    {
        label: "Sole trader",
        identifier: "SOLE_TRADER",
        value: BUSINESS_TYPE_VALUE.SOLE_TRADER
    },
    {
        label: "Partnership",
        identifier: "PARTNERSHIP",
        value: BUSINESS_TYPE_VALUE.PARTNERSHIP
    },
    {
        label: "Company",
        identifier: "COMPANY",
        value: BUSINESS_TYPE_VALUE.COMPANY
    },
    {
        label: "Trust",
        identifier: "TRUST",
        value: BUSINESS_TYPE_VALUE.TRUST
    }
];

export const PURPOSE_TYPE_VALUE = {
    PERSONAL: "Personal",
    BUSINESS: "Business"
};

export const PURPOSE_DETAILS_TYPE_VALUE = {
    ACN: "ACN",
    ABN: "ABN"
};

export const PURPOSE_TYPE = [
    {
        label: "Personal",
        value: PURPOSE_TYPE_VALUE.PERSONAL
    },
    {
        label: "Business",
        value: PURPOSE_TYPE_VALUE.BUSINESS
    }
];
export const PURPOSE_DETAILS_TYPE = [
    {
        label: "ACN",
        value: PURPOSE_DETAILS_TYPE_VALUE.ACN
    },
    {
        label: "ABN",
        value: PURPOSE_DETAILS_TYPE_VALUE.ABN
    }
];

export const INCOME_LIST = [
    {
        label: "weekly",
        value: "weekly"
    },
    {
        label: "fortnightly",
        value: "fortnightly"
    },
    {
        label: "monthly",
        value: "monthly"
    },
    {
        label: "yearly",
        value: "yearly"
    }
];

export const INCOME_LIST_WITHOUT_YEARLY = [
    {
        label: "weekly",
        value: "weekly"
    },
    {
        label: "fortnightly",
        value: "fortnightly"
    },
    {
        label: "monthly",
        value: "monthly"
    }
];

export const RELATIONSHIP_STATUS = {
    SINGLE: "single",
    MARRIED: "married",
    DEFACTO: "defacto",
    OTHER: "Other"
};

export const RELATIONSHIP_STATUS_OPTIONS = [
    {
        value: "single",
        label: "Single",
        identifier: "SINGLE"
    },
    {
        value: "married",
        label: "Married",
        identifier: "MARRIED"
    }, {
        value: "defacto",
        label: "De-facto",
        identifier: "DE_FACTO"
    }, {
        value: "other",
        label: "Others",
        identifier: "OTHERS"
    }
];

export const BOOL_STATE = {
    YES: "YES",
    NO: "NO",
    NA: "NA"
};

export const BOOL_OPTIONS = [
    { label: "No", value: BOOL_STATE.NO },
    { label: "Yes", value: BOOL_STATE.YES }
];

export const BOOLEAN_OPTIONS_FORM = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

export const BOOLEAN_OPTIONS_OBJECT_RESET = [
    { label: "Yes", value: {} },
    { label: "No", value: null }
];

export const CARD_SURCHARGE = 0.014;

export const EMI_DEFAULT_STEP = 1;
export const DOWNPAYMENT_DEFAULT_STEP = 1;

export const TIME_UNIT = 60;
export const START_TIMER_MIN_VAL = 0;
export const SEC_START_VALUE = 59;
export const INITIAL_TIMER_VALUE = 1;
export const MILLI_IN_MUNUTE = 60000;
export const RESERVED_HOURS = 48;

export const AUSTRALIAN_CITIZEN = "citizen";
export const NEW_ZEALAND_CITIZEN = "nzCitizen";
export const PERMANENT_RESIDENT = "pr";
export const TEMPORARY_VISA = "visa";

export const RESIDENCY_STATUS = [
    {label: "Australian Citizen", value: AUSTRALIAN_CITIZEN, gtm: "australian_citizen"},
    {label: "Permanent Resident", value: PERMANENT_RESIDENT, gtm: "permanent_resident"},
    {label: "Temporary Visa", value: TEMPORARY_VISA, gtm: "temporary_visa"}
];

export const RESIDENCY_STATUS_INHOUSE = [
    {label: "Australian Citizen", value: AUSTRALIAN_CITIZEN, gtm: "australian_citizen"},
    {label: "Permanent Resident", value: PERMANENT_RESIDENT, gtm: "permanent_resident"},
    {label: "New Zealand Citizen", value: NEW_ZEALAND_CITIZEN, gtm: "new_zealand_citizen"},
    {label: "Temporary Visa", value: TEMPORARY_VISA, gtm: "temporary_visa"}
];

export const RENTING = "renting";
export const OWNER_WITH_MORTGAGE = "ownerWithMortgage";
export const OWNER_WITHOUT_MORTGAGE = "ownerWithoutMortgage";
export const LIVING_WITH_PARENTS = "livingWithParents";
export const BOARD = "board";

export const LIVING_SITUATION = [
    {identifier: "OWNER_WITH_MORTAGE", label: "Owner without Mortgage", value: OWNER_WITHOUT_MORTGAGE, gtm: "owner_without_mortgage"},
    {identifier: "OWNER_WITHOUT_MORTAGE", label: "Owner with Mortgage", value: OWNER_WITH_MORTGAGE, gtm: "owner_with_mortgage"},
    {identifier: "RENTING", label: "Renting", value: RENTING, gtm: "renting"},
    {identifier: "LIVIIG_WITH_PARENTS", label: "Living with parents", value: LIVING_WITH_PARENTS, gtm: "living_with_parents"},
    {identifier: "BOARDING", label: "Boarding", value: BOARD, gtm: "boarding"}
];

export const EMPLOYEMENT_TYPES_VALUES = {
    FULL_TIME: "fullTime",
    PART_TIME: "partTime",
    SELF_EMPLOYED: "selfEmployed",
    CASUAL: "casual",
    CONTRACTOR: "contractor",
    UNEMPLOYED: "unemployed",
    PENSION: "pension"
};

export const FULL_TIME = "fullTime";
export const PART_TIME = "partTime";
export const SELF_EMPLOYED = "selfEmployed";
export const CASUAL = "casual";
export const CONTRACTOR = "contractor";
export const UNEMPLOYED = "unemployed";
export const PENSION = "pension";

export const EMPLOYEMENT_TYPES = [
    {label: "Full Time", value: FULL_TIME, gtm: "full_time", "identifier": "FULL_TIME" },
    {label: "Part Time", value: PART_TIME, gtm: "part_time", "identifier": "PART_TIME" },
    {label: "Self Employed", value: SELF_EMPLOYED, gtm: "self_employed", "identifier": "SELF_EMPLOYED" },
    {label: "Casual", value: CASUAL, gtm: "casual", "identifier": "CASUAL" },
    {label: "Contractor", value: CONTRACTOR, gtm: "contractor", "identifier": "CONTRACTOR" },
    {label: "Unemployed", value: UNEMPLOYED, gtm: "unemployed", "identifier": "UNEMPLOYED" },
    { label: "Pension", value: PENSION, gtm: "pension", "identifier": "PENSION" }
];

export const FINANCE_TYPE = {
    DRIVA_FINANCE: "DRIVA_FINANCE",
    PRE_APPROVED: "PRE_APPROVED",
    CARS24: "CARS24",
    BYOF: "BYOF"
};

export const VEHICLE_STATE = {
    USED: "used"
};

export const LOAN_ERROR = {
    DEFAULT: "default",
    CREDIT_SCORE: 1002,
    RESIDENCY: 1004,
    UNEMPLOYED: 1005,
    CREDIT_FILE: 1006,
    SERVICIBILITY: 1021,
    SERVICEABILITY_V2: 1039,
    SERVICEABILITY_V3: 1040,
    SERVICEABILITY_V4: 1041,
    EXPIRED: 105
};

export const SIGN_CONTRACT_STATUS = {
    UNSIGNED: "UNSIGNED",
    RESUME: "RESUME",
    SIGNED: "SIGNED"
};

export const VERIFICATION_STATUS = {
    CREATED: "CREATED",
    PENDING: "PENDING",
    VERIFIED: "VERIFIED",
    FAILED: "FAILED"
};

export const DRIVA_APPLICATION_STATUS = {
    RECEIVED: "Received",
    QUOTED: "Quoted",
    STARTED: "Started",
    AWAITING_DOCUMENTS: "Awaiting documents",
    UNDER_REVIEW: "Under review",
    SUBMITTED_TO_LENDER: "Submitted to lender",
    CONDITIONALLY_APPROVED: "Conditionally approved",
    APPROVED: "Approved",
    SETTLED: "Settled",
    WITHDRAWN: "Withdrawn",
    DECLINED: "Declined"
};

export const CHECKOUT_STEPS = {
    "BASIC": "basic",
    "DELIVERY": "delivery",
    "TRADEIN": "tradein",
    "PAYMENT": "payment",
    "CHECKOUT": "checkout",
    "CARCOVER": "carCover",
    "ADDONS": "addOns"
};

export const CHECKOUT_STEPS_STATUS = {
    COMPLETE: "complete",
    PENDING: "pending",
    UNAVAILABLE: "unavailable",
    SKIPPED: "skipped",
    EXPIRED: "expired"
};

export const DRIVA_POLLING_SCREENS = {
    CHECKOUT_EMPLOYMENT: "CHECKOUT_EMPLOYMENT",
    CHECKOUT_PAYMENT: "CHECKOUT_PAYMENT",
    PRE_APPROVAL_EDIT: "PRE_APPROVAL_EDIT",
    PRE_APPROVAL_EMPLOYMENT: "PRE_APPROVAL_EMPLOYMENT",
    CHECKOUT_CUSTOMISE_PAGE: "CHECKOUT_CUSTOMISE_PAGE",
    DRIVA_CUSTOMIZE_HEADER_REVAMP: "DRIVA_CUSTOMIZE_HEADER_REVAMP",
    SERVICEABILITY_ERROR_MODAL: "SERVICEABILITY_ERROR_MODAL",
    UPDATE_LOAN_TERMS_MODAL: "UPDATE_LOAN_TERMS_MODAL",
    TEST_DRIVE_FINANCE: "TEST_DRIVE_FINANCE"
};

export const DRIVA_POLLING_SCREENS_STEPS_KEYS = {
    POLLING_STARTED: "pollingStarted",
    POLLING_IN_PROGRESS: "pollingInProgress",
    POLLING_RETRY: "pollingRetry",
    REQUEST_CALLBACK: "requestCallback"
};

export const DRIVA_POLLING_STATUS = {
    COMPLETED: "COMPLETED",
    STARTED: "STARTED",
    PROCESSING_ERROR: "PROCESSING_ERROR",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    ERROR: "ERROR"
};

export const DRIVA_POLLING_CHECKOUT_SCREENS = [
    DRIVA_POLLING_SCREENS.CHECKOUT_CUSTOMISE_PAGE,
    DRIVA_POLLING_SCREENS.CHECKOUT_EMPLOYMENT,
    DRIVA_POLLING_SCREENS.CHECKOUT_PAYMENT
];

export const LOAN_QUOTE_REDIRECT_SCREENS = [
    DRIVA_POLLING_SCREENS.TEST_DRIVE_FINANCE
];

export const DRIVA_POST_OFFER_SCREENS = {
    POST_PREAPPROVED_INFO: "POST_PREAPPROVED_INFO",
    OFFER_LIST_SCREEN: "OFFER_LIST_SCREEN",
    SELECTED_OFFER_SCREEN: "SELECTED_OFFER_SCREEN",
    EDIT_SCREEN: "EDIT_SCREEN"
};

export const IN_HOUSE_SCREENS = {
    RESULT_SCREEN: "RESULT_SCREEN"
};

export const FINANCE_CYCLE = {
    WEEKLY: "Weekly",
    FORTNIGHTLY: "Fortnightly",
    MONTHLY: "Monthly"
};

export const DRIVA_FINANCE_CYCLE_OPTIONS = [
    {
        label: FINANCE_CYCLE.WEEKLY,
        value: FINANCE_CYCLE.WEEKLY
    }, {
        label: FINANCE_CYCLE.FORTNIGHTLY,
        value: FINANCE_CYCLE.FORTNIGHTLY
    }, {
        label: FINANCE_CYCLE.MONTHLY,
        value: FINANCE_CYCLE.MONTHLY
    }
];

export const PAYMENT_METHOD_TYPE = {
    MANUAL: "MANUAL"
};

export const PAYMENT_MODE = {
    OFFLINE: "OFFLINE"
};

export const CAR_YEAR = getArrayRange();

export const DEFAULT_CAR_PRICE = 30000;
export const DEFAULT_CAR_YEAR = 2020;

export const LOGIN_TYPE = {
    PRE_APPROVAL: "PRE_APPROVAL",
    DEFAULT: "DEFAULT",
    TEST_DRIVE: "TEST_DRIVE",
    C2B_FLOW: "C2B_FLOW",
    TRADE_IN_TOP_FUNNEL: "TRADE_IN_TOP_FUNNEL",
    WISHLIST: "WISHLIST",
    CLP_LOAN_CALCULATOR: "CLP_LOAN_CALCULATOR"
};

export const TEST_DRIVE_STEPS = {
    "BASIC": "basic",
    "DELIVERY": "delivery"
};

export const REDIRECT_TO_BOOKING = "/my-bookings";

export const TEST_DRIVE_BOOKED = "TEST_DRIVE_BOOKED";
export const TEST_DRIVE_RESERVED = "TEST_DRIVE_RESERVED";
export const TEST_DRIVE_BOOKING_ROUTE = "test-drive/summary";
export const CAR_DETAILS = "cardetails";
export const MULTI_WISHLIST = "MULTI_WISHLIST";
export const BOOKING_INITIATED = "BOOKING_INITIATED";
export const TEST_DRIVE = "TEST_DRIVE";
export const TEST_DRIVE_MANUAL = "CAR-SALE-MANUAL";
export const WISHLIST = "WISHLIST";
export const BOOKING_CONFIRMED = "BOOKING_CONFIRMED";
export const TRADEIN_OFFER_EXPIRED = "TRADEIN_OFFER_EXPIRED";
export const INSPECTION_PENDING = "INSPECTION_PENDING";
export const VALUATION_PENDING = "VALUATION_PENDING";
//todo:dhruva check checkout steps api.
export const CHECKOUT_STEPS_PATH = {
    "basic": "basicDetails",
    "delivery": "deliveryDetails",
    "tradein": "tradeInIntro",
    "payment": "paymentOptions",
    "checkout": "summary",
    "carCover": "carCover",
    "addOns": "addOns",
    "payment_finance": "paymentOptions"
};

export const GENDER_OPTIONS_LABEL = {
    MALE: "Male",
    FEMALE: "Female",
    TRANSGENDER: "Transgender",
    OTHERS: "Others"
};

export const GENDER_OPTIONS = [
    {label: GENDER_OPTIONS_LABEL.MALE, value: GENDER_OPTIONS_LABEL.MALE },
    {label: GENDER_OPTIONS_LABEL.FEMALE, value: GENDER_OPTIONS_LABEL.FEMALE },
    {label: GENDER_OPTIONS_LABEL.TRANSGENDER, value: GENDER_OPTIONS_LABEL.TRANSGENDER },
    {label: GENDER_OPTIONS_LABEL.OTHERS, value: GENDER_OPTIONS_LABEL.OTHERS }
];

export const OCCUPATION_TYPE = {
    ADMINISTRATION: "Administration",
    OFFICE_WORKER: "Office worker",
    PROFESSIONAL: "Professional",
    SALES: "Sales",
    TRADE: "Trade",
    UNSKILLED: "Unskilled",
    HOME_DUTIES: "Home Duties",
    OTHER: "Other"
};

export const OCCUPATION_TYPE_OPTIONS = [
    {label: OCCUPATION_TYPE.ADMINISTRATION, value: OCCUPATION_TYPE.ADMINISTRATION },
    {label: OCCUPATION_TYPE.OFFICE_WORKER, value: OCCUPATION_TYPE.OFFICE_WORKER },
    {label: OCCUPATION_TYPE.PROFESSIONAL, value: OCCUPATION_TYPE.PROFESSIONAL },
    {label: OCCUPATION_TYPE.SALES, value: OCCUPATION_TYPE.SALES },
    {label: OCCUPATION_TYPE.TRADE, value: OCCUPATION_TYPE.TRADE },
    {label: OCCUPATION_TYPE.UNSKILLED, value: OCCUPATION_TYPE.UNSKILLED },
    {label: OCCUPATION_TYPE.HOME_DUTIES, value: OCCUPATION_TYPE.HOME_DUTIES },
    {label: OCCUPATION_TYPE.OTHER, value: OCCUPATION_TYPE.OTHER }
];

export const INDUSTRY_TYPE = {
    ENTERTAINMENT: "Arts & Entertainment",
    BUILDING: "Building / Construction / Manufacturing",
    CHILDCARE: "Childcare",
    CHARITY: "Charity / Non-profit / Religious Org",
    EDUCATION: "Education / University",
    EVENTS_PLANNING: "Events planning / Catering",
    FARMING: "Farming / Agriculture / Fishing / Forestry",
    FINANCE: "Finance / Insurance Services",
    GOVERNMENT: "Government",
    HEALTH_SERVICES: "Health Services / Hospitals / Doctors / Nurses / Physio / Chiro",
    HOSPITALITY: "Hospitality / Bars / Pubs / Clubs / Hotels",
    INFORMATION_TECHNOLOGY: "Information Technology",
    MINING: "Mining / Oil / Gas / Energy",
    PROFESSIONAL_SERVICES: "Professional Services",
    REAL_ESTATE: "Real estate",
    RETAIL: "Retail (Clothing, Footwear, Homeware, Accessories)",
    SERVICES: "Services / Cleaners / Barbers / Mechanics",
    SPORTS: "Sports and recreation",
    SUPERMARKETS: "Supermarkets / Fast food / Take-away / Petrol Stations",
    TOURISM: "Tourism / Travel agents / Airlines / Hotels / Motels",
    TRANSPORT: "Transport / Logistics",
    VET: "Vet",
    HOME_DUTIES: "Home duties",
    OTHER: "Other"
};

export const INDUSTRY_TYPE_OPTIONS = [
    {label: INDUSTRY_TYPE.ENTERTAINMENT, value: INDUSTRY_TYPE.ENTERTAINMENT },
    {label: INDUSTRY_TYPE.BUILDING, value: INDUSTRY_TYPE.BUILDING },
    {label: INDUSTRY_TYPE.CHILDCARE, value: INDUSTRY_TYPE.CHILDCARE },
    {label: INDUSTRY_TYPE.CHARITY, value: INDUSTRY_TYPE.CHARITY },
    {label: INDUSTRY_TYPE.EDUCATION, value: INDUSTRY_TYPE.EDUCATION },
    {label: INDUSTRY_TYPE.EVENTS_PLANNING, value: INDUSTRY_TYPE.EVENTS_PLANNING },
    {label: INDUSTRY_TYPE.FARMING, value: INDUSTRY_TYPE.FARMING },
    {label: INDUSTRY_TYPE.FINANCE, value: INDUSTRY_TYPE.FINANCE },
    {label: INDUSTRY_TYPE.GOVERNMENT, value: INDUSTRY_TYPE.GOVERNMENT },
    {label: INDUSTRY_TYPE.HEALTH_SERVICES, value: INDUSTRY_TYPE.HEALTH_SERVICES },
    {label: INDUSTRY_TYPE.HOSPITALITY, value: INDUSTRY_TYPE.HOSPITALITY },
    {label: INDUSTRY_TYPE.INFORMATION_TECHNOLOGY, value: INDUSTRY_TYPE.INFORMATION_TECHNOLOGY },
    {label: INDUSTRY_TYPE.MINING, value: INDUSTRY_TYPE.MINING },
    {label: INDUSTRY_TYPE.PROFESSIONAL_SERVICES, value: INDUSTRY_TYPE.PROFESSIONAL_SERVICES },
    {label: INDUSTRY_TYPE.REAL_ESTATE, value: INDUSTRY_TYPE.REAL_ESTATE },
    {label: INDUSTRY_TYPE.RETAIL, value: INDUSTRY_TYPE.RETAIL },
    {label: INDUSTRY_TYPE.SERVICES, value: INDUSTRY_TYPE.SERVICES },
    {label: INDUSTRY_TYPE.SPORTS, value: INDUSTRY_TYPE.SPORTS },
    {label: INDUSTRY_TYPE.SUPERMARKETS, value: INDUSTRY_TYPE.SUPERMARKETS },
    {label: INDUSTRY_TYPE.TOURISM, value: INDUSTRY_TYPE.TOURISM },
    {label: INDUSTRY_TYPE.TRANSPORT, value: INDUSTRY_TYPE.TRANSPORT },
    {label: INDUSTRY_TYPE.VET, value: INDUSTRY_TYPE.VET },
    {label: INDUSTRY_TYPE.HOME_DUTIES, value: INDUSTRY_TYPE.HOME_DUTIES },
    {label: INDUSTRY_TYPE.OTHER, value: INDUSTRY_TYPE.OTHER }
];

export const OCCUPATION_NAME_PLENTI = {
    ACCOUNTANT_FINANCIAL_ANALYST: "AccountantFinancialAnalyst",
    ADMINISTRATOR: "Administrator",
    CABIN_CREW: "CabinCrew",
    CEO_CHAIRMAN: "CeoChairman",
    CHEF: "Chef",
    CLERGY: "Clergy",
    CONTRACTOR_CONSULTANT: "ContractorConsultant",
    DEFENCE_WORKER: "DefenceWorker",
    DIPLOMAT: "Diplomat",
    DRIVER: "Driver",
    ENGINEER: "Engineer",
    FACTORY_WORKER: "FactoryWorker",
    GOVERNMENT_OFFICIAL: "GovernmentOfficial",
    HOUSE_PERSON: "HousePerson",
    JOURNALIST: "Journalist",
    LABOURER: "Labourer",
    LEGAL_PROFESSIONAL: "LegalProfessional",
    MANAGER: "Manager",
    MANAGING_DIRECTOR: "ManagingDirector",
    MUSICIAN_ACTOR: "MusicianActor",
    NURSE: "Nurse",
    OFFICE_WORKER: "OfficeWorker",
    PENSIONER: "Pensioner",
    PILOT: "Pilot",
    POLICE_LAW_ENFORCEMENT: "PoliceLawEnforcement",
    PROFESSIONAL: "Professional",
    SALES: "Sales",
    SCIENTIST: "Scientist",
    SELF_EMPLOYED: "SelfEmployed",
    SELF_FUNDED_RETIREE: "SelfFundedRetiree",
    SERVICE_WORKER: "ServiceWorker",
    SYSTEMS_ANALYST_PROGRAMMER: "SystemsAnalystProgrammer",
    TEACHER_EDUCATOR: "TeacherEducator",
    TEAM_LEADER_SUPERVISOR: "TeamLeaderSupervisor",
    OTHER: "Other"
};

export const OCCUPATION_NAME_PLENTI_OPTIONS = [
    {value: OCCUPATION_NAME_PLENTI.ACCOUNTANT_FINANCIAL_ANALYST, label: "Accountant Financial Analyst"},
    {value: OCCUPATION_NAME_PLENTI.ADMINISTRATOR, label: "Administrator"},
    {value: OCCUPATION_NAME_PLENTI.CABIN_CREW, label: "Cabin Crew"},
    {value: OCCUPATION_NAME_PLENTI.CEO_CHAIRMAN, label: "Ceo Chairman"},
    {value: OCCUPATION_NAME_PLENTI.CHEF, label: "Chef"},
    {value: OCCUPATION_NAME_PLENTI.CLERGY, label: "Clergy"},
    {value: OCCUPATION_NAME_PLENTI.CONTRACTOR_CONSULTANT, label: "Contractor Consultant"},
    {value: OCCUPATION_NAME_PLENTI.DEFENCE_WORKER, label: "Defence Worker"},
    {value: OCCUPATION_NAME_PLENTI.DIPLOMAT, label: "Diplomat"},
    {value: OCCUPATION_NAME_PLENTI.DRIVER, label: "Driver"},
    {value: OCCUPATION_NAME_PLENTI.ENGINEER, label: "Engineer"},
    {value: OCCUPATION_NAME_PLENTI.FACTORY_WORKER, label: "Factory Worker"},
    {value: OCCUPATION_NAME_PLENTI.GOVERNMENT_OFFICIAL, label: "Government Official"},
    {value: OCCUPATION_NAME_PLENTI.HOUSE_PERSON, label: "House Person"},
    {value: OCCUPATION_NAME_PLENTI.JOURNALIST, label: "Journalist"},
    {value: OCCUPATION_NAME_PLENTI.LABOURER, label: "Labourer"},
    {value: OCCUPATION_NAME_PLENTI.LEGAL_PROFESSIONAL, label: "Legal Professional"},
    {value: OCCUPATION_NAME_PLENTI.MANAGER, label: "Manager"},
    {value: OCCUPATION_NAME_PLENTI.MANAGING_DIRECTOR, label: "Managing Director"},
    {value: OCCUPATION_NAME_PLENTI.MUSICIAN_ACTOR, label: "Musician Actor"},
    {value: OCCUPATION_NAME_PLENTI.NURSE, label: "Nurse"},
    {value: OCCUPATION_NAME_PLENTI.OFFICE_WORKER, label: "Office Worker"},
    {value: OCCUPATION_NAME_PLENTI.PENSIONER, label: "Pensioner"},
    {value: OCCUPATION_NAME_PLENTI.PILOT, label: "Pilot"},
    {value: OCCUPATION_NAME_PLENTI.POLICE_LAW_ENFORCEMENT, label: "Police Law Enforcement"},
    {value: OCCUPATION_NAME_PLENTI.PROFESSIONAL, label: "Professional"},
    {value: OCCUPATION_NAME_PLENTI.SALES, label: "Sales"},
    {value: OCCUPATION_NAME_PLENTI.SCIENTIST, label: "Scientist"},
    {value: OCCUPATION_NAME_PLENTI.SELF_EMPLOYED, label: "Self Employed"},
    {value: OCCUPATION_NAME_PLENTI.SELF_FUNDED_RETIREE, label: "Self Funded Retiree"},
    {value: OCCUPATION_NAME_PLENTI.SERVICE_WORKER, label: "Service Worker"},
    {value: OCCUPATION_NAME_PLENTI.SYSTEMS_ANALYST_PROGRAMMER, label: "Systems Analyst Programmer"},
    {value: OCCUPATION_NAME_PLENTI.TEACHER_EDUCATOR, label: "Teacher Educator"},
    {value: OCCUPATION_NAME_PLENTI.TEAM_LEADER_SUPERVISOR, label: "Team Leader Supervisor"},
    {value: OCCUPATION_NAME_PLENTI.OTHER, label: "Other"}
];

export const INDUSTRY_TYPE_PLENTI = {
    AGRI_BUSINESS: "Agribusiness",
    ASSET_WEALTH_MANAGEMENT: "AssetWealthManagement",
    BANKING_AND_CAPITAL_MARKETS: "BankingAndCapitalMarkets",
    CONSTRUCTION_TRANSPORTATION: "ConstructionTransportation",
    DEFENCE: "Defence",
    EDUCATION: "Education",
    ENERGY_OIL_GAS: "EnergyOilGas",
    ENTERTAINMENT_MEDIA: "EntertainmentMedia",
    FINANCIAL_SERVICES: "FinancialServices",
    GOVERNMENT: "Government",
    HEALTHCARE: "Healthcare",
    INFRASTRUCTURE: "Infrastructure",
    INSURANCE: "Insurance",
    MINING: "Mining",
    POWERUTILITIES: "PowerUtilities",
    REALESTATE: "RealEstate",
    RETAIL_CONSUMER: "RetailConsumer",
    SUPER_ANNUATION: "Superannuation",
    TECHNOLOGY: "Technology",
    OTHER: "Other"
};

export const INDUSTRY_TYPE_PLENTI_OPTIONS = [
    {label: "Agribusiness", value: INDUSTRY_TYPE_PLENTI.AGRI_BUSINESS },
    {label: "Asset Wealth Management", value: INDUSTRY_TYPE_PLENTI.ASSET_WEALTH_MANAGEMENT },
    {label: "Banking And Capital Markets", value: INDUSTRY_TYPE_PLENTI.BANKING_AND_CAPITAL_MARKETS },
    {label: "Construction Transportation", value: INDUSTRY_TYPE_PLENTI.CONSTRUCTION_TRANSPORTATION },
    {label: "Defence", value: INDUSTRY_TYPE_PLENTI.DEFENCE },
    {label: "Education", value: INDUSTRY_TYPE_PLENTI.EDUCATION },
    {label: "Energy Oil Gas", value: INDUSTRY_TYPE_PLENTI.ENERGY_OIL_GAS },
    {label: "Entertainment Media", value: INDUSTRY_TYPE_PLENTI.ENTERTAINMENT_MEDIA },
    {label: "Financial Services", value: INDUSTRY_TYPE_PLENTI.FINANCIAL_SERVICES },
    {label: "Government", value: INDUSTRY_TYPE_PLENTI.GOVERNMENT },
    {label: "Healthcare", value: INDUSTRY_TYPE_PLENTI.HEALTHCARE },
    {label: "Infrastructure", value: INDUSTRY_TYPE_PLENTI.INFRASTRUCTURE },
    {label: "Insurance", value: INDUSTRY_TYPE_PLENTI.INSURANCE },
    {label: "Mining", value: INDUSTRY_TYPE_PLENTI.MINING },
    {label: "Power Utilities", value: INDUSTRY_TYPE_PLENTI.POWERUTILITIES },
    {label: "Real Estate", value: INDUSTRY_TYPE_PLENTI.REALESTATE },
    {label: "Retail Consumer", value: INDUSTRY_TYPE_PLENTI.RETAIL_CONSUMER },
    {label: "Superannuation", value: INDUSTRY_TYPE_PLENTI.SUPER_ANNUATION },
    {label: "Technology", value: INDUSTRY_TYPE_PLENTI.TECHNOLOGY },
    {label: "Other", value: INDUSTRY_TYPE_PLENTI.OTHER }
];

export const BALLOON_REASONS = {
    LOWER_MONTHLY_PAYMENT: "Lower monthly payment",
    PLANNED_UPGRADE_OF_VEHICLE: "Planned upgrade of vehicle",
    OTHER: "Other"
};

export const BALLOON_REASONS_OPTIONS = [
    {label: BALLOON_REASONS.LOWER_MONTHLY_PAYMENT, value: BALLOON_REASONS.LOWER_MONTHLY_PAYMENT },
    {label: BALLOON_REASONS.PLANNED_UPGRADE_OF_VEHICLE, value: BALLOON_REASONS.PLANNED_UPGRADE_OF_VEHICLE },
    {label: BALLOON_REASONS.OTHER, value: BALLOON_REASONS.OTHER }
];

export const BALLOON_REPAYMENT_METHODS = {
    CASH: "Cash",
    REFINANCE: "Refinance",
    TRADE_IN: "Trade in"
};

export const BALLOON_REPAYMENT_METHODS_OPTIONS = [
    {label: BALLOON_REPAYMENT_METHODS.CASH, value: BALLOON_REPAYMENT_METHODS.CASH },
    {label: BALLOON_REPAYMENT_METHODS.REFINANCE, value: BALLOON_REPAYMENT_METHODS.REFINANCE },
    {label: BALLOON_REPAYMENT_METHODS.TRADE_IN, value: BALLOON_REPAYMENT_METHODS.TRADE_IN }
];

export const HOUSEHOLD_EXPENSE_OPTIONS = [
    {label: "0%", value: "0"},
    {label: "25%", value: "25"},
    {label: "50%", value: "50"},
    {label: "75%", value: "75"},
    {label: "100%", value: "100"},
    {label: "Other", value: "Other"}
];

export const NUMBER_OPTIONS = [
    {label: "0", value: 0},
    {label: "1", value: 1},
    {label: "2", value: 2},
    {label: "3", value: 3}
];

export const LICENCE_TYPE_OPTIONS = [
    {label: "Full", value: "Full"},
    {label: "Provisional", value: "Provisional"},
    {label: "Heavy Vehicle", value: "Heavy Vehicle"},
    {label: "Learner", value: "Learner"},
    {label: "None", value: "None"}
];

export const LICENCE_STATE_OPTIONS = [
    {label: "NSW", value: "NSW"},
    {label: "VIC", value: "VIC"},
    {label: "QLD", value: "QLD"},
    {label: "SA", value: "SA"},
    {label: "ACT", value: "ACT"},
    {label: "NT", value: "NT"},
    {label: "TAS", value: "TAS"},
    {label: "WA", value: "WA"}
];

export const ALLOWED_DELIVERY_STATES = ["NSW", "VIC", "QLD"];

export const FINANCE_LOAN_APPLICATION_STATUS = {
    TO_BE_STARTED: "TO_BE_STARTED",
    COMPLETED: "COMPLETED",
    IN_PROGRESS: "IN_PROGRESS"
};
export const FINANCE_LENDER_ATTRIBUTES = {
    APPROVAL_TIME: "APPROVAL_TIME",
    TERMINATION_CHARGE: "TERMINATION_CHARGE",
    CANCELLATION_FEE: "CANCELLATION_FEE",
    RATING: "RATING"
};
export const LENDER_DISPLAY_NAME = {
    AutoPay: "AutoPay",
    FirstMac: "FirstMac",
    Money3: "Money3",
    PLENTI: "Plenti",
    Wisr: "Wisr",
    Greenlight: "Greenlight",
    Resimac: "Resimac",
    Pepper: "Pepper",
    AngleFinance: "Angle Finance",
    Liberty: "Liberty"
};

export const FINANCE_FORMS_ID = {
    v1Common: "v1_common",
    v1Plenti: "v1_plenti",
    v1Autopay: "v1_autopay",
    v1Greenlight: "v1_greenlight",
    v1Pepper: "v1_pepper",
    v1Commercial: "v1_commercial",
    v1Upload: "v1_upload",
    v2Upload: "v2_upload",
    v3Upload: "v3_upload",
    v4Upload: "v4_upload"
};

export const FINANCE_BROKER_NUMBER = "+61 399 177 765";

export const FIXED_HEADER_SCREENS = ["select-offer"];

export const GUEST_LOGN_POST_PAYMENT_JOURNEY = {
    FINANCE_ZERO: "FINANCE_ZERO ",
    FINANCE: "FINANCE",
    THIRD_PARTY: "THIRD_PARTY",
    CASH: "CASH",
    BANK_TRANSFER: "BANK_TRANSFER"
};

export const REQUEST_CALLBACK_SOURCE = {
    BYOF: "request_a_callback_byof"
};

export const TEST_DRIVE_BOOKING_STATUS_TYPE = {
    BOOKED: "Test drive booked",
    COMPLETED: "Test drive conducted",
    CANCELLED: "Test drive has been cancelled"
};

export const NOT_LOGGED_IN = "NOT_LOGGED_IN";

export const PROMO_CODE_SCREEN_SOURCE = {
    ADD_ONS: "ADD_ONS",
    CHECKOUT: "CHECKOUT"
};
