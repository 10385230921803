const { ORDER_STATUS } = require("../../constants/checkout-constants");

const postOrderBookingConfirmed = (orderStatus) => {
    if (orderStatus === ORDER_STATUS.BOOKED || orderStatus === ORDER_STATUS.RESERVED) {
        return true;
    }
    return false;
};

export {
    postOrderBookingConfirmed
};
