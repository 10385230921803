import { createReducer } from "reduxsauce";
import { CAR_LIST_EVENT_NAMES, GALLERY_TAB_TYPE, PAYMENT_OPTIONS } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    content: {
        city: "",
        price: 0,
        emiDetails: {},
        features: {data: []},
        specifications: {data: []}
    },
    inspectionReport: null,
    error: null,
    isFetching: null,
    isSSR: false,
    selectedImage: null,
    impressionSource: CAR_LIST_EVENT_NAMES.DETAILS,
    isFetchingSimilarToOfferCars: false,
    similarToOfferCars: [],
    activeSimilarToOfferCarIndex: null,
    activeSimilarToOfferCarError: null,
    similarCarsPage: 0,
    similarCarsSize: 5,
    appendSimilarCars: false,
    offerScore: {},
    offerScoreError: null,
    isMakeOfferPaywallVisible: false,
    isPageLoading: false,
    isB2CCity: false,
    paymentOption: PAYMENT_OPTIONS.FINANCE,
    carLocationsError: null,
    carLocations: {
        appliedPincode: ""
    },
    carLocationsFetched: false,
    deliveryInfo: {},
    galleryProps: {
        isOpen: false,
        activeTab: GALLERY_TAB_TYPE.exterior,
        selectedImageIndex: 0,
        showGallery: false,
        subActiveTab: ""
    },
    similarCars: [],
    similarVehicleCount: null,
    isSimilarCarsFetching: false,
    isSimilarCarsError: null,
    personalised: false,
    config: {},
    ipDetectedState: "",
    seoSimilarCars: [],
    seoSimilarVehicleCount: null,
    isSeoSimilarCarsFetching: false,
    isSeoSimilarCarsError: null,
    isLoginFetchCarDetailCompleted: false,
    eligibleForExtendedWarranty: false,
    inspectionReportData: null,
    oldTimerBanner: null,
    redirectRoute: ""
};

export const fetchCarDetails = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetching: true,
        inspectionReport: null
    };
};

export const fetchCarDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        content: data.detail,
        config: data.config,
        oldTimerBanner: data.oldTimerBanner,
        personalised: data.personalised,
        inspectionReport: data.detail.inspectionReport,
        isFetching: false,
        paymentOption: PAYMENT_OPTIONS.FINANCE,
        error: null,
        ipDetectedState: data.ipDetectedState,
        eligibleForExtendedWarranty: data.eligibleForExtendedWarranty
    };
};

export const fetchCarDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false };
};

export const initFetchInspectionReport = (state = INITIAL_STATE) => {
    return {
        ...state,
        inspectionReport: null,
        inspectionReportError: null,
        isFetchingInspectionReport: true
    };
};

export const fetchInspectionReportSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        inspectionReport: data,
        inspectionReportError: null,
        isFetchingInspectionReport: false
    };
};

export const fetchInspectionReportFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        inspectionReport: null,
        inspectionReportError: error,
        isFetchingInspectionReport: false
    };
};

export const updateCarDetailsSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateSelectedImage = (state = INITIAL_STATE, { selectedImage }) => {
    return { ...state, selectedImage };
};

export const updateImpressionSource = (state = INITIAL_STATE, { impressionSource }) => {
    return { ...state, impressionSource };
};

export const fetchSimilarOfferCars = (
    state = INITIAL_STATE,
    { similarCarsPage, similarCarsSize, appendSimilarCars }
) => {
    return {
        ...state,
        isFetchingSimilarToOfferCars: true,
        similarToOfferCars: state.appendSimilarCars ? [...state.similarToOfferCars] : [],
        activeSimilarToOfferCarError: null,
        activeSimilarToOfferCarIndex: null,
        similarCarsPage,
        similarCarsSize,
        appendSimilarCars
    };
};

export const fetchSimilarToOfferCarsSuccess = (state = INITIAL_STATE, { data }) => {
    let similarToOfferCars = [];
    if (state.appendSimilarCars) {
        if (state.similarCarsPage === 0) {
            similarToOfferCars = [...data];
        } else {
            similarToOfferCars = [...state.similarToOfferCars, ...data];
        }
    } else {
        similarToOfferCars = data.slice(0, state.similarCarsSize);
    }
    return {
        ...state,
        similarToOfferCars,
        isFetchingSimilarToOfferCars: false,
        activeSimilarToOfferCarError: null,
        activeSimilarToOfferCarIndex: 0
    };
};

export const fetchSimilarToOfferCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, activeSimilarToOfferCarError: error, isFetchingSimilarToOfferCars: false };
};

export const updateActiveSimilarToOfferCarIndex = (state = INITIAL_STATE, { activeSimilarToOfferCarIndex }) => {
    return { ...state, activeSimilarToOfferCarIndex };
};

export const removeSimilarToOfferCar = (state = INITIAL_STATE, { appId }) => {
    const newIndex = (state.activeSimilarToOfferCarIndex === null ? null : state.activeSimilarToOfferCarIndex) - 1;
    const similarCars = (state.similarToOfferCars || []).filter((car) => car.carId !== appId);
    return {
        ...state,
        similarToOfferCars: similarCars,
        // eslint-disable-next-line no-nested-ternary
        activeSimilarToOfferCarIndex: newIndex >= 0 ? newIndex : similarCars.length ? 0 : null
    };
};

export const updateBookNowPaywallVisibility = (state = INITIAL_STATE, { isVisible }) => {
    return { ...state, isBookNowPaywallVisible: isVisible };
};

export const updateMakeOfferPaywallVisibility = (state = INITIAL_STATE, { isVisible }) => {
    return { ...state, isMakeOfferPaywallVisible: isVisible };
};

export const fetchOfferScoreSuccess = (state = INITIAL_STATE, { data }) => {
    const { offerAppliedCars, offer } = data;
    const validOfferCars = offerAppliedCars.filter((car) => !!car).reverse();
    return {
        ...state,
        offerScore: {
            ...data,
            offerPrice: offer && offer.offerPrice,
            offerAppliedCars: validOfferCars.map((car) => {
                return {
                    imageUrl: car.mainImage ? car.mainImage.url : ""
                };
            })
        },
        offerScoreError: null
    };
};

export const fetchOfferScoreFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, offerScoreError: error };
};

export const fetchPaywallOffers = (state = INITIAL_STATE) => {
    return { ...state, paywallOffers: { ...INITIAL_STATE.paywallOffers } };
};

export const fetchPaywallOffersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        paywallOffers: {
            ...data
        }
    };
};

export const fetchPaywallOffersFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, paywallOffers: { ...INITIAL_STATE.paywallOffers, error } };
};

export const updatePageLoaderStatus = (state = INITIAL_STATE, { isPageLoading }) => {
    return { ...state, isPageLoading };
};

export const setPaymentOption = (state = INITIAL_STATE, { paymentOption }) => {
    return { ...state, paymentOption };
};

export const updateSpincarModalStatus = (state = INITIAL_STATE, { isSpinCarModalOpen }) => {
    return { ...state, isSpinCarModalOpen };
};

export const fetchCarLocationsSuccess = (state = INITIAL_STATE, { data, pincode }) => {
    return {
        ...state,
        carLocations: {
            ...data,
            appliedPincode: pincode
        },
        carLocationsFetched: true
    };
};

export const fetchCarLocationsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, carLocationsError: error, carLocationsFetched: true };
};

export const setGsGalleryData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        galleryProps: {
            ...state.galleryProps,
            ...data
        }
    };
};

export const fetchSimilarCars = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: true, similarCars: [] };
};

export const fetchSimilarCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarCars: data.similarVehicles,
        isSimilarCarsFetching: false,
        isSimilarCarsError: null,
        similarVehicleCount: data.similarVehicleCount
    };
};

export const fetchSimilarCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isSimilarCarsError: error, isSimilarCarsFetching: false };
};

export const getAvailabilityInfoSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        deliveryInfo: {
            ...data
        }
    };
};

export const fetchSeoSimilarCars = (state = INITIAL_STATE) => {
    return { ...state, isSeoSimilarCarsFetching: true, similarCars: [] };
};

export const fetchSeoSimilarCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seoSimilarCars: data.similarVehicles,
        isSeoSimilarCarsFetching: false,
        isSeoSimilarCarsError: null,
        seoSimilarVehicleCount: data.similarVehicleCount
    };
};

export const fetchSeoSimilarCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isSimilarCarsError: error, isSimilarCarsFetching: false };
};

export const updateLoginFetchCarDetailCompleted = (state = INITIAL_STATE, { data }) => {
    return { ...state, isLoginFetchCarDetailCompleted: data };
};

export const setCurrentDeliveryTimeline = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        userDeliveryState: { ...data }
    };
};

const fetchInspectionReportDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        inspectionReportData: data
    };
};

const fetchInspectionReportDataFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        inspectionReportData: null
    };
};
const setRedirectRoute = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        redirectRoute: data
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_DETAILS]: fetchCarDetails,
    [Types.FETCH_CAR_DETAILS_SUCCESS]: fetchCarDetailsSuccess,
    [Types.FETCH_CAR_DETAILS_FAILURE]: fetchCarDetailsFailure,
    [Types.UPDATE_SSR_STATUS]: updateCarDetailsSSRStatus,
    [Types.FETCH_SIMILAR_CARS]: fetchSimilarCars,
    [Types.FETCH_SIMILAR_CARS_SUCCESS]: fetchSimilarCarsSuccess,
    [Types.FETCH_SIMILAR_CARS_FAILURE]: fetchSimilarCarsFailure,
    [Types.SET_GS_GALLERY_DATA]: setGsGalleryData,
    [Types.GET_DELIVERY_INFO_SUCCESS]: getAvailabilityInfoSuccess,
    [Types.FETCH_SEO_SIMILAR_CARS]: fetchSeoSimilarCars,
    [Types.FETCH_SEO_SIMILAR_CARS_SUCCESS]: fetchSeoSimilarCarsSuccess,
    [Types.FETCH_SEO_SIMILAR_CARS_FAILURE]: fetchSeoSimilarCarsFailure,
    [Types.UPDATE_LOGIN_CAR_DETAIL_FETCH_COMPLETED]: updateLoginFetchCarDetailCompleted,
    [Types.SET_CURRENT_DELIVERY_TIMELINE]: setCurrentDeliveryTimeline,
    [Types.FETCH_INSPECTION_REPORT_DATA_SUCCESS]: fetchInspectionReportDataSuccess,
    [Types.FETCH_INSPECTION_REPORT_DATA_FAILURE]: fetchInspectionReportDataFailure,
    [Types.SET_REDIRECT_ROUTE]: setRedirectRoute

};

export default createReducer(INITIAL_STATE, HANDLERS);
