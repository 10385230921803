import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styles from "./styles.css";

const settingsSlider1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrow: false,
    dots: false
};

const settingsSlider2 = {
    slidesToScroll: 1,
    slidesToShow: 2.38,
    infinite: false,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true
};

const VasProductDetailSlider = ({bannerImages = []}) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    if (!(bannerImages || []).length) return null;

    return (
        <div styleName={"styles.sliderOuter"}>
            <div styleName={"styles.leftSliders"}>
                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settingsSlider1}>
                    {(bannerImages || []).map(({imageUrl}) => (
                        <div key={imageUrl} styleName={"styles.heroImageWrapper"}>
                            <div styleName={"styles.blurBg"}>&nbsp;</div>
                            <img src={imageUrl} />
                        </div>
                    ))}
                </Slider>
            </div>
            <div styleName={"styles.rightSliders"}>
                <Slider asNavFor={nav1} ref={(slider2) => setNav2(slider2)} {...settingsSlider2}>
                    {(bannerImages || []).map(({ imageUrl }) => (
                        <div className="thumbnailWrapper" key={imageUrl} styleName={"styles.thumbnailWrapper"}>
                            <img src={imageUrl} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default VasProductDetailSlider;

VasProductDetailSlider.propTypes = {
    bannerImages: PropTypes.array
};

