import React from "react";
import styles from "./styles.css";
import DISCOUNT from "./images/discount.svg";
import PropTypes from "prop-types";

const DrivaPromocodeCard = ({
    onChangeHandler,
    promoCode
}) => {
    const {  message = "message", couponCode} = promoCode;
    const displayMessage = Array.isArray((message || "").split(".")) ? message.split(".")[1] : message;
    return (
        <div styleName={"styles.cardouter"}>
            <div styleName={"styles.header"}>
                <p styleName={"styles.heading"}> <img src={DISCOUNT} /> ‘{couponCode}’ Promo applied.</p>
                <p styleName={"styles.change"} onClick={onChangeHandler}>CHANGE</p>
            </div>
            <p styleName={"styles.text"}>{displayMessage}</p>
        </div>
    );
};

DrivaPromocodeCard.propTypes = {
    promoCode: PropTypes.number,
    onChangeHandler: PropTypes.func
};

export default DrivaPromocodeCard;
