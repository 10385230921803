import React from "react";
import loadable from "@loadable/component";
const DrivaCustomizeWrapper = loadable(() => import("../../components/au.desktop/driva-customize-wrapper"));
const LoanPollingScreen = loadable(() => import("../../components/au.desktop/loan-polling-screen"));

const PersonalDetails = loadable(() => import("../../components/au.desktop/test-drive-v2/components/personal-details"));
const FinanceDetails = loadable(() => import("../../components/au.desktop/test-drive-v2/components/payment-cards"));
const SelectDayStep = loadable(() => import("../../components/au.desktop/test-drive-v2/components/select-day-step"));
const BookingConfirmed = loadable(() => import("../../components/au.desktop/test-drive-v2/components/booking-confirmed"));
const PreliminaryScreen = loadable(() => import("../../components/au.desktop/test-drive-v2/components/preliminary-screen"));

const GetFinancing = loadable(() => import("../../components/au.desktop/get-financing"));
const FinanceLoanDetails = loadable(() => import("../../components/au.desktop/finance-loan-details"));
const FinancePersonalDetails = loadable(() => import("../../components/au.desktop/finance-personal-details"));
const FinanceEmploymentDetails = loadable(() => import("../../components/au.desktop/finance-employment-details"));

export const testDriveV2Routes = {
    preliminary: {
        key: "preliminary",
        name: "Preliminary",
        route: "preliminary",
        component: PreliminaryScreen,
        defaultBack: "",
        defaultNext: "basic-details",
        showEgcPrice: true,
        eventName: "Preliminary",
        sidebarNavigation: {
            heading: "CARS24 test drive",
            subHeading: "Start Booking Process",
            navKey: "preliminary"
        }
    },
    basicDetails: {
        key: "basicDetails",
        name: "basic details",
        route: "basic-details",
        component: PersonalDetails,
        defaultBack: "",
        defaultNext: "finance",
        showEgcPrice: true,
        eventName: "Basic Details",
        sidebarNavigation: {
            heading: "Personal details",
            subHeading: "Tell us about yourself",
            navKey: "basicDetails"
        }
    },
    //finance-routes
    financeDetails: {
        key: "financeDetails",
        name: "finance details",
        route: "finance",
        component: FinanceDetails,
        defaultBack: "basic-details",
        defaultNext: "select-day",
        showEgcPrice: true,
        eventName: "finance",
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails"
        }
    },

    getFinancing: {
        key: "getFinancing",
        name: "get financing",
        route: "get-financing",
        gtmPageName: "Driva_value_prop",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "loan-details",
        component: GetFinancing,
        type: "get-financing",
        approx: 0,
        showPreApprovalPill: false,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },
    loanDetails: {
        key: "loanDetails",
        name: "loan details",
        route: "loan-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "loan_details",
        defaultBack: "get-pre-approval",
        defaultNext: "employment-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: false,
        component: FinanceLoanDetails,
        type: "financing",
        approx: 4,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },
    employmentDetails: {
        key: "employmentDetails",
        name: "employment details",
        route: "employment-details",
        gtmPageName: "Driva_employment_status",
        gtmPillClickLabel: "employment_details",
        defaultNext: "personal-details",
        defaultBack: "loan-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: false,
        component: FinanceEmploymentDetails,
        type: "financing",
        approx: 1,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },
    personalDetails: {
        key: "personalDetails",
        name: "personal details",
        route: "personal-details", //
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "driva_personal_details",
        defaultNext: "select-offer",
        defaultBack: "employment-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: false,
        component: FinancePersonalDetails,
        type: "financing",
        approx: 2,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },

    pollOffers: {
        key: "pollOffers",
        name: "poll offers",
        route: "poll-offers",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "select-offer",
        defaultBack: "basic-details",
        component: LoanPollingScreen,
        type: "financing",
        hideCurrentPill: true,
        approx: 1,
        showPreApprovalPill: false,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },
    selectOffer: {
        key: "selectOffer",
        name: "select offer",
        route: "select-offer",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "select-day",
        defaultBack: "employment-details",
        component: DrivaCustomizeWrapper,
        type: "financing",
        hideCurrentPill: true,
        approx: 1,
        showPreApprovalPill: false,
        showEgcPrice: true,
        sidebarNavigation: {
            heading: "Finance or cash",
            subHeading: "Preferred payment mode",
            navKey: "financeDetails",
            isHidden: true
        }
    },

    scheduleTestDrive: {
        key: "scheduleTestDrive",
        name: "schedule test drive",
        route: "select-day",
        component: SelectDayStep,
        defaultBack: "finance",
        defaultNext: "confirmation",
        showEgcPrice: true,
        eventName: "book slot",
        sidebarNavigation: {
            heading: "Book test drive",
            subHeading: "Choose preferred slot",
            navKey: "scheduleTestDrive"
        }
    },
    bookingConfirmed: {
        key: "bookingConfirmed",
        name: "booking confirmed",
        route: "confirmation",
        component: BookingConfirmed,
        defaultBack: "select-day",
        defaultNext: "",
        showEgcPrice: true,
        eventName: "booking confirmation",
        lastStep: true,
        sidebarNavigation: {
            heading: "Booking confirmed",
            subHeading: "Test drive scheduled",
            navKey: "bookingConfirmed"
        }
    }
};

export const testDriveV2RoutesArray = Object.values(testDriveV2Routes);

export const TEST_DRIVE_V2_ROUTES = testDriveV2RoutesArray.map((item) => item.key);
