import React, { useState } from "react";
import { SubsrciptionService } from "../../../service";
import Button from "../../shared/button";
import InputText from "../../shared/input-text";
import styles from "./styles.css";
import successCheck from "./images/check.png";
import { validateEmail } from "../../../utils/validators/email-validator";
import TrackImpression from "../track-impression";

const StayInTouch = () => {
    const [ email, setEmail ] = useState("");
    const [ successMessage, setSuccessMessage ] = useState(false);
    const isEmailValid = validateEmail(email);

    const handleSubscribeUser = async () => {
        await SubsrciptionService.subsribeEmail({ userId: email });
        setEmail("");
        setSuccessMessage(true);
    };
    return (
        <TrackImpression event={{name: "subscribeViewd"}}>
            <section styleName={"styles.sectionWrap"}>
                <div className={"container"}>
                    <div styleName={"styles.content"}>
                        <h2>Let’s stay in touch!</h2>
                        <p
                            styleName={"styles.subText"}>Subscribe for news, insights and deals from CARS24.</p>
                        <div styleName={"styles.formWrap"}>
                            <InputText
                                placeholder="Your email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                validator={validateEmail}
                                errorMessage="Please enter a valid email!"
                                maxLength={100}
                            />
                            <Button text="SUBSCRIBE" disabled={!isEmailValid} onClick={handleSubscribeUser} />
                        </div>
                        {successMessage && <p
                            styleName={"styles.successText"}>
                            <img src={successCheck} alt="Thank you!" />
                            <span>Thank you! Your submission has been received!</span>
                        </p>}
                    </div>
                </div>
            </section>
        </TrackImpression>
    );
};

export default StayInTouch;
