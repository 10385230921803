import { createReducer } from "reduxsauce";
import Types from "../car-listing/types";

export const INITIAL_STATE = {
    faqData: [],
    inlineFilters: [],
    browseSeoLinks: [],
    isLoading: false,
    error: null
};
export const fetchClpData = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchClpDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        faqData: data.faq || [],
        inlineFilters: data.inlineFilters || [],
        browseSeoLinks: data.browseSeoLinks || [],
        isLoading: false,
        error: null
    };
};

export const fetchClpDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const HANDLERS = {
    [Types.FETCH_FAQ_DATA]: fetchClpData,
    [Types.FETCH_FAQ_DATA_SUCCESS]: fetchClpDataSuccess,
    [Types.FETCH_FAQ_DATA_FAILURE]: fetchClpDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
