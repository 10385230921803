import { init, createReduxEnhancer } from "@sentry/react";
import { NUMBER } from "../../constants/app-constants";

const _dsn = process.env.SENTRY_DSN_REACT;
const blockReduxActionTypes = [
    "configuration/SET_SECURE_TOKEN",
    "checkout/POPULATE_CHECKOUT_PERSONAL_DATA",
    "configuration/GET_PROFILE_DETAILS_SUCCESS"
];

const IGNORE_APIS = [
    { endpoint: `api/v1/order`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `quote/price`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `trade/offer`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `trade/booking`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/consumer/byof`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `/summary`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `oauth`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/vehicle`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/filter`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `/user/me`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/auto-suggest`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/docsign`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/preapproval`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/checkout-journey`, status: [NUMBER.FOUR_HUNDRED_FOUR, NUMBER.FOUR_HUNDRED_ONE, NUMBER.FOUR_HUNDRED, NUMBER.ZERO] },
    { endpoint: `v1/public/config`, status: [NUMBER.ZERO] },
    { endpoint: `v1/inspection`, status: [NUMBER.ZERO] }
];

const IGNORE_THIRD_PARTY_FAILURE = [
    `DhPixel`,
    `gtag`,
    `ChunkLoadError`
];

const IGNORE_ERRORS = [
    "gtag",
    "DhPixel",
    "Loading chunk",
    "InvalidTokenError",
    "Invalid token specified",
    "unknown",
    "pause",
    "resume",
    "Illegal invocation",
    "@derivedConstructor",
    /Request failed with status code (400|401|404|0|403)/,
    "Blocked a frame with origin"
];

const initializeSentry = () => {
    if (process.env.HOST_ENV === "PRODUCTION") {
        init({
            dsn: _dsn,
            environment: process.env.HOST_ENV,
            ignoreErrors: IGNORE_ERRORS,
            release: process.env.SENTRY_RELEASE,
            sampleRate: 0.25,
            // eslint-disable-next-line complexity
            beforeSend(event, hint) {
                const err = hint.originalException;
                if ((err && err.config && err.request && err.config.url &&
                IGNORE_APIS.filter(item => (err.config.url.indexOf(item.endpoint) >= 0 && item.status.filter(statusCode => err.request.status === statusCode).length) || (err.path && err.path.indexOf(item.endpoint) >= 0)).length)
                || err.errorCode) {
                    return null;
                }
                if (err && (err.stack && IGNORE_THIRD_PARTY_FAILURE.filter(item => err.stack.indexOf(item) >= 0).length) || (err.stack === undefined)) {
                    return null;
                }
                return event;
            }
        });
    }
};

const sentryReduxEnhancer = createReduxEnhancer({
    actionTransformer: action => {
        if (blockReduxActionTypes.includes(action.type)) {
            // Return a transformed action to remove sensitive information
            return null;
        }
        return action;
    },
    stateTransformer: state => {
        if (state.user.mobile || state.user.secureToken || state.checkout.personalDetails) {
            // Return null to not send this version of the state.
            return null;
        }
        return state;
    }
});

export {
    initializeSentry,
    sentryReduxEnhancer
};
