import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Pill from "../checkout-pill";
import styles from "./styles.css";
import { withRouter } from "react-router-dom";
import { NUMBER } from "../../../constants/app-constants.js";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import FinancePills from "../finance-pills/component";
import Tick from "./images/tick.svg";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import withEditCheckout from "../checkout-edit-flow-hoc";
import { cloneDeep, unset, update } from "lodash";
import { BOOL_STATE } from "../../../constants/checkout-constants";

const PillNavigation = ({
    match: {
        params: { stepid: currentRoute }
    },
    checkoutEditFormData,
    financeData,
    history,
    content,
    checkoutEditMode = false,
    checkoutEditCompletedStages = [],
    onCustomPillClick = () => {},
    maxStep
}) => {
    const { loanType } = checkoutEditMode ? checkoutEditFormData || {} : financeData || {};
    const { isPurposeBusiness } = loanType || {};
    let checkoutRoutesWithoutEmployment = cloneDeep(checkoutRoutes);
    if (isPurposeBusiness === BOOL_STATE.YES) {
        unset(checkoutRoutesWithoutEmployment, "employmentDetails");
        update(checkoutRoutesWithoutEmployment, "loanDetails.defaultNext", () => "personal-details");
        update(checkoutRoutesWithoutEmployment, "personalDetails.defaultBack", () => "loan-details");
    } else {
        checkoutRoutesWithoutEmployment = cloneDeep(checkoutRoutes);
    }

    const updateCheckoutRoutesArray = Object.values(checkoutRoutesWithoutEmployment);

    const showType = updateCheckoutRoutesArray.find((pillData) => pillData.route === currentRoute).type;

    const maxStepIndex =
        updateCheckoutRoutesArray
            .filter((data) => data.type === showType)
            .findIndex((pillData) => pillData.route === maxStep) || 0;

    const scrollingContainer = useRef();
    const activePill = useRef();

    useEffect(() => {
        const activePillOffsetLeft = activePill.current ? activePill.current.offsetLeft : 0;
        const offset = activePillOffsetLeft ? activePillOffsetLeft - NUMBER.FOURTY : 0;
        scrollingContainer.current.scrollTo({ left: offset, top: 0 });
    }, []);

    return (
        <React.Fragment>
            <div styleName="styles.scrollingContainer" ref={scrollingContainer}>
                <div styleName="styles.pillsContainer">
                    {updateCheckoutRoutesArray
                        .filter((data) => data.type === showType)
                        .map((data, index) => {
                            const active = index <= maxStepIndex;
                            const greenTick = active && data.showGreenTick && (index < maxStepIndex);

                            if (!data.hideCurrentPill && checkoutEditMode) {
                                return (
                                    <PillEditMode
                                        content={content}
                                        checkoutEditCompletedStages={checkoutEditCompletedStages}
                                        key={index}
                                        history={history}
                                        pillData={data}
                                        index={index + 1}
                                        active={active}
                                        showGreenTick={greenTick}
                                        current={currentRoute === data.route}
                                        activeRef={activePill}
                                        showNumber={data.showPillNumber}
                                        editMode={checkoutEditMode}
                                        onCustomPillClick={onCustomPillClick} />
                                );
                            }

                            return (
                                !data.hideCurrentPill && <Pill
                                    key={index}
                                    pillData={data}
                                    index={index + 1}
                                    active={active}
                                    showGreenTick={greenTick}
                                    current={currentRoute === data.route}
                                    activeRef={activePill}
                                    showNumber={data.showPillNumber}
                                    editMode={checkoutEditMode}
                                    onCustomPillClick={onCustomPillClick}
                                />
                            );
                        })}
                </div>
            </div>
        </React.Fragment>
    );
};

const PillEditMode = withEditCheckout((props) => {
    const { index, content, current, history = {}, pillData = {}, onEditStageComplete = () => {}, onCustomPillClick = () => {}, checkoutEditCompletedStages = [] } = props || {};
    const { name, route } = pillData || {};
    const pageStepNo = index;

    const onClick = (e) => {
        const { relativeURL } = getCheckoutPageURL(content, route);
        onCustomPillClick(e, pillData);
        history.push(relativeURL);
    };

    const onCompletedPillClick = (e) => {
        const updatedPills = checkoutEditCompletedStages.slice(0, checkoutEditCompletedStages.indexOf(pageStepNo));
        onEditStageComplete(updatedPills);
        onClick(e);
    };

    if (current) {
        return (
            <FinancePills color="blue" text={name} image={pageStepNo} onClick={onClick} />
        );
    } else if (checkoutEditCompletedStages.indexOf(pageStepNo) !== -1) { /* step is already complete */
        return (
            <FinancePills color="green" text={name} image={Tick} isImage={true} onClick={onCompletedPillClick}  />
        );
    } else {
        return (
            <FinancePills text={name} image={pageStepNo} />
        );
    }
});

PillNavigation.propTypes = {
    match: PropTypes.object,
    content: PropTypes.object,
    history: PropTypes.object,
    checkoutEditFormData: PropTypes.object,
    financeData: PropTypes.object,
    checkoutEditMode: PropTypes.bool,
    completedStages: PropTypes.array,
    checkoutEditCompletedStages: PropTypes.array,
    onCustomPillClick: PropTypes.func,
    maxStep: PropTypes.string
};

export default withRouter(PillNavigation);
