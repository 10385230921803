import React from "react";
import Success from "./images/success.svg";

const IconSuccess = () => {

    return (
        <img src={Success} />
    );
};

export default IconSuccess;
