export default (api) => {

    const getStripePublicKey = (token) => {
        return api.get(`v1/stripe/publishable-key`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        getStripePublicKey
    };
};
