export const C2B_AU_MOBILE_EVENTS = {
    WELCOME_SCREEN: "welcome_screen",
    WELCOME_SCREEN_MOBILE: "welcome_screen_mobile",
    REVIEW_CAR_DETAIL_METHOD: "review_car_detail_method",
    REGO_NUMBER_ENTERED: "rego_number_entered",
    STATE_SELECTED: "state_selected",
    VIN_ENTERED: "vin_entered",
    YEAR_SELECTED: "year_selected",
    KBB_CAR_OUPTUT_CONFIRMATION: "kbb_car_ouptut_confirmation",
    KBB_CAR_OUPTUT_CONFIRMATION_MOBILE: "kbb_car_ouptut_confirmation_mobile",
    CAR_OWNER: "car_owner",
    CAR_OWNER_MOBILE: "car_owner_mobile",
    WRITTEN_OFF: "written_off",
    INERESTED_IN_BUYING: "INERESTED_IN_BUYING",
    WRITTEN_OFF_MOBILE: "written_off_mobile",
    MAJOR_ACCIDENT: "major_accident",
    MAJOR_ACCIDENT_MOBILE: "major_accident_mobile",
    ODOMETER_READING: "odometer_reading",
    ODOMETER_READING_MOBILE: "odometer_reading",
    DASHBOARD_WARNING_LIGHTS: "dashboard_warning_lights",
    DASHBOARD_WARNING_LIGHTS_MOBILE: "dashboard_warning_lights_mobile",
    AIR_CONDITIONING: "air_conditioning",
    AIR_CONDITIONING_MOBILE: "air_conditioning_mobile",
    EXTERIOR_DAMAGE: "exterior_damage",
    EXTERIOR_DAMAGE_MOBILE: "exterior_damage_mobile",
    INTERIOR_DAMAGE: "interior_damage",
    INTERIOR_DAMAGE_MOBILE: "interior_damage_mobile",
    EXTERIOR_DAMAGE_OTHERS_INPUT: "exterior_damage_others_input",
    SERVICE_LOG: "service_log",
    SERVICE_LOG_MOBILE: "service_log_mobile",
    SERVICE_HISTORY: "service_history",
    SERVICE_HISTORY_MOBILE: "service_history_mobile",
    NUMBER_OF_KEYS: "number_of_keys",
    NUMBER_OF_KEYS_MOBILE: "number_of_keys",
    OWE_FINANCE: "owe_finance",
    OWE_FINANCE_MOBILE: "owe_finance_mobile",
    COLLECT_FINANCE_PAYMENT: "collect_finance_payment",
    COLLECT_FINANCE_PAYMENT_MOBILE: "collect_finance_payment",
    SUBMIT_PAYOUT_LETTER: "submit_payout_letter",
    FINANCE_OWING_AMOUNT: "finance_owing_amount",
    FINANCE_OWING_AMOUNT_MOBILE: "finance_owing_amount_mobile",
    EDIT_DETAILS: "edit_details",
    EDIT_DETAILS_MOBILE: "edit_details_mobile",
    CONDITIONS_OF_EVALUATION_SELECTED: "conditions_of_evaluation_selected",
    CONDITIONS_OF_EVALUATION_SELECTED_MOBILE: "conditions_of_evaluation_selected_mobile",
    OFFER_ACCEPTED: "offer_accepted",
    OFFER_ACCEPTED_MOBILE: "offer_accepted_mobile",
    DETAIL_DELIVERY: "Detail_delivery",
    DETAIL_DELIVERY_MOBILE: "Detail_delivery_mobile",
    DELIVERY_SCREEN: "delivery_screen",
    DELIVERY_SCREEN_MOBILE: "delivery_screen_mobile",
    C2B_FLOW: "C2B_flow",
    PAYMENT_SCREEN: "payment_screen",
    PAYMENT_SCREEN_MOBILE: "payment_screen_mobile",
    PAYMENT_SCREEN_SETUP_NOW: "payment_screen_setup_now",
    PAYMENT_SCREEN_SETUP_NOW_MOBILE: "payment_screen_setup_now_mobile",
    ORDER_CONFIRMATION: "order_confirmation",
    ORDER_CONFIRMATION_MOBILE: "order_confirmation_mobile",
    C2B_NEXT: "next",
    C2B_NEXT_DESKTOP: "next_desktop",
    C2B_BACK: "back",
    C2B_BACK_DESKTOP: "back_desktop",
    C2B_LANDING_REVIEW_CAR_DETAILS: "c2b_review_car_detail_method",
    C2B_LANDING_REVIEW_CAR_DETAILS_MOBILE: "review_car_detail_method_mobile",
    C2B_LANDING_REGO_NUMBER_ENTERED: "c2b_rego_number_entered",
    C2B_LANDING_REGO_NUMBER_ENTERED_MOBILE: "rego_number_entered_mobile",
    C2B_LANDING_STATE_SELECTED: "c2b_state_selected",
    C2B_LANDING_STATE_SELECTED_MOBILE: "state_selected_mobile",
    C2B_LANDING_VIN_ENTERED: "c2b_vin_entered",
    C2B_LANDING_VIN_ENTERED_MOBILE: "vin_entered_mobile",
    C2B_LANDING_YEAR_SELECTED: "c2b_year_selected",
    C2B_LANDING_YEAR_SELECTED_MOBILE: "year_selected_mobile",
    C2B_VARIANT_LIST: "variant_list",
    C2B_VARIANT_LIST_MOBILE: "variant_list_mobile",
    C2B_LANDING_GET_STARTED: "get_started",
    C2B_LANDING_GET_STARTED_MOBILE: "get_started_mobile",
    C2B_FLOW_STARTED: "c2b_flow_started",
    C2B_FLOW_STARTED_MOBILE: "c2b_flow_started_mobile",
    PERSONAL_DETAILS: "Detail_Personal",
    PERSONAL_DETAILS_MOBILE: "Detail_Personal_mobile",
    C2B_FORM_SUBMITTED: "c2b_form_submitted",
    C2B_FORM_SUBMITTED_MOBILE: "c2b_form_submitted_mobile",
    C2B_FAQ_OPEN_MOBILE: "c2b_faq_open_mobile",
    C2B_FAQ_CLOSE_MOBILE: "c2b_faq_close_mobile",
    C2B_FAQ_MOBILE: "c2b_faq_mobile",
    C2B_FAQ_OPEN: "c2b_faq_open",
    C2B_FAQ_CLOSE: "c2b_faq_close",
    C2B_FAQ: "c2b_faq",
    C2B_RETURN_TO_HOME: "return_to_home",
    C2B_RETURN_TO_HOME_MOBILE: "return_to_home_mobile",
    C2B_VIRTUAL_INSPECTION: "c2b_virtual_inspection",

    C2B_GET_AN_ESITIMATE: "c2b_get_an_estimate",
    C2B_HOW_IT_WORKS: "c2b_how_it_works",
    VALUATION_SCREEN: "c2b_valuation_screen",
    BODY_CORROSION_ANSWERED: "underbody_rust",
    HARD_GAURDRAIL_ANSWERED: "hard_guardrail",
    ESTIMATED_VALUATION_RANGE_ACCEPTED: "estimated_valuation_accepted",

    //no kbb

    VARIANT_NOT_RETURN: "variant_not_returned",
    NO_TRADEIN_MAKE_SELECTED: "no_trade_in_make_selected",
    NO_TRADEIN_MODEL_SELECTED: "no_trade_in_model_selected",
    NO_TRADEIN_YEAR_SELECTED: "no_trade_in_year_selected",
    NO_TRADEIN_FUEL_SELECTED: "no_trade_in_fuel_selected",
    NO_TRADEIN_BODY_SELECTED: "no_trade_in_body_selected",
    NO_TRADEIN_TRANSMISSION_SELECTED: "no_trade_in_transmission_selected",
    NO_TRADEIN_VARIANT_SELECTED: "no_trade_in_variant_selected",
    NO_TRADEIN_DRIVETRAIN_SELECTED: "no_trade_in_driveTrain_selected",
    NO_TRADE_IN_BACK: "no_trade_in_back",
    NO_TRADE_IN_NEXT: "no_trade_in_next",
    C2B_DO_NOT_AGREE_ASSESSMENT_OPEN: "c2b_do_not_agree_assessment_open",
    C2B_DO_NOT_AGREE_ASSESSMENT_CLOSE: "c2b_do_not_agree_assessment_close",

    // Inspection

    INSPECTION_FORM_DATE_SELECTED: "inspection_form_date_selected",
    INSPECTION_FORM_TIME_SELECTED: "inspection_form_time_selected",
    INSPECTION_FORM_FIRST_DATE: "inspection_form_first_date",
    INSPECTION_FORM_FIRST_TIME: "inspection_form_first_time",
    INSPECTION_FORM_DETAILS_SUBMITTED: "inspection_form_details_submitted",
    INSPECTION_FORM_STATE_SELECTED: "inspection_form_state_selected",
    INSPECTION_FORM_WRITTEN_OFF: "inspection_form_written_off",
    INSPECTION_FORM_FINANCE_OWING: "inspection_form_finance_owing",
    INSPECTION_FORM_PAYOUT_LETTER: "inspection_form_payout_letter",
    INSPECTION_FORM_FINANCE_OWING_AMOUNT: "inspection_form_finance_owing_amount",
    INSPECTION_FORM_SUBMITTED: "inspection_form_submitted",
    INSPECTION_FORM_NEXT_BACK: "inspection_form_next_back",
    INSPECTION_CONFIRMATION_SCREEN: "inspection_confirmation_screen",
    CANCEL_BOOKING_POPUP: "cancel_booking_popup",
    INSPECTION_CANCELLED_POPUP: "inspection_cancelled_popup",
    INSPECTION_RESCHEDULE_MODAL: "inspection_reschedule_model",
    // Rejection

    REJECTION_SCREEN: "rejection_screen",

    // make model flow

    MMVY_PILL_CLICKED: "mmvy_pill_clicked",
    MMVY_DETIAL_ENTERED: "mmvy_detail_enterd",

    // Sell_outright_rejection_screem
    SELL_OUTRIGHT_REJECTION_SCREEN: "sell_outright_rejection_screen",

    // Guest valuation
    EMAIL_ID_SCREEN: "email_id_screen",
    VERIFY_PHONE_NO_SCREEN: "verify_phone_screen",

    // Exterior Condition
    NEXT_EXTERIOR_CONDITION: "next_from_exterior_parts_condition",
    EXTERIOR_DAMAGE_SEVERITY_SELECTED: "exterior_damage_severity_selected",
    PROCEED_AFTER_PARTS_SELECTED: "proceed_after_parts_selection",

    // Landing Page
    LANDING_PAGE_VALUE_PROPS_VIEWED: "landing_page_value_props_viewed",
    LANDING_PAGE_PROCESS_VIEWED: "landing_page_process_viewed",
    LANDING_PAGE_REVIEWS_VIEWED: "landing_page_reviews_viewed",
    LANDING_PAGE_VALUATION_CLICKED: "landing_page_valuation_clicked",

    // MARKET PLACE EVENTS
    MARKET_PLACE_BOOK_INSPECTION: "market_place_book_inspection",
    MARKET_PLACE_VALUATION_PAGE_LOAD: "market_place_valuation_page_load",
    MARKET_PLACE_INSPECTION_LOAD: "market_place_inspection_page_load",
    MARKET_PLACE_VALUATION_PAGE_EDIT_CLICKED: "marketplace_valuation_page",
    MARKET_PLACE_VALUATION_REJECTION_SCREEN_VIEWED: "rejection_screen_viewed",

    // Valuation Page
    VALUATION_PAGE_LOAD: "valuation_page_load",
    INERESTED_CLICKED: "interested_clicked",
    TRADE_IN_PRICE_ACCEPTED_NON_C2B_ELIGIBLE: "trade_in_price_accepted_non_c2b_eligible",
    PRE_BI_TRADE_IN_CAR_CAROSEL_PRICE_SELECTED: "pre_bi_trade_in_car_carousel_price_selected",

    // Price Range Screen
    PRICE_RANGE_INFO_ICON_CLICKED: "price_range_info_icon_clicked",
    PRICE_RANGE_KNOW_MORE_CLICKED: "price_range_know_more_clicked",
    PRICE_RANGE_TRADEIN_MSG_VIEWED: "price_range_tradein_msg_viewed",
    ESTIMATED_VALUATION_RANGE_VARIANT: "price_range_screen_show",

    // email popup
    EMAIL_POPUP_OPENED: "email_popup_opened",
    MOBILE_NUMBER_ENTERED: "mobile_number_entered",
    EMAIL_POPUP_PROCEED_CLICKED: "email_popup_proceed_clicked",
    C2B_LANDING_REGO_NUMBER_ENTERED_BANNER_CLICKED: "rego_number_entered_banner_clicked",
    C2B_LANDING_BANNER_REGO_NUMBER_ENTERED: "banner_rego_number_entered",
    PRE_BI_TRADE_IN_CAR_CAROUSEL_VIEWED: "pre_bi_trade_in_car_carousel_viewed",

    // Mechanical Condition
    MECHANICAL_CONDITION_RATING_VIEWED: "mechanical_condition_viewed",
    MECHANICAL_CONDITION_RATING_CLICKED: "mechanical_condition_clicked",

    BUILD_YEAR_TOOLTIP_CLICKED: "build_year_tooltip_clicked"
};

export default {
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ_CLOSE_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq_close"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ_OPEN_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq_open"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq",
        eventLabel: "'NA'"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ_CLOSE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq_close"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ_OPEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq_open"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FAQ]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "faq",
        eventLabel: "'NA'"
    },

    [C2B_AU_MOBILE_EVENTS.WELCOME_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "welcome_screen"
    },
    [C2B_AU_MOBILE_EVENTS.WELCOME_SCREEN_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "welcome_screen"
    },
    [C2B_AU_MOBILE_EVENTS.REVIEW_CAR_DETAIL_METHOD]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "review_car_detail_method"
    },
    [C2B_AU_MOBILE_EVENTS.REVIEW_CAR_DETAIL_METHOD_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "review_car_detail_method"
    },
    [C2B_AU_MOBILE_EVENTS.REGO_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "rego_number_entered"
    },
    [C2B_AU_MOBILE_EVENTS.STATE_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "state_selected"
    },
    [C2B_AU_MOBILE_EVENTS.VIN_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "vin_entered",
        eventLabel: "NA"
    },
    [C2B_AU_MOBILE_EVENTS.YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "year_selected"
    },
    [C2B_AU_MOBILE_EVENTS.KBB_CAR_OUPTUT_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "kbb_car_ouptut_confirmation"
    },
    [C2B_AU_MOBILE_EVENTS.KBB_CAR_OUPTUT_CONFIRMATION_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "kbb_car_ouptut_confirmation"
    },
    [C2B_AU_MOBILE_EVENTS.CAR_OWNER]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "car_owner"
    },
    [C2B_AU_MOBILE_EVENTS.CAR_OWNER_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "car_owner"
    },
    [C2B_AU_MOBILE_EVENTS.WRITTEN_OFF]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "written_off"
    },
    [C2B_AU_MOBILE_EVENTS.WRITTEN_OFF_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "written_off"
    },
    [C2B_AU_MOBILE_EVENTS.MAJOR_ACCIDENT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "major_accident"
    },
    [C2B_AU_MOBILE_EVENTS.INERESTED_IN_BUYING]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "interested_in_buying"
    },
    [C2B_AU_MOBILE_EVENTS.MAJOR_ACCIDENT_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "major_accident"
    },
    [C2B_AU_MOBILE_EVENTS.ODOMETER_READING]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "odometer_reading"
    },
    [C2B_AU_MOBILE_EVENTS.ODOMETER_READING_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "odometer_reading"
    },
    [C2B_AU_MOBILE_EVENTS.DASHBOARD_WARNING_LIGHTS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "dashboard_warning_lights"
    },
    [C2B_AU_MOBILE_EVENTS.DASHBOARD_WARNING_LIGHTS_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "dashboard_warning_lights"
    },
    [C2B_AU_MOBILE_EVENTS.AIR_CONDITIONING]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "air_conditioning"
    },
    [C2B_AU_MOBILE_EVENTS.AIR_CONDITIONING_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "air_conditioning"
    },
    [C2B_AU_MOBILE_EVENTS.EXTERIOR_DAMAGE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "exterior_damage"
    },
    [C2B_AU_MOBILE_EVENTS.EXTERIOR_DAMAGE_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "exterior_damage"
    },
    [C2B_AU_MOBILE_EVENTS.INTERIOR_DAMAGE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "interior_damage"
    },
    [C2B_AU_MOBILE_EVENTS.INTERIOR_DAMAGE_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "interior_damage"
    },
    [C2B_AU_MOBILE_EVENTS.EXTERIOR_DAMAGE_OTHERS_INPUT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "exterior_damage_others_input",
        eventLabel: "NA"
    },
    [C2B_AU_MOBILE_EVENTS.SERVICE_LOG]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "service_log"
    },
    [C2B_AU_MOBILE_EVENTS.SERVICE_LOG_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "service_log"
    },
    [C2B_AU_MOBILE_EVENTS.SERVICE_HISTORY]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "service_history_status"
    },
    [C2B_AU_MOBILE_EVENTS.SERVICE_HISTORY_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "service_history"
    },
    [C2B_AU_MOBILE_EVENTS.NUMBER_OF_KEYS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "number_of_keys"
    },
    [C2B_AU_MOBILE_EVENTS.NUMBER_OF_KEYS_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "number_of_keys"
    },
    [C2B_AU_MOBILE_EVENTS.OWE_FINANCE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "owe_finance"
    },
    [C2B_AU_MOBILE_EVENTS.OWE_FINANCE_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "owe_finance"
    },
    [C2B_AU_MOBILE_EVENTS.FINANCE_OWING_AMOUNT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "finance_owe_amount"
    },
    [C2B_AU_MOBILE_EVENTS.FINANCE_OWING_AMOUNT_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "finance_owe_amount"
    },
    [C2B_AU_MOBILE_EVENTS.SUBMIT_PAYOUT_LETTER]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "submit_payout_letter"
    },
    [C2B_AU_MOBILE_EVENTS.COLLECT_FINANCE_PAYMENT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "collect_finance_payment"
    },
    [C2B_AU_MOBILE_EVENTS.COLLECT_FINANCE_PAYMENT_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "collect_finance_payment"
    },
    [C2B_AU_MOBILE_EVENTS.EDIT_DETAILS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "edit_details"
    },
    [C2B_AU_MOBILE_EVENTS.EDIT_DETAILS_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "edit_details"
    },

    [C2B_AU_MOBILE_EVENTS.CONDITIONS_OF_EVALUATION_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "conditions_of_evaluation_selected"
    },
    [C2B_AU_MOBILE_EVENTS.CONDITIONS_OF_EVALUATION_SELECTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "conditions_of_evaluation_selected"
    },
    [C2B_AU_MOBILE_EVENTS.OFFER_ACCEPTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "offer_accepted"
    },
    [C2B_AU_MOBILE_EVENTS.OFFER_ACCEPTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "offer_accepted"
    },
    [C2B_AU_MOBILE_EVENTS.DETAIL_DELIVERY]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Detail_delivery"
    },
    [C2B_AU_MOBILE_EVENTS.DETAIL_DELIVERY_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Detail_delivery"
    },
    [C2B_AU_MOBILE_EVENTS.DELIVERY_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "delivery_screen"
    },
    [C2B_AU_MOBILE_EVENTS.DELIVERY_SCREEN_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "delivery_screen"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FLOW]: {
        event: "custom_event",
        eventCategory: "custom_event",
        eventAction: "C2B_flow",
        eventLabel: "{{Detail_Personal}}"
    },
    [C2B_AU_MOBILE_EVENTS.PAYMENT_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "payment_screen"
    },
    [C2B_AU_MOBILE_EVENTS.PAYMENT_SCREEN_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "payment_screen"
    },
    [C2B_AU_MOBILE_EVENTS.PAYMENT_SCREEN_SETUP_NOW]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "payment_screen_setup_now"
    },
    [C2B_AU_MOBILE_EVENTS.PAYMENT_SCREEN_SETUP_NOW_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "payment_screen_setup_now"
    },
    [C2B_AU_MOBILE_EVENTS.ORDER_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "order_confirmation"
    },
    [C2B_AU_MOBILE_EVENTS.ORDER_CONFIRMATION_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "order_confirmation"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_REVIEW_CAR_DETAILS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "review_car_detail_method"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_REVIEW_CAR_DETAILS_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "review_car_detail_method"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_REGO_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "rego_number_entered"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_REGO_NUMBER_ENTERED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "rego_number_entered"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_STATE_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "state_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_STATE_SELECTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "state_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_VIN_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "vin_entered"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_VIN_ENTERED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "vin_entered"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "year_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_YEAR_SELECTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "year_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_GET_STARTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "get_started"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_VARIANT_LIST]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "variant_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_VARIANT_LIST_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "variant_selected"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_GET_STARTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "landing_page_valuation_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FLOW_STARTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_flow_started",
        eventLabel: "NA"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FLOW_STARTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_flow_started"
    },
    [C2B_AU_MOBILE_EVENTS.PERSONAL_DETAILS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Detail_Personal"
    },
    [C2B_AU_MOBILE_EVENTS.PERSONAL_DETAILS_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Detail_Personal"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_NEXT_DESKTOP]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "next"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_BACK_DESKTOP]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "back"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_NEXT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "next"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_BACK]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "back"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FORM_SUBMITTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_form_submitted"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_FORM_SUBMITTED_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_form_submitted"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_RETURN_TO_HOME]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "price_rejection_screen"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_RETURN_TO_HOME_MOBILE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "price_rejection_screen"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_VIRTUAL_INSPECTION]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "appointment_confirmed_screen"
    },

    [C2B_AU_MOBILE_EVENTS.VARIANT_NOT_RETURN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "variant_not_returned"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_MAKE_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "make_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_MODEL_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "model_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "year_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_FUEL_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "fuel_type_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_BODY_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "body_type_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_TRANSMISSION_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "transmission_type_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_DRIVETRAIN_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "DriveTrain_type_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADEIN_VARIANT_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Variant_type_dropdown_selected"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADE_IN_BACK]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Back"
    },
    [C2B_AU_MOBILE_EVENTS.NO_TRADE_IN_NEXT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "Next"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_DO_NOT_AGREE_ASSESSMENT_OPEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "cta_selected",
        eventLabel: "don't_agree_with_our_assessment_modal"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_DO_NOT_AGREE_ASSESSMENT_CLOSE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "don't_agree_with_our_assessment_modal",
        eventLabel: "close"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_DATE_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "date_selected"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_TIME_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "time_selected"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_FIRST_DATE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "inpection_flow_first_date"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_FIRST_TIME]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "inpection_flow_first_time"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_DETAILS_SUBMITTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_details_submitted"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_STATE_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_state_selected"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_WRITTEN_OFF]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_written_off"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_FINANCE_OWING]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_finance_owing"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_PAYOUT_LETTER]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_payout_letter"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_FINANCE_OWING_AMOUNT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_information_finance_owed_amount"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_SUBMITTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "contact_informaation_screen"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_FORM_NEXT_BACK]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "inspection_booking_screen"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_CONFIRMATION_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_booking_submitted_screen"
    },
    [C2B_AU_MOBILE_EVENTS.CANCEL_BOOKING_POPUP]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "cancel_booking_popup"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_CANCELLED_POPUP]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "inspection_cancelled_popup"
    },
    [C2B_AU_MOBILE_EVENTS.REJECTION_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "rejection_sreen_load"
    },
    [C2B_AU_MOBILE_EVENTS.INSPECTION_RESCHEDULE_MODAL]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "reschedule_popup"
    },
    [C2B_AU_MOBILE_EVENTS.MMVY_DETIAL_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow"
    },
    [C2B_AU_MOBILE_EVENTS.MMVY_PILL_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "pill_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_GET_AN_ESITIMATE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "sell_or_trade_in_screen",
        eventLabel: "get_an_estimate_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_HOW_IT_WORKS]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "how_it_works_screen"
    },
    [C2B_AU_MOBILE_EVENTS.SELL_OUTRIGHT_REJECTION_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "sell_outright_rejection_screen"
    },
    [C2B_AU_MOBILE_EVENTS.VALUATION_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "c2b_tradein_valuation_screen"
    },
    [C2B_AU_MOBILE_EVENTS.EMAIL_ID_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "email_id_screen"
    },
    [C2B_AU_MOBILE_EVENTS.VERIFY_PHONE_NO_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "email_verification_screen"
    },
    [C2B_AU_MOBILE_EVENTS.BODY_CORROSION_ANSWERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "underbody_rust"
    },
    [C2B_AU_MOBILE_EVENTS.HARD_GAURDRAIL_ANSWERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "hard_guardrail"
    },
    [C2B_AU_MOBILE_EVENTS.ESTIMATED_VALUATION_RANGE_ACCEPTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "estimated_valuation_accepted"
    },
    [C2B_AU_MOBILE_EVENTS.NEXT_EXTERIOR_CONDITION]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "next_from_exterior_parts_condition"
    },
    [C2B_AU_MOBILE_EVENTS.EXTERIOR_DAMAGE_SEVERITY_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "exterior_damage_severity_selected"
    },
    [C2B_AU_MOBILE_EVENTS.PROCEED_AFTER_PARTS_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "proceed_after_parts_selection"
    },
    [C2B_AU_MOBILE_EVENTS.LANDING_PAGE_VALUE_PROPS_VIEWED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "landing_page_value_props_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.LANDING_PAGE_PROCESS_VIEWED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "landing_page_process_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.LANDING_PAGE_REVIEWS_VIEWED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "landing_page_reviews_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.LANDING_PAGE_VALUATION_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "landing_page_valuation_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.MARKET_PLACE_BOOK_INSPECTION]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "marketplace_book_inspection_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.MARKET_PLACE_VALUATION_PAGE_LOAD]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "marketplace_valuation_page_load"
    },
    [C2B_AU_MOBILE_EVENTS.MARKET_PLACE_INSPECTION_LOAD]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "marketplace_inspection_calender_page_load"
    },
    [C2B_AU_MOBILE_EVENTS.MARKET_PLACE_VALUATION_PAGE_EDIT_CLICKED]: {
        event: "Marketplace users",
        eventCategory: "C2B_flow",
        eventAction: "edit_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.MARKET_PLACE_VALUATION_REJECTION_SCREEN_VIEWED]: {
        event: "Marketplace users",
        eventCategory: "C2B_flow",
        eventAction: "rejection_screen_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.VALUATION_PAGE_LOAD]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "valuation_page_load"
    },
    [C2B_AU_MOBILE_EVENTS.INERESTED_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "interested_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.PRICE_RANGE_INFO_ICON_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "price_range_info_icon_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.PRICE_RANGE_KNOW_MORE_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "price_range_know_more_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.PRICE_RANGE_TRADEIN_MSG_VIEWED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "price_range_tradein_msg_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.ESTIMATED_VALUATION_RANGE_VARIANT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "range_price_viewed"
    },
    [C2B_AU_MOBILE_EVENTS.EMAIL_POPUP_OPENED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "email_popup_opened"
    },
    [C2B_AU_MOBILE_EVENTS.MOBILE_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "mobile_number_entered"
    },
    [C2B_AU_MOBILE_EVENTS.EMAIL_POPUP_PROCEED_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "email_popup_proceed_clicked"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_REGO_NUMBER_ENTERED_BANNER_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "clicked",
        eventLabel: "sell_trade_banner"
    },
    [C2B_AU_MOBILE_EVENTS.C2B_LANDING_BANNER_REGO_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "rego_entered",
        eventLabel: "sell_trade_banner"
    },
    [C2B_AU_MOBILE_EVENTS.PRE_BI_TRADE_IN_CAR_CAROUSEL_VIEWED]: {
        eventCategory: "C2B_flow",
        eventAction: "pre_bi_trade_in_car_carousel_viewed",
        event: "custom_event"
    },
    [C2B_AU_MOBILE_EVENTS.MECHANICAL_CONDITION_RATING_CLICKED]: {
        eventCategory: "C2B_flow",
        eventAction: "mechanical_condition_clicked",
        event: "custom_event"
    },
    [C2B_AU_MOBILE_EVENTS.MECHANICAL_CONDITION_RATING_VIEWED]: {
        eventCategory: "C2B_flow",
        eventAction: "mechanical_condition_viewed",
        event: "custom_event"
    },
    [C2B_AU_MOBILE_EVENTS.BUILD_YEAR_TOOLTIP_CLICKED]: {
        eventAction: "build_year_tooltip_clicked",
        event: "Build year tooltip"
    },
    [C2B_AU_MOBILE_EVENTS.TRADE_IN_PRICE_ACCEPTED_NON_C2B_ELIGIBLE]: {
        eventCategory: "C2B_flow",
        eventAction: "trade_in_price_accepted_non_c2b_eligible",
        event: "custom_event"
    },
    [C2B_AU_MOBILE_EVENTS.PRE_BI_TRADE_IN_CAR_CAROSEL_PRICE_SELECTED]: {
        eventCategory: "C2B_flow",
        eventAction: "pre_bi_trade_in_car_carousel_price_selected",
        event: "custom_event"
    }
};
