import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SUBMIT_CONTACT_US_QUERY
`,
    {
        prefix: "contactusinfo/"
    }
);
