import { createTypes } from "reduxsauce";

export default createTypes(
    `
    GET_TRADE_IN_REQUEST
    GET_TRADE_IN_SUCCESS
    GET_TRADE_IN_FAILURE

    CREATE_TRADE_IN_REQUEST
    CREATE_TRADE_IN_SUCCESS
    CREATE_TRADE_IN_FAILURE

    UPDATE_TRADE_IN_REQUEST
    UPDATE_TRADE_IN_SUCCESS
    UPDATE_TRADE_IN_FAILURE

    GET_TRADE_IN_STATE_REQUEST
    GET_TRADE_IN_STATE_SUCCESS
    GET_TRADE_IN_STATE_FAILURE

    GET_TRADE_IN_VEHICLE_REQUEST
    GET_TRADE_IN_VEHICLE_SUCCESS
    GET_TRADE_IN_VEHICLE_FAILURE

    GET_TRADE_IN_CAR_DETAILS_REQUEST
    GET_TRADE_IN_CAR_DETAILS_SUCCESS
    GET_TRADE_IN_CAR_DETAILS_FAILURE

    GET_TRADE_IN_CAR_VARIANT_REQUEST
    GET_TRADE_IN_CAR_VARIANT_SUCCESS
    GET_TRADE_IN_CAR_VARIANT_FAILURE

    GET_TRADE_IN_TOGGLE_VISIBLE_REQUEST
    GET_TRADE_IN_TOGGLE_VISIBLE_SUCCESS
    GET_TRADE_IN_TOGGLE_VISIBLE_FAILURE

    SET_TRADE_IN_ANOTHER_CAR_TRIGGERED
    SET_EDIT_CAR_DETAILS_TRIGGERED
    SET_TRADE_IN_TOGGLE_SELECTED
    SAVE_TRADE_IN_ID
    SAVE_CAR_REGNO_VIN
    SET_PILLS_MAX_STEP
    SET_QUESTIONNAIRE_PAGE_NO
    RESET_CAR_TRADE_IN_DETAILS
    SET_VIEW_CARS_BUTTON_TRIGGERED

    FETCH_TRADE_IN_CANCELLATION_REASON
    FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS
    FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE
`,
    {
        prefix: "tradeInYourCar/"
    }
);
