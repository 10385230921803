import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_DETAILS
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE

    UPDATE_SSR_STATUS

    FETCH_SIMILAR_CARS
    FETCH_SIMILAR_CARS_SUCCESS
    FETCH_SIMILAR_CARS_FAILURE

    SET_GS_GALLERY_DATA

    GET_DELIVERY_INFO_REQUEST
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    FETCH_SEO_SIMILAR_CARS
    FETCH_SEO_SIMILAR_CARS_SUCCESS
    FETCH_SEO_SIMILAR_CARS_FAILURE
    GET_DRIVA_ROI_REQUEST
    GET_DRIVA_ROI_SUCCESS
    GET_DRIVA_ROI_FAILURE
    UPDATE_LOGIN_CAR_DETAIL_FETCH_COMPLETED

    SET_CURRENT_DELIVERY_TIMELINE

    FETCH_INSPECTION_REPORT_DATA_SUCCESS
    FETCH_INSPECTION_REPORT_DATA_FAILURE

    SET_REDIRECT_ROUTE
`,
    {
        prefix: "cardetails/"
    }
);
