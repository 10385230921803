const config = {
    behavior: "smooth",
    block: "start"
};

export default (element) => {
    if (element) {
        element.scrollIntoView(config);
    }
};
