import React, {useState, useEffect} from "react";
import styles from "./index.css";
import PropTypes from "prop-types";

const AvatarText = ({fullName, nameStyles = {}, bodyStyles = {} }) => {
    const [initials, setInitials] = useState("");

    useEffect(() => {
        if (fullName) {
            const name = fullName.trim().split(" ");
            let temp = "";
            if (name.length > 0 && name[0].length > 0) temp += name.shift()[0];
            if (name.length > 0 && name[name.length - 1].length > 0) temp += name.pop()[0];
            if (temp.length > 0) {setInitials(temp.toUpperCase());}
        }
    }, [fullName]);

    return (
        <div styleName={"styles.avatarWrapper"} style={{...bodyStyles}} className="avatarTextBody">
            <h1 style={{...nameStyles}} className="avatarTextName">{initials}</h1>
        </div>
    );
};

AvatarText.propTypes = {
    fullName: PropTypes.string,
    nameStyles: PropTypes.object,
    bodyStyles: PropTypes.object
};

export default AvatarText;
