import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckoutLayout from "./component";

const mapStateToProps = ({ carDetails: { content }, user: {isAffiliate} }) => ({ content, isAffiliate});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutLayout);
