/* eslint-disable no-magic-numbers */
const getArrayRange = (start = 2010, end = new Date().getFullYear()) => {
    const data = [];
    for (let i = start; i <= end; i++) {
        const item = {};
        item.label  = `${i}`;
        item.value = i;
        data.push(item);
    }
    return data;
};

export {
    getArrayRange
};
