import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import animation from "./images/chat-animation.gif";
import ChatIcon from "./images/chat-icon.webp";
const ChatIconRevamp = ({onClick, supportWidget = {}}) => {
    const {showNudge = false, nudgeNotificationCount = 0} = supportWidget || {};
    return (
        <div styleName={"styles.chatOuter"} onClick={onClick}>
            {+nudgeNotificationCount ? <p styleName={"styles.chatCount"}>{nudgeNotificationCount || null}</p> : null}
            <div styleName={"styles.outer"}>
                <img src={ showNudge ? animation : ChatIcon} styleName={showNudge ? "styles.chatIconAnimation" : "styles.staticChatIcon"} />
            </div>
        </div>
    );
};

ChatIconRevamp.propTypes = {
    onClick: PropTypes.func,
    supportWidget: PropTypes.object
};

export default ChatIconRevamp;
