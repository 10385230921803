import { NUMBER, SALE_KEY } from "../../constants/app-constants";
import { makePriceLabelRound } from "./make-price-label";

export const checkoutSummarySaleLabel = ({saleConfig = {}, price, egc}) => {
    const onSalePrice =    makePriceLabelRound(price - egc.value);
    const {saleLive, key: saleKey} = saleConfig?.data || {};
    const salePrice = (price - egc.value) >= NUMBER.THREE_HUNDRED;
    const showSaleLabel = saleLive && salePrice;
    const SALE_LABEL = {
        [SALE_KEY.BLACK_FRIDAY]: `Includes ${onSalePrice} Black Friday Sale discount`,
        [SALE_KEY.CHRISTMAS_SALE]: `Christmas sale discount ${onSalePrice}`
    };
    return showSaleLabel && SALE_LABEL[saleKey];
};

export const checkoutSummarySaleLabelNew = ({saleConfig = {}, price, egc}) => {
    const onSalePrice =    makePriceLabelRound(price - egc);
    const {saleLive, key: saleKey} = saleConfig?.data || {};
    const salePrice = (price - egc) >= NUMBER.THREE_HUNDRED;
    const showSaleLabel = saleLive && salePrice;
    const SALE_LABEL = {
        [SALE_KEY.BLACK_FRIDAY]: `Includes ${onSalePrice} Black Friday Sale discount`,
        [SALE_KEY.CHRISTMAS_SALE]: `Christmas sale discount ${onSalePrice}`
    };
    return showSaleLabel && SALE_LABEL[saleKey];
};

