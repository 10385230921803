import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    content: [],
    quotedPriceContent: [],
    bodyTypeContent: [],
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null
    },
    totalCars: 0,
    totalPages: null,
    isSSR: false,
    page: 0,
    isLoading: false,
    error: null,
    config: {},
    shouldReloadList: false,
    saleConfig: {
        isLoading: false,
        error: false,
        isSSR: false,
        data: {}
    },
    personalised: false,
    seoMenu: {},
    logoURL: "/",
    recentlyViewedArr: [],
    recentlyViewedCookieData: [],
    totalAvailableCars: 0
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data, params, contentKey }) => {
    return {
        ...state,
        [contentKey ? contentKey : "content"]: [...data.results],
        metaContent: { ...data.metaContent },
        totalCars: data.total,
        totalPages: data.totalPages,
        personalised: data.personalised,
        isLoading: false,
        error: null,
        ...((params && params.page >= 0) && { page: params.page }),
        config: data.config
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isLoading: false};
};

export const fetchConfigInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        saleConfig: { ...state.saleConfig, isLoading: false, data, error: false }
    };
};

export const fetchConfigFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        saleConfig: { ...state.saleConfig, isLoading: false, error: true }
    };
};

export const seoHeaderMenuSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seoMenu: {  isLoading: false, ...data, error: false, isSSR: false}
    };
};

export const seoHeaderMenuFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        seoMenu: { ...state.seoMenu, isLoading: false, error: true }
    };
};

export const setLogoUrl = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        logoURL: data
    };
};

export const setHomeSSR = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isSSR: data
    };
};
const setTotalAvailableCarsSuccess = (state = INITIAL_STATE, {data = {}}) => {
    return {
        ...state,
        totalAvailableCars: data.totalCount
    };
};

const setTotalAvailableCarsFailed = (state = INITIAL_STATE) => {
    return {
        ...state,
        totalAvailableCars: 0
    };
};
export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.FETCH_CONFIG]: fetchConfigInit,
    [Types.FETCH_CONFIG_SUCCESS]: fetchConfigSuccess,
    [Types.FETCH_CONFIG_FAILURE]: fetchConfigFailure,
    [Types.SEO_HEADER_MENU_SUCCESS]: seoHeaderMenuSuccess,
    [Types.SEO_HEADER_MENU_FAILURE]: seoHeaderMenuFailure,
    [Types.SET_LOGO_URL]: setLogoUrl,
    [Types.SET_HOME_SSR]: setHomeSSR,
    [Types.FETCH_TOTAL_CAR_SUCCESS]: setTotalAvailableCarsSuccess,
    [Types.FETCH_TOTAL_CAR_FAILURE]: setTotalAvailableCarsFailed

};

export default createReducer(INITIAL_STATE, HANDLERS);
