import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import CUSTOMERICON from "./images/customericon.svg";

const CustomerCareTeam = ({mobile}) => {
    return (
        <div styleName={"styles.wrapper"}>
            <img src={CUSTOMERICON} alt="" />
            <p styleName={"styles.text"}>Our team will call on {mobile} to finalise your preferred date and time after the refundable deposit is paid</p>
        </div>
    );
};

CustomerCareTeam.propTypes = {
    mobile: PropTypes.string
};
export default CustomerCareTeam;
