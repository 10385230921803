import Types from "./types";

const showToast = (toast) => ({
    type: Types.SHOW_TOAST,
    toast
});

const hideToast = () => ({
    type: Types.HIDE_TOAST
});

export {
    showToast,
    hideToast
};
