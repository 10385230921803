import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import UploadImage from "./images/upload-img.png";

const TransferSuccessPhotoModal = ({onClose}) => {
    return (
        <Modal isOpen={true}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.header"}>
                    <h4 styleName={"styles.heading"}>Transfer success photo</h4>
                    <div styleName={"styles.headerActions"}>
                        <span>
                            <CloseButton type="grey" onClickHandler={onClose}/>
                        </span>
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <p styleName={"styles.contentHeading"}>Take a screenshot of your bank confirming the transfer to CARS24 has been successful.</p>

                    <p styleName={"styles.listHeading"}>The screenshot should contain;</p>
                    <ul styleName={"styles.lists"}>
                        <li>Amount transferred</li>
                        <li>CARS24 account number</li>
                        <li>CARS24 BSB</li>
                    </ul>
                    <p styleName={"styles.imageHeading"}>Example of a transfer success photo</p>
                    <div styleName={"styles.uploadImageWrap"}>
                        <img src={UploadImage} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

TransferSuccessPhotoModal.propTypes = {
    onClose: PropTypes.func
};

export default TransferSuccessPhotoModal;
