import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import InputRadioButton from "../../shared/input-radio-button";

const cardColors = {
    red: "styles.surcharge",
    blue: "styles.blueSurcharge",
    default: "styles.noFees"
};

const PaymentMode = ({
    isSelected = false,
    paymentMethodTypeRef,
    mode,
    onClick = () => {},
    imgSrc = null,
    label = "Debit/Credit Card",
    description = "",
    recommended = false,
    cardColor = cardColors.default
}) => {
    const handleClick = () => {
        onClick(mode);
    };

    const cardStyle = cardColors[cardColor] || cardColors.default;

    return (
        <React.Fragment>

            <div styleName={"styles.paymentModeWrap"}  ref={isSelected ? paymentMethodTypeRef : undefined} onClick={handleClick}>
                <img src={imgSrc} />
                <div styleName={"styles.checkboxWrapper"}>
                    <div>
                        <p styleName={"styles.heading"}>{label}</p>
                        <p styleName={cardStyle}>{description}</p>
                        {recommended && <p styleName={"styles.recommended"}>Recommended</p>}
                    </div>
                    <div styleName={"styles.radioWrapper"}>
                        <InputRadioButton checked={isSelected} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

PaymentMode.propTypes = {
    isSelected: PropTypes.bool,
    paymentMethodTypeRef: PropTypes.any,
    mode: PropTypes.string,
    onClick: PropTypes.func,
    imgSrc: PropTypes.any,
    label: PropTypes.string,
    description: PropTypes.string,
    orderId: PropTypes.string,
    handlePaymentMode: PropTypes.func,
    agreedToTerms: PropTypes.bool,
    canMakePayment: PropTypes.object,
    disablePaymentMode: PropTypes.object,
    isReservationVariant: PropTypes.bool,
    finalPrice: PropTypes.number,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    selectedSplitAmount: PropTypes.number,
    isSplitPaymentOpted: PropTypes.bool,
    recommended: PropTypes.bool,
    cardColor: PropTypes.bool
};
export default PaymentMode;
