import { createTypes } from "reduxsauce";

export default createTypes(
    `
    REGISTER_VERIFICATION_STATUS_INIT
    REGISTER_VERIFICATION_STATUS_SUCCESS
    REGISTER_VERIFICATION_STATUS_FAILURE
`,
    {
        prefix: "checkout/"
    }
);
