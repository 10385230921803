import React, {useRef, useEffect, useState} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Arrow from "./images/arrow.png";
import { EXTENDED_WARRANTY_DURATION, NUMBER } from "../../../constants/app-constants";

const ManufacturerWarrantyCard = ({warrantyPlanInfo}) => {
    const transformRef = useRef(null);
    const [fillValue, setFillValue] = useState({});
    const {coverMonths = NUMBER.THREE, coverDate} = warrantyPlanInfo || {};

    useEffect(() => {
        const calculateFill = () => {
            const fill = (coverMonths * NUMBER.HUNDRED) / EXTENDED_WARRANTY_DURATION.MAX;
            const percentFill = (fill * NUMBER.ONE_HUNDRED_EIGHTY) /  NUMBER.HUNDRED;
            return {filled: percentFill, unfilled: NUMBER.HUNDRED - percentFill};
        };
        const fillValues = calculateFill();
        setFillValue(fillValues);
        if (transformRef.current) {
            transformRef.current.style.setProperty("--rotate-deg", `${fillValues.filled}deg`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverMonths]);
    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.topSection"}>
                <div styleName={"styles.topLeft"}>
                    <p styleName={"styles.activeState"}>ACTIVE</p>
                    <p styleName={"styles.manufacturerWarranty"}>Manufacturer warranty</p>
                    <p styleName={"styles.coverage"}>Coverage till {coverDate}</p>
                </div>
                <div styleName="styles.topRight">
                    <div styleName="styles.outerCircle">
                        <div styleName="styles.outerWhite">
                            <div styleName="styles.container">
                                <div styleName="styles.pie" ref={transformRef} >
                                    <span styleName="styles.overlay" />
                                </div>
                            </div>
                            <div styleName="styles.innerDarkBlue">
                                <div styleName="styles.innerLightBlue">
                                    <p styleName="styles.text">{coverMonths}<span>months</span></p>
                                </div>
                            </div>

                            <span styleName="styles.arrow" style={{transform: `rotate(${fillValue.filled || 0 }deg)`}}>
                                <img src={Arrow} height="17px" width="75px" />

                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div styleName={"styles.bottomSection"}>
                <p styleName={"styles.listWrapper"}><span styleName={"styles.orangeBox"}>&nbsp;</span>{coverMonths} months free manufacturer warranty left.</p>
                <p styleName={"styles.listWrapper"}><span styleName={"styles.blueBox"}>&nbsp;</span>Optional additional warranty with CARS24 platinum cover plans.</p>
            </div>
        </div>
    );
};
ManufacturerWarrantyCard.propTypes = {
    warrantyPlanInfo: PropTypes.object
};
export default ManufacturerWarrantyCard;
