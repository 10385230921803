import React from "react";
// import { useHistory } from "react-router";
// import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import VerifyIcon from "./images/verify-id-icon.svg";
import GreenCheck from "./images/green-check.svg";
import Pending from "./images/pending.svg";
import ErrorIcon from "./images/error.svg";
import styles from "./styles.css";
// import { tasksRoutes } from "../../../constants/au.desktop/tasks-routes";
import PropTypes from "prop-types";
import { VERIFICATION_STATUS } from "../../../constants/checkout-constants";
import TaskCardShimmer from "../task-card-shimmer";
import { useHistory } from "react-router-dom";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import Button from "../../shared/button";

const GreenIdCheck = ({
    // content,
    verificationData,
    verifyIdCompleted,
    verificationDataError,
    showVariant = "VARIANT_A"
}) => {
    const history = useHistory();
    const { CREATED, PENDING, VERIFIED, FAILED } = VERIFICATION_STATUS;
    const { status, verificationToken, orderId } = verificationData || {};

    const handleVerifyIdClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.GREEN_ID_BUTTON_CLICK, {eventLabel: `${orderId}`});
        history.push(`/licence-verification?verificationToken=${verificationToken || ""}&orderId=${orderId}&status=${status || ""}&error=${verificationDataError || ""}`);
    };

    const renderVerificationIdStatus = () => {
        switch (status) {
        case CREATED:
            return (
                <React.Fragment>
                    {showVariant === EXPERIMENT_TYPE.VARIANT_A && <span styleName={"styles.cardCta"} onClick={handleVerifyIdClick}>VERIFY ID</span>}
                    {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
                    <div styleName="styles.buttonWrapper">
                        <Button text={"VERIFY LICENCE"} onClick={handleVerifyIdClick} />
                    </div>}

                </React.Fragment>

            );
        case PENDING:
            return (
                <span styleName={"styles.ctaPending"}>Pending
                    <img src={Pending} alt="Pending" />
                </span>
            );
        case FAILED:
            return (
                <span styleName={"styles.cardCtaFailed"}>Failed
                    <img src={ErrorIcon} alt="Error" />
                </span>
            );
        case VERIFIED:
            return (
                <span styleName={"styles.cardCta styles.approvedGreen"}>
                    ID verified
                    <img src={GreenCheck} alt="Approved" />
                </span>
            );
        default:
            return (
                <span styleName={"styles.cardCta"} onClick={handleVerifyIdClick}>{"VERIFY ID"}</span>
            );
        }
    };

    const renderText = () => {
        if (status === PENDING) {
            return "A member of our team will be in contact to manually verify your ID";
        } else if (status === VERIFIED) {
            return "Your drivers licence has been verified";
        } else if (status === FAILED) {
            return "A member of our team will be in contact to manually verify your ID";
        }
        return null;
    };

    if (!verifyIdCompleted) {
        return <TaskCardShimmer />;
    }

    return (
        <div>
            {showVariant === EXPERIMENT_TYPE.VARIANT_A &&
            <div styleName={"styles.taskCard "}>
                <h3>Upload Drivers Licence</h3>
                <p styleName={"styles.contractText"}>{renderText()}</p>
                {renderVerificationIdStatus()}
                <img styleName={"styles.cardImage"} src={VerifyIcon} alt="verify id" />
            </div>}
            {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
            <div styleName={"styles.taskCard "}>
                <h3>Verify your drivers licence {status === CREATED && <span styleName={"styles.dueNow"}>Due now</span>}</h3>
                <p styleName={"styles.contractText"}>{renderText()}</p>
                {renderVerificationIdStatus()}
                <img styleName={"styles.cardImage"} src={VerifyIcon} alt="verify id" />
            </div>}
        </div>
    );
};

GreenIdCheck.propTypes = {
    content: PropTypes.object,
    verificationData: PropTypes.object,
    registerVerificationStatusConnect: PropTypes.func,
    verifyIdCompleted: PropTypes.bool,
    verificationDataError: PropTypes.object,
    showVariant: PropTypes.string
};

export default GreenIdCheck;
