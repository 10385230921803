/* eslint-disable prefer-const */
import { NUMBER, PAYMENT_SURCHARGE, PAYMENT_SURCHARGE_NEW } from "../../constants/app-constants";
import { PAYMENT_STATUS, PAYMENT_TYPE } from "../../constants/checkout-constants";
import { fastlyUrl, imageTypes } from "./cdn-image-url";
import { makePriceLabelRound } from "./make-price-label";
import { postOrderBookingConfirmed } from "./post-order-placed-confirmed";

/**
 * sum all the values
 *
 * @param {Array} array the array of objects where each object contains key for summation
 * @param {Object} key a property from object which needs to sum
 * @returns {number} Returns the total amount of composed arguments.
 * example: array - [{amount:12},{amount:15}]
 * return 27
 */
const getSum = (array = [], {key = "amount"} = {}) => (array || []).reduce((acc, curr) => acc + curr[key], 0);

/**
 * @param {Object} carDetails
 * {
 * config: cdn url to get image
 * content: cardetails like year,make,model,path
 * }
 * @returns {Object} imageUrl and carFullName
 */
const getCarImage = ({config = {}, content = {}, imageType = imageTypes.helloARCarCardAu}) => {
    const {year, make, model, mainImage} = content;
    const hasImage = mainImage && mainImage.path;
    const imageUrl = hasImage ? fastlyUrl(config.imageHost, mainImage.path, imageType) : "";
    const carFullName = `${year || ""} ${make || ""} ${model || ""}`;

    return {imageUrl, carFullName, ...content};
};

const validateSplitPay = ({splitPayments = [], dapOrTradePrice, selectedAmount = 0, splitPaymentOpted, orderStatus, discountValue = 0}) => {
    // const {isSplitPaymentOpted } = splitAmountDetails;
    if (!splitPayments) {
        return {
            selectedSplitAmount: selectedAmount,
            totalAmountPaid: 0,
            remainingAmount: dapOrTradePrice
        };
    }
    const  succeedPay = splitPayments.filter(d => d.paymentStatus === PAYMENT_STATUS.SUCCESS);
    const  createdPay = splitPayments.filter(d => d.paymentStatus === PAYMENT_STATUS.CREATED);
    const isFirstSplitPay =  (succeedPay.length === 0 && createdPay.length === 1) || !postOrderBookingConfirmed(orderStatus);
    const isSplitPaymentOpted =  splitPaymentOpted;
    const totalAmountPaid = getSum(succeedPay) + discountValue;
    const selectedSplitAmount = (createdPay[0] && createdPay[0].amount) || 0;
    const isFullPaymentDone =  splitPaymentOpted ? Math.round(totalAmountPaid) === Math.round(dapOrTradePrice) : true;

    return {
        isFirstSplitPay,
        isSplitPaymentOpted,
        totalAmountPaid,
        selectedSplitAmount,
        isFullPaymentDone,
        remainingAmount: Math.round(dapOrTradePrice) - totalAmountPaid - selectedSplitAmount,
        finalRemainingAmount: Math.round(dapOrTradePrice - totalAmountPaid),
        splitPayLabel: (succeedPay.length > 0 && `Payment ${succeedPay.length}`) || (isFirstSplitPay && "Deposit")
    };
};

const getAmountWithSurcharge = (amount = 0, paymentMethodType = "") => {
    let surcharge = 0;
    if (paymentMethodType === PAYMENT_TYPE.CARD.value || paymentMethodType === PAYMENT_TYPE.GOOGLE_PAY.value || paymentMethodType === PAYMENT_TYPE.APPLE_PAY.value) {
        surcharge =  amount * (PAYMENT_SURCHARGE.value / NUMBER.HUNDRED);
    }

    const amountWithSurcharge = Math.round(amount) + Math.round(surcharge);
    return {amountWithSurcharge, surcharge, amountWithSurchargeLabel: makePriceLabelRound(amountWithSurcharge)};
};

// eslint-disable-next-line max-params
const getAmountWithSurchargeNew = (amount = 0, paymentMethodType = "", cardType = "", isInHouse = false) => {
    let surcharge = 0;
    if (paymentMethodType === PAYMENT_TYPE.CARD.value || paymentMethodType === PAYMENT_TYPE.GOOGLE_PAY.value || paymentMethodType === PAYMENT_TYPE.APPLE_PAY.value) {
        if (cardType.length>1) {
            surcharge =  amount * (PAYMENT_SURCHARGE_NEW[cardType].value / NUMBER.HUNDRED);
        } else surcharge =  amount * (PAYMENT_SURCHARGE_NEW.value / NUMBER.HUNDRED);
    }
    if (isInHouse) {
        surcharge = 0;
    }

    const amountWithSurcharge = Math.round(amount) + Math.round(surcharge);
    return {amountWithSurcharge, surcharge, amountWithSurchargeLabel: makePriceLabelRound(amountWithSurcharge)};
};

export {getSum, getCarImage, validateSplitPay, getAmountWithSurcharge, getAmountWithSurchargeNew};
