// eslint-disable-next-line no-magic-numbers
export default ({latitude, longitude, zoom = 17}) => {
    const navigator = window.navigator;
    if (navigator) {
        const position = `${latitude},${longitude}&z=${zoom}`;
        if ((navigator.platform.indexOf("iPhone") !== -1)
        || (navigator.platform.indexOf("iPod") !== -1)
        || (navigator.platform.indexOf("iPad") !== -1)) {
            window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${position}`);
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${position}`);
        }
    }

};
