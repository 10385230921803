import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GreenId from "./component";
import {registerVerificationStatus}  from "./actions";

const mapStateToProps = ({
    checkout: {order}
}) => ({order});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    registerVerificationStatusConnect: registerVerificationStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GreenId);
