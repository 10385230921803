import { API_SOURCE } from "../../constants/app-constants";

const getDeviceType = () => {
    if (typeof window !== "undefined") {
        const userAgent = window ? window.navigator.userAgent : "";
        if (userAgent.match(/mobile/i)) {
            return API_SOURCE.M_SITE;
        } else if (userAgent.match(/iPad|Android|Touch/i)) {
            return API_SOURCE.WEB_APP;
        } else {
            return API_SOURCE.WEB_APP;
        }
    }
    return null;
};

export {
    getDeviceType
};
