import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    nudgeList: [],
    error: false,
    isLoading: false,
    closedNudgesId: []
};

export const fetchNudgeListInitiated = (state = INITIAL_STATE) => {
    return { ...state,  isLoading: true};
};

export const fetchNudgeListSuccess = (state = INITIAL_STATE, {data}) => {
    return { ...state, nudgeList: data, isLoading: false, error: false};
};

export const fetchNudgeListFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, error: true};
};

export const setNudgeDisplay = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        closedNudgesId: [...state.closedNudgesId, data]
    };
};

export const CloseNudgeListInitiated = (state = INITIAL_STATE) => {
    return { ...state,  isLoading: true};
};

export const closeNudgeListSuccess = (state = INITIAL_STATE, {data}) => {
    return { ...state, data, isLoading: false, error: false};
};

export const closeNudgeListFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, error: true};
};

export const HANDLERS = {
    [Types.FETCH_NUDGE_LIST]: fetchNudgeListInitiated,
    [Types.FETCH_NUDGE_LIST_SUCCESS]: fetchNudgeListSuccess,
    [Types.FETCH_NUDGE_LIST_FAILURE]: fetchNudgeListFailure,
    [Types.SET_NUDGE_DISPLAY]: setNudgeDisplay,
    [Types.CLOSE_NUDGE_LIST]: CloseNudgeListInitiated,
    [Types.CLOSE_NUDGE_LIST_SUCCESS]: closeNudgeListSuccess,
    [Types.CLOSE_NUDGE_LIST_FAILURE]: closeNudgeListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
