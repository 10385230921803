import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const VasStoreSliderCard = ({cardImage, cardTitle, isActive, onClick}) => {
    return (
        <div styleName={`styles.vasCardOuter ${isActive ? "styles.activeCard" : ""}`} onClick={onClick}>
            <img src={cardImage} />
            <p styleName={`styles.cardTitle ${isActive ? "styles.boldFont" : ""}`}>{cardTitle}</p>
            {/*<p styleName={`styles.saveAmount ${isAvailable ? "styles.display" : ""}`}>Save $800</p>*/}
        </div>
    );
};

VasStoreSliderCard.propTypes = {
    cardImage: PropTypes.string,
    cardTitle: PropTypes.string,
    isActive: PropTypes.bool,
    isAvailable: PropTypes.bool,
    onClick: PropTypes.func
};

export default VasStoreSliderCard;
