import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    transition: true,
    nudgeText: "Need Help?",
    pathname: "",
    showHelpSupportModal: false,
    showEmailSupportModal: false,
    hideWidgetIcon: false
};

export const setNudgeDetails = (state = INITIAL_STATE, { details = {} }) => {
    return {
        ...state,
        ...details
    };
};

const showHelpSupportModal = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        showHelpSupportModal: data
    };
};

const showEmailSupportModal = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        showEmailSupportModal: data
    };
};

export const HANDLERS = {
    [Types.SET_NUDGE_DETAILS]: setNudgeDetails,
    [Types.SHOW_HELP_SUPPORT_MODAL]: showHelpSupportModal,
    [Types.SHOW_EMAIL_SUPPORT_MODAL]: showEmailSupportModal

};

export default createReducer(INITIAL_STATE, HANDLERS);
