import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

import ORANGE from "./images/orange-circle.svg";
import CAR from "./images/car.svg";
import LOCATION from "./images/location.svg";
import ICON from "./images/icon.svg";
import TICK from "./images/green-tick.svg";
import TICKSEC from "./images/gray-tick.svg";
import { formatDeliveryAddressToString } from "../../../utils/helpers/format-address-object";

const ReservationConfirmTestDrive = ({
    handleNavigateMap,
    yardAddress
}) => {

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.reservation"}>
                <img src={TICK} />
                <h2>Reservation Confirmed</h2>
                <div styleName={"styles.border"} />
            </div>
            <div styleName={"styles.box-border"}>
                <div styleName={"styles.test-drive"}>
                    <div styleName={"styles.heading"}>
                        <p><img src={ORANGE} />Schedule test drive</p>
                        <img src={CAR} />
                    </div>
                    <div styleName={"styles.location"}>
                        <img src={LOCATION} />
                        <a target="_blank" onClick={handleNavigateMap} styleName={"styles.location styles.actionLoc"}>
                            {formatDeliveryAddressToString(yardAddress).addressArray.map(item =>
                                <span key={item}>{item}<br/></span>
                            )}
                        </a>
                        {/* <div>
                            <h4>CARS24 location</h4>
                            <p>40 Steel Place <br />Morningside <br />QLD - 4170</p>
                        </div> */}
                    </div>
                </div>
                <div styleName={"styles.support"}>
                    <img src={ICON} />
                    <p>Our team will call you in the next 2 hours to finalise your test drive date and time</p>
                </div>
            </div>
            <div styleName={"styles.reservation"}>
                <img src={TICKSEC} />
                <h2>Test drive car</h2>
                <div styleName={"styles.border-sec"} />
            </div>
            <div styleName={"styles.reservation"}>
                <img src={TICKSEC} />
                <h2>Purchase</h2>
                <div styleName={"styles.border-sec"} />
            </div>
        </div>
    );
};

ReservationConfirmTestDrive.propTypes = {
    handleNavigateMap: PropTypes.func,
    yardAddress: PropTypes.object
};

export default ReservationConfirmTestDrive;
