/* eslint-disable max-statements */
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { OTP_MAX_LENGTH_EMAIL } from "../../../constants/app-constants";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { validateEmail } from "../../../utils/validators/email-validator";
import Button from "../../shared/button";
import CountdownTimer from "../../shared/countdown-timer";
import InputOTP from "../../shared/input-otp";
import InputText from "../../shared/input-text";
import validEmailTickImg from "./images/success-icon.svg";
import styles from "./styles.css";

const LoginStepTwoOTP = ({
    userEmail,
    otp,
    // onEmailChange,
    onOtpChange,
    handleUserLogin,
    errorMessage,
    setErrorMessage,
    showLoader,
    sendOTPConnect,
    onOtpFocus,
    onOtpFocusOut = () => {},
    otpRef,
    showOtpSupportText = true,
    onResendOtp = () => {}
}) => {
    const [showTimer, setShowTimer] = useState(true);

    const onResendClick = () => {
        setShowTimer(true);
        setErrorMessage("");
        sendOTPConnect(userEmail);
        onResendOtp();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_VERIFY, { eventLabel: "cta selected resend_otp" });
    };

    const onCompleteTimer = () => {
        setShowTimer(false);
    };

    const onBlur = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_VERIFY_OTP);
        onOtpFocusOut();
    };

    const emailRef = useRef();

    const isBtnDisabled = otp.length !== OTP_MAX_LENGTH_EMAIL;

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.header"}>
                <h3 styleName={"styles.heading"}>Verify Your Email ID</h3>
            </div>
            <div className={"mt-3"} styleName={"styles.inputNumber"}>
                <InputText
                    inputRef={emailRef}
                    value={userEmail}
                    text="Email ID"
                    validator={validateEmail}
                    errorMessage="Please enter a valid email!"
                    disabled
                    readOnly
                />
                {validateEmail(userEmail) && <img src={validEmailTickImg} />}
            </div>
            <div styleName={"styles.inputNumber"}>
                {showOtpSupportText &&
                <p styleName={"styles.otpSentText"}>
                    We've sent a verification code to your email, it’s on its way! Please check your inbox and enter the
                    code below
                </p>
                }
                <div styleName={"styles.otpContainer"}>
                    <InputOTP
                        inputRef={otpRef}
                        value={otp}
                        onChange={onOtpChange}
                        text="Enter OTP"
                        onBlurCallback={onBlur}
                        onFocusCallback={onOtpFocus}
                    />
                    {showTimer ? (
                        <div styleName={"styles.otpCountdown"}>
                            <CountdownTimer interval={30} onComplete={onCompleteTimer} />
                        </div>
                    ) : (
                        <span onClick={onResendClick} styleName={"styles.resendButton"}>
                            Resend
                        </span>
                    )}
                </div>
                <span styleName={"styles.error"}>{errorMessage}</span>
            </div>
            <div styleName={"styles.buttonWrap"}>
                <Button text="Verify" onClick={handleUserLogin} isLoading={showLoader} disabled={isBtnDisabled} />
            </div>
        </div>
    );
};

LoginStepTwoOTP.propTypes = {
    userEmail: PropTypes.string,
    otp: PropTypes.string,
    // onEmailChange: PropTypes.func,
    onOtpChange: PropTypes.func,
    handleUserLogin: PropTypes.func,
    errorMessage: PropTypes.string,
    showLoader: PropTypes.bool,
    setErrorMessage: PropTypes.func,
    sendOTPConnect: PropTypes.func,
    onOtpFocus: PropTypes.func,
    onOtpFocusOut: PropTypes.func,
    otpRef: PropTypes.object,
    showOtpSupportText: PropTypes.bool,
    onResendOtp: PropTypes.func
};

export default LoginStepTwoOTP;
