import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCheckoutEditFormExitModal } from "../checkout-info/actions";
import ApplyForFinance from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        checkoutEditMode,
        checkoutEditFormExitModal
    },
    preApprovalLoan: {
        preApporvedOrigin
    }
}) => ({
    content,
    checkoutEditMode,
    checkoutEditFormExitModal,
    preApporvedOrigin
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setCheckoutEditFormExitModalConnect: setCheckoutEditFormExitModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForFinance);
