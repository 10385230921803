/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

import { NUMBER } from "../../../constants/app-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { getAbExpirementVariant} from "../../../utils/helpers/get-ab-expirement-variant";

import usePreBiAssistance from "../../../hooks/use-pre-bi-assistance";

import ChatWidgetRevamp from "../chat-widget-revamp";
import ChatIconRevamp from "../chat-icon-revamp/component";
import { PRE_BI_ASSISTANCE_CATEGORIES } from "../../../config/au.mobile/pre-bi-assistance-config";
import { GA_EVENT_NAMES } from "./tracking";
import { pageSource } from "../../../utils/helpers/page-source";

const HelpSupportModalMobile = loadable(() => import("../help-support-modal/component"));
const Cars24AssistanceModal = loadable(() => import("../cars24-assistance-modal"));
const EmailUsModalMobile = loadable(() => import("../email-us-modal"));
const ChatAssistanceNudge = loadable(() => import("../chat-assistance-nudge"));

const SupportWidget = ({
    showWidget = true,
    supportWidget = {},
    setNudgeDetailsConnect = () => { },
    showHelpSupportModalConnect,
    isSellCarPage = false, ipDetectedState,
    webMobilePreBiAssistance,
    updateSupportWidgetConfigConnect = () => {},
    setAccordionStateConnect = () => {},
    showFinanceCentricListing
}) => {
    const [emailSupportModal, setEmailSupportModal] = useState(false);
    const {renderSupportNudge} = usePreBiAssistance();
    const { transition, showHelpSupportModal, hideWidgetIcon, nudgeDetails: {category = "", heading = ""} = {}, nudgeTimerId } = supportWidget;
    const showPreBiAssistanceVariant = getAbExpirementVariant(webMobilePreBiAssistance, EXPERIMENT_TYPE.VARIANT_B);
    const accordionOpenCategory = category === PRE_BI_ASSISTANCE_CATEGORIES.FINANCE ? PRE_BI_ASSISTANCE_CATEGORIES.FINANCE : PRE_BI_ASSISTANCE_CATEGORIES.BUYER;
    const currentPageSource = pageSource(showFinanceCentricListing);
    useEffect(() => {
        // todo:why is the exp not working ssr
        trackMobileCustomEventsAU(GA_EVENT_NAMES.experiment, {
            ...GA_EVENT_NAMES.experiment,
            eventLabel: webMobilePreBiAssistance?.data
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleHelpModal = (val) => {
        showHelpSupportModalConnect(val);
    };

    const handleWidgetClick = async (val, source) => {
        if (emailSupportModal) return;
        await yieldToMain();
        handleHelpModal(val);
        if (currentPageSource && source === "bubble") {
            trackMobileCustomEventsAU(GA_EVENT_NAMES.assistance_bubble_clicked, {
                ...GA_EVENT_NAMES.assistance_bubble_clicked,
                eventLabel: pageSource(showFinanceCentricListing)

            });
        }

    };

    const handleEmailUsModal = (val) => {
        setEmailSupportModal(val);
        if (!val) {
            trackMobileCustomEventsAU(GA_EVENT_NAMES.assistance_option_selected_type, {
                ...GA_EVENT_NAMES.assistance_option_selected_type,
                eventLabel: "mail"
            });
        }
    };

    useEffect(() => {
        const onTouchMove = () => {
            if (transition) {
                setNudgeDetailsConnect({
                    transition: false
                });
                window.setTimeout(() => {
                    setNudgeDetailsConnect({
                        nudgeText: false
                    });
                }, NUMBER.THOUSAND);
            }
        };

        window.addEventListener("touchmove", onTouchMove);

        return () => {
            window.removeEventListener("touchmove", onTouchMove);
        };
    }, [setNudgeDetailsConnect, transition]);

    const fireSeenEvent = (event, nudgeText) => {
        trackMobileCustomEventsAU(event, {
            ...event,
            eventLabel: nudgeText
        });

        if (currentPageSource) {
            trackMobileCustomEventsAU(event, {
                ...event,
                eventAction: "page_source",
                eventLabel: pageSource(showFinanceCentricListing)
            });
        }
    };

    useEffect(() => {
        if (renderSupportNudge) {
            fireSeenEvent(GA_EVENT_NAMES.assistance_nudge_seen_text, heading);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderSupportNudge]);

    const handleNudgeClose = () => {
        updateSupportWidgetConfigConnect({
            showNudge: false
        });
    };

    const handleNudgeClick = () => {
        handleWidgetClick(!showHelpSupportModal, "nudge");
        setAccordionStateConnect(accordionOpenCategory, true);
        if (nudgeTimerId) {
            window.clearTimeout(nudgeTimerId);
        }
        updateSupportWidgetConfigConnect({
            showNudge: false,
            showAnimation: false
        });
        fireSeenEvent(GA_EVENT_NAMES.assistance_nudge_clicked_text, heading);

    };

    const handleChatIconClick = () => {
        handleWidgetClick(!showHelpSupportModal, "bubble");
        setAccordionStateConnect(PRE_BI_ASSISTANCE_CATEGORIES.BUYER, true);
        updateSupportWidgetConfigConnect({
            showNudge: false,
            showAnimation: false,
            nudgeNotificationCount: 0
        });
    };
    if (!showWidget) return null;

    return (
        showPreBiAssistanceVariant ?
            <React.Fragment>
                {renderSupportNudge ? <ChatAssistanceNudge onClose={handleNudgeClose} onClick={handleNudgeClick} /> : null}
                {hideWidgetIcon ? null : <ChatIconRevamp onClick={handleChatIconClick} supportWidget={supportWidget} />}
                {showHelpSupportModal && <Cars24AssistanceModal isSellCarPage={isSellCarPage} handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} ipDetectedState={ipDetectedState} />}
            </React.Fragment>
            :
            <React.Fragment>
                {hideWidgetIcon ? null : <ChatWidgetRevamp onClick={() => handleWidgetClick(!showHelpSupportModal)} />}
                {showHelpSupportModal && <HelpSupportModalMobile isSellCarPage={isSellCarPage} handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} ipDetectedState={ipDetectedState} />}
                {emailSupportModal && isSellCarPage && <EmailUsModalMobile handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} ipDetectedState={ipDetectedState} />}
            </React.Fragment>
    );
};
export default SupportWidget;

SupportWidget.propTypes = {
    showWidget: PropTypes.bool,
    isSellCarPage: PropTypes.bool,
    supportWidget: PropTypes.object,
    setNudgeDetailsConnect: PropTypes.func,
    showHelpSupportModalConnect: PropTypes.func,
    ipDetectedState: PropTypes.string,
    stateCode: PropTypes.string,
    webMobilePreBiAssistance: PropTypes.object,
    updateSupportWidgetConfigConnect: PropTypes.func,
    setAccordionStateConnect: PropTypes.func,
    showFinanceCentricListing: PropTypes.bool
};
