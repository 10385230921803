import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { getTestDriveCheckoutURL } from "../../../../utils/helpers/get-detail-page-url";
import CheckoutHeader from "../../checkout-header";
import styles from "./styles.css";
import Button from "../../../shared/button";
import TransparentButton from "../../../shared/button/transparent-button";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS, DESKTOP_EVENT_KEY } from "../../../../tracking/au.desktop-events";

// eslint-disable-next-line complexity
const Layout = ({
    children,
    routeData = {},
    showHeader = false,
    showNavigation = false,
    showFooter = true,
    hideNextButton,
    customNextText,
    customBackText,
    nextButtonDisabled,
    nextButtonLoading,
    customCallClick,
    customBack,
    customNext,
    content,
    history,
    isAffiliate,
    backEventLabel,
    nextEventLabel,
    customPriceHeaderLabel
}) => {
    const defaultBack = () => {
        if (backEventLabel) {
            trackDesktopCustomEventsAU(DESKTOP_EVENT_KEY.CHECKOUT_PAGE_BACK, { eventLabel: backEventLabel });
        }
        const { relativeURL: backURL } = getTestDriveCheckoutURL(content, routeData.defaultBack);
        history.push(backURL);
    };

    const defaultNext = () => {
        if (nextEventLabel) {
            trackDesktopCustomEventsAU(DESKTOP_EVENT_KEY.CHECKOUT_PAGE_NEXT, { eventLabel: nextEventLabel });
        }
        const { relativeURL: nextURL } = getTestDriveCheckoutURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const defaultCallClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_HEADER_CALL, {
            eventLabel: `options ${routeData.gtmPageName}`
        });
    };

    return (
        <div
            styleName={"styles.layoutWrapper"}
        >
            {showHeader ? (
                <CheckoutHeader
                    showExtraInfo={showNavigation}
                    onCallClick={customCallClick || defaultCallClick}
                    showPreApprovalPill={routeData.showPreApprovalPill}
                    customPriceHeaderLabel={customPriceHeaderLabel}
                />
            ) : (
                ""
            )}

            {children}

            {showFooter ? (
                <div styleName={`styles.buttonWrapper ${routeData.showPreApprovalPill ? "styles.financeButtonWrapper" : ""}`}>
                    <div styleName={"styles.transparentButton"}>
                        <TransparentButton disabled={isAffiliate && routeData.key === "basicDetails"} text={customBackText || "Back"} onClick={customBack || defaultBack}/>
                    </div>
                    {hideNextButton ? "" :
                        <Button
                            text={customNextText || "Next"}
                            onClick={customNext || defaultNext}
                            disabled={nextButtonDisabled}
                            isLoading={nextButtonLoading && !nextButtonDisabled}
                        />
                    }
                </div>

            ) : (
                ""
            )}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    routeData: PropTypes.object.isRequired,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    hideNextButton: PropTypes.bool,
    customBack: PropTypes.func,
    customNext: PropTypes.func,
    customCallClick: PropTypes.func,
    nextButtonDisabled: PropTypes.bool,
    nextButtonLoading: PropTypes.bool,
    isAffiliate: PropTypes.bool,
    showNavigation: PropTypes.bool,
    content: PropTypes.object,
    history: PropTypes.object,
    customNextText: PropTypes.string,
    customBackText: PropTypes.string,
    backEventLabel: PropTypes.string,
    nextEventLabel: PropTypes.string,
    customPriceHeaderLabel: PropTypes.object
};

export default withRouter(Layout);
