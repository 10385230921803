import React from "react";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import BookingsFooter from "../../bookings-footer";
import GreenIdCheck from "../../green-id-card";
import styles from "./styles.css";
import PropTypes from "prop-types";

import CheckIcon from "./images/check-icon.svg";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { postPaymentRoutes } from "../../../../constants/au.desktop/post-payment-routes";
import { SIGN_CONTRACT_STATUS } from "../../../../constants/checkout-constants";

const VerifyUserLicencey = ({order, signContractData = [], getPaymentSummaryConnect}) => {
    const history = useHistory();
    const { documentStatus } = Array.isArray(signContractData) && signContractData[0] || {};

    const {orderId = ""} = order;

    const handleSkip = () => {
        const {relativeURL} = getMyBookingCarDetailsURL(order.appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
        history.replace(relativeURL);
    };

    React.useEffect(() => {
        if (orderId) {
            getPaymentSummaryConnect(orderId);
        }
    }, [getPaymentSummaryConnect, orderId]);

    return (
        <div className="row">
            <div className="col-lg-7" styleName="styles.wrapper">
                <p styleName={"styles.header"}>Verify your ID</p>
                <div styleName="styles.reserveCarInfo">
                    <img src={CheckIcon} alt="info"/>
                    <div styleName="styles.subHeader">
                        <p>Congratulations on reserving your car!</p>
                        <p>Order isn’t complete until you verify your ID and sign the contract of sale</p>
                    </div>
                </div>
                <GreenIdCheck showVariant={EXPERIMENT_TYPE.VARIANT_B}/>
                {documentStatus !== SIGN_CONTRACT_STATUS.SIGNED && <div onClick={handleSkip} styleName={"styles.skipDls"}>
                    <a styleName={"styles.skipDLText"}>Skip, I do not have an Australian DL</a>
                </div>}

            </div>
            <div className="col-lg-5">
                <div styleName={"styles.getInTouchWrapper"}>
                    <BookingsFooter heading="" subHeading="It takes us some time to confirm your payment with our bank. Your updated payment will reflect here after confirmation, post which you can proceed with paying remaining amount (if pending). Meanwhile please sign your contract and upload your drivers licence." />
                </div>
            </div>
        </div>

    );
};

VerifyUserLicencey.propTypes = {
    order: PropTypes.object,
    signContractData: PropTypes.array,
    getPaymentSummaryConnect: PropTypes.func
};

export default VerifyUserLicencey;
