// eslint-disable-next-line consistent-return
export const getKeyByValue = (object, value) => {
    for (const prop in object) {
        if (object.hasOwnProperty(prop)) {
            if (object[prop] === value) {return prop;}
        }
    }
};

// eslint-disable-next-line consistent-return
export const getObjectByValue = (object, key, value) => {
    for (const prop in object) {
        if (object.hasOwnProperty(prop)) {
            if (object[prop][key] === value) {return object[prop];}
        }
    }
};

