export const getSortData = (sorts) => {
    const sortIds = [];
    const sortData = {};
    (sorts || []).forEach(sort => {
        if (sortIds.indexOf(sort.groupId) === -1) {
            sortIds.push(sort.groupId);
        }
        if (sortData[sort.groupId]) {
            sortData[sort.groupId].options.push({
                key: sort.key,
                displayText: sort.displayName
            });
        } else {
            sortData[sort.groupId] = {
                name: sort.groupName,
                options: [
                    {
                        key: sort.key,
                        displayText: sort.displayName
                    }
                ]
            };
        }
    });

    return {
        sortIds,
        sortData
    };
};
