import { NUMBER } from "../../constants/app-constants";
import { captureException } from "@sentry/react";

export const getSha256Hash = async (value = "") => {
    try {
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", new window.TextEncoder().encode(value));
        const hashArray = Array.from(new Uint8Array(hashBuffer)) || [];
        const hashHex = hashArray.map(b => b.toString(NUMBER.SIXTEEN).padStart(NUMBER.TWO, "0")).join("");
        return hashHex;
    } catch (error) {
        throw error;
    }
};

export const getHashedValueOrNull = async (value) => {
    if (!value) return null;
    try {
        return await getSha256Hash(value);
    } catch (err) {
        captureException(err);
        return null;
    }
};

export const getHashedDetails = async (details = {}) => {
    const hashedDetails = {};

    for (const [key, value] of Object.entries(details)) {
        hashedDetails[key] = await getHashedValueOrNull(value);
    }

    return hashedDetails;
};
