import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
// import { trackAmplitude, trackMobileCustomEventsAU } from "../../../../tracking";
// import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import IconSpinner from "../../../shared/icon-spinner";
import snarkdown from "snarkdown";
// import { AMPLITUDE_EVENTS_CATEGORY } from "../../../../tracking/amplitude-events";

const CarPackagesCardRevamp = ({ ctaText = "ADD", loading = false, showKnowMore = false, bundle, onBundleClick = () => { }, onCtaClick = () => { } }) => {
    const { category = [], discount, discountedPrice, name, repaymentsPerWeek } = bundle || {};

    const onKnowMoreClick = () => {
        // trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
        //     eventAction: "Pack_know_more",
        //     eventLabel: name
        // });
        // trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.PRODUCT_DETAIL_PAGE, {
        //     "Pack_know_more": name
        // });
        onBundleClick();
    };

    return (
        <div styleName={"styles.cardOuter"}>
            <div styleName={"styles.cardTopSection"}>
                <div styleName={"styles.topSectionWrap"}>
                    {discount > 0 &&
                        <div styleName={"styles.stickerWrapper"}>
                            <p styleName={"styles.saveAmount"}>SAVE {makePriceLabelRound(discount)}</p>
                        </div>
                    }
                    <p styleName={"styles.cardHeading"}>{name}</p>
                    <div styleName={"styles.headerPriceWrap"}>
                        <div styleName={"styles.bottomLeftSection"}>
                            <p styleName={"styles.perWeek"}>{makePriceLabelRound(repaymentsPerWeek)}/wk</p>
                            <p styleName={"styles.actualPricePreApproved"}>or {makePriceLabelRound(discountedPrice)}</p>
                        </div>
                        <p onClick={onKnowMoreClick} styleName={"styles.knowMore"}>{showKnowMore ? "Know more" : ""}</p>
                    </div>
                </div>
                {category.map((highlight) => {
                    // eslint-disable-next-line no-shadow
                    const { iconUrl, key, name } = highlight || {};

                    return (
                        <div key={key} styleName={"styles.checklistWrap"}>
                            <img src={iconUrl} />
                            <p styleName={"styles.list"} dangerouslySetInnerHTML={{
                                __html: snarkdown(name || "")
                            }} />
                        </div>
                    );
                })}
            </div>
            <div styleName={"styles.cardBottomSection"}>
                <button styleName={"styles.outerBtn"} disabled={loading} onClick={onCtaClick}>
                    {loading ? <IconSpinner /> : ctaText}
                </button>
            </div>
        </div>
    );
};

export default CarPackagesCardRevamp;

CarPackagesCardRevamp.propTypes = {
    bundle: PropTypes.object,
    ctaText: PropTypes.string,
    loading: PropTypes.bool,
    preApproved: PropTypes.bool,
    onBundleClick: PropTypes.func,
    onCtaClick: PropTypes.func,
    showKnowMore: PropTypes.bool
};
