/* eslint-disable max-params */
export default (api) => {

    const getPickupLocation = (appointmentId, cityCode) => {
        return api.get(`api/inventory/availability/apt-${appointmentId}?cityCode=${cityCode}`);
    };

    const getDeliveryAvailability = (appointmentId, pincode, origincity) => {
        return api.get(`api/inventory/availability/apt-${appointmentId}?pincode=${pincode}&origincity=${origincity}`);
    };

    return {
        getPickupLocation,
        getDeliveryAvailability
    };
};
