import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentHistoryCards from "./component";

const mapStateToProps = ({
    checkout: {order: {splitPayments}, paymentHistoryData}
}) => ({
    splitPayments,
    paymentHistoryData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryCards);
