import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_NUDGE_LIST
    FETCH_NUDGE_LIST_SUCCESS
    FETCH_NUDGE_LIST_FAILURE
    SET_NUDGE_DISPLAY

    CLOSE_NUDGE_LIST
    CLOSE_NUDGE_LIST_SUCCESS
   CLOSE_NUDGE_LIST_FAILURE
`,
    {
        prefix: "nudgecards/"
    }
);
