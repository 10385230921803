import getFiltersData from "../filters-v2/get-filters-data";

const getCategoryKey = (category) => {
    return category ? category.split(" ").join("").toLowerCase() : null;
};

const getSanitizedFilterData = (categoryFilterDetails) => {
    const sanitizedCategory = (categoryFilterDetails || []).reduce((acc, data) => {
        const santitizedFilter = getFiltersData(data.filters);
        acc.push({
            ...data,
            filters: santitizedFilter,
            key: getCategoryKey(data.category),
            label: data.category
        });
        return acc;
    }, []);
    return sanitizedCategory;
};

const getDynamicContent = ({categories = [], selectedCategory = "", dynamicContent = {}}) => {
    const selectedCategoryFilter = categories.filter((data) => getCategoryKey(data.category) === selectedCategory) || null;
    let data = {h1: null, h1Body: null, h2: null, h2Body: null};
    if (selectedCategoryFilter[0] && selectedCategoryFilter[0].categoryFilterDescriptions) {
        data = { ...data,
            h1: selectedCategoryFilter[0].categoryFilterDescriptions[0] ? selectedCategoryFilter[0].categoryFilterDescriptions[0].header : null,
            h1Body: selectedCategoryFilter[0].categoryFilterDescriptions[0] ? selectedCategoryFilter[0].categoryFilterDescriptions[0].body : null,
            h2: selectedCategoryFilter[0].categoryFilterDescriptions[1] ? selectedCategoryFilter[0].categoryFilterDescriptions[1].header : null,
            h2Body: selectedCategoryFilter[0].categoryFilterDescriptions[1] ? selectedCategoryFilter[0].categoryFilterDescriptions[1].body : null
        };
    } else {
        data = {
            ...data, ...dynamicContent
        };
    }
    return data;
};

export {
    getDynamicContent,
    getCategoryKey,
    getSanitizedFilterData
};

