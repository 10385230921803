import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { imageTypes } from "../../../utils/helpers/cdn-image-url";
import { getCarImage } from "../../../utils/helpers/split-payment";

const ExtendedYourProtection = ({config, content, warrantyPlanInfo = {}, largestPlan = {} }) => {
    const {manufacturerWarranty = {}} = warrantyPlanInfo;
    const {  endDate: expiryEndDate, label, warranty, expired, subLabel } = manufacturerWarranty || {};
    const { imageUrl, carFullName } = getCarImage({ config, content, imageProps: imageTypes.auCarDetailThumbnail });
    const {months} = largestPlan;

    return (
        <div styleName={"styles.outer"}>
            <h2>Extend your protection</h2>
            <div styleName={"styles.dFlex"}>
                <div styleName={"styles.imageWrapper"}>
                    <img src={imageUrl} alt={carFullName} />
                </div>
                <div styleName={"styles.warranty"}>
                    <h5>Manufacturer warranty</h5>
                    <p styleName={!expired ? "styles.activeStyle" : ""}>{warranty}</p>
                </div>
            </div>
            { expiryEndDate ?
                <p styleName={"styles.text-one"}>{label}<span> {expiryEndDate}.</span></p>
                : <p styleName={"styles.text-one"}>{label}</p>
            }
            {months && subLabel && <p styleName={"styles.text-one"}>{subLabel.replace("X", months)}</p>}
        </div>
    );
};

ExtendedYourProtection.propTypes = {
    config: PropTypes.object,
    content: PropTypes.object,
    warrantyPlanInfo: PropTypes.object,
    largestPlan: PropTypes.object
};

export default ExtendedYourProtection;
