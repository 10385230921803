import Types from "./types";
import { showToast } from "../../shared/toast-message/actions";
import { VasService } from "../../../service";
import { updateChargeDetails } from "../checkout-info/actions";
import { SERVICING_VARIANT_MAPPING } from "../../../constants/app-constants";
import { getIsNewServicingVariant } from "../../../utils/statsig-ab-utils/get-experiment-data";

const getVASBundlesRequest = () => ({
    type: Types.GET_VAS_BUNDLES_REQUEST
});

const getVASBundlesSuccess = (data) => ({
    type: Types.GET_VAS_BUNDLES_SUCCESS,
    data
});

const getVASBundlesFailure = (error) => ({
    type: Types.GET_VAS_BUNDLES_FAILURE,
    error
});

const getVASBundles = (year, price) =>
    async (dispatch, getState) => {
        dispatch(getVASBundlesRequest());
        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment,
                        tag
                    } = {}
                } = {}
            },
            checkout: { order = {} } = {}
        } = getState();
        try {
            const configData = { experiment, tag };
            const response = await VasService.getVASBundles({
                token: secureToken,
                year,
                price,
                experiment: configData.experiment,
                tag: configData.tag,
                orderId: order?.orderId
            });
            dispatch(getVASBundlesSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASBundlesFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const getVASItemsRequest = () => ({
    type: Types.GET_VAS_ITEMS_REQUEST
});

const getVASItemsSuccess = (data) => ({
    type: Types.GET_VAS_ITEMS_SUCCESS,
    data
});

const getVASItemsFailure = (error) => ({
    type: Types.GET_VAS_ITEMS_FAILURE,
    error
});

const getVASItems = (key, year, price) =>
    async (dispatch, getState) => {
        dispatch(getVASItemsRequest());
        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment,
                        tag
                    }
                }
            }
        } = getState();
        try {
            const response = await VasService.getVASItems({ token: secureToken, key, year, price, experiment, tag });
            dispatch(getVASItemsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASItemsFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const getVASCartRequest = () => ({
    type: Types.GET_VAS_CART_REQUEST
});

const getVASCartSuccess = (data) => ({
    type: Types.GET_VAS_CART_SUCCESS,
    data
});

const getVASCartFailure = (error) => ({
    type: Types.GET_VAS_CART_FAILURE,
    error
});

const getVASCart = ({ orderId, year, price, vasPageName }) =>
    async (dispatch, getState) => {
        dispatch(getVASCartRequest());
        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment
                    }
                }
            }
        }  = getState();
        try {
            const isNewServicingVariant = getIsNewServicingVariant(experiment);
            const screenName = isNewServicingVariant ? vasPageName : undefined;
            const response = await VasService.getVASCart({token: secureToken, orderId, year, price, vasPageName: screenName});
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASCartFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const setCounterAnimation = (data) => ({
    type: Types.SET_COUNTER_ANIMATION,
    data
});

const addVASItemRequest = (data) => ({
    type: Types.ADD_VAS_ITEM_REQUEST,
    data
});

const addVASItemSuccess = (data) => ({
    type: Types.ADD_VAS_ITEM_SUCCESS,
    data
});

const addVASItemFailure = (error) => ({
    type: Types.ADD_VAS_ITEM_FAILURE,
    error
});

const addVASItem = ({ orderId, type, key, year, price, vasPageName }) =>
    async (dispatch, getState) => {
        dispatch(addVASItemRequest(key));
        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment
                    }
                }
            }
        } = getState();
        try {
            const isNewServicingVariant = experiment === SERVICING_VARIANT_MAPPING.VARIANT;
            const screenName = isNewServicingVariant ? vasPageName : undefined;
            const response = await VasService.addVASItem({ token: secureToken, orderId, type, key, year, price, vasPageName: screenName });
            dispatch(addVASItemSuccess(response.data));
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(addVASItemFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const removeVASItemRequest = (data) => ({
    type: Types.REMOVE_VAS_ITEM_REQUEST,
    data
});

const removeVASItemSuccess = (data) => ({
    type: Types.REMOVE_VAS_ITEM_SUCCESS,
    data
});

const removeVASItemFailure = (error) => ({
    type: Types.REMOVE_VAS_ITEM_FAILURE,
    error
});

const removeVASItem = ({ orderId, type, key, year, price, skipLoad = false, vasPageName }) =>
    async (dispatch, getState) => {
        dispatch(removeVASItemRequest(skipLoad ? null : key));

        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment
                    }
                }
            }
        } = getState();
        try {
            const isNewServicingVariant = experiment === SERVICING_VARIANT_MAPPING.VARIANT;
            const screenName = isNewServicingVariant ? vasPageName : undefined;
            const response = await VasService.removeVASItem({
                token: secureToken,
                orderId,
                type,
                key,
                year,
                price,
                vasPageName: screenName
            });
            dispatch(removeVASItemSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            dispatch(getVASCartSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(removeVASItemFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const clearVASCartRequest = () => ({
    type: Types.CLEAR_VAS_CART_REQUEST
});

const clearVASCartSuccess = (data) => ({
    type: Types.CLEAR_VAS_CART_SUCCESS,
    data
});

const clearVASCartFailure = (error) => ({
    type: Types.CLEAR_VAS_CART_FAILURE,
    error
});

const clearVASCart = (orderId, vasPageName) =>
    async (dispatch, getState) => {
        dispatch(clearVASCartRequest());

        const {
            user: { secureToken }
        } = getState();
        try {
            const response = await VasService.clearVASCart({ token: secureToken, orderId, vasPageName });
            dispatch(clearVASCartSuccess(response.data));
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(clearVASCartFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const setAddonScreenType = (data) => ({
    type: Types.SET_ADDONS_SCREEN_TYPE,
    data
});

const vasTracker = (orderId, action) =>
    async (dispatch, getState) => {
        const {
            user: { secureToken }
        } = getState();
        try {
            const response = await VasService.vasTracker(secureToken, orderId, action);
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const getVASProductDetailRequest = () => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_REQUEST
});

const getVASProductDetailSuccess = (data) => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_SUCCESS,
    data
});

const getVASProductDetailFailure = (error) => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_FAILURE,
    error
});

const getVASProductDetail = ({ key, carYear, type, carPrice }) =>
    async (dispatch, getState) => {
        dispatch(getVASProductDetailRequest());
        const {
            user: { secureToken }
        } = getState();
        try {
            const response = await VasService.getVASProductDetail({ token: secureToken, key, carYear, type, carPrice });
            dispatch(getVASProductDetailSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASProductDetailFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const getServicingPriceConfigRequest = () => ({
    type: Types.GET_SERVICING_PRICE_CONFIG_REQUEST
});

const getServicingPriceConfigSuccess = (data) => ({
    type: Types.GET_SERVICING_PRICE_CONFIG_SUCCESS,
    data
});

const getServicingPriceConfigFailure = (error) => ({
    type: Types.GET_SERVICING_PRICE_CONFIG_FAILURE,
    error
});

const getCarCoverBundleRequest = () => ({
    type: Types.GET_CAR_COVER_BUNDLES_REQUEST
});
const getCarCoverBundleSuccess = (data) => ({
    type: Types.GET_CAR_COVER_BUNDLES_SUCCESS,
    data
});
const getCarCoverBundleFailure = (error) => ({
    type: Types.GET_CAR_COVER_BUNDLES_FAILURE,
    error
});

const getServicingPriceConfig = (appointmentId, pincode, servicingPricingExperiment = "OLD") =>
    async (dispatch, getState) => {
        dispatch(getServicingPriceConfigRequest());
        const {
            user: { secureToken }
        } = getState();
        try {
            const response = await VasService.validateServicingPriceExperiment({ token: secureToken, appointmentId, pincode, servicingPricingExperiment });
            dispatch(getServicingPriceConfigSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getServicingPriceConfigFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

const getCarCoverBundles = () =>
    async (dispatch, getState) => {
        dispatch(getCarCoverBundleRequest());
        const {
            user: { secureToken },
            addOns: {
                servicingPriceConfig: {
                    data: {
                        experiment,
                        tag
                    }
                }
            },
            tradeIn: {
                tradeInData: {
                    tradeIn
                }
            },
            checkout: {
                order: {
                    orderId,
                    appointmentId
                }
            }
        } = getState();
        try {
            const response = await VasService.getCarCoverBundle({ token: secureToken, appointmentId, servicingPricingExperiment: experiment, tradeIn, orderId, tag });
            dispatch(getCarCoverBundleSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getCarCoverBundleFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle" }));
            return Promise.reject(error);
        }
    };

export {
    getVASBundles,
    getVASItems,
    addVASItem,
    removeVASItem,
    getVASCart,
    clearVASCart,
    setAddonScreenType,
    setCounterAnimation,
    vasTracker,
    getVASProductDetail,
    getServicingPriceConfig,
    getCarCoverBundles
};
