/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import { NUMBER } from "../../constants/app-constants";
import { B2cFinanceService } from "../../service";
import { trackDesktopCustomEventsAU, trackMobileCustomEventsAU } from "../../tracking";
import { AU_DESKTOP_EVENTS } from "../../tracking/au.desktop-events";
import { AU_MOBILE_EVENTS } from "../../tracking/au.mobile-events";

const isCallbackRequestExpired = (callbackDate = null) => {
    if (!callbackDate) return true;
    return new Date(callbackDate).getTime() < new Date().getTime();
};

const getFinalIncome = (income, frequency) => {
    let finalIncome = +income || 0;

    if (frequency) {
        if (frequency === "weekly") finalIncome *= 52.14;
        if (frequency === "fortnightly") finalIncome *= 26.0714;
        if (frequency === "monthly") finalIncome *= NUMBER.TWELVE;
    }

    return +finalIncome;
};

const getAnnualIncome = (income, frequency) => {
    let finalIncome = +income || 0;

    if (frequency) {
        if (frequency === "weekly") finalIncome = finalIncome * NUMBER.FOUR * NUMBER.TWELVE;
        if (frequency === "fortnightly") finalIncome = finalIncome * NUMBER.TWO * NUMBER.TWELVE;
        if (frequency === "monthly") finalIncome *= NUMBER.TWELVE;
    }

    return +finalIncome;
};

const getNudgeIdentifier = ({finalGrossIncome, finalMortgageAmount, grossIncomeDuration, mortgageOrRentPaymentDuration, hemExpenses, id}) => {
    let grossIncomeIdentifier = "";

    if (grossIncomeDuration) {
        if (["grossIncome", "grossIncomeDuration"].includes(id) && finalGrossIncome < hemExpenses) {
            grossIncomeIdentifier = "GROSS_INCOME_LESS_THAN_EXPENSES";
        } else if (["grossIncome", "grossIncomeDuration"].includes(id) && finalGrossIncome > NUMBER.FIFTY_LAKH) {
            grossIncomeIdentifier = "GROSS_INCOME_GREATER_THAN_5000000";
        } else if (["grossIncome", "grossIncomeDuration"].includes(id) && finalGrossIncome < NUMBER.TWENTY_THOUSAND) {
            grossIncomeIdentifier = "GROSS_INCOME_LESS_THAN_20000";
        } else if (["mortgageOrRentPayment", "mortgageOrRentPaymentDuration"].includes(id) && mortgageOrRentPaymentDuration && finalMortgageAmount > finalGrossIncome) {
            grossIncomeIdentifier = "RENT_GREATEN_THAN_GROSS_INCOME";
        } else if (["mortgageOrRentPayment", "mortgageOrRentPaymentDuration"].includes(id) && mortgageOrRentPaymentDuration && (hemExpenses + finalMortgageAmount) > finalGrossIncome) {
            grossIncomeIdentifier = "RENT_AND_HEM_GREATEN_THAN_GROSS_INCOME";
        } else {
            grossIncomeIdentifier = "";
        }
    }
    return grossIncomeIdentifier;
};

const getHemExpenses = async ({secureToken, relationshipStatus, annualIncome, dependants, postCode}) => {
    let hemExpenses = 0;
    try {
        const {data} = await B2cFinanceService.getHemExpenses({
            token: secureToken,
            ...relationshipStatus && {relationshipStatus: String(relationshipStatus).toUpperCase()},
            annualIncome,
            dependants,
            pincode: +postCode
        });
        hemExpenses = (data || {}).hemExpenses;
    } catch (err) {
        //console.log(err);
    } finally {
        return hemExpenses * NUMBER.TWELVE;
    }
};

const setNudgeInfoBox = async ({ defaultIdentifier, grossIncome, mortgageOrRentPayment, grossIncomeDuration, mortgageOrRentPaymentDuration, hemExpenses, metaData, setInfoBox = () => {}, id, device = ""}) => {
    // CONSIDERING MONTH AS FINAL BASE
    const finalGrossIncome = getFinalIncome(grossIncome, grossIncomeDuration);
    const finalMortgageAmount = getFinalIncome(mortgageOrRentPayment, mortgageOrRentPaymentDuration);
    const identifier = getNudgeIdentifier({ finalGrossIncome, finalMortgageAmount, grossIncomeDuration, mortgageOrRentPaymentDuration, hemExpenses, id });

    if (!identifier) {
        const { additionalInfo = {} } = metaData[defaultIdentifier] || {};
        if (device === "msite") {
            setInfoBox(additionalInfo, defaultIdentifier);
            window.setTimeout(() => {
                setInfoBox(additionalInfo, defaultIdentifier);
            }, 0);
        } else {
            setInfoBox({ additionalInfo, identifier: defaultIdentifier });
            window.setTimeout(() => {
                setInfoBox({ additionalInfo, identifier: defaultIdentifier });
            }, 0);
        }
    } else {
        const { infoNote } = metaData[identifier] || {};
        if (device === "msite") {
            setInfoBox({description: [infoNote], error: true});
            window.setTimeout(() => {
                setInfoBox({description: [infoNote], error: true});
            }, 0);
        } else {
            setInfoBox({
                additionalInfo: {
                    description: [infoNote || ""]
                },
                identifier: identifier || defaultIdentifier,
                error: true
            });
            window.setTimeout(() => {
                setInfoBox({
                    additionalInfo: {
                        description: [infoNote || ""]
                    },
                    identifier: identifier || defaultIdentifier,
                    error: true
                });
            }, 0);
        }

    }
};

const trackNudgeEvents = ({ hemExpenses, finalGrossIncome, finalMortgageAmount, device = "desktop" }) => {
    let props = {};

    if (hemExpenses > finalGrossIncome) {
        props = {
            eventAction: "Nudge",
            eventLabel: "Income_lesser_expenses"
        };
    } else if (finalGrossIncome > NUMBER.FIFTY_LAKH) {
        props = {
            eventAction: "Nudge",
            eventLabel: "Income_greater_threshold"
        };
    } else if (finalGrossIncome < NUMBER.TWENTY_THOUSAND) {
        props = {
            eventAction: "Nudge",
            eventLabel: "Income_lesser_threshold"
        };
    } else if (+finalMortgageAmount > +finalGrossIncome) {
        props = {
            eventAction: "Nudge",
            eventLabel: "Income_lesser_rent"
        };
    }

    if (device === "desktop") {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW, props);
    } else {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_APPROVAL_FLOW, props);
    }

};

export  {
    isCallbackRequestExpired,
    getFinalIncome,
    getAnnualIncome,
    getNudgeIdentifier,
    setNudgeInfoBox,
    getHemExpenses,
    trackNudgeEvents
};
