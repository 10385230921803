/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios from "axios";
import {
    appUrl,
    // apiUrl,
    c2bAuthKey,
    c2bKeys,
    vehicleApiUrl} from "../constants/url-constants";
// import { apiUrl, c2bAuthKey } from "../constants/url-constants";
import { captureException } from "@sentry/react";

const DEBUG = process.env.NODE_ENV === "development2";

// const BASE_API_URL = process.env.HOST_ENV === "QA" ? `${apiUrl()}/` : `${appUrl()}/svc/`;
const BASE_API_URL = `${appUrl()}/svc/`;
const VEHICLE_SERVICE_BASE_API_URL = `${vehicleApiUrl()}/`;

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${c2bAuthKey()}`,
        "X-Application-Type": "React" // Added for php api to identify requesting application
    },
    withCredentials: true
});

api.interceptors.request.use((config) => {
    if (config.isMigrated && (process.env.HOST_ENV === "QA" || process.env.HOST_ENV === "STAGING" || process.env.HOST_ENV === "PRODUCTION")) {
        config.baseURL = VEHICLE_SERVICE_BASE_API_URL;
        if (config.headers && config.headers["X-Application-Type"]) {
            delete config.headers["X-Application-Type"];
        }
        if (process.env.HOST_ENV === "STAGING") {
            config.headers.Authorization = `Basic ${c2bKeys.QA}`;
        }
        config.withCredentials = false;
    }
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) { console.debug("✉️ ", config); }
    return config;
}, (error) => {
    if (DEBUG) { console.error("✉️ ", error); }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) { console.debug("📩 ", response); }
    return response;
}, (error) => {
    captureException(error);
    if (error.response && error.response.data) {
        if (DEBUG) { console.error("Error: ", error.response.data); }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) { console.error("📩 ", error); }
    return Promise.reject(error);
});

export default api;
