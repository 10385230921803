/* eslint-disable max-statements */
/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import Types from "./types";
import { CheckoutService, CheckoutDrivaService, UserAuthService, ThirdPartyService, CheckoutTradeInService, ListingServiceAU, B2cConsumerFinanceService, SignContractService, PaymentServiceAu, BffPaymentService, BffDataAU, GreenIdService, B2cFinanceService, ListingBaseService, C2bTradeService, VasService} from "../../../service";
import { AUTH_ADDRESS, X_VEHICLE_TYPE } from "../../../constants/api-constants";
import { formatDeliveryAddressToDrivaAddress } from "../../../utils/helpers/format-address-object";
import { getPreApprovedPayload } from "../../../utils/helpers/checkout-util";
import { FINANCE_CYCLE, FINANCE_TYPE, ORDER_STATUS, REQUEST_CALLBACK_SOURCE, VEHICLE_STATE } from "../../../constants/checkout-constants";
import { API_SOURCE, NUMBER, resetReducerKeys, EXTENDEDWARRANTY, REFERRER_SOURCE, CAR_LIST_EVENT_NAMES, ORDER_TYPE } from "../../../constants/app-constants";
import { getCarPrice, getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";
import { getLoanDepositAmount } from "../../../utils/helpers/get-loan-deposit-amount";
import { showToast } from "../../shared/toast-message/actions";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { getSum } from "../../../utils/helpers/split-payment";
import { fetchCarDetails } from "../car-details/actions";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { resetUnapplicableFinanceParameters } from "../../../utils/helpers/reset-unapplicable-finance-parameters";
import carListingEventDataParser from "../../../tracking/parser/car-listing";
import carListingEventDataParserGA4, { parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";
import { trackCustomReMarketingEvents, trackDesktopCustomEventsAU, desktopCleverTapEvent } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { MARKETING_EVENTS_CONSTANTS } from "../../../tracking/marketing-page-events";
import { setProfileDetails } from "../../au.configuration/actions";
import clevertapDetails from "../../../tracking/parser/clevertap-details";
import { CLEVERTAP_EVENT_MAP } from "../../../tracking/au.desktop-clevertap";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { STATES_AU } from "../../../au-c2b/constants/app-constants";
import { AMPLITUDE_EVENT_NAMES, GA_EVENTS } from "./tracking";
import { emitEventNames } from "../../../constants/event-constants";
import { setTradeInToggleSelected } from "../trade-in-your-car-wrapper/actions";

const populateCheckoutPersonalData = (userData) => ({
    type: Types.POPULATE_CHECKOUT_PERSONAL_DATA,
    userData
});
const populateEmailFromToken = (userData) => ({
    type: Types.POPULATE_EMAIL_FROM_TOKEN,
    userData
});

const setUserPersonalDetails = (personalDetails) => ({
    type: Types.SET_USER_PERSONAL_DETAILS,
    personalDetails
});

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanDetails = (data) => {
    return {
        type: Types.SET_LOAN_DETAILS,
        newData: data
    };
};

const setLoanType = (data) => {
    return {
        type: Types.SET_LOAN_TYPE,
        newData: data
    };
};

const setFinanceSelectedCycle = (data) => {
    return {
        type: Types.SET_FINANCE_SELECTED_CYCLE,
        newData: data
    };
};

const setPostFinanceScreen = (data) => {
    return {
        type: Types.SET_POST_FINANCE_SCREEN,
        newData: data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setCheckoutEditMode = (data) => {
    return {
        type: Types.SET_CHECKOUT_EDIT_MODE,
        data
    };
};

const setCheckoutEditCompletedStages = (data) => {
    return {
        type: Types.SET_CHECKOUT_EDIT_COMPLETED_STAGES,
        data
    };
};

const setLoanOfferScreen = (data) => {
    return {
        type: Types.SET_LOAN_OFFER_SCREENS,
        data
    };
};

const setCheckoutEditFormExitModal = (data) => {
    return {
        type: Types.SET_CHECKOUT_EDIT_FORM_EXIT_MODAL,
        data
    };
};

const setCheckoutEditQuestionsUpdateModal = (data, viewed = false, modalType) => {
    return {
        type: Types.SET_CHECKOUT_EDIT_QUESTIONS_UPDATE_MODAL,
        data,
        viewed,
        modalType
    };
};

const setCheckoutEditFormData = (data) => ({
    type: Types.SET_CHECKOUT_EDIT_FORM_DATA,
    data
});

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setSelectedQuote = (data) => ({
    type: Types.SET_SELECTED_QUOTE,
    data
});

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const getOrderDetailInit = (data) => ({
    type: Types.GET_ORDER_DETAIL_INIT,
    isLoading: data
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const getOrderDetail = (appointmentId, withLoader = true) => (dispatch, getState) => {
    dispatch(getOrderDetailInit(withLoader));
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, secureToken)
            .then(response => {
                const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
                dispatch(getOrderDetailSuccess({...response.data, clientId}));
                dispatch(setDeliveryMode({deliveryMode: response.data.deliveryMode}));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderRequest = () => ({
    type: Types.CREATE_ORDER_REQUEST
});

const createOrderSuccess = (data) => ({
    type: Types.CREATE_ORDER_SUCCESS,
    data
});

const createOrderFailure = (error) => ({
    type: Types.CREATE_ORDER_FAILURE,
    error
});

const createOrder = (params = {}) => (dispatch, getState) => {
    dispatch(createOrderRequest());
    const {
        user: { secureToken, gaId, affiliateSource: source, isAffiliate, userGeoData: {postalCode = ""} },
        carDetails: { content, config },
        filters: {allFilters, appliedFilters, selectedSort, listingType },
        abExperiment: {showTestDriveV2}
    }  = getState();
    const refSource = parseCookie(REFERRER_SOURCE) || "origin";
    const customHeader = {
        referrerSource: isAffiliate ? source : refSource,
        pincode: postalCode
    };
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder({...params, clientId: gaId || clientId}, secureToken, API_SOURCE.WEB_APP, customHeader)
            .then(response => {
                dispatch(createOrderSuccess({...response.data, clientId}));
                const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);
                const data = carListingEventDataParser(content, {
                    list: CAR_LIST_EVENT_NAMES.DETAILS,
                    orderId: response.data.orderId,
                    excludedKeys: [
                        "list"
                    ]
                });
                const dataGA4 = carListingEventDataParserGA4(content, {
                    filterDimensions: filterDimensionsGA4,
                    listingType
                });

                const {features: CAR_USP} = content?.overview;

                trackDesktopCustomEventsAU("clearEccomerce", { ecommerce: null });
                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.BEGIN_CHECKOUT, {
                    ...dataGA4
                });
                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.START_PURCHASE, data.startPurchase);
                trackCustomReMarketingEvents(MARKETING_EVENTS_CONSTANTS.START_PURCHASE, {
                    ecomm_prodid: response.data.appointmentId,
                    ecomm_totalvalue: response.data.vehiclePrice
                });

                const {appointmentId} = content;
                if (CAR_USP.length) {
                    CAR_USP.forEach((usp) => {
                        trackDesktopCustomEventsAU(GA_EVENTS.BOOKING_INITATED_USP, {
                            ...GA_EVENTS.BOOKING_INTIATED_DETAIL,
                            "event_label": `${usp?.title}|${appointmentId}`
                        });
                    });
                }

                //cleverTap events
                const biEvents = clevertapDetails({...content, orderId: response.data.orderId}, {prefix: "bi_", config});
                desktopCleverTapEvent(CLEVERTAP_EVENT_MAP.BOOKING_INITIATED, biEvents);

                //Amplitude BI EVENTS
                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CAR_DETAILS_ATF_INTERACTIONS, {eventLabel: "selected get_started"});
                resolve(response.data);

            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
});

const fetchFinance = (vehiclePrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchFinanceDetail(vehiclePrice)
            .then(response => {
                const { data } = response;
                dispatch(setFinanceData(data));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const updateFinancePersonalDetail = (params, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateFinanceDetailRequest = () => ({
    type: Types.UPDATE_FINANCE_DETAILS
});
const updateFinanceDetailSuccess = (data) => ({
    type: Types.UPDATE_FINANCE_DETAILS_SUCCESS,
    data
});
const updateFinanceDetailFailure = (error) => ({
    type: Types.UPDATE_FINANCE_DETAILS_FAILURE,
    error
});

const updateFinanceDetail = (params = {}) => (dispatch, getState) => {
    dispatch(updateFinanceDetailRequest());
    const {
        user: { secureToken, gaId },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId};
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail({ ...order, ...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateFinanceDetailSuccess({...response.data, clientId: gaId || clientId}));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateFinanceDetailFailure(error));
                reject(error);
            });
    });
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((response) => {
            const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
            dispatch(updateDeliveryModeSuccess({...response.data, clientId}));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const initiateOrderPaymentRequest = (paymentPayload) => ({
    type: Types.INITIATE_ORDER_PAYMENT_REQUEST,
    paymentPayload
});

const initiateOrderPayment = (orderId, payload, payableAmount) => (dispatch, getState) => {
    dispatch(initiateOrderPaymentRequest({...payload, payableAmount}));
    const {
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        BffPaymentService.initiateOrderPayment(orderId, payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getStripePublicKey = () => (dispatch, getState) => {
    const {
        user: {
            secureToken
        }
    } = getState();

    return new Promise((resolve, reject) => {
        PaymentServiceAu.getStripePublicKey(secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatusRequest = () => ({
    type: Types.GET_PAYMENT_STATUS
});

const getPaymentStatus = (orderId) => (dispatch, getState) => {
    dispatch(getPaymentStatusRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const cancelOrder = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cancelOrder(orderId, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
    });
};

const softResetCheckoutData = () => dispatch => {
    dispatch({ type: Types.SOFT_RESET_CHECKOUT });
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CHECKOUT });
};

// const updateVisitedSteps = (step) => ({
//     type: Types.UPDATE_VISITED_STEPS,
//     step
// });

const setMaxStep = (maxStep) => ({type: Types.SET_MAX_STEP, maxStep});

const sendMobileOTP = () => (dispatch, getState) => {
    const {
        checkout: {
            personalDetails: { phone }
        }
    } = getState();

    return new Promise((resolve, reject) => {
        UserAuthService.sendOTP(phone, AUTH_ADDRESS.PHONE)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

// BYOF Actions start
const getByoLoanInit = () => ({
    type: Types.GET_BYO_LOAN_INIT
});

const getByoLoanSuccess = (data) => ({
    type: Types.GET_BYO_LOAN_SUCCESS,
    data
});

const getByoLoanFailure = (error) => ({
    type: Types.GET_BYO_LOAN_FAILURE,
    error
});

const getByoLoan = (params = {}) => (dispatch, getState) => {
    dispatch(getByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId} = order || {};
    return new Promise((resolve, reject) => {
        B2cConsumerFinanceService.getBYODetails(secureToken, orderId).then(response => {
            dispatch(getByoLoanSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getByoLoanFailure(error));
            reject(error);
        });
    });
};

const updateByoLoanInit = () => ({
    type: Types.UPDATE_BYO_LOAN_INIT
});

const updateByoLoanSuccess = (data) => ({
    type: Types.UPDATE_BYO_LOAN_SUCCESS,
    data
});

const updateByoLoanFailure = (error) => ({
    type: Types.UPDATE_BYO_LOAN_FAILURE,
    error
});

const updateByoLoan = (params = {}) => async (dispatch, getState) => {
    dispatch(updateByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId, appointmentId} = order || {};
    try {
        const response = await B2cConsumerFinanceService.updateBYODetails(secureToken, {...params, orderId, appointmentId});
        dispatch(updateByoLoanSuccess(response.data));
        Promise.resolve(response.data);
    } catch (error) {
        // eslint-disable-next-line no-magic-numbers
        if (error.errorCode === NUMBER.THOUSAND_THREE) {
            const response =  await B2cConsumerFinanceService.addBYODetails(secureToken, {...params, orderId, appointmentId});
            dispatch(updateByoLoanSuccess(response.data));
            Promise.resolve(response.data);
        } else {
            dispatch(updateByoLoanFailure(error));
            Promise.reject(error);
        }

    }
};

// const addByoLoan = (params = {}) => (dispatch, getState) => {
//     dispatch(updateByoLoanInit());
//     const data = getPreApprovedPayload(params, getState());
//     return new Promise((resolve, reject) => {
//         B2cConsumerFinanceService.addBYODetails(data.payload).then(response => {
//             dispatch(updateByoLoanSuccess(response.data));
//             resolve(response.data);
//         }).catch(error => {
//             dispatch(updateByoLoanFailure(error));
//             reject(error);
//         });
//     });
// };

// BYOF Actions ends
const getLoanOfferDetailsRequest = () => ({
    type: Types.GET_LOAN_OFFER_REQUEST
});

const getLoanOfferDetailsSuccess = (data, isInHouseFinance, isUserZeroDpVariant) => ({
    type: Types.GET_LOAN_OFFER_SUCCESS,
    data,
    isInHouseFinance,
    isUserZeroDpVariant
});

const getEmptyLoanOfferDetailsSuccess = () => ({
    type: Types.GET_EMPTY_LOAN_OFFER_SUCCESS
});
const getLoanOfferDetailsFailure = (error) => ({
    type: Types.GET_LOAN_OFFER_FAILURE,
    error
});

const getLoanOfferDetails = () =>
    // eslint-disable-next-line max-statements
    async  (dispatch, getState) => {
        dispatch(getLoanOfferDetailsRequest());
        const {
            user: { secureToken, isGuestLogin, isUserZeroDpVariant },
            checkout: {
                order: { orderId, status},
                financeData,
                inHouseFinanceEligibilityVariant
            }
        } = getState();

        try {
            let response;
            if (inHouseFinanceEligibilityVariant) {
                response = await B2cFinanceService.getInHouseLoanOfferDetails(orderId, secureToken);
            } else if (status === ORDER_STATUS.CREATED) {
                response = await CheckoutDrivaService.getLoanOfferDetails(orderId, secureToken);
            } else {
                response =  await CheckoutDrivaService.getCurrentOrderLoan(secureToken, orderId);
            }
            if (response.data !== "") {
                if (isGuestLogin  && response.data.quotesAvailable) {
                    dispatch(getLoanOfferDetailsSuccess({ ...response.data, quotesAvailable: true }, inHouseFinanceEligibilityVariant, isUserZeroDpVariant));
                } else {
                    dispatch(getLoanOfferDetailsSuccess(response.data, inHouseFinanceEligibilityVariant, isUserZeroDpVariant));
                }
            } else {
                dispatch(getEmptyLoanOfferDetailsSuccess());
            }

            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getLoanOfferDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const createLoanOfferRequest = () => ({
    type: Types.CREATE_LOAN_OFFER_REQUEST
});

const createLoanOfferSuccess = (data) => ({
    type: Types.CREATE_LOAN_OFFER_SUCCESS,
    data
});

const createLoanOfferFailure = (error) => ({
    type: Types.CREATE_LOAN_OFFER_FAILURE,
    error
});

const createLoanOffer = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createLoanOfferRequest());
        const {
            user: {
                secureToken,
                firstName,
                middleName,
                lastName,
                mobile,
                email = ""
            },
            checkout: {
                financeData,
                egcData,
                tradeinOpted,
                tradeInData,
                promoCode,
                order: {
                    orderId,
                    deliveryAddress,
                    chargeDetails,
                    deliveryMode
                },
                inHouseFinanceEligibilityVariant
            },
            preApprovalLoan: {
                financeData: preApprovalFinanceData
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price,
                    personalised
                }
            },
            user: {
                isUserZeroDpVariant
            },
            deliveryInfo
        } = getState();
        // const address = formatDeliveryAddressToDrivaAddress(deliveryAddress);
        const { offeredPrice, expired, accepted } = tradeInData || {};
        const { shippingPrice, pickupCharges } = deliveryInfo || {};
        const {discountPrice = 0} = promoCode;
        const { totalDap, tradeInPrice } = getCarPrice({ egcData, offeredPrice, shippingPrice, deliveryMode, pickupCharges }) || {};
        const { drivaConfig = {} } = getTradePriceLabel({egcData, tradeInData, tradeinOpted, deliveryInfo, chargeDetails, discountPrice, isUserZeroDpVariant});
        let driveAwayPrice = (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price);
        const preApprovalloanDetails = getLoanDepositAmount(driveAwayPrice, preApprovalFinanceData);
        driveAwayPrice  += getSum(chargeDetails);
        const financeDataUpdated = {
            ...(personalised ? preApprovalFinanceData : financeData),
            submitted: true,
            selectedCycle: financeData.selectedCycle || FINANCE_CYCLE.WEEKLY,
            driveAwayPrice: (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price),
            loan: personalised ? preApprovalloanDetails : {
                ...(financeData.loan || {}),
                deposit: drivaConfig.min
            },
            user: {
                ...(personalised ? preApprovalFinanceData.user : financeData.user),
                email,
                firstName,
                middleName,
                lastName,
                mobile
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: "used",
                vehicleYear: year,
                warrantyFinanced: !!(chargeDetails || []).find(item => item.key === EXTENDEDWARRANTY)
            }
        };
        let payload = { ...financeDataUpdated, ...params, appointmentId, financeType: inHouseFinanceEligibilityVariant ? FINANCE_TYPE.CARS24 : FINANCE_TYPE.DRIVA_FINANCE, selectedCycle: inHouseFinanceEligibilityVariant ? financeData.selectedCycle : (financeData.selectedCycle || FINANCE_CYCLE.WEEKLY) };
        payload = resetUnapplicableFinanceParameters(payload);
        return new Promise((resolve, reject) => {
            if (inHouseFinanceEligibilityVariant) {
                B2cFinanceService.createOrUpdateInHouseLoanOffer(payload, orderId, secureToken).then((response) => {
                    dispatch(createLoanOfferSuccess(response.data, inHouseFinanceEligibilityVariant));
                    resolve(response.data);
                })
                    .catch((error) => {
                        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                        dispatch(createLoanOfferFailure(error));
                        reject(error);
                    });
            } else {
                CheckoutDrivaService.createLoanOffer(payload, orderId, secureToken)
                    .then((response) => {
                        dispatch(createLoanOfferSuccess(response.data));
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                        dispatch(createLoanOfferFailure(error));
                        reject(error);
                    });
            }
        });
    };

const updateLoanOfferRequest = () => ({
    type: Types.UPDATE_LOAN_OFFER_REQUEST
});

const updateLoanOfferSuccess = (data, isInHouseFinance = false) => {
    return {
        type: Types.UPDATE_LOAN_OFFER_SUCCESS,
        data,
        isInHouseFinance
    };
};

const updateLoanOfferFailure = (error) => ({
    type: Types.UPDATE_LOAN_OFFER_FAILURE,
    error
});

const updateLoanOffer = (params = { checkoutEditMode: false, submitted: false,  offerSelected: false, persistSelectedQuote: false }, showInHouse, resetForm = false, previousLoanOfferRequest = null, urlParam = {}) =>
    (dispatch, getState) => {
        dispatch(updateLoanOfferRequest());
        const {
            user: { secureToken, email,
                firstName,
                middleName,
                lastName,
                mobile },
            checkout: {
                promoCode,
                financeData: apiFinanceData,
                checkoutEditFormData,
                egcData,
                tradeinOpted,
                inHouseFinanceEligibilityVariant,
                tradeInData: {
                    offeredPrice,
                    expired,
                    accepted
                },
                order: { orderId, chargeDetails, deliveryMode }
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price
                }
            },
            deliveryInfo: {
                shippingPrice,
                pickupCharges
            }
        } = getState();

        const { isCouponApplied, discountPrice } = promoCode;
        const { totalDap, tradeInPrice } = getCarPrice({ egcData, offeredPrice, shippingPrice, deliveryMode, pickupCharges }) || {};
        let  driveAwayPrice = (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price);
        driveAwayPrice += getSum(chargeDetails);
        const financeData = params.checkoutEditMode ? checkoutEditFormData : apiFinanceData;
        const { loan } = financeData || {};
        const { deposit, amount } = loan || {};
        const getNewAmount = params?.testDriveFlow && !params.checkoutEditMode;
        const amountCalc = !amount || getNewAmount ? driveAwayPrice - deposit : amount;
        let loanAmount = amountCalc;
        if (isCouponApplied) { loanAmount = Math.round(amountCalc) - discountPrice; }

        const financeDataUpdated = {
            ...financeData,
            driveAwayPrice,
            loan: {
                ...financeData.loan,
                amount: loanAmount
                //amount: Math.round(amountCalc)
            },
            selectedCycle: financeData.selectedCycle || FINANCE_CYCLE.WEEKLY,
            user: {
                ...financeData.user,
                firstName: (financeData.user && financeData.user.firstName) || firstName,
                //middleName: (financeData.user && financeData.user.middleName) || middleName,
                middleName: (financeData.user && financeData.user.middleName),
                lastName: (financeData.user && financeData.user.lastName) || lastName,
                email,
                mobile
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: VEHICLE_STATE.USED,
                vehicleYear: year
            }
        };
        let payload = { ...financeDataUpdated, appointmentId, ...params, selectedCycle: inHouseFinanceEligibilityVariant ? financeData.selectedCycle : (financeData.selectedCycle || FINANCE_CYCLE.WEEKLY) };
        payload = resetUnapplicableFinanceParameters(payload);
        return new Promise((resolve, reject) => {
            if (inHouseFinanceEligibilityVariant) {
                const inHousePayload = { ...payload, financeType: FINANCE_TYPE.CARS24};
                //TODO:: Repetitive code
                if (resetForm) {
                    B2cFinanceService.createOrUpdateInHouseLoanOffer(inHousePayload, orderId, secureToken).then((response) => {
                        dispatch(updateLoanOfferSuccess(response.data, inHouseFinanceEligibilityVariant));
                        resolve(response.data);
                    })
                        .catch((error) => {
                            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                            dispatch(updateLoanOfferFailure(error));
                            reject(error);
                        });
                } else if (params.offerSelected) {
                    B2cFinanceService.selectInHouseLoanOfferV2(orderId, params.selectedQuote.offerId, secureToken, (financeData.selectedCycle || FINANCE_CYCLE.WEEKLY)).then((response) => {
                        dispatch(updateLoanOfferSuccess(response.data, inHouseFinanceEligibilityVariant));
                        resolve(response.data);
                    })
                        .catch((error) => {
                            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                            dispatch(updateLoanOfferFailure(error));
                            reject(error);
                        });
                } else if (params.submitted) {
                    let service;

                    if (params.persistSelectedQuote) {
                        service = B2cFinanceService.persistSelectedQuote(inHousePayload, orderId, secureToken, params.persistSelectedQuote);
                    } else {
                        service = B2cFinanceService.submitInHouseLoanOffer(previousLoanOfferRequest || inHousePayload, orderId, secureToken, urlParam);
                    }

                    service.then((response) => {
                        dispatch(updateLoanOfferSuccess(response.data, inHouseFinanceEligibilityVariant));
                        resolve(response.data);
                    })
                        .catch((error) => {
                            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                            dispatch(updateLoanOfferFailure(error));
                            reject(error);
                        });
                } else {
                    B2cFinanceService.createOrUpdateInHouseLoanOffer(inHousePayload, orderId, secureToken).then((response) => {
                        dispatch(updateLoanOfferSuccess(response.data, inHouseFinanceEligibilityVariant));
                        resolve(response.data);
                    })
                        .catch((error) => {
                            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                            dispatch(updateLoanOfferFailure(error));
                            reject(error);
                        });
                }

            } else {
                const isDrivaPayload = {...payload, financeType: FINANCE_TYPE.DRIVA_FINANCE };
                CheckoutDrivaService.updateLoanOffer(isDrivaPayload, orderId, secureToken)
                    .then((response) => {
                        dispatch(updateLoanOfferSuccess(response.data));
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                        dispatch(updateLoanOfferFailure(error));
                        reject(error);
                    });
            }
        });
    };

const setRecalculateTriggered = (recalculateTriggered) => ({
    type: Types.SET_RECALCULATE_TRIGGERED,
    recalculateTriggered
});

const requestCallback = () => (dispatch, getState) => {
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.requestCallBack({}, orderId,  secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchEgcPriceInit = () => ({
    type: Types.FETCH_EGC_PRICE
});

const fetchEgcPriceSuccess = (data, allStatesPrice) => ({
    type: Types.FETCH_EGC_PRICE_SUCCESS,
    data,
    allStatesPrice
});

const fetchEgcPriceFailure = (error) => ({
    type: Types.FETCH_EGC_PRICE_FAILURE,
    error
});

const fetchEgcPrice = (carId, state) => (dispatch, getState) => {
    dispatch(fetchEgcPriceInit());
    const {
        checkout: {
            egcData,
            testDriveData
        },
        user: {
            secureToken
        },
        tradeIn: {
            tradeInToggleSelected
        }
    } = getState();

    let queryString = state ? `state=${state}` : "";

    if (tradeInToggleSelected) {
        queryString += `&tradeIn=${tradeInToggleSelected}`;
    }

    const { amount = 0 } = testDriveData || {};
    const amountPaid = amount;

    return new Promise((resolve, reject) => {
        BffDataAU.fetchCarPricing(secureToken, carId, queryString)
            .then(response => {
                const modifiedResponse = [{...egcData[0], ...response.data[0], amountPaid}];
                dispatch(fetchEgcPriceSuccess(modifiedResponse, response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(fetchEgcPriceFailure([]));
                reject([]);
            });
    });
};

//Trade-in
const getTradeInDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_REQUEST
});

const getTradeInDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_SUCCESS,
    data
});

const getTradeInDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_FAILURE,
    error
});

const getTradeInDetails = ({fromCdp = false} = {}) =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const {
            user: { secureToken, isLoggedIn},
            checkout: {
                order: { orderId, status}
            },
            tradeIn: {tradeInToggleSelected}
        } = getState();
        try {
            let response;
            if ((status === ORDER_STATUS.CREATED) || fromCdp) {
                response = await C2bTradeService.getTradeInDetailsV2(secureToken);
                const {havingAnOffer, expired} = response?.data || {};
                if (tradeInToggleSelected === null && isLoggedIn && havingAnOffer && !expired) dispatch(setTradeInToggleSelected(true));
                else if (tradeInToggleSelected && expired) dispatch(setTradeInToggleSelected(null));
            } else {
                response =  await CheckoutTradeInService.getPostOrderTradeDetail(secureToken, orderId);
            }
            dispatch(getTradeInDetailsSuccess(response.data));
            const {offeredPrice, expired} = response.data || {};
            dispatch(setProfileDetails({
                isTradeinOfferValid: !expired && offeredPrice,
                tradeinOfferedPrice: offeredPrice
            }));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(setProfileDetails({
                isTradeinOfferValid: null,
                tradeinOfferedPrice: null
            }));
            dispatch(getTradeInDetailsFailure(error));
            dispatch(setTradeInToggleSelected(null));
            return Promise.reject(error);
        }
    };

const getTradeInDetailsV2 = () =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const {
            user: { secureToken, isLoggedIn},
            tradeIn: {tradeInToggleSelected}
        }  = getState();
        try {
            const  response = await C2bTradeService.getTradeInDetailsV2(secureToken);
            dispatch(getTradeInDetailsSuccess(response.data));
            const {offeredPrice, havingAnOffer, expired} = response.data || {};
            if (tradeInToggleSelected === null && isLoggedIn && havingAnOffer && !expired) dispatch(setTradeInToggleSelected(true));
            else if (tradeInToggleSelected && expired) dispatch(setTradeInToggleSelected(null));
            dispatch(setProfileDetails({
                isTradeinOfferValid: !expired && offeredPrice,
                tradeinOfferedPrice: offeredPrice
            }));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(setProfileDetails({
                isTradeinOfferValid: null,
                tradeinOfferedPrice: null
            }));
            dispatch(getTradeInDetailsFailure(error));
            dispatch(setTradeInToggleSelected(null));
            return Promise.reject(error);
        }
    };

const createTradeInRequest = () => ({
    type: Types.CREATE_TRADE_IN_REQUEST
});

const createTradeInSuccess = (data) => ({
    type: Types.CREATE_TRADE_IN_SUCCESS,
    data
});

const createTradeInFailure = (error) => ({
    type: Types.CREATE_TRADE_IN_FAILURE,
    error
});

const createTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            },
            carDetails: {
                content: {
                    appointmentId
                }
            }
        } = getState();

        const payload = {
            userEmail: email,
            vehicleType: X_VEHICLE_TYPE.CAR,
            currency,
            ...params,
            appointmentId,
            omsOrderId: orderId
        };

        return new Promise((resolve, reject) => {
            C2bTradeService.createC2bTrade(secureToken, payload)
                .then((response) => {
                    resolve(response.data);
                    const tradeInData = response.data && response.data.data;
                    dispatch(createTradeInSuccess(tradeInData));
                    resolve(tradeInData);
                })
                .catch((error) => {
                    dispatch(createTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateTradeInRequest = () => ({
    type: Types.UPDATE_TRADE_IN_REQUEST
});
const updateTradeInSuccess = (data, valuationTags, priceComparison) => {
    return {
        type: Types.UPDATE_TRADE_IN_SUCCESS,
        data,
        valuationTags,
        priceComparison
    };
};

const updateTradeInFailure = (error) => ({
    type: Types.UPDATE_TRADE_IN_FAILURE,
    error
});

const updateTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            },
            carDetails: {
                content: {
                    appointmentId
                }
            }
        } = getState();

        const payload = {
            userEmail: email,
            vehicleType: X_VEHICLE_TYPE.CAR,
            currency,
            ...params,
            appointmentId,
            omsOrderId: orderId
        };

        return new Promise((resolve, reject) => {
            C2bTradeService.updateC2bTrade(secureToken, payload, params.c2bOrderId)
                .then((response) => {
                    const tradeInData = response.data && response.data.data;
                    const valuationTags = response.data && response.data.valuationTags;
                    const priceComparison =  response.data && response.data.priceComparison;
                    dispatch(updateTradeInSuccess(tradeInData, valuationTags, priceComparison));
                    resolve(tradeInData);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const acceptTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            }
        } = getState();
        const payload = {
            currency,
            userEmail: email,
            vehicleType: X_VEHICLE_TYPE.CAR,
            ...params
        };
        return new Promise((resolve, reject) => {
            CheckoutTradeInService.updateTradeIn(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(updateTradeInSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const submitTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            }
        } = getState();

        const payload = {
            currency,
            vehicleType: X_VEHICLE_TYPE.CAR,
            userEmail: email,
            ...params,
            omsOrderId: orderId
        };

        return new Promise((resolve, reject) => {
            BffDataAU.submitC2bTrade(secureToken, payload, params.c2bOrderId)
                .then((response) => {
                    const tradeInData = response.data && response.data.data;
                    const valuationTags = response.data && response.data.valuationTags;
                    const priceComparison =  response.data && response.data.priceComparison;
                    dispatch(updateTradeInSuccess(tradeInData, valuationTags, priceComparison));
                    resolve(tradeInData);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const customerOptedTrade = (tradeinOpted) => ({
    type: Types.SET_TRADE_OPTION,
    tradeinOpted
});

const fetchTradeInStateRequest = () => ({
    type: Types.GET_TRADE_IN_STATE_REQUEST
});

const fetchTradeInStateSuccess = (tradeInStates) => ({
    type: Types.GET_TRADE_IN_STATE_SUCCESS,
    tradeInStates
});

const fetchTradeInStateFailure = (error) => ({
    type: Types.GET_TRADE_IN_STATE_FAILURE,
    error
});

const getTradeInState = () => async (dispatch, getState) => {
    dispatch(fetchTradeInStateRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getTradeInStates(secureToken);
        const stateValue = response.data.reduce((acc, data) => {
            acc.push({ ...data, label: data.stateName, value: data.stateName});
            return acc;
        }, []);
        dispatch(fetchTradeInStateSuccess(stateValue));
        return response;
    } catch (error) {
        dispatch(fetchTradeInStateFailure(error));
        return error;
    }
};

const fetchCancellationReasonRequest = () => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON
});

const fetchCancellationReasonSuccess = (cancelationReasons) => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS,
    cancelationReasons
});

const fetchCancellationReasonFailure = (error) => ({
    type: Types.FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE,
    error
});

const fetchCancellationReason = () => (dispatch, getState) => {
    dispatch(fetchCancellationReasonRequest());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        CheckoutTradeInService.fetchCancellationReason(secureToken)
            .then(response => {
                const reasons = response.data && response.data.feedback;
                dispatch(fetchCancellationReasonSuccess(reasons));
                resolve(reasons);
            }).catch(error => {
                dispatch(fetchCancellationReasonFailure(error));
                reject(error);
            });
    });
};

const updateOrderTradeDetailRequest = () => ({
    type: Types.UPDATE_FINANCE_DETAILS
});

const updateOrderTradeDetailSuccess = (data) => ({
    type: Types.UPDATE_FINANCE_DETAILS_SUCCESS,
    data
});
const updateOrderTradeDetailFailure = (error) => ({
    type: Types.UPDATE_FINANCE_DETAILS_FAILURE,
    error
});

const updateOrderTradeDetail = (params = {}) => (dispatch, getState) => {
    dispatch(updateOrderTradeDetailRequest());
    const {
        user: { secureToken },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail({ ...order, ...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateOrderTradeDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateOrderTradeDetailFailure(error));
                reject(error);
            });
    });
};

const fetchTradeInCarDetails = () => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS
});

const fetchTradeInCarDetailsSuccess = (tradeInCarDetails) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS,
    tradeInCarDetails
});

const fetchTradeInCarDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_FAILURE,
    error
});

const getTradeInCarMakeModel = () => async (dispatch, getState) => {
    dispatch(fetchTradeInCarDetails());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getCarMakeModel(secureToken);
        if (response.data && response.data.data) {
            dispatch(fetchTradeInCarDetailsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        }
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        return error;
    }
};

const fetchTradeInCarVariants = () => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT
});

const fetchTradeInCarVariantsSuccess = (tradeInCarVariants) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS,
    tradeInCarVariants
});

const fetchTradeInCarVariantsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_FAILURE,
    error
});

const getTradeInCarVariants = (params = {}) => async (dispatch, getState) => {
    dispatch(fetchTradeInCarVariants());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getVariants(secureToken, params);
        if (response.data && response.data.data && response.data.data.length) {
            dispatch(fetchTradeInCarVariantsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        }
        return response;
    } catch (error) {
        dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        return error;
    }
};

const saveTradeInRegoandVin = (data) => ({
    type: Types.SAVE_CAR_REGNO_VIN,
    data
});

const saveTradeInCarMakeModelId = (data) => ({
    type: Types.SAVE_TRADE_IN_ID,
    data
});

const resetTradeInDetails = () => ({
    type: Types.RESET_CAR_TRADE_IN_DETAILS
});

const saveCheckoutSourcePathname = (data) => ({
    type: Types.SAVE_CHECKOUT_SOURCE_PATHNAME,
    data
});

const setSplitPayment = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT,
    data
});

const updateSplitPaymentRequest = () => ({
    type: Types.UPDATE_SPLIT_PAYMENT
});

const updateSplitPaymentSuccess = (data) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_SUCCESS,
    data
});

const updateSplitPaymentFailure = (error) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_FAILURE,
    error
});

const updateSplitPayment = (params = {}) => (dispatch, getState) => {
    dispatch(updateSplitPaymentRequest());
    const {
        user: { secureToken, gaId },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId};
    return new Promise((resolve, reject) => {
        CheckoutService.updateSplitPayment({...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateSplitPaymentSuccess(response.data));
                dispatch(getOrderDetailSuccess({...response.data, clientId: gaId || clientId}));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateSplitPaymentFailure(error));
                reject(error);
            });
    });
};

const setSplitPaymentUserState = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT_USER_STATE,
    data
});

const getPaymentHistoryInit  = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_INIT,
    data
});

const getPaymentHistorySuccess = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_SUCCESS,
    data
});

const getPaymentHistoryFailure = (error) => ({
    type: Types.GET_PAYMENT_HISTORY_FAILURE,
    error
});

const getPaymentHistory = () => (dispatch, getState) => {
    dispatch(getPaymentHistoryInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentHistory(orderId, secureToken)
            .then(response => {
                dispatch(getPaymentHistorySuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(getPaymentHistoryFailure(error));
                reject(error);
            });
    });
};

const getSignContractInit  = (data) => ({
    type: Types.GET_SIGN_CONTRACT_INIT,
    data
});

const getSignContractSuccess = (data) => ({
    type: Types.GET_SIGN_CONTRACT_SUCCESS,
    data
});

const getSignContractFailure = (error) => ({
    type: Types.GET_SIGN_CONTRACT_FAILURE,
    error
});

const getSignContract = () => (dispatch, getState) => {
    dispatch(getSignContractInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        SignContractService.getSignContract(orderId, secureToken)
            .then(response => {
                const data = response.data && response.data.data;
                dispatch(getSignContractSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(getSignContractFailure(error));
                reject(error);
            });
    });
};

const postSignContractInit  = (data) => ({
    type: Types.POST_SIGN_CONTRACT_INIT,
    data
});

const postSignContractSuccess = (data) => ({
    type: Types.POST_SIGN_CONTRACT_SUCCESS,
    data
});

const postSignContractFailure = (error) => ({
    type: Types.POST_SIGN_CONTRACT_FAILURE,
    error
});

const signContractPollStatus = (data) => ({
    type: Types.SIGN_CONTRACT_POLL_STATUS,
    data
});

const postSignContract = (params) => (dispatch, getState) => {
    dispatch(postSignContractInit(false));
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        SignContractService.postSignContract(params, secureToken)
            .then(response => {
                dispatch(postSignContractSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(postSignContractFailure(error));
                reject(error);
            });
    });
};

const updateSignContractStatus = (params) => (dispatch, getState) => {
    dispatch(postSignContractInit(false));
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        SignContractService.updateSignContractStatus(params, secureToken)
            .then(response => {
                dispatch(postSignContractSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(postSignContractFailure(error));
                reject(error);
            });
    });
};

const paymentInit = (data) => ({
    type: Types.PAYMENT_INIT,
    data
});

const sessionInit = (data) => ({
    type: Types.SESSION_INIT,
    data
});

const setCurrentDeliveryStep = (data) => ({
    type: Types.SET_CURRENT_DELIVERY_STEP,
    data
});

//Trade vechicle Details
const getTradeVehicleDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_VEHICLE_REQUEST
});

const getTradeVehicleDetailsSuccess = ({data}) => ({
    type: Types.GET_TRADE_IN_VEHICLE_SUCCESS,
    data
});

const getTradeVehicleDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_VEHICLE_FAILURE,
    error
});

const getTradeVehicleDetails = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getTradeVehicleDetailsRequest());
        const { user: { secureToken }} = getState();
        try {
            const response =  await C2bTradeService.getC2BTradeInVehicleDetails(secureToken, params);
            dispatch(getTradeVehicleDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeVehicleDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const updateBasicHubspotRequest = () => ({
    type: Types.UPDATE_BASIC_HUBSPOT_INIT
});

const updateBasicHubspotSuccess = ({data}) => ({
    type: Types.UPDATE_BASIC_HUBSPOT_SUCCESS,
    data
});

const updateBasicHubspotFailure = (error) => ({
    type: Types.UPDATE_BASIC_HUBSPOT_FAILURE,
    error
});

const updateBasicHubspot = (orderId, params) => (dispatch, getState) => {
    dispatch(updateBasicHubspotRequest());
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.hubSpotBasicUpdate(orderId, secureToken, params)
            .then(response => {
                dispatch(updateBasicHubspotSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateBasicHubspotFailure(error));
                reject(error);
            });
    });
};

// Promo code

const getPromoCodeInit = () => ({
    type: Types.GET_PROMO_CODE_REQUEST
});

const getPromoCodeSuccess = ({data}) => ({
    type: Types.GET_PROMO_CODE_SUCCESS,
    data
});

const getPromoCodeFailure = (error) => ({
    type: Types.GET_PROMO_CODE_FAILURE,
    error
});

const getPromoCodeDetails = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getPromoCodeInit());
        const { user: { secureToken }} = getState();
        try {
            const response =  {};
            dispatch(getPromoCodeSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getPromoCodeFailure(error));
            return Promise.reject(error);
        }
    };

const applyPromoCodeInit = () => ({
    type: Types.POST_PROMO_CODE_REQUEST
});

const applyPromoCodeSuccess = (data) => ({
    type: Types.POST_PROMO_CODE_SUCCESS,
    data
});

const applyPromoCodeFailure = (error) => ({
    type: Types.POST_PROMO_CODE_FAILURE,
    error
});

const applyPromoCode = (orderId, params = {}) =>
    async (dispatch, getState) => {
        dispatch(applyPromoCodeInit());
        const { user: { secureToken } } = getState();
        // const payload = {request: params, tag: "AU"};
        try {
            const response =  await BffPaymentService.applyCoupon(secureToken, orderId, params);
            dispatch(applyPromoCodeSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(applyPromoCodeFailure(error));
            return Promise.reject(error);
        }
    };
const removePromoCodeInit = () => ({
    type: Types.REMOVE_PROMO_CODE_REQUEST
});

const removePromoCodeSuccess = (data) => ({
    type: Types.REMOVE_PROMO_CODE_SUCCESS,
    data
});

const removePromoCodeFailure = (error) => ({
    type: Types.REMOVE_PROMO_CODE_FAILURE,
    error
});

const resetDrivaFinanceData = () => {
    return {
        type: Types.RESET_DRIVA_FINANCE_DATA
    };
};

const removePromoCode = (orderId, params = {}) =>
    async (dispatch, getState) => {
        dispatch(removePromoCodeInit());
        const { user: { secureToken }} = getState();
        // const payload = {request: params, tag: "AU"};
        try {
            const response =  await BffPaymentService.removeCoupon(secureToken, orderId, params);
            dispatch(removePromoCodeSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(removePromoCodeFailure(error));
            return Promise.reject(error);
        }
    };

const resetApplyPromoCode = () => ({
    type: Types.RESET_POST_PROMO_CODE
});

const getWarrantyPlansInit = () => ({
    type: Types.GET_WARRANTY_PLANS_REQUEST
});

const getWarrantyPlansSuccess = (data) => ({
    type: Types.GET_WARRANTY_PLANS_SUCCESS,
    data
});

const getWarrantyPlansFailure = (error) => ({
    type: Types.GET_WARRANTY_PLANS_FAILURE,
    error
});

const getWarrantyPlans = (params = "", appId, notLoggedInInfo) => async (dispatch, getState) => {
    dispatch(getWarrantyPlansInit());
    const {
        user: { secureToken = "" },
        checkout: {
            order: { appointmentId }
        }} = getState();
    const modifiedParam =  params || `?appointmentId=${appId || appointmentId}`;
    const notLoggedInParam = notLoggedInInfo ? `${modifiedParam}&userId=${notLoggedInInfo}` : modifiedParam;
    try {
        const response =  await VasService.getWarrantyPlans(secureToken, notLoggedInParam);
        dispatch(getWarrantyPlansSuccess(response.data));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(getWarrantyPlansFailure(error));
        return Promise.reject(error);
    }
};

const updateWarrantyPlansInit = () => ({
    type: Types.UPDATE_WARRANTY_PLANS_REQUEST
});

const updateWarrantyPlansSuccess = (data) => ({
    type: Types.UPDATE_WARRANTY_PLANS_SUCCESS,
    data
});

const updateWarrantyPlansFailure = (error) => ({
    type: Types.UPDATE_WARRANTY_PLANS_FAILURE,
    error
});

const updateWarrantyPlans = (orderId, params = {}) => async (dispatch, getState) => {
    dispatch(updateWarrantyPlansInit());
    const { user: { secureToken, gaId }} = getState();
    // const payload = {request: params, tag: "AU"};
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId};
    try {
        const response =  await CheckoutService.updateWarrantyPlans(secureToken, orderId, params);
        dispatch(updateWarrantyPlansSuccess(response.data));
        dispatch(getOrderDetailSuccess({...response.data, clientId: gaId || clientId}));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(updateWarrantyPlansFailure(error));
        return Promise.reject(error);
    }
};

const getTestDriveInit = (data) => ({
    type: Types.GET_TEST_DRIVE_INIT,
    data
});

const getTestDriveSuccess = (data) => ({
    type: Types.GET_TEST_DRIVE_SUCCESS,
    data
});

const getTestDriveFailure = (error) => ({
    type: Types.GET_TEST_DRIVE_FAILURE,
    error
});

const getTestDriveSlots = (stateCode) => (dispatch, getState) => {
    dispatch(getTestDriveInit());
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getTestDriveDate(secureToken, stateCode)
            .then(response => {
                dispatch(getTestDriveSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(getTestDriveFailure(error));
                reject(error);
            });
    });
};

const getBookingSummaryInit = (data) => ({
    type: Types.GET_BOOKINGS_SUMMARY_INIT,
    isLoading: data
});

const getBookingSummarySuccess = ({data}) => ({
    type: Types.GET_BOOKINGS_SUMMARY_SUCCESS,
    data
});

const getBookingSummaryFailure = (error) => ({
    type: Types.GET_BOOKINGS_SUMMARY_FAILURE,
    error
});

const getBookingSummary = (orderId, withLoader = true) => (dispatch, getState) => {
    dispatch(getBookingSummaryInit(withLoader));
    const { user: { secureToken, gaId } } = getState();
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};

    return new Promise((resolve, reject) => {
        BffDataAU.getBookingSummary(secureToken, orderId)
            .then(response => {
                const {order} = (response.data && response.data.data) || {};
                order.clientId = gaId || clientId;
                dispatch(createOrderSuccess(order));
                dispatch(getBookingSummarySuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getBookingSummaryFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const updateOrderRequest = () => ({
    type: Types.UPDATE_ORDER_REQUEST
});

const updateOrderSuccess = (data) => ({
    type: Types.UPDATE_ORDER_SUCCESS,
    data
});

const updateOrderFailure = (error) => ({
    type: Types.UPDATE_ORDER_FAILURE,
    error
});

const updateOrder = (orderId, params = {}) => async (dispatch, getState) => {
    dispatch(updateOrderRequest());
    const { user: { secureToken, gaId } } = getState();
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    params = {...params, clientId: gaId || clientId};
    try {
        const response = await CheckoutService.updateOrder(secureToken, orderId, params);
        dispatch(createOrderSuccess({...response.data, clientId: gaId || clientId}));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(createOrderFailure(error));
        return Promise.reject(error);
    }
};

const getTestDriveDataInit = (data) => ({
    type: Types.GET_TEST_DRIVE_DATA_INIT,
    isLoading: data
});

const getTestDriveDataSuccess = (data) => ({
    type: Types.GET_TEST_DRIVE_DATA_SUCCESS,
    data
});

const getTestDriveDataFailure = (error) => ({
    type: Types.GET_TEST_DRIVE_DATA_FAILURE,
    error
});

const getTestDriveData = (orderId, params, withLoader = true) => (dispatch, getState) => {
    dispatch(getTestDriveDataInit(withLoader));
    const { user: { secureToken }, checkout: {egcData}} = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getTestDriveData(secureToken, orderId, params)
            .then(response => {
                const {amount = 0} = response.data;
                const modifiedResponse = [{...egcData[0], amountPaid: amount}];
                dispatch(fetchEgcPriceSuccess(modifiedResponse));
                dispatch(getTestDriveDataSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getTestDriveDataFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const checkInHouseFinanceEligibilityRequest = () => ({
    type: Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_REQUEST
});

const checkInHouseFinanceEligibilitySuccess = (data) => ({
    type: Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_SUCCESS,
    data
});

const checkInHouseFinanceEligibilityFailure = (error) => ({
    type: Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_FAILURE,
    error
});

const checkInHouseFinanceEligibility = () => (dispatch, getState) => {
    dispatch(checkInHouseFinanceEligibilityRequest());
    const {
        user: { secureToken },
        abExperiment: {
            showInHouseFinance
        }
    } = getState();
    const isInhouseFlowVariant = (showInHouseFinance || {}).data === EXPERIMENT_TYPE.VARIANT_B;
    return new Promise((resolve, reject) => {
        B2cFinanceService.checkEligibilityBFF(secureToken).then((response) => {
            dispatch(checkInHouseFinanceEligibilitySuccess({
                data: response.data,
                inHouseFinanceEligibilityVariant: response.data && isInhouseFlowVariant
            }));
            // dispatch(checkInHouseFinanceEligibilitySuccess(true));
            resolve(true);
        }).catch((error) => {
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            dispatch(checkInHouseFinanceEligibilityFailure(error));
            reject(error);
        });
    });
};

const getValidQuotesInHouseFinanceRequest = () => ({
    type: Types.GET_VALID_QUOTES_HOUSE_FINANCE_REQUEST
});

const getValidQuotesInHouseFinanceSuccess = (data) => ({
    type: Types.GET_VALID_QUOTES_HOUSE_FINANCE_SUCCESS,
    data
});

const getValidQuotesInHouseFinanceFailure = (error) => ({
    type: Types.GET_VALID_QUOTES_HOUSE_FINANCE_FAILURE,
    error
});

const getValidQuotesInHouseFinance = () => (dispatch, getState) => {
    dispatch(getValidQuotesInHouseFinanceRequest());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        },
        abExperiment: {
            //showLenderDisplayExperiment,
            showLoanOfferScreen
        }
    } = getState();

    // const experiment = (showLoanOfferScreen || {}).data === EXPERIMENT_TYPE.VARIANT_B ? "WHITE_LABEL" : "LOAN_COMPARISON";
    // const params = {
    //     experiment
    //     //experiment: (showLenderDisplayExperiment || {}).data === EXPERIMENT_TYPE.VARIANT_B ? "V2" : null
    // };
    return new Promise((resolve, reject) => {
        B2cFinanceService.inHouseFinancePollingApi(orderId, secureToken).then(response => {
            dispatch(getValidQuotesInHouseFinanceSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getValidQuotesInHouseFinanceFailure(error));
            reject(error);
        });
    });
};

const createOrderForSimilarVehichleRequest = () => ({
    type: Types.CREATE_ORDER_SIMILAR_VEHICLE_REQUEST
});

const createOrderForSimilarVehichleSuccess = ({data}) => ({
    type: Types.CREATE_ORDER_SIMILAR_VEHICLE_SUCCESS,
    data
});

const createOrderForSimilarVehichleFailure = (error) => ({
    type: Types.CREATE_ORDER_SIMILAR_VEHICLE_FAILURE,
    error
});

const createOrderForSimilarVehichle = (payload, deliveryAddress) => async (dispatch, getState) => {
    dispatch(createOrderForSimilarVehichleRequest());
    const { user: { secureToken, gaId }} = getState();
    const {clientId = ""} = JSON.parse(parseCookie(emitEventNames.desktop.TRACKING_CONSENT)) || {};
    try {
        const response = await CheckoutService.createOrderForSimilarVehichle(secureToken, {...payload, clientId});
        dispatch(createOrderForSimilarVehichleSuccess({...response.data, deliveryAddress}));
        dispatch(createOrderSuccess({
            ...response.data,
            deliveryAddress: { ...((response.data && response.data.deliveryAddress) || {}), ...deliveryAddress},
            clientId: gaId || clientId
        })
        );
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(createOrderForSimilarVehichleFailure(error));
        return Promise.reject(error);
    }
};

const getCheckoutStepsInit = (data) => ({
    type: Types.GET_CHECKOUT_STEPS_INIT,
    isLoading: data
});

const getCheckoutStepsSuccess = (data) => ({
    type: Types.GET_CHECKOUT_STEPS_SUCCESS,
    data
});

const getCheckoutStepsFailure = (error) => ({
    type: Types.GET_CHECKOUT_STEPS_FAILURE,
    error
});

const getCheckoutSteps = (orderId, withLoader = true) => (dispatch, getState) => {
    dispatch(getCheckoutStepsInit(withLoader));
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        BffDataAU.getCheckoutSteps(secureToken, orderId)
            .then(response => {
                const {httpCode} = response.data || {};
                if (httpCode === NUMBER.ONE_THOUSAND_ONE) {
                    dispatch(getCheckoutStepsFailure());
                } else {
                    dispatch(getCheckoutStepsSuccess(response.data));
                }                resolve(response.data);
            }).catch(error => {
                dispatch(getCheckoutStepsFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createCheckoutStepsInit = () => ({
    type: Types.CREATE_CHECKOUT_STEPS_REQUEST
});

const createCheckoutStepsSuccess = (data) => ({
    type: Types.CREATE_CHECKOUT_STEPS_SUCCESS,
    data
});

const createCheckoutStepsFailure = (error) => ({
    type: Types.CREATE_CHECKOUT_STEPS_FAILURE,
    error
});

const createCheckoutSteps = (params) => (dispatch, getState) => {
    dispatch(createCheckoutStepsInit());
    const { user: { secureToken }, checkout: {order: {orderId}}} = getState();
    return new Promise((resolve, reject) => {
        BffDataAU.createCheckoutSteps({...params, orderId }, secureToken, API_SOURCE.M_SITE)
            .then(response => {
                dispatch(createCheckoutStepsSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(createOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(createCheckoutStepsFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const updateCheckoutStepsInit = () => ({
    type: Types.UPDATE_CHECKOUT_STEPS_REQUEST
});

const updateCheckoutStepsSuccess = (data) => {
    return {
        type: Types.UPDATE_CHECKOUT_STEPS_SUCCESS,
        data
    };
};

const updateCheckoutStepsFailure = (error) => ({
    type: Types.UPDATE_CHECKOUT_STEPS_FAILURE,
    error
});

const updateCheckoutSteps = (params) => (dispatch, getState) => {
    dispatch(updateCheckoutStepsInit());
    const { user: { secureToken, email }, checkout: {order, appointmentId} } = getState();
    const {orderId = ""} = order || {};
    const checkoutStep = { userId: email, orderId, appointmentId};
    return new Promise((resolve, reject) => {
        BffDataAU.updateCheckoutSteps({...checkoutStep, ...params, orderId}, secureToken, API_SOURCE.M_SITE)
            .then(response => {
                dispatch(updateCheckoutStepsSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(updateOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(updateCheckoutStepsFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const updateCheckoutPreApprovalStep = ({stage, form}) => {
    return {
        type: Types.UPDATE_CHECKOUT_PRE_APPROVAL_STEPS,
        stage,
        form
    };
};

export const setSelectedSimilarCarForCheckout = (data) => {
    return {
        type: Types.SET_SELECTED_SIMILAR_CAR_FOR_CHECKOUT,
        data
    };
};

const getVerificationStatusInit  = (data) => ({
    type: Types.GET_VERIFICATION_STATUS_INIT,
    data
});

const getVerificationStatusSuccess = (data) => ({
    type: Types.GET_VERIFICATION_STATUS_SUCCESS,
    data
});

const getVerificationStatusFailure = (error) => ({
    type: Types.GET_VERIFICATION_STATUS_FAILURE,
    error
});

const getVerificationStatus = (orderIdParam) => (dispatch, getState) => {
    dispatch(getVerificationStatusInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        GreenIdService.getVerificationStatus(orderIdParam || orderId, secureToken)
            .then(response => {
                const data = response.data && response.data.data;
                dispatch(getVerificationStatusSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(getVerificationStatusFailure(error));
                reject(error);
            });
    });
};

const registerVerificationStatusInit  = (data) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_INIT,
    data
});

const registerVerificationStatusSuccess = (data) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_SUCCESS,
    data
});

const registerVerificationStatusFailure = (error) => ({
    type: Types.REGISTER_VERIFICATION_STATUS_FAILURE,
    error
});

const registerVerificationStatus = (verificationToken) => (dispatch, getState) => {
    dispatch(registerVerificationStatusInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        GreenIdService.registerVerificationStatus(orderId, verificationToken, secureToken)
            .then(response => {
                const data = response.data && response.data.data;
                dispatch(registerVerificationStatusSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(registerVerificationStatusFailure(error));
                reject(error);
            });
    });
};

const verifyIdPollStatus = (data) => ({
    type: Types. GET_VERIFICATION_ID_STATUS,
    data
});

const postEventImpressionSourceInit = () => ({
    type: Types.POST_EVENT_IMPRESSION_SOURCE_INIT
});

const postEventImpressionSourceSuccess = (data) => ({
    type: Types.POST_EVENT_IMPRESSION_SOURCE_SUCCESS,
    data
});

const postEventImpressionSourceFailure = (error) => ({
    type: Types.POST_EVENT_IMPRESSION_SOURCE_FAILURE,
    error
});

const postEventImpressionSource = (payload) => async (dispatch, getState) => {
    dispatch(postEventImpressionSourceInit());
    const { user: { secureToken }} = getState();
    try {
        const response =  await ListingBaseService.postEventImpressionSource(payload, secureToken);
        dispatch(postEventImpressionSourceSuccess(response.data));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(postEventImpressionSourceFailure(error));
        return Promise.reject(error);
    }
};

const fetchCheckoutCallbackRequestInit = (data) => {
    return {
        type: Types.FETCH_CHECKOUT_CALLBACK_REQUEST_INIT,
        data
    };
};

const fetchCheckoutCallbackRequestSuccess = (data) => {
    return {
        type: Types.FETCH_CHECKOUT_CALLBACK_REQUEST_SUCCESS,
        data
    };
};

const fetchCheckoutCallbackRequestFailure = (error) => ({
    type: Types.FETCH_CHECKOUT_CALLBACK_REQUEST_FAILURE,
    error
});

const fetchCheckoutCallbackRequest = () => (dispatch, getState) => {
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    if (orderId) {
        dispatch(fetchCheckoutCallbackRequestInit(true));
        return new Promise((resolve, reject) => {
            B2cFinanceService.getCheckoutCallbackRequest(secureToken, orderId)
                .then((response) => {
                    dispatch(fetchCheckoutCallbackRequestSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(fetchCheckoutCallbackRequestFailure(error));
                    dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                    reject(error);
                });
        });
    }
    return null;
};

const checkoutCallbackRequestInit = (data) => {
    return {
        type: Types.CHECKOUT_CALLBACK_REQUEST_INIT,
        data
    };
};

const checkoutCallbackRequestSuccess = (data) => {
    return {
        type: Types.CHECKOUT_CALLBACK_REQUEST_SUCCESS,
        data
    };
};

const byofCallbackRequestSuccess = (data) => {
    return {
        type: Types.BYOF_CALLBACK_REQUEST_SUCCESS,
        data
    };
};

const checkoutCallbackRequestFailure = (error) => ({
    type: Types.CHECKOUT_CALLBACK_REQUEST_FAILURE,
    error
});

const postCheckoutCallbackRequest = (source, params) => (dispatch, getState) => {
    dispatch(checkoutCallbackRequestInit(true));
    const {
        user: { secureToken: token },
        checkout: {
            order: { orderId }
        }
    } = getState();

    return new Promise((resolve, reject) => {
        B2cFinanceService.postCheckoutCallbackRequest({token, orderId, source}, params)
            .then((response) => {
                if (source === REQUEST_CALLBACK_SOURCE.BYOF) {
                    dispatch(byofCallbackRequestSuccess(response.data));
                } else {
                    dispatch(checkoutCallbackRequestSuccess(response.data));
                }
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(checkoutCallbackRequestFailure(error));
                dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                reject(error);
            });
    });
};

const setZeroDpSelectOfferCoachMark = (data) => ({
    type: Types.SET_ZERO_DP_SELECT_OFFER_COACHMARK,
    data
});

const setLoanOffersCoachMark = (data) => ({
    type: Types.SET_LOAN_OFFERS_COACHMARK,
    data
});

const postZeroDpPaymentApiInit = (data) => {
    return {
        type: Types.POST_ZERO_DP_PAYMENT_API_INIT,
        data
    };
};

const postZeroDpPaymentApiSuccess = (data) => {
    return {
        type: Types.POST_ZERO_DP_PAYMENT_API_SUCCESS,
        data
    };
};

const postZeroDpPaymentApiFailure = (error) => ({
    type: Types.POST_ZERO_DP_PAYMENT_API_FAILURE,
    error
});

const postZeroDpPaymentApi = () => (dispatch, getState) => {
    dispatch(postZeroDpPaymentApiInit(true));
    const {
        user: { secureToken: token },
        checkout: {
            order: { orderId, appointmentId }
        }
    } = getState();
    const params = {
        orderId,
        appointmentId
    };
    return new Promise((resolve, reject) => {
        CheckoutService.postZeroDpPaymentApi(token, params)
            .then((response) => {
                dispatch(postZeroDpPaymentApiSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(postZeroDpPaymentApiFailure(error));
                dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                reject(error);
            });
    });
};

const postReBookZeroDpOrderInit = (data) => {
    return {
        type: Types.POST_ZERO_DP_PAYMENT_API_INIT,
        data
    };
};

const postReBookZeroDpOrderSuccess = (data) => {
    return {
        type: Types.POST_ZERO_DP_PAYMENT_API_SUCCESS,
        data
    };
};

const postReBookZeroDpOrderFailure = (error) => ({
    type: Types.POST_ZERO_DP_PAYMENT_API_FAILURE,
    error
});

const postReBookZeroDpOrder = (oldOrderId) => (dispatch, getState) => {
    dispatch(postReBookZeroDpOrderInit(true));
    const {
        user: { secureToken: token },
        checkout: {
            order: { appointmentId }
        }
    } = getState();
    const params = {
        oldOrderId,
        appointmentId
    };
    return new Promise((resolve, reject) => {
        CheckoutService.postReBookZeroDpOrder(token, params)
            .then((response) => {
                dispatch(postReBookZeroDpOrderSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(postReBookZeroDpOrderFailure(error));
                dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                reject(error);
            });
    });
};

const getZeroDpTextDataConfigInit = (data) => {
    return {
        type: Types.GET_ZERO_DP_TEXT_DATA_CONFIG_INIT,
        data
    };
};

const getZeroDpTextDataConfigSuccess = (data) => {
    return {
        type: Types.GET_ZERO_DP_TEXT_DATA_CONFIG_SUCCESS,
        data
    };
};

const getZeroDpTextDataConfigFailure = (error) => ({
    type: Types.GET_ZERO_DP_TEXT_DATA_CONFIG_FAILURE,
    error
});

const getZeroDpTextDataConfig = () => (dispatch, getState) => {
    dispatch(getZeroDpTextDataConfigInit(true));
    const {
        user: { secureToken: token }
    } = getState();

    return new Promise((resolve, reject) => {
        B2cFinanceService.getZeroDpTextDataConfig(token)
            .then((response) => {
                dispatch(getZeroDpTextDataConfigSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(getZeroDpTextDataConfigFailure(error));
                dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                reject(error);
            });
    });
};

const uploadPaymentProofRequest = () => ({
    type: Types.UPLOAD_BANK_TRANSFER_FILE_REQUEST
});

const uploadPaymentProofSuccess = (paymentPayload) => ({
    type: Types.UPLOAD_BANK_TRANSFER_FILE_SUCCESS,
    paymentPayload
});

const uploadPaymentProofFailure = (error) => ({
    type: Types.UPLOAD_BANK_TRANSFER_FILE_FAILURE,
    error
});

const uploadBankTransferProof = (payload) => async (dispatch, getState) => {
    dispatch(uploadPaymentProofRequest());
    const {
        user: { secureToken },
        checkout: {order: { orderId }}
    } = getState();
    try {
        const response = await CheckoutService.uploadPaymentProof(secureToken, orderId, payload);
        dispatch(uploadPaymentProofSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(uploadPaymentProofFailure(error));
        return error;
    }
};

const confirmBankTransfer = (payload) => async (dispatch, getState) => {
    const { user: { secureToken },
        checkout: {order: { orderId }}
    } = getState();
    try {
        const response = await CheckoutService.confirmBankTransfer(secureToken, orderId, payload);
        return response;
    } catch (error) {
        return error;
    }
};

const getLoanOfferFAQRequest = () => ({
    type: Types.GET_LOAN_OFFER_FAQ_REQUEST
});

const getLoanOfferFAQSuccess = (data) => ({
    type: Types.GET_LOAN_OFFER_FAQ_SUCCESS,
    data
});

const getLoanOfferFAQFailure = (error) => ({
    type: Types.GET_LOAN_OFFER_FAQ_FAILURE,
    error
});

const getLoanOfferFAQ = () =>
    async (dispatch, getState) => {
        dispatch(getLoanOfferFAQRequest());
        const {
            user: {
                secureToken,
                isGuestLogin
            }
        } = getState();
        try {
            const financeResponse = await B2cFinanceService.getPreApprovalQuestions({ token: isGuestLogin ? null : secureToken, scenario: "Loan_Offer_Faq" });

            dispatch(getLoanOfferFAQSuccess(financeResponse.data));
            return Promise.resolve(financeResponse.data);
        } catch (error) {
            dispatch(getLoanOfferFAQFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return  Promise.reject(error);
        }
    };

const resetLoanOfferDetailsforGuest = (params = {}) => (dispatch, getState) => {
    const {isGuestLogin} = getState();
    if (isGuestLogin) {
        dispatch(getLoanOfferDetailsSuccess({quotesAvailable: true}, true));
    }
};

const setSelectOfferScreenLoader = (data) => ({
    type: Types.SET_LOAN_OFFER_SCREEN_LOADER,
    data
});

const resetCheckoutFinanceData = () => ({
    type: Types.RESET_CHECKOUT_FINANCE_DATA
});

// make model Flow
const setMakeFlowMaxStep = (maxStep) => ({type: Types.SET__MAKE_MODEL_MAX_STEP, maxStep});
const setMakeFlowCurrentStep = (currentStep) => ({type: Types.SET__MAKE_MODEL_CURRENT_STEP, currentStep});
const setMakeFlowHardRejection = (rejectionReasonInfo) => ({type: Types.SET__MAKE_MODEL_HARD_REJECTION, rejectionReasonInfo});
const setMakeFlowRecheduleflag = (rescheduleFlag) => ({type: Types.SET__MAKE_MODEL_RECHEDULE_FLAG, rescheduleFlag});
const setMakeModelYear = (makeModelYear) => ({type: Types.SET_MAKE_MODEL_YEAR, makeModelYear});
const setMmvyUserInputs = (mmvyUserInputs) => ({type: Types.SET_MMVY_USER_INPUTS, mmvyUserInputs});
const updateMakeModelFlowRequest = () => ({
    type: Types.UPDATE_MAKE_MODEL_REQUEST
});
const updateMakeModelFlow = (orderData, orderId) =>
    (dispatch, getState) => {
        dispatch(updateMakeModelFlowRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.updateMakeModelFlow(secureToken, orderData, orderId)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
const getBankDetailsRequest = () => ({
    type: Types.GET_BANK_TRANSFER_REQUEST
});

const getBankDetailsSuccess = (data) => ({
    type: Types.GET_BANK_TRANSFER_SUCCESS,
    data
});

const getBankDetailsFailure = (error) => ({
    type: Types.GET_BANK_TRANSFER_FAILURE,
    error
});

const getBankDetails = (secureToken, payload) => async (dispatch, getState) => {
    dispatch(getBankDetailsRequest());
    try {
        const response = await CheckoutService.getBankDetails(secureToken, payload);
        dispatch(getBankDetailsSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(getBankDetailsFailure(error));
        return error;
    }
};

const updateChargeDetails = (data) => async (dispatch, getState) => {
    const {
        checkout: {
            order: {
                chargeDetails
            }
        }
    } = getState();

    const chargeAmount = (chargeDetails || []).filter((cd) => !["BUNDLE", "PRODUCT"].includes(cd.type)) || [];
    const vasItems = (data || []).map((d) => ({ ...d, label: d.name }));

    dispatch({
        type: Types.UPDATE_CHARGE_DETAILS,
        data: [...chargeAmount, ...vasItems]
    });
};
const adyenFailurePollStatus = (data) => ({
    type: Types.ADYEN_FAILURE_POLL_STATUS,
    data
});

const getAdyenFailureInit  = (data) => ({
    type: Types.GET_ADYEN_FAILURE_INIT,
    data
});

const getAdyenFailureSuccess = (data) => ({
    type: Types.GET_ADYEN_FAILURE_SUCCESS,
    data
});

const getAdyenFailureFailure = (error) => ({
    type: Types.GET_ADYEN_FAILURE_FAILURE,
    error
});
const getAdyenFailure = (params) => (dispatch, getState) => {
    dispatch(getAdyenFailureInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getAdyenFailure(orderId, params, secureToken)
            .then(response => {
                const data = response.data && response.data;
                dispatch(getAdyenFailureSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(getAdyenFailureFailure(error));
                reject(error);
            });
    });
};

const getCheckoutSummaryFAQRequest = () => ({
    type: Types.CHECKOUT_SUMMARY_FAQ_REQUEST
});

const getCheckoutSummaryFAQSuccess = (data) => ({
    type: Types.CHECKOUT_SUMMARY_FAQ_SUCCESS,
    data
});

const getCheckoutSummaryFAQFailure = (error) => ({
    type: Types.CHECKOUT_SUMMARY_FAQ_FAILURE,
    error
});

const getCheckoutSummaryFAQ = () =>
    async (dispatch, getState) => {
        dispatch(getCheckoutSummaryFAQRequest());
        const {
            user: {
                secureToken,
                isGuestLogin
            }
        } = getState();
        try {
            const financeResponse = await B2cFinanceService.getCheckoutSummaryFAQData({ token: isGuestLogin ? null : secureToken, scenario: "Order_Summary" });

            dispatch(getCheckoutSummaryFAQSuccess(financeResponse.data));
            return Promise.resolve(financeResponse.data);
        } catch (error) {
            dispatch(getCheckoutSummaryFAQFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return  Promise.reject(error);
        }
    };

const updateTestDriveDetails = (data, status) => ({
    type: Types.UPDATE_TEST_DRIVE_DETAILS,
    data,
    status
});

const getIsStraightThroughEligble = () => {
    return async(dispatch, getState) => {
        dispatch({type: Types.SET_ST_ELIGIBLE_START});
        const {
            user: {
                secureToken
            }
        } = getState();
        try {
            const resp = await B2cFinanceService.fetchStraightThroughEligble({token: secureToken});
            dispatch({type: Types.SET_ST_ELIGIBLE_SUCCESS, data: resp.data || {}});
            return Promise.resolve(resp);
        } catch (error) {
            dispatch({type: Types.SET_ST_ELIGIBLE_FAILURE});
            return Promise.reject(error);
        }
    };
};

const updateValidC2bOrder = (secureToken) => async () => {
    return new Promise((resolve, reject) => {
        C2bTradeService.getTradeInDetailsV2(secureToken)
            .then(response => {
                const {c2bOrderId} = response.data || {};
                const updateC2bData = {
                    c2bOrderId,
                    "tradeInOptedForTOP": true
                };
                C2bTradeService.updateC2bOrder(secureToken, c2bOrderId, updateC2bData).then((resp) => {
                    resolve(resp);
                });
            }).catch(error => {
                reject(error);
            });
    });
};

export {
    populateCheckoutPersonalData,
    setUserPersonalDetails,
    setPersonalDetailSuccessMessage,
    fetchFinance,
    setFinanceData,
    setLoanDetails,
    setLoanType,
    setCheckoutEditCompletedStages,
    setFinanceSelectedCycle,
    setPostFinanceScreen,
    setLoanTenureValue,
    setCreditScoreValue,
    setDownPaymentValue,
    setMonthlyEmiValue,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    setFinancePersonalDetails,
    createOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateOrderBooking,
    initiateOrderPayment,
    setFinanceUpdatedData,
    setFinancingScreen,
    cancelOrder,
    setFinanceChargeList,
    softResetCheckoutData,
    resetCheckoutData,
    getPaymentStatus,
    setMaxStep,
    sendMobileOTP,
    updateByoLoan,
    // updatePreApprovedLoan,
    getLoanOfferDetails,
    createLoanOffer,
    updateLoanOffer,
    // updateVisitedSteps
    setRecalculateTriggered,
    requestCallback,
    getTradeInDetails,
    createTradeIn,
    updateTradeIn,
    acceptTradeIn,
    submitTradeIn,
    fetchEgcPrice,
    customerOptedTrade,
    getTradeInState,
    updateOrderTradeDetail,
    saveCheckoutSourcePathname,
    getByoLoan,
    setSplitPayment,
    updateSplitPayment,
    setSplitPaymentUserState,
    getPaymentHistory,
    getPaymentHistoryInit,
    getSignContract,
    postSignContract,
    updateSignContractStatus,
    signContractPollStatus,
    paymentInit,
    sessionInit,
    setCurrentDeliveryStep,
    getTradeVehicleDetails,
    getStripePublicKey,
    updateBasicHubspot,
    getLoanOfferDetailsSuccess,
    getPromoCodeDetails,
    applyPromoCode,
    resetApplyPromoCode,
    applyPromoCodeSuccess,
    removePromoCode,
    getWarrantyPlans,
    updateWarrantyPlans,
    getTestDriveSlots,
    getBookingSummary,
    updateOrder,
    getTestDriveData,
    checkInHouseFinanceEligibility,
    getValidQuotesInHouseFinance,
    createOrderForSimilarVehichle,
    updateCheckoutSteps,
    createCheckoutSteps,
    getCheckoutSteps,
    registerVerificationStatus,
    verifyIdPollStatus,
    getVerificationStatus,
    resetDrivaFinanceData,
    setSelectedQuote,
    saveTradeInRegoandVin,
    saveTradeInCarMakeModelId,
    resetTradeInDetails,
    getTradeInCarVariants,
    getTradeInCarMakeModel,
    postEventImpressionSource,
    fetchCancellationReason,
    fetchCheckoutCallbackRequest,
    postCheckoutCallbackRequest as checkoutCallbackRequest,
    setZeroDpSelectOfferCoachMark,
    setLoanOffersCoachMark,
    postZeroDpPaymentApi,
    postReBookZeroDpOrder,
    uploadBankTransferProof,
    confirmBankTransfer,
    getZeroDpTextDataConfig,
    resetLoanOfferDetailsforGuest,
    resetCheckoutFinanceData,
    setCheckoutEditMode,
    getLoanOfferFAQ,
    setSelectOfferScreenLoader,
    updateCheckoutPreApprovalStep,
    setCheckoutEditFormExitModal,
    setCheckoutEditQuestionsUpdateModal,
    setCheckoutEditFormData,
    setMakeFlowMaxStep,
    setMakeFlowCurrentStep,
    setMakeFlowHardRejection,
    setMakeFlowRecheduleflag,
    setMakeModelYear,
    setMmvyUserInputs,
    setLoanOfferScreen,
    updateMakeModelFlow,
    getTradeInDetailsV2,
    getBankDetails,
    updateChargeDetails,
    getAdyenFailure,
    adyenFailurePollStatus,
    getCheckoutSummaryFAQ,
    updateTestDriveDetails,
    getIsStraightThroughEligble,
    populateEmailFromToken,
    updateValidC2bOrder
};
