
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import Checkbox from "../../shared/checkbox/component";

const PaymentAgreeTerms = ({onClickCheckBox, origin = ""}) => {

    const trackEvent = (eventAction, eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS[eventAction], { eventLabel});
    };

    return (
        <div styleName={"styles.termsWrapper"}>
            <Checkbox id="Terms" checked={true} onClickCallback={onClickCheckBox}/>
            <p styleName={"styles.termsCondition"}>I agree to the{" "}
                <a href="/terms-of-purchase" target="_blank" onClick={(e) => trackEvent(e, "terms_of_purchase")}>
        Terms of {origin === "VariantB" ? "Purchase" : "use"}
                </a>{" "}and{" "}
                <a href="/privacy-policy-statement" target="_blank" onClick={(e) => trackEvent(e, "privacy_policy")}>
        Privacy Policy
                </a>.
            </p>
        </div>
    );
};

PaymentAgreeTerms.propTypes = {
    onClickCheckBox: PropTypes.func,
    origin: PropTypes.string
};

export default PaymentAgreeTerms;
