import { X_VEHICLE_TYPE } from "../constants/api-constants";

// eslint-disable-next-line max-statements
export default (api) => {

    const subsribeEmail = (params) => {
        return api.post(`/public/subscribe`, {...params}, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car
            }
        });
    };

    const unsubsribeEmail = (params) => {
        return api.post(`/public/unsubscribe`, {...params}, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car
            }
        });
    };

    return {
        subsribeEmail,
        unsubsribeEmail
    };
};
