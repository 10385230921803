import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SAVE_TRADE_IN_REGO_VIN
    SAVE_TRADE_IN_ID
`,
    {
        prefix: "tradeincarmake/"
    }
);
