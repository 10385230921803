/* eslint-disable max-statements */
/* eslint-disable new-cap */
import styles from "./styles.css";
//import "@adyen/adyen-web/dist/adyen.css";
import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { Helmet } from "react-helmet";
import formQueryParams from "../../../utils/helpers/form-query-params";
import { adyenStyles } from "./adyen-styles";
import loadable from "@loadable/component";
const AdyenCheckout = loadable.lib(() => import("@adyen/adyen-web"));

const configuration = {
    environment: process.env.ADYEN_ENV,
    clientKey: process.env.ADYEN_CLIENT_KEY,
    analytics: {
        enabled: true // Set to false to not send analytics data to Adyen.
    },
    paymentMethodsConfiguration: {
        card: {
            name: "Debit/Credit Card",
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: false
        }
    }
};

const AdyenPaymentComp = ({
    sessionData,
    paymentConfig
}) => {
    const paymentContainer = useRef(null);
    const adyenReference = useRef(null);
    const type = "dropin";
    const history = useHistory();
    const { location } = history;
    const queryParams = parseLocationSearchParams(location.search);
    const { id, sessionData: sessionDataUrl, cardType} = queryParams;

    const { successUrl, failureUrl } = paymentConfig || {};
    const cardTypeNames = {
        DEBIT: "DEBIT CARD",
        CREDIT: "CREDIT CARD"
    };

    if (cardType in cardTypeNames) {
        configuration.paymentMethodsConfiguration.card.name = cardTypeNames[cardType];
    }

    useEffect(() => {
        if (!sessionData && !sessionDataUrl) {
            history.goBack();
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    const getRedirectUrl = useCallback((resultCode, params) => {
        const query = formQueryParams(params);
        switch (resultCode) {
        case "Authorised":
            return `${successUrl}${query}`;
        case "Pending":
        case "Received":
            return `${failureUrl}${query}`;
        case "Refused":
            return `${failureUrl}${query}`;
        default:
            return `${failureUrl}${query}`;
        }
    }, [failureUrl, successUrl]);

    const createCheckout = async (adyenInstance) => {
        if (!id || !adyenInstance || typeof window === "undefined" || !window?.document) return;
        const checkout = await adyenInstance({
            ...configuration,
            session: { id, sessionData: sessionData || sessionDataUrl, countryCode: "AU" },
            onPaymentCompleted: (response) => {
                window.location.href = getRedirectUrl(response.resultCode);
            },
            onError: (error) => {
                window.location.href = getRedirectUrl("Refused", { message: error.message });
            }
        });
        if (paymentContainer.current) {
            checkout.create(type).mount(paymentContainer.current);
        }
    };

    const adyenFallback = () => {
        return (<React.Fragment/>);
    };

    return (
        <React.Fragment>
            <AdyenCheckout ref={adyenReference} fallback={adyenFallback} >
                { (data) =>  {
                    const adyenInstance = data?.default ? data.default : data;
                    createCheckout(adyenInstance);
                    return <React.Fragment/>;
                }}
            </AdyenCheckout>
            <Helmet>
                {/* <script src="https://pay.google.com/gp/p/js/pay.js"/> */}
                <style>
                    {`${adyenStyles}`}
                </style>
            </Helmet>
            <div styleName={"styles.paymentContainers"}>
                <div ref={paymentContainer} className="payment" />
            </div>
        </React.Fragment>
    );
};

AdyenPaymentComp.propTypes = {
    paymentConfig: PropTypes.object,
    sessionData: PropTypes.string
};

export default AdyenPaymentComp;

