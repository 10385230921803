import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    carTradeInRegoVin: {},
    carTradeInCarMakeModelId: {}
};

export const saveTradeInRegoVin = (state = INITIAL_STATE, { data }) => ({...state, carTradeInRegoVin: data});

export const saveTradeInCarMakeModelId = (state = INITIAL_STATE, { data }) => ({...state, carTradeInCarMakeModelId: data});

export const HANDLERS = {
    [Types.SAVE_TRADE_IN_REGO_VIN]: saveTradeInRegoVin,
    [Types.SAVE_TRADE_IN_ID]: saveTradeInCarMakeModelId
};

export default createReducer(INITIAL_STATE, HANDLERS);
