/* eslint-disable max-params */

import { objectToQueryString } from "../utils/helpers/checkout-util";

// eslint-disable-next-line max-statements
export default (api) => {
    const getVASBundles = ({ token, year, price, experiment, tag, orderId }) => {
        let url = `/api/v1/landing-page?carYear=${year}&carPrice=${price}`;
        if (experiment) {
            url = `/api/v1/landing-page?carYear=${year}&carPrice=${price}&experiment=${experiment}&tag=${tag}&orderId=${orderId}`;
        }
        return api.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getVASItems = ({ token, key = "VAS_POPULAR_BUNDLES", year, price, experiment, tag }) => {
        let url = `/api/v1/vas-store?key=${key}&carYear=${year}&carPrice=${price}`;
        if (experiment && tag) {
            url = `/api/v1/vas-store?key=${key}&carYear=${year}&carPrice=${price}&experiment=${experiment}&tag=${tag}`;
        }
        return api.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const validateServicingPriceExperiment = ({ token, appointmentId, pincode, servicingPricingExperiment = "OLD" }) => {
        return api.get(`/api/v1/validate-experiment/${servicingPricingExperiment}?appointmentId=${appointmentId}&pincode=${pincode}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getCarCoverBundle = ({ token, appointmentId, servicingPricingExperiment = "OLD", tradeIn = false, orderId, tag }) => {
        return api.get(`api/v1/car-cover-page?tag=${tag}&tradeIn=${tradeIn}&orderId=${orderId}&experiment=${servicingPricingExperiment}&appointmentId=${appointmentId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const addVASItem = ({ token, orderId, type, key, year, price, vasPageName }) => {
        const queryString = objectToQueryString({ carYear: year, carPrice: price, vasPageName });
        let url = `/api/v1/vas/add-item/${orderId}/${type}/${key}`;
        if (queryString) {
            url += `?${queryString}`;
        }
        return api.post(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const removeVASItem = ({ token, orderId, type, key, year, price, vasPageName }) => {
        const queryString = objectToQueryString({ carYear: year, carPrice: price, vasPageName });
        let url = `/api/v1/vas/remove-item/${orderId}/${type}/${key}`;
        if (queryString) {
            url += `?${queryString}`;
        }
        return api.put(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const getVASCart = ({ token, orderId, year, price, vasPageName }) => {
        const queryString = objectToQueryString({ carYear: year, carPrice: price, vasPageName });
        let url = `/api/v1/vas/${orderId}`;
        if (queryString) {
            url += `?${queryString}`;
        }
        return api.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const clearVASCart = ({ token, orderId, year, price, vasPageName }) => {
        const queryString = objectToQueryString({ carYear: year, carPrice: price, vasPageName });
        let url = `/api/v1/vas/clear/${orderId}`;
        if (queryString) {
            url += `?${queryString}`;
        }
        return api.put(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const vasTracker = (token, orderId, action) => {
        return api.put(`/api/v1/vas-tracker?orderId=${orderId}&action=${action}`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const getVASProductDetail = ({ token, key, carYear, type, carPrice }) => {
        return api.get(`/api/v1/detail-page?key=${key}&carYear=${carYear}&type=${type}&carPrice=${carPrice}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getWarrantyPlans = (token, params = "") => {
        return api.get(`/v1/extended-warranty/plans${params}`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    return {
        getVASBundles,
        getVASItems,
        getVASCart,
        addVASItem,
        removeVASItem,
        clearVASCart,
        vasTracker,
        getVASProductDetail,
        getWarrantyPlans,
        validateServicingPriceExperiment,
        getCarCoverBundle
    };
};
