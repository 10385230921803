import React from "react";
import styles from "./styles.css";
import Gradient from "./images/gradient.svg";
import Comma from "./images/coma.svg";
import PropTypes from "prop-types";

const OrderSummaryTestimonialCard = ({image, customerName, customerLocation, detailText }) => {
    return (
        <div styleName={"styles.cardOuter"}>
            <div styleName={"styles.gradientImage"}>
                <img src={Gradient} />
            </div>
            <div styleName={"styles.comaWrap"}>
                <img src={Comma} />
            </div>
            <div className="media" styleName={"styles.mediaWrap"}>
                <img src={image} />
                <div className="media-body">
                    <p styleName={"styles.name"}>{customerName}</p>
                    <p styleName={"styles.location"}>{customerLocation}</p>
                </div>
            </div>
            <p styleName={"styles.comment"}>{detailText}</p>
        </div>
    );
};

OrderSummaryTestimonialCard.propTypes = {
    id: PropTypes.string,
    image: PropTypes.string,
    customerName: PropTypes.string,
    customerLocation: PropTypes.string,
    detailText: PropTypes.string
};

export default OrderSummaryTestimonialCard;
