import { v1CommercialResetQuestions, V1_COMMERCIAL_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-commercial";
import { v1AutopayResetQuestions, V1_AUTOPAY_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-autopay";
import { v1CommonResetQuestions, V1_COMMON_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-common";
import { v1PlentiResetQuestions, V1_PLENTI_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-plenti";
import { FINANCE_FORMS_ID } from "../../../constants/checkout-constants";
import { v1PepperResetQuestions, V1_PEPPER_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-pepper";
import { v1GreenlightResetQuestions, V1_GREENLIGHT_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-greenlight";

const { v1Common,  v1Plenti, v1Autopay, v1Greenlight, v1Pepper, v1Commercial } = FINANCE_FORMS_ID;
const formMapping = {
    [v1Common]: V1_COMMON_CONFIG,
    [v1Plenti]: V1_PLENTI_CONFIG,
    [v1Autopay]: V1_AUTOPAY_CONFIG,
    [v1Pepper]: V1_PEPPER_CONFIG,
    [v1Greenlight]: V1_GREENLIGHT_CONFIG,
    [v1Commercial]: V1_COMMERCIAL_CONFIG
};
const resetArrayMapping = {
    [v1Common]: v1CommonResetQuestions,
    [v1Plenti]: v1PlentiResetQuestions,
    [v1Autopay]: v1AutopayResetQuestions,
    [v1Pepper]: v1PepperResetQuestions,
    [v1Greenlight]: v1GreenlightResetQuestions,
    [v1Commercial]: v1CommercialResetQuestions
};

export {
    formMapping,
    resetArrayMapping
};
