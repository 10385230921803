const PRE_APPROVAL_QUESTION_INITIAL_STATE = {
    "identifier": "",
    "question": "",
    "questionHelperText": "",
    "additionalInfo": {
        "description": [],
        "socialProof": ""
    },
    "showInfoForInput": false,
    "additionalInfoForInputs": {}
};

export const PRE_APPROVAL_QUESTIONS_INITIAL_STATE = {
    "FINANCE_FOR_BUSINESS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "BUSINESS_TYPE": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "BUSINESS_START_DATE": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "ABN": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "GST": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "GST_REGISTRATION_DATE": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "DEPOSIT": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "LOAN_TERM": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "EMPLOYMENT_STATUS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "EMPLOYMENT_START_DATE": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "EMPLOYMENT_DURATION": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "GROSS_INCOME": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "MORTGAGE_RENT_EXPENSES": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "NAME": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "DOB": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "LIVING_SITUATION": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "DEPENDENTS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "RELATIONSHIP_STATUS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "PARTNER_GROSS_INCOME": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "RESIDENCY_STATUS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "ADDRESS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "OLD_ADDRESS": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "VISA_CLASS_NUMBER": PRE_APPROVAL_QUESTION_INITIAL_STATE,
    "GROSS_INCOME_GREATER_THAN_5000000": {
        title: "",
        infoNote: ""
    },
    "GROSS_INCOME_LESS_THAN_20000": {
        title: "",
        infoNote: ""
    },
    "GROSS_INCOME_LESS_THAN_EXPENSES": {
        title: "",
        infoNote: ""
    },
    "RENT_AND_HEM_GREATEN_THAN_GROSS_INCOME": {
        title: "",
        infoNote: ""
    }
};
