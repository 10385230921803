import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    toast: null
};

export const showToast = (state = INITIAL_STATE, { toast }) => {
    return { ...state, toast };
};

export const hideToast = (state = INITIAL_STATE) => {
    return { ...state, toast: null };
};

export const HANDLERS = {
    [Types.SHOW_TOAST]: showToast,
    [Types.HIDE_TOAST]: hideToast
};

export default createReducer(INITIAL_STATE, HANDLERS);
