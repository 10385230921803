import { API_SOURCE } from "../../constants/app-constants";
import { getDeviceType } from "./get-device-type";

const platformSources = {
    [API_SOURCE.M_SITE]: "M-site",
    [API_SOURCE.WEB_APP]: "Desktop"
};

export const contactUsFormQuerySources = {
    contactUsPage: "Contact Us Page",
    helpSupportButton: "Help & Support Button"
};

export const contactUsFormMetaData = ({ querySource }) => {
    return {
        querySource,
        platformSource: platformSources[getDeviceType()]
    };
};
