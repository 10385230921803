/* eslint-disable complexity */
import { LICENCE_TYPE_OPTIONS, LICENCE_STATE_OPTIONS, TEMPORARY_VISA } from "../../constants/checkout-constants";
import * as Yup from "yup";
import { NUMBER } from "../../constants/app-constants";

const V4_UPLOAD_CONFIG = ({ components = {}, formData, financeData }) => {
    const { user } = financeData || {};
    const { residency, relationshipStatus } = user || {};
    let { drivingLicenseDetails = {} } = formData || {};

    drivingLicenseDetails = drivingLicenseDetails || {};
    formData = {
        ...formData,
        drivingLicenseDetails: {
            media: !drivingLicenseDetails.media ? [null, null] : drivingLicenseDetails.media,
            ...drivingLicenseDetails
        }
    };
    return {
        formId: "v1_upload",
        totalStages: 3,
        formTitle: "Upload Documents",
        introTexts: ["Your driving license", residency === TEMPORARY_VISA ?  "Your visa & passport" : "Your medicare card", "Your pay slips"],
        stages: [
            {
                name: "Driving license",
                stageNo: 1,
                totalPages: 1,
                sidebarNavigation: {
                    key: "DriverLicence",
                    title: "Driver's licence",
                    description: "Upload your DL"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            return !!drivingLicenseDetails.licenceType;
                        },
                        questions: [
                            {
                                questionLabel: "Driving license back",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[0]",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Driving license back",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "back",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Driving license front",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.media[1]",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Driving license front",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        type: "front",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Licence type",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceType",
                                    fieldType: components.select,
                                    shortTitle: "Licence type",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_TYPE_OPTIONS,
                                    placeholder: "Type of license you hold",
                                    isRequired: true,
                                    props: {
                                        data: LICENCE_TYPE_OPTIONS
                                    }
                                }]
                            },
                            {
                                questionLabel: "Drivers licence number",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceNumber",
                                    fieldType: components.inputText,
                                    shortTitle: "Drivers licence number",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "DL number as mentioned on your license",
                                    isRequired: true,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Licence state",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "drivingLicenseDetails.licenceState",
                                    fieldType: components.select,
                                    shortTitle: "Licence state",
                                    title: null,
                                    isVisible: true,
                                    options: LICENCE_STATE_OPTIONS,
                                    placeholder: "Select the state you hold your license in",
                                    isRequired: true,
                                    props: {
                                        data: LICENCE_STATE_OPTIONS
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            drivingLicenseDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                media: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            fileName: Yup.string().required("Please upload a valid document.")
                                        }).nullable().required("Please upload a valid document.")
                                    )
                                    .min(NUMBER.TWO, "Please upload a valid document.")
                                    .required("Please upload a valid document.").nullable(),
                                licenceType: Yup.string().required("Please select a valid option ").nullable(),
                                licenceNumber: Yup.string().required("Please enter a valid text").nullable(),
                                licenceState: Yup.string().required("Please select a valid option ").nullable()
                            })
                        }),
                        initialValues: {
                            drivingLicenseDetails: formData.drivingLicenseDetails
                        }
                    }

                ]
            }, {
                name: "Medicare card",
                stageNo: 2,
                totalPages: 1,
                sidebarNavigation: {
                    key: "medicareCard",
                    title: residency === TEMPORARY_VISA ? "Visa and Passport" : "Medicare card",
                    description: residency === TEMPORARY_VISA ? "Add Visa & Passport" : "Add your medicare"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { medicareCard, passportDetails, visaCertificateDetails } = formData || {};
                            if (residency === TEMPORARY_VISA) {
                                const { media: passportDetailsMedia }  = passportDetails || {};
                                const { media: visaCertificateDetailsMedia } = visaCertificateDetails || {};
                                return Array.isArray(passportDetailsMedia) && passportDetailsMedia.filter((value) => value.fileName).length > 0 &&
                                    Array.isArray(visaCertificateDetailsMedia) && visaCertificateDetailsMedia.filter((value) => value.fileName).length > 0;
                            } else {
                                const { media }  = medicareCard || {};
                                return Array.isArray(media) && media.filter((value) => value.fileName).length > 0;
                            }
                        },
                        questions: [
                            ...residency !== TEMPORARY_VISA && ({
                                questionLabel: "Medicare card",
                                questionHelperLabel: null,
                                helperText: "If you don’t have a valid medicare card, please upload your passport as a proxy",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "medicareCard.media",
                                    errorKey: "medicareCard",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Medicare card",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }),
                            ...residency === TEMPORARY_VISA && ({
                                questionLabel: "Visa certificate",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "visaCertificateDetails.media",
                                    fieldType: components.uploadFile,
                                    errorKey: "visaCertificateDetails",
                                    shortTitle: "Visa certificate",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }),
                            ...residency === TEMPORARY_VISA && ({
                                questionLabel: "Passport",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "passportDetails.media",
                                    errorKey: "passportDetails",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Passport",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: false,
                                        maxLength: null,
                                        isFormikFeildArray: false
                                    }
                                }]
                            })
                        ],
                        validate: (values) => {
                            const errors = {};

                            if (residency === TEMPORARY_VISA) {
                                const { passportDetails, visaCertificateDetails } = values || {};
                                if (!passportDetails || !passportDetails.media) {
                                    errors.passportDetails = "Please upload a valid document.";
                                }
                                if (!visaCertificateDetails || !visaCertificateDetails.media) {
                                    errors.visaCertificateDetails = "Please upload a valid document.";
                                }
                                return errors;
                            } else {
                                const { medicareCard } = values || {};
                                if (!medicareCard || !medicareCard.media) {
                                    errors.medicareCard = "Please upload a valid document.";
                                    return errors;
                                }
                            }
                            return errors;
                        },
                        //formValidator: Yup.object().shape({
                        //    medicareCard: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                        //        media: Yup.array().min(NUMBER.ONE, "Please upload a valid document.").required("Please upload a valid document.").nullable()
                        //    }),
                        //    visaCertificateDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value)
                        //        .when("medicareCard", () => {
                        //            console.log("visaCertificateDetails");
                        //            console.log({residency});
                        //            return false;
                        //        })
                        //        .shape({
                        //            media: Yup.array().min(NUMBER.ONE, "Please upload a valid document.").required("Please upload a valid document.").nullable()
                        //        }),
                        //    passportDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value)
                        //        .when("medicareCard", () => {
                        //            console.log("passportDetails");
                        //            console.log({residency});
                        //            return false;
                        //        }).shape({
                        //            media: Yup.array().min(NUMBER.ONE, "Please upload a valid document.").required("Please upload a valid document.").nullable()
                        //        })
                        //}),
                        initialValues: {
                            medicareCard: formData.medicareCard,
                            visaCertificateDetails: formData.visaCertificateDetails,
                            passportDetails: formData.passportDetails
                        }
                    }
                ]
            }, {
                name: "Payslips",
                stageNo: 3,
                totalPages: 1,
                sidebarNavigation: {
                    key: "payslips",
                    title: "Payslips",
                    description: "Add payslips"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { paySlips }  = formData || {};
                            const { media }  = paySlips || {};
                            return Array.isArray(media) && media.filter((value) => value.fileName).length >= NUMBER.ONE;
                        },
                        questions: [
                            {
                                questionLabel: "Payslips",
                                questionHelperLabel:
                                    `Upload last ${(relationshipStatus === "married" || relationshipStatus === "defacto") ? NUMBER.SIX : NUMBER.THREE} most recent payslips. Upload original payslips in PDF format for your loan to go through.`,
                                helperText: (relationshipStatus === "married" || relationshipStatus === "defacto") ?
                                    "Please upload 3 of your most recent payslips. Also upload 2 most recent payslips of your partner too, if available as your lender requires the same for verification" : null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "paySlips.media",
                                    fieldType: components.uploadFile,
                                    shortTitle: "Payslips",
                                    objectDisplayName: "fileName",
                                    readOnlyComponent: "downloadDocument",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Upload or drag files here",
                                    isRequired: true,
                                    props: {
                                        fileSize: 5,
                                        fileExtensionsRegex: /\.(jpe?g|png|pdf)$/i,
                                        fileExtenionText: "PDF, JPEG, JPG, PNG",
                                        mutiUpload: true,
                                        maxLength: (relationshipStatus === "married" || relationshipStatus === "defacto") ? NUMBER.SIX : NUMBER.THREE,
                                        isFormikFeildArray: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            paySlips: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                media: Yup.array().min(NUMBER.ONE, "Please upload a valid document.").required("Please upload a valid document.").nullable()
                            })
                        }),
                        initialValues: {
                            paySlips: formData.paySlips
                        }
                    }
                ]
            }
        ]
    };
};

export {
    V4_UPLOAD_CONFIG
};
