import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkoutCallbackRequest } from "../checkout-info/actions";
import { submitContactUs } from "../email-us-modal/actions";
import HelpCenterModalDoubts from "./component";

const mapStateToProps = ({
    checkout: {
        order: {
            financeOpted
        },
        financeData: {
            user: { mobile },
            loan
        },
        checkoutCallbackRequestLoading,
        callbackDetails: {
            isExpired: checkoutCallbackRequestExpired
        }
    },
    carDetails: {
        content: {
            city: carLocation
        }
    },
    user: {
        mobile: phoneNumber,
        firstName,
        lastName,
        isUserZeroDpVariant
    }
}) => ({
    mobile,
    loan,
    checkoutCallbackRequestLoading,
    checkoutCallbackRequestExpired,
    isUserZeroDpVariant,
    phoneNumber,
    carLocation,
    firstName,
    lastName,
    financeOpted
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    checkoutCallbackRequestConnect: checkoutCallbackRequest,
    submitContactUsConnect: submitContactUs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterModalDoubts);
