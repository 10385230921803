import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ChatWidgetRevamp from "./component";

const mapStateToProps = ({
    supportWidget
}) => ({
    supportWidget
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatWidgetRevamp));
