/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";
import { checkoutSummarySaleLabel } from "../../../utils/helpers/on-sale-label";
import { ORDER_STATUS, DELIVERY_TYPES } from "../../../constants/checkout-constants";

const DriveAwayPriceModal = ({
    priceBreakDown,
    onClose,
    egcData,
    tradeInData,
    tradeinOpted,
    deliveryInfo,
    chargeDetails,
    price,
    saleConfig,
    status,
    testDriveData,
    promoCode,
    deliveryMode
}) => {
    const { deliveryData = {}, isHomeDeliveryInterstateFeesApplicable,  isPickupInterstateFeesApplicable, pickupCharges} = deliveryInfo || {};
    const {  homeDelivery = [] } = deliveryData || {};
    const selectedState = egcData[0]?.stateCode?.replace("AU_", "");
    const {state} = priceBreakDown || {};
    const { amount: testDriveAmount = 0 } = testDriveData || {};

    const { couponCode, discountPrice, isCouponApplied } = promoCode || {};

    let shippingPrice = homeDelivery && homeDelivery[0] && homeDelivery[0].shipping && homeDelivery[0].shipping[0] && homeDelivery[0].shipping[0].shippingPrice || 0;
    const calculateShippingPrice =  deliveryMode === DELIVERY_TYPES.HomeDelivery ? shippingPrice : 0;
    shippingPrice =  calculateShippingPrice > 0 && deliveryMode === DELIVERY_TYPES.HomeDelivery ? calculateShippingPrice : 0;

    const {
        egc,
        motorVehicleDuty,
        transferFee,
        offeredPrice,
        // priceSubLabel,
        dapOrTradePrice,
        ctp,
        registrationCost,
        registrationTenure,
        warrantyLabel,
        windowTinting,
        totalDap,
        vasItems
    } = getTradePriceLabel({egcData, tradeInData, tradeinOpted, deliveryInfo: {...deliveryInfo, shippingPrice, pickupCharges}, dapPrice: price, chargeDetails, discountPrice, deliveryMode});
    const dap = motorVehicleDuty.value + registrationCost.value + ctp.value + transferFee.value;

    const isTradeHigh = totalDap.value <= offeredPrice;

    const saleLabel = checkoutSummarySaleLabel({saleConfig, price, egc});

    const finalAmount = status === ORDER_STATUS.TEST_DRIVE_RESERVED ? dapOrTradePrice + testDriveAmount : dapOrTradePrice;

    return (
        <Modal isOpen={true} onRequestClose={onClose} close={onClose}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.nextStep"}>drive away price</p>
                    <div styleName={"styles.close"}>
                        <CloseButton type="grey" onClickHandler={onClose}/>
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.priceBreakupWrapper"}>
                        <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>Excl. Govt. Charges price</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(egc.value)}</p>
                        </div>
                        <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>{state || selectedState} Gov. charges*</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(dap)}</p>
                        </div>
                        <div styleName={"styles.secondSectionWrap"}>
                            <div styleName={"styles.firstSectionWrapper"}>
                                <p styleName={"styles.leftText"}>Motor vehicle duty</p>
                                <p styleName={"styles.leftText"}>{motorVehicleDuty.label}</p>
                            </div>
                            <div styleName={"styles.firstSectionWrapper"}>
                                <p styleName={"styles.leftText"}>{registrationTenure.value} months registration</p>
                                <p styleName={"styles.leftText"}>{registrationCost.label}</p>
                            </div>
                            <div styleName={"styles.firstSectionWrapper"}>
                                <p styleName={"styles.leftText"}>CTP insurance</p>
                                <p styleName={"styles.leftText"}>{ctp.label}</p>
                            </div>
                            <div styleName={"styles.firstSectionWrapper"}>
                                <p styleName={"styles.leftText"}>Transfer fee</p>
                                <p styleName={"styles.leftText"}>{transferFee.label}</p>
                            </div>
                            {isCouponApplied && dapOrTradePrice > 0 &&
                                <div styleName={"styles.firstSectionWrapper"}>
                                    <p styleName={"styles.leftText"}>Promotion({couponCode})</p>
                                    <p  styleName={"styles.leftText"}>{`-${makePriceLabelRound(discountPrice)}`}</p>
                                </div>
                            }
                        </div>
                        {windowTinting && windowTinting.name && <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>Window Tinting</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(windowTinting.amount)}</p>
                        </div>}
                        {shippingPrice > 0 && deliveryMode === DELIVERY_TYPES.HomeDelivery && <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>{isHomeDeliveryInterstateFeesApplicable ? "Interstate Delivery Charges" : "Delivery charge"}</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(shippingPrice)}</p>
                        </div>
                        }
                        {deliveryMode === DELIVERY_TYPES.PickUp && isPickupInterstateFeesApplicable && <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>Interstate shipping charges</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(pickupCharges)}</p>
                        </div>}
                        {warrantyLabel.label && <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>Extended warranty</p>
                            <p styleName={"styles.priceInBold"}>{makePriceLabelRound(warrantyLabel.amount)}</p>
                        </div>
                        }
                        {(vasItems || []).length > 0 && vasItems.map(vp => {
                            const { discountedPrice, key, name } = vp || {};
                            return (
                                <div key={key} styleName={"styles.firstSectionWrapper"}>
                                    <p styleName={"styles.leftText"}>{name}</p>
                                    <p styleName={"styles.priceInBold"}>{makePriceLabelRound(discountedPrice)}</p>
                                </div>
                            );
                        })}
                        {tradeinOpted &&  <div styleName={"styles.firstSectionWrapper"}>
                            <p styleName={"styles.leftText"}>Trade-in credit</p>
                            <p styleName={"styles.priceInBold"}>-{makePriceLabelRound(offeredPrice)}</p>
                        </div>}
                        <div styleName={"styles.firstSectionWrapper styles.border"}>
                            <p styleName={"styles.leftText"}>{isTradeHigh ? "Payable to you" : "Drive Away Price" }</p>
                            <p styleName={"styles.totalPrice"}>{makePriceLabelRound(Math.abs(finalAmount))}</p>
                        </div>
                        {saleLabel &&   <p styleName="styles.saleDiscount">({saleLabel})</p>}
                    </div>
                    <div styleName={"styles.bottomBox"}>
                        <p styleName={"styles.bottomText"}>
                            *Mandated State government charges payable upon registration and transfer of ownership.
                            We pay all amounts directly to the relevant State government. You will need to pay these
                            charges to the State government even if you don’t register the car through CARS24.<br />100% refundable
                            if you return the car.
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    carDetails: {content: {price}},
    checkout: {
        promoCode,
        order: { chargeDetails, status, deliveryMode},
        egcData,
        tradeInData,
        tradeinOpted,
        testDriveData

    },
    homeCarList: {
        saleConfig
    },
    deliveryInfo
}) => ({
    egcData,
    tradeInData,
    tradeinOpted,
    deliveryInfo,
    chargeDetails,
    price,
    saleConfig,
    status,
    testDriveData,
    promoCode,
    deliveryMode
});

DriveAwayPriceModal.propTypes = {
    priceBreakDown: PropTypes.array,
    onClose: PropTypes.func,
    egcData: PropTypes.array,
    tradeInData: PropTypes.object,
    tradeinOpted: PropTypes.bool,
    deliveryInfo: PropTypes.object,
    chargeDetails: PropTypes.array,
    price: PropTypes.func,
    saleConfig: PropTypes.object,
    status: PropTypes.string,
    testDriveData: PropTypes.object,
    promoCode: PropTypes.object,
    deliveryMode: PropTypes.string
};

export default connect(mapStateToProps, null)(DriveAwayPriceModal);
