import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "intersection-observer";

// CSS files
import "../../../styles/reset.desktop.css";
import "../../../styles/slick.css";
import "../../../styles/slick-theme.css";
import "../../../styles/slick-reset-desktop.css";
import "../../../fonts/fonts.css";

//Components
import ErrorHandler from "../../shared/with-error-handler";
import ToastMessage from "../../shared/toast-message";
import Header from "../header";
import Footer from "../footer";
import Error from "../../shared/error";

// eslint-disable-next-line max-params
export default function withAULayout(WrappedComponent, header = true, footer = true, options = {}) {
    const { headerProps, footerProps = {} } = options;
    const Layout = (props) => {
        const getLayout = () => {
            const {hideSiteHeader = false} = props;
            let component = (
                <Fragment>
                    {(header && !hideSiteHeader) && <Header {...props} {...headerProps} />}
                    <ErrorHandler>
                        <WrappedComponent {...props} />
                    </ErrorHandler>
                    {footer && <Footer {...props} {...footerProps} />}
                    <ErrorHandler>
                        <ToastMessage/>
                    </ErrorHandler>
                </Fragment>
            );
            if (props.isSSRError) {

                component = <Error />;
            }
            return component;
        };

        return getLayout();
    };
    const mapStateToProps = ({ user: { isSSRError, hideSiteHeader} }) => {
        return {
            isSSRError,
            hideSiteHeader
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
