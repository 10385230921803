/* eslint-disable no-nested-ternary */
import React from "react";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { getCheckoutPageURL, getTestDriveV2CheckoutURL } from "../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import CloseButton from "../../shared/icon-cross";
import Credit from "./images/credit.png";
import Close from "./images/close.png";
import EditFlowExitModal from "../edit-flow-exit-modal/component";
import withEditCheckout from "../checkout-edit-flow-hoc";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import { testDriveV2Routes } from "../../../constants/au.desktop/test-drive-v2-routes";

const ApplyForFinance = ({
    checkoutEditFormExitModal = false,
    checkoutEditMode = false,
    completedPercentage,
    content,
    errorScreenCTA = "",
    errorScreenCTACallback = () => {},
    isDrivaErrorScreen = false,
    onFormExit = () => {},
    resetEditMode = () => {},
    setCheckoutEditFormExitModalConnect = () => {},
    title = "Apply for finance",
    preApporvedOrigin
}) => {
    const history = useHistory();

    const onClickBack = () => {
        const isTDflow = preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE;
        const generateNextUrl = isTDflow ? getTestDriveV2CheckoutURL : getCheckoutPageURL;
        const nextRoute = isTDflow ? testDriveV2Routes.financeDetails.route : checkoutRoutes.paymentOptions.route;
        const {relativeURL: paymentOptionsUrl}  = generateNextUrl(content, nextRoute);
        history.push(paymentOptionsUrl);
    };

    return (
        <div styleName={"styles.finance-outer"}>
            <div styleName={"styles.heading"}>
                {isDrivaErrorScreen ?
                    <span><CloseButton type="grey" onClickHandler={onClickBack} /></span> :
                    checkoutEditMode ?
                        <img className={styles.close} src={Close} onClick={onFormExit} /> :
                        <ArrowIcon black="BlackArrow" onClick={onClickBack} rotateBy={90} />
                }
                <p>
                    {title}
                    {isDrivaErrorScreen ?
                        <span><strong styleName={"styles.action"} onClick={errorScreenCTACallback}>{errorScreenCTA}</strong></span> :
                        <div styleName={"credit"}>
                            <img src={Credit} alt="" />
                            <span>No impact on credit score</span>
                        </div>
                    }
                </p>
            </div>
            {/*<hr styleName={"styles.border"} />*/}
            {completedPercentage >= 0 &&
                    <div styleName={"styles.progressBarOuter"}>
                        <div styleName={"styles.progressBarPercent"}
                            style={{ width: `${checkoutEditMode ? 0 : completedPercentage}%` }}>&nbsp;</div>
                    </div>
            }

            {checkoutEditFormExitModal && <EditFlowExitModal open={checkoutEditFormExitModal} onClose={() => setCheckoutEditFormExitModalConnect(false)} onExit={resetEditMode} />}
        </div>
    );
};

ApplyForFinance.propTypes = {
    checkoutEditFormExitModal: PropTypes.bool,
    checkoutEditMode: PropTypes.bool,
    completedPercentage: PropTypes.number,
    content: PropTypes.object,
    errorScreenCTA: PropTypes.string,
    errorScreenCTACallback: PropTypes.func,
    isDrivaErrorScreen: PropTypes.bool,
    onFormExit: PropTypes.func,
    readOnlyFinanceData: PropTypes.object,
    resetEditMode: PropTypes.func,
    setCheckoutEditFormData: PropTypes.func,
    setCheckoutEditFormExitModalConnect: PropTypes.func,
    title: PropTypes.string,
    preApporvedOrigin: PropTypes.string
};

export default withEditCheckout(ApplyForFinance);
