import Types from "./types";

const setNudgeDetails = (details) => {
    return {
        type: Types.SET_NUDGE_DETAILS,
        details
    };
};

const showHelpSupportModal = (data) => {
    return {
        type: Types.SHOW_HELP_SUPPORT_MODAL,
        data
    };
};

const showEmailSupportModal = (data) => {
    return {
        type: Types.SHOW_EMAIL_SUPPORT_MODAL,
        data
    };
};

export {
    setNudgeDetails,
    showHelpSupportModal,
    showEmailSupportModal
};
