import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarDeliveryDateCard from "./component";

const mapStateToProps = ({
    carDetails: { content, config },
    checkout: {order: {pickupTime, deliveryMode, deliveryDateRange, updatedDeliveryDate}}
}) => ({
    content,
    config,
    pickupTime,
    deliveryMode,
    deliveryDateRange,
    updatedDeliveryDate
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarDeliveryDateCard);
