import PropTypes from "prop-types";
import React from "react";
import { renderRoutes } from "react-router-config";
import { withRouter, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import AUConfiguration from "./components/au.configuration";
import DesktopRoot from "./components/au.desktop/root";
const CarDetail = loadable(() => import("./pages/au.desktop/car-details"));
import Home from "./pages/au.desktop/home";
// import CarListing from "./pages/au.desktop/car-listing";
// import Checkout from "./pages/au.desktop/checkout";
// import NotFound from "./pages/au.desktop/not-found";
const AppleOAuthCallback = loadable(() => import("./pages/au.desktop/apple-oauth-callback"));
// const CancellationRequest = loadable(() => import(/* webpackPreload: true */ "./pages/au.desktop/cancellation-request"));
const CarListing = loadable(() => import("./pages/au.desktop/car-listing"), {ssr: true});
const Checkout = loadable(() => import("./pages/au.desktop/checkout"));
const NotFound = loadable(() => import("./pages/au.desktop/not-found"));
const CancellationRequest = loadable(() => import("./pages/au.desktop/cancellation-request"));
const TradeInYourCarPage = loadable(() => import("./pages/au.desktop/trade-in-your-car"));

const ContactUs = loadable(() => import("./pages/au.desktop/contact-us"));
const ThreeHundredPointInspection = loadable(() => import("./pages/au.desktop/three-hundred-point-inspection"));
const CarQuality = loadable(() => import("./pages/au.desktop/car-quality"));
const TermsOfUse = loadable(() => import("./pages/au.desktop/terms-of-use"));
const TermsOfPurchase = loadable(() => import("./pages/au.desktop/terms-of-purchase"));
const TermsOfSale = loadable(() => import("./pages/au.desktop/terms-of-sale"));
const PrivacyPolicy = loadable(() => import("./pages/au.desktop/privacy-policy"));
const PrivacyPolicyDisclosureStatement = loadable(() => import("./pages/au.desktop/privacy-policy-statement"));
const AboutUs = loadable(() => import("./pages/au.desktop/about-us"));
const GetPreApproval = loadable(() => import("./pages/au.desktop/get-pre-approval"));

const WishlistPage = loadable(() => import("./pages/au.desktop/wishlist"));
const MyBookings = loadable(() => import("./pages/au.desktop/my-bookings"));
const MySellOrders = loadable(() => import("./pages/au.desktop/my-sell-orders"));
const CareerRevamp = loadable(() => import("./pages/au.desktop/career-revamp"));
const Finance = loadable(() => import("./pages/au.desktop/finance"));

const SiteMap = loadable(() => import("./pages/au.desktop/sitemap"));
const WarrantySixMonth = loadable(() => import("./pages/au.desktop/warranty-page-six-month"));
const Warranty = loadable(() => import("./pages/au.desktop/warranty-page"));

const OrderTasks = loadable(() => import("./pages/au.desktop/order-tasks-wrapper"));

const BookInspection = loadable(() => import("./au-c2b/au.desktop/container/book-inspection"));
const BookInspectionConfirmation = loadable(() => import("./au-c2b/au.desktop/container/book-inspection-confirmation"));
const C2bTradein = loadable(() => import("./au-c2b/au.desktop/container/checkout/desktop"));
const CarValuationPage  = loadable(() => import("./au-c2b/au.desktop/container/car-valuation"));
const MakeModelFlow = loadable(() => import("./au-c2b/au.desktop/components/make-model-flow"));

const ExternalLinkPage = loadable(() => import("./pages/au.desktop/external-link-page"));
const SellCarHome = loadable(() => import("./au-c2b/au.desktop/container/home"));
const MyAccount = loadable(() => import("./pages/au.desktop/my-account"));

const TestDriveCheckout = loadable(() => import("./pages/au.desktop/test-drive"));
const BankStatementAppWrapper = loadable(() => import("./components/shared/bank-statements-app-wrapper"));
const ReviewRevamp = loadable(() => import("./pages/au.desktop/review-revamp/component"));
const unsubscribeEmail = loadable(() => import("./components/shared/unsubscribe-email-info"), { ssr: false });
const PostPaymentJourney = loadable(() => import("./pages/au.desktop/post-payment-journey"));
const GuestCheckoutPostBooking = loadable(() => import("./components/au.desktop/guest-checkout-post-booking"));
const TestDriveV2Checkout = loadable(() => import("./pages/au.desktop/test-drive-v2"));

import TestDriveSummary from "../client/components/au.desktop/test-drive-confirmation-page";
import GreenId from "./components/shared/green-id";

const Root = ({ route }) => {

    return (
        <AUConfiguration type="desktop">
            <DesktopRoot>
                {renderRoutes(route.routes)}
            </DesktopRoot>
        </AUConfiguration>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const routes = [
    {
        path: "/",
        component: withRouter(Root),
        init: "./init-top.au.desktop",
        routes: [
            {
                path: ["/"],
                exact: true,
                // name: "au.home",
                component: Home,
                init: "./au/home.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)",
                exact: true,
                name: "au.car-details",
                component: CarDetail,
                init: "./au/details.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/gallery",
                exact: true,
                name: "au.car-details",
                component: CarDetail,
                init: "./au/details.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/checkout/:stepid",
                exact: true,
                name: "au.car-details",
                component: Checkout,
                init: "./au/checkout.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/book-test-drive/:stepid",
                exact: true,
                name: "au.car-details",
                component: TestDriveV2Checkout,
                init: "./au/checkout.desktop"
            },
            {
                path: "/buy-used-:carMakeModel-car-:lid(\\d+)/test-drive/checkout/:stepid",
                exact: true,
                name: "au.car-details",
                component: TestDriveCheckout,
                init: "./au/checkout.mobile"
            },
            {
                path: "/buy-used-:filterQuery",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.desktop"
            },
            {
                path: "/used-cars-in-:filterQuery",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.desktop"
            },
            {
                path: "/australia-used-cars",
                exact: true,
                name: "au.listing",
                component: CarListing,
                init: "./au/listing.desktop.static"
            },
            {
                path: "/warranty",
                exact: true,
                name: "warranty",
                component: WarrantySixMonth
            },
            {
                path: "/cars24cover",
                exact: true,
                name: "warranty",
                component: Warranty
            },
            {
                path: "/wishlist",
                exact: true,
                component: WishlistPage,
                name: "au.wishlist"
            },
            {
                path: "/my-bookings",
                exact: true,
                component: MyBookings,
                name: "au.my-bookings"
            },
            {
                path: "/reviews",
                exact: true,
                component: ReviewRevamp,
                name: "au.review-revamp"
            },
            {
                path: "/my-sell-orders",
                exact: true,
                component: MySellOrders,
                name: "au.my-sell-orders"
            },
            {
                path: "/my-bookings-:carId/:stepid",
                exact: true,
                component: OrderTasks,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/:carId/verify/:stepid",
                exact: true,
                component: PostPaymentJourney,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/:carId/guest/verify-user",
                exact: true,
                component: GuestCheckoutPostBooking,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/my-bookings/bank-statements/:orderId",
                exact: true,
                component: BankStatementAppWrapper,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/trade-in",
                exact: true,
                name: "trade-in",
                component: () => <Redirect to="/sell-your-car/" />
            },
            {
                path: "/trade-in-your-car/:stepid",
                exact: true,
                name: "trade-in-your-car",
                component: TradeInYourCarPage
            },
            {
                path: "/my-bookings-:carId/test-drive/summary",
                exact: true,
                component: TestDriveSummary,
                name: "au.my-bookings-tasks"
            },
            {
                path: "/contact-us",
                exact: true,
                name: "contact-us",
                component: ContactUs
            },
            {
                path: "/cars24-finance",
                exact: true,
                name: "finance",
                component: Finance,
                init: "./au/finance-landing.desktop"
            },
            {
                path: "/unsubscribe-email",
                exact: true,
                name: "unsubscribe-email",
                component: unsubscribeEmail
            },
            {
                path: "/300-point-inspection",
                exact: true,
                // name: "300-point-inspection",
                component: ThreeHundredPointInspection
            },
            {
                path: "/thecars24way",
                exact: true,
                // name: "car-quality",
                component: CarQuality
            },
            {
                path: "/terms-of-use",
                exact: true,
                // name: "terms-of-use",
                component: TermsOfUse
            },
            {
                path: "/terms-of-purchase",
                exact: true,
                // name: "terms-of-purchase",
                component: TermsOfPurchase
            },
            {
                path: "/terms-of-sale",
                exact: true,
                component: TermsOfSale
            },
            {
                path: "/Cancellation-request",
                exact: true,
                // name: "privacy-policy",
                component: CancellationRequest
            },
            {
                path: "/privacy-policy",
                exact: true,
                // name: "privacy-policy",
                component: PrivacyPolicy
            },
            {
                path: "/privacy-policy-statement",
                exact: true,
                component: PrivacyPolicyDisclosureStatement
            },
            {
                path: "/about-us",
                exact: true,
                name: "about-us",
                component: AboutUs
            },
            {
                path: "/career",
                exact: true,
                name: "career",
                component: CareerRevamp
            },
            {
                path: "/oauth/apple/callback",
                exact: true,
                component: AppleOAuthCallback,
                name: "apple-login-callback"
            },
            {
                path: "/sitemap",
                exact: true,
                name: "sitemap",
                component: SiteMap,
                init: "./au/sitemap.desktop"
            },
            {
                path: "/external",
                exact: true,
                component: ExternalLinkPage,
                name: "au.external-link"
            },
            {
                path: "/sell-your-car",
                exact: true,
                name: "c2b-trade",
                component: SellCarHome,
                init: "./au/sell-your-car.desktop"
            },
            {
                path: "/car-valuation",
                exact: true,
                name: "c2b-trade",
                component: () => <Redirect to="/sell-your-car/" />,
                init: "./au/sell-your-car.desktop"
            },
            {
                path: "/sell-your-car-:city",
                exact: true,
                name: "c2b-trade",
                component: SellCarHome,
                init: "./au/sell-your-car.desktop"
            },
            {
                path: "/sell-your-car/car-valuation/:orderId",
                exact: true,
                name: "c2b-trade",
                component: CarValuationPage
            },
            {
                path: "/sell-your-car/trade-in-car-make/:stepId/:orderId",
                exact: true,
                name: "c2b-trade",
                component: MakeModelFlow
            },
            {
                path: "/book-inspection/:orderId/confirmation",
                exact: true,
                name: "c2b-trade",
                component: BookInspectionConfirmation
            },
            {
                path: "/book-inspection/:orderId/:stepId",
                exact: true,
                name: "c2b-trade",
                component: BookInspection
            },
            {
                path: "/sell-your-car/:stepid/:orderId?",
                exact: true,
                name: "c2b-trade",
                component: C2bTradein
            },
            {
                path: "/financing",
                exact: true,
                // name: "get-pre-approval",
                component: GetPreApproval,
                hideLayoutViaUTM: false
            },
            {
                path: "/financing/:stepid",
                exact: true,
                // name: "get-pre-approval",
                component: GetPreApproval,
                hideLayoutViaUTM: false
            },
            {
                path: "/my-account",
                exact: true,
                name: "my-account",
                component: MyAccount
            },
            {
                path: "/my-account/:stepId",
                exact: true,
                name: "my-account",
                component: MyAccount
            },
            {
                path: "/my-account/financing/:stepId",
                exact: true,
                name: "my-account",
                component: MyAccount
            },
            {
                path: "/licence-verification",
                exact: true,
                // name: "get-pre-approval",
                component: GreenId,
                hideLayoutViaUTM: false
            },
            {
                path: "*",
                exact: true,
                name: "au.not-found",
                component: NotFound,
                init: "./au/notfound.desktop"
            }

        ]
    }
];

export { routes };
