import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTradeInState, updateTradeIn, getTradeInDetails, createTradeIn, setUserUpdating, setMaxStep, getTradeVehicleDetails,  handleRejectModal, getSellOrderDetails } from "../../../au-c2b/au.desktop/components/trade-in-wrapper/actions";
import C2bLandingAtf from "./component";
import withGuestLogin from "../../../au-c2b/au.desktop/components/guest-login";
import withLogin from "../../au.desktop/with-login";
import { withRouter } from "react-router-dom";
import { saveTradeInRegoandVin } from "../../../au-c2b/au.desktop/components/c2b-no-kbb-layout/actions";

const mapStateToProps = ({
    user: {isGuestLogin, isLoggedIn, gaId},
    auc2bTradein: {
        tradeInData,
        tradeInStates,
        rejectModal,
        seoData,
        isFetchingConfig
    }}) => ({
    tradeInData,
    tradeInStates,
    rejectModal,
    seoData,
    isFetchingConfig,
    isLoggedIn,
    isGuestLogin,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTradeInStateConnect: getTradeInState,
    updateTradeInConnect: updateTradeIn,
    getTradeInDetailsConnect: getTradeInDetails,
    createTradeInConnect: createTradeIn,
    setUserUpdatingConnect: setUserUpdating,
    setMaxStepConnect: setMaxStep,
    getTradeVehicleDetailsConnect: getTradeVehicleDetails,
    handleRejectModalConnect: handleRejectModal,
    getSellOrderDetailsConnect: getSellOrderDetails,
    saveTradeInRegoandVinConnect: saveTradeInRegoandVin
}, dispatch);

export default withRouter(withGuestLogin(withLogin(connect(mapStateToProps, mapDispatchToProps)(C2bLandingAtf))));
