/* eslint-disable complexity */
import { NUMBER } from "../../constants/app-constants";
import { BOOLEAN_OPTIONS_FORM, INCOME_LIST,  INCOME_LIST_WITHOUT_YEARLY, EMPLOYEMENT_TYPES, GENDER_OPTIONS, INDUSTRY_TYPE_OPTIONS, OCCUPATION_TYPE_OPTIONS, RELATIONSHIP_STATUS, RELATIONSHIP_STATUS_OPTIONS, NUMBER_OPTIONS, BALLOON_REASONS_OPTIONS, BALLOON_REPAYMENT_METHODS_OPTIONS } from "../../constants/checkout-constants";
import { getOptions } from "../../utils/helpers/checkout-util";
import * as Yup from "yup";
import { getFinanceLendersPrivacyDiscliamers } from "../../utils/helpers/finance-loan-application-util";

const v1PepperResetQuestions = ["otherSourceOfIncome"];
const V1_PEPPER_CONFIG = ({ components = {}, formData }) => {
    const { previousAddress, currentAddressDuration, previousAddressDuration, relationshipStatus = "", lenderName, balloon } =  formData || {};
    let { personalLoans = {}, mortgage = {}, currentEmployment = {}, otherSourceOfIncome = {}, investmentInProperty = {}, creditCards = {} } = formData || {};
    personalLoans = personalLoans || {};
    mortgage = mortgage || {};
    otherSourceOfIncome = otherSourceOfIncome || {};
    currentEmployment = currentEmployment || {};
    investmentInProperty = investmentInProperty || {};
    creditCards = creditCards || {};
    const { questionLabel: privacyPolicyLabel, apiKey: privacyPolicyKey } = getFinanceLendersPrivacyDiscliamers(lenderName) || {};
    const currentAddressLessThanTwo = ((currentAddressDuration || {}).year < NUMBER.TWO);

    return {
        formId: "v1_pepper",
        totalStages: 6,
        formTitle: `${lenderName} loan application`,
        readOnlyTitle: "Review your details",
        stages: [
            {
                name: "Additional personal details",
                isReadDisabled: false,
                intermediateScreenText: "Let’s add your personal details now",
                stageNo: 1,
                totalPages: 2,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { gender }  = formData || {};
                            return !!gender;
                        },
                        questions: [
                            {
                                questionLabel: "Your gender",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "gender",
                                    shortTitle: "Gender",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: GENDER_OPTIONS,
                                    placeholder: "Your gender",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: GENDER_OPTIONS,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Your relationship status",
                                questionHelperLabel: null,
                                helperText: "Filled based on your pre-approval form details",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "relationshipStatus",
                                    shortTitle: "Relationship status",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: RELATIONSHIP_STATUS_OPTIONS,
                                    placeholder: "Your relationship status",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: RELATIONSHIP_STATUS_OPTIONS,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            gender: Yup.string().required("Enter a valid input").nullable("Enter a valid input"),
                            relationshipStatus: Yup.string().required("Enter a valid input").nullable("Enter a valid input")
                        }),
                        initialValues: {
                            gender: formData.gender,
                            relationshipStatus: formData.relationshipStatus
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            if (currentAddressLessThanTwo) return currentAddressDuration && previousAddressDuration;
                            return currentAddressDuration;
                        },
                        questions: [
                            {
                                questionLabel: "Duration of stay at current address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "currentAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address year${(currentAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("year", 0, NUMBER.THIRTY)
                                    }
                                }, {
                                    apiKey: "currentAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address month${(currentAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("month", 0, NUMBER.ELEVEN)
                                    }
                                }]
                            },
                            {
                                questionLabel: "Address of previous stay",
                                helperText: null,
                                isRequired: true,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "previousAddress",
                                    errorKey: "previousAddress.full",
                                    fieldType: components.locationPicker,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: true,
                                    props: {
                                        value: previousAddress
                                    }
                                }]
                            },
                            {
                                questionLabel: "Duration of stay at previous address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "previousAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address year${(previousAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "previousAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address month${(previousAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            currentAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                            }),
                            ...currentAddressLessThanTwo && ({
                                previousAddress: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    full: Yup.string().required("Enter valid input").nullable("Enter valid input")
                                }),
                                previousAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                    month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                                })
                            })
                        }),
                        initialValues: {
                            currentAddressDuration: formData.currentAddressDuration,
                            previousAddressDuration: formData.previousAddressDuration,
                            previousAddress: formData.previousAddress
                        }
                    }
                ],
                sidebarNavigation: {
                    key: "personalDetails",
                    title: "Personal details",
                    description: "Add personal details"
                }
            },
            {
                name: "Employment & Income",
                intermediateScreenText: "Let’s add your employment details now",
                isReadDisabled: false,
                stageNo: 2,
                totalPages: 3,
                isCompleted: () => {
                    const { occupation }  = currentEmployment || {};
                    return occupation;
                },
                sidebarNavigation: {
                    key: "employmentIncome",
                    title: "Employment details",
                    description: "Add income details"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { annualGrossIncome  }  = formData || {};
                            const { income, frequency }  = annualGrossIncome || {};
                            return income && frequency;
                        },
                        questions: [
                            {
                                questionLabel: "Your gross income",
                                questionHelperLabel: null,
                                helperText: "Your annual gross income shown based on your pre-approval form details, please edit if you wish to. ",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "annualGrossIncome.income",
                                    shortTitle: "Your gross income",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "annualGrossIncome.frequency",
                                    shortTitle: "Your gross income frequency",
                                    fieldType: components.radioButtonGroup,
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "Your partner's gross income",
                                questionHelperLabel: "Don't worry, your partner will not be added as a co-applicant! We only need this to allocate your expenses accurately.",
                                helperText: "Your partner's gross income shown based on your pre-approval form details, please edit if you wish to. ",
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partnerAnnualGrossIncome.income",
                                    shortTitle: "Partner's gross income",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "partnerAnnualGrossIncome.frequency",
                                    shortTitle: "Partner's gross income frequency",
                                    fieldType: components.radioButtonGroup,
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            annualGrossIncome: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                income: Yup.number().required(`Your income should be > 0`).min(1, "Your income should be > 0").nullable("Enter a valid input"),
                                frequency: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            annualGrossIncome: formData.annualGrossIncome,
                            partnerAnnualGrossIncome: formData.partnerAnnualGrossIncome
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            const { occupation }  = currentEmployment || {};
                            return occupation;
                        },
                        questions: [
                            {
                                questionLabel: "Current Employer",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "currentEmployment.occupation",
                                    shortTitle: "Occupation description",
                                    title: "What best describes your occupation?",
                                    isVisible: true,
                                    isRequired: true,
                                    fieldType: components.select,
                                    options: OCCUPATION_TYPE_OPTIONS,
                                    placeholder: "Select occupation type",
                                    props: {
                                        data: OCCUPATION_TYPE_OPTIONS,
                                        isSearchable: false
                                    }
                                }, {
                                    apiKey: "currentEmployment.occupationName",
                                    shortTitle: "Occupation name",
                                    title: "Occupation name",
                                    isVisible: true,
                                    isRequired: true,
                                    fieldType: components.inputText,
                                    options: [],
                                    placeholder: "Enter occupation",
                                    props: {}
                                }, {
                                    apiKey: "currentEmployment.employer",
                                    shortTitle: "Current employer",
                                    title: "Employer",
                                    isVisible: true,
                                    isRequired: true,
                                    fieldType: components.inputText,
                                    options: [],
                                    placeholder: "Enter employer name",
                                    props: {}
                                }, {
                                    apiKey: "currentEmployment.type",
                                    shortTitle: "Current employment type",
                                    helperText: "Filled based on inputs shared in your pre-approval form",
                                    title: "Employment type",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.listTabGroup,
                                    options: EMPLOYEMENT_TYPES,
                                    placeholder: "Enter type",
                                    props: {
                                        data: EMPLOYEMENT_TYPES,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "currentEmployment.industry",
                                    shortTitle: "Current employer industry",
                                    title: "Employer industry",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.select,
                                    options: INDUSTRY_TYPE_OPTIONS,
                                    placeholder: "Select Industry",
                                    props: {
                                        data: INDUSTRY_TYPE_OPTIONS,
                                        isSearchable: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Time with current employer",
                                questionHelperLabel: null,
                                helperText: "Filled based on inputs shared in your pre-approval form",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "currentEmployment.durationYears",
                                    shortTitle: "Current employment years",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "currentEmployment.durationMonths",
                                    shortTitle: "Current employment months",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Previous Employer",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: currentEmployment.durationYears <= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "previousEmployment.occupation",
                                    shortTitle: "Prev. occupation",
                                    title: "What best describes your occupation?",
                                    isVisible: true,
                                    isRequired: true,
                                    fieldType: components.select,
                                    options: OCCUPATION_TYPE_OPTIONS,
                                    placeholder: "Select occupation type",
                                    props: {
                                        data: OCCUPATION_TYPE_OPTIONS,
                                        isSearchable: false
                                    }
                                }, {
                                    apiKey: "previousEmployment.occupationName",
                                    shortTitle: "Prev. occupation description",
                                    title: "Previous occupation name",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.inputText,
                                    options: [],
                                    placeholder: "Enter occupation",
                                    props: {}
                                }, {
                                    apiKey: "previousEmployment.employer",
                                    shortTitle: "Prev. employer",
                                    title: "Previous Employer",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.inputText,
                                    options: [],
                                    placeholder: "Enter employer name",
                                    props: {}
                                },  {
                                    apiKey: "previousEmployment.type",
                                    shortTitle: "Prev. employment type",
                                    title: "Employment type",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.listTabGroup,
                                    options: EMPLOYEMENT_TYPES,
                                    placeholder: "Enter type",
                                    props: {
                                        data: EMPLOYEMENT_TYPES,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "previousEmployment.industry",
                                    shortTitle: "Prev. employer industry",
                                    title: "Employer industry",
                                    isRequired: true,
                                    isVisible: true,
                                    fieldType: components.select,
                                    options: INDUSTRY_TYPE_OPTIONS,
                                    placeholder: "Select Industry",
                                    props: {
                                        data: INDUSTRY_TYPE_OPTIONS,
                                        isSearchable: true
                                    }
                                }]
                            },
                            {
                                questionLabel: "Time with previous employer",
                                questionHelperLabel: null,
                                helperText: "Filled based on inputs shared in your pre-approval form",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: currentEmployment.durationYears <= NUMBER.TWO,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "previousEmployment.durationYears",
                                    fieldType: components.select,
                                    shortTitle: "Prev. employment years",
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    isRequired: true,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "previousEmployment.durationMonths",
                                    fieldType: components.select,
                                    shortTitle: "Prev. employment months",
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    isRequired: true,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            currentEmployment: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                occupation: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                occupationName: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                employer: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                type: Yup.string(`Enter a valid input`).required(`Enter a valid input`).nullable("Enter a valid input"),
                                industry: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                durationYears: Yup.number().required(`Enter a valid input`).min(NUMBER.ZERO, `Enter a valid input`).max(NUMBER.THIRTY, `Enter a valid input`).nullable("Enter a valid input"),
                                durationMonths: Yup.number().required(`Enter a valid input`).min(NUMBER.ZERO, `Enter a valid input`).max(NUMBER.ELEVEN, `Enter a valid input`).nullable("Enter a valid input")
                            }).nullable("Enter a valid input"),
                            previousEmployment: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).when(("currentEmployment"), {
                                is: (duration) => duration.durationYears <= NUMBER.TWO,
                                then: Yup.object().shape({
                                    occupation: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                    occupationName: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                    employer: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                    type: Yup.string(`Enter a valid input`).required(`Enter a valid input`).nullable("Enter a valid input"),
                                    industry: Yup.string().required(`Enter a valid input`).nullable("Enter a valid input"),
                                    durationYears: Yup.number().required(`Enter a valid input`).min(NUMBER.ZERO, `Enter a valid input`).max(NUMBER.THIRTY, `Enter a valid input`).nullable("Enter a valid input"),
                                    durationMonths: Yup.number().required(`Enter a valid input`).min(NUMBER.ZERO, `Enter a valid input`).max(NUMBER.ELEVEN, `Enter a valid input`).nullable("Enter a valid input")
                                })
                            })
                        }),
                        initialValues: {
                            currentEmployment: formData.currentEmployment,
                            previousEmployment: formData.previousEmployment
                        }
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            return otherSourceOfIncome.isOpted === true || otherSourceOfIncome.isOpted === false;
                        },
                        questions: [
                            {
                                questionLabel: "Other sources of income, if any",
                                questionHelperLabel: "Please enter the monthly amount, if applicable",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherSourceOfIncome.isOpted",
                                    shortTitle: "Other income (Y/N)",
                                    title: "Do you have other sources of income?",
                                    isVisible: true,
                                    fieldType: components.listTabGroup,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "otherSourceOfIncome.investmentPropertyIncome",
                                    shortTitle: "Property / rental income",
                                    title: "Investment property, rental income",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherSourceOfIncome.dividendIncome",
                                    shortTitle: "Dividend income",
                                    title: "Dividend income",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherSourceOfIncome.interestIncome",
                                    shortTitle: "Interest income",
                                    title: "Interest income",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherSourceOfIncome.centreLinkIncome",
                                    shortTitle: "Centrelink",
                                    title: "Centrelink",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    priceType: true,
                                    isRequired: false,
                                    note: otherSourceOfIncome.centreLinkIncome > 0 ? "You might be asked by the lender to submit the centrelink income statement later" : ""
                                }, {
                                    apiKey: "otherSourceOfIncome.excludedIncome",
                                    shortTitle: "Other excl. income",
                                    title: "Other excluded income",
                                    isVisible: otherSourceOfIncome.isOpted,
                                    fieldType: components.amountInput,
                                    options: [],
                                    placeholder: "Monthly amount",
                                    priceType: true,
                                    isRequired: false,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            otherSourceOfIncome: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                isOpted: Yup.boolean().required("Enter valid input").nullable()
                            })
                        }),
                        initialValues: {
                            otherSourceOfIncome: formData.otherSourceOfIncome
                        }
                    }
                ]
            },
            {
                name: "Expenses",
                intermediateScreenText: "Let’s add your expenses now",
                isReadDisabled: false,
                stageNo: 3,
                totalPages: 3,
                isCompleted: () => {
                    const { rentAndBoardingExpense } = formData || {};
                    const { expense, frequency } = rentAndBoardingExpense || {};
                    return expense && frequency;
                },
                sidebarNavigation: {
                    key: "expenses",
                    title: "Expenses",
                    description: "Add your expenses"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { rentAndBoardingExpense } = formData || {};
                            const { expense, frequency } = rentAndBoardingExpense || {};
                            return expense && frequency;
                        },
                        questions: [{
                            questionLabel: "Rent / boarding expense",
                            questionHelperLabel: "Total household expense, not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "rentAndBoardingExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Rent / boarding ",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "rentAndBoardingExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Rent / boarding exp. frequency ",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Communications",
                            questionHelperLabel: "Total household expense (incl. landline, mobile, internet), not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "communicationExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Communication",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "communicationExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Comm. exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Transportation",
                            questionHelperLabel: "Total household expense (incl. petrol, parking and transport) , not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "transportExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Transportation",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "transportExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Transport exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Utilities",
                            questionHelperLabel: "Total household expense (incl. power, water, gas), not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "utilitiesAndOtherExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Utilities",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: true,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "utilitiesAndOtherExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Utilities exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Home & content insurance",
                            questionHelperLabel: "Total household expense, not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: false,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "homeAndContentInsuranceExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Home & content insurance",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: false,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "homeAndContentInsuranceExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Home & content insurance exp. frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: false,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }, {
                            questionLabel: "Childcare / Public school fees",
                            questionHelperLabel: "Total household expense, not just your share",
                            helperText: null,
                            inFormNudge: null,
                            isRequired: false,
                            isVisible: true,
                            fieldStyle: null,
                            fields: [{
                                apiKey: "schoolExpense.expense",
                                fieldType: components.amountInput,
                                shortTitle: "Childcare / pub. school fees",
                                title: null,
                                isVisible: true,
                                options: [],
                                placeholder: "Enter expense",
                                isRequired: false,
                                priceType: true,
                                props: {}
                            }, {
                                apiKey: "schoolExpense.frequency",
                                fieldType: components.radioButtonGroup,
                                shortTitle: "Childcare / pub. school fees frequency",
                                title: null,
                                isVisible: true,
                                options: INCOME_LIST_WITHOUT_YEARLY,
                                placeholder: "Enter frequency",
                                isRequired: false,
                                priceType: false,
                                props: {
                                    data: INCOME_LIST_WITHOUT_YEARLY,
                                    isSearchable: false
                                }
                            }]
                        }
                        ],
                        formValidator: Yup.object().shape({
                            rentAndBoardingExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input"),
                                shared: Yup.string("Enter a valid input").nullable("Enter a valid input")
                            }),
                            communicationExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input")
                            }),
                            transportExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input")
                            }),
                            utilitiesAndOtherExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            rentAndBoardingExpense: formData.rentAndBoardingExpense,
                            communicationExpense: formData.communicationExpense,
                            transportExpense: formData.transportExpense,
                            utilitiesAndOtherExpense: formData.utilitiesAndOtherExpense,
                            homeAndContentInsuranceExpense: formData.homeAndContentInsuranceExpense,
                            schoolExpense: formData.schoolExpense
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            const { groceriesAndPetCareExpense } = formData || {};
                            const { expense, frequency } = groceriesAndPetCareExpense || {};
                            return expense && frequency;
                        },
                        questions: [
                            {
                                questionLabel: "Food & groceries",
                                questionHelperLabel: "Total household expense, (incl. alcohol and cigarretes), not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "groceriesAndPetCareExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Food and groceries",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "groceriesAndPetCareExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Food exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "Clothing & personal care",
                                questionHelperLabel: "Total household expense, not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "clothingAndPersonalCareExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Clothing & personal care",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "clothingAndPersonalCareExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Clothing exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Vehicle insurance",
                                questionHelperLabel: "Total household expense, not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "vehicleInsuranceExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Vehicle insurance",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "vehicleInsuranceExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Vehicle insurance frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            },  {
                                questionLabel: "Other household expenses",
                                questionHelperLabel: "Total household expense, not just your share",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherHouseHoldExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Other house exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherHouseHoldExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Other house exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            groceriesAndPetCareExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input")
                            }),
                            clothingAndPersonalCareExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                expense: Yup.number().required("Enter a valid input").nullable("Enter a valid input"),
                                frequency: Yup.string().required("select frequency").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            groceriesAndPetCareExpense: formData.groceriesAndPetCareExpense,
                            clothingAndPersonalCareExpense: formData.clothingAndPersonalCareExpense,
                            vehicleInsuranceExpense: formData.vehicleInsuranceExpense,
                            otherHouseHoldExpense: formData.otherHouseHoldExpense
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            return true;
                        },
                        questions: [
                            {
                                questionLabel: "Health insurance & medical expenses",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "healthInsurance.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Health insurance / medical",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "healthInsurance.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Health insurance / medical exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Life insurance",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "lifeInsuranceExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Life insurance exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "lifeInsuranceExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Life insurance exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "Private education fees",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "privateEducation.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Private education",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "privateEducation.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Private education exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Second motor vehicle costs",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "secondVehicleExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Second vehicle exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "secondVehicleExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Second vehicle exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Expenses for owner owned dwelling",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "occupiedDwellingExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Owner occupied dwelling exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "occupiedDwellingExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Owner occupied dwelling exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Expenses for investment properties",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentPropertiesExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Investment property exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "investmentPropertiesExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Investment property exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Child maintenace / alimony payments",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "childMaintenanceExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Child maintenance exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "childMaintenanceExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Child maintenance exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Other expenses",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherExpense.expense",
                                    fieldType: components.amountInput,
                                    shortTitle: "Other exp.",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "otherExpense.frequency",
                                    fieldType: components.radioButtonGroup,
                                    shortTitle: "Other exp. frequency",
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_WITHOUT_YEARLY,
                                    placeholder: "Enter frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_WITHOUT_YEARLY,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            // healthInsurance: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                            //     expense: Yup.number().required("Food and groceries is required"),
                            //     frequency: Yup.string().required("Food and groceries is required")
                            // }),
                            // privateEducation: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                            //     expense: Yup.number().required("Food and groceries is required"),
                            //     frequency: Yup.string().required("Food and groceries is required")
                            // }),
                            // secondVehicleExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                            //     expense: Yup.number().required("Food and groceries is required"),
                            //     frequency: Yup.string().required("Food and groceries is required")
                            // }),
                            // otherExpense: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                            //     expense: Yup.number().required("Food and groceries is required"),
                            //     frequency: Yup.string().required("Food and groceries is required")
                            // })
                        }),
                        initialValues: {
                            healthInsurance: formData.healthInsurance,
                            lifeInsuranceExpense: formData.lifeInsuranceExpense,
                            privateEducation: formData.privateEducation,
                            secondVehicleExpense: formData.secondVehicleExpense,
                            occupiedDwellingExpense: formData.occupiedDwellingExpense,
                            investmentPropertiesExpense: formData.investmentPropertiesExpense,
                            childMaintenanceExpense: formData.childMaintenanceExpense,
                            otherExpense: formData.otherExpense
                        }
                    }
                ]
            }, {
                name: "Assets",
                intermediateScreenText: "Let’s add your assets now",
                isReadDisabled: false,
                stageNo: 4,
                totalPages: 1,
                isCompleted: () => {
                    const { investedInProperty } = investmentInProperty || {};
                    return investedInProperty === true || investedInProperty === false;
                },
                sidebarNavigation: {
                    key: "assets",
                    title: "Assets",
                    description: "Add your assets"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { investedInProperty } = investmentInProperty || {};
                            return investedInProperty === true || investedInProperty === false;
                        },
                        questions: [
                            {
                                questionLabel: "Do you own an investment property?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.investedInProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Investment property ownership (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Is the property mortgaged?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: investmentInProperty.investedInProperty,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.mortgaged",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Investment property mortgaged (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Investment property value",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: investmentInProperty.investedInProperty,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.propertyValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Investment property value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Commercial property value",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "commercialPropertyValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Commercial property value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign ",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Savings",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "saving",
                                    fieldType: components.amountInput,
                                    shortTitle: "Savings",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Home value",
                                questionHelperLabel: "Enter current value of your home",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "homeValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Home value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Home contents",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "homeContentsValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Home contents",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Existing vehicles(market value)",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "existingVehiclesValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Existing vehicles(market value)",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Shares (value)",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "shareValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Shares (value)",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            investmentInProperty: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                investedInProperty: Yup.boolean().required("Enter a valid input").nullable("Enter a valid input"),
                                mortgaged: Yup.boolean("Enter a valid input").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            investmentInProperty: formData.investmentInProperty,
                            commercialPropertyValue: formData.commercialPropertyValue,
                            saving: formData.saving,
                            homeValue: formData.homeValue,
                            homeContentsValue: formData.homeContentsValue,
                            existingVehiclesValue: formData.existingVehiclesValue,
                            investments: formData.investments,
                            shareValue: formData.shareValue
                        }
                    }
                ]
            }, {
                name: "Liabilities",
                intermediateScreenText: "Let’s add liabilities now",
                hideIntermediateScreen: true,
                isReadDisabled: false,
                stageNo: 5,
                totalPages: 3,
                isCompleted: () => {
                    const { numberOfCards } = creditCards || {};
                    return typeof numberOfCards === "number";
                },
                sidebarNavigation: {
                    key: "liabilities",
                    title: "Liabilities",
                    description: "Add your liabilities"
                },
                jumpToNextStageNo: () => {
                    if (balloon) {
                        return NUMBER.SIX;
                    }
                    return NUMBER.SEVEN;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { numberOfCards } = creditCards || {};
                            return typeof numberOfCards === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many credit cards do you have?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.numberOfCards",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Credit cards owned",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select number of credit cards you own",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# cards should be >=0",
                                        maxError: "# cards should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Credit card 1",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 1 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[0].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 1 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[0].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 1 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Credit card 2",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 2 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[1].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 2 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[1].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 2 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Credit card 3",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 3 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[2].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 3 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[2].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 3 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            creditCards: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfCards: Yup.number().min(0, "# cards should be >=0").max(NUMBER.THREE, "# cards should not exceed 3").required("Enter a valid input").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            creditCards: {
                                ...formData.creditCards,
                                numberOfCards: ((creditCards && creditCards.numberOfCards) || 0)
                            }
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            const { numberOfLoans } = personalLoans || {};
                            return typeof numberOfLoans === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many personal loans do you have?",
                                questionHelperLabel: "Include secured and unsecured loans",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.numberOfLoans",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Personal loans",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of personal loans",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# loans should be >=0",
                                        maxError: "# loans should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Loan 1",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 1 - name",
                                    title: "Lender Name",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 - current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 1 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans > 0) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Loan 2",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 2 - name",
                                    title: "Lender Name",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 2 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans >= NUMBER.TWO) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Loan 3",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 3 - name",
                                    title: "Lender Name",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 3 - current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 3 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 3 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans >= NUMBER.THREE) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            personalLoans: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfLoans: Yup.number().min(0, "# loans should be >=0").max(NUMBER.THREE, "# loans should not exceed 3").required("Enter a valid input").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            personalLoans: {
                                ...formData.personalLoans,
                                numberOfLoans: ((personalLoans && personalLoans.numberOfLoans) || 0)
                            }
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            const { numberOfMortgage } = mortgage || {};
                            return typeof numberOfMortgage === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many mortgages do you have?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.numberOfMortgage",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Mortgages",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of mortgages",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# mortgages should be >=0",
                                        maxError: "# mortgages should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Mortgage 1",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 1 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 1 - Original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 1 -  amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 1 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 1 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage > 0) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 1 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Mortgage 2",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 2 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 - Original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 -  amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 2 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage >= NUMBER.TWO) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 2 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Mortgage 3",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 3 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                },  {
                                    apiKey: "mortgage.mortgageDetails[2].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 - Original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 3 -  amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 3 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 3 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage >= NUMBER.THREE) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 3 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Do you have any other liabilities?",
                                questionHelperLabel: "Our financial consultant will get in touch with you for more details.",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherLiabilities.haveAnyOtherLiabilities",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Other liabilities (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            mortgage: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfMortgage: Yup.number().min(0, "# mortgages should be >=0").max(NUMBER.THREE, "# mortgages should not exceed 3").required("Enter a valid input").nullable("Enter a valid input")
                            }),
                            otherLiabilities: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                haveAnyOtherLiabilities: Yup.boolean().required("Enter a valid input").nullable("Enter a valid input")
                            })
                        }),
                        initialValues: {
                            mortgage: {
                                ...formData.mortgage,
                                numberOfMortgage: ((mortgage && mortgage.numberOfMortgage) || 0)
                            },
                            otherLiabilities: formData.otherLiabilities
                        }
                    }
                ]
            }, {
                name: "Balloon Details",
                intermediateScreenText: "Let’s add your Balloon details now",
                stageNo: 6,
                isHidden: !balloon,
                isReadDisabled: !balloon,
                totalPages: 1,
                hideIntermediateScreen: true,
                sidebarNavigation: {
                    key: "balloonDetails",
                    title: "Balloon Details",
                    description: "Add balloon details",
                    isHidden: !balloon
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isHidden: !balloon,
                        isCompleted: () => {
                            const { balloonReason, balloonRepaymentMethod }  = formData || {};
                            return !!balloonReason && !!balloonRepaymentMethod;
                        },
                        questions: [
                            {
                                questionLabel: "Why did you opt for balloon payment in your loan?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonReason",
                                    shortTitle: "Balloon reason",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REASONS_OPTIONS,
                                    placeholder: "Select reason",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REASONS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "What will likely be your method of repayment when balloon is due?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonRepaymentMethod",
                                    shortTitle: "Balloon repayment method",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                    placeholder: "Select method",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            balloonReason: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                            balloonRepaymentMethod: Yup.string().required("Enter valid input").nullable("Enter valid input")
                        }),
                        initialValues: {
                            balloonReason: formData.balloonReason,
                            balloonRepaymentMethod: formData.balloonRepaymentMethod
                        }
                    }
                ]
            },
            {
                name: "Declaration & Signature",
                hideIntermediateScreen: true,
                isReadDisabled: true,
                stageNo: 7,
                totalPages: 1,
                isCompleted: () => {
                    const { declarationForInformation } = formData || {};
                    return declarationForInformation;
                },
                sidebarNavigation: {
                    key: "declaration",
                    title: "Declaration",
                    description: "Add your signature"
                },
                jumpToPrevStageNo: () => {
                    if (balloon) {
                        return NUMBER.SIX;
                    }
                    return NUMBER.FIVE;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { declarationForInformation } = formData || {};
                            return declarationForInformation;
                        },
                        questions: [
                            {
                                questionLabel: "I declare that the information I have provided is correct to the best of my knowledge",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInformation",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            },
                            {
                                questionLabel: privacyPolicyLabel,
                                setHtml: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: privacyPolicyKey,
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I agree that Cars24 may collect, use and disclose my personal information as set out by the privacy policy, including requesting access to my information from credit reporting bodies",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForPersonalDataUses",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I consent to receive a call from CARS24 to discuss insurance and get a no-obligation quote (optional)",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInsuranceCall",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "Name",
                                questionStyle: "styles.smallQuestionDark",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "name",
                                    fieldType: components.inputText,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: null,
                                questionStyle: null,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "signatureDateTime",
                                    fieldType: components.staticDate,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        startDate: new Date(),
                                        minDate: new Date(),
                                        dateFormat: "dd-MM-yyyy",
                                        value: new Date()
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            declarationForInformation: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            [privacyPolicyKey]: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            declarationForPersonalDataUses: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            name: Yup.string().required("Enter a valid input").nullable()
                        }),
                        initialValues: {
                            declarationForInformation: formData.declarationForInformation,
                            [privacyPolicyKey]: formData[privacyPolicyKey],
                            declarationForPersonalDataUses: formData.declarationForPersonalDataUses,
                            declarationForInsuranceCall: formData.declarationForInsuranceCall,
                            name: formData.name,
                            signatureDateTime: formData.signatureDateTime
                        }
                    }
                ]
            }
        ]
    };
};

export {
    V1_PEPPER_CONFIG,
    v1PepperResetQuestions
};
