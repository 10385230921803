import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
// import { AUTH_ADDRESS } from "../../../../constants/api-constants";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { getTestDriveCheckoutURL } from "../../../../utils/helpers/get-detail-page-url";
// import CheckoutNameDatails from "../../checkout-name-details";
// import CheckoutMobileNumber from "../checkout-mobile-number";
// import CheckoutVerifyOtp from "../checkout-verify-otp";
import styles from "./styles.css";

// import carListingEventDataParser from "../../../../tracking/parser/car-listing";
// import { ORDER_INITIAL_ADDRESS } from "../../checkout-info/reducers";
import nameValidator from "../../../../utils/validators/name-validator";
import { mobileNumberValidatorAU } from "../../../../utils/validators/mobile-number-validator";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";
import InputText from "../../../shared/input-text";
import InputMobile from "../../../shared/input-mobile";
import { NUMBER, ORDER_TYPE, PHONE_COUNTRY_CODE, stringConstants } from "../../../../constants/app-constants";
import Layout from "../layout";
import { testDriveRoutes } from "../../../../constants/au.desktop/test-drive-routes";
import { ORDER_INITIAL_ADDRESS } from "../../checkout-info/reducers";
import LocationIcon from "./images/location.svg";
import mediaImage from "./images/test-drive-img.png";
import BuyCarOnlineModal from "../../buy-car-online-modal/component";

const LOCATION_SELECTED = [
    {
        key: "yes",
        value: "Yes"
    },
    {
        key: "no",
        value: "No"
    }
];

// const CheckoutNameDatails = loadable(() => import("../checkout-name-details"));
// const CheckoutMobileNumber = loadable(() => import("../checkout-mobile-number"));

// import { PHONE_COUNTRY_CODE } from "../../../../constants/app-constants";
// import { mobileNumberValidatorAU as mobileValidator } from "../../../../utils/validators/mobile-number-validator";

// eslint-disable-next-line max-statements
const BasicDetails = ({
    routeData,
    history,
    content,
    personalDetails,
    setUserPersonalDetailsConnect,
    // updateDeliveryModeConnect,
    // sendMobileOTPConnect,
    updateProfileConnect,
    // savedMobileNumber,
    secureToken,
    name: customerName,
    order,
    updateBasicHubspotConnect,
    updateOrderConnect,
    ipDetectedState
    // checkoutSourcePathname = ""
}) => {
    const wrapper = useRef();
    const {city = ""} = content;
    const { orderId = "", testDriveLocationDetails = {} } = order || {};
    const {availableDateRange = "", testDriveAddress = ""} = testDriveLocationDetails || {};

    const [isLoading, setIsLoading] = useState(false);
    const [locationSelected, setLocationSelected] = useState("");

    // const [step, setStep] = useState(0);
    // const [errorMessage, setErrorMessage] = useState("");

    // useEffect(() => {
    //     if (orderId) {
    //         const data = carListingEventDataParser(content, {
    //             list: CAR_LIST_EVENT_NAMES.DETAILS,
    //             orderId,
    //             excludedKeys: [ "list" ]
    //         });
    //         trackMobileCustomEventsAU(AU_MOBILE_EVENTS.START_PURCHASE, data.startPurchase);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [orderId]);

    const isNextButtonDisabled = () => {
        return (
            !nameValidator(personalDetails.firstName) ||
            (personalDetails.middleName && !nameValidator(personalDetails.middleName)) ||
            (personalDetails.lastName && !nameValidator(personalDetails.lastName)) ||
            (!mobileNumberValidatorAU(personalDetails.phone)) ||
            !(locationSelected === stringConstants.YES)
        );
    };

    // const handleNextClick = () => {
    //     trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_NEXT, {
    //         eventLabel: "options Detail_Name"
    //     });
    //     // onNextClick();
    // };

    const trackHeaderCallClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_HEADER_CALL, {
            eventLabel: "options Detail_Name"
        });
    };

    const scrollParent = (e) => {
        wrapper.current.scroll({
            top: e.target.parentElement.parentElement.offsetTop - NUMBER.FIVE,
            left: 0,
            behavior: "smooth"
        });
    };

    const handleChange = (e) => {
        setUserPersonalDetailsConnect({ ...personalDetails, [e.target.id]: e.target.value });
    };

    // const goToCarDetails = () => {
    //     trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_BACK, {
    //         eventLabel: "options Detail_Name"
    //     });

    //     if (checkoutSourcePathname) {
    //         history.push(checkoutSourcePathname);
    //     } else {
    //         const carDetailsURL = getCarDetailsPageURL(content);
    //         history.push(carDetailsURL);
    //     }
    // };

    // const openNameStep = () => {
    //     trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_BACK, {
    //         eventLabel: "options Detail_phone"
    //     });
    //     setStep(0);
    // };

    // const trackCheckoutStep = () => {
    //     const dataPayload = carListingEventDataParser(content, {
    //         step: 1,
    //         bookingAmount: content.price,
    //         orderId
    //     });
    //     trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT, dataPayload.checkout);
    // };

    // const openOtpVerifyStep = async () => {
    //     try {
    //         setIsLoading(true);
    //         await sendMobileOTPConnect();
    //         trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_NEXT, {
    //             eventLabel: "options Detail_phone"
    //         });
    //         setStep(NUMBER.TWO);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };
    const updateProfile = async (payload) => {
        try {
            setIsLoading(true);
            await updateProfileConnect(payload, secureToken);
            const { relativeURL: nextURL } = getTestDriveCheckoutURL(content, testDriveRoutes.paymentSummary.route);
            history.push(nextURL);
        } catch (error) {
            // setErrorMessage(error.message);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const mobileNumberNextClickHandler = async () => {
        try {
            const {firstName, lastName, middleName} = personalDetails || {};
            const payload = { ...personalDetails, firstName: firstName ?  firstName.trim() : "", lastName: lastName ? lastName.trim() : "", middleName: middleName ? middleName.trim() : ""};
            const orderPayload = {
                ...order,
                orderType: ORDER_TYPE.TEST_DRIVE,
                pickUpAddress: {
                    ...(order.pickUpAddress || ORDER_INITIAL_ADDRESS),
                    customerName,
                    customerPhoneNumber: personalDetails.phone
                }
            };
            updateBasicHubspotConnect(orderId, orderPayload);
            await updateProfile(payload);
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_NEXT, { eventLabel: "basic_details" });
        } catch (error) {
            // console.log(error)
        }
    };

    const trackInput = (inputName) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PERSONAL_DETAILS, {
            eventLabel: `options ${inputName}`
        });
    };

    const handleLocationView = (value) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, {
            eventLabel: value,
            eventAction: "state_consent"
        });
        setLocationSelected(value || "");
    };

    return (
        <div className="row">
            <div  className="col-lg-7">
                <Layout
                    routeData={routeData}
                    customNext={mobileNumberNextClickHandler}
                    nextButtonDisabled={isNextButtonDisabled()}
                    nextButtonLoading={isLoading}
                    customCallClick={trackHeaderCallClick}
                    backEventLabel="detail_name"
                >
                    {/* <div styleName="styles.headerStyle">
                <h2>Basic details</h2>
            </div> */}
                    <div styleName={"styles.wrapper"} ref={wrapper}>
                        <h3>Are you located in this State?</h3>
                        <div styleName={"styles.locationContainer"}>
                            <img src={LocationIcon} />
                            <p styleName={"styles.city"}>{ipDetectedState}</p>
                        </div>
                        <div styleName={"styles.optionWrapper"}>
                            {LOCATION_SELECTED.map((item =>
                                (<div styleName={`styles.answer ${locationSelected === item.key ? "styles.active" : ""}`} onClick={() => handleLocationView(item.key)} key={item.key}>
                                    <p styleName={"styles.response"}>{item.value}</p>
                                </div>)

                            ))}
                            {/* <div styleName={"styles.answer styles.active"}>
                                <p styleName={"styles.response"}>Yes</p>
                            </div>
                            <div styleName={"styles.answer"}>
                                <p styleName={"styles.response"}>No</p>
                            </div> */}
                        </div>
                        {locationSelected === stringConstants.YES && <React.Fragment>
                            <div className="media" styleName={"styles.outerContainer"}>
                                <img src={mediaImage} />
                                <div className="media-body">
                                    <p styleName={"styles.testDriveAt"}>Test drive at</p>
                                    <p styleName={"styles.address"}>
                                        {`${(testDriveAddress || "").replaceAll(",", "\n")}`}
                                    </p>
                                    <p styleName={"styles.availableBetween"}>Test drive available between</p>
                                    <p styleName={"styles.days"}>{availableDateRange}</p>
                                </div>
                            </div>
                            <h3>What’s your name?</h3>
                            <div styleName={"styles.inputField"}>
                                <InputText
                                    id="firstName"
                                    text="First Name (As on your license)"
                                    value={personalDetails.firstName || ""}
                                    onChange={handleChange}
                                    validator={nameValidator}
                                    errorMessage="Please enter a valid first name"
                                    onBlurCallback={() => trackInput("first_name")}
                                    onFocusCallback={scrollParent}
                                />
                            </div>
                            <div styleName={"styles.inputField"}>
                                <InputText
                                    id="middleName"
                                    text="Middle Name (Optional)"
                                    value={personalDetails.middleName || ""}
                                    onChange={handleChange}
                                    validator={(name) => !name || nameValidator(name)}
                                    errorMessage="Please enter a valid middle name"
                                    onBlurCallback={() => trackInput("middle_name")}
                                    onFocusCallback={scrollParent}
                                />
                            </div>
                            <div styleName={"styles.inputField"}>
                                <InputText
                                    id="lastName"
                                    text="Last Name (As on your license)"
                                    value={personalDetails.lastName || ""}
                                    onChange={handleChange}
                                    validator={(name) => !name || nameValidator(name)}
                                    errorMessage="Please enter a valid last name"
                                    onBlurCallback={() => trackInput("last_name")}
                                    onFocusCallback={scrollParent}
                                />
                            </div>
                            <h3>What’s your mobile number?</h3>
                            <p styleName={"styles.text"}>This is the number we’ll use to confirm your test drive</p>
                            <div styleName={"styles.inputField"}>
                                <InputMobile
                                    id="phone"
                                    placeholder={`+${PHONE_COUNTRY_CODE} - Your mobile number`}
                                    value={personalDetails.phone || ""}
                                    onChange={handleChange}
                                    validator={mobileNumberValidatorAU}
                                    onBlurCallback={() => trackInput("phone")}
                                />
                            </div>

                        </React.Fragment>
                        }
                    </div>
                </Layout>
            </div>

            {/* {step === NUMBER.ONE ? (
                <CheckoutMobileNumber
                    routeData={routeData}
                    onBackClick={openNameStep}
                    onNextClick={mobileNumberNextClickHandler}
                    phone={personalDetails.phone}
                    handleChange={handleChange}
                    isLoading={isLoading}
                    trackInput={trackInput}
                />
            ) : (
                ""
            )} */}
            <div className="col-lg-5" />
            {locationSelected === stringConstants.NO &&
            <BuyCarOnlineModal
                onClose={handleLocationView}
                city={city}
                orderId={orderId}
                content={content}
                updateOrderConnect={updateOrderConnect}
            /> }

        </div>
    );
};

BasicDetails.propTypes = {
    routeData: PropTypes.object,
    history: PropTypes.object,
    content: PropTypes.object,
    name: PropTypes.string,
    order: PropTypes.object,
    personalDetails: PropTypes.object,
    setUserPersonalDetailsConnect: PropTypes.func,
    updateDeliveryModeConnect: PropTypes.func,
    // sendMobileOTPConnect: PropTypes.func,
    updateProfileConnect: PropTypes.func,
    secureToken: PropTypes.string,
    orderId: PropTypes.string,
    checkoutSourcePathname: PropTypes.string,
    updateBasicHubspotConnect: PropTypes.func,
    createCheckoutStepsConnect: PropTypes.func,
    updateCheckoutStepsConnect: PropTypes.func,
    checkoutStepsData: PropTypes.object,
    email: PropTypes.string,
    updateOrderConnect: PropTypes.func,
    ipDetectedState: PropTypes.string
};

export default withRouter(BasicDetails);
