export const API_PATH_CONSTANTS = {
    ORDER: "order",
    FINANCE: "finance",
    ADDRESS: "address",
    OFFER: "offer",
    PAYMENT: "payment",
    PAYMENTS: "payments",
    BOOK: "book",
    QUOTE: "quote",
    PRICE: "price",
    SUPPORT: "support",
    CALLBACK: "callback",
    TRADE: "trade",
    STATES: "states",
    CONSUMER: "consumer",
    BYOF: "byof",
    SUMMARY: "summary",
    BOOKING: "booking",
    SPLITPAYMENT: "splitPayment",
    TRANSACTIONS: "transactions",
    DOCSIGN: "docsign",
    ENVELOPE: "envelope",
    PUSHBACK: "pushback",
    C2B: "c2b",
    UNBOOKED: "unbooked",
    PREAPPROVAL: "preapproval",
    DETAILS: "details",
    VEHICLE: "vehicle",
    TEST: "test-drive",
    UPDATE: "update",
    EXTENDEDWARRANTY: "extended-warranty"
};
