
export const ORDER_STATUS = {
    CREATED: "CREATED",
    EXPIRED: "EXPIRED",
    CANCELED: "CANCEL",
    BOOKED: "BOOKED"
};

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    CANCEL: "CANCEL",
    RETRY: "RETRY",
    CREATED: "CREATED",
    FAILED: "FAILED",
    INITIATED: "INITIATED"
};

export const DELIVERY_TYPES = {
    SelfPickup: "PICK_UP",
    HomeDelivery: "HOME_DELIVERY"
};

export const PAYMENT_SPLIT = {
    SPLIT: "split",
    FULL: "full"
};

export const PAYMENT_TYPE = {
    CARD: {label: "Card", value: "card"},
    BANK_TRANSFER: {label: "Bank Transfer", value: "au_becs_debit"},
    INTERNET_BANKING: {label: "Internet Banking", value: "internet_banking"}
};

export const PAYMENT_GATEWAY = {
    adeyn: "ADYEN",
    stripe: "STRIPE",
    poli: "POLI"
};

export const LOAN_YEARS_OPTIONS = [
    { label: "3 years", value: 3 },
    { label: "4 years", value: 4 },
    { label: "5 years", value: 5 },
    { label: "7 years", value: 7 }
];

export const BOOL_STATE = {
    YES: "YES",
    NO: "NO",
    NA: "NA"
};

export const BOOL_OPTIONS = [
    { label: "Yes", value: BOOL_STATE.YES },
    { label: "No", value: BOOL_STATE.NO }
];

export const CARD_SURCHARGE = 0.014;

export const EMI_DEFAULT_STEP = 1;
export const DOWNPAYMENT_DEFAULT_STEP = 1;

export const TIME_UNIT = 60;
export const START_TIMER_MIN_VAL = 0;
export const SEC_START_VALUE = 59;
export const INITIAL_TIMER_VALUE = 1;
export const MILLI_IN_MUNUTE = 60000;
export const RESERVED_HOURS = 48;

export const AUSTRALIAN_CITIZEN = "citizen";
export const PERMANENT_RESIDENT = "pr";
export const TEMPORARY_VISA = "visa";

export const RESIDENCY_STATUS = [
    {label: "Australian Citizen", value: AUSTRALIAN_CITIZEN, gtm: "australian_citizen"},
    {label: "Permanent Resident", value: PERMANENT_RESIDENT, gtm: "permanent_resident"},
    {label: "Temporary Visa", value: TEMPORARY_VISA, gtm: "temporary_visa"}
];

export const RENTING = "renting";
export const OWNER_WITH_MORTGAGE = "ownerWithMortgage";
export const OWNER_WITHOUT_MORTGAGE = "ownerWithoutMortgage";
export const LIVING_WITH_PARENTS = "livingWithParents";
export const BOARD = "board";

export const LIVING_SITUATION = [
    {label: "Owner without Mortgage", value: OWNER_WITHOUT_MORTGAGE, gtm: "owner_without_mortgage"},
    {label: "Owner with Mortgage", value: OWNER_WITH_MORTGAGE, gtm: "owner_with_mortgage"},
    {label: "Renting", value: RENTING, gtm: "renting"},
    {label: "Living with parents", value: LIVING_WITH_PARENTS, gtm: "living_with_parents"},
    {label: "Boarding", value: BOARD, gtm: "boarding"}
];

export const FULL_TIME = "fullTime";
export const PART_TIME = "partTime";
export const SELF_EMPLOYED = "selfEmployed";
export const CASUAL = "casual";
export const CONTRACTOR = "contractor";
export const UNEMPLOYED = "unemployed";
export const PENSION = "pension";
export const Others = "others";

export const EMPLOYEMENT_TYPES = [
    {label: "Full Time", value: FULL_TIME, gtm: "full_time"},
    {label: "Part Time", value: PART_TIME, gtm: "part_time"},
    {label: "Self Employed", value: SELF_EMPLOYED, gtm: "self_employed"},
    {label: "Casual", value: CASUAL, gtm: "casual"},
    {label: "Contractor", value: CONTRACTOR, gtm: "contractor"},
    {label: "Unemployed", value: UNEMPLOYED, gtm: "unemployed"},
    {label: "Pension", value: PENSION, gtm: "pension"},
    {label: "Others", value: Others, gtm: "others"}
];

export const FINANCE_TYPE = {
    DRIVA_FINANCE: "DRIVA_FINANCE",
    PRE_APPROVED: "PRE_APPROVED",
    BYOF: "BYOF"
};

export const VEHICLE_STATE = {
    USED: "used"
};

export const LOAN_ERROR = {
    DEFAULT: "default",
    CREDIT_SCORE: 1002,
    RESIDENCY: 1004,
    CREDIT_FILE: 1006
};

export const TRADE_STATE = [
    {
        stateCode: "AU_NSW ",
        stateName: "New South Wales",
        label: "New South Wales",
        value: "New South Wales"
    },
    {
        stateCode: "AU_QLD",
        stateName: "Queensland",
        label: "Queensland",
        value: "Queensland"
    },
    {
        stateCode: "AU_CAPTR",
        stateName: "Australian Capital Territory",
        label: "Australian Capital Territory",
        value: "Australian Capital Territory"
    },
    {
        stateCode: "AU_NTR",
        stateName: "Northern Territory",
        label: "Northern Territory",
        value: "Northern Territory"
    },
    {
        stateCode: "AU_SAU",
        stateName: "South Australia",
        label: "South Australia",
        value: "South Australia"
    },
    {
        stateCode: "AU_TAS",
        stateName: "Tasmania",
        label: "Tasmania",
        value: "Tasmania"
    },
    {
        stateCode: "AU_VIC",
        stateName: "Victoria",
        label: "Victoria",
        value: "Victoria"
    },
    {
        stateCode: "AU_WEST",
        stateName: "Western Australia",
        label: "Western Australia",
        value: "Western Australia"
    }
];
