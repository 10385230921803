import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkoutCallbackRequest } from "../checkout-info/actions";
import HelpCenterModalDoubts from "./component";

const mapStateToProps = ({
    checkout: {
        financeData: {
            user: { mobile },
            loan
        },
        checkoutCallbackRequestLoading,
        callbackDetails: {
            isExpired: checkoutCallbackRequestExpired
        }
    },
    user: {
        isUserZeroDpVariant
    }
}) => ({
    mobile,
    loan,
    checkoutCallbackRequestLoading,
    checkoutCallbackRequestExpired,
    isUserZeroDpVariant
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    checkoutCallbackRequestConnect: checkoutCallbackRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterModalDoubts);
