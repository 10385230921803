import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const OverflowContainer = ({ children, containerRef = null, type = undefined, onTouchStart = () => {}, onTouchEnd = () => {}, onScrollStart = () => {}}) => {
    return (
        <div styleName={"styles.horizontalScroll"} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onScrollCapture={onScrollStart}>
            <div ref={containerRef} styleName={type === "pb0mb0" ? "styles.pb0mb0" : "styles.container"}>
                {children}
            </div>
        </div>
    );
};

OverflowContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    containerRef: PropTypes.object,
    type: PropTypes.string,
    onTouchStart: PropTypes.func,
    onScrollStart: PropTypes.func,
    onTouchEnd: PropTypes.func
};

export default OverflowContainer;
