
// eslint-disable-next-line max-statements
export default (api) => {
    const updateAffilateSource = (token, userId, source) => {
        return api.post(`/v1/acquisition`, {
            userId,
            acquisitionSource: source
        }, { headers: {
            Authorization: `Bearer ${token}`
        } });
    };
    return {
        updateAffilateSource
    };
};
