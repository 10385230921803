import Types from "./types";

const setPaymentConfiguration = (data) => ({
    type: Types.SET_PAYMENT_CONFIGURATION,
    data
});

export {
    setPaymentConfiguration
};
