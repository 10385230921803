import { getDeviceType } from "./get-device-type";
import { getWebViewSource } from "./get-webview-source";

export const getApiSource = (source, isWebView) => {
    let apiSource = "";
    if (source && isWebView) {
        apiSource = getWebViewSource(source);
    } else {
        apiSource = getDeviceType();
    }
    return apiSource;
};
