/* eslint-disable no-magic-numbers */
import { useEffect, useState } from "react";
import { NUMBER } from "../constants/app-constants";

const pad = (num) => (`0${  num}`).slice(-2);

export const getTimeLeftInMins = (expiryTime) => {
    const currentTime = new Date().getTime();
    const timeLeft =  new Date(expiryTime).getTime() - currentTime;
    const timeLeftInMins = timeLeft > 0 ? timeLeft / NUMBER.SIXTY_THOUSAND : 0;
    return timeLeftInMins;
};

const useCountDown = (targetDate, startDate, leftMinsDuration = NUMBER.FIFTEEN) => {
    const countDownDate = new Date(targetDate).getTime();
    const startDateTimestamp = new Date(startDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        setCountDown(new Date(targetDate).getTime() - new Date().getTime());
    }, [targetDate]);

    const getReturnValues = (countDownTime) => {
    // calculate time left
        const currentTimestamp = new Date().getTime();
        const days = Math.floor(countDownTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDownTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDownTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDownTime % (1000 * 60)) / 1000);
        const fullHours = Math.floor(countDownTime  / (1000 * 60 * 60));
        const timeCompletePercentage = Math.round(((currentTimestamp - startDateTimestamp) / (countDownDate - startDateTimestamp)) * 100);
        const lessMinsLeft = parseInt(hours) === 0 && parseInt(minutes) < leftMinsDuration;
        const timeExpired = (countDownDate - currentTimestamp) <= 0;
        return [pad(days), pad(hours), pad(minutes), pad(seconds), pad(fullHours), timeCompletePercentage, lessMinsLeft, timeExpired];
    };

    useEffect(() => {
        const interval = window.setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => window.clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

export default useCountDown;
