import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Disclaimer from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        extendedWarranty: {
            warrantyPlanInfo
        }
    }
}) => ({
    content,
    warrantyPlanInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);
