/* eslint-disable max-params */
import {loadAsyncScript} from "../../utils/helpers/load-async-script";
const DISABLE_ZEN_PATH = ["/checkout/"];

// eslint-disable-next-line no-var
var makeScript = (src, id) => (function(d, s) { if (d.getElementById(id)) {return;} var element = d.getElementsByTagName(s)[0]; var jscript = d.createElement(s); jscript.src = src; jscript.id = id; element.parentNode.insertBefore(jscript, element);}(document, "script"));

const multiListener = (l, t = "a") => {
    const e = "scroll mousedown mousemove touchstart scroll keydown".split(" ");
    for (let i = 0, iLen = e.length; i < iLen; i++) {
        if (t === "a") {
            window.addEventListener(e[i], l, false);
        } else { window.removeEventListener(e[i], l, false);}
    }
};

export const zendeskVisibility = () => {
    if (window && window.zE && window.zE.hide) {
        if (DISABLE_ZEN_PATH.filter(item => window.location.pathname.indexOf(item) > -1).length) {
            window.zE.hide();
        } else {
            window.zE.show();

        }
    }
};

// export const loadGoogleTagManager = () => (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({"gtm.start": new Date().getTime(), event: "gtm.js"}); const f = d.getElementsByTagName(s)[0]; const j = d.createElement(s); const dl = l !== "dataLayer" ? `&l=${  l}` : ""; j.async = true; j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);})(window, document, "script", "dataLayer", process.env.GTM_ID_AU);

export const loadStripe = () =>  makeScript("https://js.stripe.com/v3/", "stripe");

export const loadGoogleMap = () => makeScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places&callback=Function.prototype`, "google-place");

export const loadZendesk = () => {
    window.zESettings = {
        webWidget: {
            chat: {
                connectOnPageLoad: false
            }
        }
    };
    loadAsyncScript("ze-snippet", `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_ID_AU}`, () => {
        if (window && window.zE) window.zE(() => zendeskVisibility());
    });
};

export const loadClevertap = () => {
    window.clevertap = {event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: []};
    // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
    window.clevertap.account.push({"id": process.env.CLEVERTAP_ID_AU});
    window.clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
    window.clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
    (function () {
        const wzrk = document.createElement("script");
        wzrk.type = "text/javascript";
        wzrk.async = true;
        wzrk.src = `${document.location.protocol === "https:" ? "https://d2r1yp2w7bby2u.cloudfront.net" : "http://static.clevertap.com"  }/js/clevertap.min.js`;
        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wzrk, s);
    })();
};

export const loadGooglMapInit = () => makeScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&callback=initMap`, "google-map");

export const loadS20SafeCam = (env) => makeScript(env, "s20-safecam");

export const loadScriptsOnFirstInteraction = () => {
    const loadScript = () => {
        loadGoogleMap();
        window.dataLayer = window.dataLayer || [];
        multiListener(loadScript, "r");
    };
    multiListener(loadScript);
};
