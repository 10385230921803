import React from "react";
import PropTypes from "prop-types";

import Slider from "react-slick";
import OrderSummaryTestimonialCard from "../../order-summary-testimonial-card/component";
import styles from "./styles.css";
import CustomerOne from "./images/testimonial-img.svg";

const reviews = [
    {
        // icon: Testimonial1,
        title: "Janelle",
        location: "Melbourne",
        heading: "Great protection",
        description: "Great protection. I could definitely tell the difference, lot shiner and the water just beads off the car when it rains. I have not had to wash the car even once."
    },
    {
        // icon: Testimonial2,
        title: "Danielle",
        location: "Melbourne",
        heading: "Fantastic package",
        description: "Very happy with the protect max pack. Would recommend, as I don't have to maintain the car much, still looks shiny & clean."
    }
];

const AddonsTestimonialSlider = () => {
    const trackEvents = () => {

    };

    // const handleBeforeChange = (oldIndex, newIndex) => {

    // };

    const settings = {
        slidesToShow: 1.6,
        slidesToScroll: 1,
        arrow: true,
        dots: true,
        infinite: false,
        // beforeChange: handleBeforeChange,
        afterChange() {
            trackEvents();
        }
    };

    return (
        <React.Fragment>
            <p styleName={"styles.heading"}>See what our customers say</p>
            <div styleName={"styles.testimonialWrap"}>
                <div styleName={"styles.rightGradient"}>&nbsp;</div>
                <Slider {...settings}>
                    {reviews.map((item, idx) => (
                        <OrderSummaryTestimonialCard id={idx} image={CustomerOne} customerLocation={item.location} customerName={item.title} detailText={item.description} key={idx} />
                    )) }
                </Slider>
            </div>
        </React.Fragment>
    );
};

AddonsTestimonialSlider.propTypes = {
    isInHouse: PropTypes.bool,
    isByoOpted: PropTypes.bool
};

export default AddonsTestimonialSlider;
