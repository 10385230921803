/* eslint-disable complexity */
/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    loading: false,
    error: null,
    data: null
};

const fetchFinanceMetaDataRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        loading: true,
        data: null,
        error: null
    };
};

const fetchFinanceMetaDataSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        loading: false,
        data,
        error: null
    };
};
const fetchFinanceMetaDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        loading: false,
        data: null,
        error
    };
};

export const HANDLERS = {
    [Types.FETCH_FINANCE_META_DATA_INIT]: fetchFinanceMetaDataRequest,
    [Types.FETCH_FINANCE_META_DATA_SUCCESS]: fetchFinanceMetaDataSuccess,
    [Types.FETCH_FINANCE_META_DATA_FAILURE]: fetchFinanceMetaDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
