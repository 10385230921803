import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearVASCart, getVASBundles, getVASCart, getVASItems, setAddonScreenType, setCounterAnimation, vasTracker } from "../add-ons/actions";
import { updateCheckoutSteps } from "../checkout-info/actions";
import AddOnsRevamp from "./component";

const mapStateToProps = ({
    carDetails: {
        content,
        content: {
            appointmentId
        }
    },
    checkout: {
        deliveryMode,
        order,
        egcData,
        tradeInData,
        order: {
            orderId,
            chargeDetails
        },
        quotesAvailable: preApproved
    },
    addOns: {
        screenType,
        activeStore,
        showCounterAnimation,
        vasBundles: {
            loading: bundleLoading
        },
        vasItems: {
            loading: itemsLoading
        },
        vasCart: {
            data: vasCartData,
            loading: cartLoading
        },
        servicingPriceConfig: {
            loading: isServicingConfigLoading,
            data: servicingPriceConfig
        }
    },
    deliveryInfo
}) => ({
    activeStore,
    appointmentId,
    chargeDetails,
    content,
    deliveryMode,
    egcData,
    tradeInData,
    loading: bundleLoading || cartLoading || itemsLoading,
    order,
    orderId,
    preApproved,
    screenType,
    showCounterAnimation,
    vasCartData,
    deliveryInfo,
    isServicingConfigLoading,
    servicingPriceConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearVASCartConnect: clearVASCart,
    getVASBundlesConnect: getVASBundles,
    getVASCartConnect: getVASCart,
    getVASItemsConnect: getVASItems,
    setAddonScreenTypeConnect: setAddonScreenType,
    setCounterAnimationConnect: setCounterAnimation,
    updateCheckoutStepsConnect: updateCheckoutSteps,
    vasTrackerConnect: vasTracker
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsRevamp);
