export default (api) => {

    /**
     *
     * @param {string} token lead token
     * @param {Object} params object of type Paytm payment
     * @returns {Promise<string>} promise that resolves with payment checksum
     */
    const fetchChecksum = (token, params) => {
        return api.post(`order-get-checksum/${token}`, params);
    };

    /**
     *
     * @param {Object} queryObject Object containing props token, orderId & gateway
     * @param {Object} params params received in Paytm response
     * @returns {Promise<string>} promise that resolves with order confirmation
     */
    const confirmOrder = ({ token, orderId, gateway }, params) => {
        return api.post(`order-confirm/${token}/${orderId}/${gateway}`, params);
    };

    /**
     *
     * @param {string} token lead token
     * @param {Object} params object of type Paytm payment
     * @returns {Promise<string>} promise that resolves with payment checksum
     */
    const fetchBikeChecksum = (token, params) => {
        return api.post(`bike-order-checksum/${token}`, params);
    };

    return {
        fetchChecksum,
        confirmOrder,
        fetchBikeChecksum
    };
};
