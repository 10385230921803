import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PriceBreakUp from "./component";

const mapStateToProps = ({
    carDetails: {
        content: {promotion, gst}
    },
    deliveryInfo: {
        shippingPrice
    },
    homeCarList: {
        saleConfig
    },
    checkout: {
        order: {
            discount,
            status,
            financeOpted,
            financeProvider,
            deliveryMode,
            interstateFees,
            shippingCharge
        },
        promoCode,
        isManualSales,
        testDriveData
    },
    user: {
        isUserZeroDpVariant
    },
    myBookings: {paymentSummaryData},
    preApprovalLoan: {
        quotesAvailable: preApproved
    }
}) => ({
    saleConfig,
    shippingPrice,
    promotion,
    discount,
    gst,
    promoCode,
    status,
    financeOpted,
    financeProvider,
    isManualSales,
    deliveryMode,
    paymentSummaryData,
    testDriveData,
    isUserZeroDpVariant,
    preApproved,
    interstateFees,
    shippingCharge
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceBreakUp);
