import React from "react";
import PropTypes from "prop-types";
import { HELPLINE_NUMBER_AU, SUPPORT_EMAIL, SMS_NUMBER_AU } from "../../../constants/app-constants";
import CallIcon from "./images/call.svg";
import EmailIcon from "./images/email.svg";
import TextIcon from "./images/text.svg";
import styles from "./styles.css";
import ReservingYourCard from "../reserving-your-car-card/component";

// Before changing the event key make sure to change it to other place also where we are using event key
const LOCAL_CONTACT = [
    {
        key: "call",
        href: `tel:${HELPLINE_NUMBER_AU.value}`,
        img: CallIcon,
        text: "Call",
        event: "call_us"
    },
    {
        key: "email",
        href: `mailto:${SUPPORT_EMAIL}`,
        img: EmailIcon,
        text: "Email",
        event: "email_us"
    },
    {
        key: "sms",
        href: `sms:${SMS_NUMBER_AU.value}`,
        img: TextIcon,
        text: "Text",
        event: "text_us"
    }
];

const BookingsFooter = ({
    trackButtonClick = () => {},
    cancelLabelPosition = "bottom",
    hideCancellationStrip = false,
    call,
    email,
    sms,
    title = "",
    description = "",
    reconcilePending = null,
    heading,
    subHeading
}) => {
    const getData = (item) => {
        switch (item.key) {
        case "call":
            return call || item.href;
        case "sms":
            return sms || item.href;
        case "email":
            return email || item.href;
        default:
            return `tel:${HELPLINE_NUMBER_AU.value}`;
        }
    };

    return (
        <React.Fragment>
            {reconcilePending  &&  (
                <div styleName="styles.reserveWrapper">
                    <ReservingYourCard heading={heading} subHeading={subHeading}  />
                </div>
            )}
            {cancelLabelPosition === "top" && <div styleName={"styles.cancellationStrip"}>
                <p>You can cancel this order anytime for a full refund</p>
            </div>}
            <div styleName={"styles.orderSummaryWrap"}>
                <div styleName={"styles.getInTouch"}>
                    <h4>{title || "Get in touch with us today!"}</h4>
                    <p>{description || "For any queries our team is available to help"} </p>
                </div>
                <ul styleName={"styles.touchList"}>
                    {LOCAL_CONTACT.map(item =>
                        (<li key={item.text}  onClick={() => trackButtonClick(item.event)}>
                            <a href={getData(item)}>
                                <img src={item.img} alt={item.text} /> {item.text}
                            </a>
                        </li>))}

                </ul>
            </div>
            { cancelLabelPosition === "bottom" && !hideCancellationStrip && <div styleName={"styles.cancellationStripNew"}>
                <p>You can cancel this order anytime for a full refund</p>
            </div>}
        </React.Fragment>
    );
};

BookingsFooter.propTypes = {
    trackButtonClick: PropTypes.func,
    cancelLabelPosition: PropTypes.string,
    hideCancellationStrip: PropTypes.bool,
    call: PropTypes.string,
    email: PropTypes.string,
    sms: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    reconcilePending: PropTypes.bool,
    heading: PropTypes.string,
    subHeading: PropTypes.string
};

export default BookingsFooter;
