import { bffContactUs } from "../../../service";
import Types from "./types";

const quack = () => ({
    type: Types.QUACK
});

const submitContactUsQueryRequest = () => ({
    type: Types.SUBMIT_CONTACT_US_QUERY
});

const submitContactUsViaHubSpot = (payload) => (dispatch) => {
    dispatch(submitContactUsQueryRequest());
    return new Promise((resolve, reject) => {
        bffContactUs.submitContactQuery(payload).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

export {
    quack,
    // submitContactUs,
    submitContactUsViaHubSpot as submitContactUs
};
