import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Pill from "../checkout-pill";
import styles from "./styles.css";
import { withRouter } from "react-router-dom";
import { NUMBER } from "../../../constants/app-constants.js";
import { financingRoutesArray } from "../../../constants/au.desktop/financing-routes";

const PreApprovalPillNavigation = ({
    match: {
        params: { stepid: currentRoute }
    },
    maxStep,
    customCurrentStep,
    onCustomPillClick
}) => {
    currentRoute = customCurrentStep || currentRoute;
    const showType = (financingRoutesArray.find((pillData) => pillData.route === currentRoute || customCurrentStep) || {}).type;

    const maxStepIndex =
        financingRoutesArray
            .filter((data) => data.type === showType)
            .findIndex((pillData) => pillData.route === maxStep) || 0;

    const scrollingContainer = useRef();
    const activePill = useRef();

    useEffect(() => {
        const activePillOffsetLeft = activePill.current ? activePill.current.offsetLeft : 0;
        const offset = activePillOffsetLeft ? activePillOffsetLeft - NUMBER.FOURTY : 0;
        scrollingContainer.current.scrollTo({ left: offset, top: 0 });
    }, []);

    return (
        <React.Fragment>
            <div styleName="styles.scrollingContainer" ref={scrollingContainer} className={"scrollingContainer"}>
                <div styleName="styles.pillsContainer" className={"pillsContainer"}>
                    {financingRoutesArray
                        .filter((data) => data.type === showType)
                        .map((data, index) => (
                            data.hideCurrentPill ? "" : <Pill
                                key={index}
                                pillData={data}
                                active={index <= maxStepIndex}
                                current={currentRoute === data.route || (currentRoute.includes(data.useRoute) && data.route.includes(data.useRoute))}
                                activeRef={activePill}
                                onCustomPillClick={onCustomPillClick}
                            />
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
};

PreApprovalPillNavigation.propTypes = {
    match: PropTypes.object,
    maxStep: PropTypes.string,
    onCustomPillClick: PropTypes.fun,
    customCurrentStep: PropTypes.string
};

export default withRouter(PreApprovalPillNavigation);
