const currentEnv = process.env.HOST_ENV;

const appUrl = () => {
    const urls = {
        LOCAL: "http://localhost:3000",
        QA: "https://consumer-web-au.qac24svc.dev",
        STAGING: "https://stage-consumer-web-au.qac24svc.dev",
        LNOD: "https://stage-c2b-website.qac24svc.dev",
        PRODUCTION: "https://www.cars24.com.au"
    };
    return urls[currentEnv];
};

const sellCarApiUrl = () => {
    const urls = {
        LOCAL: "https://car-service-java.qac24svc.dev",
        QA: "https://car-service-java.qac24svc.dev",
        STAGING: "https://car-service-stage.cars24.team",
        LNOD: "https://sellerengine-qa8.ninja24.in",
        PRODUCTION: "https://car-service-java.c24.tech"
    };
    return urls[currentEnv];
};

const apiUrl = () => {
    const urls = {
        LOCAL: "https://c2c-web.qac24svc.dev",
        STAGING: "https://api-staging.qac24svc.dev",
        QA: "https://api-se-qa1.qac24svc.dev",
        LNOD: "https://sellerengine-qa8.ninja24.in",
        PRODUCTION: "https://api.cars24.com"
    };
    return urls[currentEnv];
};

const ssrApiUrl = () => {
    const urls = {
        LOCAL: "http://c2c-web.qac24svc.dev",
        STAGING: "https://api-staging.qac24svc.dev",
        QA: "http://api-se-qa1.qac24svc.dev",
        LNOD: "http://sellerengine-qa8.ninja24.in",
        PRODUCTION: "http://api.cars24.com"
    };
    return urls[currentEnv];
};

const vehicleApiUrl = () => {
    const urls = {
        LOCAL: "https://vehicle-service.qac24svc.dev",
        STAGING: "https://vehicle-service.qac24svc.dev",
        QA: "https://vehicle-service.qac24svc.dev",
        LNOD: "https://vehicle-service.qac24svc.dev",
        PRODUCTION: "https://vehicle.cars24.team"
    };
    return urls[currentEnv];
};

const b2cInventoryApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-inventory.qac24svc.dev",
        STAGING: "https://stage-b2c-inventory.qac24svc.dev",
        QA: "https://b2c-inventory.qac24svc.dev",
        LNOD: "https://b2c-inventory.qac24svc.dev",
        PRODUCTION: "https://b2c-inventory.c24.tech"
    };
    return urls[currentEnv];
};

const vehicleSSRApiUrl = () => {
    const urls = {
        LOCAL: "http://vehicle-service.qac24svc.dev",
        STAGING: "http://vehicle-service.qac24svc.dev",
        QA: "http://vehicle-service.qac24svc.dev",
        LNOD: "http://vehicle-service.qac24svc.dev",
        PRODUCTION: "https://vehicle.cars24.team"
    };
    return urls[currentEnv];
};

const staticAppUrl = () => {
    const urls = {
        LOCAL: "https://staging-static.cars24.com",
        QA: "https://static.cars24.com",
        STAGING: "https://static.cars24.com",
        LNOD: "https://staging-static.cars24.com",
        PRODUCTION: "https://static.cars24.com"
    };
    return urls[currentEnv];
};

const getPrivateProductApiKey = () => {
    const urls = {
        QA: "c2c-s4583HJKH58",
        STAGING: "pp-ui-s32578KJDHS",
        PRODUCTION: "qGuMZcWGxZpgd8uSH4rgkal4v1evAlCd"
    };
    return urls[currentEnv];
};

const ppApiUrl = () => {
    const urls = {
        LOCAL: "https://pvt-product.qac24svc.dev",
        QA: "https://pvt-product.qac24svc.dev",
        STAGING: "https://pvt-product-stage.cars24.com",
        LNOD: "https://pvt-product-stage.cars24.com",
        PRODUCTION: "https://pvt-product.cars24.com"
    };
    return urls[currentEnv];
};

const c2bKeys = {
    LOCAL: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    QA: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
    STAGING: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    PRODUCTION: "YzJiX2Zyb250ZW5kOko1SXRmQTk2bTJfY3lRVk00dEtOSnBYaFJ0c0NtY1h1"
};

const c2bAuthKey = () => {
    return c2bKeys[currentEnv];
};

const sellCarAuthKey = () => {
    const keys = {
        LOCAL: "Y2FyX3NlcnZpY2VfdXNlcl9xYTpxYzBhZThmNGE3YTQzNmY1ZmQyODJxYTFlM2JlNTZjODFiOTM3OQ==",
        QA: "Y2FyX3NlcnZpY2VfdXNlcl9xYTpxYzBhZThmNGE3YTQzNmY1ZmQyODJxYTFlM2JlNTZjODFiOTM3OQ==",
        STAGING: "YWRtaW46cGFzc3dvcmQ=",
        PRODUCTION: "Y2FyX3NlcnZpY2VfdXNlcjpwZDBhZThmNGE3YTQzNmY1ZmQyOHByb2QyMWUzYmU1NmNiOTM3OQ=="
    };
    return keys[currentEnv];
};

const lmsAuthKey = () => {
    const keys = {
        LOCAL: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        QA: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        STAGING: "01e72f78-24f1-434a-ab6b-3342d75c352e",
        PRODUCTION: "b78f06db-d0d7-4c60-b1a6-fc153d2527be"
    };
    return keys[currentEnv];
};

const getLogoURL = () => {
    return `https://fastly-production.24c.in/cars24/au/images/cars24_au_logo.png`;
};

const exchangeApiUrl = () => {
    const urls = {
        LOCAL: "https://c2b-website.qac24svc.dev",
        QA: "https://exchange-service-qa.ninja24.in",
        STAGING: "https://exchange-service-stage.cars24.team",
        LNOD: "https://exchange-service-qa.ninja24.in",
        PRODUCTION: "https://exchange-service.cars24.team"
    };
    return urls[currentEnv];
};

const checkoutApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-oms-au.qac24svc.dev",
        QA: "https://b2c-oms-au.qac24svc.dev",
        STAGING: "https://stage-b2c-oms-au.qac24svc.dev",
        LNOD: "https://b2c-oms-au.qac24svc.dev",
        PRODUCTION: "https://b2c-oms-au.c24.tech"
    };
    return urls[currentEnv];
};

const b2cDrivaApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-driva-driver.qac24svc.dev",
        QA: "https://b2c-driva-driver.qac24svc.dev",
        STAGING: "https://stage-b2c-driva-driver.qac24svc.dev",
        LNOD: "https://b2c-oms.qac24svc.dev",
        PRODUCTION: "https://b2c-driva-driver.c24.tech"
    };
    return urls[currentEnv];
};

const tradeInApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-tradein-service.qac24svc.dev",
        QA: "https://b2c-tradein-service.qac24svc.dev",
        STAGING: "https://stage-b2c-tradein-service.qac24svc.dev",
        LNOD: "https://b2c-tradein-service.qac24svc.dev",
        PRODUCTION: "https://b2c-tradein-service.c24.tech"
    };
    return urls[currentEnv];
};

const consumerFinanceApiUrl = () => {
    const urls = {
        LOCAL: "https://consumer-finance-stage.cars24.team",
        QA: "https://stage-consumer-finance-engine.qac24svc.dev/",
        STAGING: "https://stage-consumer-finance-engine.qac24svc.dev/",
        LNOD: "https://consumer-finance-stage.cars24.team/",
        PRODUCTION: "https://simpler-engine.cars24.team/"
    };
    return urls[currentEnv];
};

const listingApiUrl = () => {
    const urls = {
        LOCAL: "https://listing-service-au.qac24svc.dev",
        QA: "https://listing-service-au.qac24svc.dev",
        // QA: "http://listing-service",
        STAGING: "https://stage-listing-service-au.qac24svc.dev",
        LNOD: "https://listing-service-au.qac24svc.dev",
        PRODUCTION: "https://listing-service-au.c24.tech"
    };
    return urls[currentEnv];
};
const leadApiUrl = () => {
    const urls = {
        LOCAL: "https://lead-collector-stage.cars24.team",
        QA: "https://stage-lead-collector-service.qac24svc.dev",
        STAGING: "https://stage-lead-collector-service.qac24svc.dev",
        LNOD: "https://lead-collector-stage.cars24.team",
        PRODUCTION: "https://lead-collector.cars24.team"
    };
    return urls[currentEnv];
};

const b2cMasterDataSSRApi = () => {
    const urls = {
        LOCAL: "http://b2c-masterdata.qac24svc.dev",
        STAGING: "http://stage-b2c-masterdata.qac24svc.dev",
        QA: "http://b2c-masterdata.qac24svc.dev",
        LNOD: "http://b2c-masterdata.qac24svc.dev",
        PRODUCTION: "http://b2c-masterdata.c24.tech"
    };
    return urls[currentEnv];
};

const b2cMasterDataApi = () => {
    const urls = {
        LOCAL: "https://b2c-masterdata.qac24svc.dev",
        STAGING: "https://stage-b2c-masterdata.qac24svc.dev",
        QA: "https://b2c-masterdata.qac24svc.dev",
        LNOD: "https://b2c-masterdata.qac24svc.dev",
        PRODUCTION: "https://b2c-masterdata.c24.tech"
    };
    return urls[currentEnv];
};

const leadApiUrlKey = () => {
    const keys = {
        LOCAL: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        QA: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        STAGING: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        PRODUCTION: "6f355999-4af7-4c3a-8816-a16aaf2d8994"
    };
    return keys[currentEnv];
};

const cloudFunctionsURL = () => {
    const urls = {
        LOCAL: "https://asia-south1-production-283804.cloudfunctions.net",
        QA: "https://asia-south1-production-283804.cloudfunctions.net",
        STAGING: "https://asia-south1-production-283804.cloudfunctions.net",
        PRODUCTION: "https://asia-south1-production-283804.cloudfunctions.net"
    };
    return urls[currentEnv];
};

const cmsURl = () => {
    const urls = {
        LOCAL: "https://www.cars24.com.au",
        QA: "https://www.cars24.com.au",
        STAGING: "https://www.cars24.com.au",
        LNOD: "https://www.cars24.com.au",
        PRODUCTION: "https://www.cars24.com.au"
    };
    return urls[currentEnv];
};

const b2cConsumerFinance = () => {
    const urls = {
        LOCAL: "https://b2c-consumer-finance-au.qac24svc.dev",
        QA: "https://b2c-consumer-finance-au.qac24svc.dev",
        STAGING: "https://stage-b2c-consumer-finance-au.qac24svc.dev",
        LNOD: "https://b2c-consumer-finance-au.qac24svc.dev",
        PRODUCTION: "https://b2c-consumer-finance-au.c24.tech"

    };
    return urls[currentEnv];
};

const nudgeApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-alert-service.qac24svc.dev",
        QA: "https://b2c-alert-service.qac24svc.dev",
        STAGING: "https://stage-b2c-alert-service.qac24svc.dev",
        LNOD: "https://b2c-alert-service.qac24svc.dev",
        PRODUCTION: "https://b2c-alert-service.c24.tech"
    };
    return urls[currentEnv];
};
module.exports = {
    currentEnv,
    appUrl,
    apiUrl,
    ssrApiUrl,
    staticAppUrl,
    getPrivateProductApiKey,
    ppApiUrl,
    c2bAuthKey,
    getLogoURL,
    exchangeApiUrl,
    sellCarApiUrl,
    sellCarAuthKey,
    lmsAuthKey,
    vehicleApiUrl,
    vehicleSSRApiUrl,
    checkoutApiUrl,
    b2cDrivaApiUrl,
    tradeInApiUrl,
    consumerFinanceApiUrl,
    listingApiUrl,
    b2cMasterDataSSRApi,
    b2cInventoryApiUrl,
    leadApiUrl,
    leadApiUrlKey,
    b2cMasterDataApi,
    c2bKeys,
    cloudFunctionsURL,
    cmsURl,
    b2cConsumerFinance,
    nudgeApiUrl
};
