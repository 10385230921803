import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseImage from "./images/close.svg";
import BuildYear from "./images/build-year.png";
import CarDetailImage from "./images/car-image.png";

const BuildYearModal = ({open, hideModal}) => {
    return (
        <Modal isOpen={open}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.headerWrapper"}>
                    <p styleName={"styles.heading"}>Build year</p>
                    <img src={CloseImage} alt="Modal close" onClick={hideModal}/>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.leftWrapper"}>
                        <p styleName={"styles.leftHeading"}>What is a build year?</p>
                        <p styleName={"styles.leftSubHeading styles.mb"}>The build year is the year in which your car was manufactured. You can find your build year on the build plate.
                        </p>
                        <p styleName={"styles.leftSubHeading styles.normalFont"}>
                        The build year can be found on the build plate or label, which is usually fitted in the engine bay or on the driver's door frame.
                        </p>
                        <div styleName={"styles.leftImageWrapper"}>
                            <img src={BuildYear} />
                        </div>
                    </div>
                    <div styleName={"styles.rightWrapper"}>
                        <p styleName={"styles.leftHeading"}>Where can I find the build plate?</p>
                        <img src={CarDetailImage} alt="Car details" />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

BuildYearModal.defaultProps = {
    open: false,
    hideModal: () => {}
};

BuildYearModal.propTypes = {
    open: PropTypes.bool,
    hideModal: PropTypes.func
};

export default BuildYearModal;
