const eventMap = {
    ON_CITY_SELECTION: "ON_CITY_SELECTION",
    TRACKING_CONSENT: "trackingConsent",
    SPLIT_PAYMENT_MODAL: "splitPaymentModal"
};

const globalEventMap = {
    IS_AMP_AB_INITIALIZED: "IS_AMP_AB_INITIALIZED",
    IS_AMP_ANALYTICS_INITIALIZED: "IS_AMP_ANALYTICS_INITIALIZED"
};

export const emitEventNames = {
    desktop: { ...eventMap },
    mobile: { ...eventMap },
    global: { ...globalEventMap }
};

export const filterGTMnames = {
    make: "Make_model",
    quotedPrice: "Price",
    odometerReading: "Km",
    year: "Year",
    transmissionType: "Transmission type",
    fuelType: "Fuel type",
    bodyType: "Body type",
    features: "Features"
};
