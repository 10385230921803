import { updateSessionStorageCount } from "../../../config/au.mobile/pre-bi-assistance-config";
import Types from "./types";

const setNudgeDetails = (details) => {
    return {
        type: Types.SET_NUDGE_DETAILS,
        details
    };
};

const showHelpSupportModal = (data) => {
    return {
        type: Types.SHOW_HELP_SUPPORT_MODAL,
        data
    };
};

const updateSupportWidgetConfig = (data) => {
    return {
        type: Types.UPDATE_SUPPORT_WIDGET_CONFIG,
        data
    };
};
const setModalContentSeen = (data) => {
    return {
        type: Types.SET_MODAL_CONTENT_SEEN,
        data
    };
};
const updateNudgeCounts = (category) => {
    updateSessionStorageCount(category);
    return {
        type: Types.UPDATE_NUDGE_COUNTS,
        category
    };
};
const updateNotificationCounts = () => {
    return {
        type: Types.UPDATE_NOTIFICATION_COUNTS
    };
};

const setNudgeTimerId = (timerId) => {
    return {
        type: Types.SET_NUDGE_TIMER_ID,
        timerId
    };
};

const setAccordionState = (category, state) => {
    return {
        type: Types.SET_ACCORDION_STATE,
        category,
        state
    };
};

const updateNudgeCountSyncStatus = (status) => {
    return {
        type: Types.UPDATE_NUDGE_COUNT_SYNC_STATUS,
        status
    };
};
export {
    setNudgeDetails,
    showHelpSupportModal,
    updateSupportWidgetConfig,
    setModalContentSeen,
    updateNudgeCounts,
    updateNotificationCounts,
    setNudgeTimerId,
    setAccordionState,
    updateNudgeCountSyncStatus
};
