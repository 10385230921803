import React from "react";
import { connect } from "react-redux";
import { fetchCarDetails } from "../car-details/actions";
import { fetchEgcPrice, getBookingSummary, getOrderDetail, setSplitPayment, updateOrder } from "../checkout-info/actions";
import TestDriveFinishPage from "./component";

const mapStateToProps = ({
    carDetails: { content, config },
    checkout: { order, egcData, bookingSummary },
    user: { secureToken, firstName }
}) => ({
    content,
    order,
    secureToken,
    egcData,
    config,
    firstName,
    bookingSummary
});

const mapDispatchToProps = {
    fetchEgcConnect: fetchEgcPrice,
    getOrderConnect: getOrderDetail,
    fetchCarDetailsConnect: fetchCarDetails,
    setSplitPaymentConnect: setSplitPayment,
    getBookingSummaryConnect: getBookingSummary,
    updateOrderConnect: updateOrder

};

export default connect(mapStateToProps, mapDispatchToProps)(TestDriveFinishPage);
