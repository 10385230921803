export const LMS_API_PATH = {
    DEAL_PAYMENT_INFO: "/sell24/deal"
};

export const BIKE_LEAD_STATUS = {
    BOOKED: "BOOKED",
    CONFIRMED: "CONFIRMED",
    INSPECTED: "INSPECTED",
    PURCHASED: "PURCHASED"
};

export const C2C_SECURE_API_PATH = {
    PAYWALL_BOOK_LEAD: "/paywall/buynow",
    PAYWALL_OFFERS: "/paywall/offer",
    CREATE_GS_BOOK_LEAD: "/b2c/booknow",
    GET_BUY_CAR_STATE: "/b2c/state",
    GET_BUY_PRICE_BREAKUP: "/price-breakup",
    GET_CAR_PICKUP_DETAILS: "/b2c/final-page",
    BUY_CAR_PAYMENT_OPTIONS: "/b2c/payment-options",
    BOOKING_CONFIRMATION_DETAILS: "/b2c/booking-details",
    PICKUP_LOCATION: "/b2c/pickup-location",
    APPLY_FOR_LOAN: "/b2c/apply-for-loan",
    LOAN_DETAILS: "/b2c/apply-for-loan/mobile",
    MY_BOOKINGS: "/b2c/booking-list",
    CANCEL_BOOKING: "/b2c/cancel-booking",
    RECOMMENDED_CARS: "/recommend-cars"
};

export const BOOKING_PRICE = {
    AE: 999
};

export const AUTH_ADDRESS = {
    GOOGLE: "GOOGLE",
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    FACEBOOK: "FACEBOOK",
    APPLE: "APPLE"
};

export const AUTH_PRODUCT_TYPE = "consumer-app-au";

export const X_VEHICLE_TYPE = {
    CAR: "CAR",
    car: "car"
};

export const X_COUNTRY_CODE = {
    "AU": "AU"
};
