import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cars24CoverWrapper from "./component";

const mapStateToProps = ({
    addOns: {
        servicingPriceConfig
    }
}) => ({
    servicingPriceConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cars24CoverWrapper);
