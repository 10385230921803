import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
//import Call from "./images/phone-icon.svg";
import { CALLBACK_SOURCE_SCREEN, NUMBER } from "../../../constants/app-constants";
import { CallBackRequest } from "../call-back/component";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

const HelpCenterModalDoubts = ({
    checkoutCallbackRequestConnect,
    checkoutCallbackRequestExpired,
    checkoutCallbackRequestLoading,
    mobile,
    onBackClick,
    onModalClose,
    open,
    isUserZeroDpVariant,
    loan
}) => {
    const { deposit } = loan || {};
    const handleGAEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_MOBILE_EVENTS.PRE_APPROVED_LOAN_OFFERS, {
            eventAction: "exit",
            eventLabel
        });
    };
    const handleCallbackRequest = async () => {
        try {
            await checkoutCallbackRequestConnect(CALLBACK_SOURCE_SCREEN.LOAN_OFFERS);
            window.setTimeout(onModalClose, NUMBER.THOUSAND);
            handleGAEvent("Callback");
        } catch (error) {
            //console.log(error)
        }
    };

    const handleLeaveClick = () => {
        handleGAEvent("Leave");
        onModalClose();
        onBackClick();
    };

    const displayZeroDpFLow = () => {
        return (
            <div>
                <p styleName={"styles.header"}>Before you leave we want to tell you that you can:</p>
                <ul>
                    <p styleName={"styles.listItem"}>Opt-in for <span>$0 deposit</span>, which will allow you reserve car without any deposit amount</p>
                    <p styleName={"styles.listItem"}><span>Get in touch</span> with our finance team so that we can help you out with loan offers</p>
                </ul>
            </div>
        );
    };

    const displayDefaultFlow = () => {
        return (
            <p styleName={"styles.text"}>Hey! If you have any <span>questions</span> about your loan offers we're here to help</p>
        );
    };

    return (
        <Modal isOpen={open} close={onModalClose}>
            <div styleName={"styles.loginModalNew"}>
                {(isUserZeroDpVariant && deposit > 0) ? displayZeroDpFLow() : displayDefaultFlow()}
                <CallBackRequest mobile={mobile} loading={checkoutCallbackRequestLoading} onClick={handleCallbackRequest} success={!checkoutCallbackRequestExpired} />
                <p onClick={handleLeaveClick} styleName={"styles.leave"}>I’ll still leave</p>
            </div>
        </Modal>
    );
};

export default HelpCenterModalDoubts;

HelpCenterModalDoubts.propTypes = {
    checkoutCallbackRequestConnect: PropTypes.func.isRequired,
    checkoutCallbackRequestExpired: PropTypes.bool.isRequired,
    checkoutCallbackRequestLoading: PropTypes.bool.isRequired,
    mobile: PropTypes.string,
    onBackClick: PropTypes.func.isRequired,
    isUserZeroDpVariant: PropTypes.bool,
    loan: PropTypes.object,
    onModalClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
