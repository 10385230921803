import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
const COUNTDOWN_INTERVAL = 1000;

const CircleTimerLoader = ({
    interval,
    onComplete,
    size = "small"
}) => {
    const [countdownInterval, setCountdownInterval] = useState(interval);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = window.setInterval(() => {
            setCountdownInterval(c => {
                if (c === 1) {
                    window.clearInterval(intervalRef.current);
                    onComplete();
                }
                return c - 1;
            });
        }, COUNTDOWN_INTERVAL);
        return () => window.clearInterval(intervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {size === "small" &&
            <div styleName="styles.small">
                <div styleName="styles.countdown">
                    <div ><p styleName="styles.countdownNumber">{countdownInterval}</p></div>
                    <svg>
                        <circle r="18" cx="20" cy="20" />
                    </svg>
                </div>
            </div>
            }
            {size === "large" &&
            <div styleName="styles.large">
                <div styleName="styles.countdown">
                    <div ><p styleName="styles.countdownNumber">{countdownInterval}</p></div>
                    <svg viewBox="0 0 50 50">
                        <circle r="18" cx="30" cy="25" />
                    </svg>
                </div>
            </div>
            }
        </React.Fragment>
    );
};

CircleTimerLoader.propTypes = {
    interval: PropTypes.number,
    onComplete: PropTypes.func,
    onlyText: PropTypes.bool,
    size: PropTypes.string
};

export default CircleTimerLoader;
