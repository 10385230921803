import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import ErrorHandler from "../../shared/with-error-handler";
import NeedHelpWidget from "../need-help-widget";
import BackIcon from "./images/back-icon.svg";

const VasStoreHeader = ({
    model,
    onCloseClick = () => {}
}) => {
    return (
        <React.Fragment>
            <div styleName={"styles.headerWrapper"}>
                <div styleName={"styles.titleWrapper"}>
                    <img src={BackIcon} onClick={onCloseClick}/>
                    <p styleName={"styles.headings"}>Add-ons for your <span>{model}</span></p>
                </div>
                <div styleName={"styles.leftWrapper"}>
                    <ErrorHandler>
                        <NeedHelpWidget vasStore={true}  />
                    </ErrorHandler>
                    <div styleName={"styles.needHelpWrapper"} onClick={onCloseClick}>
                        <p styleName={"styles.needHelp"}>Exit</p>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

VasStoreHeader.propTypes = {
    loading: PropTypes.bool,
    model: PropTypes.string,
    onCloseClick: PropTypes.func
};

export default VasStoreHeader;

