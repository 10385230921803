/* eslint-disable max-statements */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import Discount from "./images/discount-icon.svg";
import ArrowDown from "./images/arrow-down.svg";
// import Check from "./images/check.svg";
import styles from "./styles.css";
import InputText from "../../shared/input-text";
import Button from "../../shared/button";
import Modal from "../../shared/modal";
import { useHistory } from "react-router";
import ErrorIcon from "./images/error-icon.svg";
import { COUPON_STATUS, LOAN_PROVIDER } from "../../../constants/app-constants";
import Cross from "./images/cross.svg";
import Check from "./images/check.svg";
import CloseButton from "../../shared/icon-cross";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import DrivaPromocodeCard from "../driva-promocode-card";
import withFinancePolling from "../finance-polling";
import { DRIVA_POLLING_SCREENS } from "../../../constants/checkout-constants";
import getCouponCode from "../../../utils/checkout/get-coupon-code";

// eslint-disable-next-line max-statements
// eslint-disable-next-line complexity
const PromoCode = ({
    promoCode = {},
    order = {},
    viewType = "normal",
    applyPromoCodeConnect = () => {},
    resetApplyPromoCodeConnect = () => {},
    removePromoCodeConnect = () => {},
    showCouponSuccess = false,
    refreshLoanOffers = () => {},
    getOrderDetailConnect = () => { },
    appointmentId
}) => {
    const history = useHistory();
    const { maxPromoCount = 1, message = "message", isCouponApplied, couponCode} = promoCode;
    const {orderId} = order;
    const [showPromoCode, setShowPromoCode] = useState(false);
    const [value, setValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {discountCode = ""} = getCouponCode(order);

    const handlePromoVisibility = () => {
        setShowPromoCode(!showPromoCode);
    };

    useEffect(() => {
        if (discountCode && !value) {
            const payload = { couponCode: discountCode ? discountCode.trim() : discountCode };
            applyPromoCodeConnect(orderId, payload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountCode]);

    useEffect(() => {
        setValue(couponCode);
        setShowPromoCode(true);
    }, [couponCode]);

    const resetPromoCode = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.COUPON_REMOVE, { eventLabel: value });
        resetApplyPromoCodeConnect();
        setValue("");
        setErrorMessage("");
    };

    const handleValue = (event) => {
        const val = event.target.value;
        setValue(val);
        if (!val) resetPromoCode();
    };

    const handleSplit = () => {
        history.goBack();
    };

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const handleApply = () => {
        if (!value) {
            setErrorMessage("Enter a valid coupon code");
            return;
        }
        setIsLoading(true);
        if (isCouponApplied) {
            // resetPromoCode()
            const payload = { couponCode: value };
            removePromoCodeConnect(orderId, payload).then(async ({showCouponPopup}) => {
                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.COUPON_REMOVE, {eventLabel: value});
                if (showCouponPopup) {handleModal();}
                if (showCouponSuccess) setValue(value);
                await refreshLoanOffers();
                await getOrderDetailConnect(appointmentId);
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        } else {
            const payload = { couponCode: value ? value.trim() : value };
            applyPromoCodeConnect(orderId, payload)
                .then(async ({showCouponPopup, status, message: rejectMessage}) => {
                    trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.COUPON_APPLY_BUTTON,
                        {eventLabel: status === COUPON_STATUS.SUCCESS ? status : `${status}_${rejectMessage}` });
                    if (showCouponPopup) { handleModal(); }
                    await refreshLoanOffers();
                    await getOrderDetailConnect(appointmentId);
                    setIsLoading(false);
                }).catch(() => {
                    setIsLoading(false);
                });
        }

    };

    const cancelModal = () => {
        handleModal();
        resetPromoCode();
    };

    const inputBlur = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.COUPON_CODE_ENTERED, {eventLabel: value });
    };
    const onChangeHandler = () => {
        handleApply();
    };

    const isError = ((message && !isCouponApplied) || errorMessage);

    const showCouponAppliedComponent = isCouponApplied && showCouponSuccess;
    return (
        <React.Fragment>
            {
                showCouponAppliedComponent ?  <DrivaPromocodeCard promoCode={promoCode} onChangeHandler={onChangeHandler} /> :
                    <div styleName={viewType === LOAN_PROVIDER.DRIVA ? "styles.promoSectionDriva" : "styles.promoSection"}>
                        <div
                            styleName="styles.promoWrapperHeader" onClick={handlePromoVisibility}>
                            <img src={Discount} alt="discount" styleName="styles.promoIcon"/>
                            <p>Coupon code</p>
                            <img src={ArrowDown} alt="discount" styleName={!showPromoCode ? "styles.promoArowIcon" : "styles.promoArowIcon styles.rotateArrow"} />
                        </div>
                        {showPromoCode &&
                            <React.Fragment>
                                <p styleName="styles.maxCount">Max {maxPromoCount} code per transaction</p>
                                <div styleName={`styles.inputWrapper`}>
                                    <div styleName={isError ? "styles.errorInputDiv" :  "styles.inputDiv" }>
                                        <InputText
                                            placeholder={"Enter coupon code"}
                                            onChange={handleValue}
                                            value={value}
                                            onBlurCallback={inputBlur}
                                            // showValidCheckmark={isCouponApplied}
                                            // disabled={isCouponApplied}
                                        />
                                        { isError && <img src={Cross} alt="cross" styleName="styles.crossImg" onClick={resetPromoCode}/>}
                                        {isCouponApplied && <img src={Check} alt="check" styleName="styles.crossImg styles.checkimg" />}
                                    </div>
                                    <div styleName="styles.buttonWrapper">
                                        <Button
                                            text={isCouponApplied ? "remove" : "apply"}
                                            onClick={handleApply}
                                            disabled={!value}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </div>
                                {isCouponApplied && value && <div styleName={`styles.message styles.success`}>
                                    <p>{message}</p>
                                </div>}
                            </React.Fragment>
                        }
                        {isError &&  <span styleName="styles.error">{message || errorMessage}</span> }

                        <Modal
                            isOpen={showModal}
                            close={cancelModal}
                            closeTimeoutMS={200}
                            overlayClass={"promoOverlayClass"}
                        >
                            <div styleName="styles.modalContentWrapper">
                                <div styleName={"styles.closeIcon"} onClick={cancelModal}>
                                    <CloseButton type="grey"  />
                                </div>
                                <div styleName="styles.modalMessageWrapper">
                                    <img styleName={"styles.errorIcon"} src={ErrorIcon} alt="update deposit" />

                                    <div styleName="styles.modalSection">
                                        <p styleName={"styles.messageText"}>{message}</p>
                                    </div>
                                    <ul>
                                        <li onClick={handleSplit}><p>update deposit</p></li>
                                        {/* <li onClick={cancelModal}><p>Cancel</p></li> */}
                                    </ul>
                                </div>
                            </div>
                        </Modal>
                    </div>}
        </React.Fragment>
    );
};

PromoCode.propTypes = {
    promoCode: PropTypes.number,
    content: PropTypes.object,
    showCouponSuccess: PropTypes.bool,
    userId: PropTypes.string,
    appointmentId: PropTypes.string,
    email: PropTypes.string,
    price: PropTypes.number,
    applyPromoCodeConnect: PropTypes.func,
    order: PropTypes.object,
    resetApplyPromoCodeConnect: PropTypes.func,
    refreshLoanOffers: PropTypes.func,
    getOrderDetailConnect: PropTypes.func,
    removePromoCodeConnect: PropTypes.func,
    viewType: PropTypes.string
};

//export default PromoCode;
export default withFinancePolling({screenType: DRIVA_POLLING_SCREENS.CHECKOUT_PAYMENT})(PromoCode);
