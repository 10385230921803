/* eslint-disable complexity */

import { NUMBER } from "../../constants/app-constants";
import { FILTER_ENTITIES } from "../../utils/filters-v2/constants";

const basicParser = filter => filter.map(fv => fv.displayText).join("|");
// const basicParser = (filter = {}) => filter.displayText;
const rangeParser = (filter = []) => (filter && filter[0]) ? [filter[0].min, filter[0].max].join("-") : undefined;
const budgetParser = (filter = []) => (filter && filter[0]) ? {min: filter[0].min, max: filter[0].max} : {};

const parserMap = {
    [FILTER_ENTITIES.PRICE]: budgetParser,
    [FILTER_ENTITIES.BODY_TYPE]: basicParser,
    [FILTER_ENTITIES.ODOMETER_READING]: rangeParser,
    [FILTER_ENTITIES.YEAR]: rangeParser,
    [FILTER_ENTITIES.FUEL_TYPE]: basicParser,
    [FILTER_ENTITIES.TRANSMISSION]: basicParser,
    [FILTER_ENTITIES.MAKE]: basicParser
};

export const parseFilterToDimension = (filters, applied, selectedSort) => {
    const appliedFilters = Object.keys(filters || {})
        .filter(filter => applied.filter(f => f.filterKey === filter).length && parserMap[filter])
        .reduce((pv, cv) => {
            return {
                ...pv,
                ...{ [cv]: parserMap[cv](applied.filter(f => f.filterKey === cv)) }
            };
        }, {});

    return {
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { list: appliedFilters[FILTER_ENTITIES.PRICE].min }),
        ...(appliedFilters[FILTER_ENTITIES.PRICE] && { list: appliedFilters[FILTER_ENTITIES.PRICE].max }),
        ...(appliedFilters[FILTER_ENTITIES.MAKE] && { list: appliedFilters[FILTER_ENTITIES.MAKE] }),
        ...(appliedFilters[FILTER_ENTITIES.ODOMETER_READING] && { list: appliedFilters[FILTER_ENTITIES.ODOMETER_READING] }),
        ...(appliedFilters[FILTER_ENTITIES.YEAR] && { list: appliedFilters[FILTER_ENTITIES.YEAR] }),
        ...(appliedFilters[FILTER_ENTITIES.FUEL_TYPE] && { list: appliedFilters[FILTER_ENTITIES.FUEL_TYPE] }),
        ...(appliedFilters[FILTER_ENTITIES.TRANSMISSION] && { list: appliedFilters[FILTER_ENTITIES.TRANSMISSION] }),
        ...(appliedFilters[FILTER_ENTITIES.BODY_TYPE] && { list: appliedFilters[FILTER_ENTITIES.BODY_TYPE]}),
        ...(selectedSort.key && { list: selectedSort.key})
    };
};

const carData = (carDetailsData, {
    // list = "",
    position = "",
    similarCar,
    excludedKeys = [],
    cdpSource,
    usp,
    tag
    // filterDimensions = {}
} = {}) => {
    const {
        appointmentId,
        carId,
        egc,
        make,
        body,
        bodyType,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        modelYear,
        model,
        transmissionType,
        variant,
        kbbAskingPrice,
        booked,
        reserved
    } = carDetailsData || {};
    const kbbMarketPrice = kbbAskingPrice - egc;
    const showKbbMarketPrice = egc < kbbAskingPrice && kbbMarketPrice >=  NUMBER.FIVE_HUNDRED;
    let status = null;
    if (booked) {status =  "booked";} else if (reserved) {status = "reserved";}
    const data = {
        "name": `${year} ${make} ${model}`,
        "app id": appointmentId || carId,
        "price": `${egc}`,
        "brand": make,
        "body type": bodyType || body,
        variant,
        // list,
        ...(position && {position: `${position}`}),
        "location": city,
        ...(views && {dimension5: views}),
        "total km": odometerReading || kilometerDriven,
        year: `${year}`,
        "transmission": transmissionType,
        model,
        "below market price": showKbbMarketPrice ? "yes" : "no",
        status,
        "ModelYearGreaterThanBuildYear": +modelYear > +year ? "Yes" : "No",
        ...(similarCar && {"similar car": `${similarCar}`}),
        ...(cdpSource && {"cdp source": `${cdpSource}`}),
        ...(usp && {"usp": `${usp}`}),
        ...(tag && {"tag": `${tag}`})

        // ...filterDimensions
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

const checkoutData = (carDetailsData, {
    bookingAmount = "",
    orderId = "",
    excludedKeys = []
} = {}) => {
    const {
        appointmentId,
        carId,
        egc,
        make,
        bodyType,
        body,
        city,
        views,
        odometerReading,
        kilometerDriven,
        year,
        modelYear,
        model,
        variant,
        transmissionType,
        paymentMode,
        kbbAskingPrice
    } = carDetailsData || {};
    const kbbMarketPrice = kbbAskingPrice - egc;
    const showKbbMarketPrice = egc < kbbAskingPrice && (kbbMarketPrice) >=  NUMBER.FIVE_HUNDRED
    && kbbMarketPrice < NUMBER.TEN_THOUSAND ? kbbMarketPrice : false;
    const data = {
        "name": `${year} ${make} ${model}`,
        "app id": appointmentId || carId,
        "price": egc,
        "brand": make,
        "body type": bodyType || body,
        variant,
        "location": city,
        ...(views && {dimension5: views}),
        "total km": odometerReading || kilometerDriven,
        year,
        "transmission": transmissionType,
        model,
        "payment mode": paymentMode,
        ...(bookingAmount && {"booking amount": bookingAmount}),
        "order id": orderId,
        "ModelYearGreaterThanBuildYear": +modelYear > +year ? "Yes" : "No",
        "below market price": showKbbMarketPrice ? "Yes" : "No"
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

export default (payload, properties = {}) => {
    return {
        carListing: {
            impressions: carData(payload, properties),
            click: carData(payload, properties)
        },
        carDetails: {
            impressions: carData(payload, properties)
        },
        startPurchase: carData(payload, properties),
        checkout: {
            ...checkoutData(payload, properties)
        },
        thankYou: {
            ...checkoutData(payload, properties),
            affiliation: "CARS24"
        }
    };
};
