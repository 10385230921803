import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./styles.css";

import VasStoreSliderCard from "../vas-store-slider-card/component";
import { SCREEN_TYPE } from "../reducers";
import { trackMobileCustomEventsAU } from "../../../../tracking";
import { getVASItems, setAddonScreenType } from "../actions";
import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";

const VasStoreSlider = ({
    activeStore,
    egc,
    getVASItemsConnect,
    setAddonScreenTypeConnect,
    vasItems,
    year
}) => {
    const { data } = vasItems || {};
    const { options = [] } = data || {};

    return (
        <div styleName={"styles.bundleWrapper"}>
            {options.map((option, i) => {
                const { webIconUrl: iconUrl, key, name } = option || {};
                const active = activeStore ? activeStore === key : i === 0;

                const onProductClick = async () => {
                    await getVASItemsConnect(key, year, egc);
                    setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.VAS_STORE, activeStore: key });
                    trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                        eventAction: "Tab_clicked",
                        eventLabel: name
                    });
                };

                return (
                    <VasStoreSliderCard key={key} onClick={onProductClick} isActive={active} cardTitle={name} cardImage={iconUrl} isAvailable={true} />
                );
            })}
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content: {
            egc,
            year
        }
    },
    addOns: {
        vasItems,
        activeStore
    }
}) => ({
    activeStore,
    egc,
    vasItems,
    year
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setAddonScreenTypeConnect: setAddonScreenType,
    getVASItemsConnect: getVASItems
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VasStoreSlider);

VasStoreSlider.propTypes = {
    activeStore: PropTypes.string,
    egc: PropTypes.number,
    getVASItemsConnect: PropTypes.func,
    setAddonScreenTypeConnect: PropTypes.func,
    vasItems: PropTypes.object,
    year: PropTypes.number
};

