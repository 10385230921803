import Statsig from "statsig-js";
import { NUMBER } from "../../constants/app-constants";
import saveCookie from "../helpers/save-cookie";
import parseCookie from "../helpers/parse-cookie";
import statsigEnv from "./statsig-host";
import { v4 as uuidv4 } from "uuid";

export const statsigInitialization = async ({
    statsigId = null
}) => {
    try {
        const userId = parseCookie("userId");
        const statsigStableId = parseCookie("statsigStableId");
        const finalUserId = !statsigStableId ? uuidv4() : statsigStableId;

        if (!statsigStableId && statsigId) {
            saveCookie("statsigStableId", statsigId, NUMBER.ONE_HUNDRED_EIGHTY);
        } else if (!statsigStableId) {
            saveCookie("statsigStableId", finalUserId, NUMBER.ONE_HUNDRED_EIGHTY);
        }

        const statsigUser = {
            userID: userId,
            customIDs: { stableID: finalUserId }
        };

        await Statsig.initialize(
            process.env.STATSIG_SECRET_CSR_KEY,
            statsigUser,
            {
                environment: { tier: statsigEnv() },
                overrideStableID: finalUserId
            } // optional, pass options here if needed
        );
    } catch (err) {
        // eslint-disable-next-line no-console, no-undef
        console.log(err, "Error in statsigInitialization file");
    }
};
