import loadable from "@loadable/component";

const GetPreApprovalInfo = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/get-pre-approval-info"));
const FinanceLoanDetails = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/finance-loan-details"));
//const FinanceLoanType = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/finance-loan-type"));
const FinancePersonalDetails = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/finance-personal-details"));
const FinanceEmploymentDetails = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/finance-employment-details"));
const FinanceViewCars = loadable(() => import("../../components/au.desktop/pre-approval-financing-flow/finance-view-cars"));

export const financingRoutes = {
    preApprovedLoan: {
        key: "getPreApproval",
        name: "get pre approval",
        route: "get-pre-approval",
        gtmPageName: "pre_approved",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "loan-details",
        showPreApprovalBanner: true,
        component: GetPreApprovalInfo,
        type: "pre-approval-financing",
        approx: 0,
        hideCurrentPill: true,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    // financing routes
    loanDetails: {
        key: "loanDetails",
        name: "loan details",
        route: "loan-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "loan_details",
        defaultBack: "get-pre-approval",
        defaultNext: "employment-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: true,
        component: FinanceLoanDetails,
        type: "pre-approval-financing",
        approx: 4,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    //loanType: {
    //    key: "loanType",
    //    name: "loan type",
    //    route: "loan-type",
    //    gtmPageName: "Driva_business_purpose",
    //    gtmPillClickLabel: "loan_type",
    //    defaultBack: "loan-details",
    //    defaultNext: "personal-details",
    //    showPreApprovalBanner: true,
    //    showPreApprovalPill: true,
    //    component: FinanceLoanType,
    //    type: "pre-approval-financing",
    //    approx: 3,
    //    sidebarNavigation: {
    //        heading: "Loan detail",
    //        subHeading: "Fill pre-approval form  ",
    //        navKey: "paymentOptions"
    //    }
    //},
    employmentDetails: {
        key: "employmentDetails",
        name: "employment details",
        route: "employment-details",
        gtmPageName: "Driva_employment_status",
        gtmPillClickLabel: "employment_details",
        defaultNext: "personal-details",
        defaultBack: "loan-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: true,
        component: FinanceEmploymentDetails,
        type: "pre-approval-financing",
        approx: 1,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    personalDetails: {
        key: "personalDetails",
        name: "your details",
        route: "",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "driva_personal_details",
        defaultNext: "result",
        defaultBack: "employment-details",
        showPreApprovalBanner: true,
        showPreApprovalPill: true,
        component: FinancePersonalDetails,
        type: "pre-approval-financing",
        approx: 2,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    result: {
        key: "result",
        name: "result",
        route: "result",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "personal-details",
        hideCurrentPill: true,
        component: FinanceViewCars,
        type: "pre-approval-financing",
        approx: 1,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form",
            navKey: "paymentOptions"
        }
    }
};

export const financingRoutesArray = Object.values(financingRoutes);

export const routesToRedirectCheckout = financingRoutesArray
    .filter((routeData) => routeData.type === "checkout")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = financingRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = financingRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);
