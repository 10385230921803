/* eslint-disable no-magic-numbers */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const TransparentTooltip = ({
    text,
    centerX,
    centerY,
    isVisible = false
}) => {
    const tooltipStyles = [
        "styles.tooltip",
        centerX && !centerY && "styles.centerX",
        centerY && !centerX && "styles.centerY",
        centerX && centerY && "styles.centerXY"
    ].filter(Boolean).join(" ");

    return (
        isVisible ? <div styleName={tooltipStyles}>{text}</div> : null
    );
};

TransparentTooltip.propTypes = {
    text: PropTypes.text,
    centerX: PropTypes.bool,
    centerY: PropTypes.bool,
    isVisible: PropTypes.bool,
    autoHide: PropTypes.bool,
    duration: PropTypes.number
};

export default TransparentTooltip;
