/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { poll } from "../../../utils/polling";
import { drivaPollApi,  inHouseFinancePollApi, inHouseFinanceFirstFinancePollApi, getInHouseFinanceTimeoutConfig, getDrivaTimeoutConfig } from "../config-ssr/actions";
import {  DRIVA_POLLING_SCREENS_STEPS_KEYS, DRIVA_POLLING_STATUS, DRIVA_POLLING_CHECKOUT_SCREENS, DRIVA_POLLING_SCREENS } from "../../../constants/checkout-constants";
import { getLoanOfferDetailsSuccess, updateLoanOffer } from "../checkout-info/actions";
import { getPreApprovalLoanDetailsSuccess, getInhousePreApprovalLoanDetailsSuccess } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import retryImage from "./images/retry.svg";
import requestCallBackImage from "./images/request-callback.svg";
import Button from "../../shared/button";
import { useHistory } from "react-router";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import { getCheckoutPageURL, getTestDriveV2CheckoutURL } from "../../../utils/helpers/get-detail-page-url";
import { showToast } from "../../shared/toast-message/actions";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";

const withFinancePolling = (params) => {
    return function withHOC(Component) {
        const FinancePolling = (props) => {
            const history = useHistory();
            const {
                drivaPollingConfig,
                inHouseFinancePollingConfig,
                drivaPollApiConnect = () => {},
                getDrivaTimeoutConfigConnect = () => {},
                getLoanOfferDetailsSuccessConnect = () => {},
                getPreApprovalLoanDetailsSuccessConnect = () => {},
                getInhousePreApprovalLoanDetailsSuccessConnect = () => {},
                orderId,
                content,
                inHouseFinancePollApiConnect = () => {},
                showInHouseFinance,
                inHouseFinanceEligibilityVariant,
                inHouseFinanceEligibilityFinanceFirst,
                getInHouseFinanceTimeoutConfigConnect = () => {},
                inHouseFinanceFirstFinancePollApiConnect = () => {},
                quotesAvailable,
                updateLoanOfferConnect = () => {},
                preApporvedOrigin
            } = props;
            const showInHouse = inHouseFinanceEligibilityVariant;
            const showInHouseFinanceFirst =  (showInHouseFinance || {}).data === EXPERIMENT_TYPE.VARIANT_B && inHouseFinanceEligibilityFinanceFirst;
            const { screenType = "", screenOrigin = ""} = params || {};
            const [count, setCount] = useState(0);
            // const [currentScreenData, setCurrentScreenData] = useState({});
            const [startPolling, setStartPolling] = useState(false);
            const [retryCount, setRetryCount] = useState(0);
            const [drivaPollingStatus, setDrivaPollingStatus] = useState("");
            const config = (showInHouse || showInHouseFinanceFirst) ? inHouseFinancePollingConfig && inHouseFinancePollingConfig.config.webAppConfig : drivaPollingConfig && drivaPollingConfig.config.webAppConfig;
            const pollConfigData = (config || []).filter((data) => data.screen === screenType);
            const configData = (pollConfigData && pollConfigData[0]) || {};
            const isMounted = useRef(true);
            const generateNextUrl = preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE ? getTestDriveV2CheckoutURL : getCheckoutPageURL;

            useEffect(() => {
                return () => {
                    isMounted.current = false;
                };
            }, []);

            useEffect(() => {
                try {
                    if (showInHouse || showInHouseFinanceFirst) {
                        getInHouseFinanceTimeoutConfigConnect();
                    } else {
                        getDrivaTimeoutConfigConnect();
                    }
                } catch (e) {
                    //e
                }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [showInHouse, showInHouseFinanceFirst]);

            const dataRecieved = (response, attempts) => {
                const stopPolling = !isMounted.current; //On component un-mount stop polling
                if (stopPolling) {
                    return true;
                }
                if (response && response.pollStatus === DRIVA_POLLING_STATUS.COMPLETED) {
                    if (DRIVA_POLLING_CHECKOUT_SCREENS.indexOf(screenType) >= 0) {
                        getLoanOfferDetailsSuccessConnect(response, showInHouse);
                    } else if (showInHouseFinanceFirst) {
                        getInhousePreApprovalLoanDetailsSuccessConnect(response);
                    } else {
                        getPreApprovalLoanDetailsSuccessConnect(response);
                    }
                    setStartPolling(false);
                    setDrivaPollingStatus(DRIVA_POLLING_STATUS.COMPLETED);
                    return true;
                } else if (response && response.pollStatus === DRIVA_POLLING_STATUS.PROCESSING_ERROR) {
                    setStartPolling(false);
                    setDrivaPollingStatus(DRIVA_POLLING_STATUS.ERROR);
                    return true;
                }
                setCount(attempts);
                return false;
            };

            const pollApi = async (newOrderId, token, uuid) => {
                if (configData.maxAttempts) {
                    try {
                        let apiParams = {screen: screenType, token};
                        let action = null;
                        if (DRIVA_POLLING_CHECKOUT_SCREENS.indexOf(screenType) >= 0) {
                            if (showInHouse) {
                                action = inHouseFinancePollApiConnect;
                            } else {
                                action = drivaPollApiConnect;
                            }
                            apiParams = {...apiParams, orderId: newOrderId || orderId};
                        } else if (showInHouseFinanceFirst) {
                            action = inHouseFinanceFirstFinancePollApiConnect;
                            apiParams = {...apiParams, uuid};
                        } else {
                            action = drivaPollApiConnect;
                            apiParams = {...apiParams};
                        }
                        const pollParams = {
                            fn: action,
                            validate: dataRecieved,
                            fnParams: apiParams,
                            interval: configData.interval,
                            maxAttempts: configData.maxAttempts
                        };
                        await poll(pollParams);
                    } catch (error) {
                        setStartPolling(false);
                        setDrivaPollingStatus(DRIVA_POLLING_STATUS.ERROR);
                    }
                } else {
                    setStartPolling(false);
                    setDrivaPollingStatus(DRIVA_POLLING_STATUS.ERROR);
                }
            };

            // eslint-disable-next-line max-params
            const handlePollingCallback = (data, newOrderId, token, uuid) => {
                setDrivaPollingStatus(DRIVA_POLLING_STATUS.STARTED);
                setStartPolling(data);
                if (data) {
                    pollApi(newOrderId, token, uuid);
                }
            };

            const handleRetryClick = () => {
                setCount(0);
                setRetryCount(retryCount + 1);
            };

            const handleRequestCallback = () => {
                if (DRIVA_POLLING_CHECKOUT_SCREENS.indexOf(screenType) >= 0) {
                    const route = checkoutRoutes.paymentOptions.route;
                    const { relativeURL } = generateNextUrl(content, route);
                    history.push(relativeURL);
                    setStartPolling(false);
                } else {
                    history.push("/");
                    setStartPolling(false);
                }
            };

            const handleCurrentScreenData = (data) => {
                if (retryCount === configData.retryCount && data.key === DRIVA_POLLING_SCREENS_STEPS_KEYS.REQUEST_CALLBACK) {
                    return true;
                } else {
                    return (count >= data.showFromMinAttemptNo && count < data.showToMaxAttemptNo) || (count === data.showFromMinAttemptNo && retryCount !== configData.retryCount);
                }
            };

            const screenData = (configData.steps || []).filter(handleCurrentScreenData)[0] || {};
            const renderImage = () => {
                if (screenData.key === DRIVA_POLLING_SCREENS_STEPS_KEYS.REQUEST_CALLBACK) {
                    return (
                        <div
                            styleName={"styles.primary"} role="status">
                            <img src={ requestCallBackImage} alt={"requestCallBackImage"} />
                        </div>
                    );
                } else if (screenData.key === DRIVA_POLLING_SCREENS_STEPS_KEYS.POLLING_RETRY) {
                    return (
                        <div
                            styleName={"styles.primary"} role="status">
                            <img src={retryImage} alt={"retry"}  />
                        </div>
                    );
                } else {
                    return (
                        <div styleName={"styles.spinner styles.primary"} role="status">
                            <span
                                styleName={"styles.loading"}>{screenData.title || "Please Wait"}
                            </span>
                        </div>
                    );
                }
            };

            const renderButton = () => {
                if (screenData.key === DRIVA_POLLING_SCREENS_STEPS_KEYS.REQUEST_CALLBACK) {
                    return (
                        <div>
                            <Button styleName={"styles.button"} text={"REQUEST CALLBACK"} onClick={handleRequestCallback} />
                        </div>
                    );
                } else if (screenData.key === DRIVA_POLLING_SCREENS_STEPS_KEYS.POLLING_RETRY) {
                    return (
                        <div>
                            <Button styleName={"styles.button"} text={"Retry"} onClick={handleRetryClick} />
                        </div>
                    );
                } else {
                    return (
                        <React.Fragment />
                    );
                }
            };

            const getWapperClass = () => {
                if (screenType === DRIVA_POLLING_SCREENS.PRE_APPROVAL_EMPLOYMENT || screenType === DRIVA_POLLING_SCREENS.PRE_APPROVAL_EDIT) {
                    return "styles.loaderWrapper";
                } else if (screenOrigin === DRIVA_POLLING_SCREENS.DRIVA_CUSTOMIZE_HEADER_REVAMP) {
                    return "styles.loaderWrapper styles.loaderCenterScreen";
                } else if (screenOrigin === DRIVA_POLLING_SCREENS.SERVICEABILITY_ERROR_MODAL) {
                    return "styles.loaderWrapper styles.serviceabilityErrorScreen";
                } else if (screenOrigin === DRIVA_POLLING_SCREENS.UPDATE_LOAN_TERMS_MODAL) {
                    return "styles.loaderWrapper styles.rightDrawerModal";
                } else {
                    return "styles.loaderWrapper styles.checkoutScreenLoader";
                }
            };

            const getTitle = () => {
                const title = screenData.title;
                return title;
            };

            const getOverlayClass = () => {
                if (screenOrigin === DRIVA_POLLING_SCREENS.DRIVA_CUSTOMIZE_HEADER_REVAMP) {
                    return "checkOutLoader";
                } else if (screenOrigin === DRIVA_POLLING_SCREENS.SERVICEABILITY_ERROR_MODAL || screenOrigin === DRIVA_POLLING_SCREENS.UPDATE_LOAN_TERMS_MODAL) {
                    return "checkoutServiceabilityErrorLoader";
                } else {
                    return "preApprovalLoader";
                }
            };

            const refreshLoanOffers = async () => {
                if (orderId) {
                    try {
                        await updateLoanOfferConnect({
                            submitted: !!quotesAvailable,
                            pollBased: false,
                            retryFromUI: false,
                            persistSelectedQuote: true
                        });
                        if (quotesAvailable) {
                            handlePollingCallback(quotesAvailable);
                        }
                    } catch (error) {
                        //console.log(error);
                    }
                }
            };

            React.useEffect(() => {
            }, [screenData.key]);

            return (
                <React.Fragment>
                    <Component
                        {...props}
                        pollConfigData={pollConfigData || []}
                        handlePollingCallback={handlePollingCallback}
                        retryCount={retryCount}
                        drivaPollingStatus={drivaPollingStatus}
                        startPolling={startPolling}
                        refreshLoanOffers={refreshLoanOffers}
                        setPollingLoading={setStartPolling}
                    />
                    {startPolling && <Modal isOpen={startPolling} overlayClass={getOverlayClass()}>
                        <div
                            styleName={getWapperClass()}>
                            {renderImage()}
                            <p styleName={"styles.wait"}>{getTitle()}</p>
                            <p styleName={"styles.additionaltext"}>Do not close the window or refresh, this might take a few seconds…</p>
                            {screenData.subTitle && <p
                                styleName={"styles.detail"}>{screenData.subTitle}</p>}
                            {renderButton()}
                        </div>
                    </Modal>}
                </React.Fragment>
            );
        };

        FinancePolling.propTypes = {
            drivaPollingConfig: PropTypes.object,
            screenType: PropTypes.string,
            subHeading: PropTypes.string,
            heading: PropTypes.string,
            title: PropTypes.string,
            drivaPollApiConnect: PropTypes.func,
            getDrivaTimeoutConfigConnect: PropTypes.func,
            pollingCompletedCallback: PropTypes.func,
            pollingErrorCallback: PropTypes.func,
            getLoanOfferDetailsSuccessConnect: PropTypes.func,
            getPreApprovalLoanDetailsSuccessConnect: PropTypes.func,
            orderId: PropTypes.string,
            content: PropTypes.object,
            drivaCallback: PropTypes.func,
            inHouseFinancePollApiConnect: PropTypes.func,
            showInHouseFinance: PropTypes.object,
            inHouseFinanceEligibilityVariant: PropTypes.bool,
            inHouseFinancePollingConfig: PropTypes.object,
            getInHouseFinanceTimeoutConfigConnect: PropTypes.object,
            getInhousePreApprovalLoanDetailsSuccessConnect: PropTypes.func,
            inHouseFinanceFirstFinancePollApiConnect: PropTypes.func,
            inHouseFinanceEligibilityFinanceFirst: PropTypes.bool,
            financeUniqueId: PropTypes.string,
            quotesAvailable: PropTypes.bool,
            updateLoanOfferConnect: PropTypes.func,
            preApporvedOrigin: PropTypes.string
        };

        const mapStateToProps = ({
            config: {
                drivaPollingConfig,
                inHouseFinancePollingConfig
            },
            checkout: {
                order: {
                    orderId
                },
                inHouseFinanceEligibilityVariant,
                quotesAvailable
            },
            carDetails: {
                content
            },
            preApprovalLoan: {
                financeUniqueId,
                inHouseFinanceEligibility: inHouseFinanceEligibilityFinanceFirst,
                preApporvedOrigin
            },
            abExperiment: {
                showInHouseFinance
            }
        }) => ({
            drivaPollingConfig,
            inHouseFinancePollingConfig,
            orderId,
            content,
            showInHouseFinance,
            inHouseFinanceEligibilityVariant,
            inHouseFinanceEligibilityFinanceFirst,
            financeUniqueId,
            quotesAvailable,
            preApporvedOrigin
        });

        const mapDispatchToProps = (dispatch) => bindActionCreators({
            drivaPollApiConnect: drivaPollApi,
            getDrivaTimeoutConfigConnect: getDrivaTimeoutConfig,
            getLoanOfferDetailsSuccessConnect: getLoanOfferDetailsSuccess,
            getPreApprovalLoanDetailsSuccessConnect: getPreApprovalLoanDetailsSuccess,
            getInhousePreApprovalLoanDetailsSuccessConnect: getInhousePreApprovalLoanDetailsSuccess,
            showToastConnect: showToast,
            inHouseFinancePollApiConnect: inHouseFinancePollApi,
            inHouseFinanceFirstFinancePollApiConnect: inHouseFinanceFirstFinancePollApi,
            getInHouseFinanceTimeoutConfigConnect: getInHouseFinanceTimeoutConfig,
            updateLoanOfferConnect: updateLoanOffer
        }, dispatch);

        return connect(mapStateToProps, mapDispatchToProps)(FinancePolling);
    };

};

export default withFinancePolling;
