import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import payment from "../../payment";
import PayDeposit from "./component";

const mapStateToProps = ({
    user: { mobile },
    carDetails: {content},
    checkout: {egcData, order: {orderId}}
}) => ({
    content,
    egcData,
    mobile,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default payment(connect(mapStateToProps, mapDispatchToProps)(PayDeposit));
