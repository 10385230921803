/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import Unselected from "./images/unselected.svg";
import Selected from "./images/selected.svg";
import BestValue from "./images/badge.png";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";

const PlatinumCoverMonthlyCard = ({
    type,
    coverDetails,
    isActive,
    setSelectedProductIndex,
    index,
    clearSelection
}) => {
    const { title, description, amount, actualRepaymentsPerWeek, tag, products } = coverDetails || {};
    const isBundleSection = products?.length > 0;
    const isBestValue = tag?.toLowerCase() === "best value";

    return (
        <div styleName={`styles.outer ${isActive ? "styles.cardActive" : ""}`}
            onClick={() => {
                setSelectedProductIndex(index);
            }}>
            {isBestValue && <div styleName={"styles.bestValue"}>
                <img src={BestValue} alt="Best value sticker" />
            </div>}
            <div styleName={"styles.wrapper"}>
                <div className="media" styleName={"styles.cardContainer"}>
                    <img src={isActive ? Selected : Unselected} alt="Unselected Checkbox" />
                    <div className="media-body">
                        <p styleName={"styles.heading"}>{title}</p>
                        {!isBundleSection && <p styleName={"styles.list"}>{description}</p>}
                        <ul>
                            {isBundleSection && products.map((product, listIndex) => <li key={listIndex} styleName={"styles.list"}>{product.bundleDescription}</li>)}
                        </ul>
                    </div>
                </div>
                <div styleName={"styles.amountWrapper"}>
                    <p styleName={"styles.amountPerWeek"}>{type === "loan" ? <React.Fragment>
                        {makePriceLabelRound(actualRepaymentsPerWeek)}<span>/wk</span>
                    </React.Fragment> : makePriceLabelRound(amount)}</p>
                    {isActive && <p styleName={"styles.clearSelect"} onClick={(e) => clearSelection(e, index)}>Clear selection</p>}
                </div>
            </div>
        </div>
    );
};

PlatinumCoverMonthlyCard.propTypes = {
    type: PropTypes.string,
    coverDetails: PropTypes.object,
    isActive: PropTypes.bool,
    setSelectedProductIndex: PropTypes.func,
    index: PropTypes.number,
    clearSelection: PropTypes.func

};

export default PlatinumCoverMonthlyCard;
