import * as React from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import ChatWidgetRevamp from "../chat-widget-revamp/component";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
const HelpSupportModal = loadable(() => import("../help-support-modal/component"));
const ContactSelectModal = loadable(() => import("../contact-select-modal"));

const SupportWidget = ({ showWidget = true, isSellCarPage = false, supportWidget, showHelpSupportModalConnect, showEmailSupportModalConnect }) => {

    const {showHelpSupportModal, showEmailSupportModal, hideWidgetIcon} = supportWidget;

    const handleEmailUsModal = (val) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HELP_SUPPORT_ICON_CLICKED, {
            eventLabel: window.location.href
        });
        showEmailSupportModalConnect(val);
    };

    const handleHelpModal = (val) => {
        showHelpSupportModalConnect(val);
    };

    const handleWidgetIconClick = () => {
        if (isSellCarPage) {
            handleHelpModal(!showHelpSupportModal);
            handleEmailUsModal(false);
        } else {
            handleHelpModal(false);
            handleEmailUsModal(!showEmailSupportModal);
        }

    };

    const handleSubmitCallback = () => {
        showEmailSupportModalConnect(false);
    };

    if (!showWidget) return null;

    return (
        <React.Fragment>
            {!hideWidgetIcon ? <ChatWidgetRevamp onClick={handleWidgetIconClick} /> : null}
            {showHelpSupportModal && isSellCarPage && <HelpSupportModal handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} />}
            {showEmailSupportModal && <ContactSelectModal emailSupportModal={showEmailSupportModal} isSellCarPage={isSellCarPage} handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal}  onSubmitCallback={handleSubmitCallback} />}

        </React.Fragment>
    );
};
export default SupportWidget;

SupportWidget.propTypes = {
    showWidget: PropTypes.bool,
    isSellCarPage: PropTypes.bool,
    supportWidget: PropTypes.object,
    showHelpSupportModalConnect: PropTypes.func,
    showEmailSupportModalConnect: PropTypes.func
};
