export const TRADE_STATE = [
    {
        stateCode: "AU_NSW ",
        stateName: "New South Wales",
        label: "New South Wales",
        value: "New South Wales"
    },
    {
        stateCode: "AU_QLD",
        stateName: "Queensland",
        label: "Queensland",
        value: "Queensland"
    },
    {
        stateCode: "AU_CAPTR",
        stateName: "Australian Capital Territory",
        label: "Australian Capital Territory",
        value: "Australian Capital Territory"
    },
    {
        stateCode: "AU_NTR",
        stateName: "Northern Territory",
        label: "Northern Territory",
        value: "Northern Territory"
    },
    {
        stateCode: "AU_SAU",
        stateName: "South Australia",
        label: "South Australia",
        value: "South Australia"
    },
    {
        stateCode: "AU_TAS",
        stateName: "Tasmania",
        label: "Tasmania",
        value: "Tasmania"
    },
    {
        stateCode: "AU_VIC",
        stateName: "Victoria",
        label: "Victoria",
        value: "Victoria"
    },
    {
        stateCode: "AU_WEST",
        stateName: "Western Australia",
        label: "Western Australia",
        value: "Western Australia"
    }
];

export const STEP_DATA = {
    SELECT_CAR_INFO: {
        step: "SELECT_CAR_INFO",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "car-details",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    PRECONDITIONS: {
        step: "PRECONDITIONS_CONDITION",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "pre-conditions",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    ESTIMATED_VALUATION: {
        step: "ESTIMATED_VALUATION",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "estimated-valuation",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    INTERIOR_CONDITION: {
        step: "INTERIOR_CONDITION",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "interior-condition",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    MECHANICAL_CONDITION: {
        step: "MECHANICAL_CONDITION",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "mechanical-condition",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    EXTERIOR_CONDITION: {
        step: "EXTERIOR_CONDITION",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "exterior-condition",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    CAR_CONDITION_AND_DETAILS: {
        step: "CAR_CONDITION_AND_DETAILS",
        heading: "INCOMPLETE VALUATION",
        description: "Add your car details to view valuation.",
        ctaText: "FINISH VALUATION",
        lastStep: "car-condition",
        gaKey: "finish_valuation",
        screenLoadGa: "my_accountsell_or_trade_in_screen_incomplete_valuation"
    },
    VALUATION: {
        step: "VALUATION",
        heading: "INCOMPLETE BOOKING",
        description: "Choose an option to trade-in or sell your car to us.",
        ctaText: "Proceed to sell/trade-in",
        lastStep: "valuation",
        gaKey: "proceed_to_sell_trade_in",
        screenLoadGa: "my_account_sell_or_trade_in_screen_incomplete_booking"
    },
    CAR_SELECTION: {
        step: "CAR_SELECTION",
        heading: "CAR SELECTION PENDING",
        description: "Select a car from us to trade-in your old car with.",
        ctaText: "CHOOSE CAR NOW",
        gaKey: "choose_car",
        screenLoadGa: "my_account_sell_or_trade_in_screen_car_selection_pending"
    },
    SELECT_DAY: {
        step: "SELECT_DAY",
        heading: "INSPECTION BOOKING PENDING",
        description: "Add your inspection booking date and time to complete your booking.",
        ctaText: "Book virtual inspection",
        gaKey: "schedule_inspection",
        screenLoadGa: "my_account_sell_or_trade_in_screen_inspection_booking_pending"
    }
};
