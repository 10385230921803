/* eslint-disable quotes */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const DRIVA_DISCLAIMER = [
    <><strong styleName={"styles.bold"}>WARNING: </strong>This comparison rate applies only to the example or examples given. Different amounts and terms will result in different comparison rates. Costs such as redraw fees or early repayment fees, and cost savings such as fee waivers, are not included in the comparison rate but may influence the cost of the loan.</>,
    <>The comparison rate is based on a $30,000 loan over 5 years and 0% residual</>,
    <>Australian Credit Licence No. 538964</>
];

const IN_HOUSE_DISCLAIMER = [
    <><strong styleName={"styles.bold"}>WARNING: </strong>This comparison rate applies only to the example or examples given. Different amounts and terms will result in different comparison rates. Costs such as redraw fees or early repayment fees, and cost savings such as fee waivers, are not included in the comparison rate but may influence the cost of the loan.</>,
    <>The comparison rate is based on a $30,000 loan over 5 years and 0% residual.</>,
    <>Australian Credit Licence No. 538964</>
];

const FinanceDisclaimerTooltipBody = ({
    content = [],
    showInHouse
}) => {
    content = showInHouse ? IN_HOUSE_DISCLAIMER : DRIVA_DISCLAIMER;
    return (
        <div styleName={"styles.wrapper"}>
            {
                content.map((item, key) =>
                    <p styleName={"styles.para"} key={key}>{item}</p>
                )
            }
        </div>
    );
};

FinanceDisclaimerTooltipBody.propTypes = {
    content: PropTypes.array,
    showInHouse: PropTypes.bool
};

export default FinanceDisclaimerTooltipBody;

// WARNING:
// The comparison rate is based on a $30,000 loan over 5 years and 0% residual.

// Australian Credit Licence No. 531492
