import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PillNavigation from "./component";

const mapStateToProps = ({ carDetails: { content }, checkout: { checkoutEditFormData, financeData, checkoutEditMode, checkoutEditCompletedStages, maxStep } }) => ({ checkoutEditFormData, financeData, checkoutEditMode, checkoutEditCompletedStages, content, maxStep });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PillNavigation);
