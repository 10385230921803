import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BookingsFooter from "./component";

const mapStateToProps = ({
    myBookings: {
        paymentSummaryData: {
            reconcilePending
        }
    }
}) => ({
    reconcilePending
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookingsFooter);
