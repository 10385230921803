/* eslint-disable no-magic-numbers */
import oridinalSuffix from "./oridinal-suffix";
import { NUMBER } from "../../constants/app-constants";

const tzsregex = /\b(ACDT|ACST|ACT|ADT|AEDT|AEST|AFT|AKDT|AKST|AMST|AMT|ART|AST|AWDT|AWST|AZOST|AZT|BDT|BIOT|BIT|BOT|BRT|BST|BTT|CAT|CCT|CDT|CEDT|CEST|CET|CHADT|CHAST|CIST|CKT|CLST|CLT|COST|COT|CST|CT|CVT|CXT|CHST|DFT|EAST|EAT|ECT|EDT|EEDT|EEST|EET|EST|FJT|FKST|FKT|GALT|GET|GFT|GILT|GIT|GMT|GST|GYT|HADT|HAEC|HAST|HKT|HMT|HST|ICT|IDT|IRKT|IRST|IST|JST|KRAT|KST|LHST|LINT|MART|MAGT|MDT|MET|MEST|MIT|MSD|MSK|MST|MUT|MYT|NDT|NFT|NPT|NST|NT|NZDT|NZST|OMST|PDT|PETT|PHOT|PKT|PST|RET|SAMT|SAST|SBT|SCT|SGT|SLT|SST|TAHT|THA|UYST|UYT|VET|VLAT|WAT|WEDT|WEST|WET|WST|YAKT|YEKT)\b/gi;

// In other browsers the timezone needs to be estimated based on the offset:
const timezonenames = {"UTC+0": "GMT", "UTC+1": "CET", "UTC+2": "EET", "UTC+3": "EEDT", "UTC+3.5": "IRST", "UTC+4": "MSD", "UTC+4.5": "AFT", "UTC+5": "PKT", "UTC+5.5": "IST", "UTC+6": "BST", "UTC+6.5": "MST", "UTC+7": "THA", "UTC+8": "AWST", "UTC+9": "AWDT", "UTC+9.5": "ACST", "UTC+10": "AEST", "UTC+10.5": "ACDT", "UTC+11": "AEDT", "UTC+11.5": "NFT", "UTC+12": "NZST", "UTC-1": "AZOST", "UTC-2": "GST", "UTC-3": "BRT", "UTC-3.5": "NST", "UTC-4": "CLT", "UTC-4.5": "VET", "UTC-5": "EST", "UTC-6": "CST", "UTC-7": "MST", "UTC-8": "PST", "UTC-9": "AKST", "UTC-9.5": "MIT", "UTC-10": "HST", "UTC-11": "SST", "UTC-12": "BIT"};

const DAY_LIST = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

const ABBR_DAY_LIST = [
    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

export const getWeekDay = (date = new Date(), abbr = false) => {
    const dayList = abbr ? ABBR_DAY_LIST : DAY_LIST;
    return dayList[date.getDay()];
};

export const getParticularDay = (date = new Date()) => {
    return date.getDate();
};

const MONTH_LIST = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const ABBR_MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export const getTimeZone = (d = new Date()) => {
    const usertime = d.toLocaleString();
    let timezone = usertime.match(tzsregex);
    if (timezone) {
        timezone = timezone[timezone.length - 1];
    } else {
        let offset = -1 * d.getTimezoneOffset() / 60;
        offset = `UTC${  offset >= 0 ? `+${  offset}` : offset}`;
        timezone = timezonenames[offset];
    }

    return timezone;
};

export const getMonth = (date = new Date(), abbr = false) => {
    const monthList = abbr ? ABBR_MONTH_LIST : MONTH_LIST;
    return monthList[date.getMonth()];
};

export const getDayWithMonth = (date = new Date(), ordinal = true) => {
    return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date)}`;
};

export const getTwelveHourFormat = (date = new Date()) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    // Check whether AM or PM
    const format = hours >= NUMBER.TWELVE ? "PM" : "AM";

    // Find current hour in AM-PM Format
    hours %= NUMBER.TWELVE;

    // To display "0" as "12"
    hours = hours ? hours : NUMBER.TWELVE;
    minutes = minutes < NUMBER.TEN ? `0${  minutes}` : minutes;
    return {
        string: `${hours}:${minutes} ${format}`,
        hours,
        minutes,
        format
    };
};

export const getFullHourFormat = (date = new Date()) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const timezone = getTimeZone();
    // To display "0" as "00"
    hours = hours < NUMBER.TEN ? `0${hours}` : hours;
    minutes = minutes < NUMBER.TEN ? `0${minutes}` : minutes;
    return {
        string: `${hours}:${minutes} ${timezone}`,
        hours,
        minutes,
        timezone
    };
};

export const getDayMonthYear = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date)} ${date.getFullYear()}`;
};

export const getBookingDateAndTime = (date = new Date(), showTime = true) => {
    return ` ${getParticularDay(date)}-${getMonth(date, true)}-${date.getFullYear()}${showTime ? `, ${getTwelveHourFormat(date).string}` : ``}`;
};

export const getBookingConfirmedDate = (date = new Date()) => {
    return `${getWeekDay(date, true)}, ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()} | ${getFullHourFormat(date).string}`;
};

export const getFinanceDisplayDate = (date = new Date()) => {
    /*format: 7th sep, 2022*/
    return `${oridinalSuffix(getParticularDay(date))} ${getMonth(date, true)}, ${date.getFullYear()}`;
};

export const getDeliveryDate = (date = new Date()) => {
    return ` ${getMonth(date, true)} ${getParticularDay(date)}, ${date.getFullYear()}`;
};

export const getFomattedDateOfBirth = (date = new Date()) => {
    const d = new Date(date);
    let month = `${  d.getMonth() + 1}`;
    let day = `${  d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) {month = `0${  month}`;}
    if (day.length < 2) {day = `0${  day}`;}

    return [year, month, day].join("-");
};

export const getDateMonthYear = (date = new Date(), locales = "default", options = {}) => {
    return date.toLocaleString(locales, { month: "long", year: "numeric", ...options });
};
