import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import snarkdown from "snarkdown";

const PackageBenefits = ({ benefits = {} }) => {
    const { title, items = [] } = benefits || {};
    if (!(items || []).length) return null;

    return (
        <div styleName={"styles.outer"}>
            <p styleName={"styles.heading"}>{title}</p>
            <div styleName={"styles.stepsWrapper"}>
                {items.map(({iconUrl, info}) => (
                    <div styleName={"styles.steps"}>
                        <img src={iconUrl} />
                        <p styleName={"styles.stepsDetail"} dangerouslySetInnerHTML={{
                            __html: snarkdown(info || "")
                        }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

PackageBenefits.propTypes = {
    benefits: PropTypes.object
};

export default PackageBenefits;

