const currentEnv = process.env.HOST_ENV;

const cdnBase = () => {
    const urls = {
        LOCAL: "https://thumbor-staging.24c.in/unsafe",
        // QA: "https://thumbor-staging.24c.in/unsafe",
        QA: "https://thumbor-staging.24c.in/unsafe",
        // STAGING: "https://thumbor-staging.24c.in/unsafe", //staging bucket is shut down using prod for now
        STAGING: "https://timg.24c.in/unsafe",
        LNOD: "https://thumbor-staging.24c.in/unsafe",
        PRODUCTION: "https://timg.24c.in/unsafe"
    };
    return urls[currentEnv];
};

const cdnBucket = () => {
    const buckets = {
        LOCAL: "c2b-react-staging",
        QA: "c2b-react-staging",
        // STAGING: "c2b-react-staging", //staging bucket is shut down using prod for now
        STAGING: "c2b-react-production",
        LNOD: "c2b-react-staging",
        PRODUCTION: "c2b-react-production"
    };
    return buckets[currentEnv];
};

const marketingCdn = () => {
    const urls = {
        LOCAL: "https://assets-staging.24c.in/",
        // QA: "https://thumbor-staging.24c.in/unsafe",
        QA: "https://assets-staging.24c.in/",
        // STAGING: "https://thumbor-staging.24c.in/unsafe", //staging bucket is shut down using prod for now
        STAGING: "https://assets-staging.24c.in/",
        LNOD: "https://assets-staging.24c.in/",
        PRODUCTION: "https://assets.24c.in/"
    };
    return urls[currentEnv];
};

module.exports = {
    cdnBase,
    cdnBucket,
    marketingCdn
};
