/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlatinumCoverMonthlyCard from "../platinum-cover-monthly-card/component";
import Button from "../../shared/button";
import styles from "./styles.css";
import VasPackageAddModal from "../vas-package-add-modal/component";
import { VAS_PAGE_SCREEN_TYPE } from "../../../constants/app-constants";
import LocationIcon from "./images/location_on.svg";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import TrackImpression from "../../shared/track-impression";

const getSelectedIndex = (productList, vasCartData) => {
    const { items } = vasCartData || {};
    const keyList = items?.map((item) => item.key);
    return productList?.findIndex((item) => keyList.includes(item.key));
};

const PlatinumCoverExpandedSection = ({
    isExpanded,
    productList,
    selectedProductIndex,
    setSelectedProductIndex,
    type,
    handleAddBundle,
    addVASItemConnect,
    removeVASItemConnect,
    setCounterAnimationConnect,
    orderId,
    year,
    egc,
    appointmentId,
    vasCartData,
    selfPickUp,
    expandedSection
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [packageAddedModalInfo, setPackageAddedModalInfo] = useState({});
    const [packageAddedModal, setPackageAddedModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const servicingLocation = selfPickUp?.[0]?.pickUpLocation?.locationAddress;

    useEffect(() => {
        const selectedIndex = getSelectedIndex(productList, vasCartData);
        if (selectedIndex === -1) {
            setSelectedProductIndex(null);
        } else {
            setSelectedProductIndex(selectedIndex);
        }
    }, [productList, vasCartData, setSelectedProductIndex]);

    const onRemoveClick = async (e, index) => {
        e.stopPropagation();
        const { key: productKey, type: productType } = productList[index] || {};
        setSelectedProductIndex(null);
        setIsLoading(true);
        setCounterAnimationConnect(false);
        await removeVASItemConnect({ appointmentId, key: productKey, type: productType, orderId, year, price: egc, vasPageName: VAS_PAGE_SCREEN_TYPE.CAR_COVER });
        setIsLoading(false);
        setCounterAnimationConnect(true);
        const gaEventLabel = PLATINUM_COVER_GA_EVENTS.PRODUCT_PAGE_REMOVE.eventLabel.replace("$product", productKey).replace("$duration", "months");
        trackDesktopCustomEventsAU("", { ...PLATINUM_COVER_GA_EVENTS.PRODUCT_PAGE_REMOVE, eventLabel: gaEventLabel });
    };

    const onAddClick = async (index) => {
        if (index === selectedProductIndex) {
            return;
        }
        const { key: productKey, type: productType } = productList[index] || {};
        try {
            setIsLoading(true);
            setSelectedProductIndex(index);
            const addedVasData = await addVASItemConnect({ appointmentId, key: productKey, type: productType, orderId, year, price: egc, vasPageName: VAS_PAGE_SCREEN_TYPE.CAR_COVER });
            const { errorDetails } = addedVasData;
            setIsLoading(false);
            setCounterAnimationConnect(true);
            if (errorDetails) {
                const { variant } = errorDetails || {};
                if (variant === "POPUP") {
                    setPackageAddedModal(true);
                    setPackageAddedModalInfo(errorDetails);
                    trackDesktopCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.PRODUCT_PAGE_DUPLICATE_POP_UP);
                }
            }
        } catch (err) {
            setIsLoading(false);
        }
        const gaEventLabel = PLATINUM_COVER_GA_EVENTS.PRODUCT_PAGE_ADDED.eventLabel.replace("$product", productKey).replace("$duration", "months");
        trackDesktopCustomEventsAU("", { ...PLATINUM_COVER_GA_EVENTS.PRODUCT_PAGE_ADDED, eventLabel: gaEventLabel });
    };

    const onCloseVasPackageModal = () => {
        setPackageAddedModal(false);
        expandedSection("bundle");
    };

    return (
        <div styleName={`${!isExpanded ? "styles.collapsed" : "styles.expanded"} ${isLoading ? "styles.loadingCartState" : ""}`}>
            {packageAddedModal && <VasPackageAddModal info={packageAddedModalInfo} open={packageAddedModal} onClose={onCloseVasPackageModal} />}

            <div styleName={"styles.tabWrapper"}>
                <div styleName={`styles.tab ${activeTab === 0 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(0)}>
                    <p styleName={"styles.tabName"}>Add to loan</p>
                </div>
                <div styleName={`styles.tab ${activeTab === 1 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(1)}>
                    <p styleName={"styles.tabName"}>Pay outright</p>
                </div>
            </div>
            {activeTab === 0 && <p styleName={"styles.repaymentText"}>Price of plan will be added to your loan repayments.</p>}
            <div styleName={"styles.carContainer"}>
                {productList?.map((item, index) => (
                    <PlatinumCoverMonthlyCard
                        type={activeTab === 0 ? "loan" : "payout"}
                        coverDetails={item}
                        isActive={selectedProductIndex === index}
                        setSelectedProductIndex={onAddClick}
                        index={index}
                        clearSelection={onRemoveClick}
                    />
                ))}
                {type === "bundle" &&
            <TrackImpression event={{ data: PLATINUM_COVER_GA_EVENTS.ADD_PROCEED_BUTTON_SEEN }}>
                <Button disabled={selectedProductIndex === null} text="Add & proceed" onClick={handleAddBundle} />
            </TrackImpression>}
                {type === "servicing" && <div styleName="styles.servicingLocation"><img src={LocationIcon} alt="Location" />Center - {servicingLocation} </div>}
            </div>
        </div>
    );
};

PlatinumCoverExpandedSection.propTypes = {
    isExpanded: PropTypes.bool,
    productList: PropTypes.array,
    selectedProductIndex: PropTypes.number,
    setSelectedProductIndex: PropTypes.func,
    type: PropTypes.string,
    handleAddBundle: PropTypes.func,
    clearSelection: PropTypes.func,
    addVASItemConnect: PropTypes.func,
    removeVASItemConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    orderId: PropTypes.string,
    year: PropTypes.string,
    egc: PropTypes.string,
    appointmentId: PropTypes.string,
    vasCartData: PropTypes.object,
    selfPickUp: PropTypes.array,
    expandedSection: PropTypes.func
};

export default PlatinumCoverExpandedSection;
