import PlatinumCoverExpandedSection from "./component";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addVASItem, clearVASCart, getVASItems, removeVASItem, setAddonScreenType, setCounterAnimation } from "../add-ons/actions";

const mapStateToProps = ({
    carDetails: {
        content,
        content: {
            appointmentId,
            egc,
            year
        }
    },
    checkout: {
        order: {
            orderId
        },
        quotesAvailable: preApproved
    },
    addOns: {
        vasItems,
        activeStore,
        vasCart: {
            data: vasCartData
        },
        addVASItem: {
            loading: addLoading,
            key: addKey
        },
        removeVASItem: {
            loading: removeLoading,
            key: removeKey
        }
    },
    deliveryInfo: {
        deliveryData: { selfPickUp }
    }
}) => ({
    activeStore,
    addKey,
    addLoading,
    appointmentId,
    content,
    egc,
    removeKey,
    removeLoading,
    orderId,
    preApproved,
    vasItems,
    vasCartData,
    year,
    selfPickUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addVASItemConnect: addVASItem,
    clearVASCartConnect: clearVASCart,
    getVASItemsConnect: getVASItems,
    removeVASItemConnect: removeVASItem,
    setAddonScreenTypeConnect: setAddonScreenType,
    setCounterAnimationConnect: setCounterAnimation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlatinumCoverExpandedSection);
