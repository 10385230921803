import React from "react";
import styles from "./styles.css";
import DocusignLogo from "./images/docusign.svg";

const DocusignLoaderInfo = () => {
    return (
        <div styleName={"styles.loaderWrapper"}>
            <img styleName={"styles.logoImage"} src={DocusignLogo} alt="DocuSign" />
            <div styleName={"styles.loaderTrack"}>
                <div styleName={"styles.loaderRail"}>
                Movable Loader
                </div>
            </div>
            <p styleName={"styles.loaderText"}>Please wait while we redirect you to the Docusign platform to finalise your documents (this could take up to 2 minutes).</p>
        </div>
    );
};

export default DocusignLoaderInfo;
