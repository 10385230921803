import { differenceInMonths } from "date-fns";
import { NUMBER } from "../../constants/app-constants";
import { monthNamesValues } from "../../constants/finance-constants/month-names";

//const CURRENT_YEAR = new Date().getFullYear();

const resetPreviousYear = (payload) => {
    const { user } = payload || {};
    const { previousAddress, currentAddressDurationBelow3Years = false } = user || {};
    if (!currentAddressDurationBelow3Years && (previousAddress || {}).full) {
        payload = {
            ...payload,
            user: {
                ...payload.user,
                previousAddress: null
            }
        };
    }
    return payload;
};

const resetPreviousEmployment = (payload) => {
    const { user } = payload || {};
    const { employment = [] } = user || {};
    if ((employment || []).length > 0 && !!employment[0]) {
        const monthDiff = differenceInMonths(new Date(), new Date(employment[0].startYear, monthNamesValues.indexOf(employment[0].startMonth)), NUMBER.ONE);

        // eslint-disable-next-line no-unused-vars
        const { durationMonths, durationYears, ...updatedEmployment1 } = employment[0] || {};
        // eslint-disable-next-line no-unused-vars
        const { startMonth, startYear, ...updatedEmployment2 } = employment[1] || {};
        const updatedEmployment = monthDiff > NUMBER.SIX ? [updatedEmployment1] : [updatedEmployment1, updatedEmployment2];
        const updatedUser = {
            ...payload.user,
            employment: updatedEmployment
        };

        return {
            ...payload,
            user: updatedUser
        };
    }

    return payload;
};

const resetUnapplicableFinanceParameters = (payload) => {
    payload = resetPreviousYear(payload);
    payload = resetPreviousEmployment(payload);
    return payload;
};

export {
    resetUnapplicableFinanceParameters
};
