import React from "react";
import styles from "./styles.css";
import RIGHT from "./images/right.svg";
import PropTypes from "prop-types";

const PlatinumCover = ({content = []}) => {
    return (
        <div styleName={"styles.cover"}>
            <h2>The CARS24 Platinum Cover™ difference</h2>
            {(content || []).map(item => {
                return  (<div styleName={"styles.text"} key={item}><img src={RIGHT} /><p>{item}</p></div>);
            })}
        </div>
    );
};

PlatinumCover.propTypes = {
    content: PropTypes.array
};

export default PlatinumCover;
