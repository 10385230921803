/* eslint-disable max-statements */

import { differenceInMonths } from "date-fns";
import { NUMBER } from "../../constants/app-constants";
import { AUSTRALIAN_CITIZEN, BOOL_STATE, BUSINESS_TYPE_VALUE, NEW_ZEALAND_CITIZEN, PENSION, PERMANENT_RESIDENT, RELATIONSHIP_STATUS, TEMPORARY_VISA, UNEMPLOYED } from "../../constants/checkout-constants";
import { monthNamesValues } from "../../constants/finance-constants/month-names";

//const CURRENT_YEAR = new Date().getFullYear();

/* eslint-disable complexity */
export const getFormCompletedStates = (formData = {}) => {
    const { loan, loanType, user } = formData || {};
    const { term, deposit } = loan || {};
    const { firstName, lastName, dependants,  dateOfBirth, relationshipStatus, residency, currentAddressDurationBelow3Years, address, livingSituation, visaNumber, employment, previousAddress, userIncomeDetails  } = user || {};
    const { full: currentAddressFull } = address || {};
    const { full: previousAddressFull  } = previousAddress || {};
    const { type: employmentType, startMonth: employmentDurationMonths, startYear: employmentDurationYears} = (employment && employment[0]) || {};
    const { durationMonths: previousEmploymentDurationMonths, durationYears: previousEmploymentDurationYears} = (employment && employment[1]) || {};
    const {typeOfBusiness, isPurposeBusiness, businessStartYear, businessStartMonth, abnStartYear, abnStartMonth, isGstRegistered, gstStartYear, gstStartMonth  } = loanType || {};
    const { mortgageOrRentPayment, mortgageOrRentPaymentDuration, grossIncome, grossIncomeDuration, partnerGrossIncome, partnerGrossIncomeDuration, noMortage } = userIncomeDetails || {};

    const isStage1Form1Question1Completed = () => {
        return [BOOL_STATE.YES, BOOL_STATE.NO].includes(isPurposeBusiness);
    };

    const isStage1Form1Question2Completed = () => [BUSINESS_TYPE_VALUE.SOLE_TRADER, BUSINESS_TYPE_VALUE.PARTNERSHIP, BUSINESS_TYPE_VALUE.COMPANY, BUSINESS_TYPE_VALUE.TRUST].includes(typeOfBusiness);

    const isStage1Form1Question3Completed = () => +businessStartYear > 0 && monthNamesValues.includes(businessStartMonth);

    const isStage1Form1Question4Completed = () => {
        return +abnStartYear > 0 && monthNamesValues.includes(abnStartMonth);
    };

    const isStage1Form1Question5Completed = () => {
        return [BOOL_STATE.NO, BOOL_STATE.YES].includes(isGstRegistered);
    };

    const isStage1Form1Question6Completed = () => {
        return gstStartYear > 0 && monthNamesValues.includes(gstStartMonth);
    };

    const isStage1Form1Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            const gstAbnDiff = differenceInMonths(new Date(+abnStartYear, monthNamesValues.indexOf(abnStartMonth), NUMBER.ONE), new Date(+gstStartYear, monthNamesValues.indexOf(gstStartMonth), NUMBER.ONE));

            let gstAbnDurationInValid = false;

            if (gstAbnDiff > 0) {
                gstAbnDurationInValid = true;
            } else {
                gstAbnDurationInValid = false;
            }

            const durationPresent = isStage1Form1Question3Completed() && isStage1Form1Question4Completed();
            const isGSTPresent = isGstRegistered === BOOL_STATE.YES ?
                (isStage1Form1Question6Completed() && !gstAbnDurationInValid)
                : isGstRegistered === BOOL_STATE.NO;
            return durationPresent && isGSTPresent;
        } else {
            return isPurposeBusiness === BOOL_STATE.NO;
        }
    };

    const isStage1Form2Question1Completed = () => typeof deposit === "number";

    const isStage1Form2Question2Completed = () => typeof term === "number" && term > 0;

    const isStage1Form2Completed = () => {
        return isStage1Form2Question1Completed() && isStage1Form2Question2Completed();
    };

    const isStage1Completed = () => isStage1Form1Completed() && isStage1Form2Completed();

    const isStage2Form1Question1Completed = () => !!employmentType;

    const isStage2Form1Question2Completed = () => {
        const monthDiff = differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE);
        const currentMonth = new Date().getMonth();

        if (monthDiff < 0 || monthDiff === 0 && currentMonth !== monthNamesValues.indexOf(employmentDurationMonths)) {
            return false;
        }

        return +employmentDurationYears > 0 && !!employmentDurationMonths;
    };

    const isStage2Form1Question3Completed = () => {
        return typeof previousEmploymentDurationYears === "number" && typeof previousEmploymentDurationMonths === "number";
    };

    const isStage2Form1Completed = () => {
        const lessThanSix = differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE) < NUMBER.SIX;

        if ([PENSION, UNEMPLOYED].includes(employmentType)) {
            return true;
        } else if (lessThanSix) {
            return isStage2Form1Question1Completed() && isStage2Form1Question2Completed() && isStage2Form1Question3Completed();
        }
        return isStage2Form1Question1Completed() && isStage2Form1Question2Completed();
    };

    const isStage2Form2Question1Completed = () => !!grossIncome && !!grossIncomeDuration;

    const isStage2Form2Question2Completed = () =>  (!!mortgageOrRentPayment && !!mortgageOrRentPaymentDuration) || noMortage;

    const isStage2Form2Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            return isStage2Form1Completed();
        }
        return isStage2Form2Question1Completed() && isStage2Form2Question2Completed();
    };

    const isStage2Completed = () => {
        return isStage2Form1Completed() && isStage2Form2Completed();
    };

    const isStage3Form1Question1Completed = () => !!firstName && !!lastName;

    const isStage3Form1Question2Completed = () => !!dateOfBirth;

    const isStage3Form1Completed = () => isStage3Form1Question1Completed() && isStage3Form1Question2Completed();

    const isStage3Form2Question1Completed = () => !!livingSituation;

    const isStage3Form2Question2Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            return isStage3Form2Question1Completed();
        }
        return typeof dependants === "number";
    };

    const isStage3Form2Completed = () => isStage3Form2Question1Completed() && isStage3Form2Question2Completed();

    const isStage3Form3Question1Completed = () => !!relationshipStatus;

    const isStage3Form3Question2Completed = () => {
        const validation1 = [RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(relationshipStatus);

        if (validation1) {
            return (!!partnerGrossIncome && !!partnerGrossIncomeDuration);
        }

        return isStage3Form3Question1Completed();
    };

    const isStage3Form3Completed = () => isStage3Form3Question1Completed() && isStage3Form3Question2Completed();

    const isStage3Form4Question1Completed = () => !!residency;

    const isStage3Form4Question2Completed = () => !!visaNumber;

    const isStage3Form4Question3Completed = () => !!currentAddressFull;

    const isStage3Form4Question4Completed = () => !!previousAddressFull;

    const isStage3Form4Completed = () => {
        if (residency === TEMPORARY_VISA && visaNumber) {
            if (currentAddressDurationBelow3Years) {
                return isStage3Form4Question1Completed() && isStage3Form4Question2Completed() && isStage3Form4Question3Completed() && isStage3Form4Question4Completed();
            }
            return isStage3Form4Question1Completed() && isStage3Form4Question2Completed() && isStage3Form4Question3Completed();
        } else if ([AUSTRALIAN_CITIZEN, PERMANENT_RESIDENT, NEW_ZEALAND_CITIZEN].includes(residency) && currentAddressDurationBelow3Years) {
            return isStage3Form4Question1Completed() && isStage3Form4Question4Completed();
        }

        return isStage3Form4Question1Completed() && isStage3Form4Question3Completed();
    };

    const isStage3Completed = () => {
        return isStage3Form1Completed() && isStage3Form2Completed() && isStage3Form3Completed() && isStage3Form4Completed();
    };

    return {
        // stage 1

        // stage 1 -> form 1
        isStage1Form1Question1Completed,
        isStage1Form1Question2Completed,
        isStage1Form1Question3Completed,
        isStage1Form1Question4Completed,
        isStage1Form1Question5Completed,
        isStage1Form1Question6Completed,
        isStage1Form1Completed,
        isStage1Completed,

        // stage 1 -> form 2
        isStage1Form2Question1Completed,
        isStage1Form2Question2Completed,
        isStage1Form2Completed,

        // stage 2

        // stage 2 -> form 1
        isStage2Form1Question1Completed,
        isStage2Form1Question2Completed,
        isStage2Form1Question3Completed,
        isStage2Form1Completed,

        // stage 2 -> form 2
        isStage2Form2Question1Completed,
        isStage2Form2Question2Completed,
        isStage2Form2Completed,
        isStage2Completed,

        // stage 3 - form 1
        isStage3Form1Question1Completed,
        isStage3Form1Question2Completed,
        isStage3Form1Completed,

        // stage 3 - form 2
        isStage3Form2Question1Completed,
        isStage3Form2Question2Completed,
        isStage3Form2Completed,

        // stage 3 - form 3
        isStage3Form3Question1Completed,
        isStage3Form3Question2Completed,
        isStage3Form3Completed,

        // stage 3 - form 4
        isStage3Form4Question1Completed,
        isStage3Form4Question2Completed,
        isStage3Form4Question3Completed,
        isStage3Form4Question4Completed,
        isStage3Form4Completed,

        isStage3Completed
    };
};

/* eslint-disable complexity */
export const getMsiteFormCompletedStates = (formData = {}) => {
    const { loan, loanType, user, optedForTradeIn } = formData || {};
    const { term, deposit } = loan || {};
    const { firstName, lastName, dependants,  dateOfBirth, relationshipStatus, residency, currentAddressDurationBelow3Years, address, livingSituation, visaNumber, employment, previousAddress, userIncomeDetails  } = user || {};
    const { full: currentAddressFull } = address || {};
    const { full: previousAddressFull  } = previousAddress || {};
    const { type: employmentType, startMonth: employmentDurationMonths, startYear: employmentDurationYears} = (employment && employment[0]) || {};
    const { durationMonths: previousEmploymentDurationMonths, durationYears: previousEmploymentDurationYears} = (employment && employment[1]) || {};
    const {typeOfBusiness, isPurposeBusiness, businessStartYear, businessStartMonth, abnStartYear, abnStartMonth, isGstRegistered, gstStartYear, gstStartMonth  } = loanType || {};
    const { mortgageOrRentPayment, mortgageOrRentPaymentDuration, grossIncome, grossIncomeDuration, partnerGrossIncome, partnerGrossIncomeDuration,  noMortage } = userIncomeDetails || {};

    const isStage1Form1Question1Completed = () => {
        return [BOOL_STATE.YES, BOOL_STATE.NO].includes(isPurposeBusiness);
    };

    const isStage1Form1Question2Completed = () => [BUSINESS_TYPE_VALUE.SOLE_TRADER, BUSINESS_TYPE_VALUE.PARTNERSHIP, BUSINESS_TYPE_VALUE.COMPANY, BUSINESS_TYPE_VALUE.TRUST].includes(typeOfBusiness);

    const isStage1Form1Question3Completed = () => +businessStartYear > 0 && monthNamesValues.includes(businessStartMonth);

    const isStage1Form1Question4Completed = () => {
        return +abnStartYear > 0 && monthNamesValues.includes(abnStartMonth);
    };

    const isStage1Form1Question5Completed = () => {
        return [BOOL_STATE.NO, BOOL_STATE.YES].includes(isGstRegistered);
    };

    const isStage1Form1Question6Completed = () => {
        return gstStartYear > 0 && monthNamesValues.includes(gstStartMonth);
    };
    const isStage1Form2Question3Completed = () => {
        return [BOOL_STATE.YES, BOOL_STATE.NO].includes(optedForTradeIn);
    };
    const isStage1Form1Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            const gstAbnDiff = differenceInMonths(new Date(+abnStartYear, monthNamesValues.indexOf(abnStartMonth), NUMBER.ONE), new Date(+gstStartYear, monthNamesValues.indexOf(gstStartMonth), NUMBER.ONE));
            let gstAbnDurationInValid = false;

            if (gstAbnDiff > 0) {
                gstAbnDurationInValid = true;
            } else {
                gstAbnDurationInValid = false;
            }

            const durationPresent = isStage1Form1Question3Completed() && isStage1Form1Question4Completed();
            const isGSTPresent = isGstRegistered === BOOL_STATE.YES ?
                (isStage1Form1Question6Completed() && !gstAbnDurationInValid)
                : isGstRegistered === BOOL_STATE.NO;

            //const isGSTPresent = isGstRegistered === BOOL_STATE.YES ?
            //    (isStage1Form1Question6Completed() &&
            //        (gstStartYear > abnStartYear ? true :
            //            ((abnStartYear === gstStartYear) &&
            //                monthNamesValues.indexOf(gstStartMonth) < monthNamesValues.indexOf(abnStartMonth))))
            //    : isGstRegistered === BOOL_STATE.NO;
            return durationPresent && isGSTPresent;
        } else {
            return isPurposeBusiness === BOOL_STATE.NO;
        }
    };

    const isStage1Form2Question1Completed = () => typeof deposit === "number";

    const isStage1Form2Question2Completed = () => typeof term === "number" && term > 0;

    const isStage1Form2Completed = () => {
        return isStage1Form2Question1Completed() && isStage1Form2Question2Completed();
    };

    const isStage1Completed = () => isStage1Form1Completed() && isStage1Form2Completed();

    const isStage2Form1Question1Completed = () => !!employmentType;

    const isStage2Form1Completed = () => isStage2Form1Question1Completed();

    const isStage2Form2Question1Completed = () => {
        const monthDiff = differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE);
        const currentMonth = new Date().getMonth();

        if (monthDiff < 0 || monthDiff === 0 && currentMonth !== monthNamesValues.indexOf(employmentDurationMonths)) {
            return false;
        }

        return +employmentDurationYears > 0 && !!employmentDurationMonths;
    };

    const isStage2Form2Question2Completed = () => {
        return typeof previousEmploymentDurationYears === "number" && typeof previousEmploymentDurationMonths === "number";
    };

    const isStage2Form2Completed = () => {
        const lessThanSix = differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE) < NUMBER.SIX;

        if ([PENSION, UNEMPLOYED].includes(employmentType)) {
            return true;
        } else if (lessThanSix) {
            return isStage2Form1Question1Completed() && isStage2Form2Question1Completed() && isStage2Form2Question2Completed();
        }
        return isStage2Form1Question1Completed() && isStage2Form2Question1Completed();
    };

    const isStage2Form3Question1Completed = () => !!grossIncome && !!grossIncomeDuration;

    const isStage2Form3Question2Completed = () =>  (!!mortgageOrRentPayment && !!mortgageOrRentPaymentDuration) || noMortage;

    const isStage2Form3Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            return true;
        }
        return isStage2Form3Question1Completed() && isStage2Form3Question2Completed();
    };

    const isStage2Completed = () => {
        return isStage2Form1Completed() && isStage2Form2Completed() && isStage2Form3Completed();
    };

    const isStage3Form1Question1Completed = () => !!firstName && !!lastName;

    const isStage3Form1Question2Completed = () => !!dateOfBirth;

    const isStage3Form1Completed = () => isStage3Form1Question1Completed() && isStage3Form1Question2Completed();

    const isStage3Form2Question1Completed = () => !!livingSituation;

    const isStage3Form2Question2Completed = () => {
        if (isPurposeBusiness === BOOL_STATE.YES) {
            return isStage3Form2Question1Completed();
        }
        return typeof dependants === "number";
    };

    const isStage3Form2Completed = () => isStage3Form2Question1Completed() && isStage3Form2Question2Completed();

    const isStage3Form3Question1Completed = () => !!relationshipStatus;

    const isStage3Form3Question2Completed = () => {
        const validation1 = [RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(relationshipStatus);

        if (validation1) {
            return (!!partnerGrossIncome && !!partnerGrossIncomeDuration);
        }

        return isStage3Form3Question1Completed();
    };

    const isStage3Form3Completed = () => isStage3Form3Question1Completed() && isStage3Form3Question2Completed();

    const isStage3Form4Question1Completed = () => !!residency;
    const isStage3Form4Question2Completed = () => !!visaNumber;

    const isStage3Form4Completed = () => {
        if (residency === TEMPORARY_VISA) {
            return isStage3Form4Question1Completed() && isStage3Form4Question2Completed();
        }
        return isStage3Form4Question1Completed();
    };

    const isStage3Form5Question1Completed = () => !!visaNumber;

    const isStage3Form5Question2Completed = () => !!currentAddressFull;

    const isStage3Form5Question3Completed = () => !!previousAddressFull;

    const isStage3Form5Completed = () => {
        if (residency === TEMPORARY_VISA && visaNumber) {
            if (currentAddressDurationBelow3Years) {
                //isStage3Form5Question1Completed() &&
                return isStage3Form5Question2Completed() && isStage3Form5Question3Completed();
            }
            return isStage3Form5Question2Completed();
        } else if ([AUSTRALIAN_CITIZEN, PERMANENT_RESIDENT, NEW_ZEALAND_CITIZEN].includes(residency) && currentAddressDurationBelow3Years) {
            return isStage3Form5Question2Completed() && isStage3Form5Question3Completed();
        }

        return isStage3Form5Question2Completed();
    };

    const isStage3Completed = () => {
        return isStage3Form1Completed() && isStage3Form2Completed() && isStage3Form3Completed() && isStage3Form4Completed() && isStage3Form5Completed();
    };

    return {
        // stage 1

        // stage 1 -> form 1
        isStage1Form1Question1Completed,
        isStage1Form1Question2Completed,
        isStage1Form1Question3Completed,
        isStage1Form1Question4Completed,
        isStage1Form1Question5Completed,
        isStage1Form1Question6Completed,
        isStage1Form1Completed,
        isStage1Completed,

        // stage 1 -> form 2
        isStage1Form2Question1Completed,
        isStage1Form2Question2Completed,
        isStage1Form2Question3Completed,
        isStage1Form2Completed,

        // stage 2

        // stage 2 -> form 1
        isStage2Form1Question1Completed,
        isStage2Form1Completed,

        // stage 2 -> form 2
        isStage2Form2Question1Completed,
        isStage2Form2Question2Completed,
        isStage2Form2Completed,

        // stage 2 -> form 3
        isStage2Form3Question1Completed,
        isStage2Form3Question2Completed,
        isStage2Form3Completed,

        isStage2Completed,

        // stage 3 - form 1
        isStage3Form1Question1Completed,
        isStage3Form1Question2Completed,
        isStage3Form1Completed,

        // stage 3 - form 2
        isStage3Form2Question1Completed,
        isStage3Form2Question2Completed,
        isStage3Form2Completed,

        // stage 3 - form 3
        isStage3Form3Question1Completed,
        isStage3Form3Question2Completed,
        isStage3Form3Completed,

        // stage 3 - form 4
        isStage3Form4Question1Completed,
        isStage3Form4Question2Completed,
        isStage3Form4Completed,

        // stage 3 - form 5
        isStage3Form5Question1Completed,
        isStage3Form5Question2Completed,
        isStage3Form5Question3Completed,
        isStage3Form5Completed,

        isStage3Completed
    };
};

