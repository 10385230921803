import { getDeviceType } from "../utils/helpers/get-device-type";

export default (api) => {

    const deviceType = getDeviceType();

    const fetchTestDriveSlots = (token, params) => {
        return api.get(`/slot/v1/test-drive`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    return {
        fetchTestDriveSlots
    };
};
