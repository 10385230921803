import React from "react";
import styles from "./styles.css";
import BestService from "./images/best-service.svg";
import BestPrice from "./images/best-price.svg";
import Roadside from "./images/roadside.svg";
import FreeSticker from "./images/free.png";

const Cars24CoverRightPanel = () => {
    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.serviceBox"}>
                <img src={BestService} />
                <p styleName={"styles.topic"}>Best in<br />class service</p>
            </div>
            <div styleName={"styles.serviceBox"}>
                <img src={BestPrice} />
                <p styleName={"styles.topic"}>Best price<br />guaranteed</p>
            </div>
            <div styleName={"styles.serviceBox"}>
                <div styleName={"styles.free"}>
                    <img src={FreeSticker} />
                </div>
                <img src={Roadside} />
                <p styleName={"styles.topic"}>Roadside<br />assistance</p>
            </div>
        </div>
    );
};

export default Cars24CoverRightPanel;
