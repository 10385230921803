export const AU_COMMON_EVENTS = {
    FF_VOLUME_EXPERIMENT: "FF_volume_experiment"
};

export default {
    [AU_COMMON_EVENTS.FF_VOLUME_EXPERIMENT]: {
        event: "custom_event",
        eventCategory: "FF_volume_experiment"
    }
};
