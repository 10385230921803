import React from "react";
import Statsig from "statsig-js";
import getMeaningfulExperimentNames from "../utils/statsig-ab-utils/get-meaningful-experiment-names";
import { useSelector, useDispatch } from "react-redux";
import { setExperiment as setExperimentConnect } from "../components/shared/ab-experiment/actions";

const useExperiments = ({ experimentKeys }) => {
    const { abExperiment: allExperiments } = useSelector(store => store);

    const { statsigConfig: { initialized = false } } = allExperiments || {};
    const dispatch = useDispatch();

    const getExperiments = () => {
        experimentKeys.forEach((experimentName) => {
            const experimentData = Statsig.getExperiment(experimentName);
            const { value } = experimentData || {};
            const { key = "" } = value || {};
            dispatch(setExperimentConnect(getMeaningfulExperimentNames(experimentName), key));
        });
    };

    React.useEffect(() => {
        if (initialized && process.env.STATSIG_SECRET_CSR_KEY) {
            getExperiments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

};

export default useExperiments;
