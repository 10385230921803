import React, {useCallback} from "react";
import { useInView } from "react-intersection-observer";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import LazyImage from "../../shared/lazy-image";
import TrackImpression from "../../shared/track-impression";

import {testDriveCopy} from "./constants";
import styles from "./styles.css";
import { cmsURl } from "../../../constants/url-constants";

const videoUrl = "https://www.youtube.com/embed/bT0e0vO32AM";

const HowItWorks = () => {
    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true});

    useCallback(() => {}, []);

    const handleEvent = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HOW_TO_BUY_FAQ_LINK);
    };

    const handleImpressionView = () => {};
    const worksList = testDriveCopy;
    return (
        <TrackImpression event={{name: "howitworksViewd"}} handleImpressionView={handleImpressionView}>
            <section styleName="styles.sectionWrapper" ref={ref} >
                <div styleName="styles.wrapper" className="container">
                    <h1>How it works</h1>
                    <div styleName="styles.contentWrapper">
                        <div styleName="styles.videofaq">
                            <div styleName="styles.videoWrapper">
                                {inView && <iframe
                                    width="318"
                                    height="180"
                                    src={`${videoUrl}?autoplay=1&mute=1&enablejsapi=1&rel=0 `}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                    color="#FF9536"
                                    modestbranding={1}
                                />}
                            </div>
                            <p><a href={`${cmsURl()}/blog/faq/`} target="_blank" onClick={handleEvent}>Read our FAQs for latest offering details</a></p>
                        </div>
                        <div styleName="styles.listWrapper">
                            <ul>
                                {worksList.map((item, index) =>
                                    (<li key={item.title} styleName="styles.items">
                                        <LazyImage src={item.imageSrc} alt={item.title} styleName="styles.itemImage"/>
                                        <div styleName="styles.itemContent">
                                            <h5>{`${index + 1 }. ${ item.title}`}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </li>)
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </TrackImpression>
    );
};

HowItWorks.propTypes = {};

export default HowItWorks;

