import React from "react";
import styles from "./styles.css";

const TaskCardShimmer = () => {
    return (
        <div styleName={"styles.taskCard"}>
            <span className="shimmer" styleName={"styles.shimmerPayment"}>&nbsp;</span>
            <span className="shimmer" styleName={"styles.shimmerCardCta"}>&nbsp;</span>
            <span className="shimmer" styleName={"styles.shimmerCardCta"}>&nbsp;</span>
            <label className="shimmer" styleName={"styles.shimmerAmount"}>&nbsp;</label>
        </div>
    );
};

export default TaskCardShimmer;
