/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { mobileNumberValidatorAU } from "../../../utils/validators/mobile-number-validator";
import { DEFAULT_PHONE_LENGTH, PHONE_COUNTRY_CODE } from "../../../constants/app-constants";

const InputMobile = ({
    onChange = () => {},
    inputRef,
    value = "",
    placeholder = "Mobile Number",
    type = "tel",
    hidePrefixIfNoValuePresent = false,
    showError = true,
    hidePlaceholder = false,
    onFocusCallback = () => { },
    onBlurCallback = () => { },
    onClick = () => {},
    countryCode = PHONE_COUNTRY_CODE,
    maxLength = DEFAULT_PHONE_LENGTH,
    validator = undefined,
    showErrorBorder = false,
    prefixAlwaysVisible = false,
    ...rest
}) => {
    const { isFormikForm, form, id, isPadding = false, showErrorOnFocus = false, errorMessage = "" } = rest || {};
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState("");
    const [isPrefixVisible, setIsPrefixVisible] = useState(true);

    const mobileValidator =  validator  || mobileNumberValidatorAU; // defaults to India validator

    const onFocus = (e) => {
        const phone = (e.target.value || "");
        if (phone.length === maxLength || phone.length === 0) {
            setIsFocused(true);
            setIsTouched(true);
        }
        setIsPrefixVisible(true);
        onFocusCallback();
    };
    const onBlur = (e) => {
        setIsFocused(false);
        if (hidePrefixIfNoValuePresent && !e.target.value.length) {
            setIsPrefixVisible(false);
        }
        onBlurCallback(e.target.value);
    };

    const setFormikError = (errMsg) => {
        if (isFormikForm) {
            form.setFieldError(id, errMsg);
            form.setFieldTouched(id, true, false);
        }
    };

    const onChangeHandler = (e) => {
        const number = e.target.value;
        if (!mobileValidator(number)) {
            setFormikError("Invalid mobile number");
            setHasError(true);
            setError(errorMessage || "Invalid mobile number");
        } else {
            setFormikError(null);
            setHasError(false);
            setError("");
        }
        if (isFormikForm) {
            form.setFieldValue(id, e.target.value);
        }
        onChange(e);
    };
    showErrorBorder = isFormikForm ? showError : showErrorBorder;
    return (

        <div>
            <div styleName={ isPadding ? "styles.inputShift styles.withPadding" : "styles.inputShift styles.withPrefix"}>
                <input
                    ref={inputRef}
                    type={type}
                    maxLength={maxLength}
                    name="phone"
                    styleName={showErrorBorder ?
                        "styles.errorBorder" : ""}
                    className="form-control"
                    onFocus={(e) => onFocus(e)}
                    onBlur={(e) => onBlur(e)}
                    onClick={e => onClick(e)}
                    value={value}
                    onChange={onChangeHandler}
                    autoComplete={"off"}
                    placeholder={hidePlaceholder ? placeholder : ""}
                    {...rest}
                // required
                // autoComplete={"off"}
                />
                {(value || isFocused || hasError) && isPrefixVisible &&
                    <span id="prefix" styleName={"styles.phoneNumberPrefix"}>
                        +{countryCode}
                    </span>
                }
                {prefixAlwaysVisible &&
                    <span id="prefix" styleName={"styles.phoneNumberPrefix"}>
                        +{countryCode}
                    </span>
                }
                {!hidePlaceholder && <label
                    className={`${(value || isFocused || hasError) ? "customActive" : ""}`}
                    styleName={`${(value || isFocused || hasError) ? "active" : ""}`}>
                    { placeholder }
                </label> }

            </div>
            {
                (!isFormikForm && showError && hasError && ((isTouched &&
                    !isFocused) || ((value || "").length === maxLength) || (isTouched && showErrorOnFocus)))
                &&
                <small styleName={"styles.error"}>{error}</small>
            }
        </div>
    );
};

InputMobile.propTypes = {
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputRef: PropTypes.any,
    onBlurCallback: PropTypes.func,
    showError: PropTypes.bool,
    placeholder: PropTypes.string,
    hidePlaceholder: PropTypes.bool,
    type: PropTypes.string,
    onFocusCallback: PropTypes.func,
    onClick: PropTypes.func,
    hidePrefixIfNoValuePresent: PropTypes.bool,
    countryCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validator: PropTypes.func,
    showErrorBorder: PropTypes.bool,
    prefixAlwaysVisible: PropTypes.bool
};

export default InputMobile;
