export const FINANCE_FIRST_CHECKOUT_AMPLITUDE_EVENTS = {
    FINANCE_FIRST_CHECKOUT_TRADE_FILLED: "Filled_Y_N",
    FINANCE_FIRST_PRE_APPROVAL_FORM_NEXT_CLICK: "FF_pre_approval_form_screen_1"
};

export const FINANCE_FIRST_CHECKOUT_GA_EVENTS = {
    FINANCE_FIRST_CHECKOUT_TRADE_FILLED: "Filled_Y_N",
    FINANCE_FIRST_PRE_APPROVAL_FORM_NEXT_CLICK: "FF_pre_approval_form_screen_1",
    FINANCE_FIRST_CHECKOUT_TRADE_FILLED_DETAILS: {
        event: "custom_event",
        eventCategory: "Filled",
        eventAction: "Filled_Y_N"
    },
    FINANCE_FIRST_PRE_APPROVAL_FORM_NEXT_CLICK_DETAILS: {
        event: "custom_event",
        eventCategory: "FF_pre_approval_form_screen_1",
        eventAction: "FF_pre_approval_form_screen_1",
        eventLabel: "{{Next}}"
    }
};
