import React, {useState} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import OrderSummaryPopup from "../order-summary-popup";
import { getCarImage } from "../../../utils/helpers/split-payment";
import { getDeliveryDate } from "../../../utils/helpers/get-day-from-date";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import PaymentHistoryCards from "../payment-history-cards";
import { DELIVERY_TYPES } from "../../../constants/checkout-constants";

const CarDeliveryDateCard = ({content = {}, config = {}, pickupTime, deliveryMode, deliveryDateRange = {}, updatedDeliveryDate}) => {
    const { to = "", from = "" } = deliveryDateRange || {};
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);

    const {variant, transmissionType } = content;
    const {imageUrl, carFullName} = getCarImage({content, config});

    const handleSummaryPop = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.MY_BOOKING_TASKS, {eventLabel: "view_order_summary"});
        setShowOrderSummary(!showOrderSummary);
    };

    const handlePaymentHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPaymentHistory(!showPaymentHistory);
        setShowOrderSummary(!showOrderSummary);
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SPLIT_ORDER_SUMMARY_POST_PAYMENT, {eventLabel: "view_payment_history"});
    };

    const closeAllModal = () => {
        setShowPaymentHistory(false);
        setShowOrderSummary(false);
    };

    return (
        <div styleName={"styles.outer"}>
            <div className="media">
                <img src={imageUrl} alt="Car Delivery Date" />
                <div className="media-body">
                    <h3 styleName={"styles.heading"}>{carFullName}</h3>
                    <p styleName={"styles.modelName"}> {variant} <span>{transmissionType}</span></p>
                    <a href="#" styleName={"styles.viewButton"} onClick={handleSummaryPop}>VIEW ORDER SUMMARY</a>
                </div>
            </div>
            <div styleName={"styles.deliveryWrap"}>
                <p styleName={"styles.deliveryDate"}>{deliveryMode === DELIVERY_TYPES.PickUp ? "Pick Up Date" : "Delivery Date"}</p>
                <div styleName={"styles.date styles.dateGroup"}>
                    {updatedDeliveryDate && <span>{getDeliveryDate(new Date(updatedDeliveryDate))} </span>}
                    <span styleName={updatedDeliveryDate ? "styles.linethrough" : ""}>
                        {
                            to && from ?
                                `${from} - ${to}` :
                                (from || (pickupTime && getDeliveryDate(new Date(pickupTime))))}
                    </span>
                </div>
                {/* <span className="shimmer" styleName={"styles.shimmerDate"}>&nbsp;</span> */}
            </div>
            <Modal isOpen={showOrderSummary}>
                <OrderSummaryPopup setShowOrderSummary={setShowOrderSummary} handlePaymentHistory={handlePaymentHistory}/>
            </Modal>
            {showPaymentHistory &&  <PaymentHistoryCards onClose={closeAllModal} onBack={handlePaymentHistory}/>}

        </div>
    );
};

CarDeliveryDateCard.propTypes = {
    content: PropTypes.object,
    config: PropTypes.object,
    deliveryDateRange: PropTypes.object,
    pickupTime: PropTypes.string,
    deliveryMode: PropTypes.string,
    updatedDeliveryDate: PropTypes.string
};
export default CarDeliveryDateCard;
