import { STATES_AU } from "../au-c2b/constants/app-constants";

export const TEST_DRIVE_BOOKING_STATUS_TYPE = {
    BOOKED: "BOOKED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    RESCHEDULED: "RESCHEDULED",
    CANCELLING: "CANCELLING"
};

export const TEST_DRIVE_BOOKINGS_CTA = {
    BUY_NOW: "BUY_NOW",
    VIEW_BOOKING: "VIEW_BOOKING",
    VIEW_SIMILAR_CARS: "VIEW_SIMILAR_CARS",
    NOTIFY_ME: "NOTIFY_ME",
    BOOK_TEST_DRIVE: "BOOK_TEST_DRIVE",
    REMOVE_FROM_WISHLIST: "REMOVE_FROM_WISHLIST"
};

export const TEST_DRIVE_ENABLED_STATES = [STATES_AU.VICTORIA, STATES_AU.QUEENSLAND, STATES_AU["NEW SOUTH WALES"]];

export const TEST_DRIVE_HUB_ADDRESS = {
    [STATES_AU.VICTORIA]: "118 Cherry Lane, Laverton North, VIC 3026",
    [STATES_AU.QUEENSLAND]: "CARS24 Hub QLD, 40 Steel Place, Morningside QLD 4170",
    [STATES_AU.QUEENSLAND]: "CARS24 Hub NSW, 155 Parramatta road, Granville NSW 2142"
};
