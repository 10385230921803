import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import ChatIcon from "./images/chat.webp";
import { isSellSupportAvailable } from "../../../utils/helpers/support";

const ChatWidgetRevamp = ({
    onClick = () => {}
}) => {

    return (
        <div styleName={"styles.chatOuter"} onClick={onClick}>
            <div styleName={"styles.chatContainer"}>
                <img src={ChatIcon} />
            </div>
            <div styleName={isSellSupportAvailable ? "styles.online" : "styles.offline"} />
        </div>
    );
};

export default ChatWidgetRevamp;

ChatWidgetRevamp.propTypes = {
    onClick: PropTypes.func,
    supportWidget: PropTypes.obj
};
