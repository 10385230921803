import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddOnsWrapper from "./component";

const mapStateToProps = ({
    addOns: {
        servicingPriceConfig
    }
}) => ({
    servicingPriceConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsWrapper);
