import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import snarkdown from "snarkdown";
import Accordion from "../../../shared/accordion";
import ArrowIcon from "../../../shared/arrow";
import { ANGLE } from "../../../../constants/app-constants";

const VasProductDetailFAQ = ({ faqData = [] }) => {
    if (!(faqData || []).length) return null;

    return (
        <div styleName={"styles.faqOuter"}>
            <p styleName={"styles.faqHeading"}>FAQs about repayments</p>
            {faqData.map(({ question, answer }, index) => {
                return (
                    <div styleName={"styles.faqBox"} key={question}>
                        <Accordion key={index} defaultActiveIndex={0} toggleAll={true} totalItems={faqData.length} collapseAccordion={true}>
                            {({ onClick, currentVisibleStates }) => {
                                return (
                                    <Accordion.Content>
                                        <Accordion.Heading>
                                            <div styleName={"styles.questionWrap"} onClick={() => onClick({ index })}>
                                                {question && <p styleName={"styles.question"} dangerouslySetInnerHTML={{ __html: snarkdown(question) }} />}
                                                <ArrowIcon rotateBy={currentVisibleStates[index] ? ANGLE.NINETY : -ANGLE.NINETY} />
                                            </div>
                                        </Accordion.Heading>
                                        <Accordion.Body>
                                            {!currentVisibleStates[index] &&
                                                <p styleName={"styles.answers"} dangerouslySetInnerHTML={{__html: snarkdown(answer || "")}} />
                                            }
                                        </Accordion.Body>
                                    </Accordion.Content>
                                );
                            }}
                        </Accordion>
                    </div>

                );
            }
            )}
        </div>

    );
};

VasProductDetailFAQ.propTypes = {
    faqData: PropTypes.object
};

export default VasProductDetailFAQ;
