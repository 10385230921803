// import { testDriveRoutes } from "../../../../constants/au.desktop/test-drive-routes";

export const TEST_DRIVE_CHECKOUT_STEPS = [
    "basicDetails",
    "paymentSummary",
    "orderConfirmed"
    // testDriveRoutes.basicDetails.key,
    // testDriveRoutes.deliveryDetails.key,
    // testDriveRoutes.paymentSummary.key,
    // testDriveRoutes.orderConfirmed.key

];
