/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import CarImage from "./images/car-image.png";
import C2bLandingAtfForm from "../../../au-c2b/au.desktop/components/c2b-landing-atf-form";
import { PAGE_SOURCE } from "../../../constants/app-constants";

const SellOrTradeInBannerRevamp = ({ guestLoginProps }) => {
    return (
        <div styleName={"styles.outer"}>
            <img src={CarImage} alt="Sell or trade in car image" />
            <p styleName={"styles.bannerHeading"}>Got a car to sell or trade in?<br />Get a <span>free valuation instantly.</span></p>
            <div styleName={"styles.formWrapper"}>
                <C2bLandingAtfForm
                    guestLoginProps={guestLoginProps}
                    showVinToggleSection={false}
                    checkVinActive={false}
                    ctaText="GET VALUATION"
                    eventId="CLP_REGO_FORM"
                    customStyles="customInputStyle"
                    formSource={PAGE_SOURCE.HOME_PAGE}
                />
            </div>
        </div>
    );
};

SellOrTradeInBannerRevamp.propTypes = {
    guestLoginProps: PropTypes.object
};

export default SellOrTradeInBannerRevamp;
