import React from "react";
import { connect } from "react-redux";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Checklist from "./images/checklist.svg";
import CarBanner from "./images/banner-image.png";

const Banner = ({model}) => {
    return (
        <div styleName={"styles.bannerOuter"}>
            <img src={CarBanner} />
            <p styleName={"styles.bannerHeading"}>Get your <span>{model}</span> fully equipped at time of delivery </p>
            <div styleName={"styles.checklistWrap"}>
                <img src={Checklist} />
                <p styleName={"styles.list"}>Can be added to your loan</p>
            </div>
            <div styleName={"styles.checklistWrap"}>
                <img src={Checklist} />
                <p styleName={"styles.list"}>Certified installations</p>
            </div>
            <div styleName={"styles.checklistWrap"}>
                <img src={Checklist} />
                <p styleName={"styles.list"}>Up to lifetime warranty</p>
            </div>
            <div styleName={"styles.checklistWrap"}>
                <img src={Checklist} />
                <p styleName={"styles.list"}>Best-in-class accessories</p>
            </div>
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content: {
            model
        }
    }
}) => ({
    model
});

export default connect(mapStateToProps)(Banner);

Banner.propTypes = {
    model: PropTypes.string
};
