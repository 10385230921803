import React from "react";
import styles from "./style.css";
import PropTypes from "prop-types";

const AlertCard = ({
    children,
    variant,
    margin = false
}) => {

    const classMap = {
        green: "styles.green",
        blue: "styles.blue",
        yellow: "styles.yellow",
        red: "styles.red",
        lightRed: "styles.lightRed",
        darkPink: "styles.darkPink",
        DarkGreen: "styles.darkGreen",
        lightGreen: "styles.lightGreen",
        shadowRed: "styles.shadowRed",
        shadowGreen: "styles.shadowGreen",
        greenText: "styles.greenText",
        redText: "styles.redText",
        darkYello: "styles.darkYellow",
        greenAlert: "styles.greenAlert styles.alertCard",
        yellowAlert: "styles.yellowAlert styles.alertCard",
        redAlert: "styles.redAlert styles.alertCard",
        b2cGreen: "styles.b2cGreen",
        black: "styles.black",
        lightBlue: "styles.lightBlue"
    };
    const alertStyles = [
        classMap[variant],
        margin ? "styles.margin" : " "
    ].filter(s => !!s).join(" ");
    return (
        <div styleName={`styles.alert ${alertStyles || ""}`}>
            { children }
        </div>
    );
};
AlertCard.propTypes = {
    children: PropTypes.any,
    variant: PropTypes.string,
    margin: PropTypes.bool
};
export default AlertCard;
