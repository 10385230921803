import React, {useEffect} from "react";
import ReactModal from "react-modal";
import styles from "./styles.css";
import PropTypes from "prop-types";

//ReactModal.setAppElement(".js-content");

const Modal = ({
    children,
    isOpen,
    close,
    contentClass = "",
    overlayClass = "overlayClass",
    onAfterOpen,
    hideOverlay = false,
    hideContent = false,
    shouldCloseOnOverlayClick = true
}) => {
    useEffect(() => {
        return (() => {
            document.body.classList.remove("ReactModal__Body--open");
        });

    }, []);
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel="a"
            onRequestClose={close}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            overlayClassName={`${overlayClass} ${hideOverlay ? `hideOverlay` : ``}`}
            className={`contentClass ${contentClass} ${hideContent ? `hideContent` : ``} `}
            onAfterOpen={onAfterOpen}
        >
            {children}
        </ReactModal>
    );
};
Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func,
    contentClass: PropTypes.string,
    overlayClass: PropTypes.string,
    onRequestClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    hideOverlay: PropTypes.bool,
    hideContent: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool
};
export default Modal;
