import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const ListTab = ({
    className,
    data,
    labelKey,
    onClick,
    selectedKey = null,
    dataKey,
    color,
    children,
    type = ""
}) => {

    // IDENTIFIER IS USED TO TRACK THE POSITION OF THE CURRENT SELECTED TAB, IN ORDER TO SHOW THE INFO BOX AT THE SAME POSITION
    const { identifier = "" } = data || {};

    const getStyles = () => {
        let style = "styles.tab";
        if (color && data[dataKey] === selectedKey) {
            style = `${style} styles.${color} styles.active${color}`;
        } else if (data[dataKey] === selectedKey) {
            style = `${style} styles.active`;
        } else if (color) {
            style = `${style} styles.${color}`;
        }

        return style;
    };

    const handleClick = () => {
        onClick(data[dataKey], { ...data, selectedKey});
    };

    return (
        <div id={identifier} className={className} styleName={className !== "col-12" ? "styles.column" : "styles.noPadding"}>
            <div className={`card`} styleName={type === "rounded" ? "styles.roundedCorner" : getStyles()} onClick={handleClick}>
                {!children && data[labelKey]}
                {children && children}
            </div>
        </div>
    );
};

ListTab.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    labelKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func.isRequired,
    selectedKey: PropTypes.any,
    dataKey: PropTypes.string.isRequired,
    color: PropTypes.string,
    children: PropTypes.string,
    type: PropTypes.string
};

export default ListTab;
