import { EXPERIMENT_TYPE } from "../constants/optimize-constants";

const DEFAULT_FINANCE_TYPE = {
    showInHouseVariant: EXPERIMENT_TYPE.VARIANT_B,
    inHouseEligible: true
};

export {
    DEFAULT_FINANCE_TYPE
};
