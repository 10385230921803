/* eslint-disable max-params */
export default (api) => {

    const getFaq = () => {
        return api.get(`api/v2/masterdata/faq?type=BUY`);
    };

    return {
        getFaq
    };
};
