/* eslint-disable no-magic-numbers */
const getAgeFromDate = (date, splitText = "-") => {
    date = date.split(splitText);
    const day = parseInt(date[0]);
    const month = parseInt(date[1]) - 1;
    const year = parseInt(date[2]);

    const today = new Date();
    const birthdate = new Date(year, month, day);

    let age = today.getFullYear() - birthdate.getFullYear();

    if (
        today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate() ||
        today.getMonth() < birthdate.getMonth()
    ) {
        age--;
    }

    return age;
};

export {
    getAgeFromDate
};
