import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWarrantyPlans, updateWarrantyPlans, updateCheckoutSteps } from "../checkout-info/actions";
import Cars24Cover from "./component";
import { getVASCart, setAddonScreenType } from "../add-ons/actions";

const mapStateToProps = ({
    user: {isAffiliate, secureToken},
    carDetails: {content, config, eligibleForExtendedWarranty},
    checkout: {
        extendedWarranty: {
            isLoading: extendedWarrantyLoading,
            warrantyPlanInfo
        },
        order,
        tradeInData,
        tradeinOpted
    },
    abExperiment: {
        showWarrantyComplimentary,
        showWarrantyPageShift
    },
    preApprovalLoan: {
        quotesAvailable: preApproved
    },
    addOns: {
        vasCart: {
            data: vasCartData
        }
    }
}) => ({
    config,
    content,
    extendedWarrantyLoading,
    warrantyPlanInfo,
    order,
    eligibleForExtendedWarranty,
    tradeInData,
    tradeinOpted,
    isAffiliate,
    showWarrantyComplimentary,
    showWarrantyPageShift,
    preApproved,
    secureToken,
    vasCartData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getWarrantyPlansConnect: getWarrantyPlans,
    updateWarrantyPlansConnect: updateWarrantyPlans,
    updateCheckoutStepsConnect: updateCheckoutSteps,
    setAddonScreenTypeConnect: setAddonScreenType,
    getVASCartConnect: getVASCart
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cars24Cover);
