/* eslint-disable complexity */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
// import ComplimentaryBenefits from "../complimentary-benefits";
// import { getGroupedObj } from "../../../utils/helpers/get-grouped-obj";
// import PriceBreakupDisclaimer from "../../shared/price-breakup-disclaimer";
import {getTradePriceLabel} from "../../../utils/helpers/calculate-egc-dap";
import PriceBreakupPaymentTerms from "../price-breakup-payment-terms";
import PromoCode from "../promo-code";
import { DELIVERY_TYPES, ORDER_STATUS } from "../../../constants/checkout-constants";
import { LOAN_PROVIDER} from "../../../constants/app-constants";
import DOLLORICON from "./images/dollor-icon.svg";
import { checkoutSummarySaleLabelNew } from "../../../utils/helpers/on-sale-label";

const PriceBreakUp = ({
    chargeDetails = [],
    price,
    tradeInData = {},
    egcData = [],
    tradeinOpted,
    deliveryInfo = {},
    amountWithSurcharge,
    paymentSummaryData,
    gst: gstAmount,
    promoCode = {},
    status: orderStatus,
    origin = "",
    financeOpted,
    financeProvider,
    isManualSales,
    deliveryMode,
    testDriveData = {},
    saleConfig,
    isUserZeroDpVariant,
    isCheckoutSummaryZeroDpScreen = false,
    interstateFees,
    shippingCharge
}) => {
    // const { charges } = getGroupedObj(chargeDetails, "type");
    const {couponCode, discountPrice, isCouponApplied} = promoCode;
    const { amount = 0 } = testDriveData;
    const { shippingCharge: shippingPrice, totalAmountToCollect, priceBreakDown = {}, totalDap, tradeInOfferPrice} = paymentSummaryData || {};
    const { ctp, egc, motorVehicleDuty, registrationCost, state: auState, transferFee} = priceBreakDown || {};
    const {
        isDeliveryAvailable,
        priceLabel,
        dapOrTradePrice,
        isCarAvailable,
        registrationTenure,
        vasItems
    } = getTradePriceLabel({egcData, tradeInData, tradeinOpted, deliveryInfo, dapPrice: price, chargeDetails, isUserZeroDpVariant, deliveryMode });

    const isDrivaOpted = financeProvider === LOAN_PROVIDER.DRIVA && financeOpted;
    const isInHouseOpted = financeProvider === LOAN_PROVIDER.CARS24 && financeOpted;
    const warrantyLabel = (chargeDetails || []).find(item => item.key === "extendedWarranty") || {};
    const tintingPlan = (chargeDetails || []).find(item => item.key === "tintingPlan") || {};
    // const discountApplied = discount && discount.length > 0 ? discount[0] : {};
    //const onSalePrice = makePriceLabelRound(price - egc);

    const saleLabel = checkoutSummarySaleLabelNew({saleConfig, price, egc});
    return (
        <React.Fragment>
            {!isManualSales && !isCheckoutSummaryZeroDpScreen && <PriceBreakupPaymentTerms amountWithSurcharge={amountWithSurcharge} />}
            <div>
                <div styleName={"styles.paymentTerms"}>
                    <div styleName={"styles.new-price-breakdown"}>
                        <img src={DOLLORICON}/>
                        <p>Price Breakdown</p>
                    </div>
                    {saleLabel &&
                        <p styleName="styles.saleDiscountLabel">
                            {saleLabel}
                        </p>
                    }
                    {/* <small styleName={"styles.message"}>{priceSubLabel}</small> */}
                </div>
            </div>

            {(isDeliveryAvailable || isCarAvailable) &&   <div>
                <ul styleName="styles.showBreakup">
                    {/* {tradeinOpted && <li>
                        <label>Final Price</label>
                        <span>{makePriceLabelRound(amountDue || totalAmountToCollect)}</span>
                    </li>} */}
                    <h5>
                        <label>{priceLabel} { origin !== "checkoutSummary" && auState && !tradeinOpted ? `(${auState})` : ""}</label>
                        <span>{makePriceLabelRound(Math.abs(totalAmountToCollect))}</span>
                    </h5>
                    {tradeinOpted && <li>
                        <label>Drive-away price { auState ? `(${auState})` : ""}</label>
                        <span>{makePriceLabelRound(totalDap)}</span>
                    </li>}
                    <li>
                        <label styleName={"styles.label"}>Excluding government charges(EGC){gstAmount && "*"}</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(egc)}</span>
                    </li>
                    <li>
                        <label styleName={"styles.label"}>Motor vehicle duty</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(motorVehicleDuty)}</span>
                    </li>

                    {!!registrationTenure &&   <li>
                        <label styleName={"styles.label"}>{registrationTenure.label}</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(registrationCost)}</span>
                    </li>
                    }
                    {!!ctp && <li>
                        <label styleName={"styles.label"}>CTP{gstAmount && "*"}</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(ctp)}</span>
                    </li>
                    }
                    <li>
                        <label styleName={"styles.label"}>Transfer fee</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(transferFee)}</span>
                    </li>
                    {tintingPlan && tintingPlan.name &&
                        <li>
                            <label styleName={"styles.label"}>Window Tinting</label>
                            <span styleName={"styles.price"}>+{makePriceLabelRound(tintingPlan.amount)}</span>
                        </li>
                    }
                    {(shippingPrice && deliveryMode === DELIVERY_TYPES.HomeDelivery) ? <li>
                        <label styleName={"styles.label"}>{interstateFees ? "Interstate Delivery Charges" : "Shipping fee"}</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(shippingPrice)}</span>
                    </li> : <React.Fragment />}
                    {(shippingCharge && interstateFees && deliveryMode === DELIVERY_TYPES.PickUp) ? <li>
                        <label styleName={"styles.label"}>Interstate shipping charges</label>
                        <span styleName={"styles.price"}>{makePriceLabelRound(shippingCharge)}</span>
                    </li> : <React.Fragment />}
                    {isCouponApplied && dapOrTradePrice > 0 &&
                         <li>
                             <label styleName={"styles.label"}>Promotion({couponCode})</label>
                             <span  styleName={"styles.price"}>{`-${makePriceLabelRound(discountPrice)}`}</span>
                         </li>

                    }
                    {warrantyLabel.label &&
                        <li>
                            <label>{`Extended warranty(${warrantyLabel.label})`}</label>
                            <span>{`+${makePriceLabelRound(warrantyLabel.amount)}`}</span>
                        </li>
                    }
                    {(vasItems || []).length > 0 && vasItems.map(vp => {
                        const { discountedPrice, key, name } = vp || {};
                        return (
                            <li key={key}>
                                <label>{name}</label>
                                <span>+{makePriceLabelRound(discountedPrice)}</span>
                            </li>
                        );
                    })}
                    {tradeinOpted &&
                    <li>
                        <label styleName={"styles.label"}>{"Trade-in Credit"}</label>
                        <span styleName={"styles.price"}>-{makePriceLabelRound(tradeInOfferPrice)}</span>
                    </li>
                    }
                    {!!amount &&
                    <li>
                        <label styleName={"styles.label"}>{"Test drive deposit"}</label>
                        <span styleName={"styles.price"}>-{makePriceLabelRound(amount)}</span>
                    </li>
                    }
                    {gstAmount && <li styleName="styles.gstInclusive">(*)  Items are subject to and inclusive of {makePriceLabelRound(gstAmount)} GST</li>}
                </ul>
            </div>}
            {(orderStatus ===  ORDER_STATUS.CREATED || orderStatus === ORDER_STATUS.TEST_DRIVE_RESERVED) && !isDrivaOpted  && !isInHouseOpted && dapOrTradePrice > 0 && <PromoCode />}

            {/* <div className={"mb-3"}>
                <ComplimentaryBenefits complemetaryBenefits={charges} />
            </div> */}
            <p styleName={"styles.disclaimer"}> <span>Disclaimer:</span> Drive away price is inclusive of the cost of the car and all government duties including motor vehicle duty and transfer fees</p>
            {/* <PriceBreakupDisclaimer /> */}
            {/* {dapOrTradePrice > 0 && !isCheckoutSummaryZeroDpScreen && <PaymentTerms totalDap={totalDap} dapOrTradePrice={dapOrTradePrice} />} */}
            {!!(financeProvider === LOAN_PROVIDER.DRIVA) && origin === "checkoutSummary" && <p styleName={"styles.note"}> <span>Note:</span> These are your indicative loan terms. Once you pay deposit amount your loan application will be sent to driva team for further processing</p>}
        </React.Fragment>
    );
};

PriceBreakUp.propTypes = {
    chargeDetails: PropTypes.array,
    price: PropTypes.number,
    egcData: PropTypes.array,
    isHighValuation: PropTypes.bool,
    tradeInPrice: PropTypes.string,
    deliveryMode: PropTypes.string,
    amountWithSurcharge: PropTypes.number,
    tradeInData: PropTypes.object,
    tradeinOpted: PropTypes.bool,
    deliveryInfo: PropTypes.object,
    origin: PropTypes.string,
    shippingPrice: PropTypes.number,
    promotion: PropTypes.bool,
    saleConfig: PropTypes.object,
    discountApplied: PropTypes.number,
    discount: PropTypes.array,
    gst: PropTypes.number,
    promoCode: PropTypes.object,
    isCheckoutSummaryZeroDpScreen: PropTypes.bool,
    status: PropTypes.string,
    financeOpted: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    isManualSales: PropTypes.bool,
    financeProvider: PropTypes.string,
    paymentSummaryData: PropTypes.object,
    testDriveData: PropTypes.object,
    interstateFees: PropTypes.bool,
    shippingCharge: PropTypes.number
};

export default PriceBreakUp;
