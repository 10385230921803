/* eslint-disable no-undef */
/* eslint-disable no-console */

import authApi, { proxyAuthState as proxyState } from "../../service/userAuthApi";
import jwtDecode from "jwt-decode";
import { NUMBER } from "../../constants/app-constants";
import { AUTH_PRODUCT_TYPE } from "../../constants/api-constants";

const DEBUG = process.env.NODE_ENV === "development";

const WAIT_TIME_IN_SECS = NUMBER.SIXTY;

// Request Interceptor which handles token refresh pre and post token expiry

const authRefresh = async (config, api = authApi, proxyAuthState = proxyState) => {
    if (config.headers.Authorization) {
        const authToken = (config.headers.Authorization && config.headers.Authorization.split(" ")[1]) || null;
        const authTokenDecoded = await jwtDecode(authToken);
        const currentTimeInSec = new Date().getTime() / NUMBER.THOUSAND;
        // update access token when expiry time is less than 60 secs
        if (authToken && authTokenDecoded && authTokenDecoded.exp - currentTimeInSec < WAIT_TIME_IN_SECS) {
            try {
                const authResponse  = await api.get(`/oauth/${AUTH_PRODUCT_TYPE}/token/refresh`);
                proxyAuthState.accessToken = authResponse.data.access_token;
                proxyAuthState.expiresIn = authResponse.data.expires_in;
                proxyAuthState.isLoggedIn = true;
                config.headers.Authorization = `Bearer ${authResponse.data.access_token}`;
            } catch (err) {
                proxyAuthState.isLoggedIn = false;
            }
        }
    }
    if (DEBUG) {
        console.debug("✉️ ", config);
    }
    return config;
};

export default authRefresh;
