/*

filters: {
    make: {
        key: "make",
        displayText: "Make",
        filterType: "sf",
        uiType: "",
        onScreen: true,
        isActive: true,
        options: [
            {
                key: "hyundai",
                displayText: "Hyundai",
                subFilter: {
                    key: "model",
                    displayText: "Model",
                    options: [
                        {
                            key: "eon",
                            dispalyText: "Eon",
                            isSelected: false
                        }
                    ]
                }
            }
        ]
    },
    fuelType: {
        key: "fuelType",
        displayText: "Fuel Type",
        isCollapsed: false,
        uiType: "",
        options: [
            key: "",
            displayText: "",
            isSelected: false
        ]
    },
    odometerReading: {
        key: "odometerReading",
        filterType: "rf",
        min: 0,
        max: 50000,
        uiType: "",
        onScreen: false
    }
}

onScreenFilters: ["price", "year", "km", "make"]
offScreenFilter: ["fuelType", "bodyType"]

*/

import {ON_SCREENT_FILTERS, OFF_SCREEN_FILTERS, FILTER_TYPES, FILTER_ENTITIES} from "./constants";

const uiTypeMap = {
    "STATS": "RANGE",
    "TERMS": "CHECKBOX"
};

const getParsedOptions = (buckets, isRangeFilter, isModelKey = false) => {
    if (!(Array.isArray(buckets))) {
        return null;
    }
    return buckets.map((bucket) => ({
        key: bucket.name,
        displayText: bucket.name,
        count: bucket.count,
        logo: bucket.moreInfo || {},
        spath: bucket.spath,
        ...(
            isModelKey && {
                subFilterName: bucket.subFilterName || "",
                subFilterValue: bucket.subFilterValue || "",
                filterValue: bucket.filterValue || ""
            }
        ),
        ...(
            bucket.from && bucket.to && {
                min: bucket.from,
                max: bucket.to
            }
        ),
        ...(
            isRangeFilter && {
                min: bucket.min,
                max: bucket.max
            }
        ),
        ...(
            bucket.subFacet && {
                subFilter: {
                    key: bucket.subFacet.name,
                    dispalyText: bucket.subFacet.name,
                    count: bucket.subFacet.count,
                    options: bucket.subFacet.buckets.map((subBucket) => ({
                        key: subBucket.name,
                        displayText: subBucket.name,
                        count: subBucket.count
                    }))
                }
            }
        ),
        ...bucket
    }));
};

const getFiltersData = (filters) => {
    const modifiedFilters = {};
    const onScreenFilters = ON_SCREENT_FILTERS.filter(item => !!filters[item]);
    const offScreenFilters = OFF_SCREEN_FILTERS.filter(item => !!filters[item]);

    Object.keys(filters).forEach((filterKey) => {
        const {
            filterType,
            type,
            min,
            max,
            mainFilter,
            buckets,
            isSingleEntity,
            displayName,
            suggestions,
            stepRate,
            name = ""
        } = filters[filterKey];

        // if (mainFilter) {
        //     onScreenFilters.push(filterKey);
        // } else {
        //     offScreenFilters.push(filterKey);
        // }

        modifiedFilters[filterKey] = {
            key: filterKey,
            displayText: displayName,
            filterType,
            mainFilter,
            uiType: uiTypeMap[type],
            isSingleEntity,
            stepRate,
            ...(
                suggestions && {
                    suggestions: getParsedOptions(suggestions, filterType === FILTER_TYPES.RF, filterKey === FILTER_ENTITIES.MODEL) || suggestions
                }
            ),
            ...(
                filterType === FILTER_TYPES.RF && {
                    min,
                    max
                    // options: [{
                    //     minValue: min,
                    //     maxValue: max,
                    //     isSelected: false
                    // }]
                }
            ),
            ...(
                filterKey === FILTER_ENTITIES.DELIVERY_TIME && {
                    deliveryCityKey: name
                }
            ),
            ...(
                buckets &&  buckets.length && {
                    options: getParsedOptions(buckets) || []
                }
            )
        };
    });

    return {
        allFilters: modifiedFilters,
        onScreenFilters,
        offScreenFilters
    };
};

export default getFiltersData;
