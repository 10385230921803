/* eslint-disable max-statements */
/* eslint-disable complexity */
import { HTTP_CODES } from "../../constants/app-constants";

const poll = ({ fn, validate, interval, maxAttempts, fnParams = "", retryOnFail  }) => {
    let attempts = 0;

    // eslint-disable-next-line consistent-return
    const executePoll = async (resolve  = () => {}, reject = () => {}) => {
        try {
            let result = {};
            if (fnParams) {
                result = await fn(fnParams);
            } else {
                result = await fn();
            }
            attempts++;
            if (validate(result, attempts)) {
                return resolve(result);
            } else if (maxAttempts && attempts === maxAttempts) {
                return reject(new Error("Exceeded max attempts"));
            } else if (typeof globalThis !== undefined || window) {
            // eslint-disable-next-line no-undef
                (globalThis.self || window).setTimeout(executePoll, interval);
            }
        } catch (error) {
            if ((retryOnFail && (error.status === HTTP_CODES.INTERNAL_SERVER_ERROR || error.isNetworkError) && attempts < maxAttempts)) {
                attempts++;
                // eslint-disable-next-line no-undef
                (globalThis.self || window).setTimeout(executePoll, interval);
            } else {
                return reject(new Error(error));
            }
        }
    };
    return new Promise(executePoll);
};

export {
    poll
};
