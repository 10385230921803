const numberRegex =  /^\d+$/;
const stringRegexWithSpace = /^[A-Za-z ]+$/;

const decimalNumberRegex = /^\d+(\.\d*)?%?$/;

const removeCurrencyFormatting = (str) => {
    const val = str.replace(/[\$,\.]/g, "");
    return val ? val : 0;
};

const removePercentageFormatting = (str) => {
    const val = str.replace(/%/g, "");
    return val ? val : 0;
};

const replaceAll = (str = "") => ({
    afterDot: str.replace(/\.(.*)/, "")
});

export {
    numberRegex,
    replaceAll,
    stringRegexWithSpace,
    removeCurrencyFormatting,
    removePercentageFormatting,
    decimalNumberRegex
};
