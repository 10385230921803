import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Tick from "./images/tick.svg";

const WhatsIncludeCardVTwo = ({name}) => {
    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.imageWrap"}>
                <img src={Tick} />
            </div>
            <p styleName={"styles.cardDetail"}>{name}</p>
        </div>
    );
};

export default WhatsIncludeCardVTwo;

WhatsIncludeCardVTwo.propTypes = {
    name: PropTypes.string
};
