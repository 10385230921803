import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    showLocationPicker: false,
    selectedCity: {}
};

export const toggleLocationPicker = (state = INITIAL_STATE, {status}) => {
    return { ...state, showLocationPicker: status !== undefined ? status : !state.showLocationPicker};
};

export const resetCityLocation = (state = INITIAL_STATE, {cityObj}) => {
    return { ...state, selectedCity: cityObj };
};

export const HANDLERS = {
    [Types.TOGGLE_LOCATION_PICKER]: toggleLocationPicker,
    [Types.SET_LOCATION_CITY]: resetCityLocation

};

export default createReducer(INITIAL_STATE, HANDLERS);
