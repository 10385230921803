import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_MAX_STEP
    SET_CURRENT_STEP

    FETCH_SLOTS_SUCCESS
    FETCH_SLOTS_REQUEST
    FETCH_SLOTS_FAILURE

    BOOK_SLOT_REQUEST
    BOOK_SLOT_SUCCESS
    BOOK_SLOT_FAILURE

    SET_INSPECTION_DETAILS
    SET_SELECTED_SLOT
    SET_HARD_REJECTION
    SET_FORM_DATA
    SET_RECHEDULE_FLAG
`,
    {
        prefix: "book-inspection/"
    }
);
