import HelpCenterModalDoubtsDesktop from "../../components/au.desktop/help-center-modal-doubts";
import HelpCenterModalDoubtsMobile from "../../components/au.mobile/help-center-modal-doubts";
import { checkoutRoutes } from "../checkout-routes";

export const SELECT_OFFER_PARAM = "select-offer";
export const SELECT_OFFER_SESSION_KEY = "select-offer";
export const SELECT_PAYMENT_PARAM = checkoutRoutes.paymentSelection.route;
export const SELECT_PAYMENT_SESSION_KEY = checkoutRoutes.paymentSelection.route;
export const CHECKOUTS_SUMMARY_PARAM = checkoutRoutes.summary.route;
export const CHECKOUTS_SUMMARY_SESSION_KEY = checkoutRoutes.summary.route;

export const SHOW_MODAL_ON_BACK_BUTTON_PATHS = {
    [SELECT_OFFER_PARAM]: {
        param: SELECT_OFFER_PARAM,
        paramKey: "stepid",
        DesktopModal: HelpCenterModalDoubtsDesktop,
        MobileModal: HelpCenterModalDoubtsMobile,
        sessionKey: SELECT_OFFER_SESSION_KEY
    },
    [SELECT_PAYMENT_PARAM]: {
        param: SELECT_PAYMENT_PARAM,
        paramKey: "stepid",
        DesktopModal: HelpCenterModalDoubtsDesktop,
        sessionKey: SELECT_PAYMENT_SESSION_KEY
    },
    [CHECKOUTS_SUMMARY_PARAM]: {
        param: CHECKOUTS_SUMMARY_PARAM,
        paramKey: "stepid",
        DesktopModal: HelpCenterModalDoubtsDesktop,
        sessionKey: CHECKOUTS_SUMMARY_SESSION_KEY
    }
};
