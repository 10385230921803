import React from "react";
import PropTypes from "prop-types";

import AddOns from "../add-ons";
import AddOnsRevamp from "../add-ons-revamp";
import { SERVICING_VARIANT_MAPPING } from "../../../constants/app-constants";

const AddOnsWrapper = (props) => {
    const isNewVariant = props?.servicingPriceConfig?.data?.experiment === SERVICING_VARIANT_MAPPING.VARIANT;

    return isNewVariant ? <AddOnsRevamp {...props}/> : <AddOns {...props}/>;
};

AddOnsWrapper.propTypes = {
    servicingPriceConfig: PropTypes.object
};

export default AddOnsWrapper;
