/* eslint-disable max-statements */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import CLOCKIMG from "./images/clock.svg";
// import LOCATIONICON from "./images/location.svg";
// import DOCUMENT from "./images/document.svg";
import Button from "../../shared/button";
// import CustomerCareSupport from "../customer-care-support";
import AfterTestDrive from "../../shared/after-test-drive";
import BookingSteps from "../test-drive/steps";
import { testDriveRoutes } from "../../../constants/au.desktop/test-drive-routes";
import BookingHeaderStrip from "../test-drive/header-strip";
import { useHistory } from "react-router";
import { getCarImage } from "../../../utils/helpers/split-payment";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
// import useTimer from "../../../hooks/use-timer";
// import { getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";
import { ORDER_TYPE } from "../../../constants/app-constants";
// import { formatDeliveryAddressToString } from "../../../utils/helpers/format-address-object";
// import CARIMG from "./images/carimg.svg";
import TestDrivePaymentReciept from "../test-drive-payment-reciept";
import Loader from "../../shared/loader";
import useCountDown from "../../../hooks/use-countdown";
import navigateToMaps from "../../../utils/helpers/navigate-to-maps";
import { poll } from "../../../utils/polling";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import ReservationConfirmTestDrive from "../../shared/reservation-confirm-test-drive";
import { TEST_DRIVE_MANUAL } from "../../../constants/checkout-constants";

const TestDriveConfirmationPage = ({
    match: {
        params: { carId}
    },
    config = {},
    content = {},
    egcData,
    order,
    // chargeDetails,
    secureToken,
    bookingSummary,
    getOrderConnect,
    fetchCarDetailsConnect,
    getBookingSummaryConnect,
    fetchEgcConnect,
    updateOrderConnect
}) => {
    const history = useHistory();
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const { orderId} = order || {};
    const {appointmentId, stateCode, transmissionType, variant} = content;
    const {imageUrl, carFullName} = getCarImage({config, content});
    const {testDriveDetails, referrerSource, paymentMethodType} = bookingSummary || {};
    const {testDriveExpiry = "", yardAddress = {}, isTestDriveBookingUnderBusinessHours} = testDriveDetails || {};

    useEffect(() => {
        fetchCarDetailsConnect(carId);
        getOrderConnect(carId);
    }, [carId, fetchCarDetailsConnect, getOrderConnect]);

    const isBookingSummaryAvailable = (result) => {
        if (Object.keys(result.data) && result.data.testDriveDetails) {
            return true;
        }
        return false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pollBookingSummary = async () => {
        try {
            const params = {
                fn: getBookingSummaryConnect,
                validate: isBookingSummaryAvailable,
                interval: 1000,
                maxAttempts: 5,
                fnParams: appointmentId
            };
            await poll(params);
        } catch (error) {
            // history.replace(window.location.pathname);
        }
    };

    useEffect(() => {
        if (secureToken && appointmentId) {
            pollBookingSummary(appointmentId);
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, {eventAction: "confirm_screen", eventLabel: "car_reserved"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, secureToken]);

    useEffect(() => {
        if (appointmentId) {
            const code = stateCode.split("_");
            fetchEgcConnect(appointmentId, code[1]);
        }
    }, [appointmentId, fetchEgcConnect, stateCode]);

    // const handleBack = () => {
    //     // trackEvent("MY_BOOKING_TASKS", "pay_now");
    //     history.push("/my-bookings");
    // };

    const trackEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.ORDER_SUMMARY_POST_PAYMENT, {eventLabel});
    };

    const handleSummaryModal = () => {
        trackEvent("view_receipt");
        // trackMobileCustomEventsAU(AU_MOBILE_EVENTS.MY_BOOKING_TASKS, {eventLabel: "view_order_summary"});
        setShowOrderSummary(!showOrderSummary);
    };

    const handleBuyNow = () => {
        const payload = {
            orderType: ORDER_TYPE.BUY_ONLINE
        };
        updateOrderConnect(orderId, payload);
        trackEvent("buy_car");
        const {relativeURL} = getCheckoutPageURL(content, checkoutRoutes.basicDetails.route);
        history.push(relativeURL);
    };

    // const {totalDap} = getTradePriceLabel({egcData, chargeDetails });

    const currentStep = testDriveRoutes.orderConfirmed.key;
    // const { seconds, minutes, hours, days } = useTimer({expireTime: testDriveExpiry});
    const [days, hours, minutes, seconds] = useCountDown(testDriveExpiry);

    const handleNavigateMap = () => {
        trackEvent("map_link_clicked");
        navigateToMaps(yardAddress);
    };

    if (!testDriveExpiry) {
        return <Loader/>;
    }

    return (
        <React.Fragment>
            <BookingHeaderStrip
                egcData={egcData}
                showEgcPrice={currentStep.showEgcPrice}
                history={history}
                content={content}
                currentStep={currentStep}
                order={order}
            />

            <div className="container" styleName={"styles.flexWrapper"}>

                <div styleName={"styles.leftCol"}>
                    <BookingSteps currentStep={currentStep} order={order} content={content}/>
                </div>
                <div styleName={"styles.rightCol"} className="row">
                    <div className="col-lg-7">
                        <h1 styleName={"styles.mainheading"}>Your car has been reserved for test drive</h1>
                        <div styleName={"styles.first-section"}>
                            <img src={CLOCKIMG} alt="" />
                            <div styleName={"styles.border"}>
                                <p styleName={"styles.heading"}>Car is reserved for you for the next</p>
                                <p styleName={"styles.day"}>{days} Days<strong styleName={"styles.time"}>{hours}:{minutes}:{seconds} </strong> <span>Hrs</span></p>
                            </div>
                        </div>
                        <div styleName={"styles.booikngcard"}>
                            <p styleName={"styles.bookingid"}>Booking ID:  {orderId}</p>
                            <div styleName={"styles.bookingsummry"}>
                                <img src={imageUrl} alt={carFullName} />
                                <div styleName={"styles.cardetail"}>
                                    <h4 styleName={"styles.carname"}>{carFullName}</h4>
                                    <p styleName={"styles.veriant"}>{transmissionType}{variant}</p>
                                    { (referrerSource === TEST_DRIVE_MANUAL && paymentMethodType === TEST_DRIVE_MANUAL) && <p styleName={"styles.summrary"} onClick={handleSummaryModal}>VIEW  PAYMENT SUMMARY</p>}
                                </div>
                            </div>
                        </div>
                        <ReservationConfirmTestDrive
                            handleNavigateMap={handleNavigateMap}
                            yardAddress={yardAddress}
                            isTestDriveBookingUnderBusinessHours={isTestDriveBookingUnderBusinessHours}
                        />
                        {/* <div styleName={"styles.section"}>
                            <div styleName={"styles.sec-section"}>
                                <img src={LOCATIONICON} alt="" />
                                <div styleName={"styles.test"}>
                                    <h4 styleName={"styles.test-text"}>Test drive location</h4>
                                    <a target="_blank" onClick={handleNavigateMap} styleName={"styles.location"}>
                                        {formatDeliveryAddressToString(yardAddress).addressArray.map(item =>
                                            <span key={item}>{item}<br/></span>
                                        )}
                                    </a>
                                </div>
                            </div>
                            <div styleName={"styles.document-section"}>
                                <img src={DOCUMENT} alt="" />
                                <div styleName={"styles.third-section"}>
                                    <p styleName={"styles.document"}>Documents Required</p>
                                    <p styleName={"styles.following-document"}>You will need the following documents to test drive your car:</p>
                                    <p styleName={"styles.point"}>1. Valid and in-date driving license</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <CustomerCareSupport isTestDriveBookingUnderBusinessHours={isTestDriveBookingUnderBusinessHours} /> */}
                        {/* <ReservationConfirmTestDrive/> */}
                        <div styleName={"styles.last-section"}>
                            <h4 styleName={"styles.new-car-text"}>Can’t wait for your new car?</h4>
                            <p styleName={"styles.para-text"}>But it now and test own it for 7 days after you’ve paid the full amount.</p>
                        </div>
                        <div styleName={"styles.buttonWrapper"}>
                            <Button text="BUY CAR" onClick={handleBuyNow}/>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <AfterTestDrive />
                    </div>
                </div>
                {showOrderSummary && <TestDrivePaymentReciept  onClose={handleSummaryModal} />}
            </div>
        </React.Fragment>
    );
};

TestDriveConfirmationPage.propTypes = {
    content: PropTypes.object,
    order: PropTypes.object,
    egcData: PropTypes.object,
    config: PropTypes.object,
    chargeDetails: PropTypes.array,
    getOrderConnect: PropTypes.func,
    match: PropTypes.object,
    getBookingSummaryConnect: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func,
    fetchEgcConnect: PropTypes.func,
    updateOrderConnect: PropTypes.func,
    bookingSummary: PropTypes.object,
    secureToken: PropTypes.string
};

export default TestDriveConfirmationPage;
