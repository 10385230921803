import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Cars24Cover from "../cars24-cover";
import Cars24CoverRevamp from "../cars24-cover-revamp";
import { SERVICING_VARIANT_MAPPING } from "../../../constants/app-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";

const Cars24CoverWrapper = (props) => {
    const isNewVariant = props?.servicingPriceConfig?.data?.experiment === SERVICING_VARIANT_MAPPING.VARIANT;
    useEffect(() => {
        if (!props?.servicingPriceConfig?.loading) {
            const gaEventLabel = PLATINUM_COVER_GA_EVENTS.PLATINUM_COVER_VARIANT_SEEN.eventLabel.replace("$variant", isNewVariant ? "Variant B" : "Variant A");
            trackMobileCustomEventsAU("", {...PLATINUM_COVER_GA_EVENTS.PLATINUM_COVER_VARIANT_SEEN, eventLabel: gaEventLabel});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.servicingPriceConfig]);

    return isNewVariant ? <Cars24CoverRevamp {...props}/> : <Cars24Cover {...props}/>;
};

Cars24CoverWrapper.propTypes = {
    warrantyPlanInfo: PropTypes.object,
    manufacturerWarranty: PropTypes.object,
    content: PropTypes.object,
    servicingPriceConfig: PropTypes.object
};

export default Cars24CoverWrapper;
