import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import TransparentButton from "../../shared/button/transparent-button";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const EditFlowExitModal = ({ open, onClose, onExit }) => {
    useEffect(() => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.EDIT_FLOW, {
            eventAction: "Flow closure pop up",
            eventLabel: "Pop up seen"
        });
    }, []);

    const handleExit = (e) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.EDIT_FLOW, {
            eventAction: "Flow closure pop up",
            eventLabel: "Yes exit clicked"
        });
        onExit(e);
    };

    return (
        <Modal isOpen={open} close={onClose}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.heading"}>Are you sure you want to exit?</p>
                    <CloseButton type="grey" onClickHandler={onClose} />
                </div>
                <div styleName={"styles.contentWrap"}>
                    <p styleName={"styles.content"}>Exiting this will lose your progress. Use <span>'Next'</span> button to navigate till the end of the form and press <span>'Submit'</span> to reflect your changes.</p>
                    <TransparentButton onClick={handleExit} text="Yes, exit" />
                </div>
            </div>
        </Modal>
    );
};

EditFlowExitModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onExit: PropTypes.func
};

export default EditFlowExitModal;
