
import Types from "./types";

const setGuestLoginVisible = (data) => ({
    type: Types.SET_GUEST_LOGIN_VISIBLE,
    data
});

const setLoginSuccessResponse = (data) => ({
    type: Types.SET_GUEST_LOGIN_SUCCESS_RESPONSE,
    data
});

export {
    setGuestLoginVisible,
    setLoginSuccessResponse
};
