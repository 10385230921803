import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const WhatsIncludeCard = ({carImage, includeHeading, includeDescription, amount}) => {
    return (
        <div styleName={"styles.reviewCardOuter"}>
            <div styleName={"styles.cardMediaContainer"} className="media">
                <img src={carImage} />
                <div className="media-body">
                    <p styleName={"styles.cardHeading"}>{includeHeading}</p>
                    <p styleName={"styles.cardAmount"}>{includeDescription}</p>
                </div>
            </div>
            <p styleName={"styles.amount"}>{amount}</p>
        </div>
    );
};

WhatsIncludeCard.propTypes = {
    carImage: PropTypes.string,
    includeHeading: PropTypes.string,
    includeDescription: PropTypes.string,
    amount: PropTypes.string
};

export default WhatsIncludeCard;
