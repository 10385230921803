export const AMPLITUDE_EVENT_NAMES = {
    "SELL_BANNER_CTA_CLICKED": "sell banner cta clicked",
    "SELL_BANNER_REGO_ENTERED": "sell banner rego entered",
    "SELL_BANNER_VIN_ENTERED": "sell banner vin entered",
    "SELL_BANNER_STATE_ENTERED": "sell banner state entered",
    "SELL_BANNER_YEAR_ENTERED": "sell banner year entered"
};
export const GA_EVENT_NAMES = {
    cta_clicked: {
        event: "custom_event",
        eventAction: "cta_clicked"
    },
    rego_entered: {
        event: "custom_event",
        eventAction: "rego_entered"
    },
    vin_entered: {
        event: "custom_event",
        eventAction: "welcome_screen"
    },
    state_entered: {
        event: "custom_event",
        eventAction: "state_entered"
    },
    year_entered: {
        event: "custom_event",
        eventAction: "year_entered"
    }
};

