const statsigEnv = () => {
    const urls = {
        QA: "qa",
        STAGING: "staging",
        PRODUCTION: "production"
    };

    return urls[process.env.HOST_ENV];
};

export default statsigEnv;
