import Types from "./types";

const setExperiment = (experimentType, experimentData, flattened) => ({
    type: Types.SET_EXPERIMENT,
    experimentType,
    experimentData,
    flattened
});

const setAllAbExperiment = (variants) => ({
    type: Types.SET_ALL_EXPERIMENT,
    variants
});

const setStatsigInitialization = ({ statsigInitialized }) => ({
    type: Types.SET_STATSIG_INITIALIZATION,
    statsigInitialized
});

const allClearExperiment = () => ({
    type: Types.ALL_CLEAR_EXPERIMENT
});

export {
    setExperiment,
    setAllAbExperiment,
    setStatsigInitialization,
    allClearExperiment
};
