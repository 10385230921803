import { X_COUNTRY_CODE, X_VEHICLE_TYPE } from "../constants/api-constants";
import { cloudFunctionsURL } from "../constants/url-constants";
const cloudURL = cloudFunctionsURL();

export default (api) => {

    const getIpInfo = (mockIp) => {
        const endpoint = `https://ipinfo.io${mockIp ? `/${mockIp}` : ""}`;
        return api.get(endpoint, {
            params: {
                token: process.env.IP_INFO_API_KEY
            }
        });
    };

    const b2cBuyerNotification = (params) => {
        const url = `${cloudURL}/b2c-buyer-notification`;
        return api.post(url, { ...params }, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                X_COUNTRY: X_COUNTRY_CODE.AU,
                "Content-Type": "application/json"
            }
        });
    };

    return {
        getIpInfo,
        b2cBuyerNotification
    };
};
