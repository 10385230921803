import loadable from "@loadable/component";
import BSBBankTransfer from "../../components/au.desktop/bank-transfer";
import React from "react";
const CheckoutBasicDetails = loadable(() => import("../../components/au.desktop/checkout-basic-details"));
const CheckoutDeliveryInformation = loadable(() => import("../../components/au.desktop/checkout-delivery-information"));
// const CheckoutPaymentOptions = loadable(() => import("../../components/au.desktop/checkout-payment-options"));
const CheckoutPaymentOptionsRevamp = loadable(() => import("../../components/au.desktop/checkout-payment-options-revamp"));
const CheckoutSummaryAB = loadable(() => import("../../components/au.desktop/checkout-summary-ab"));
const OrderConfirmedInfo = loadable(() => import("../../components/au.desktop/order-confirmed-info"));
const PreApprovedLoan = loadable(() => import("../../components/au.desktop/pre-approved-loan"));
const GetFinancing = loadable(() => import("../../components/au.desktop/get-financing"));
const FinanceLoanDetails = loadable(() => import("../../components/au.desktop/finance-loan-details"));
//const FinanceLoanType = loadable(() => import("../../components/au.desktop/finance-loan-type"));
const DrivaCustomizeWrapper = loadable(() => import("../../components/au.desktop/driva-customize-wrapper"));
const FinancePersonalDetails = loadable(() => import("../../components/au.desktop/finance-personal-details"));
const FinanceEmploymentDetails = loadable(() => import("../../components/au.desktop/finance-employment-details"));
const TradeInIntro = loadable(() => import("../../components/au.desktop/trade-in-intro"));
const TradeInQuestionnaire = loadable(() => import("../../components/au.desktop/trade-in-questionnaire"));
const TradeInCarmake = loadable(() => import("../../components/au.desktop/trade-in-make-model-flow"));
const TradeInValuation = loadable(() => import("../../components/au.desktop/trade-in-valuation"));
const TradeInValuationHigh = loadable(() => import("../../components/au.desktop/trade-in-valuation-high"));
const CheckoutPaymentOverview = loadable(() => import("../../components/au.desktop/checkout-payment-overview"));
const ZeroDpReBookDeposit = loadable(() => import("../../components/au.desktop/zero-dp-re-book-deposit"));
// const AddOns = loadable(() => import("../../components/au.desktop/add-ons"));

// import Cars24Cover from "../../components/au.desktop/cars24-cover";
import AdyenPayment from "../../components/au.desktop/adyen-payment";
import Cars24CoverWrapper from "../../components/au.desktop/cars24-cover-wrapper";
import AddOnsWrapper from "../../components/au.desktop/add-ons-wrapper";

export const checkoutRoutes = {
    basicDetails: {
        key: "basicDetails",
        name: "basic details",
        route: "basic-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "basic_details",
        defaultBack: "",
        defaultNext: "delivery-details",
        component: CheckoutBasicDetails,
        type: "checkout",
        approx: 3,
        showEgcPrice: true,
        hideShippingPrice: true,
        sidebarNavigation: {
            heading: "Basic detail",
            subHeading: "Tell us about yourself",
            navKey: "basicDetails"
        }
    },
    deliveryDetails: {
        key: "deliveryDetails",
        name: "delivery details",
        route: "delivery-details",
        gtmPageName: "Delivery",
        gtmPillClickLabel: "delivery_details",
        defaultBack: "basic-details",
        defaultNext: "trade-in-intro",
        component: CheckoutDeliveryInformation,
        type: "checkout",
        approx: 2,
        showEgcPrice: false,
        hideShippingPrice: true,
        sidebarNavigation: {
            heading: "Delivery detail",
            subHeading: "Share your address",
            navKey: "deliveryDetails"
        }
    },
    //Trade-In
    tradeInIntro: {
        key: "tradeInIntro",
        name: "trade-in",
        route: "trade-in-intro",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "delivery-details",
        component: TradeInIntro,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInCarMake: {
        key: "tradeInCarMake",
        name: "trade-in",
        route: "trade-in-car-make",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInCarmake,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInCarModel: {
        key: "tradeInCarModel",
        name: "trade-in",
        route: "trade-in-car-model",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInCarmake,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInCarYear: {
        key: "tradeInCarYear",
        name: "trade-in",
        route: "trade-in-car-year",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInCarmake,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInCarVariant: {
        key: "tradeInCarVariant",
        name: "trade-in",
        route: "trade-in-car-variant",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-questionnaire",
        defaultBack: "trade-in-intro",
        component: TradeInCarmake,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInQuestionnaire: {
        key: "tradeInQuestionnaire",
        name: "trade-in",
        route: "trade-in-questionnaire",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "trade-in-valuation",
        defaultBack: "trade-in-intro",
        component: TradeInQuestionnaire,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInValuation: {
        key: "tradeInValuation",
        name: "trade-in",
        route: "trade-in-valuation",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "payment-options",
        defaultBack: "trade-in-questionnaire",
        component: TradeInValuation,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    tradeInValuationHigh: {
        key: "tradeInValuationHigh",
        name: "trade-in",
        route: "trade-in-valuation-high",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "order-confirmation",
        defaultBack: "trade-in-valuation",
        component: TradeInValuationHigh,
        type: "checkout",
        subType: "trade-in",
        approx: 2,
        sidebarNavigation: {
            heading: "Trade-in",
            subHeading: "Sell your old car to us",
            navKey: "tradeInIntro"
        }
    },
    carCover: {
        key: "carCover",
        name: "Cars24 Cover",
        route: "warranty-cover",
        gtmPageName: "carCover",
        gtmPillClickLabel: "warranty_cover_details",
        defaultBack: "trade-in-intro",
        defaultNext: "add-ons",
        component: Cars24CoverWrapper,
        type: "checkout",
        approx: 2,
        sidebarNavigation: {
            heading: "CARS24 cover",
            subHeading: "Extend Warranty ",
            navKey: "carCover"
        }
    },
    addOns: {
        key: "addOns",
        name: "Addons",
        route: "add-ons",
        gtmPageName: "addOns",
        gtmPillClickLabel: "addOns",
        defaultBack: "warranty-cover",
        defaultNext: "payment-options",
        component: AddOnsWrapper,
        type: "checkout",
        approx: 2,
        sidebarNavigation: {
            heading: "Add ons",
            subHeading: "Get car accessorised",
            navKey: "addOns"
        }
    },
    paymentOptions: {
        key: "paymentOptions",
        name: "payment options",
        route: "payment-options",
        gtmPageName: "payment_method",
        gtmPillClickLabel: "payment_method_checkout",
        defaultBack: "add-ons",
        defaultNext: "get-financing",
        component: CheckoutPaymentOptionsRevamp,
        type: "checkout",
        approx: 0,
        sidebarNavigation: {
            heading: "Payment mode",
            subHeading: "Full price or finance",
            navKey: "paymentOptions"
        }
    },
    summary: {
        key: "summary",
        name: "checkout",
        route: "summary",
        gtmPageName: "Summary",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "order-confirmation",
        component: CheckoutSummaryAB,
        type: "checkout",
        approx: 0,
        sidebarNavigation: {
            heading: "Order details",
            subHeading: "Make payment",
            navKey: "summary"
        }
    },
    payment: {
        key: "payment",
        name: "checkout",
        route: "payment",
        gtmPageName: "Paymenbt",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "order-confirmation",
        component: AdyenPayment,
        type: "checkout",
        approx: 0,
        sidebarNavigation: {
            heading: "Order details",
            subHeading: "Make payment",
            navKey: "summary"
        }
    },
    orderConfirmed: {
        key: "orderConfirmed",
        name: "order confirmed",
        route: "order-confirmation",
        gtmPageName: "confirmation",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: OrderConfirmedInfo,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Order confirmation",
            subHeading: "Order summary",
            navKey: "orderConfirmed"
        }
    },
    preApprovedLoan: {
        key: "preApprovedLoan",
        name: "pre approved loan",
        route: "pre-approved-loan",
        gtmPageName: "pre_approved",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "summary",
        component: PreApprovedLoan,
        type: "pre-approved",
        approx: 0,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    // financing routes
    getFinancing: {
        key: "getFinancing",
        name: "get financing",
        route: "get-financing",
        gtmPageName: "Driva_value_prop",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "loan-details",
        component: GetFinancing,
        type: "get-financing",
        approx: 0,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    loanDetails: {
        key: "loanDetails",
        name: "loan details",
        route: "loan-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "loan_details",
        defaultBack: "payment-options",
        defaultNext: "employment-details",
        component: FinanceLoanDetails,
        showGreenTick: true,
        showPillNumber: true,
        isCheckoutPreApproval: true,
        type: "financing",
        approx: 4,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    //loanType: {
    //    key: "loanType",
    //    name: "loan type",
    //    route: "loan-type",
    //    gtmPageName: "Driva_business_purpose",
    //    gtmPillClickLabel: "loan_type",
    //    defaultBack: "loan-details",
    //    defaultNext: "personal-details",
    //    component: FinanceLoanType,
    //    type: "financing",
    //    approx: 3,
    //    sidebarNavigation: {
    //        heading: "Loan detail",
    //        subHeading: "Fill pre-approval form  ",
    //        navKey: "paymentOptions"
    //    }
    //},
    employmentDetails: {
        key: "employmentDetails",
        name: "employment details",
        route: "employment-details",
        gtmPageName: "Driva_employment_status",
        gtmPillClickLabel: "employment_details",
        defaultNext: "personal-details",
        defaultBack: "loan-details",
        component: FinanceEmploymentDetails,
        showGreenTick: true,
        showPillNumber: true,
        isCheckoutPreApproval: true,
        type: "financing",
        approx: 1,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    personalDetails: {
        key: "personalDetails",
        name: "your details",
        route: "personal-details",
        gtmPageName: "", // multi-step
        gtmPillClickLabel: "driva_personal_details",
        defaultNext: "select-offer",
        defaultBack: "employment-details",
        component: FinancePersonalDetails,
        showGreenTick: true,
        showPillNumber: true,
        isCheckoutPreApproval: true,
        type: "financing",
        approx: 2,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form  ",
            navKey: "paymentOptions"
        }
    },
    selectOffer: {
        key: "selectOffer",
        name: "select offer",
        route: "select-offer",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: DrivaCustomizeWrapper,
        type: "financing",
        hideCurrentPill: true,
        approx: 1,
        sidebarNavigation: {
            heading: "Loan detail",
            subHeading: "Fill pre-approval form",
            navKey: "paymentOptions"
        }
    },
    paymentSelection: {
        key: "paymentSelection",
        name: "Payment Summary Select",
        route: "select-payment",
        gtmPageName: "payment_method",
        gtmPillClickLabel: "payment_method_checkout",
        defaultBack: "warranty-cover",
        defaultNext: "summary",
        component: CheckoutPaymentOverview,
        type: "checkout",
        approx: 0,
        sidebarNavigation: {
            heading: "Payment mode",
            subHeading: "Full price or finance",
            navKey: "paymentOptions"
        }
    },
    selectDeposit: {
        key: "selectDeposit",
        name: "Select Deposit",
        route: "select-deposit",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: ZeroDpReBookDeposit,
        hideCurrentPill: true,
        useRoute: null,
        type: "financing",
        approx: 1,
        sidebarNavigation: {
            heading: "Select Deposit",
            subHeading: "Add your deposit",
            navKey: "selectDeposit"
        }
    },
    bankTransferDetails: {
        key: "bankTransferDetails",
        name: "Bank Transfer",
        route: "bank-transfer-details",
        gtmPageName: "bank_transfer",
        gtmPillClickLabel: "",
        defaultBack: "payment-options",
        defaultNext: "summary",
        component: BSBBankTransfer,
        type: "bank-transfer",
        approx: 0,
        sidebarNavigation: {
            heading: "Bank Transfer",
            subHeading: "",
            navKey: "summary"
        }
    }
};

export const checkoutRoutesArray = Object.values(checkoutRoutes);

export const routesToRedirectCheckout = checkoutRoutesArray
    .filter((routeData) => routeData.type === "checkout")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = checkoutRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = checkoutRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);
