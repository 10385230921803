import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitContactUs } from "./actions";
import CallbackUsModal from "./component";

const mapStateToProps = ({
    user: { firstName, lastName },
    carDetails: {
        content: {
            city: carLocation
        }
    }
}) => ({
    firstName,
    lastName,
    carLocation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitContactUsConnect: submitContactUs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CallbackUsModal);
