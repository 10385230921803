import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CHECK from "./image/check.svg";
import CLOSE from "./image/close.svg";
import OPEN from "./image/plus.svg";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const SeeWhatCoverd = ({warrantyPlanInfo, isVariant, screeName}) => {
    const [open, setopen] = useState(false);
    const {covered} = warrantyPlanInfo || {};

    const getEvents = () => {
        let eventName = "";
        switch (screeName) {
        case "PAYMENT_OPTIONS":
            eventName = "EW_Coverage_Details_pop_up";
            break;
        default:
            eventName = "CARS24_COVER_EW_COVERAGE_DETAILS";
        }
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS[eventName]);
    };

    const handleCovered = () => {
        setopen(!open);
        getEvents();
    };

    return (
        <div className="seeWhatMargin" styleName={isVariant ?  "styles.wrapper" : "styles.outer" }>
            <h2 onClick={handleCovered}>See what’s covered <img src={!open ? OPEN : CLOSE}/></h2>
            {open &&
            <div>
                <div styleName={"styles.section"}>
                    <p>Mechanical</p>
                    <ul>
                        {covered.Mechanical.map((item, i) => {
                            return (<li key={i}><img src={CHECK}  />{item}</li>);
                        })}
                    </ul>
                </div>
                <div styleName={"styles.section styles.margin"}>
                    <p>Electrical</p>
                    <ul>
                        {covered.Electrical.map((item, i) => {
                            return (<li key={i}><img src={CHECK}  />{item}</li>);
                        })}
                    </ul>
                </div>
                <a styleName={"styles.term-text"} href="https://drive.google.com/file/d/1ywLYzVTXImB8QJfl-J9WtXmB7DtBTIE8/view" target="__blank">View terms and conditions</a>
            </div>
            }
        </div>
    );
};

SeeWhatCoverd.propTypes = {
    warrantyPlanInfo: PropTypes.object,
    isVariant: PropTypes.bool,
    screeName: PropTypes.string
};

export default SeeWhatCoverd;
