import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    bookedTasks: [],
    error: false,
    isLoading: false
};

export const fetchBookedTasksListInitiated = (state = INITIAL_STATE) => {
    return { ...state,  isLoading: true};
};

export const fetchBookedTasksListSuccess = (state = INITIAL_STATE, {data}) => {
    return { ...state, bookedTasks: data, isLoading: false, error: false};
};

export const fetchBookedTasksListFailure = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, error: true};
};

export const HANDLERS = {
    [Types.FETCH_BOOKED_TASKS_LIST]: fetchBookedTasksListInitiated,
    [Types.FETCH_BOOKED_TASKS_LIST_SUCCESS]: fetchBookedTasksListSuccess,
    [Types.FETCH_BOOKED_TASKS_LIST_FAILURE]: fetchBookedTasksListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
