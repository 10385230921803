export const AU_MOBILE_EVENTS_IN_HOUSE_FINANCE = {
    IN_HOUSE_FINANCE_VALUE_PROP: "inHouseFinanceValueProp",
    IN_HOUSE_FINANCE_DEPOSIT_INPUT: "inHouseFinanceDepositInput",
    IN_HOUSE_FINANCE_DEPOSIT_SLIDER: "inHouseFinanceDepositSlider",
    IN_HOUSE_FINANCE_LOAN_TENURE_OPTION: "inHouseFinanceLoanTenureOption",
    IN_HOUSE_FINANCE_BALLOON_CLICK: "inHouseFinanceBalloonClick",
    IN_HOUSE_FINANCE_BALLOON_OPTION: "inHouseFinanceBalloonOption",
    IN_HOUSE_FINANCE_LOAN_TYPE: "inHouseFinanceLoanType",
    IN_HOUSE_FINANCE_LOAN_TYPE_ADDITIONAL: "inHouseFinanceLoanTypeAdditional",
    IN_HOUSE_FINANCE_NAME_DETAILS: "inHouseFinanceNameDetails",
    IN_HOUSE_FINANCE_DOB: "inHouseFinanceDOB",
    IN_HOUSE_FINANCE_RESIDENCY: "inHouseFinanceResidency",
    IN_HOUSE_FINANCE_RESIDENCY_VISA: "inHouseFinanceResidencyVisa",
    IN_HOUSE_FINANCE_ADDRESS: "inHouseFinanceAddress",
    IN_HOUSE_FINANCE_ADDRESS_TENURE: "inHouseFinanceAddressTenure",
    IN_HOUSE_FINANCE_LIVING_SITUATION: "inHouseFinanceLivingSituation",
    IN_HOUSE_FINANCE_EMPLOYMENT_STATUS: "inHouseFinanceEmploymentStatus",
    IN_HOUSE_FINANCE_SELECT_OFFER: "inHouseFinanceSelectOffer",
    IN_HOUSE_FINANCE_CUSTOMIZE: "inHouseFinanceCustomize",
    IN_HOUSE_FINANCE_REJECTION: "inHouseFinanceRejection",
    IN_HOUSE_FINANCE_ORDER_SUMMARY: "inHouseFinanceOrderSummary",
    BYOF_ORDER_SUMMARY: "byofOrderSummary",
    IN_HOUSE_FINANCE_ORDER_CONFIRMATION: "inHouseFinanceOrderConfirmation",
    IN_HOUSE_FINANCE_ORDER_CONFIRMATION_PENDING: "inHouseFinanceOrderConfirmationPending",
    IN_HOUSE_FINANCE_ORDER_CONFIRMATION_SUMMARY_POPUP: "inHouseFinanceOrderConfirmationSummaryPopup",
    IN_HOUSE_FINANCE_OFFER_SCREEN: "inHouseFinanceOfferScreen",
    IN_HOUSE_FINANCE_DEPOSIT_OPEN: "inHouseFinanceDepositOpen",
    IN_HOUSE_FINANCE_TENURE_OPEN: "inHouseFinanceTenureOpen",
    IN_HOUSE_FINANCE_BALLOON_OPEN: "inHouseFinanceBalloonOpen",
    IN_HOUSE_FINANCE_OFFER_SELECT_SCREEN: "inHouseFinanceOfferSelectScreen",
    IN_HOUSE_FINANCE_PRE_APPROVED_SCREEN: "inHouseFinancePreApprovedScreen",
    LOAN_OFFERS: "inHouseFinanceLoanOffersScreen",
    PRE_APPROVAL_RESULT: "preApprovalResultScreen",
    IN_HOUSE_OFFER_SCREEN: "inHouseOfferScreen",
    IN_HOUSE_PAYMENT_SCREEN: "inHousePaymentSummaryScreen"
};

export default {
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_VALUE_PROP]: {
        event: "custom_event",
        eventCategory: "In_house_finance_value_prop",
        eventAction: "cta_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_DEPOSIT_INPUT]: {
        event: "custom_event",
        eventCategory: "In_house_finance_deposit",
        eventAction: "deposit_input"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_DEPOSIT_SLIDER]: {
        event: "custom_event",
        eventCategory: "In_house_finance_deposit",
        eventAction: "deposit_slider"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LOAN_TENURE_OPTION]: {
        event: "custom_event",
        eventCategory: "In_house_finance_loan_tenure",
        eventAction: "option_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_BALLOON_CLICK]: {
        event: "custom_event",
        eventCategory: "In_house_finance_balloon",
        eventAction: "cta_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_BALLOON_OPTION]: {
        event: "custom_event",
        eventCategory: "In_house_finance_balloon",
        eventAction: "balloon_amount_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LOAN_TYPE]: {
        event: "custom_event",
        eventCategory: "In_house_finance_business_purpose",
        eventAction: "option_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LOAN_TYPE_ADDITIONAL]: {
        event: "custom_event",
        eventCategory: "In_house_finance_business_purpose",
        eventAction: "business_yes_additional_questions"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_NAME_DETAILS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_personal_detail",
        eventAction: "Name_details_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_DOB]: {
        event: "custom_event",
        eventCategory: "In_house_finance_DOB",
        eventAction: "DOB_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_RESIDENCY]: {
        event: "custom_event",
        eventCategory: "In_house_finance_residency_status",
        eventAction: "option_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_RESIDENCY_VISA]: {
        event: "custom_event",
        eventCategory: "In_house_finance_residency_status",
        eventAction: "visa_class_number"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ADDRESS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_address",
        eventAction: "Address_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ADDRESS_TENURE]: {
        event: "custom_event",
        eventCategory: "In_house_finance_address_length",
        eventAction: "Tenure_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LIVING_SITUATION]: {
        event: "custom_event",
        eventCategory: "In_house_finance_living_situation",
        eventAction: "option_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_EMPLOYMENT_STATUS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_employment_status",
        eventAction: "option_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_SELECT_OFFER]: {
        event: "custom_event",
        eventCategory: "In_house_finance_pre_approved",
        eventAction: "cta_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_CUSTOMIZE]: {
        event: "custom_event",
        eventCategory: "In_house_finance_customize",
        eventAction: "variable_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_REJECTION]: {
        event: "custom_event",
        eventCategory: "In_house_finance_rejection",
        eventAction: "cta_selected"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "In_house_finance_order_summary"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.BYOF_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Byof_order_summary"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ORDER_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "In_house_finance_order_confirmation"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ORDER_CONFIRMATION_PENDING]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "In_house_finance_order_confirmation_pending" // TODO @Raghav !!!
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_ORDER_CONFIRMATION_SUMMARY_POPUP]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "In_house_finance_order_summary_post_payment"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_PRE_APPROVED_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "in_house_finance_pre_approved_screen"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.LOAN_OFFERS]: {
        event: "custom_event",
        eventCategory: "Loan offers"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.PRE_APPROVAL_RESULT]: {
        event: "custom_event",
        eventCategory: "Pre approval result"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_OFFER_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "offers_screen"
    },
    [AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_PAYMENT_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "inhouse_order_summary_screen"
    }
};
