import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImage = (props) => {
    return (
        <React.Fragment>
            <LazyLoadImage
                effect="blur"
                {...props}
            />
            <noscript>
                <img {...props} />
            </noscript>
        </React.Fragment>
    );
};

export default LazyImage;
