/* eslint-disable react/jsx-key */
import React, { Fragment } from "react";
import styles from "./styles.css";
import { TEST_DRIVE_CHECKOUT_STEPS } from "./constants";
import PropTypes from "prop-types";
// import { ORDER_STATUS } from "../../../constants/checkout-constants";
import { testDriveRoutes } from "../../../../constants/au.desktop/test-drive-routes";
import { getTestDriveCheckoutURL } from "../../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router";
import { postOrderBookingConfirmed } from "../../../../utils/helpers/post-order-placed-confirmed";

const BookingSteps = ({ order, currentStep, content }) => {
    const history = useHistory();
    const { status } = order || {};
    const progressItems = TEST_DRIVE_CHECKOUT_STEPS;
    const currentRoute = testDriveRoutes[currentStep];

    const getClass = (item, index) => {
        if (currentRoute && index < progressItems.indexOf(currentRoute.sidebarNavigation.navKey)) {
            return  "styles.active";
        }
        if (currentRoute && item === currentRoute.sidebarNavigation.navKey) {
            return "styles.current";
        }
        return "";
    };

    const onTabClick = (item) => {
        if (!currentRoute.postBookingRoute) {
            const { relativeURL } = getTestDriveCheckoutURL(content, testDriveRoutes[item].route);
            history.push(relativeURL);
        }
    };

    return (
        <React.Fragment>
            <ul styleName={"styles.stepperWrap"}>
                {
                    progressItems.map((item, index) => {
                        const styleString = getClass(item, index);
                        return (
                            <li key={index} styleName={styleString}>
                                {!postOrderBookingConfirmed(status) && styleString &&
                                    <a
                                        onClick={() => onTabClick(item)}
                                    >
                                        <span styleName={"styles.heading"}>{testDriveRoutes[item].sidebarNavigation.heading}</span>
                                        <span styleName={"styles.subHeading"}>{testDriveRoutes[item].sidebarNavigation.subHeading}</span>
                                    </a>
                                }
                                {postOrderBookingConfirmed(status) && styleString &&
                                    <Fragment>
                                        <span styleName={"styles.heading"}>{testDriveRoutes[item].sidebarNavigation.heading}</span>
                                        <span styleName={"styles.subHeading"}>{testDriveRoutes[item].sidebarNavigation.subHeading}</span>
                                    </Fragment>
                                }
                                {!styleString &&
                                    <Fragment>
                                        <span styleName={"styles.heading"}>{testDriveRoutes[item].sidebarNavigation.heading}</span>
                                        <span styleName={"styles.subHeading"}>{testDriveRoutes[item].sidebarNavigation.subHeading}</span>
                                    </Fragment>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </React.Fragment>
    );
};

BookingSteps.propTypes = {
    order: PropTypes.object,
    history: PropTypes.object,
    content: PropTypes.object,
    currentStepIndex: PropTypes.number,
    currentStep: PropTypes.number,
    isOrderDetailLoading: PropTypes.bool,
    isFullPaymentDone: PropTypes.bool
};
export default BookingSteps;
