import React from "react";
import styles from "./styles.css";
import Info from "./images/info.svg";
import PropTypes from "prop-types";

const ReservingYourCarCard = ({heading, subHeading}) => {
    return (
        <div styleName={"styles.outer"}>
            <div className="media">
                <img src={Info} />
                <div className="media-body">
                    {heading && <p styleName={"styles.heading"}>{heading || ""}</p>}
                    <p styleName={"styles.subHeading"}>{subHeading || ""}</p>
                </div>
            </div>
        </div>
    );
};

ReservingYourCarCard.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string
};

export default ReservingYourCarCard;
