/* eslint-disable max-statements */
/* eslint-disable complexity */
import { NUMBER } from "../../constants/app-constants";
import { BOOLEAN_OPTIONS_FORM, RELATIONSHIP_STATUS, NUMBER_OPTIONS, BUSINESS_TYPE, BUSINESS_TYPE_VALUE, RELATIONSHIP_STATUS_OPTIONS, BALLOON_REASONS_OPTIONS, BALLOON_REPAYMENT_METHODS_OPTIONS } from "../../constants/checkout-constants";
import { getNumberOptions, getOptions } from "../../utils/helpers/checkout-util";
import { MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS } from "../../utils/validators/mobile-number-validator";
import * as Yup from "yup";
import { getFinanceLendersPrivacyDiscliamers } from "../../utils/helpers/finance-loan-application-util";

const v1CommercialResetQuestions = ["otherSourceOfIncome"];
const V1_COMMERCIAL_CONFIG = ({ components = {}, formData }) => {
    const { previousAddress, currentAddressDuration, previousAddressDuration, relationshipStatus = "", businessType, lenderName, balloon } =  formData || {};
    let { personalLoans = {}, mortgage = {}, directors = {}, vehicleAddress = {}, abnRegistrationDetails = {}, partners = {}, businessAddress = {}, investmentInProperty = {}, creditCards = {}, companySecretary = {}, guarantorSecretary = {}} = formData || {};
    personalLoans = personalLoans || {};
    mortgage = mortgage || {};
    directors = directors || {};
    companySecretary = companySecretary || {};
    businessAddress = businessAddress || {};
    vehicleAddress = vehicleAddress || {};
    companySecretary = companySecretary || {};
    guarantorSecretary = guarantorSecretary || {};
    abnRegistrationDetails = abnRegistrationDetails || {};
    partners = partners || {};
    investmentInProperty = investmentInProperty || {};
    creditCards = creditCards || {};
    const { noOfPartner, partnerDetails } = partners;
    const { noOfDirector, directorDetails } = directors;
    /* creating copies to keep the old data */
    const partnerDetailsInfo =  [...(partnerDetails || [])];
    const directorDetailsInfo =  [...(directorDetails || [])];
    formData = {
        ...formData,
        partners: {
            ...partners,
            noOfPartner,
            partnerDetails:
                (partnerDetails || []).length ?
                    (partnerDetailsInfo || []).slice(0, noOfPartner) :
                    Array(noOfPartner || 0).fill(null)
        },
        directors: {
            ...directors,
            noOfDirector,
            directorDetails:
                (directorDetails || []).length ?
                    (directorDetailsInfo || []).slice(0, noOfDirector) :
                    Array(noOfDirector || 0).fill(null)
        }
    };
    const {questionLabel: privacyPolicyLabel, apiKey: privacyPolicyKey } = getFinanceLendersPrivacyDiscliamers(lenderName) || {};
    const showCompanySecratoryDetails = () => {
        /* show question only if 1st director company secretary or 2nd director company secretary */
        if (businessType === BUSINESS_TYPE_VALUE.COMPANY && directors.noOfDirector && Array.isArray(directors.directorDetails) && (((directors.directorDetails[0] || {}).companySecretary) || ((directors.directorDetails[1] || {}).companySecretary))) {
            return true;
        }
        return false;
    };
    const currentAddressLessThanTwo = ((currentAddressDuration || {}).year < NUMBER.TWO);
    const configObj =  {
        formId: "v1_commercial",
        totalStages: 5,
        formTitle: `${lenderName} loan application`,
        readOnlyTitle: "Review your details",
        stages: [
            {
                name: "Additional personal details",
                isReadDisabled: false,
                intermediateScreenText: "Let’s add your personal details now",
                stageNo: 1,
                totalPages: 1,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            if (currentAddressLessThanTwo) return currentAddressDuration && previousAddressDuration;
                            return currentAddressDuration;
                        },
                        questions: [
                            {
                                questionLabel: "Duration of stay at current address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "currentAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address year${(currentAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("year", 0, NUMBER.THIRTY)
                                    }
                                }, {
                                    apiKey: "currentAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at current address month${(currentAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        isSearchable: false,
                                        data: getOptions("month", 0, NUMBER.ELEVEN)
                                    }
                                }]
                            },
                            {
                                questionLabel: "Address of previous stay",
                                helperText: null,
                                isRequired: true,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "previousAddress",
                                    errorKey: "previousAddress.full",
                                    fieldType: components.locationPicker,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: true,
                                    props: {
                                        value: previousAddress
                                    }
                                }]
                            },
                            {
                                questionLabel: "Duration of stay at previous address",
                                isRequired: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isVisible: currentAddressLessThanTwo,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "previousAddressDuration.year",
                                    fieldType: components.select,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address year${(previousAddressDuration || {}).year > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "previousAddressDuration.month",
                                    fieldType: components.select,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    // validator: (data) => !data,
                                    validationText: "Please enter a valid input",
                                    shortTitle: `Time at previous address month${(previousAddressDuration || {}).month > 0 ? "s" : ""}`,
                                    isRequired: true,
                                    isVisible: true,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            currentAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                            }),
                            ...currentAddressLessThanTwo && ({
                                previousAddress: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    full: Yup.string().required("Enter valid input").nullable("Enter valid input")
                                }),
                                previousAddressDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    year: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.THIRTY, `Please enter a valid input`).nullable("Please enter a valid input"),
                                    month: Yup.number().required(`Please enter a valid input`).min(NUMBER.ZERO, `Please enter a valid input`).max(NUMBER.ELEVEN, `Please enter a valid input`).nullable("Please enter a valid input")
                                })
                            })
                        }),
                        initialValues: {
                            currentAddressDuration: formData.currentAddressDuration,
                            previousAddressDuration: formData.previousAddressDuration,
                            previousAddress: formData.previousAddress
                        }
                    }
                ],
                sidebarNavigation: {
                    key: "personalDetails",
                    title: "Personal details",
                    description: "Add personal details"
                }
            },
            {
                name: "Business information",
                isReadDisabled: false,
                intermediateScreenText: "Let’s add your business information now",
                stageNo: 2,
                totalPages: 12,
                jumpToNextStageNo: () => {
                    if (businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER) {
                        return NUMBER.FIVE;
                    }
                    return NUMBER.THREE;
                },
                sidebarNavigation: {
                    key: "businessInformation",
                    title: "Business information",
                    description: "Add business information"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { businessName }  = formData || {};
                            return !!(businessType && (businessType === BUSINESS_TYPE_VALUE.SOLE_TRADER ? businessName : true));
                        },
                        questions: [
                            {
                                questionLabel: "Your business type",
                                questionHelperLabel: "Filled based on inputs shared in your pre-approval form",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "businessType",
                                    shortTitle: "Business type",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: BUSINESS_TYPE,
                                    placeholder: "Business type",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BUSINESS_TYPE,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Your business name",
                                questionHelperLabel: "If you are a sole trader and do not have a business name, please skip this question.",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "businessName",
                                    shortTitle: "Business name",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Business name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            businessType: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                            businessName: Yup.string().required("Enter valid input").nullable("Enter valid input")
                        }),
                        initialValues: {
                            businessType: formData.businessType,
                            businessName: formData.businessName
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            return !!abnRegistrationDetails.abnNumber;
                        },
                        questions: [
                            {
                                questionLabel: "Your ABN Number",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "abnRegistrationDetails.abnNumber",
                                    shortTitle: "ABN number",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter ABN Number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            },
                            {
                                questionLabel: "Industry",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "abnRegistrationDetails.industry",
                                    shortTitle: "Industry",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter Industry Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Length of ABN Duration",
                                questionHelperLabel: null,
                                helperText: "Filled based on inputs shared in your pre-approval form",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "abnRegistrationDetails.abnDuration.year",
                                    shortTitle: "ABN duration years",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "abnRegistrationDetails.abnDuration.month",
                                    shortTitle: "ABN duration months",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }, {
                                questionLabel: "Length of GST Duration",
                                questionHelperLabel: null,
                                helperText: "Filled based on inputs shared in your pre-approval form",
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: "postPaymentForms",
                                fields: [{
                                    apiKey: "abnRegistrationDetails.gstDuration.year",
                                    shortTitle: "GST duration years",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("year", 0, NUMBER.THIRTY),
                                    placeholder: "Select years",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.THIRTY),
                                        isSearchable: false,
                                        convertLabelAs: "year"
                                    }
                                }, {
                                    apiKey: "abnRegistrationDetails.gstDuration.month",
                                    shortTitle: "GST duration months",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            abnRegistrationDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                abnNumber: Yup.string().required(`Enter valid input`).nullable("Enter valid input"),
                                industry: Yup.string(`Enter valid input`).nullable(),
                                abnDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    year: Yup.number().required(`Enter valid input`).min(NUMBER.ZERO, `Enter valid input`).max(NUMBER.THIRTY, `Enter valid input`).nullable("Enter valid input"),
                                    month: Yup.number().required(`Enter valid input`).min(NUMBER.ZERO, `Enter valid input`).max(NUMBER.ELEVEN, `Enter valid input`).nullable("Enter valid input")
                                }),
                                gstDuration: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                    year: Yup.number().required(`Enter valid input`).min(NUMBER.ZERO, `Enter valid input`).max((abnRegistrationDetails.abnDuration || {}).year, `GST Duration should be <=ABN Duration. Enter valid input`).nullable("Enter valid input"),
                                    month: Yup.number().required(`Enter valid input`).min(NUMBER.ZERO, `Enter valid input`).max((abnRegistrationDetails.abnDuration || {}).year === (abnRegistrationDetails.gstDuration || {}).year ? (abnRegistrationDetails.abnDuration || {}).month : NUMBER.ELEVEN, `GST Duration should be <=ABN Duration. Enter valid input`).nullable("Enter valid input")
                                })
                            })
                        }),
                        initialValues: {
                            abnRegistrationDetails: formData.abnRegistrationDetails
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            const { isProposedGuarantor } = formData || {};
                            return isProposedGuarantor === true || isProposedGuarantor === false;
                        },
                        questions: [
                            {
                                questionLabel: "Do you / proposed guarantor own property?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "isProposedGuarantor",
                                    shortTitle: "Guarantor's prop ownership (Y/N)",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            isProposedGuarantor: Yup.boolean().required("Enter valid input").nullable()
                        }),
                        initialValues: {
                            isProposedGuarantor: formData.isProposedGuarantor
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 4,
                        isCompleted: () => {
                            return !!businessAddress.postCode;
                        },
                        questions: [
                            {
                                questionLabel: "Principal address of the business",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "businessAddress.addressLine1",
                                    fieldType: components.inputText,
                                    title: "Address line 1",
                                    shortTitle: "Principal address line 1",
                                    isRequired: true,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Address line 1",
                                    props: {}
                                }, {
                                    apiKey: "businessAddress.addressLine2",
                                    fieldType: components.inputText,
                                    title: "Address line 2",
                                    shortTitle: "Principal address line 2",
                                    isRequired: false,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Address line 2",
                                    props: {}
                                }, {
                                    apiKey: "businessAddress.postCode",
                                    fieldType: components.pincodeSearch,
                                    shortTitle: "Prin. address - ZIP",
                                    title: "Postal / Zip Code",
                                    isRequired: true,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Postal / Zip Code",
                                    props: {
                                        suburbDataKey: "businessAddressSuburbData",
                                        stateKey: "businessAddress.state",
                                        suburbKey: "businessAddress.city",
                                        maxLength: 4
                                    }
                                }, {
                                    apiKey: "businessAddress.city",
                                    fieldType: formData.businessAddressSuburbData ? components.select : components.inputText,
                                    title: "Suburb",
                                    shortTitle: "Prin. address - Suburb",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "Suburb",
                                    props: {
                                        data: formData.businessAddressSuburbData,
                                        isSearchable: false
                                    }
                                }, {
                                    apiKey: "businessAddress.state",
                                    fieldType: components.inputText,
                                    title: "State / Province / Region",
                                    shortTitle: "Prin. address - state",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "State / Province / Region",
                                    props: {}
                                }]

                            }
                        ], formValidator: Yup.object().shape({
                            businessAddress: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                addressLine1: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                addressLine2: Yup.string(`Enter valid input`).nullable("Enter valid input"),
                                city: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                state: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                postCode: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable()
                            })
                        }),
                        initialValues: {
                            businessAddress: formData.businessAddress
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 5,
                        jumpToNextPageNo: () => {
                            if (businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST) {
                                return NUMBER.SIX;
                            } else if (businessType === BUSINESS_TYPE_VALUE.COMPANY) {
                                return NUMBER.SEVEN;
                            } else if (businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST) {
                                return NUMBER.NINE;
                            }
                            return NUMBER.TEN;
                        },
                        isCompleted: () => {
                            const { revenueDetails }  = formData || {};
                            const { monthlyRevenue, turnOver } = revenueDetails  || {};
                            return businessType === BUSINESS_TYPE_VALUE.SOLE_TRADER ? typeof monthlyRevenue === "number" : typeof turnOver === "number";
                        },
                        questions: [
                            {
                                questionLabel: "Average monthly revenue",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: businessType === BUSINESS_TYPE_VALUE.SOLE_TRADER,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "revenueDetails.monthlyRevenue",
                                    shortTitle: "Average monthly revenue",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Monthly revenue",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Average montly expense",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: businessType === BUSINESS_TYPE_VALUE.SOLE_TRADER,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "revenueDetails.monthlyExpense",
                                    shortTitle: "Average montly expense",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Monthly expense",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Annual turnover",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "revenueDetails.turnOver",
                                    shortTitle: "Annual turnover",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Annual turnover",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            revenueDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).when("businessType", {
                                is: () => businessType === BUSINESS_TYPE_VALUE.SOLE_TRADER,
                                then: Yup.object().shape({
                                    monthlyRevenue: Yup.number().required(`Enter valid input`).nullable("Enter valid input"),
                                    monthlyExpense: Yup.number().required(`Enter valid input`).nullable("Enter valid input")
                                }),
                                otherwise: Yup.object().shape({
                                    turnOver: Yup.number().required(`Enter valid input`).nullable("Enter valid input")
                                })
                            })
                        }),
                        initialValues: {
                            revenueDetails: formData.revenueDetails
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 6,
                        isHidden: !(businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST),
                        jumpToNextPageNo: () => {
                            if (businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST) {
                                return NUMBER.NINE;
                            }
                            return NUMBER.TEN;
                        },
                        isCompleted: () => {
                            return !!partners.noOfPartner;
                        },
                        questions: [
                            {
                                questionLabel: "Number of partners/trustees for this company",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.noOfPartner",
                                    shortTitle: "# Partners",
                                    fieldType: components.increementDecreeement,
                                    title: null,
                                    isVisible: true,
                                    options: getNumberOptions(NUMBER.ONE, NUMBER.THREE),
                                    placeholder: "# Partners",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getNumberOptions(NUMBER.ONE, NUMBER.THREE),
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 1,
                                        minError: "# partners / trustees should be >=1",
                                        maxError: "# partners / trustees should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Partner 1 / Trustee 1 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[0].firstName",
                                    shortTitle: "Partner 1 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: partners.noOfPartner > 0,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[0].middleName",
                                    shortTitle: "Partner 1 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: partners.noOfPartner > 0,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[0].lastName",
                                    shortTitle: "Partner 1 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: partners.noOfPartner > 0,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Partner 1 / Trustee 1 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[0].emailId",
                                    shortTitle: "Partner 1 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: partners.noOfPartner > 0,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[0].mobileNumber",
                                    shortTitle: "Partner 1 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: partners.noOfPartner > 0,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }]
                            }, {
                                questionLabel: "Partner 2 / Trustee 2 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[1].firstName",
                                    shortTitle: "Partner 2 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: partners.noOfPartner >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[1].middleName",
                                    shortTitle: "Partner 2 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: partners.noOfPartner >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[1].lastName",
                                    shortTitle: "Partner 2 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: partners.noOfPartner >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Partner 2 / Trustee 2 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[1].emailId",
                                    shortTitle: "Partner 2 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: partners.noOfPartner >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[1].mobileNumber",
                                    shortTitle: "Partner 2 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: partners.noOfPartner >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }]
                            }, {
                                questionLabel: "Partner 3 / Trustee 3 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[2].firstName",
                                    shortTitle: "Partner 3 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: partners.noOfPartner >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[2].middleName",
                                    shortTitle: "Partner 3 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: partners.noOfPartner >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[2].lastName",
                                    shortTitle: "Partner 3 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: partners.noOfPartner >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Partner 3 / Trustee 3 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: partners.noOfPartner >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "partners.partnerDetails[2].emailId",
                                    shortTitle: "Partner 3 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: partners.noOfPartner >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "partners.partnerDetails[2].mobileNumber",
                                    shortTitle: "Partner 3 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: partners.noOfPartner >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            partners: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                noOfPartner: Yup.number().min(1, "# partners / trustees should be >=1").max(NUMBER.THREE, "# partners / trustees should not exceed 3").required(`Enter valid input`).nullable("Enter valid input"),
                                partnerDetails: Yup.array()
                                    .transform((value, originalValue) => (noOfPartner > originalValue.length) ? [...originalValue, ...Array(noOfPartner - (originalValue || []).length).fill({})] : originalValue)
                                    .of(Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                        firstName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        middleName: Yup.string().nullable(),
                                        lastName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        emailId: Yup.string().email(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                        mobileNumber: Yup.string().matches(MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS, "Enter valid input").required("Enter valid input").nullable("Enter valid input")
                                    }))
                            })
                        }),
                        initialValues: {
                            partners: {
                                ...formData.partners,
                                noOfPartner: ((partners && partners.noOfPartner) || 1)
                            }
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 7,
                        isHidden: (businessType !== BUSINESS_TYPE_VALUE.COMPANY),
                        isCompleted: () => {
                            return !!directors.noOfDirector;
                        },
                        jumpToNextPageNo: () => {
                            if (showCompanySecratoryDetails()) {
                                return NUMBER.EIGHT;
                            }
                            return NUMBER.NINE;
                        },
                        jumpToPrevPageNo: () => {
                            return NUMBER.FIVE;
                        },
                        questions: [
                            {
                                questionLabel: "Number of directors for this company",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.noOfDirector",
                                    shortTitle: "# Directors",
                                    fieldType: components.increementDecreeement,
                                    title: null,
                                    isVisible: true,
                                    options: getNumberOptions(NUMBER.ONE, NUMBER.THREE),
                                    placeholder: "# Directors",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: getNumberOptions(NUMBER.ONE, NUMBER.THREE),
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 1,
                                        minError: "# directors should be >=1",
                                        maxError: "# directors should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Director 1 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector >= NUMBER.ONE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[0].firstName",
                                    shortTitle: "Director 1 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[0].middleName",
                                    shortTitle: "Director 1 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[0].lastName",
                                    shortTitle: "Director 1 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Director 1 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector >= NUMBER.ONE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[0].emailId",
                                    shortTitle: "Director 1 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[0].mobileNumber",
                                    shortTitle: "Director 1 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }, {
                                    apiKey: "directors.directorDetails[0].companySecretary",
                                    shortTitle: "Director 1 company secretary (Y/N)",
                                    fieldType: components.listTabGroup,
                                    title: "Is this director also the company secretary?",
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Director 2 / Trustee 2 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[1].firstName",
                                    shortTitle: "Director 2 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[1].middleName",
                                    shortTitle: "Director 2 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[1].lastName",
                                    shortTitle: "Director 2 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Director 2 / Trustee 2 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[1].emailId",
                                    shortTitle: "Director 2 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[1].mobileNumber",
                                    shortTitle: "Director 2 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }, {
                                    apiKey: "directors.directorDetails[1].companySecretary",
                                    shortTitle: "Director 2 company secretary (Y/N)",
                                    fieldType: components.listTabGroup,
                                    title: "Is this director also the company secretary?",
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Director 3 / Trustee 3 - Name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector === NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[2].firstName",
                                    shortTitle: "Director 3 - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[2].middleName",
                                    shortTitle: "Director 3 - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[2].lastName",
                                    shortTitle: "Director 3 - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Director 3 / Trustee 3 - Other Details",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: directors.noOfDirector === NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "directors.directorDetails[2].emailId",
                                    shortTitle: "Director 3 - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "directors.directorDetails[2].mobileNumber",
                                    shortTitle: "Director 3 - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }, {
                                    apiKey: "directors.directorDetails[2].companySecretary",
                                    shortTitle: "Director 3 company secretary (Y/N)",
                                    fieldType: components.listTabGroup,
                                    title: "Is this director also the company secretary?",
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            directors: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                noOfDirector: Yup.number().min(1, "# directors should be >=1").max(NUMBER.THREE, "# directors should not exceed 3").required(`Enter valid input`).required(`Enter valid input`).required(`Enter valid input`),
                                directorDetails: Yup.array()
                                    .transform((value, originalValue) => (noOfDirector > originalValue.length) ? [...originalValue, ...Array(directors.noOfDirector - (originalValue || []).length).fill({})] : originalValue)
                                    .of(Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                        firstName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        middleName: Yup.string().nullable(),
                                        lastName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        emailId: Yup.string().email(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                        mobileNumber: Yup.string().matches(MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS, "Enter valid input").required("Enter valid input").nullable("Enter valid input"),
                                        companySecretary: Yup.boolean().required("Enter valid input")
                                    }))
                            })
                        }),
                        initialValues: {
                            directors: {
                                ...formData.directors,
                                noOfDirector: ((directors && directors.noOfDirector) || 1)
                            }
                        }
                    },  {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 8,
                        isHidden: (businessType !== BUSINESS_TYPE_VALUE.COMPANY),
                        isCompleted: () => {
                            return !!companySecretary.firstName;
                        },
                        questions: [{
                            questionLabel: "Company Secretary details - Name",
                            questionHelperLabel: null,
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: showCompanySecratoryDetails(),
                            fieldStyle: null,
                            fields: [{
                                apiKey: "companySecretary.firstName",
                                shortTitle: "Comp. Secretary - First Name",
                                fieldType: components.inputText,
                                title: "First Name",
                                isVisible: true,
                                options: [],
                                placeholder: "First Name",
                                isRequired: true,
                                priceType: false,
                                props: {}
                            }, {
                                apiKey: "companySecretary.middleName",
                                shortTitle: "Comp. Secretary - Middle Name",
                                fieldType: components.inputText,
                                title: "Middle Name",
                                isVisible: true,
                                options: [],
                                placeholder: "Middle Name",
                                isRequired: false,
                                priceType: false,
                                props: {}
                            }, {
                                apiKey: "companySecretary.lastName",
                                shortTitle: "Comp. Secretary - Last Name",
                                fieldType: components.inputText,
                                title: "Last Name",
                                isVisible: true,
                                options: [],
                                placeholder: "Last Name",
                                isRequired: true,
                                priceType: false,
                                props: {}
                            }]
                        }, {
                            questionLabel: "Company Secretary details - Other details",
                            questionHelperLabel: null,
                            helperText: null,
                            inFormNudge: null,
                            isRequired: true,
                            isVisible: showCompanySecratoryDetails(),
                            fieldStyle: null,
                            fields: [{
                                apiKey: "companySecretary.emailId",
                                shortTitle: "Comp. Secretary - Email ID",
                                fieldType: components.inputText,
                                title: "Email ID",
                                isVisible: true,
                                options: [],
                                placeholder: "Email ID",
                                isRequired: true,
                                priceType: false,
                                props: {}
                            }, {
                                apiKey: "companySecretary.mobileNumber",
                                shortTitle: "Comp. Secretary - Mobile number",
                                fieldType: components.inputNumber,
                                title: "Mobile number",
                                isVisible: true,
                                options: [],
                                placeholder: "Mobile number",
                                isRequired: true,
                                priceType: false,
                                props: {
                                    type: "number"
                                }
                            }]
                        }
                        ],
                        formValidator: Yup.object().shape({
                            companySecretary: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                firstName: Yup.string().required(`Enter valid input`).nullable("Enter valid input"),
                                middleName: Yup.string(`Enter valid input`).nullable(),
                                lastName: Yup.string(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                emailId: Yup.string().email(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                mobileNumber: Yup.string(`Enter valid input`).matches(MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS, `Enter valid input`).required("Enter valid input").nullable("Enter valid input")
                            })
                        }),
                        initialValues: {
                            companySecretary: formData.companySecretary
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 9,
                        isHidden: !(businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST),
                        isCompleted: () => {
                            return !!guarantorSecretary.firstName;
                        },
                        jumpToPrevPageNo: () => {
                            if (showCompanySecratoryDetails()) {
                                return NUMBER.EIGHT;
                            } else if (businessType === BUSINESS_TYPE_VALUE.COMPANY) {
                                return NUMBER.SEVEN;
                            }
                            return NUMBER.SIX;
                        },
                        questions: [
                            {
                                questionLabel: "Guarantor details - Name",
                                questionHelperLabel: "Only directors, partners and trustees are allowed to be guarantors",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "guarantorSecretary.firstName",
                                    shortTitle: "Guarantor - First Name",
                                    fieldType: components.inputText,
                                    title: "First Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "First Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "guarantorSecretary.middleName",
                                    shortTitle: "Guarantor - Middle Name",
                                    fieldType: components.inputText,
                                    title: "Middle Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Middle Name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "guarantorSecretary.lastName",
                                    shortTitle: "Guarantor - Last Name",
                                    fieldType: components.inputText,
                                    title: "Last Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Last Name",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Guarantor details - Other details",
                                questionHelperLabel: "Only directors, partners and trustees are allowed to be guarantors",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP || businessType === BUSINESS_TYPE_VALUE.TRUST,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "guarantorSecretary.emailId",
                                    shortTitle: "Guarantor - Email ID",
                                    fieldType: components.inputText,
                                    title: "Email ID",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Email ID",
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "guarantorSecretary.mobileNumber",
                                    shortTitle: "Guarantor - Mobile number",
                                    fieldType: components.inputNumber,
                                    title: "Mobile number",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Mobile number",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        type: "number"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            guarantorSecretary: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                firstName: Yup.string().required(`Enter valid input`).nullable("Enter valid input"),
                                middleName: Yup.string(`Enter valid input`).nullable(),
                                lastName: Yup.string(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                emailId: Yup.string().email(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                mobileNumber: Yup.string(`Enter valid input`).matches(MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS, `Enter valid input`).required("Enter valid input").nullable("Enter valid input")
                            })
                        }),
                        initialValues: {
                            guarantorSecretary: formData.guarantorSecretary
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 10,
                        isCompleted: () => {
                            return !!vehicleAddress.postCode;
                        },
                        jumpToPrevPageNo: () => {
                            if (businessType === BUSINESS_TYPE_VALUE.COMPANY || businessType === BUSINESS_TYPE_VALUE.TRUST || businessType === BUSINESS_TYPE_VALUE.PARTNERSHIP) {
                                return NUMBER.NINE;
                            } else if (showCompanySecratoryDetails()) {
                                return NUMBER.EIGHT;
                            }
                            return NUMBER.FIVE;
                        },
                        questions: [
                            {
                                questionLabel: "Address at which the vehicle will be kept",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "vehicleAddress.addressLine1",
                                    fieldType: components.inputText,
                                    shortTitle: "Vehicle address line 1",
                                    title: "Address line 1",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "Address line 1",
                                    props: {}
                                }, {
                                    apiKey: "vehicleAddress.addressLine2",
                                    fieldType: components.inputText,
                                    shortTitle: "Vehicle address line 2",
                                    title: "Address line 2",
                                    isVisible: true,
                                    isRequired: false,
                                    options: [],
                                    placeholder: "Address line 2",
                                    props: {}
                                }, {
                                    apiKey: "vehicleAddress.postCode",
                                    fieldType: components.pincodeSearch,
                                    shortTitle: "Vehicle address - ZIP",
                                    title: "Postal / Zip Code",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "Postal / Zip Code",
                                    props: {
                                        suburbDataKey: "vehicleAddressSuburbData",
                                        stateKey: "vehicleAddress.state",
                                        suburbKey: "vehicleAddress.city",
                                        maxLength: 4
                                    }
                                }, {
                                    apiKey: "vehicleAddress.city",
                                    fieldType: formData.vehicleAddressSuburbData ? components.select : components.inputText,
                                    shortTitle: "Vehicle address - Suburb",
                                    title: "Suburb",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "City",
                                    props: {
                                        data: formData.vehicleAddressSuburbData,
                                        isSearchable: false
                                    }
                                }, {
                                    apiKey: "vehicleAddress.state",
                                    fieldType: components.inputText,
                                    shortTitle: "Vehicle address - state",
                                    title: "State / Province / Region",
                                    isVisible: true,
                                    isRequired: true,
                                    options: [],
                                    placeholder: "State / Province / Region",
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            vehicleAddress: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                addressLine1: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable("Enter valid input"),
                                addressLine2: Yup.string(`Enter valid input`).nullable("Enter valid input"),
                                city: Yup.string().required(`Enter valid input`).nullable("Enter valid input"),
                                state: Yup.string().required(`Enter valid input`).nullable("Enter valid input"),
                                postCode: Yup.string().required(`Enter valid input`).required(`Enter valid input`).nullable()
                            })
                        }),
                        initialValues: {
                            vehicleAddress: formData.vehicleAddress
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 11,
                        isCompleted: () => {
                            const { isVehicleForLeasingOrHiring } = formData || {};
                            return isVehicleForLeasingOrHiring === true || isVehicleForLeasingOrHiring === false;
                        },
                        questions: [
                            {
                                questionLabel: "Is the vehicle intended for leasing or hiring to a third party?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "isVehicleForLeasingOrHiring",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Vehicle leasing (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]

                            }, {
                                questionLabel: "Will the vehicle be used for rideshare, taxi or limousine services?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "isVehicleForTaxi",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Vehicle taxi (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]

                            }
                        ],
                        formValidator: Yup.object().shape({
                            isVehicleForLeasingOrHiring: Yup.boolean().required("Enter valid input").nullable(),
                            isVehicleForTaxi: Yup.boolean().required("Enter valid input").nullable()
                        }),
                        initialValues: {
                            isVehicleForLeasingOrHiring: formData.isVehicleForLeasingOrHiring,
                            isVehicleForTaxi: formData.isVehicleForTaxi
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 12,
                        isCompleted: () => {
                            return abnRegistrationDetails.haveAbnPriorToThis === true || abnRegistrationDetails.haveAbnPriorToThis === false;
                        },
                        questions: [
                            {
                                questionLabel: "Do you have an ABN prior to this?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "abnRegistrationDetails.haveAbnPriorToThis",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Prior ABN (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]

                            }, {
                                questionLabel: "Your previous business name",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: abnRegistrationDetails.haveAbnPriorToThis,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "abnRegistrationDetails.previousBusinessName",
                                    fieldType: components.inputText,
                                    shortTitle: "Prev. business name",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Prev. business name",
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false
                                    }
                                }]
                            }, {
                                questionLabel: "Your previous business ABN",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                fieldStyle: null,
                                isVisible: abnRegistrationDetails.haveAbnPriorToThis,
                                fields: [{
                                    apiKey: "abnRegistrationDetails.previousBusinessAbn",
                                    fieldType: components.inputText,
                                    shortTitle: "Prev. business ABN",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Prev. business ABN",
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false
                                    }
                                }]

                            }
                        ],
                        formValidator: Yup.object().shape({
                            abnRegistrationDetails: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                haveAbnPriorToThis: Yup.boolean().required("Enter valid input").nullable()
                            })
                        }),
                        initialValues: {
                            abnRegistrationDetails: formData.abnRegistrationDetails
                        }
                    }
                ]
            }, {
                name: "Assets",
                isReadDisabled: businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER,
                stageNo: 3,
                totalPages: 1,
                intermediateScreenText: "Let’s add your assets now",
                isHidden: businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER,
                isCompleted: () => {
                    const { investedInProperty } = investmentInProperty || {};
                    return investedInProperty === true || investedInProperty === false;
                },
                sidebarNavigation: {
                    key: "assets",
                    title: "Assets",
                    description: "Add your assets"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { investedInProperty } = investmentInProperty || {};
                            return investedInProperty === true || investedInProperty === false;
                        },
                        questions: [
                            {
                                questionLabel: "Do you own an investment property?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.investedInProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Investment property ownership (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Is your investment property mortgaged?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: investmentInProperty.investedInProperty,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.mortgaged",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Investment property mortgaged (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Select yes or no",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Investment property value",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: investmentInProperty.investedInProperty,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "investmentInProperty.propertyValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Investment property value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Commercial property value",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "commercialPropertyValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Commercial property value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Savings",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "saving",
                                    fieldType: components.amountInput,
                                    shortTitle: "Savings",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Home value",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "homeValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Home value",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Home contents",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "homeContentsValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Home contents",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Existing vehicles(market value)",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "existingVehiclesValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Existing vehicles(market value)",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Number w/o $ sign",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Shares (value)",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "shareValue",
                                    fieldType: components.amountInput,
                                    shortTitle: "Shares (value)",
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Shares (value)",
                                    isRequired: true,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            investmentInProperty: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                investedInProperty: Yup.boolean().required("Enter valid input").nullable("Enter valid input")
                            })
                        }),
                        initialValues: {
                            investmentInProperty: formData.investmentInProperty,
                            commercialPropertyValue: formData.commercialPropertyValue,
                            saving: formData.saving,
                            homeValue: formData.homeValue,
                            homeContentsValue: formData.homeContentsValue,
                            existingVehiclesValue: formData.existingVehiclesValue,
                            investments: formData.investments,
                            shareValue: formData.shareValue
                        }
                    }
                ]
            }, {
                name: "Liabilities",
                intermediateScreenText: "Let’s add your liabilities now",
                hideIntermediateScreen: true,
                isReadDisabled: businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER,
                stageNo: 4,
                totalPages: 3,
                isHidden: businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER,
                isCompleted: () => {
                    const { numberOfCards } = creditCards || {};
                    return typeof numberOfCards === "number";
                },
                sidebarNavigation: {
                    key: "liabilities",
                    title: "Liabilities",
                    description: "Add your liabilities"
                },
                jumpToNextStageNo: () => {
                    if (balloon) {
                        return NUMBER.FIVE;
                    }
                    return NUMBER.SIX;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { numberOfCards } = creditCards || {};
                            return typeof numberOfCards === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many credit cards do you have?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.numberOfCards",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Credit cards owned",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select number of credit cards you own",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# cards should be >=0",
                                        maxError: "# cards should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Credit card 1",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 1 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[0].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 1 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[0].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 1 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Amount outstanding",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Credit card 2",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 2 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[1].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 2 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[1].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 1 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Amount outstanding",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }, {
                                questionLabel: "Credit card 3",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: creditCards.numberOfCards >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "creditCards.creditCards[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Credit card 3 - name",
                                    title: "Lender name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Name of the lender",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[2].limit",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 3 - limit",
                                    title: "Limit",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Dollar limit",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "creditCards.creditCards[2].balance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Credit card 3 - Amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Amount outstanding",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            creditCards: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfCards: Yup.number().min(0, "# cards should be >=0").max(NUMBER.THREE, "# cards should not exceed 3").required("Enter valid input").nullable("Enter valid input")
                            })
                        }),
                        initialValues: {
                            creditCards: {
                                ...formData.creditCards,
                                numberOfCards: ((creditCards && creditCards.numberOfCards) || 0)
                            }
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 2,
                        isCompleted: () => {
                            const { numberOfLoans } = personalLoans || {};
                            return typeof numberOfLoans === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many personal loans do you have?",
                                questionHelperLabel: "Include secured and unsecured loans",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.numberOfLoans",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Personal loans",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of personal loans",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# loans should be >=0",
                                        maxError: "# loans should not exceed 3"
                                    }
                                }]
                            }, {
                                questionLabel: "Your relationship status",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "relationshipStatus",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Relationship status",
                                    title: null,
                                    isVisible: true,
                                    options: RELATIONSHIP_STATUS_OPTIONS,
                                    placeholder: "Your relationship status",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: RELATIONSHIP_STATUS_OPTIONS,
                                        isSearchable: false,
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Loan 1",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 1 - name",
                                    title: "Lender Name",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 -  current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 1 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans > 0,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[0].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 1 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans > 0) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Loan 2",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 2 - name",
                                    title: "Lender Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 -  current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[1].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 2 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans >= NUMBER.TWO) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Loan 3",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "personalLoans.loanDetails[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Personal loan 3 - name",
                                    title: "Lender Name",
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 2 - balance",
                                    title: "Original amount",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 3 -  current balance",
                                    title: "Current balance",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].repaymentAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Personal loan 3 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: personalLoans.numberOfLoans >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "personalLoans.loanDetails[2].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Personal loan 3 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (personalLoans.numberOfLoans >= NUMBER.THREE) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            personalLoans: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfLoans: Yup.number().min(0, "# loans should be >=0").max(NUMBER.THREE, "# loans should not exceed 3").required("Enter valid input").nullable()
                            }),
                            relationshipStatus: Yup.string().required("Enter valid input").nullable()
                        }),
                        initialValues: {
                            personalLoans: {
                                ...formData.personalLoans,
                                numberOfLoans: ((personalLoans && personalLoans.numberOfLoans) || 0)
                            },
                            relationshipStatus: formData.relationshipStatus
                        }
                    }, {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 3,
                        isCompleted: () => {
                            const { numberOfMortgage } = mortgage || {};
                            return typeof numberOfMortgage === "number";
                        },
                        questions: [
                            {
                                questionLabel: "How many mortgages do you have?",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.numberOfMortgage",
                                    fieldType: components.increementDecreeement,
                                    shortTitle: "# Mortgages",
                                    title: null,
                                    isVisible: true,
                                    options: NUMBER_OPTIONS,
                                    placeholder: "Select the number of mortgages",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: NUMBER_OPTIONS,
                                        isSearchable: false,
                                        defaultValue: 0,
                                        max: 3,
                                        min: 0,
                                        minError: "# mortgages should be >=0",
                                        maxError: "# mortgages should not exceed 3"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Mortgage 1",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage > 0,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[0].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 1 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 1 - original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Morgage 1 - amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 1 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 1 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage > 0) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[0].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 1 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage > 0,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Mortgage 2",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[1].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 2 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 - original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Morgage 2 - amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 2 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 2 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage >= NUMBER.TWO) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[1].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 2 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }, {
                                questionLabel: "Mortgage 3",
                                questionHelperLabel: "Enter number without dollar sign",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "mortgage.mortgageDetails[2].lenderName",
                                    fieldType: components.inputText,
                                    shortTitle: "Mortgage 3 - name",
                                    title: "Lender Name",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter lender's name",
                                    isRequired: false,
                                    priceType: false,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].originalAmount",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 3 - original amount",
                                    title: "Original amount",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].currentBalance",
                                    fieldType: components.amountInput,
                                    shortTitle: "Morgage 3 - amount outstanding",
                                    title: "Amount outstanding",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.TWO,
                                    options: [],
                                    placeholder: "Enter current balance",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].monthlyPayment",
                                    fieldType: components.amountInput,
                                    shortTitle: "Mortgage 3 - mon. repayment",
                                    title: "Monthly repayment",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: [],
                                    placeholder: "Enter monthly repayment",
                                    isRequired: false,
                                    priceType: true,
                                    props: {}
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].jointLoan",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 3 - joint loan (Y/N)",
                                    title: "Is this a joint loan?",
                                    isVisible: (mortgage.numberOfMortgage >= NUMBER.THREE) && (relationshipStatus === RELATIONSHIP_STATUS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS.DEFACTO),
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }, {
                                    apiKey: "mortgage.mortgageDetails[2].investmentProperty",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Mortgage 3 - investment (Y/N)",
                                    title: "Is this an investment property?",
                                    isVisible: mortgage.numberOfMortgage >= NUMBER.THREE,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            },
                            {
                                questionLabel: "Do you have any other liabilities?",
                                questionHelperLabel: "Our financial consultant will get in touch with you for more details.",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "otherLiabilities.haveAnyOtherLiabilities",
                                    fieldType: components.listTabGroup,
                                    shortTitle: "Other liabilities (Y/N)",
                                    title: null,
                                    isVisible: true,
                                    options: BOOLEAN_OPTIONS_FORM,
                                    placeholder: "Yes / No",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BOOLEAN_OPTIONS_FORM,
                                        isSearchable: false,
                                        convertLabelAs: "boolean",
                                        className: "col-6",
                                        styleName: "flexWrapper"
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            mortgage: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                numberOfMortgage: Yup.number().min(0, "# mortgage should be >=1").max(NUMBER.THREE, "# mortgage should not exceed 3").required("Enter valid input").nullable("Enter valid input")
                            }),
                            otherLiabilities: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                haveAnyOtherLiabilities: Yup.boolean().required("Enter valid input").nullable("Enter valid input")
                            })
                        }),
                        initialValues: {
                            mortgage: {
                                ...formData.mortgage,
                                numberOfMortgage: ((mortgage && mortgage.numberOfMortgage) || 0)
                            },
                            otherLiabilities: formData.otherLiabilities
                        }
                    }
                ]
            }, {
                name: "Balloon Details",
                intermediateScreenText: "Let’s add your Balloon details now",
                stageNo: 5,
                isHidden: !balloon,
                isReadDisabled: !balloon,
                totalPages: 1,
                hideIntermediateScreen: true,
                sidebarNavigation: {
                    key: "balloonDetails",
                    title: "Balloon Details",
                    description: "Add balloon details",
                    isHidden: !balloon
                },
                jumpToPrevStageNo: () => {
                    if (businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER) {
                        return NUMBER.TWO;
                    }
                    return NUMBER.FOUR;
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isHidden: !balloon,
                        isCompleted: () => {
                            const { balloonReason, balloonRepaymentMethod }  = formData || {};
                            return !!balloonReason && !!balloonRepaymentMethod;
                        },
                        questions: [
                            {
                                questionLabel: "Why did you opt for balloon payment in your loan?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonReason",
                                    shortTitle: "Balloon reason",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REASONS_OPTIONS,
                                    placeholder: "Select reason",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REASONS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            },
                            {
                                questionLabel: "What will likely be your method of repayment when balloon is due?",
                                questionHelperLabel: "Required as per lender requirements",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "balloonRepaymentMethod",
                                    shortTitle: "Balloon repayment method",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                    placeholder: "Select method",
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        data: BALLOON_REPAYMENT_METHODS_OPTIONS,
                                        isSearchable: false
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            balloonReason: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                            balloonRepaymentMethod: Yup.string().required("Enter valid input").nullable("Enter valid input")
                        }),
                        initialValues: {
                            balloonReason: formData.balloonReason,
                            balloonRepaymentMethod: formData.balloonRepaymentMethod
                        }
                    }
                ]
            }, {
                name: "Declaration & Signature",
                hideIntermediateScreen: true,
                isReadDisabled: true,
                stageNo: 6,
                totalPages: 1,
                isCompleted: () => {
                    const { declarationForInformation } = formData || {};
                    return declarationForInformation;
                },
                jumpToPrevStageNo: () => {
                    if (balloon) {
                        return NUMBER.FIVE;
                    }
                    if (businessType !== BUSINESS_TYPE_VALUE.SOLE_TRADER) {
                        return NUMBER.TWO;
                    }
                    return NUMBER.FOUR;
                },
                sidebarNavigation: {
                    key: "declaration",
                    title: "Declaration",
                    description: "Add your signature"
                },
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        pageNo: 1,
                        isCompleted: () => {
                            const { declarationForInformation } = formData || {};
                            return declarationForInformation;
                        },
                        questions: [
                            {
                                questionLabel: "I declare that the information I have provided is correct to the best of my knowledge",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInformation",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            },
                            {
                                questionLabel: privacyPolicyLabel,
                                setHtml: true,
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: privacyPolicyKey,
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I agree that Cars24 may collect, use and disclose my personal information as set out by the privacy policy, including requesting access to my information from credit reporting bodies",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForPersonalDataUses",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "I consent to receive a call from CARS24 to discuss insurance and get a no-obligation quote (optional)",
                                questionHelperLabel: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                questionStyle: "styles.smallQuestion",
                                fields: [{
                                    apiKey: "declarationForInsuranceCall",
                                    fieldType: components.checkbox,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        label: "Yes"
                                    }
                                }]
                            }, {
                                questionLabel: "Name",
                                questionHelperLabel: null,
                                questionStyle: "styles.smallQuestionDark",
                                helperText: null,
                                inFormNudge: null,
                                isRequired: true,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "name",
                                    fieldType: components.inputText,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: true,
                                    priceType: false,
                                    props: {}
                                }]
                            }, {
                                questionLabel: null,
                                questionHelperLabel: null,
                                questionStyle: null,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                fields: [{
                                    apiKey: "signatureDateTime",
                                    fieldType: components.staticDate,
                                    shortTitle: null,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: null,
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        startDate: new Date(),
                                        minDate: new Date(),
                                        dateFormat: "dd-MM-yyyy",
                                        value: new Date()
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            declarationForInformation: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            [privacyPolicyKey]: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            declarationForPersonalDataUses: Yup.boolean().oneOf([true], "Please click 'Yes'").nullable(),
                            name: Yup.string().required("Enter valid input").nullable()
                        }),
                        initialValues: {
                            declarationForInformation: formData.declarationForInformation,
                            [privacyPolicyKey]: formData[privacyPolicyKey],
                            declarationForPersonalDataUses: formData.declarationForPersonalDataUses,
                            declarationForInsuranceCall: formData.declarationForInsuranceCall,
                            name: formData.name,
                            signatureDateTime: formData.signatureDateTime
                        }
                    }
                ]
            }
        ]
    };
    return configObj;
};

export {
    V1_COMMERCIAL_CONFIG,
    v1CommercialResetQuestions
};
