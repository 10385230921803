import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendOTP, verifySignIn } from "../../../../components/au.configuration/actions";
import GuestLoginVerifyEmail from "./component";

const mapStateToProps = ({
    user: {email}
}) => ({
    email
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    verifySignInConnect: verifySignIn,
    sendOTPConnect: sendOTP
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GuestLoginVerifyEmail);
