import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "../../shared/modal";
import styles from "./styles.css";
import BackIcon from "./images/back-icon.svg";
import Banner from "./images/banner.png";
import CheckIcon from "./images/check.svg";
import locationIcon from "./images/location.svg";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { PLATINUM_COVER_GA_EVENTS } from "../cars24-cover-revamp/tracking";

const SeeWhatIsCoveredModal = ({ onClose, isOpen, servicingLocation }) => {
    const [activeTab, setActiveTab] = useState(0);

    const renderListItems = (items) => (
        items.map((item, index) => (
            <p key={index} styleName={"styles.list"}>
                <img src={CheckIcon} alt="Check icon" />{item}
            </p>
        ))
    );

    useEffect(() => {
        if (isOpen) {
            trackDesktopCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.WHAT_IS_COVERED_MODAL_OPENED);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen}>
            <div styleName={"styles.modalOuter"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.heading"}><img src={BackIcon} onClick={onClose} />See what’s covered</p>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.leftWrapper"}>
                        <div styleName={"styles.bannerWrapper"}>
                            <img src={Banner} />
                        </div>
                        <div styleName={"styles.sectionWrap"}>
                            <p styleName={"styles.sectionHeading"}><span>1</span>Free Roadside Assistance</p>
                            {/* {renderListItems([
                                "Servicing at 6 month / 10,000km",
                                "Completed by skilled CARS24 technicians at our local service centre",
                                "Lock in attractive prices"
                            ])} */}
                        </div>
                        <div styleName={"styles.sectionWrap"}>
                            <p styleName={"styles.sectionHeading"}><span>2</span>Extended warranty cover</p>

                            <div styleName={"styles.tabWrapper"}>
                                <div styleName={`styles.tab ${activeTab === 0 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(0)}>
                                    <p>Mechanical</p>
                                </div>
                                <div styleName={`styles.tab ${activeTab === 1 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(1)}>
                                    <p>Electrical</p>
                                </div>
                            </div>
                            {activeTab === 0 ?
                                <div styleName={"styles.secondSection"}>
                                    <div styleName={"styles.listSections"}>
                                        {renderListItems(["Engine", "Drivetrain", "Steering", "Differential"])}
                                    </div>
                                    <div styleName={"styles.listSections"}>
                                        {renderListItems(["Driveshaft", "Transmission", "Cooling", "Ignition"])}
                                    </div>
                                    <div styleName={"styles.listSections"}>
                                        {renderListItems(["Fuel system", "Timing belt", "Clutch", "Gearbox"])}
                                    </div>
                                </div>
                                :
                                <div styleName={"styles.secondSection"}>
                                    <div styleName={"styles.listSections"}>
                                        {renderListItems(["Computers", "Starter motor", "ECUs", "Alternator"])}
                                    </div>
                                    <div styleName={"styles.listSections"}>
                                        {renderListItems(["Power windows", "ABS brake system", "Air conditioning"])}
                                    </div>
                                </div>
                            }
                        </div>
                        <div styleName={"styles.sectionWrap"}>
                            <p styleName={"styles.sectionHeading"}><span>3</span>Car maintainance - Servicing</p>
                            {renderListItems([
                                "Servicing at 6 month / 10,000km",
                                "Completed by skilled CARS24 technicians at our local service centre",
                                "Lock in attractive prices"
                            ])}
                        </div>
                    </div>
                    <div styleName={"styles.rightWrapper"}>
                        <div styleName={"styles.boxWrapper"}>
                            <p styleName={"styles.locationHeading"}>CARS24 service center</p>
                            <p styleName={"styles.locationSubHeading"}>Drop off your car at the service centre and we’ll do the rest.</p>
                            <p styleName={"styles.location"}><img src={locationIcon} />{servicingLocation}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SeeWhatIsCoveredModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.func,
    servicingLocation: PropTypes.string
};

export default SeeWhatIsCoveredModal;
