import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openLoginPopup, setAffiliateSource } from "../../au.configuration/actions";
import { LoginContext } from "../../au.configuration/component";

export default function withLogin(Component) {
    const AuthenticatedComponent = (props) => {
        const { setOnLoginSuccessCallback, setOnLoginCloseCallback } = useContext(LoginContext);

        return (
            <Component
                {...props}
                setOnLoginSuccessCallback={setOnLoginSuccessCallback}
                setOnLoginCloseCallback={setOnLoginCloseCallback}
            />
        );
    };

    AuthenticatedComponent.propTypes = {
        isLoggedIn: PropTypes.bool,
        openLoginPopupConnect: PropTypes.func
    };

    const mapStateToProps = ({ user: { isLoggedIn, secureToken, name, email, profileName, isAffiliate, affiliateSource } }) => {
        return {
            isLoggedIn,
            secureToken,
            name,
            email,
            profileName,
            isAffiliate,
            affiliateSource
        };
    };

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                openLoginPopupConnect: openLoginPopup,
                setAffiliateSourceConnect: setAffiliateSource
            },
            dispatch
        );
    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
