import { V1_UPLOAD_CONFIG } from "../../../config/financing-post-payment-forms-config/v1-upload";
import { V2_UPLOAD_CONFIG } from "../../../config/financing-post-payment-forms-config/v2-upload";
import { V3_UPLOAD_CONFIG } from "../../../config/financing-post-payment-forms-config/v3-upload";
import { V4_UPLOAD_CONFIG } from "../../../config/financing-post-payment-forms-config/v4-upload";
import { FINANCE_FORMS_ID } from "../../../constants/checkout-constants";
const { v1Upload, v2Upload, v3Upload, v4Upload } = FINANCE_FORMS_ID;
const formMapping = {
    [v1Upload]: V1_UPLOAD_CONFIG,
    [v2Upload]: V2_UPLOAD_CONFIG,
    [v3Upload]: V3_UPLOAD_CONFIG,
    [v4Upload]: V4_UPLOAD_CONFIG
};

export {
    formMapping
};
