import React from "react";
import { Helmet } from "react-helmet";

const GreenIdScripts = () => {
    if (process.env.HOST_ENV === "PRODUCTION") {
        return (
            <Helmet>
                <link rel="stylesheet" type="text/css" media="screen" href="https://simpleui-au.vixverify.com/df/assets/stylesheets/greenid.css"/>
                <link rel="stylesheet" type="text/css" media="screen" href="https://au.vixverify.com/df/assets/stylesheets/greenid-mobile.css"/>
            </Helmet>
        );
    } else {
        return (
            <Helmet>
                <link rel="stylesheet" type="text/css" media="screen" href="https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css"/>
                <link rel="stylesheet" type="text/css" media="screen" href="https://test-au.vixverify.com/df/assets/stylesheets/greenid-mobile.css"/>
            </Helmet>
        );
    }

};

export default GreenIdScripts;
