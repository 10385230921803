import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {registerVerificationStatus} from "../checkout-info/actions";
import GreenIdCard from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        verificationData,
        verifyIdCompleted,
        verificationDataError

    }
}) => ({
    content,
    verificationData,
    verifyIdCompleted,
    verificationDataError
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    registerVerificationStatusConnect: registerVerificationStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GreenIdCard);
