
// eslint-disable-next-line max-statements
import { getDeviceType } from "../utils/helpers/get-device-type";

export default (api) => {
    const deviceType = getDeviceType();
    const userLoginEvent = (params, token) => {
        return api.post("/api/v1/user/login-event", params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const submitContactQuery = (params) => {
        return api.post("/v1/contact-us", params, {
            headers: {
                X_VEHICLE_TYPE: "car",
                SOURCE: deviceType
            }
        });
    };

    return {
        userLoginEvent,
        submitContactQuery
    };
};
