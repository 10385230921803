import { createReducer } from "reduxsauce";
import Types from "./types";
// import  pollingConfig from "./poll-config.json";

export const INITIAL_STATE = {
    cityList: [],
    map: "googlemap",
    drivaPollingConfig: null,
    isGetDrivaPollingConfigError: null,
    drivaPollingConfigLoading: false,
    inHouseFinancePollingConfig: null,
    isGetInHouseFinancePollingConfigError: null,
    inHouseFinancePollingConfigLoading: false,
    checkoutText: {
        deliveryScreen: {
            deliveryFAQ: [],
            homeDeliveryInformation: [],
            pickUpInformation: [],
            disclaimer: [],
            voucherHeading: ""
        },
        orderSummaryScreen: {}
    }
};

export const fetchCityListSuccess = (state = INITIAL_STATE, { cityList }) => {
    return { ...state, cityList };
};

export const getDrivaTimeoutConfigRequest = (state = INITIAL_STATE, { loading }) => {
    return {
        ...state,
        drivaPollingConfigLoading: loading,
        isGetDrivaPollingConfigError: null
    };
};

export const getDrivaTimeoutConfigSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        drivaPollingConfig: data,
        drivaPollingConfigLoading: false,
        isGetDrivaPollingConfigError: null
    };
};

export const getDrivaTimeoutConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        drivaPollingConfigLoading: false,
        isGetDrivaPollingConfigError: error
    };
};

export const getInHouseFinanceTimeoutConfigRequest = (state = INITIAL_STATE, { loading }) => {
    return {
        ...state,
        inHouseFinancePollingConfigLoading: loading,
        isGetInHouseFinancePollingConfigError: null
    };
};

export const getInHouseFinanceTimeoutConfigSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        inHouseFinancePollingConfig: data,
        inHouseFinancePollingConfigLoading: false,
        isGetInHouseFinancePollingConfigError: null
    };
};

export const getInHouseFinanceTimeoutConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        inHouseFinancePollingConfigLoading: false,
        isGetInHouseFinancePollingConfigError: error
    };
};

const drivaPollApiSuccess = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

const drivaPollApiFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const getCheckoutScreenConfigRequest = (state = INITIAL_STATE, { loading }) => {
    return {
        ...state,
        drivaPollingConfigLoading: loading,
        isGetDrivaPollingConfigError: null
    };
};

const getCheckoutScreenConfigSucess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        checkoutText: (data.config || {}).checkoutText,
        isCheckoutConfigLoading: false,
        isCheckoutConfigError: null,
        ...((data || {}).config || {})
    };
};

const getCheckoutScreenConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isCheckoutConfigLoading: false,
        isCheckoutConfigError: error
    };
};

export const HANDLERS = {
    [Types.FETCH_CITY_LIST_SUCCESS]: fetchCityListSuccess,
    [Types.GET_DRIVA_TIMEOUT_CONFIG_SUCCESS]: getDrivaTimeoutConfigSuccess,
    [Types.GET_DRIVA_TIMEOUT_CONFIG_FAILURE]: getDrivaTimeoutConfigFailure,
    [Types.GET_DRIVA_TIMEOUT_CONFIG_REQUEST]: getDrivaTimeoutConfigRequest,
    [Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_SUCCESS]: getInHouseFinanceTimeoutConfigSuccess,
    [Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_FAILURE]: getInHouseFinanceTimeoutConfigFailure,
    [Types.GET_IN_HOUSE_FINANCE_TIMEOUT_CONFIG_REQUEST]: getInHouseFinanceTimeoutConfigRequest,
    [Types.DRIVA_API_POLL_SUCCESS]: drivaPollApiSuccess,
    [Types.DRIVA_API_POLL_FAILURE]: drivaPollApiFailure,
    [Types.CHECKOUT_SCREEN_CONFIG_SUCCESS]: getCheckoutScreenConfigSucess,
    [Types.CHECKOUT_SCREEN_CONFIG_FAILURE]: getCheckoutScreenConfigFailure,
    [Types.CHECKOUT_SCREEN_CONFIG_REQUEST]: getCheckoutScreenConfigRequest
};

export default createReducer(INITIAL_STATE, HANDLERS);
