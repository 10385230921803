import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EnterGuestEmailModal from "./component";
import { getGuestToken, setProfileDetails } from  "../../../../components/au.configuration/actions";
import { setGuestLoginVisible, setLoginSuccessResponse } from  "../../../../components/au.mobile/guest-login/actions";

const mapStateToProps = ({
    user: {secureToken, email, gaId}
}) => ({
    secureToken,
    email,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setGuestLoginVisibleConnect: setGuestLoginVisible,
    setLoginSuccessResponseConnect: setLoginSuccessResponse,
    getGuestTokenConnect: getGuestToken,
    setProfileDetailsConnect: setProfileDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnterGuestEmailModal);
