import React from "react";
import styles from "./styles.css";
import LoaderImg from "./images/loader.svg";
import PropTypes from "prop-types";

const Loader = ({ autoHeight = false }) => {
    return (
        <div
            styleName={`styles.wrapper ${autoHeight ? "styles.heightAuto" : ""}`}>
            <div styleName={"styles.loader"}>
                <img src={LoaderImg} alt={"Please wait"} />
            </div>
        </div>
    );
};

Loader.propTypes = {
    autoHeight: PropTypes.bool
};

export default Loader;
