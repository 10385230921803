import React from "react";
import PropTypes from "prop-types";

import Modal from "../../shared/modal";
import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import Tick from "./images/tick.svg";
import Button from "../../shared/button";
import { useHistory } from "react-router";
import Filters from "../../../utils/filters-v2";
import { ORDER_TYPE } from "../../../constants/app-constants";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const BuyCarOnlineModal = ({
    city = "",
    orderId,
    content,
    updateOrderConnect = () => {},
    onClose = () => {}
}) => {

    const history = useHistory();

    const onClickListingPage = () => {
        const filters = new Filters({ cityCode: "", cityName: "" });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {} });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, { eventAction: "consent_screen", eventLabel: "continue_browsing" });
        history.push(relativeURL);
        window.setTimeout(() => window.scrollTo(0, 0));
    };

    const updateOrderForBuyNow = () => {
        const payload = {
            orderType: ORDER_TYPE.BUY_ONLINE
        };
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, { eventAction: "consent_screen", eventLabel: "buy_online" });
        updateOrderConnect(orderId, payload).then(() => {
            const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.basicDetails.route);
            history.push(relativeURL);
        });
    };

    const handleClose = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, { eventAction: "consent_screen", eventLabel: "close" });
        onClose();
    };

    return (
        <Modal isOpen={true}>
            <div styleName={"styles.modalContent"}>
                <div styleName={"styles.headerWrap"}>
                    <h3>Test drive unavailable</h3>
                    <CloseButton type="grey" onClickHandler={handleClose}/>
                </div>
                <div styleName={"styles.contentWrapper"}>
                    <p styleName={"styles.heading"}>Unfortunately this car is only available for test-drive in {city}. You can either:</p>
                    <p styleName={"styles.buyIt"} >Buy it completely online</p>
                    <div styleName={"styles.lists"}>
                        <img src={Tick} />
                        <p styleName={"styles.listType"}>7 days to inspect, drive, and test-own</p>
                    </div>
                    <div styleName={"styles.lists"}>
                        <img src={Tick} />
                        <p styleName={"styles.listType"}>100% refund if you don’t like the car</p>
                    </div>
                    <div styleName={"styles.lists"}>
                        <img src={Tick} />
                        <p styleName={"styles.listType"}>Free delivery to your doorstep</p>
                    </div>
                    <div styleName={"styles.buttonContainer"}>
                        <Button text="buy this car online" onClick={updateOrderForBuyNow} />
                    </div>
                    <p styleName={"styles.or"}>OR</p>
                    <p styleName={"styles.continueBrowsing"} onClick={onClickListingPage}>Continue browsing for other cars</p>
                </div>
            </div>
        </Modal>
    );
};

BuyCarOnlineModal.propTypes = {
    onClose: PropTypes.func,
    city: PropTypes.string,
    updateOrderConnect: PropTypes.func,
    content: PropTypes.object,
    orderId: PropTypes.string
};

export default BuyCarOnlineModal;
