import { createReducer } from "reduxsauce";
import { findByKey } from "../../../utils/helpers/find-by-key";
import Types from "./types";

export const INITIAL_STATE = {
    selectedCity: {}
};

export const updateCity = (state = INITIAL_STATE, { city, cityList }) => {
    const isCityName = isNaN(Number(city));
    const selectedCity = findByKey(cityList, isCityName ? "name" : "id", city) || INITIAL_STATE.selectedCity;
    return { ...state, selectedCity };
};

export const updateCityWithCode = (state = INITIAL_STATE, { cityCode, cityList }) => {
    const selectedCity = findByKey(cityList, "code", cityCode) || INITIAL_STATE.selectedCity;
    return { ...state, selectedCity };
};

export const HANDLERS = {
    [Types.UPDATE_CITY]: updateCity,
    [Types.UPDATE_CITY_WITH_CODE]: updateCityWithCode
};

export default createReducer(INITIAL_STATE, HANDLERS);
