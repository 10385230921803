import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VasProductDetail from "./component";
import { addVASItem, getVASProductDetail, removeVASItem, setAddonScreenType } from "../actions";
import { showToast } from "../../../shared/toast-message/actions";

const mapStateToProps = ({
    carDetails: {
        content
    },
    addOns: {
        vasProductDetail,
        addVASItem: addVasItemData,
        vasCart: {
            data: vasCartData
        }
    },
    checkout: {
        order: {
            orderId
        },
        quotesAvailable: preApproved
    }
}) => ({
    addVasItemData,
    content,
    vasProductDetail,
    orderId,
    preApproved,
    vasCartData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addVASItemConnect: addVASItem,
    getVASProductDetailConnect: getVASProductDetail,
    removeVASItemConnect: removeVASItem,
    setAddonScreenTypeConnect: setAddonScreenType,
    showToastConnect: showToast
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VasProductDetail);
