import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HowWeCompare from "./component";

const mapStateToProps = ({checkout: {extendedWarranty: { warrantyPlanInfo }}}) => ({warrantyPlanInfo});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HowWeCompare);
